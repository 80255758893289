<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="warehouse?.accountId">
                <a href="/#/AccountDetails/Index/{{warehouse?.accountId}}">{{warehouse?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Warehouses/Overview">{{'general.warehouses' | translate }}</a>
            </li>
            <li>
                <a href="/#/WarehouseDetails/Index/{{warehouse?.id}}">{{warehouse?.name}}</a>
            </li>
            <li class="active">{{'menu.sensors' | translate }}</li>
        </ol>
    </div>
    <fh-warehouse-details-navigation [warehouseId]="warehouseId"></fh-warehouse-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="">
            <div class="col-lg-12">

                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox" style="position:relative">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-sensor"></i> {{'general.sensors' | translate }}
                        </h5>

                        <span class="pull-right"
                            tooltip="{{'general.updated' | translate }} {{ previousLookupDisplay | amDateFormat: 'LLL' }}">
                            <i style="margin-left: 3px;" class="fa fa-fw"
                                [ngClass]="isSpinning ? 'fa-spinner fa-spin eye-active green' : 'fa-sync-alt'"></i>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                        <ngx-loading [show]="loading || loadingZones || loadingSensorData || saving"></ngx-loading>
                        <div style="padding:10px" *ngIf="loadingSensorData">Fetching sensor data</div>
                        <div style="padding:10px" *ngIf="!loadingSensorData && sensorData.length == 0"> {{
                            'general.noSensorData' | translate}}</div>
                        <div>
                            <div *ngFor="let data of sensorData | orderBy: 'zoneName': false ; let i = index">
                                <div class="col-lg-2 col-xs-12" style="padding: 0;">
                                    <div class="kpi-item border-active " [ngClass]="data?.markerColor">
                                        <h3 class="margin-bottom font-bold" title="Sensor: {{ data.sensorId }}">
                                            <i class="fa fa-signal"
                                                [style.color]="data?.markerColor == 'green' ? '#0a8a15' : (data?.markerColor == 'orange' ? '#d48113' : '#b81313')"></i>
                                            {{
                                            data.sensorName}}
                                        </h3>
                                        <div title="{{ data.timestamp | amDateFormat: 'LLL'}}">{{
                                            data.zoneName}} - {{ data.timestamp | amTimeAgo}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-6" style="padding: 0;">
                                    <div class="kpi-item">
                                        <h3 class="margin-bottom font-bold"
                                            title="Accepted range: {{ data.lowerTemperatureThreshold }}°C - {{data.upperTemperatureThreshold}}°C">

                                            <i class="fas fa-fw fa-thermometer-three-quarters" title="Temperature"></i>
                                            {{
                                            data.temperature}} °C
                                        </h3>
                                        <div>{{ 'general.temperature' | translate}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-xs-6" style="padding: 0;">
                                    <div class="kpi-item">
                                        <h3 class="margin-bottom font-bold"
                                            title="Accepted range: {{ data.lowerHumidityThreshold }}% - {{data.upperHumidityThreshold}}%">

                                            <i class="fas fa-fw fa-humidity" title="humidity"></i>
                                            {{
                                            data.humidity}} %
                                        </h3>
                                        <div>{{ 'general.humidity' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>