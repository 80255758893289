<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Resellers/Overview">{{'general.reseller' | translate }}</a>
            </li>
            <li class="active">{{'general.resellerDetails' | translate }}</li>
        </ol>
    </div>
    <fh-reseller-details-navigation [resellerId]="resellerId"></fh-reseller-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">

                <form #userForm="ngForm">
                    <div class="ibox" style="position: relative;">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-city"></i> {{'general.reseller' | translate }}
                            </h5>
                        </div>
                        <fh-reseller-details [reseller]="reseller" [formMode]="formMode" [loading]="loading || saving">
                        </fh-reseller-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                            [allowEdit]="permissions['Platform_IsAdministrator']" [allowDelete]=false
                            (setFormMode)="setFormMode($event)" [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-city"></i> {{'general.children' | translate }}
                        </h5>
                        <span class="pull-right">
                            <div class="filter-form hidden-sm hidden-xs">
                                <input type="text" class="form-control table-inline top-filter hidden-sm hidden-xs"
                                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                        <ngx-loading [show]="loadingChildren"></ngx-loading>
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <thead>
                                <tr>
                                    <th>{{'general.name' | translate }}</th>
                                    <th>{{'general.code' | translate }}</th>
                                    <th>{{'general.clientCount' | translate }}</th>
                                    <th>{{'general.deviceCount' | translate }}</th>
                                    <th>{{'general.countRecursive' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let child of children | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage, id: 'first'}">

                                    <td><a href="/#/ResellerDetails/Index/{{child.id}}">
                                            <span class='secondary link_bolder'><i
                                                    class="fas fa-fw fa-angle-right"></i></span>
                                            {{ child.resellerDescription }}</a>
                                    </td>
                                    <td>{{ child.resellerAuthorizationCode }}</td>
                                    <td>{{ child.resellerCount }}</td>
                                    <td>{{ child.deviceCount }}</td>
                                    <td>{{ child.deviceRecursiveCount }}</td>
                                </tr>
                                <tr *ngIf="children.length === 0">
                                    <td colspan="5">
                                        {{'general.nodatafound' | translate }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="first">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="row7">
                    <div class="col-lg-4 col-md-12">
                        <div class="ibox hand" [routerLink]="['/Devices/Overview/']"
                            [queryParams]="{resellerDescription: reseller.resellerDescription}">
                            <div class="ibox-title">
                                <h5>
                                    <i class="fas fa-fw fa-microchip"></i> {{'general.devices' | translate }}
                                </h5>
                            </div>
                            <div class="ibox-content ibox-kpi" style="position: relative;">
                                <ngx-loading [show]="loadingKpi"></ngx-loading>
                                <h2 class="margin-bottom font-bold"
                                    title="Devices on this reseller and children / Device on this reseller">
                                    {{activeDevicesRecursiveCount?.toLocaleString()}} /
                                    {{activeDevices?.toLocaleString()}}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="ibox hand" [routerLink]="['/Drivers/Overview/']"
                            [queryParams]="{resellerDescription: reseller.resellerDescription}">
                            <div class="ibox-title">
                                <h5>
                                    <i class="fa fa-fw fa-id-card-alt"></i> {{'general.drivers' | translate }}
                                </h5>
                            </div>
                            <div class="ibox-content ibox-kpi" style="position: relative;">
                                <ngx-loading [show]="loadingKpi"></ngx-loading>
                                <h2 class="margin-bottom font-bold">{{activeDrivers?.toLocaleString()}}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="ibox hand" [routerLink]="['/Geofences/Overview/']"
                            [queryParams]="{resellerDescription: reseller.resellerDescription}">
                            <div class="ibox-title">
                                <h5>
                                    <i class="fa fa-fw fa-draw-polygon"></i> {{'general.geofences' | translate }}
                                </h5>
                            </div>
                            <div class="ibox-content ibox-kpi" style="position: relative;">
                                <ngx-loading [show]="loadingKpi"></ngx-loading>
                                <h2 class="margin-bottom font-bold">{{activeGeofences?.toLocaleString()}}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ibox" style="position: relative;">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-building"></i> {{'general.accounts' | translate }}
                        </h5>

                        <span class="pull-right">
                            <div class="filter-form hidden-sm hidden-xs">
                                <input type="text" class="form-control table-inline top-filter hidden-sm hidden-xs"
                                    [(ngModel)]="filterAccounts">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                        <ngx-loading [show]="loadingAccounts"></ngx-loading>
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th style="width:auto">
                                        {{ 'general.name' | translate}}
                                    </th>
                                    <th style="width:70px">
                                        {{ 'general.deviceCount' | translate}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="!loading && !loadingAccounts && accounts.length === 0">
                                    <td colspan="2">
                                        {{'general.nodatafound' | translate }}
                                    </td>
                                </tr>
                                <tr
                                    *ngFor="let account of accounts | orderBy : 'name':false | stringFilter: filterAccounts | paginate: { itemsPerPage: 10, currentPage: currentPageAccounts, id: 'accounts'}">
                                    <td><a href='/#/AccountDetails/Index/{{account.id}}'>
                                            <span class='secondary link_bolder'><i
                                                    class="fas fa-fw fa-angle-right"></i></span>
                                            {{ account.name }}</a>
                                    </td>
                                    <td> {{ account.deviceCount }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #pAccounts="paginationApi" (pageChange)="currentPageAccounts = $event"
                            id="accounts">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous"
                                        [class.disabled]="pAccounts.isFirstPage()">
                                        <a class="page-link" (click)="pAccounts.previous()"
                                            *ngIf="!pAccounts.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="pAccounts.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of pAccounts.pages"
                                        [class.current]="pAccounts.getCurrent() === page.value">
                                        <a class="page-link" (click)="pAccounts.setCurrent(page.value)"
                                            *ngIf="pAccounts.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="pAccounts.setCurrent(page.value)"
                                            *ngIf="pAccounts.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next"
                                        [class.disabled]="pAccounts.isLastPage()">
                                        <a class="page-link" (click)="pAccounts.next()" *ngIf="!pAccounts.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="pAccounts.isLastPage()"> {{ 'grid.next' | translate
                                            }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <fh-devices-connected [loadingDeviceCount]="loadingDeviceCount" [data]="deviceCountData">
                </fh-devices-connected>

                <fh-tagging [objectId]="reseller?.id" [objectType]="tagType">
                </fh-tagging>

            </div>
        </div>
    </div>
</fh-permission-handler>