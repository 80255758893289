import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { MaintenanceType } from 'app/models/maintenance.model';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { MaintenanceTypesService } from 'app/services/maintenance/maintenanceTypes.service';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fh-maintenance-type-add',
  templateUrl: 'addType.template.html'
})
export class MaintenanceTypeAddViewComponent {
  token: string;
  loading = false;
  maintenanceType = new MaintenanceType;

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string; };

  assetTypes = [];

  permissionName = 'MaintenanceTypes_Add';

  constructor(private translateServe: TranslateService, private maintenanceTypeService: MaintenanceTypesService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router, private assetTypeService: AssetTypeService) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
  }

  hasSelectedTypes(): boolean {
    return this.assetTypes.some(x => x.maintenanceTypeChecked === true);
  }

  onInsert() {
    this.loading = true;

    this.maintenanceTypeService.saveMaintenanceType(this.maintenanceType, this.assetTypes).subscribe(result => {

      this.success = {
        statusText: 'Success',
        success: this.translateServe.instant('general.saveSuccessRedirect', { entity: 'maintenanceType' })
      };

      setTimeout(() => {
        this.router.navigate(['/Maintenances/MaintenanceTypeDetails/Index/' + result.id]);
      }, 2000);

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }

  accountChanged(accountId) {
    console.log('Changed accountId');
    this.maintenanceType.accountId = accountId;

    this.loading = true;

    if (this.maintenanceType.accountId && this.maintenanceType.accountId > 0) {
      this.assetTypeService.getAssetTypes(this.maintenanceType.accountId).subscribe(result => {
        this.assetTypes = result;
        this.loading = false;
        this.cd.markForCheck();
      });
    } else {
      this.loading = false;
    }

    this.cd.markForCheck();
  }
}
