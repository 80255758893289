export const environment = {
    version: '20230718.3',
    production: false,
    ConsumerToken: 'c4p8GFRvoWjo+x1I4QZNZP2hpsoRrIvkj44xP5J0zj6eG7fYI1RgNMyXXxNmmWdkjyRjjs4VlWJFMA8OR6huFw==',
    AuthenticationUrl: 'https://fleetmanagement-api.staging.gpscockpit.net/api/',
    Environment: 'local',
    Debug: true,
    SSOEnabled: true,
    SSOAuthenticationUrl: 'http://localhost:5000',
    SSORedirectUrl: 'http://localhost:4200/#/Auth-callback?',
    SSOPostLogoutRedirectUrl: 'http://localhost:4200/#/Endsession-callback',
    SSOFacebookID: '359800557914995',
    FORedirectUrl: 'http://localhost:4201/#/',
    ApplicationName: '360Locate - Fleet Management',
};
