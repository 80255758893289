import { Component, OnInit } from '@angular/core';
import { environment } from 'app/../environments/environment';
import { InfoService } from 'app/services/info/info.service';
import { MarkdownService } from 'ngx-markdown';

import 'prismjs';
import 'prismjs/components/prism-typescript.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';

@Component({
    selector: 'fh-changelog',
    providers: [MarkdownService],
    templateUrl: 'changelog.component.html'
})
export class ChangelogViewComponent implements OnInit {

    appDate: Date;
    appVersion: string;
    appName: string;
    endPoint: string;

    constructor(private infoService: InfoService) {
    }

    ngOnInit(): void {
        this.appVersion = environment.version;
    }
}
