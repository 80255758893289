<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Drivers/Overview">{{'general.drivers' | translate }}</a>
            </li>
            <li class="active">{{'general.cards' | translate }}</li>
        </ol>
    </div>
    <fh-driver-navigation></fh-driver-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-credit-card-front"></i> {{'general.cards' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative;" *ngIf="languageLoaded">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div id="dynamicFilters">
                            <div class="col-lg-4">
                                <div class="form-group" style="margin-bottom: 6px;">
                                    <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                        (onChanged)="resellerChanged($event)" required [tableInline]="false"
                                        placeholder="{{ 'general.chooseReseller' | translate}}"
                                        [(ngModel)]="selectedResellerId">
                                    </fh-reseller-input>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group" style="margin-bottom: 6px;">
                                    <fh-account-input name="account" #accountVar="ngModel"
                                        (onChanged)="accountChanged($event)" required [activeOnly]=true
                                        [selectedResellerId]="selectedResellerId" [tableInline]="false"
                                        placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                        [(ngModel)]="selectedAccountId">
                                    </fh-account-input>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group" style="margin-bottom: 6px;">
                                    <fh-driver-group-input name="driverGroups" #driverGroupVar="ngModel" required
                                        [selectedAccountId]="selectedAccountId" [tableInline]="false"
                                        (onChanged)="filterDrivers()"
                                        placeholder="{{ (selectedAccountId? 'general.chooseDriverGroups' : 'general.firstChooseAccount') | translate}}"
                                        [(ngModel)]="selectedDriverGroups">
                                    </fh-driver-group-input>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group input-group" style="margin-bottom: 6px;">
                                    <span class="input-group-addon">
                                        <i class="fa fa-fw fa-filter"></i>
                                    </span>
                                    <input type="text" class="form-control" placeholder="Filter on name/imei ect"
                                        (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group input-group" style="margin-bottom: 6px;">
                                    <span class="input-group-addon hand" title="Sort direction"
                                        (click)="orderByReversed = !orderByReversed">
                                        <i class="fa fa-fw "
                                            [ngClass]="orderByReversed == true ? 'fa-sort-amount-down' : 'fa-sort-amount-up'"></i>
                                    </span>
                                    <ng-select [(ngModel)]="orderBy" class="form-control" name="itemsPerPage" required
                                        [clearable]="false" style="width: 50%; float:left;" style="width:100%">
                                        <ng-option value='created'>{{'general.created' | translate }}</ng-option>
                                        <ng-option value='name'>{{'general.name' | translate }}</ng-option>
                                        <ng-option value='activeAssignments'>{{'general.activeAssignments' | translate
                                            }}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group input-group" style="margin-bottom: 6px;">
                                    <span class="input-group-addon" title="Items per page">
                                        <i class="fa fa-fw fa-stream"></i>
                                    </span>
                                    <ng-select [(ngModel)]="itemsPerPage" class="form-control" name="itemsPerPage"
                                        required [clearable]="false" style="width:100%">
                                        <ng-option [value]="10">10</ng-option>
                                        <ng-option [value]="17">17</ng-option>
                                        <ng-option [value]="25">25</ng-option>
                                        <ng-option [value]="50">50</ng-option>
                                        <ng-option [value]="99999">{{'general.all' | translate }}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="drivers.length == 0">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-content" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <span *ngIf="!selectedAccountId">{{'general.pleaseSelectAccount' | translate
                            }}</span>
                        <span *ngIf="selectedAccountId">{{'general.noDataFound' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6"
                *ngFor="let driver of drivers | orderBy : orderBy : orderByReversed | stringFilter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, id: 'first'}; ">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-id-card-alt"></i> <a class="secondary link_bolder"
                                href="/#/DriverDetails/Index/{{driver?.id}}">{{ driver.name }}</a>
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening_small">
                                        {{ 'general.companyName' | translate}}
                                    </td>
                                    <td class="row_content">
                                        <a class="secondary link_bolder"
                                            href="/#/AccountDetails/Index/{{driver?.accountId}}">{{driver?.companyName
                                            }}</a>
                                    </td>
                                    <td></td>
                                    <td>
                                        <!-- <img style="position: absolute;" [src]="getIcon(driver.asset.icon)"> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_small">
                                        {{ 'general.phone' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ driver?.phone ?? '-' }}
                                    </td>
                                    <td class="row_opening_small">
                                        {{ 'general.tag' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ driver?.tag ?? '-' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_small">
                                        {{ 'general.activeAssignments' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ driver?.activeAssignments ?? '-' }}
                                    </td>
                                    <td class="row_opening_small">
                                        {{ 'general.isActive' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ driver?.isActive ?? '-' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_small">
                                        {{ 'general.groups' | translate}}
                                    </td>
                                    <td class="row_content" colspan="3">
                                        <span *ngFor="let group of driver.driverGroups">
                                            <a class="btn btn-info btn-grid"
                                                href="/#/DriverGroupDetails/Index/{{group?.id}}"><i
                                                    class="fas fa-fw fa-layer-group"></i> {{group?.name
                                                }}</a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="first">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>