import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { EntityType } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DriverGroupsService } from 'app/services/driver/driverGroups.service';
import { DriverGroup } from 'app/models/group.model';
import { DriverService } from 'app/services/driver/driver.service';

@Component({
    selector: 'fh-driver-group-details',
    templateUrl: 'groupDetails.template.html'
})
export class DriverGroupDetailsViewComponent implements OnInit {
    sub: any;
    driverGroup: DriverGroup = new DriverGroup();
    device: any;
    tagType = EntityType.DriverGroup.valueOf();
    formMode = FormMode.read;

    loading = false;
    saving = false;
    loadingGroups = false;
    loadingUsers = false;

    userItems = [];

    error: any;
    permissions: {};
    success: any;

    driverGroupsItems = [];
    driverLink = 'DriverDetails';

    permissionName = 'DriverGroups_View';

    // Selector
    source = [];
    confirmed = [];
    format = {
        add: 'Add', remove: 'Remove', all: 'All', none: 'None', draggable: true
    };
    loadingDrivers = false;
    display = 'name';

    constructor(private cd: ChangeDetectorRef, private driverService: DriverService, private authentication: AuthenticationService, private driverGroupsService: DriverGroupsService, private route: ActivatedRoute, private router: Router) {
        this.permissions = this.authentication.permissions;
    }

    ngOnInit() {

        this.loading = true;
        this.loadingGroups = true;
        this.loadingUsers = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.driverGroupsService.getDriverGroupById(id).subscribe(driverGroup => {
                this.driverGroup = driverGroup;
                this.loading = false;

                this.driverGroupsService.getDriverGroupItemsById(0, id).subscribe(res => {
                    this.driverGroupsItems = res;
                    this.loadingGroups = false;
                });
    
                this.driverGroupsService.getUserItemsById(id).subscribe(res => {
                    this.userItems = res;
                    this.loadingUsers = false;
                });
            }, error => {
                this.error = error;
                this.error.statusText = 'Error fetching driverGroup';
                this.loading = false;
            });
        });
    }

    // Form
    onDelete() {
        this.loading = true;

        this.driverGroupsService.deleteDriverGroup(this.driverGroup).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Driver group is successfully deleted.'
            };

            setTimeout(() => {
                this.router.navigate(['/DriverGroups/Overview']);
            }, 3000);
        }, error => {
            this.error = error
            this.getDriverGroupInfo();
        });
    }

    onSave() {
        this.saving = true;

        this.driverGroup.items = this.confirmed.map((driver) => +driver.id);
        this.driverGroupsService.updateDriverGroup(this.driverGroup).subscribe(result => {
            this.saving = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Driver group is successfully updated.'
            };

            this.setFormMode(FormMode.read);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getDriverGroupInfo();
        } else {
            if (this.driverGroup.accountId) {
                this.loadSelector();
            } else {
                console.log('Timing error ');
            }
        }
    }

    loadSelector() {
        this.loadingDrivers = true;

        this.source = this.driverGroupsItems;
        this.confirmed = this.driverGroupsItems;

        if (+this.driverGroup.accountId !== 0) {
            this.driverService.getDriversByAccount(this.driverGroup.accountId).subscribe(result => {
                this.source = result;
                this.loadingDrivers = false;

                this.cd.markForCheck();
            });
        }
    }

    getDriverGroupInfo() {
        if (this.loading !== true) {
            this.loading = true;
            this.cd.markForCheck();
        }

        this.driverGroupsService.getDriverGroupById(this.driverGroup.id).subscribe(driverGroup => {
            this.driverGroup = driverGroup;
            this.loading = false;

            this.cd.markForCheck();
        });
    }
}
