<ng-container *ngIf="permissions['Platform_IsAdministrator'] || !isUserConnected">
    <a aria-expanded="false" role="button" dropdownToggle>
        <span *ngIf="batches.length === 1"><i class="fa fa-sync fa-spin"></i> <span class="hidden-sm hidden-xs">{{
                'general.processing' | translate}}</span> {{ batches[0].percentage }} %
            <span class="caret" style="margin-left:-5px"></span>
        </span>
        <span *ngIf="batches.length > 1"><i class="fa fa-sync fa-spin"></i> <span class="hidden-sm hidden-xs">{{
                'general.processing' | translate}} </span> {{ batches.length }} <span class="hidden-sm hidden-xs">{{
                'general.batches' | translate}} </span>
            <span class="caret" style="margin-left:-5px"></span>
        </span>
        <span *ngIf="batches.length == 0"><i class="fa fa-link"
                [ngClass]="{ 'error': isUserConnected == false || !connection?.connectionId || !isConntected }"></i>
            <span class="hidden"> Hub: {{ hubUserStatusMessage }}</span>
            <span class="caret" style="margin-left:-5px"></span>
        </span>
    </a>
    <ul role="menu" *dropdownMenu class="dropdown-menu">
        <li><a><i class="fa fa-link"></i> {{ 'general.connection' | translate}}: {{ connection?.connectionId }}</a></li>
        <li><a><i class="fa fa-users"></i> {{ 'general.user' | translate}}: {{ hubUserStatusMessage }}</a></li>
        <li><a (click)="sendTests()"><i class="fa fa-users"></i> {{'general.test'
                |translate }}</a></li>
        <!-- <li><a (click)="sendTest()"><i class="fa fa-users"></i> Direct: {{ hubStatusMessage }} | {{ 'general.test'
                    |translate }}</a></li> -->
        <li><a *ngIf="isUserConnected == false || !connection?.connectionId || !isConntected" (click)="reconnect()"><i
                    class="fa fa-link"></i> {{ 'general.reconnect' |
                translate }}</a></li>
        <li *ngFor="let batch of batches;"><a>{{ batch.id }} | {{ batch.percentage }}%</a></li>
    </ul>
</ng-container>

<ng-container *ngIf="!permissions['Platform_IsAdministrator'] && batches.length > 0">
    <a aria-expanded="false" role="button" dropdownToggle>
        <span *ngIf="batches.length === 1"><i class="fa fa-sync fa-spin"></i> <span class="hidden-sm hidden-xs">{{
                'general.processing' | translate}}</span> {{ batches[0].percentage }} %
            <span class="caret"></span>
        </span>
        <span *ngIf="batches.length > 1"><i class="fa fa-sync fa-spin"></i> <span class="hidden-sm hidden-xs">{{
                'general.processing' | translate}} </span> {{ batches.length }} <span class="hidden-sm hidden-xs">{{
                'general.batches' | translate}} </span>
            <span class="caret"></span>
        </span>
        &nbsp;
    </a>
    <ul role="menu" *dropdownMenu class="dropdown-menu">
        <li *ngFor="let batch of batches;"><a>{{ batch.id }} | {{ batch.percentage }}%</a></li>
    </ul>
</ng-container>