<!-- hour -->
<select name="hours" class="timeFormControl" (change)="change.emit()" [(ngModel)]="time.hours" [disabled]="disabled"
  [ngClass]="selectClass">
  <option *ngFor="let hour of hours" [ngValue]="hour">{{hour | number:'2.0'}}</option>
</select>

<!-- minute -->
<select name="minutes" class="timeFormControl" (change)="change.emit()" [(ngModel)]="time.minutes" [disabled]="disabled"
  [ngClass]="selectClass">
  <option *ngFor="let minute of minutes" [ngValue]="minute">{{minute | number:'2.0'}}</option>
</select>

<!-- second -->
<select name="seconds" class="timeFormControl" (change)="change.emit()" [(ngModel)]="time.seconds" [disabled]="disabled"
  *ngIf="!hideSeconds" [ngClass]="selectClass">
  <option *ngFor="let second of seconds" [ngValue]="second">{{second | number:'2.0'}}</option>
</select>

<!-- am/pm -->
<select name="timer" class="timeFormControl" (change)="change.emit()" [(ngModel)]="time.hourTypes" [disabled]="disabled"
  *ngIf="!use24HourTime" [ngClass]="selectClass">
  <option *ngFor="let hourType of hourTypes" [ngValue]="hourType">{{hourType}}</option>
</select>