import { Component, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'fh-batch-delete',
    templateUrl: 'delete.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchDeleteComponent {
    step: number;
    @Input() gridSelection = [];
    @Output() onFinish = new EventEmitter();
    disabled = true;
    logItem;

    assetForm = new FormGroup({
        checkbox: new FormControl('', Validators.required),
    });

    constructor(private cd: ChangeDetectorRef) {
        this.step = 1;
    }

    initFinish() {
        // Fetch all data and make sure it can be saved
        console.log(1);
    }

    onCheckOutFinish() {
        this.onFinish.emit({ status: 'success', object: {}, log: this.logItem })
        this.step = 1;
    }

    initForm() {
        this.step = 1;
        console.log('init tab');
    }
}
