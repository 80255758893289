import { Component, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { DeviceService } from 'app/services/device/device.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ResellerService } from 'app/services/resellers/resellers.service';
import { AccountService } from 'app/services/account/account.service';
@Component({
    selector: 'fh-batch-move-to-client',
    templateUrl: 'moveToClient.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchMoveToClientComponent implements OnChanges, OnInit {
    step: number;

    @Input() gridSelection = [];
    @Output() onFinish = new EventEmitter();
    disabled = true;
    logItem;

    selectedReseller = undefined;
    loadingResellers = false;
    loadingAccounts = false;

    groupForm = new FormGroup({
        selectedReseller: new FormControl(null, Validators.required),
        selectedAccount: new FormControl(null, Validators.required),
    });

    deviceInfo = {};

    selectedAccountName = '';

    selectedResellerId;

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    constructor(private resellerService: ResellerService, private cd: ChangeDetectorRef, private accountService: AccountService, private deviceService: DeviceService) {
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.step = 1;
    }

    ngOnInit(): void {

        console.log('init');
        this.step = 1;
        this.cd.markForCheck();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('changes');
        this.step = 1;
        this.cd.markForCheck();
    }

    initFinish() {
        // Fetch all data and make sure it can be saved
        console.log(1);
        console.log(this.groupForm.value);
    }

    onCheckOutFinish() {
        this.onFinish.emit({ status: 'success', object: this.groupForm.value, log: this.logItem })
        this.step = 1;
    }

    initForm() {
        console.log('init tab');
        this.step = 1;

        this.deviceService.getDeviceData(this.gridSelection).subscribe(devices => {
            devices.forEach(item => {
                this.deviceInfo[item.id] = item;
            });
        });
    }

    resellerChanged(resellerId) {

        this.groupForm.patchValue({
            selectedAccount: undefined,
        });

        this.selectedResellerId = resellerId;

        setTimeout(() => {
            this.cd.markForCheck();
        }, 100);
    }

    accountChanged(accountId) {
        this.selectedAccountName = this.accountService.accounts.find(x => x.id === accountId)?.name;
    }
}
