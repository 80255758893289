<form #ruleForm="ngForm">

    <div class="ibox-title">
        <i class="fas fa-fw fa-ruler"></i> {{ 'general.addRule' | translate }}
    </div>
    <div class="ibox-content slick" style="position:relative;" *ngIf="triggerRules.length > 0">
        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
            <tbody>
                <tr>
                    <td class="row_opening"> {{ 'general.triggerRule' | translate }}
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select name="ruleType" ngDefaultControl [searchable]="false" [clearable]="false"
                            placeholder="{{ 'general.chooseTriggerRule' | translate}} ({{triggerRules.length}})"
                            [(ngModel)]="rule.ruleType" #ruleTypeVar="ngModel" class="form-control table-inline"
                            (change)="onRuleTypeChanged($event)" style="width:100%">
                            <ng-option *ngFor="let triggerRule of triggerRules | orderBy : 'name':false"
                                [ngSwitch]="triggerRule.value" [value]="triggerRule.value">
                                <span *ngSwitchCase="allTriggerRules.DeviceSensor"><i
                                        class="fas fa-fw fa-sensor"></i></span>
                                <span *ngSwitchCase="allTriggerRules.RoadSpeed"><i
                                        class="fas fa-fw fa-gauge-max"></i></span>
                                <span *ngSwitchCase="allTriggerRules.Geofences"><i
                                        class="fas fa-fw fa-draw-polygon"></i></span>
                                <span *ngSwitchCase="allTriggerRules.Schedule"><i
                                        class="fas fa-fw fa-calendar"></i></span>
                                <span *ngSwitchCase="allTriggerRules.DeviceOutsideSpecs"><i
                                        class="fas fa-sensor-triangle-exclamation"></i></span>
                                <span *ngSwitchCase="allTriggerRules.DeviceState"><i
                                        class="fas fa-fw fa-location-dot"></i></span>
                                <span *ngSwitchCase="allTriggerRules.IOLogical"><i
                                        class="fas fa-fw fa-light-switch-off"></i></span>
                                <span *ngSwitchCase="allTriggerRules.InputOutputPorts"><i
                                        class="fas fa-fw fa-inbox-in"></i></span>
                                <span *ngSwitchCase="allTriggerRules.VirtualSensor"><i
                                        class="fas fa-fw fa-sensor"></i></span>
                                <span *ngSwitchDefault><i class="fas fa-fw fa-flag"></i> </span>
                                <span *ngSwitchCase="allTriggerRules.AvgDeviationDetection"><i
                                    class="fas fa-fw fa-sensor"></i> </span>
                                <span *ngSwitchCase="allTriggerRules.NoData"><i
                                    class="fas fa-fw fa-sensor"></i> </span>
                                {{ triggerRule.name }}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="ibox-content slick" style="position:relative;" *ngIf="rule.ruleType">
        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">

            <!--AVG Deviation detection -->
            <tbody *ngIf="rule.ruleType == 13">
                <tr>
                    <td class="row_opening"> {{ 'general.sensor' | translate}}
                        <span class="error" *ngIf="rulePropertyVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select class="form-control table-inline" name="deviceState" [clearable]="false"
                            placeholder="{{ 'general.chooseSensor' | translate }}" #rulePropertyVar="ngModel"
                            [(ngModel)]="rule.ruleProperty" required style="width:100%">
                            <ng-option [value]=3>
                                <i class="fa fa-fw fa-weight"></i> {{ 'enums.ruleProperty.3' | translate}}
                            </ng-option>
                            <ng-option [value]=4 [disabled]="true">
                                <i class="fas fa-fw fa-thermometer-three-quarters"></i> {{ 'enums.ruleProperty.4' |
                                translate}}
                            </ng-option>
                            <ng-option [value]=5 [disabled]="true">
                                <i class="fas fa-fw fa-thermometer-three-quarters"></i> {{ 'enums.ruleProperty.5' |
                                translate}}
                            </ng-option>
                            <ng-option [value]=6 [disabled]="true">
                                <i class="fa fa-fw fa-humidity"></i> {{ 'enums.ruleProperty.6' | translate}}
                            </ng-option>
                            <ng-option [value]=7 [disabled]="true">
                                <i class="fa fa-fw fa-humidity"></i> {{ 'enums.ruleProperty.7' | translate}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening"> {{ 'general.value' | translate}} %
                        <span class="error" *ngIf="valueVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <input type="number" [readonly]="formMode == 1" #valueVar="ngModel" name="minSpeed" required
                            class="form-control table-inline" placeholder="{{ 'placeholder.value' | translate}}"
                            [(ngModel)]="rule.threshold">
                    </td>
                </tr>
            </tbody>

            <!-- Simple value compare -->
            <tbody
                *ngIf="rule.ruleType == 9 || rule.ruleType == 10 || rule.ruleType == 11 || rule.ruleType == 12 || rule.ruleType == 14">
                <tr>
                    <td class="row_opening"> {{ 'general.value' | translate}}
                        <span class="error" *ngIf="valueCompare?.invalid || valueVar?.invalid">*</span>
                    </td>
                    <td class="row_content" colspan="2" style="overflow: visible;">
                        <span style="width:70px; float:left">
                            <ng-select class="form-control table-inline" name="valueCompare" [clearable]="false"
                                placeholder="{{ 'general.valueCompare' | translate }}" #valueCompare="ngModel"
                                [(ngModel)]="rule.thresholdCompare" required style="width:70px">
                                <ng-option [value]=1>
                                    <i class="fas fa-fw fa-greater-than"></i>
                                </ng-option>
                                <ng-option [value]=2>
                                    <i class="fas fa-fw fa-less-than"></i>
                                </ng-option>
                            </ng-select>
                        </span>
                        <span style="width:calc(100% - 70px); float:left">
                            <input type="number" [readonly]="formMode == 1" #valueVar="ngModel" name="minSpeed" required
                                class="form-control table-inline" placeholder="{{ 'placeholder.value' | translate}}"
                                [(ngModel)]="rule.threshold"></span>
                    </td>
                </tr>
            </tbody>

            <!-- DeviceProperty -->
            <tbody *ngIf="rule.ruleType == 17">
                <tr>
                    <td class="row_opening"> {{ 'general.sensor' | translate}}
                        <span class="error" *ngIf="triggerDeviceSensorVar?.invalid">*</span>
                    </td>
                    <td class="row_content" colspan="2" style="overflow: visible;">
                        <ng-select name="triggerDeviceSensor" ngDefaultControl [searchable]="false" [clearable]="false"
                            placeholder="{{ 'general.chooseSensor' | translate}} ({{triggerDeviceSensors.length}})"
                            [(ngModel)]="rule.triggerDeviceSensor" #triggerDeviceSensorVar="ngModel"
                            class="form-control table-inline" style="width:100%" required>
                            <ng-option *ngFor="let triggerDeviceSensor of triggerDeviceSensors | orderBy : 'name':false"
                                [value]="triggerDeviceSensor.value">
                                {{triggerDeviceSensor.name}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening"> {{ 'general.value' | translate}}
                        <span class="error" *ngIf="valueCompare?.invalid || valueVar?.invalid">*</span>
                    </td>
                    <td class="row_content" colspan="2" style="overflow: visible;">
                        <span style="width:70px; float:left">
                            <ng-select class="form-control table-inline" name="valueCompare" [clearable]="false"
                                placeholder="{{ 'general.valueCompare' | translate }}" #valueCompare="ngModel"
                                [(ngModel)]="rule.thresholdCompare" required style="width:70px">
                                <ng-option [value]=1>
                                    <i class="fas fa-fw fa-greater-than"></i>
                                </ng-option>
                                <ng-option [value]=2>
                                    <i class="fas fa-fw fa-less-than"></i>
                                </ng-option>
                            </ng-select>
                        </span>
                        <span style="width:calc(100% - 70px); float:left">
                            <input type="number" [readonly]="formMode == 1" #valueVar="ngModel" name="minSpeed" required
                                class="form-control table-inline" placeholder="{{ 'placeholder.value' | translate}}"
                                [(ngModel)]="rule.threshold"></span>
                    </td>
                </tr>
            </tbody>

            <!-- NoData -->
            <tbody *ngIf="rule.ruleType == 18">
                <tr>
                    <td class="row_opening"> {{ 'general.sensor' | translate}}
                        <span class="error" *ngIf="triggerDeviceSensorVar?.invalid">*</span>
                    </td>
                    <td class="row_content" colspan="2" style="overflow: visible;">
                        <ng-select name="triggerDeviceSensor" ngDefaultControl [searchable]="false" [clearable]="false"
                            placeholder="{{ 'general.chooseSensor' | translate}} ({{triggerDeviceSensors.length}})"
                            [(ngModel)]="rule.triggerDeviceSensor" #triggerDeviceSensorVar="ngModel"
                            class="form-control table-inline" style="width:100%" required>
                            <ng-option *ngFor="let triggerDeviceSensor of triggerDeviceSensors | orderBy : 'name':false"
                                [value]="triggerDeviceSensor.value">
                                {{triggerDeviceSensor.name}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
            </tbody>
            
            <!-- RoadSpeed -->
            <tbody *ngIf="rule.ruleType == 16">
                <tr>
                    <td class="row_opening"> {{ 'general.deviation' | translate}} %
                        <span class="error" *ngIf="valueVar?.invalid">*</span>
                    </td>
                    <td class="row_content" colspan="2" style="overflow: visible;">
                        <span style="width:70px; float:left">
                            <ng-select class="form-control table-inline" name="valueCompare" [clearable]="false"
                                placeholder="{{ 'general.valueCompare' | translate }}" #valueCompare="ngModel"
                                [(ngModel)]="rule.thresholdCompare" required style="width:70px">
                                <ng-option [value]=1>
                                    <i class="fas fa-fw fa-greater-than"></i>
                                </ng-option>
                                <ng-option [value]=2>
                                    <i class="fas fa-fw fa-less-than"></i>
                                </ng-option>
                            </ng-select>
                        </span>
                        <span style="width:calc(100% - 70px); float:left">
                            <input type="number" [readonly]="formMode == 1" #valueVar="ngModel" name="minSpeed" required
                                class="form-control table-inline" placeholder="{{ 'placeholder.value' | translate}}"
                                [(ngModel)]="rule.threshold"></span>
                    </td>
                </tr>
            </tbody>

            <!-- DeviceState -->
            <tbody *ngIf="rule.ruleType == 2">
                <tr>
                    <td class="row_opening"> {{ 'general.deviceState' | translate}}
                        <span class="error" *ngIf="deviceStateVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select class="form-control table-inline" name="deviceState" [clearable]="false"
                            placeholder="{{ 'general.chooseDeviceState' | translate }}" #deviceStateVar="ngModel"
                            [(ngModel)]="rule.deviceState" required style="width:100%">
                            <ng-option [value]=1>
                                <i class="fa fa-fw fa-map-marker-alt eye-active green"></i>
                                {{ 'enums.deviceStateProperties.1' | translate}}
                            </ng-option>
                            <ng-option [value]=2>
                                <i class="fa fa-fw fa-map-marker-alt eye-active black"></i>
                                {{ 'enums.deviceStateProperties.2' | translate}}
                            </ng-option>
                            <ng-option [value]=3>
                                <i class="fa fa-fw fa-map-marker-alt eye-active orange"></i>
                                {{ 'enums.deviceStateProperties.3' | translate}}
                            </ng-option>
                            <ng-option [value]=4>
                                <i class="fa fa-fw fa-map-marker-alt eye-active blue"></i>
                                {{ 'enums.deviceStateProperties.4' | translate}}
                            </ng-option>
                            <ng-option [value]=5>
                                <i class="fa fa-fw fa-map-marker-alt eye-active purple"></i>
                                {{ 'enums.deviceStateProperties.5' | translate}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
            </tbody>

            <!-- Virtual sensors -->
            <tbody *ngIf="rule.ruleType == 15">
                <tr>
                    <td class="row_opening"> {{ 'general.virtualSensors' | translate}}
                        <span class="error" *ngIf="virtualSensorOutputTypeVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select name="logicalIo" ngDefaultControl [searchable]="false" [clearable]="false"
                            placeholder="{{ 'general.chooseEventType' | translate}} ({{virtualSensorOutputTypes.length}})"
                            [(ngModel)]="rule.virtualSensorOutputType" #virtualSensorOutputTypeVar="ngModel"
                            class="form-control table-inline" style="width:100%" required>
                            <ng-option *ngFor="let virtualSensorOutputType of virtualSensorOutputTypes"
                                [value]="virtualSensorOutputType.value">
                                {{virtualSensorOutputType.name}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
            </tbody>

            <!-- Geofences -->
            <tbody *ngIf="rule.ruleType == 3">
                <tr>
                    <td class="row_opening"> {{ 'general.insideOutsideSettings' | translate}}
                        <span class="error" *ngIf="eventTypeVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select name="insideOutside" ngDefaultControl [searchable]="false" [clearable]="false"
                            [(ngModel)]="rule.insideOutsideSettings" #eventTypeVar="ngModel"
                            class="form-control table-inline" style="width:100%" required>
                            <ng-option *ngFor="let insideOutside of insideOutsideSettings"
                                [value]="insideOutside.value">
                                {{insideOutside.name | translate}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">
                        <span *ngIf="rule.insideOutsideSettings === 1"> {{ 'general.anyGeofence' | translate}}</span>
                        <span *ngIf="rule.insideOutsideSettings !== 1"> {{ 'general.allGeofences' | translate}}</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <div class="fa_checkbox">
                            <input [checked]="true" [disabled]="formMode == 1" id="allGeofences" type="checkbox"
                                name="allGeofences" [(ngModel)]="rule.allGeofences" />
                            <label for="allGeofences">
                            </label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening"> {{ 'general.geofences' | translate}}
                        <i class="fa fa-fw fa-draw-polygon" tooltip="{{'general.geofences' | translate }}"
                            [routerLink]="['/Geofences/Overview/']"></i>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <fh-geofence-group-item-input name="geofenceGroups" #geofenceGroupVar="ngModel" required
                            [selectedAccountId]="selectedAccountId" [readonly]="rule.allGeofences"
                            placeholder="{{ (selectedAccountId? 'general.chooseGeofenceGroups' : 'general.firstChooseAccount') | translate}}"
                            [(ngModel)]="rule.geofenceSource">
                        </fh-geofence-group-item-input>
                    </td>
                </tr>
            </tbody>

            <!-- Input/Output Ports -->
            <tbody *ngIf="rule.ruleType == 4">
                <tr>
                    <td class="row_opening"> {{ 'general.port' | translate}}
                        <span class="error" *ngIf="eventTypeVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select name="port" ngDefaultControl [searchable]="false" [clearable]="true"
                            placeholder="{{ 'general.choosePort' | translate}} ({{ports.length}})"
                            [(ngModel)]="rule.ioPort" #eventTypeVar="ngModel" class="form-control table-inline"
                            style="width:100%" required>
                            <ng-option *ngFor="let port of ports" [value]="port.value">
                                <i class="fas fa-fw fa-arrow-alt-circle-up"></i> {{port.name}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
            </tbody>

            <!-- IO Logical -->
            <tbody *ngIf="rule.ruleType == 5">
                <tr>
                    <td class="row_opening"> {{ 'general.io' | translate}}
                        <span class="error" *ngIf="eventTypeVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select name="logicalIo" ngDefaultControl [searchable]="false" [clearable]="false"
                            placeholder="{{ 'general.chooseEventType' | translate}} ({{eventTypesLogical.length}})"
                            [(ngModel)]="rule.eventType" #eventTypeVar="ngModel" class="form-control table-inline"
                            style="width:100%" required>
                            <ng-option *ngFor="let eventType of eventTypesLogical" [value]="eventType.value">
                                <i class="fas fa-fw fa-traffic-light-stop"></i> {{eventType.name}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
            </tbody>

            <!-- Events -->
            <tbody *ngIf="rule.ruleType == 6">
                <tr>
                    <td class="row_opening"> {{ 'general.event' | translate}}
                        <span class="error" *ngIf="eventTypeVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select name="eventType" ngDefaultControl [searchable]="false" [clearable]="false"
                            placeholder="{{ 'general.chooseEvent' | translate}} ({{eventTypes.length}})"
                            [(ngModel)]="rule.eventType" #eventTypeVar="ngModel" class="form-control table-inline"
                            style="width:100%" required>
                            <ng-option *ngFor="let eventType of eventTypes" [value]="eventType.value">
                                <i class="fas fa-fw fa-traffic-light-stop"></i> {{eventType.name}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
            </tbody>

            <!-- Inside/Outside Time schedule -->
            <tbody *ngIf="rule.ruleType == 7">
                <tr>
                    <td class="row_opening"> {{ 'general.triggerShedule' | translate}}
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <fh-schedule-input name="triggerSchedules" #triggerSchedueVar="ngModel" required
                            [selectedAccountId]="selectedAccountId"
                            placeholder="{{ (selectedAccountId? 'general.chooseTriggerSchedule' : 'general.firstChooseAccount') | translate}}"
                            [(ngModel)]="rule.templateId">
                        </fh-schedule-input>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening"> {{ 'general.insideOutsideSettings' | translate}}
                        <span class="error" *ngIf="eventTypeVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select name="insideOutside" ngDefaultControl [searchable]="false" [clearable]="false"
                            [(ngModel)]="rule.insideOutsideSettings" #eventTypeVar="ngModel"
                            class="form-control table-inline" style="width:100%" required>
                            <ng-option *ngFor="let insideOutside of insideOutsideSettings"
                                [value]="insideOutside.value">
                                {{insideOutside.name | translate}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- State -->
    <div class="ibox-content slick" style="position:relative;"
        *ngIf="rule.ruleType && (rule.ruleType == 2 || rule.ruleType == 4 || rule.ruleType == 5 || rule.ruleType == 15)">
        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
            <tbody>
                <tr>
                    <td class="row_opening"> {{ 'general.condition' | translate}}
                        <span class="error" *ngIf="eventTypeVar?.invalid">*</span>
                    </td>
                    <td class="row_content" style="overflow: visible;">
                        <ng-select name="condition" ngDefaultControl [searchable]="false" [clearable]="false"
                            placeholder="{{ 'general.chooseCondition' | translate}} ({{conditions.length}})"
                            [(ngModel)]="rule.ioState" #eventTypeVar="ngModel" class="form-control table-inline"
                            style="width:100%" required>
                            <ng-option *ngFor="let condition of conditions" [value]="condition.value">
                                {{condition.name}}
                            </ng-option>
                        </ng-select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="ibox-footer">
        <button type="button" name="addBtn" (click)="addRule()" class="btn btn-primary"
            [disabled]="ruleForm.invalid && !rule.allGeofences"><i class="fa fa-fw fa-save"></i> {{'general.save' |
            translate}}</button>
        <button type="button" class="btn btn-info" (click)="cancel()"><i class="fa fa-fw fa-times"></i>
            {{'general.cancel' | translate }}</button>
    </div>
</form>