import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notification } from '../../models';

@Component({
    selector: 'fh-notify-notification',
    templateUrl: './notification.component.html',
    styleUrls: [
        './../../notifications.styles.css'
    ]
})
export class NotificationComponent {
    @Input() notification: Notification;
    @Output() dismissNotify = new EventEmitter();

    constructor() {
        setTimeout(result => { this.dismiss() }, 5000);
    }

    dismiss() {
        this.dismissNotify.emit(this.notification);
    }
}
