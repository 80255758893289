import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';
import { AuditLog } from '../../models/auditlog.model';
import { Device } from '../../models/device.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { UserService } from '../users/user.service';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import * as moment from 'moment-timezone';
import { generateEntityDetailLink } from 'app/common/globals';
import { EntityType } from 'app/common/enums';
import { AssetService } from '../asset/asset.service';
window['moment'] = Moment;
mTZ()
@Injectable()
export class AuditLogService {
    token: string;
    timestamp;
    url = '';
    urlBase = '';
    timezoneIana: string;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService, private userService: UserService, private assetService: AssetService) {
        this.url = authenticationService.getWebserviceURL('auditlog');
        this.urlBase = authenticationService.getWebserviceURL('');
        this.timezoneIana = authenticationService.getTimeZoneIana();
    }

    getPagingUrl(start, end) {
        return this.url + 'Paging?startRange=' + start.unix() + '&endRange=' + end.unix();
    }

    getAllAuditLogsForAccount(id: string, start = null, end = null): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.url + '?accountId=' + id + '&start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogById(id: string): Observable<AuditLog> {
        console.log('getting auditLog from service');
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponseDetails(data, true);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByDevice(id: string): Observable<AuditLog[]> {
        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'device/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByDriver(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'driver/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByDriverGroup(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'driverGroup/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByTrailer(id: string): Observable<AuditLog[]> {
        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'trailer/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByReportSubscription(id: string): Observable<AuditLog[]> {
        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'report/subscription/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByTrigger(id: string): Observable<AuditLog[]> {
        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'trigger/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByProject(id: string): Observable<AuditLog[]> {
        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'project/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByGeofence(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'geofence/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByGeofenceGroup(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'geofenceGroup/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByMaintenance(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'maintenance/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByMaintenanceType(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'maintenanceType/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByAccount(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'account/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByReseller(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'reseller/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByWarehouse(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'warehouse/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByAsset(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'asset/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByAssetGroup(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'assetGroup/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByAssetType(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'assetType/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByUser(id: string): Observable<AuditLog[]> {
        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'user/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAuditLogByCustomer(id: string): Observable<AuditLog[]> {

        console.log('getting auditlog from service');
        return this.http.get(this.urlBase + 'customer/' + id + '/auditlog', { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        return throwError(error);
    }

    parseResponse(json: any): AuditLog[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Logitems.');
        const that = this;

        const ident = 1;
        const auditlogs: AuditLog[] = [];

        json.forEach(item => {
            const log = this.parseResponseDetails(item);
            auditlogs.push(log);
        });

        this.timestamp = new Date().getTime();
        return auditlogs;
    }

    parseResponseDetails(item, isDetailsView = false) {
        const log = new AuditLog();
        log.id = item.id;
        log.logType = item.logType;
        log.timestamp = moment.utc(item.createdDate)['tz'](this.timezoneIana);
        log.createdDate = moment.utc(item.createdDate)['tz'](this.timezoneIana);
        log.description = item.description;
        log.delta = item.delta;
        if (item.delta) {
            log.deltaItems = JSON.parse(item.delta);
        }
        log.user = item.userName;
        log.userId = item.userId;

        if (item.device) {
            const device = new Device();
            device.name = item.device.name;
            device.id = item.device.id;
            log.device = device;
        }

        log.accountId = item.accountId;
        log.companyName = item.companyName;

        log.entityId = item.entityId;
        log.entityType = item.entityType;

        log.link = generateEntityDetailLink(item.entityType, item.entityId);

        return log;
    }
}
