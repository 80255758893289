<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="geofence?.accountId">
                <a href="/#/AccountDetails/Index/{{geofence?.accountId}}">{{geofence?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Geofences/Overview">{{'general.geofences' | translate }}</a>
            </li>
            <li>
                <a href="/#/GeofenceDetails/Index/{{geofence?.id}}">{{geofence?.name}}</a>
            </li>
            <li class="active">{{'general.dashboard' | translate }}</li>
        </ol>
    </div>
    <fh-geofence-details-navigation [geofenceId]="geofenceId"></fh-geofence-details-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0;">
        <ngx-loading [show]="loading"></ngx-loading>
        <fh-leaflet-map (mapReady)="onMapReady($event)" [editableGeofence]="geofence" [height]="mapHeight"
            [allowEdit]="allowEdit" [showSearch]=true [showGeofenceSwitch]=false [allowBookmarks]="allowBookmarks"
            [locations]="locations" (onSave)="onSaveShape($event)">
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="" *ngIf="error || success || warning">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">

                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-fw fa-draw-polygon"></i> {{'general.geofence' | translate }}
                            </h5>
                        </div>
                        <fh-geofence-details [geofence]="geofence" [formMode]="formMode" [loading]="loading || saving">
                        </fh-geofence-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                            [allowEdit]="permissions['Geofences_Edit']" [allowDelete]="permissions['Geofences_Delete']"
                            (setFormMode)="setFormMode($event)" [formMode]="formMode">
                            <span class="pull-right">
                                <fh-task-dropdown (onFinishAction)="finishTask($event)" #taskDropdown
                                    [entityTypes]="tagType" [entity]="geofence"></fh-task-dropdown>
                            </span>
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">

                <div class="ibox" *ngIf="showMapOnSide">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <fh-leaflet-map (mapReady)="onMapReady($event)" [editableGeofence]="geofence" [height]="mapHeight"
                        [allowEdit]="allowEdit" [showSearch]=true [showGeofenceSwitch]=false
                        [allowBookmarks]="allowBookmarks" [locations]="locations" (onSave)="onSaveShape($event)">
                    </fh-leaflet-map>
                </div>

                <fh-group-overview [loading]="loadingGroups" [groups]="geofenceGroups"
                    name="{{'general.geofenceGroups' | translate }}" [deletePermission]="'Geofences_Edit'"
                    [linkUrl]="geofenceGroupLink" [childId]="geofence?.id" (onFinish)="getGeofenceInfo()">
                    <span groupAddSlot (click)="confirmationModal.showModal(geofence.id)"
                        [title]="'general.addToGroup' | translate" container="body">
                        <button class="btn btn-primary">
                            <i class="fa fa-fw fa-add"></i> {{ 'general.assign' | translate}}
                        </button>
                    </span>
                </fh-group-overview>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-microchip"></i> {{'general.devicesInside' | translate }}
                        </h5>
                        <span class="pull-right">
                            <div class="filter-form hidden-sm hidden-xs">
                                <input type="text" class="form-control table-inline top-filter hidden-sm hidden-xs"
                                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y:hidden">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <table class="table table-fixed nowrap dtr-inline no-footer"
                            style="width:100%; margin-bottom:0px ">
                            <thead>
                                <tr>
                                    <th>{{'general.name' | translate }}</th>
                                    <th> {{ 'general.enteredTimestamp' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ngx-loading [show]="loadingDevices"></ngx-loading>
                                <ng-container
                                    *ngFor="let device of devices | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage, id: 'first'}">
                                    <tr>
                                        <td class="elipses_overflow">
                                            <a href="/#/DeviceDetails/Index/{{device.id}}"><span
                                                    class="secondary link_bolder"><i
                                                        class="fas fa-fw fa-angle-right"></i></span> {{
                                                device.name }}</a>
                                        </td>
                                        <td class="elipses_overflow" title="{{device?.rtcBasedTimestamp}}">
                                            {{ device?.rtcBasedTimestamp | amTimeAgo}}
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr *ngIf="devices.length === 0">
                                    <td colspan="2">
                                        {{'general.nodatafound' | translate }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="first">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <fh-tagging [objectId]="geofence?.id?.toString()" [objectType]="tagType">
                </fh-tagging>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal #confirmationModal (onFinish)="addToGroup($event, confirmationModal, groupInputVar)" [invalid]="!(groupInputVar.value?.length > 0) || groupInputVar.loadingGeofenceGroups">
    <h4 header class="modal-title pull-left"><i class="fa fa-fw fa-bolt"></i> {{'general.action' | translate }}: {{ 'general.addToGroup' | translate }}</h4>
        <form body #groupForm="ngForm" style="width: 100%; border-top: 0px;">
            <div class="modal-body">
                <fh-geofence-group-input #groupInputVar ngDefaultControl required [selectedAccountId]="geofence?.accountId" [canAddNew]="true"
                    [hideSystemGroups]="true" [autoSelect]="false" placeholder="{{ 'general.chooseGeofenceGroups' | translate }}">
                </fh-geofence-group-input>
            </div>
        </form>
</fh-confirmation-modal>