<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Fuel/Overview">{{'general.fuel' | translate }}</a>
            </li>
            <li class="active">{{'general.dashboard' | translate }}</li>
        </ol>
    </div>
    <fh-fuel-navigation></fh-fuel-navigation>
</div>


<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class='col-lg-12'>
            <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>

            <!-- Custom filters -->
            <div class="ibox" [hidden]="!showFilter">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content slick_toppadding" style="position: relative;">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="col-lg-4">
                        <div class="form-group input-group" style="margin-bottom: 6px; display:block">
                            <div style="width: 100%; float:left;">
                                <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                    (onChanged)="resellerChanged($event)" required
                                    placeholder="{{ 'general.chooseReseller' | translate}}"
                                    [(ngModel)]="selectedResellerId">
                                </fh-reseller-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group input-group" style="margin-bottom: 6px; display:block">
                            <fh-account-input name="account" #accountVar="ngModel" (onChanged)="accountChanged($event)"
                                required [selectedResellerId]="selectedResellerId" [showEmptyAccountsButton]="false"
                                placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                [(ngModel)]="selectedAccountId">
                            </fh-account-input>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group input-group" style="margin-bottom: 0px; display:block">
                            <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker" type="text"
                                [bsConfig]="dpConfig" class="form-control" bsDaterangepicker
                                (onHidden)="dateChanged('hidden')" [(ngModel)]="daterangepickerModel">
                            <span class="input-group-calendar-datatables">
                                <i class="fa fa-calendar-alt"></i>
                            </span>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                    <div style="clear:both"></div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px; display:block">
                            <fh-asset-group-input name="assetGroups" #assetGroupVar="ngModel" required
                                [selectedAccountId]="selectedAccountId" [tableInline]="false"
                                (onChanged)="dateChanged(true)" [multiple]="false" [autoSelect]="false"
                                placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                                [(ngModel)]="selectedAssetGroup">
                            </fh-asset-group-input>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px; display:block">
                            <ng-select placeholder="{{ 'general.chooseVehicleType' | translate}}" [clearable]="true"
                                class="form-control" name="vehicleType" required #vehicleType="ngModel"
                                (ngModelChange)="dateChanged(true)" [(ngModel)]="selectedVehicleType"
                                style="width:100%">
                                <ng-option *ngFor="let vehicleType of vehicleTypes" [value]="vehicleType.id">
                                    {{ ('enums.vehicleType.' + vehicleType.id) | translate }}
                                </ng-option>
                            </ng-select>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px; display:block">
                            <fh-asset-type-input name="assetTypes" #assetTypeVar [selectedAccountId]="selectedAccountId"
                                [tableInline]="false" (onChanged)="dateChanged(true)" [multiple]="false"
                                [autoSelect]="false"
                                placeholder="{{ (selectedAccountId? 'general.chooseAssetTypes' : 'general.firstChooseAccount') | translate}}"
                                [(ngModel)]="selectedAssetType">
                            </fh-asset-type-input>
                        </div>
                    </div>
                    <div style="clear:both"></div>
                    <div class="col-lg-4">
                        <div class="form-group" style="margin-bottom: 6px;">
                            <fh-project-input name="project" #projectVar [selectedAccountId]="selectedAccountId"
                                [tableInline]="false" (onChanged)="dateChanged(true)" [multiple]="false"
                                [autoSelect]="false"
                                placeholder="{{ (selectedAccountId? 'general.chooseProject' : 'general.firstChooseAccount') | translate}}"
                                [(ngModel)]="selectedProjectId">
                            </fh-project-input>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group input-group" style="margin-bottom: 0px; display:block">
                            <ng-select class="form-control" name="deviceTypes" (change)="dateChanged(true)"
                                class="form-control"
                                placeholder="{{ 'general.chooseDeviceType' | translate }}  ({{ deviceTypeOptions.length }})"
                                [(ngModel)]="selectedDeviceType" style="width:100%">
                                <ng-option *ngFor="let deviceType of deviceTypeOptions" [value]="deviceType.id">
                                    {{ deviceType.value }}</ng-option>
                            </ng-select>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-group input-group" style="margin-bottom: 6px">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-euro-sign" title="{{'general.fuelCostHover' | translate }}"></i>
                            </span>
                            <input class="form-control" [(ngModel)]="fuelCosts" name="fuelCosts">
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-group input-group" style="margin-bottom: 6px;" title="Filter on 0 values">
                            <span id="checkBox11" class="input-group-addon hand" title="Filter on 0 values">
                                <i id="checkBox_11" class="auto_checkbox fa fa-fw"
                                    (click)="filterZeroValues = !filterZeroValues; dateChanged('hidden')"
                                    [ngClass]="filterZeroValues ? 'fa-check' : 'fa-times'" title="Filter on 0 values"
                                    [style.color]="filterZeroValues ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                            </span>
                            <input name="checkboxName_11" id="checkboxName_11"
                                style="width:100%; cursor: pointer!important;"
                                placeholder="{{'general.filterZeroValues' | translate }}" disabled="disabled"
                                class="form-control auto_search">
                        </div>
                    </div>
                    <div style="clear:both"></div>
                </div>
                <div class="ibox-content" style="position: relative; display:none">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div id="dynamicFilters"></div>
                    <div style="clear:both"></div>
                </div>
            </div>
            <!-- End default dynamic filters -->
        </div>
    </div>

    <div class="row" style="position: relative;" *ngIf="devices?.length > 0">
        <ngx-loading [show]="loading"></ngx-loading>
        <div class="col-lg-2 col-md-4 col-xs-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;" [routerLink]="['/Devices/Overview/']">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-car"></i>
                        {{kpis?.assetCountWithTrips?.toLocaleString()}} / {{kpis?.assetCount?.toLocaleString()}}

                    </h3>
                    <div>{{'general.activeAssets' | translate }}</div>
                </div>
            </div>
        </div>

        <div class="col-lg-2 col-md-4 col-xs-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fa fa-fw fa-route"></i>
                        {{actualRound(kpis?.totalDistance,0)?.toLocaleString()}} km
                    </h3>
                    <div>{{'general.distance' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-route"></i> {{actualRound(kpis?.totalDistance /
                        kpis?.assetCount,0)?.toLocaleString()}} km
                    </h3>
                    <div>{{'general.avgDistance' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-clock"></i>
                        {{actualRoundMinutes(kpis?.totalDuration / 60)}}
                    </h3>
                    <div>{{'general.duration' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fa fa-fw fa-snooze"></i>
                        {{actualRoundMinutes(kpis?.totalIdling / 60)}}
                    </h3>
                    <div>{{'general.idling' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-gas-pump"></i>
                        {{actualRound(kpis?.fuelUsedWhileDriving,0)?.toLocaleString()}} L
                    </h3>
                    <div>{{'general.fuelUsedWhileDriving' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-gas-pump"></i>
                        {{actualRound(kpis?.fuelLostWhileIdling,0)?.toLocaleString()}} L
                    </h3>
                    <div>{{'general.fuelLostWhileIdling' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-gas-pump"></i> {{actualRound(kpis?.fuelUsedTotal,0)?.toLocaleString()}} L
                    </h3>
                    <div>{{'general.fuelUsedTotal' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-gas-pump"></i> {{actualRound((kpis?.fuelLostWhileIdling /
                        kpis?.fuelUsedTotal) * 100,1)?.toLocaleString()}} %
                    </h3>
                    <div>{{'general.fuelLostPercentage' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-gas-pump"></i> {{actualRound((kpis?.totalDistance /
                        kpis?.fuelUsedTotal),1)?.toLocaleString()}} km/L
                    </h3>
                    <div>{{'general.fuelKmPerLiter' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-euro-sign"></i> {{actualRound((fuelCosts> 0 ? (kpis?.fuelUsedTotal *
                        fuelCosts) : '0'),0)?.toLocaleString()}}
                    </h3>
                    <div>{{'general.fuelCostTotal' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-xs-6">
            <div class="ibox hand">
                <div class="kpi-item" style="position: relative;">
                    <h3 class="margin-bottom font-bold">
                        <i class="fas fa-fw fa-euro-sign"></i> {{actualRound((fuelCosts> 0 ? (kpis?.fuelLostWhileIdling
                        *
                        fuelCosts) : '0'),0)?.toLocaleString()}}
                    </h3>
                    <div>{{'general.fuelCostLost' | translate }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="devices?.length > 0">
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-gas-pump"></i> {{'general.fuelEfficiency' | translate }} km/L
                    </h5>
                    <span class="pull-right">
                        <ibox-chart-export [chartObject]="chartFuelEfficiency">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <highcharts-chart *ngIf="chartFuelEfficiency" [runOutsideAngular]="true"
                        style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                        [options]="chartFuelEfficiency"></highcharts-chart>

                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-route"></i> {{'general.totalDistance' | translate }} ({{'general.km'
                        |
                        translate }})
                    </h5>
                    <span class="pull-right">
                        <ibox-chart-export [chartObject]="chartDistance">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loadingCount"></ngx-loading>

                    <highcharts-chart *ngIf="chartDistance" [runOutsideAngular]="true"
                        style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                        [options]="chartDistance">
                    </highcharts-chart>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-gas-pump"></i> {{ 'general.fuel' | translate }}</h5>
                    <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"
                        [constructorName]="constructorName" (filterChanged)="showFilter = $event"
                        (presetLoaded)="onPresetLoaded($event)" (columnsChanged)="drawFilterRow()"></iboxseach>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <table style="width:100%; min-height:50px;" fh-datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger" class="table  nowrap margin-bottom-0 dataTable dtr-inline">
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>