import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'app/modules/notification';
import { AccountService } from 'app/services/account/account.service';

@Component({
  selector: 'fh-account-sharing',
  templateUrl: 'sharing.template.html'
})
export class AccountSharingComponent implements OnInit {

  account;
  accountId;

  error;
  success;
  filter = '';

  constructor(protected notificationsService: NotificationsService, private accountService: AccountService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      const id = params['id'];

      this.accountId = id;

      this.accountService.getAccountById(id).subscribe(account => {
        this.account = account;
      });
    });
  }
}
