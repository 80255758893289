import { Component, ViewChild, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { FormMode } from 'app/common/enums';
import { ViewerOverviewComponent } from './viewerOverview.component';
import { AppUser } from 'app/models/user.model';
import { StorageHelper } from 'app/common/storagehelper';
@Component({
    selector: 'fh-assetgroup-chooser',
    templateUrl: 'groupChooser.template.html',
})
export class AssetGroupChooserComponent extends GridBase360Directive implements OnInit, OnDestroy {
    @ViewChild('autoShownModal') autoShownModal: ModalDirective;
    @ViewChild('viewerOverview', { static: false }) viewerOverview: ViewerOverviewComponent;
    modalRef: BsModalRef;
    isModalShown = false;
    loadingAccounts = false;

    token: string;
    @Output() onSelect = new EventEmitter();

    dropdown_IssueType = '';

    dtOptions = {};

    min: number;
    max: number;

    loading = false;
    languageLoaded: boolean;
    permissions: {};

    formMode = FormMode.add;
    loadingUser = false;

    accounts = [];

    groupLinkUrl = 'AssetGroupDetails';
    groupType = 'general.sharedAssetGroupsHeader';

    user: AppUser;
    viewerId;

    constructor(private authenticationService: AuthenticationService, protected storageHelper: StorageHelper) {
        super(storageHelper);
        this.token = this.authenticationService.getAuthToken();
    }

    close() {
        this.accounts = [];
        this.user = undefined;
        this.hideModal();
    }

    onHidden() {
        this.hideModal();
    }

    finish() {
        const keys = Object.keys(this.viewerOverview.selectedItems);

        const groups = keys.filter((key) => this.viewerOverview.selectedItems[key]);

        this.onSelect.emit({ id: this.viewerId, groups });

        this.close();
    }

    ngOnInit(): void {
        this.permissions = this.authenticationService.permissions;
    }

    // Model
    public showModal(item): void {
        if (item) {
            console.log('set user');
            this.user = new AppUser();
            this.user.id = item.userId;
            this.viewerId = item.id;
            this.accounts = [{ id: item.userId, name: item.emailLoginName }];
            setTimeout(() => {
                this.viewerOverview.loadAssetItems(item);
            }, 1000);
        }

        this.isModalShown = true;
    }

    public accountChanged(id): void {
        this.viewerOverview.loadAssetItems(id);
    }

    public hideModal(): void {
        this.isModalShown = false;
    }
}
