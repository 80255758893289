import { AfterViewInit, Directive, Inject, OnDestroy, ViewChild } from '@angular/core';
import { DataTable360Directive } from 'app/modules/360grid';
import { BatchProcessingComponent } from 'app/views/shared/usercontrols/batch/batchProcessing.component';
import { Subject } from 'rxjs';
import { StorageType } from './enums';
import { createSearch } from './gridhelper';

import pako from 'pako';
import { StorageHelper } from './storagehelper';

@Directive()
export abstract class GridBase360Directive implements AfterViewInit, OnDestroy {
    @ViewChild(DataTable360Directive)
    datatableElement: DataTable360Directive;

    @ViewChild(BatchProcessingComponent)
    processing: BatchProcessingComponent;

    dtTrigger: Subject<any> = new Subject();
    constructorName: string;
    columns = [];
    searchTerm: any;
    dtOptions;
    excludingColumns = [];

    storageType = StorageType.LocalStorage;

    commonExportOptions: any = {
        modifier: {
            page: 'all',
            search: 'none'
        },
        columns: ['id_export:name', 'accountId:name', ':visible[tabindex]']
    };

    // Show filters and presets
    showFilter = true;
    showPresets = true;

    constructor(protected storageHelper: StorageHelper) {
    }

    ngAfterViewInit() {
        const dynamicFilterContainer = $('#dynamicFilters');
        dynamicFilterContainer.html('<div style="padding: 10px">Loading filters...</div>');
    }

    ngOnDestroy(): void {
        // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
        // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
        // handling this global variable
        $.fn['dataTable'].ext.search.pop();

        // Had to reset the array...
        $.fn['dataTable'].ext.search = [];

        this.dtTrigger.unsubscribe();
    }

    onPresetLoaded(preset) {
        if (this.processing) {
            this.processing.resetSelection();
        }

        console.log('Appending grid_select_or to selectorHolder');
        // Move checkbox to the holder again
        $('#grid_select_or').appendTo($('#selectorHolder'));

        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            // Load settings
            localStorage.setItem('Columns_' + this.constructorName, preset.json);
            // Call the dtTrigger to rerender again
            this.dtTrigger.next(void 0);
        });
    }

    // Set events for datatable to listen on
    setEvents() {
        const that = this;
        if (this.processing && that.processing.eventsSet === false) {
            that.processing.eventsSet = true;
            this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.on('search.dt', function () {
                    that.processing.resetSelection();
                });

                dtInstance.on('column-reorder', function (event) {
                    // that.drawFilterRow();
                });

                dtInstance.on('draw.dt', function () {
                    // console.log('Table redrawn');
                });
            });
        }
    }

    editMode(isEditMode) {
        const toolbox = document.getElementById('grid-toolbox');

        console.log(isEditMode);
        if (isEditMode) {
            toolbox.classList.remove('hidden');
        } else {
            toolbox.classList.add('hidden');
        }
    }

    fireFilter(event = null): void {
        console.log('fire update');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Fire the request
            dtInstance.draw();
        });
    }

    seachChanged(value) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.search(value).draw();
        });
    }

    clearAllFilters() {
        console.log('Resetting all');
        $('.auto_search:input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
        $('.auto_checkbox').removeClass('fa-check').removeClass('fa-times').removeClass('fa-th').addClass('fa-th').css('color', '');

        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.search('').columns().search('').draw();
        });
    }

    checkFilters(event = null) {
        this.fireFilter(event);
    }

    // Create dynamic filters
    drawFilterRow(): void {
        const dynamicFilterContainer = $('#dynamicFilters');
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            createSearch(dtInstance, dynamicFilterContainer, this.excludingColumns, false, this.columns, this.dtOptions);
        });
    }

    // State
    saveState(constructor, data, useLocation = true) {
        if (useLocation) {
            window.history.replaceState(null, null, window.location.href.split('?')[0]);
        }

        this.storageHelper.saveStoreState(this.storageType, 'Columns_', constructor, data);

        if (useLocation) {
            this.loadState(constructor).then(state => {
                const encoded = pako.deflate(JSON.stringify(state));
                const tableSettings = window.btoa(String.fromCharCode.apply(null, encoded));

                window.history.replaceState(null, null, window.location.href.split('?')[0] + '?filter=' + encodeURIComponent(tableSettings));
            });
        }
    }

    loadState(constructor, useLocation = true) {
        return new Promise<any>((resolve, reject) => {
            const [_, filter, ...__] = window.location.href.split('?filter=');

            // Check if filter is set for this url
            if (filter && useLocation) {
                const tableSettings = new Uint8Array([...window.atob(decodeURIComponent(filter))].map(char => char.charCodeAt(0)));
                this.storageHelper.saveStoreState(this.storageType, 'Columns_', constructor, JSON.parse(pako.inflate(tableSettings, { to: 'string' })));
            }

            this.storageHelper.loadStoreState(this.storageType, 'Columns_', constructor).subscribe((columnSettings) => {
                if (columnSettings && columnSettings.time) {
                    columnSettings.time = (new Date()).getTime();
                }

                resolve(columnSettings);
            });
        })
    }

    setCookie(name, value, days) {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    getCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') { c = c.substring(1, c.length); }
            if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length, c.length); }
        }
        return null;
    }
}
