<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="device?.asset && device?.asset.accountId">
                <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate }}</a>
            </li>
            <li>
                <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                    device?.name}}</a>
            </li>
            <li class="active">{{'general.trailers' | translate }}</li>
        </ol>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId">
        <li *ngIf="device?.id && device?.asset?.id && permissions['Trailers_Assign']">
            <a (click)="trailerChooser.showModal()">
                <i class="fas fa-fw fa-trailer"></i>
                <div>{{'general.assignTrailer' | translate }}</div>
            </a>
        </li>
    </fh-device-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="">
            <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>

            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-trailer"></i> {{'general.trailer' | translate }}
                        </h5>
                        <span class="pull-right">
                            <div class="filter-form hidden-xs">
                                <input type="text" class="form-control table-inline top-filter"
                                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: hidden">
                        <ngx-loading [show]="loading"></ngx-loading>

                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>
                                        {{'general.trailer' | translate }}
                                    </th>
                                    <th>
                                        {{'general.start' | translate }}
                                    </th>
                                    <th>
                                        {{'general.end' | translate }}
                                    </th>
                                    <ng-container *ngIf="permissions['Trailers_Assign']">
                                        <th style="width: 60px;" scope="col">
                                            &nbsp;
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="trailers?.length === 0 && !loading">
                                    <td colspan="4">{{'general.nodatafound' | translate }}</td>
                                </tr>
                                <tr
                                    *ngFor="let trailer of trailers | orderBy : ['dateEnd','dateStart']:true | stringFilter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, id: 'first'}; ">
                                    <td><a class="secondary link_bolder"
                                            href="/#/TrailerDetails/Index/{{trailer?.trailerId}}">{{trailer?.trailerName}}
                                        </a>
                                    </td>
                                    <td>
                                        {{ trailer.dateStart | amDateFormat: 'LLL' }}
                                    </td>
                                    <td>
                                        {{ trailer.dateEnd ? (trailer.dateEnd | amDateFormat: 'LLL') : '-' }}
                                    </td>
                                    <ng-container *ngIf="permissions['Trailers_Assign']">
                                        <td [ngSwitch]="true" style="width: 60px; text-align: right;">
                                            <ng-container *ngSwitchCase="(trailer.dateEnd ?? null) === null">
                                                <a class='btn btn-primary btn-grid' (click)="showEndAssignment(trailer)"
                                                    title="{{'general.endAssignment' | translate }}">
                                                    <i class="fas fa-fw fa-hand-paper"></i>
                                                </a>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="(trailer.dateEnd ?? null) !== null">
                                                <a class='btn btn-danger btn-grid' (click)="showDelete(trailer.id)"
                                                    title="{{'general.delete' | translate }}">
                                                    <i class="fas fa-fw fa-trash-alt"></i>
                                                </a>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                &nbsp;
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="first">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-trailer-chooser *ngIf="device?.id && device?.asset?.id" [device]="device" #trailerChooser
    (onSelect)="onSelect($event)">
</fh-trailer-chooser>

<fh-confirmation-modal #deleteModal (onFinish)="deleteAssignment($event)">
</fh-confirmation-modal>

<fh-confirmation-modal #assignmentModal (onFinish)="endAssignment($event)">
    <fh-archiving-modal #assignmentBody [trailer]="selectedTrailerAssignment" body></fh-archiving-modal>
</fh-confirmation-modal>