import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { Driver } from 'app/models/driver.model';
import { AccountInventory } from 'app/models/account.model';


@Component({
    selector: 'fh-wasl-account-details',
    templateUrl: 'waslAccountDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class WaslAccountDetailsComponent implements OnInit {

    @Input() account: AccountInventory;
    @Input() formMode: any = FormMode.read;

    constructor() {
    }

    ngOnInit(): void {
    }

}

