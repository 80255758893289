import { Schedule } from './commutingSchedule.model';

export class WorkingHoursSettings {
    id: string;
    schedule: Schedule[] = [];
    timestamp: string;
    accountId: any;
    assetId: any;
    markAsPrivate = false;
    timezoneIana = 'Europe/Amsterdam';
}
