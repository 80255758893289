<a aria-expanded="false" role="button" dropdownToggle
    style="margin-top: -8px; margin-bottom: -8px; padding-bottom: 10px;">
    <span class="fa-stack" [attr.data-count]="notificationsUnread?.length">
        <i class="fa fa-bell fa-stack-1x"></i>
    </span>
</a>

<ul id="notifications-tray" role="menu" *dropdownMenu class="dropdown-menu notifications-tray">
    <li *ngFor="let notification of notificationsUnread | stringFilter: filter">
        <a [routerLink]="['/Notifications/']"><i class="fa fa-fw fa-bell"></i>
            <span [title]="notification.date.toLocaleString()">{{notification.date | amDateFormat: 'HH:mm' }}</span> -
            {{notification.subject}}</a>
    </li>
    <li [routerLink]="['/Notifications/']">
        <a><strong>{{'general.moreDetails' | translate}}</strong></a>
    </li>
</ul>