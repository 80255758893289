import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from 'app/models/project.model';
import { ProjectService } from 'app/services/project/project.service';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';

@Component({
    selector: 'fh-project-log',
    templateUrl: 'projectLog.template.html'
})
export class ProjectLogViewComponent implements OnInit {
    projectLog: AuditLog[];
    sub;
    project: Project;
    loading = false;

    error: any;
    success: any;

    permissionName = 'FleetManagement_Log';
    projectId: any;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private projectService: ProjectService, private route: ActivatedRoute, private router: Router) {
        this.project = null;
        this.projectLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.project = new Project;
        this.project.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.projectId = id;
            this.projectService.getProjectById(id).subscribe(project => {
                this.project = project;

                if (this.project == null) {
                    this.router.navigate(['/Projects/Overview'])
                }

                this.auditLogService.getAuditLogByProject(id).subscribe(deviceLog => {
                    console.log('loading done');
                    this.projectLog = deviceLog;
                    this.loading = false;
                }, error => {
                    this.error = error;
                    this.loading = false;
                });
            });
        });
    }
}
