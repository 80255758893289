import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { DeviceService } from '../../services/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { TrailerChooserComponent } from '../shared/usercontrols/trailerChooser.component';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { AssetService } from 'app/services/asset/asset.service';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';
import { AnalogFunctions, DeviceAuxiliary } from 'app/common/enums';
import { AssignmentModalComponent } from '../shared/usercontrols/assignmentModal.component';

@Component({
    selector: 'fh-device-trailers',
    templateUrl: 'trailers.template.html',
    animations: [fadeInOnEnterAnimation({ anchor: 'enter' })]
})
export class DeviceTrailersViewComponent implements OnInit {
    @ViewChild('trailerChooser') trailerChooser: TrailerChooserComponent;
    @ViewChild('deleteModal') deleteModal: ConfirmationModalComponent;

    @ViewChild('assignmentModal', { static: false }) assignmentModal: ConfirmationModalComponent;
    @ViewChild('assignmentBody', { static: false }) assignmentBody: AssignmentModalComponent;
    selectedTrailerAssignment;

    deviceLog: AuditLog[];
    sub;
    device: Device;
    deviceId;
    loading = false;
    hasTrailerTagsEnabled = false;
    loadingTrailerTagAssignments = false;

    error: any;
    success: any;
    warning: any;

    trailers = [];
    trailerTagAssignments = [];

    permissionName = 'Trailers_View';
    permissions: {};

    currentPage: number;
    filter: string;
    itemsPerPage = 10;

    constructor(private http: HttpClient, private assetService: AssetService, private authenticationService: AuthenticationService, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router, private translateService: TranslateService) {
        this.permissions = this.authenticationService.permissions;
        this.device = null;
        this.deviceLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.device = new Device;
        this.device.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.deviceId = id;
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }

                if (this.device.asset?.id == null) {
                    this.warning = {
                        statusText: 'Warning',
                        warning: 'There is no asset activated to this device. Trailers can not be configured!'
                    };
                    this.loading = false;
                } else {
                    this.getTrailerInfo(id);
                }
            });
        }, error => {
            this.error = error;
            this.error.statusText = 'Error fetching device';

            setTimeout(() => {
                this.router.navigate(['/Devices/Overview']);
            }, 3000);
        });
    }

    getTrailerInfo(id = this.device.id, func?: Function) {
        this.deviceService.getTrailersById(id).subscribe(trailers => {
            this.trailers = trailers;
            this.loading = false
        }, error => {
            this.loading = false;
            this.success = null;
            this.error = error;
        });
    }

    deleteAssignment(assignmentId) {
        this.deleteModal.hideModal();
        const assignment = this.trailers.find(x => x.id === assignmentId);

        this.loading = true;

        const deleteAssignTrailer = {
            AssetId: this.device.asset?.id,
            AssetTrailerAssignmentId: assignment.id,
        };

        this.assetService.deleteTrailerAssignment(this.device.asset?.id, deleteAssignTrailer).subscribe(
            (task: { error: boolean, isSuccess: boolean, message: any }) => {
                if (task?.isSuccess) {
                    this.error = null;

                    if (task.message?.startsWith('You can only')) {
                        this.success = {
                            statusText: 'Warning',
                            success: task.message,
                        };
                    } else {
                        this.success = {
                            statusText: 'Success',
                            success: task.message,
                        };
                    }
                } else {
                    this.error = task?.message ?? task?.error;
                }
                this.getTrailerInfo(undefined);
            }, error => {
                this.loading = false;
                this.success = null;
                this.error = error;
            });

    }

    showEndAssignment(trailer) {
        this.selectedTrailerAssignment = trailer;
        this.assignmentModal.showModal(trailer.id);
    }

    endAssignment(assignment) {
        this.assignmentModal.hideModal();
        this.loading = true;

        const stopAssignTrailer = {
            AssetId: this.device.asset?.id,
            AssetTrailerAssignmentId: this.selectedTrailerAssignment.id,
            EndDate: +this.assignmentBody.archiveDate / 1000
        };

        this.assetService.stopTrailerAssignment(this.device.asset?.id, stopAssignTrailer).subscribe(
            (task: { error: boolean, isSuccess: boolean, message: any }) => {
                if (task?.isSuccess) {
                    this.error = null;

                    if (task.message?.startsWith('You can only')) {
                        this.success = {
                            statusText: 'Warning',
                            success: task.message,
                        };
                    } else {
                        this.success = {
                            statusText: 'Success',
                            success: task.message,
                        };
                    }
                } else {
                    this.error = task?.message ?? task?.error;
                }
                this.getTrailerInfo(undefined, () => this.loading = false);
            }, error => {
                this.loading = false;
                this.success = null;
                this.error = error;
            });
    }

    onSelect(event): void {
        this.loading = true;

        const assignTrailer = {
            TrailerId: event.id,
            AssetId: this.device.asset?.id,
            StartDate: +event.from / 1000,
            EndDate: event.to ? +event.to / 1000 : null,
        };

        this.assetService.assignTrailer(this.device.asset?.id, assignTrailer).subscribe(
            (task: { error: boolean, isSuccess: boolean, message: any }) => {
                if (task?.isSuccess) {
                    this.error = null;

                    if (task.message?.startsWith('You can only')) {
                        this.success = {
                            statusText: 'Warning',
                            success: task.message,
                        };
                    } else {
                        this.success = {
                            statusText: 'Success',
                            success: task.message,
                        };
                    }
                } else {
                    this.error = task?.message ?? task?.error;
                }
                this.getTrailerInfo(undefined);
            }, error => {
                this.loading = false;
                this.success = null;
                this.error = error;
            });
    }

    showDelete(assignmentId) {
        console.log(assignmentId);
        this.deleteModal.showModal(assignmentId);
    }
}
