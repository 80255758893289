import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { AssetType } from 'app/models/assetType.model';

@Component({
    selector: 'fh-asset-type-selector',
    templateUrl: 'assetTypeSelector.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssetTypeSelectorComponent implements OnInit, OnChanges {

    @Input() formMode: any = FormMode.read;
    @Input() loading = false;
    @Input() assetTypes: AssetType[] = [];
    @Input() isDisplayOnly = false;

    activeCount = 0;

    // Pagination
    filter;
    currentPage;

    constructor(private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.cd.markForCheck();
    }

    ngOnChanges(): void {
        this.activeCount = 0;

        if (this.assetTypes !== undefined) {
            this.assetTypes.forEach(at => {
                const _ = at.maintenanceTypeChecked && this.activeCount++;
            })
            this.cd.markForCheck();
        }
    }
}
