import {
    Component, AfterViewInit, Input, ElementRef, ViewChild, OnInit, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SensorTemplateService } from 'app/services/sensorTemplate/sensorTemplate.service';

const noop = () => {
};

@Component({
    selector: 'fh-sensor-template-input',
    templateUrl: './sensorTemplateSelector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: SensorTemplateInputComponent }
    ]
})

export class SensorTemplateInputComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {

    @Input() placeholder: string;
    @Input() clearable = true;
    @Input() selectedAccountId;
    @Input() autoSelect = true;
    @Input() readonly = false;
    @Input() addTag = false;
    @Input() sensorType;

    @Output() onChanged = new EventEmitter();

    @Input() sensorTemplates = []
    @Input() loadingSensorTemplates = false;

    @ViewChild('input') el: ElementRef;

    private _value: string;
    private _onChange: (_: any) => void = noop;

    get value(): any {
        return this._value;
    };

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this._onChange(v);
            this.cd.markForCheck();
        }
    }

    constructor(private sensorTemplateService: SensorTemplateService, private cd: ChangeDetectorRef) {
    }

    sensorTemplateChanged() {
        this.onChanged.emit(this.value);
        this.loadingSensorTemplates = false;
        this.cd.markForCheck();
    }

    ngOnInit() {

    }

    ngOnChanges() {
        if (this.sensorType) {
            this.sensorTemplates = this.sensorTemplates.filter(x => x.sensorType.toString() === this.sensorType.toString());
        }
    }

    ngAfterViewInit() {
        const __this = this;
    }

    writeValue(value: any) {
        this._value = value;
        this.cd.markForCheck();
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
        this.cd.markForCheck();
    }

    registerOnTouched(fn: any) {
        this.cd.markForCheck();
    }

}
