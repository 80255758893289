<div class="col-lg-12">
    <div class='horizontalMenuWrapper'>
        <ul>
            <li *ngIf="permissions['Modules_HasPrivateFleet']"
                [ngClass]="{'active': activeRoute('CarSharing/Dashboard')}">
                <a [routerLink]="['/CarSharing/Dashboard/']">
                    <i class="fas fa-fw fa-garage-car"></i>
                    <div>{{ 'general.dashboard' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Modules_HasPrivateFleet']"
                [ngClass]="{active: activeRoute('CarSharing/Damages')}">
                <a [routerLink]="['/CarSharing/Damages']">
                    <i class="fas fa-fw fa-car-burst"></i>
                    <div>{{ 'general.damages' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Modules_HasPrivateFleet']"
                [ngClass]="{'active': activeRoute('/CarSharing/Garages')}">
                <a [routerLink]="['/CarSharing/Garages/']">
                    <i class="fas fa-fw fa-garage"></i>
                    <div>{{ 'general.garages' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class='horizontalMenuWrapper' style="float:right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>