export class RegisterOperatingDriver {
    public identityNumber = '';
    public dateOfBirthGregorian = '';
    public mobileNumber = '';
    public referenceKey = '';
    public registerDateWasl: any;
    public registerDateSfda: any;
    public registerDateTow: any;
}

export class Properties {
    public wasl = new RegisterOperatingDriver();
    public custom: any = [];
}

export class AssetDriver {
    public startDate: any;
    public endDate: any;
    public type: any;
    public id: any;
}

export class Driver {

    public id: any;

    public firstName: string;
    public lastName: string;
    public email: string;

    public erpCode: any;
    public tag: any;
    public mobilePhone: any;
    public officePhone: any;
    public homePhone: any;
    public created: any;
    public dateOfBirth: any;
    public driverLicense: any;
    public driverLicenceExpirationDate: any;
    public identificationCardNumber: any;
    public identificationCardExpirationDate: any;
    public assetDriver: AssetDriver;
    public IsArchived: any;

    public comment: any;
    public qualification: any;

    public accountPlatformId: string;

    public language: any;

    public properties = new Properties();
    public isActive = false;

    public resellerId;
    public resellerDescription: any;

    public companyName: any;
    public accountId: any;

    public deviceId: any;
    public assetName: any;
    public assignmentStart: any;
    public assignmentEnd: any;
    public isArchived: any;
    public name: string;

    public score: any;
    public activeAssignments: any;

    public garageName: string;
    public garageId: number;

    public driverGroups: any;
}

export class DriverScore {
    deviceId: any;
    assetName: any;
    accountId: any;
    assetCount: any;
    assignmentCount: any;
    driverGroups: any;
    assetGroups: any;
    tripCount: any;
    workingHoursDurationInSeconds: any;
    maxSpeed: any;
    segmentsDistance: any;
    totalDurationInSeconds: any;
    score: any;
    idlingDurationInSeconds: any;
    speedingCount: any;
    roadSpeedingCount: any;
    driverId: any;
    driverName: any;
    distancePer100kmCalculator: number;
    accelPer100Count: number;
    decelPer100Count: number;
    speedScore: number;
    speedingDurationInSeconds: any;
    decelScore: any;
    accelScore: any;
    seatBeltScore: number;
    durationSeatBeltViolationInSeconds: any;
    accelCount: any;
    decelCount: any;
    corneringCount: any;
    corneringPer100Count: number;
    corneringScore: any;
    brakingEventScore: any;
    accellerationEventScore: any;
    corneringEventScore: any;
    idlingScore: any;
    idlingScoreWeighted: number;
    speedScoreWeighted: number;
    seatBeltScoreWeighted: number;
    accellerationEventScoreWeighted: number;
    brakingEventScoreWeighted: number;
    corneringEventScoreWeighted: number;
    pureDrivingDurationInSeconds: any;
    isActive: any;
    rank: number;
    rpmEventScore: any;
    rpmEventScoreWeighted: any;
    rpmCount: any;
    vehicleType: any;
    calculatorValue: any;
}

