<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/Overview">{{'general.users' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/UserRoles/Overview">{{'general.userRoles' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/UserRoleDetails/Index/{{userRole?.id}}">{{userRole?.name}}</a>
            </li>
            <li class="active">{{'general.dashboard' | translate }}</li>
        </ol>

        <span class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix hidden-sm hidden-xs" title="Help"
                href="https://help.360locate.com/knowledge-base/fleet-management/user-roles/" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="hidden-sm hidden-xs">{{'general.needHelp' | translate
                    }}</span></a>
        </span>
    </div>
    <fh-user-navigation></fh-user-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" *ngIf="error || success || warning">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>
            </div>
        </div>
        <div class="row">
            <form #userRoleForm="ngForm">
                <div class="col-md-12">
                    <div class="ibox" style="position:relative">
                        <ngx-loading [show]="loading || saving"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-restroom"></i> {{'general.userRoles' | translate }}
                            </h5>
                        </div>
                        <fh-user-role-details [userRole]="userRole" [formMode]="formMode"
                            (onAccountChanged)="accountChanged($event)" (onResellerChanged)="resellerChanged($event)">
                        </fh-user-role-details>
                        <fh-save-footer [valid]="!userRoleForm.invalid" (onSave)="onSave()"
                            [allowEdit]="permissions['UserRoles_Edit'] && ((myUserRole != userRole?.id) || myUserRole == 1)"
                            (onDelete)="onDelete()"
                            [allowDelete]="permissions['UserRoles_Delete'] && permissions['Platform_IsReseller'] && !(userRole?.permissions?.Platform?.includes('IsAdministrator'))"
                            (setFormMode)="setFormMode($event)" [formMode]="formMode">
                            <span class="pull-right">
                                <button class="btn btn-info" (click)="clone();"
                                    title="{{ 'general.clone' | translate }}"><i class="fa fa-fw fa-copy"></i>
                                    {{'general.clone' | translate }}</button>
                            </span>
                        </fh-save-footer>
                    </div>
                </div>
                <div class="col-md-12">
                    <fh-user-role-permission-content [permissionsMap]="permissionsMap" [formMode]="formMode"
                        [userRole]="userRole">
                    </fh-user-role-permission-content>
                </div>
            </form>
        </div>
    </div>
</fh-permission-handler>