<div class="col-lg-12">
    <div class='horizontalMenuWrapper'>
        <ul>
            <li [ngClass]="{'active': activeRoute('Drivers/Overview')}"><a href="/#/Drivers/Overview/"><i
                        class="fa fa-fw fa-id-card-alt"></i>
                    <div>{{ 'general.overview' |translate }}</div>
                </a></li>
            <li [ngClass]="{'active': activeRoute('Drivers/Cards')}"><a href="/#/Drivers/Cards/"><i
                        class="fas fa-fw fa-credit-card-front"></i>
                    <div>{{ 'general.cards' | translate }}</div>
                </a></li>
            <li [ngClass]="{'active': activeRoute('Drivers/External')}"><a href="/#/Drivers/External/"><i
                        class="fas fa-fw fa-link"></i>
                    <div>{{ 'general.external' | translate }}</div>
                </a></li>
            <li [ngClass]="{'active': isData()}"><a href="/#/Drivers/Assignments"><i class="fas fa-fw fa-database"></i>
                    <div>{{ 'menu.data' | translate }}</div>
                </a></li>

            <li [ngClass]="{'active': activeRoute('DriverGroups') || activeRoute('DriverGroup')}"><a
                    href="/#/DriverGroups/Overview/"><i class="fas fa-fw fa-layer-group"></i>
                    <div>{{ 'general.groups' | translate }}</div>
                </a></li>
        </ul>
    </div>
    <div class='horizontalMenuWrapper' style="float:right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div class="col-lg-12" style="padding: 5px" *ngIf="isData()">
    <div class='horizontalMenuSubWrapper'>
        <ul>
            <li [ngClass]="{'active': activeRoute('Drivers/Assignments')}"><a href="/#/Drivers/Assignments/"><i
                        class="fas fa-fw fa-barcode"></i>
                    {{ 'general.assignments' | translate }}
                </a></li>
            <li [ngClass]="{active: activeRoute('/Drivers/DayViewAssignments')}">
                <a [routerLink]="['/Drivers/DayViewAssignments/']">
                    <i class="fas fa-fw fa-solid fa-calendar-days"></i>
                    {{ 'general.assignmentGantt' | translate }}
                </a>
            </li>
            <li [ngClass]="{active: activeRoute('/Drivers/DayViewTrips')}">
                <a [routerLink]="['/Drivers/DayViewTrips/']">
                    <i class="fas fa-fw fa-solid fa-calendar-days"></i>
                    {{ 'general.tripsGantt' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>
<div class="col-lg-12" style="padding: 5px" *ngIf="isDriverGroupDetails()">
    <div class='horizontalMenuSubWrapper'>
        <ul>
            <li [ngClass]="{active: activeRoute('DriverGroupDetails/Index')}">
                <a [routerLink]="['/DriverGroupDetails/Index/', activeId]">
                    <i class="fas fa-fw fa-layer-group"></i>
                    {{ 'menu.details' | translate }}
                </a>
            </li>
            <li [ngClass]="{active: activeRoute('DriverGroupDetails/Log')}">
                <a [routerLink]="['/DriverGroupDetails/Log/', activeId]">
                    <i class="fas fa-fw fa-file-alt"></i>
                    {{ 'menu.log' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>
<div style="clear: both"></div>