import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EntityType } from 'app/common/enums';
import { TagSearch, TagUsage } from 'app/models/tag.model';
import { of } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { TranslatorService } from '../common/translator.service';
import { LoggingService } from '../logging/logging.service';


@Injectable()
export class TagService {
    token: string;
    timestamp;
    url = '';

    constructor(private http: HttpClient, private loggingService: LoggingService, private translatorService: TranslatorService, private authenticationService: AuthenticationService) {
        this.url = authenticationService.getWebserviceURL('tag');
        this.token = authenticationService.getAuthToken();
    }

    getTagsForObject(guid: string, type: EntityType): Observable<TagSearch[]> {
        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        if (guid == null) {
            return of([]);
        }

        return this.http.get(this.url + 'GetTagsForObject?objectId=' + guid + '&objectType=' + type, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseTagSearchResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getTagsWithUsage(): Observable<TagUsage[]> {
        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.get(this.url, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseTagUsageResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }


    getObjectsByName(tag): Observable<TagSearch[]> {
        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        return this.http.get(this.url + 'GetMatches?Tags=' + tag, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseTagSearchResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    saveTag(tag: string, objectId: string, objectType: EntityType): Observable<boolean> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        const body = {
            name: tag,
            objectId: objectId,
            objectType: objectType
        }

        console.log('posting tag ' + tag + ' for object ' + objectId + ' of type ' + objectType);
        return this.http.post(this.url + 'PostTag',
            JSON.stringify(body),
            { headers: headers })
            .map((data) => {
                const parsedResponse = (data === true);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    removeTag(tag: string, objectId: string, objectType: EntityType): Observable<boolean> {

        this.token = this.authenticationService.getAuthToken();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        const body = {
            name: tag,
            objectId: objectId,
            objectType: objectType
        }

        console.log('removing tag ' + tag + ' for object ' + objectId + ' of type ' + objectType);
        return this.http.post(this.url + 'RemoveTag',
            JSON.stringify(body),
            { headers: headers })
            .map((data) => {
                const parsedResponse = (data === true);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        return throwError(error);
    }

    parseTagSearchResponse(json: any): TagSearch[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' tags.');

        const ident = 1;
        const tags: TagSearch[] = [];

        // this.loggingService.log(json);
        json.forEach(item => {
            //   this.loggingService.log(this.constructor().name + " - New", item);
            const tag = this.parseTagSearchResponseDetails(item);
            tags.push(tag);
        });

        this.timestamp = new Date().getTime();
        return tags;
    }

    parseTagSearchResponseDetails(item): TagSearch {

        const tag = new TagSearch();
        tag.id = item.id;
        tag.name = item.name;
        tag.objectId = item.objectId;
        tag.objectName = item.objectName;
        tag.objectType = item.objectType;

        return tag;
    }

    parseTagUsageResponse(json: any): TagUsage[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' tags.');

        const ident = 1;
        const tags: TagUsage[] = [];

        // this.loggingService.log(json);
        json.forEach(item => {
            //   this.loggingService.log(this.constructor().name + " - New", item);
            const tag = this.parseTagUsageResponseDetails(item);
            tags.push(tag);
        });

        this.timestamp = new Date().getTime();
        return tags;
    }

    parseTagUsageResponseDetails(item): TagUsage {

        const tag = new TagUsage();
        tag.id = item.id;
        tag.name = item.name;
        tag.useCount = item.useCount;
        tag.nameWithCount = item.name + ' (' + item.useCount + ')';

        return tag;
    }
}
