<div class="ibox-content slick" style="position:relative">
    <table *ngIf="driver" style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody>
            <tr>
                <td class="row_opening">
                    {{ 'general.identityNumber' | translate}}
                    <span class="error" *ngIf="identityNumber.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1"
                        name="identityNumber" class="form-control table-inline"
                        [(ngModel)]="driver.properties.wasl.identityNumber" required minlength="4"
                        #identityNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.mobileNumber' | translate}}
                    <span class="error" *ngIf="mobileNumber.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1"
                        name="mobileNumber" class="form-control table-inline"
                        [(ngModel)]="driver.properties.wasl.mobileNumber" required minlength="4"
                        #mobileNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.dateOfBirthGregorian' | translate}}
                    <span class="error" *ngIf="dateOfBirthGregorian.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1"
                        name="dateOfBirthGregorian" class="form-control table-inline"
                        [(ngModel)]="driver.properties.wasl.dateOfBirthGregorian" required minlength="6"
                        #dateOfBirthGregorian="ngModel">
                </td>
            </tr>
        </tbody>
    </table>
</div>