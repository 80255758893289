<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Drivers/Overview">{{'general.drivers' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Drivers/Add">{{'menu.add' | translate }}</a>
            </li>
        </ol>
    </div>
    <fh-driver-navigation>
        <li class="active"><a href="/#/Drivers/Add/"><i class="fa fa-fw fa-id-card-alt"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a></li>
    </fh-driver-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-fw fa-id-card-alt"></i> {{'general.driver' | translate }}
                            </h5>
                        </div>
                        <fh-driver-details [driver]="driver" [formMode]="formMode" [loading]="loading"
                            (onAccountChanged)="accountChanged($event)"></fh-driver-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()"
                            [allowAdd]="permissions['Drivers_Add']" [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">
                <fh-group-overview [linkUrl]="'DriverGroupDetails'" name="{{'general.driverGroups' | translate }}"
                    [loading]="loadingGroups" [groups]="driverGroups" [showCount]="true">
                </fh-group-overview>
            </div>
        </div>
    </div>
</fh-permission-handler>