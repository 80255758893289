<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Settings/">{{'general.settings' | translate }}</a>
            </li>
            <li>
                <a href="/#/Settings/AssetTypes/Overview">{{'general.assetTypes' | translate }}</a>
            </li>
            <li class="active">{{'menu.add' | translate }}</li>
        </ol>
    </div>
    <fh-device-navigation>
        <li class="active"><a href="/#/Devices/AssetTypes/Add"><i class="fas fa-fw fa-truck-monster"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a></li>
    </fh-device-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-truck-monster"></i> {{'general.assetType' | translate }}
                            </h5>
                        </div>
                        <fh-asset-type-details [assetType]="assetType" [formMode]="formMode"
                            (onAccountChanged)="accountChanged($event)">
                        </fh-asset-type-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()" [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <form #userForm="ngForm">
                    <fh-asset-type-selector [isDisplayOnly]=true [assetTypes]="assetTypes"
                        [formMode]="formModeAssetTypes" [loading]="loading">
                    </fh-asset-type-selector>
                </form>
            </div>
        </div>
    </div>
</fh-permission-handler>