import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getGridButtons, getGridLanguages, getDefaultDpConfig } from 'app/common/gridhelper';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { SignalrService } from 'app/services/signalr/signalr.service';
import { ActivatedRoute } from '@angular/router';

import { ReportService } from 'app/services/reports/report.service';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { roundAsString, roundAsNumber } from 'app/common/globals';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';

import { StorageHelper } from 'app/common/storagehelper';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';

window['moment'] = Moment;
mTZ();
@Component({
  selector: 'fh-reporting-templates',
  templateUrl: 'templates.template.html'
})
export class ReportingTemplatesViewComponent extends GridBase360Directive implements OnDestroy {
  @ViewChild('deleteModal') deleteModal: ConfirmationModalComponent;
  token: string;

  loading = false;
  saving = false;
  languageLoaded: boolean;
  permissions: {};

  availableReports = {};

  excludingColumns = ['timestamp'];

  success;
  error;

  randomKey;

  constructorName = 'ReportingViewComponent';
  permissionName = 'FleetManagement_Reporting';
  timezoneIana: string;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(private route: ActivatedRoute, public signalrService: SignalrService, private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private reportService: ReportService, protected storageHelper: StorageHelper) {
    super(storageHelper);

    const that = this;
    this.loading = true;

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().subtract(1, 'months').startOf('day').toDate(),
      Moment().add(0, 'days').endOf('day').toDate()
    ];

    this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

    // Get all the date for dropdown boxes
    forkJoin([
      this.reportService.getReportColumnsByReportType(),
      this.translateService.get('general.date')
    ]).subscribe(
      ([columns, _]) => {

        this.languageLoaded = true;
        this.loading = false;

        for (const item in columns) {
          if (item === null) {
            continue;
          }

          this.availableReports[columns[item].reportType] = true;
        }

        delete this.availableReports['0'];

        this.cd.markForCheck();

        this.initGrid();
      },
      err => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  dateChanged(event) {
    const that = this;
    if (event !== null) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.url(that.reportService.getSubscriptionPagingUrl(moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.randomKey)).load();
      });
    }
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  finishReportTemplate(id) {
    this.deleteModal.hideModal();

    $.fn['dataTable'].ext.search.pop();

    forkJoin([this.reportService.deleteReportTemplate(id), this.datatableElement.dtInstance]).subscribe(([_, dtInstance]) => {
      dtInstance.ajax.reload();
      this.saving = false;
      this.cd.markForCheck();
    });
  }

  @HostListener('document:deleteTemplate', ['$event.detail'])
  deleteReportTemplate(el) {
    if (this.saving) {
      return;
    }

    this.saving = true;
    this.deleteModal.showModal(el);
  }

  initGrid(): void {
    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    // Id = (Guid)row.GeneratedReportId,
    // AccountId = (int)row.AccountId,
    // SelectedReportTypeId = (int)row.SelectedReportTypeId,
    // FkUserId = (int)row.FkUserId,
    // Timestamp = (DateTime)row.Timestamp,
    // Filters = (string)row.Filters,
    // ReportData = (string)row.ReportData,

    const reportSubscriptionTypeOptions = [];
    for (let i = 1; i < 3; i++) {
      reportSubscriptionTypeOptions.push({ id: i, value: this.translateService.instant('enums.reportSubscriptionType.' + i) });
    }

    const reportTypeOptions = [];
    for (const i of Object.keys(this.availableReports)) {
      reportTypeOptions.push({ id: i, value: this.translateService.instant('enums.reportType.' + i) });
    }

    this.columns = [
      {
        name: 'name',
        data: 'name',
        defaultContent: '',
        title: this.translateService.instant('general.name'),
        render: function (data, type, row) {
          return data ? data : '-';
        }
      },
      {
        name: 'reportType',
        data: 'reportType',
        title: this.translateService.instant('general.selectedReportType'),
        type: 'select',
        options: reportTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
        render: function (data, type, row) {
          return that.translateService.instant(('enums.reportType.' + data));
        }
      },
      {
        name: 'createdDate',
        data: 'createdDate',
        type: 'date',
        title: this.translateService.instant('general.createdDate'),
        render: function (data, type, row) {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          if (type && type === 'display') {
            return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
          }

          return date.toLocaleString();
        },
      },
      {
        name: 'id',
        data: 'id',
        className: 'noVis',
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: '20',
        render: function (data, type, row) {
          return '<div class=\'btn btn-danger btn-grid\' title=\'' + that.translateService.instant('general.delete') + '\'onclick=\'document.dispatchEvent(new CustomEvent(\"deleteTemplate\", { detail: \"' + data + '\", bubbles: true }));\'' + '><i class=\'fas fa-fw fa-trash-alt\'></i></div>';
        }
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'templates_overview', this.translateService.instant('menu.messagesoverview')),
      pagingType: 'simple_numbers',
      serverSide: false,
      processing: false,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      order: [[0, 'desc']],
      stateSave: false,
      ajax: (dataTablesParameters: any, callback) => {
        $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));

        this.loading = true;
        this.reportService.getReportTemplates().subscribe(resp => {
          this.loading = false;
          const reportTemplates = resp.filter(({ id }) => id !== '00000000-0000-0000-0000-000000000000');
          callback({
            recordsTotal: reportTemplates.length,
            recordsFiltered: reportTemplates.length,
            data: reportTemplates,
          });
        }, error => {
          this.success = null;
          this.error = error;
          this.loading = false;
        });
      },
      initComplete: function (settings, json) {
        that.loading = false;

        console.log('init complete');
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'id_export']
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }
}
