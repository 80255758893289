import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { getGridButtons, getGridLanguages, createSearch, createdCellCheckbox, getDefaultDpConfig } from 'app/common/gridhelper';

import { AuthenticationService } from '../../services/authentication/authentication.service';

import { Observable } from 'rxjs';
import { MaintenanceService } from 'app/services/maintenance/maintenance.service';

import { BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { formatDecimal, formatMoney } from 'app/services/common/functions.service';
import { GridBase360Directive } from 'app/common/360Grid.base';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { StorageHelper } from 'app/common/storagehelper';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-maintenance-due',
  templateUrl: 'maintenanceDue.template.html'
})
export class MaintenanceDueViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;

  showActiveOnly = true;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  permissionName = 'Maintenances_View';
  constructorName = 'MaintenanceDueViewComponent';
  timezoneIana: string;

  // Daterange
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  datePickerModel: Date;

  error;

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private maintenanceService: MaintenanceService, protected storageHelper: StorageHelper) {
    super(storageHelper);

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.datePickerModel = Moment().subtract(0, 'days').startOf('day').toDate();

    // Datepicker
    this.dpConfig.containerClass = 'theme-default'; // or whatever color
    this.dpConfig.dateInputFormat = 'lll';
    this.dpConfig.isAnimated = true;

    // Get all the date for dropdown boxes
    Observable.forkJoin(
      this.translateService.get('general.date')
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      err => console.error(err)
    );
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log('Fire update');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

        if (this.showActiveOnly === true) {
          dtInstance.column('isActive:name').search('true');
        } else {
          dtInstance.column('isActive:name').search('@ignore');
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  // Check custom filters from grid save
  checkFilters() {

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.showActiveOnly = dtInstance.column('isActive:name').search() !== '@ignore';
    });

    this.fireFilter(1);
  }

  dateChanged(event) {
    const that = this;
    if (event !== null) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.url(that.maintenanceService.getPagingDueUrl(this.datePickerModel.getTime())).load();
      });
    }
  }

  initGrid(): void {
    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: 'assetName',
        data: 'assetName',
        defaultContent: '-',
        title: this.translateService.instant('general.assetName'),
        render: function (data, type, row) {
          return '<a class="link_bolder" href="/#/DeviceDetails/Maintenance/' + row.deviceId + '">' + data + '</a>';
        },
      },
      {
        name: 'typeName',
        data: 'typeName',
        defaultContent: '-',
        title: this.translateService.instant('general.typeName'),
        render: function (data, type, row) {
          return '<a class="link_bolder" href="/#/Maintenances/MaintenanceTypeDetails/Index/' + row.maintenanceTypeId + '">' + data + '</a>';
        },
      },
      {
        name: 'pastDue',
        data: 'pastDue',
        title: this.translateService.instant('general.pastDue'),
        iconName: 'fas fa-fw fa-exclamation-triangle',
        type: 'select',
        options: [
          { id: 'Pastdue', value: that.translateService.instant(('enums.pastDue.pastDue')) },
          { id: 'Imminent', value: that.translateService.instant(('enums.pastDue.imminent')) },
        ],
        render: function (data, type, row) {
          return '<span class="' + (data === 'Imminent' ? 'font-red' : 'font-orange') + '">' + data + '</span>'
        },
      },
      {
        name: 'lastMaintenanceEvent',
        data: 'lastMaintenanceEvent',
        type: 'date',
        render: function (data, type, row) {
          if (Moment.utc(data).year() <= 2000) {
            return '-';
          }

          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
        },
        title: this.translateService.instant('general.lastMaintenanceEvent')
      }, {
        name: 'lastMaintenanceOdo',
        data: 'lastMaintenanceOdo',
        defaultContent: '-',
        type: 'num',
        title: this.translateService.instant('general.lastMaintenanceOdo'),
        render: function (data, type, row) {
          return formatDecimal(data as number);
        },
      }, {
        name: 'lastMaintenanceEngineHours',
        data: 'lastMaintenanceEngineHours',
        defaultContent: '-',
        type: 'num',
        title: this.translateService.instant('general.lastMaintenanceEngineHours'),
        render: function (data, type, row) {
          return formatDecimal(data as number);
        },
      }, {
        name: 'currentOdoValue',
        data: 'currentOdoValue',
        defaultContent: '-',
        type: 'num',
        title: this.translateService.instant('general.currentOdoValue'),
        render: function (data, type, row) {
          return formatDecimal(data as number);
        },
      }, {
        name: 'currentEngineHour',
        data: 'currentEngineHour',
        defaultContent: '-',
        type: 'num',
        title: this.translateService.instant('general.currentEngineHour'),
        render: function (data, type, row) {
          return formatDecimal(data as number);
        },
      }, {
        name: 'nextMaintenanceOdo',
        data: 'nextMaintenanceOdo',
        defaultContent: '-',
        type: 'num',
        title: this.translateService.instant('general.nextMaintenanceOdo'),
        render: function (data, type, row) {
          return data === 0 ? '-' : formatDecimal(data as number);
        },
      }, {
        name: 'nextMaintenanceEngineHours',
        data: 'nextMaintenanceEngineHours',
        defaultContent: '-',
        type: 'num',
        title: this.translateService.instant('general.nextMaintenanceEngineHours'),
        render: function (data, type, row) {
          return data === 0 ? '-' : formatDecimal(data as number);
        },
      }, {
        name: 'hoursThreshold',
        data: 'hoursThreshold',
        defaultContent: '-',
        type: 'num',
        title: this.translateService.instant('general.hoursThreshold'),
        render: function (data, type, row) {
          return data === 0 ? '-' : formatDecimal(data as number);
        },
      }, {
        name: 'hoursRemainingForMaintenanceEvent',
        data: 'hoursRemainingForMaintenanceEvent',
        defaultContent: '-',
        type: 'num',
        title: this.translateService.instant('general.hoursRemainingForMaintenanceEvent'),
        render: function (data, type, row) {
          return data === 0 || data == null ? '-' : formatDecimal(data as number);
        },
      },
      {
        name: 'remarks',
        data: 'remarks',
        defaultContent: '-',
        title: this.translateService.instant('general.remarks'),
        render: function (data, type, row) {
          const result = data ?? '-';
          return '<div class="truncate">' + result + '</div>';
        }
      }, {
        name: 'averageKmPerDay',
        data: 'averageKmPerDay',
        defaultContent: '-',
        type: 'num',
        visible: false,
        title: this.translateService.instant('general.averageKmPerDay'),
        render: function (data, type, row) {
          return formatDecimal(data as number / 1000) + ' km';
        },
      }, {
        name: 'kilometersThreshold',
        data: 'kilometersThreshold',
        defaultContent: '-',
        type: 'num',
        title: this.translateService.instant('general.kilometersThreshold'),
        render: function (data, type, row) {
          return data === 0 ? '-' : formatDecimal(data as number) + ' km';;
        },
      }, {
        name: 'daysThreshold',
        data: 'daysThreshold',
        defaultContent: '-',
        type: 'num',
        visible: false,
        title: this.translateService.instant('general.daysThreshold'),
        render: function (data, type, row) {
          return data === 0 ? '-' : formatDecimal(data as number);
        },
      }, {
        name: 'daysRemainingNextMaintenanceEvent',
        data: 'daysRemainingNextMaintenanceEvent',
        defaultContent: '-',
        type: 'num',
        visible: false,
        title: this.translateService.instant('general.daysRemainingNextMaintenanceEvent'),
        render: function (data, type, row) {
          return data === 0 || data == null ? '-' : formatDecimal(data as number);
        },
      }, {
        name: 'kmRemainingNextMaintenanceEvent',
        data: 'kmRemainingNextMaintenanceEvent',
        defaultContent: '-',
        type: 'num',
        visible: false,
        title: this.translateService.instant('general.kmRemainingNextMaintenanceEvent'),
        render: function (data, type, row) {
          return data === 0 || data == null ? '-' : formatDecimal(data as number) + ' km';
        },
      }, {
        name: 'expectedMaintenanceDate',
        data: 'expectedMaintenanceDate',
        defaultContent: '-',
        type: 'date',
        visible: false,
        title: this.translateService.instant('general.expectedMaintenanceDate'),
        render: function (data, type, row) {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
        },
      }, {
        name: 'projectName',
        data: 'projectName',
        defaultContent: '-',
        visible: false,
        title: this.translateService.instant('general.projectName')
      }, {
        name: 'companyName',
        data: 'companyName',
        title: this.translateService.instant('general.companyName')
      }, {
        name: 'accountId',
        data: 'accountId',
        defaultContent: '-',
        title: this.translateService.instant('general.accountId'),
        visible: false
      }, {
        name: 'resellerDescription',
        data: 'resellerDescription',
        defaultContent: '-',
        title: this.translateService.instant('general.resellerDescription'),
        visible: false
      },
    ];

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'maintenanceDue_overview', this.translateService.instant('menu.deviceoverview')),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => {
          try {
            const columnSettings = await that.loadState(that.constructorName);
            that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            that.error = {};
            that.error.error = e;
            that.error.statusText = 'Error fetching column settings';

            return null;
          }
        })().then(result => {
          callback(result);
        });
      },
      order: [[2, 'asc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.maintenanceService.getPagingDueUrl(this.datePickerModel.getTime()),
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        console.log('init complete');
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'id_export']
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }
}