export class ErrorLog {

    public id: number;
    public description: string;
    public stack: string;
    public location: string;
    public username: string;
    public message: string;
    public createdDate: any;
}
