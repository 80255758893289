import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { generateEntityDetailLink } from 'app/common/globals';
import { SignalrService } from 'app/services/signalr/signalr.service';

@Component({
    selector: 'fh-notifications',
    templateUrl: 'notifications.component.html'
})
export class NotificationsViewComponent implements OnDestroy, OnInit {

    notifications = [];
    filter;
    currentPage;

    constructor(public signalrService: SignalrService, private cd: ChangeDetectorRef) {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.signalrService.notifications.subscribe(value => {
                this.notifications = [];

                value?.forEach(item => {
                    item.link = generateEntityDetailLink(item.entityType, item.entityId);
                    this.notifications.push(item);
                });

                this.cd.markForCheck();
            });
        }, 100);
    }

    clear() {
        this.signalrService.notificationList = [];
        this.signalrService.notifications.next(this.signalrService.notificationList);

        this.cd.markForCheck();
    }

    setIsRead() {
        this.signalrService.notificationList.forEach(notification => {
            notification.isRead = true;
        });

        this.signalrService.notifications.next(this.signalrService.notificationList);

        this.cd.markForCheck();
    }

    setRead(notification) {
        notification.isRead = true;

        this.signalrService.notifications.next(this.signalrService.notificationList);
        this.cd.markForCheck();
    }
}
