<div class="vertical-center" (document:mousemove)="onMouseMove($event)">
    <div class="page-content-inner login-background">
        <div class="container loginscreen" style="display:table-cell; vertical-align:middle;height:100%!important">

            <div class="login-box well single-page-block-inner blur-placeholder effect-3d-element">

                <div class='text-center' style="min-height: 145px;">
                    <div class="alert" [ngClass]="messageStatus" *ngIf="error">
                        <strong>{{error}}</strong>
                    </div>
                    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
                    <form novalidate #f="ngForm" action="" (keyup.enter)="clicked($event)">
                        <div class="site_login_logo"></div>
                        <h1 style="font-size: 40px; padding-bottom:20px; display:none" class="text-center">
                            {{'general.title' | translate }}
                        </h1>

                        <div class="form-group col-sm-12" [ngClass]="{ 'has-error': TheUserName.invalid && (TheUserName.dirty || TheUserName.touched) }">
                            <input name="username" value='' id="username-email" [(ngModel)]="UserName" placeholder='{{"login.emailoruser" | translate }}'
                                pattern="[^ @]*@[^ @]*" type="text" class="form-control" required #TheUserName="ngModel" />
                        </div>

                        <div class="form-group col-sm-6">
                            <button [disabled]="loading || resetDiabled" class="btn btn-primary btn-block" (click)="clicked($event)"
                                [disabled]="(TheUserName.touched && !UserName)"><i class="fa fa-fw fa-unlock"></i>
                                {{'login.reset' | translate }}</button>
                        </div>
                        <div class="form-group col-sm-6">
                            <button type="button" [routerLink]="['/Login']" class="btn btn-light btn-block">
                                {{'login.cancel' | translate }}</button>
                        </div>
                    </form>
                    <footer class="col-sm-12">
                        <div class="copy-text col-sm-12">{{'login.footer' | translate }} <a href="https://360locate.com" target="_blank">360locate</a> - {{appVersion}}</div>
                    </footer>
                </div>

            </div>
        </div>
    </div>
</div>