<nav class="navbar-default navbar-static-side noselect navbar-fo foSideBarContent" role="navigation">
  <div class="sidebar-collapse">
    <ul style="max-height: 100%;" class="nav metismenu" id="side-menu">
      <li class="nav-header">
        <div class="dropdown profile-element hand" [routerLink]="['/']">
          <div class="site_logo"></div>
        </div>
        <div class="logo-element hand" [routerLink]="['/']">
          <div class="fas"><img width="75%" height="75%" src='/assets/images/360fav-200x200.png' alt='Logo'></div>
        </div>
      </li>
      <li class="fleet-overview-tabs">
        <button [disabled]="mode !== 'Overview'" [class.active]="tab === 'Assets'" (click)="changeTab('Assets')" class="fleet-overview-tab">
          <i class='fas fa-fw fa-car-bus'></i>
          {{'general.assets' | translate}}
        </button>
        <button [disabled]="mode !== 'Overview'" [class.active]="tab === 'Drivers'" (click)="changeTab('Drivers')" class="fleet-overview-tab">
          <i class='fas fa-fw fa-id-card-alt'></i>
          {{'general.drivers' | translate}}
        </button>
        <button [disabled]="true || mode !== 'Overview'" [class.active]="tab === 'Geofences'" (click)="changeTab('Geofences')" class="fleet-overview-tab">
          <i class='fas fa-fw fa-draw-polygon'></i>
          {{'general.geofences' | translate}}
        </button>
      </li>

      <div [style.display]="mode !== 'History' ? 'table' : 'none'" class="input-group" style="padding: 10px; max-height: 55px;">
          <span class="input-group-addon">
              <i class="fa fa-fw fa-search"></i>
          </span>
          <input style="width:100%" type="text" min="0" class="form-control" (input)="filterMarkers()"
              placeholder="{{ 'general.search' | translate}}" [(ngModel)]="filter">
      </div>

      <div [style.display]="mode === 'History' ? 'table' : 'none'"  class="input-group" style="padding: 10px; max-height: 55px;">
        <span style="padding-top: 10px;" class="input-group-addon">
            <i class="fa fa-fw fa-calendar"></i>
        </span>
        <input placeholder="Daterangepicker"
            style="width:100%; margin-top: 0px; width: 100%; margin-bottom: -5px;"
            placeholder="Datepicker" type="text" [bsConfig]="dpConfig" class="form-control" placement="right"
            bsDaterangepicker (onHidden)="dateChanged('hidden')" [(ngModel)]="daterangepickerModel">
        <div style="clear:both"></div>
      </div>

      <div *ngIf="getFleetOverviewState() | async as state" class="fleet-overview-list" style="overflow-y: scroll; height: calc(100vh - 255px);">
        <div *ngIf="state !== 'Loaded'">
          <li class="fleet-overview-header">
            <a [ngSwitch]="state" style="display: flex; align-items: center;">
              <span *ngIf="state === 'Initialize'">
                {{ 'general.loadingData' | translate }}
              </span>
              <span *ngIf="state === 'LoadingMap'">
                {{ 'general.loadingMap' | translate }}
              </span>
            </a>
          </li>
        </div>

        <!-- Assets -->
        <div [style.display]="state === 'Loaded' && tab === 'Assets' ? 'block' : 'none'">
          <div [style.display]="mode === 'Overview' ? 'block' : 'none'">
            <ng-container *ngFor="let assetGroup of assetGroups | keyvalue: valueAscOrder | slice:0:maxGroups">
              <ng-container *ngIf="(assetGroup.value.items | stringFilter: filter) as filteredGroup">
                <li [style.display]="!(filter.length > 0 && filteredGroup.length === 0) ? 'block' : 'none'" class="fleet-overview-header" style="position: relative;">
                  <div style="position: absolute; top: 18px; left: 9px;" class="fa_checkbox"
                    [class.intermediate]="!watchGroupSelected(assetGroup.value.items)">
                    <input [id]="'assetGroupSelection_' + assetGroup.key" type="checkbox"
                      [name]="'assetGroupSelection_' + assetGroup.key" [ngModel]="assetGroupSelection.get(assetGroup.key)"
                      (ngModelChange)="$event.stopPropagation?.(); selectGroup(assetGroup, $event)" />
                    <label [for]="'assetGroupSelection_' + assetGroup.key"></label>
                  </div>

                  <a style="display: flex; align-items: center; margin-left: 20px;">
                    <span style="flex: auto; overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;" class="nav-label">{{assetGroup.value.name}}</span>
                    <span style="padding: 0 5px;">({{filteredGroup.length}})</span>
    
                    <ng-container *ngIf="watchGroupVisibility(hiddenAssets, assetGroup.value.items); else groupInvisible;">
                      <i (click)="$event.stopPropagation?.(); changeGroupVisibility(hiddenAssets, false, assetGroup.value.items);"
                        style="align-items: center; min-width: 25px; min-height: 25px; display: flex; justify-content: center; margin-right: 5px;"
                        class='fa fa-fw fa-eye'></i>
                    </ng-container>
    
                    <ng-template #groupInvisible>
                      <i (click)="$event.stopPropagation?.(); changeGroupVisibility(hiddenAssets, true, assetGroup.value.items);"
                        style="align-items: center; min-width: 25px; min-height: 25px; display: flex; justify-content: center; margin-right: 5px; opacity: 0.4;"
                        class='fa fa-fw fa-eye-slash'></i>
                    </ng-template>
    
                    <span class="fa arrow"></span>
                  </a>
    
                  <ul class="nav nav-second-level collapse">
                    <li [ngClass]="{ 'active': device.name === selectedDevice }"
                      class="fleet-overview-item fontColor {{calculatedDeviceState.get(device.id)?.color}}" (click)="selectAsset(device.name)"
                      *ngFor="let device of filteredGroup">
                      <div style="margin-right: 5px;" class="fa_checkbox">
                        <input [id]="'deviceSelection_' + device.id" type="checkbox" [name]="'deviceSelection_' + device.id"
                          [ngModel]="deviceSelection.get(device.id)?.checked"
                          (change)="shouldToggleGroup($any($event.target).checked, device.id)"
                          (ngModelChange)="$event.stopPropagation?.(); deviceSelection.set(device.id, { checked: $event, name: device.name })" />
                        <label [for]="'deviceSelection_' + device.id"></label>
                      </div>
                      <span style="display: flex; flex: auto; flex-direction: column; font-size: 13px; font-weight: 500;"
                        class="nav-label">
                        {{device.name}}
                        <span style="font-size: 11px; font-weight: 300;"
                          *ngIf="lastCommunication.get(device.id) as momentTime;">
                          {{ momentTime | amTimeAgo }}
                        </span>
                      </span>
                      <i (click)="$event.stopPropagation?.(); changeVisibility(hiddenAssets, device.id);"
                        style="align-items: center; min-width: 25px; min-height: 25px; display: flex; justify-content: center; margin-right: 5px; cursor: pointer;"
                        [ngClass]="hiddenAssets.get(device.id) === true ? 'fa-eye-slash' : 'fa-eye'"
                        class='fa fa-fw eye-active {{calculatedDeviceState.get(device.id)?.color}}'></i>
                      <span style="padding-left: 15px; opacity: 0;" class="fa arrow"></span>
                    </li>
                    <li *ngIf="filteredGroup.length > maxListItems" class="fontColor"
                      style="display: flex; align-items: center; padding: 15px 2px 15px 9px; background: white;">
                      <span class="nav-label">We do only show {{maxListItems}} items per group. If you want to see more
                        please refine search</span>
                    </li>
                  </ul>
                </li>
              </ng-container>
            </ng-container>

            <li *ngIf="assetGroups.size > maxGroups" class="fleet-overview-header" style="position: relative;">
              <a style="display: flex; align-items: center; pointer-events: none;">
                <span>
                  <i style="margin-right: 8.5px;">&ndash;</i>
                  Support for {{maxGroups}} groups each search
                </span>
              </a>
            </li>
          </div>

          <ng-container *ngIf="mode === 'History'">
            <li *ngFor="let asset of history | keyvalue; index as i;"
              [class.active]="selectedHistoryDevice === asset.key" class="fleet-overview-header">
              <a (click)="activateHistoryToggle(asset.key, asset.value.id);"
                style="display: flex; align-items: center; padding: 7.5px 9px 7.5px 9px; min-height: 45px;">
                <span class="fontColor"
                  style="padding: 3px 10px; background: white !important; border-radius: 2px; margin-right: 7px; font-weight: 600;">{{i
                  + 1}}</span>
                <span style="flex: auto; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  class="nav-label">{{asset.value.name}}</span>
                <span class="fa arrow"></span>
              </a>

              <ng-container *ngIf="selectedHistoryDevice === asset.key">
                <a *ngIf="asset.value.trips.length === 0" style="display: flex; position: relative; background: white;">
                  <ngx-loading [show]="asset.value.loading"></ngx-loading>
                  {{'general.noDataFound' | translate}}
                </a>

                <ng-container *ngFor="let trip of renderTrips(asset.value.trips); index as i;">
                  <a *ngIf="isNewDate(trip)"
                    style="display: flex; background-color: #e5e5e5; max-height: 40px; align-items: center; border-bottom: 1px solid #ccc !important; font-weight: 500;">
                    {{ trip.beginDateTime | amDateFormat:'dddd LL' }}
                  </a>

                  <a (click)="openTrip(trip, i + 1)" class="fleet-overview-item"
                    [ngStyle]="{'border-left': '7px solid ' + colorArray[(i + 1) % colorArray.length]}">
                    <span class="fontColor" style="margin-right: 15px; font-weight: 600;"
                      [ngStyle]="{'color': colorArray[(i + 1) % colorArray.length]}">{{i + 1}}</span>
                    <span class="fontColor" style="display: flex; flex-direction: column; flex: auto; overflow: hidden;">
                      <span style="font-weight: 500; font-size: 13px;">
                        {{ trip.beginDateTime | amDateFormat:'HH:mm' }} / {{
                        actualRound(trip.segmentDistanceInKilometers, 2)?.toLocaleString() }} {{ 'general.km' |
                        translate }}
                      </span>
                      <span
                        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 12px; font-weight: 400;">
                        {{trip.durationHumanized}} / {{trip.endAddressFull}}
                      </span>
                    </span>
                    <i (click)="$event.stopPropagation?.(); changeTripVisibility(trip, i + 1)"
                      [class.green]="trip.plotted === true"
                      style="align-items: center; min-width: 25px; min-height: 25px; display: flex; justify-content: center;"
                      class='fa fa-fw fa-eye eye-active fontColor'></i>
                    <span class="fontColor fa arrow" [class.fa-flip-horizontal]="trip.isOpen !== true" style="margin-left: 5px;"></span>
                  </a>

                  <ul *ngIf="trip.isOpen === true" style="position: relative; padding: 0; background: white;">
                    <ng-container *ngIf="trip.loading === true; else tripContent">
                      <li
                        style="display: flex; min-height: 50px; align-items: center; font-weight: 600; padding: 0px 20px;">
                        <ngx-loading [show]="true"></ngx-loading>
                        {{'general.loadingDetails' | translate}}
                      </li>
                    </ng-container>
                    <ng-template #tripContent>
                      <li style="display: flex;">
                        <i style="align-items: center; min-width: 40px; min-height: 40px; display: flex; justify-content: center; background: black; margin-right: 5px; color: white;"
                          class='fas fa-lg fa-fw fa-flag-checkered'></i>
                        <div
                          style="display: flex; flex-direction: column; flex: auto; overflow: hidden; justify-content: center;"
                          class="fontColor nav-label">
                          <span style="font-weight: 500; font-size: 13px;">
                            Einde rit - {{ trip.endDateTime | amDateFormat:'HH:mm' }}
                          </span>
                          <span
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 12px; font-weight: 400;">
                            {{ trip.endAddressFull }}
                          </span>
                        </div>
                      </li>
                      <li style="display: flex;">
                        <i style="align-items: center; min-width: 40px; min-height: 40px; display: flex; justify-content: center; color: rgb(102, 102, 102); background-color: rgb(240, 240, 240); margin-right: 5px;"
                          class='fas fa-lg fa-fw fa-question-circle'></i>
                        <div
                          style="display: flex; flex-direction: column; flex: auto; overflow: hidden; justify-content: center;"
                          class="fontColor nav-label">
                          <span
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 12px; font-weight: 400;">
                            {{'general.noDataFound' | translate}}
                          </span>
                        </div>
                      </li>
                      <li style="display: flex;">
                        <i style="align-items: center; min-width: 40px; min-height: 40px; display: flex; justify-content: center; background: green; margin-right: 5px; color: white;"
                          class='fas fa-lg fa-fw fa-clock'></i>
                        <div
                          style="display: flex; flex-direction: column; flex: auto; overflow: hidden; justify-content: center;"
                          class="fontColor nav-label">
                          <span style="font-weight: 500; font-size: 13px;">
                            Start rit - {{ trip.beginDateTime | amDateFormat:'HH:mm' }}
                          </span>
                          <span
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 12px; font-weight: 400;">
                            {{ trip.beginAddressFull }}
                          </span>
                        </div>
                      </li>
                      <li style="display: flex; min-height: 55px;">
                        <div
                          style="display: flex; flex-direction: column; padding: 0 10px; overflow: hidden; justify-content: center;"
                          class="fontColor nav-label">
                          <span style="font-weight: 500; font-size: 13px;">
                            Max snelheid:
                          </span>
                          <span
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 12px; font-weight: 400;">
                            {{ trip.maxSpeed }} km/u
                          </span>
                        </div>
                        <button
                          style="flex: auto; user-select: none; border: 1px solid transparent; background: white;">
                          <i style="align-items: center; min-width: 40px; min-height: 40px; display: flex; justify-content: center; background: green; margin-right: 5px;"
                            class='fas fa-lg fa-fw fa-play-circle'></i>
                        </button>
                      </li>
                    </ng-template>
                  </ul>
                </ng-container>
              </ng-container>
            </li>
          </ng-container>

          <ng-container *ngIf="mode === 'Live'">
            <ng-container *ngFor="let liveGroup of live | keyvalue: valueAscOrder | slice:0:maxGroups">
              <ng-container *ngIf="(liveGroup.value.items | stringFilter: filter) as filteredLiveGroup">
                <li [style.display]="!(filter.length > 0 && filteredLiveGroup.length === 0) ? 'block' : 'none'" class="fleet-overview-header" style="position: relative;">
                  <a (click)="selectedLiveGroup = (selectedLiveGroup !== liveGroup.key && liveGroup.key)" style="display: flex; align-items: center;">
                    <span style="flex: auto; overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;" class="nav-label">{{liveGroup.value.name}}</span>
                    <span style="padding: 0 5px;">({{filteredLiveGroup.length}})</span>
    
                    <ng-container *ngIf="watchGroupVisibility(liveHidden, liveGroup.value.items); else groupInvisible;">
                      <i (click)="$event.stopPropagation?.(); changeGroupVisibility(liveHidden, false, liveGroup.value.items);"
                        style="align-items: center; min-width: 25px; min-height: 25px; display: flex; justify-content: center; margin-right: 5px;"
                        class='fa fa-fw fa-eye'></i>
                    </ng-container>
    
                    <ng-template #groupInvisible>
                      <i (click)="$event.stopPropagation?.(); changeGroupVisibility(liveHidden, true, liveGroup.value.items);"
                        style="align-items: center; min-width: 25px; min-height: 25px; display: flex; justify-content: center; margin-right: 5px; opacity: 0.4;"
                        class='fa fa-fw fa-eye-slash'></i>
                    </ng-template>
    
                    <span class="fa arrow"></span>
                  </a>
    
                  <ul *ngIf="filter.length > 0 || selectedLiveGroup === liveGroup.key" class="nav nav-second-level">
                    <li [ngClass]="{ 'active': device.name === selectedDevice }"
                      class="fleet-overview-item fontColor {{calculatedDeviceState.get(device.id)?.color}}" (click)="selectAsset(device.name)"
                      *ngFor="let device of filteredLiveGroup | orderBy:['time', 'status']:true">
                      <span style="display: flex; flex: auto; flex-direction: column; font-size: 13px; font-weight: 500;"
                        class="nav-label">
                        {{device.name}}
                        <span style="font-size: 11px; font-weight: 300;"
                          *ngIf="lastCommunication.get(device.id) as momentTime;">
                          {{ momentTime | amTimeAgo }}
                        </span>
                      </span>
                      <i (click)="$event.stopPropagation?.(); changeVisibility(liveHidden, device.id);"
                        style="align-items: center; min-width: 25px; min-height: 25px; display: flex; justify-content: center; margin-right: 5px; cursor: pointer;"
                        [ngClass]="liveHidden.get(device.id) === true ? 'fa-eye-slash' : 'fa-eye'"
                        class='fa fa-fw eye-active {{calculatedDeviceState.get(device.id)?.color}}'></i>
                      <span style="padding-left: 15px; opacity: 0;" class="fa arrow"></span>
                    </li>
                    <li *ngIf="filteredLiveGroup.length > maxListItems" class="fontColor"
                      style="display: flex; align-items: center; padding: 15px 2px 15px 9px; background: white;">
                      <span class="nav-label">We do only show {{maxListItems}} items per group. If you want to see more
                        please refine search</span>
                    </li>
                  </ul>
                </li>
              </ng-container>
            </ng-container>

            <li *ngIf="assetGroups.size > maxGroups" class="fleet-overview-header" style="position: relative;">
              <a style="display: flex; align-items: center; pointer-events: none;">
                <span>
                  <i style="margin-right: 8.5px;">&ndash;</i>
                  Support for {{maxGroups}} groups each search
                </span>
              </a>
            </li>
          </ng-container>
        </div>

        <!-- Drivers -->
        <div [style.display]="state === 'Loaded' && tab === 'Drivers' ? 'block' : 'none'">
          <div [style.display]="mode === 'Overview' ? 'block' : 'none'">
              <ng-container *ngFor="let driverGroup of driverGroups | keyvalue: valueAscOrder">
                <ng-container *ngIf="(driverGroup.value.items | stringFilter: filter) as filteredGroup">
                  <li [style.display]="!(filter.length > 0 && filteredGroup.length === 0) ? 'block' : 'none'" class="fleet-overview-header" style="position: relative;">
                    <div style="position: absolute; top: 18px; left: 9px;" class="fa_checkbox">
                      <input [id]="'driverGroupSelection_' + driverGroup.key" type="checkbox"
                        [name]="'driverGroupSelection_' + driverGroup.key" [ngModel]="driverGroupSelection.get(driverGroup.key)"
                        (ngModelChange)="$event.stopPropagation?.(); driverGroupSelection.set(driverGroup.key, $event)" />
                      <label [for]="'driverGroupSelection_' + driverGroup.key"></label>
                    </div>

                    <a style="display: flex; align-items: center; margin-left: 20px;">
                      <span style="flex: auto; overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;" class="nav-label">{{driverGroup.value.name}}</span>
                      <span style="padding: 0 5px;">({{filteredGroup.length}})</span>
                      <span class="fa arrow"></span>
                    </a>

                    <ul class="nav nav-second-level collapse">
                      <li class="fleet-overview-item fontColor"
                        style="display: flex; align-items: center; padding: 0px;"
                        *ngFor="let driver of filteredGroup">
                        <div style="flex: 1;" class="fa_checkbox">
                          <input [id]="'driverSelection_' + driver.id" type="checkbox" [name]="'driverSelection_' + driver.id"
                            [ngModel]="driverSelection.get(driver.id)?.checked"
                            (ngModelChange)="$event.stopPropagation?.(); driverSelection.set(driver.id, { checked: $event, name: driver.name })" />
                          <label style="display: flex; padding: 15px 2px 15px 9px;" [for]="'driverSelection_' + driver.id">
                            <span style="margin-left: 4px; margin-top: -2.5px;" class="nav-label">{{driver.name}}</span>
                          </label>
                        </div>
                      </li>
                      <li *ngIf="filteredGroup.length > maxListItems" class="fontColor"
                        style="display: flex; align-items: center; padding: 15px 2px 15px 9px; background: white;">
                        <span class="nav-label">We do only show {{maxListItems}} items per group. If you want to see more please
                          refine search</span>
                      </li>
                    </ul>
                  </li>
                </ng-container>
              </ng-container>
          </div>

          <ng-container *ngIf="mode === 'History'">
            <li *ngFor="let driver of history | keyvalue; index as i;"
              [class.active]="selectedHistoryDevice === driver.key" class="fleet-overview-header">
              <a (click)="activateHistoryToggle(driver.key, driver.value.id);"
                style="display: flex; align-items: center; padding: 7.5px 9px 7.5px 9px; min-height: 45px;">
                <span class="fontColor"
                  style="padding: 3px 10px; background: white !important; border-radius: 2px; margin-right: 7px; font-weight: 600;">{{i
                  + 1}}</span>
                <span style="flex: auto; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  class="nav-label">{{driver.value.name}}</span>
                <span class="fa arrow"></span>
              </a>
            </li>
          </ng-container>
        </div>
      </div>
    </ul>
  </div>

  <!-- Navigation -->
  <div style="position: relative; padding: 1px 0px 0px 0px;" class="buttonContent">
    <div class="col-12 d-flex pl-0 pr-0">
      <button *ngIf="mode !== 'Overview'" (click)="changeMode('Overview')" class="btn app-btn-main flex-fill rounded-0"
        style="width: 50%;" type="button"><i class="fa fa-history"></i>
        {{'general.overview' | translate}}</button>
        <!-- && tab !== 'Drivers' -->
      <button *ngIf="mode !== 'History'" [disabled]="!hasAnySelected([deviceSelection, assetGroupSelection]) || tab !== 'Assets'"
        (click)="changeMode('History')" class="btn app-btn flex-fill rounded-0" style="width: 50%;" type="button"><i
          class="fa fa-history"></i>
        {{'general.history' | translate}}</button>
      <button *ngIf="mode !== 'Live'" [disabled]="!hasAnySelected([deviceSelection, assetGroupSelection]) || tab !== 'Assets'"
        (click)="changeMode('Live')" class="btn app-btn flex-fill rounded-0" style="width: 50%;" type="button"><i
          class="fa fa-heartbeat"></i>
        {{'general.live' | translate}}</button>
    </div>
  </div>
</nav>