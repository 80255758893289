import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Warehouse } from 'app/models/warehouse.model';
import { WarehouseService } from 'app/services/warehouse/warehouse.service';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';

@Component({
    selector: 'fh-warehouses-log',
    templateUrl: 'warehouseLog.template.html'
})
export class WarehousesLogViewComponent implements OnInit {
    warehousesLog: AuditLog[];
    sub;
    warehouse: Warehouse;
    loading = false;

    error: any;
    success: any;

    permissionName = 'FleetManagement_Log';
    warehouseId: any;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private warehouseService: WarehouseService, private route: ActivatedRoute, private router: Router) {
        this.warehouse = null;
        this.warehousesLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.warehouse = new Warehouse;
        this.warehouse.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.warehouseId = id;
            this.warehouseService.getWarehouseById(id).subscribe(warehouses => {
                this.warehouse = warehouses;

                if (this.warehouse == null) {
                    this.router.navigate(['/Warehouses/Overview'])
                }

                this.auditLogService.getAuditLogByWarehouse(id).subscribe(deviceLog => {
                    console.log('loading done');
                    this.warehousesLog = deviceLog;
                    this.loading = false;
                }, error => {
                    this.error = error;
                    this.loading = false;
                });
            });
        });
    }
}
