<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/Overview">{{'general.users' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/UserRoles/Overview">{{'general.userRoles' | translate }}</a>
            </li>
            <li class="active">{{'menu.add' | translate }}</li>
        </ol>
    </div>
    <fh-user-navigation>
        <li class="active"><a href="/#/Users/UserRoleDetails/Add/"><i class="fas fa-fw fa-restroom"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a></li>
    </fh-user-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" *ngIf="error || success">
        <div class="col-lg-12">
            <fh-error [success]="success" [error]="error"></fh-error>
        </div>
    </div>
    <div class="row">
        <form #userRoleForm="ngForm">
            <div class="col-md-12">
                <div class="ibox" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-restroom"></i> {{'general.userRoles' | translate }}
                        </h5>
                    </div>
                    <fh-user-role-details [userRole]="userRole" [formMode]="formMode"
                        (onAccountChanged)="accountChanged($event)" (onResellerChanged)="resellerChanged($event)">
                    </fh-user-role-details>
                    <fh-save-footer [valid]="!userRoleForm.invalid" (onInsert)="onInsert()"
                        [allowAdd]="permissions['UserRoles_Add']" [formMode]="formMode">
                    </fh-save-footer>
                </div>
            </div>
            <div class="col-md-12">
                <fh-user-role-permission-content [permissionsMap]="permissionsMap" [formMode]="formMode">
                </fh-user-role-permission-content>
            </div>
        </form>
    </div>
</div>