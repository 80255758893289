<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Geofences/Overview">{{'general.geofences' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Geofences/Overview">{{'menu.add' | translate }}</a>
            </li>
        </ol>
    </div>
    <fh-geofence-navigation>
        <li class="active"><a href="/#/Geofences/Add/"><i class="fa fa-fw fa-draw-polygon"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a></li>
    </fh-geofence-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0;">
        <ngx-loading [show]="loading"></ngx-loading>
        <fh-leaflet-map [editableGeofence]="geofence" [height]="mapHeight" [allowEdit]="allowEdit" [showSearch]=true
            (mapReady)="onMapReady($event)" [allowBookmarks]="allowBookmarks" [showGeofenceSwitch]=false
            [showSaveAsGeofence]="false" (onSave)="onSaveShape($event)">
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-fw fa-draw-polygon"></i> {{'general.geofence' | translate }}
                            </h5>
                        </div>
                        <fh-geofence-details [geofence]="geofence" [formMode]="formMode" [loading]="loading"
                            (onAccountChanged)="accountChanged($event)"></fh-geofence-details>
                        <fh-save-footer [valid]="!userForm.invalid && geofence.geoJson != null" (onInsert)="onInsert()"
                            [allowAdd]="permissions['Geofences_Add']" [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">

                <div class="ibox" *ngIf="showMapOnSide">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <fh-leaflet-map (mapReady)="onMapReady($event)" [editableGeofence]="geofence" [height]="mapHeight"
                        [allowEdit]="allowEdit" [showSearch]=true [allowBookmarks]="allowBookmarks"
                        [showGeofenceSwitch]=false (onSave)="onSaveShape($event)">
                    </fh-leaflet-map>
                </div>

                <fh-group-overview [loading]="loadingGroups" [groups]="geofenceGroups"
                    name="{{'general.geofenceGroups' | translate }}">
                </fh-group-overview>
            </div>
        </div>
    </div>
</fh-permission-handler>