import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { DeviceType } from 'app/models/devicetype.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import 'rxjs/add/observable/of';
import { Device } from '../../../models/device.model';
import { DeviceService } from '../../../services/device/device.service';


@Component({
    selector: 'fh-device-details',
    templateUrl: 'deviceDetails.template.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                // css styles at start of transition
                style({ opacity: 0 }),

                // animation and styles at end of transition
                animate('0.5s', style({ opacity: 1 }))
            ]),
        ]
        )
    ],
})
export class DeviceDetailsComponent implements OnChanges, OnInit {

    @Input() device: Device;
    @Input() formMode: any = FormMode.read;
    @Input() loading = false;

    permissions: {};
    deviceTypes: DeviceType[];

    constructor(private deviceTypeService: DeviceTypeService, private modalService: BsModalService, private deviceService: DeviceService, private authentication: AuthenticationService) {
        this.permissions = this.authentication.permissions;
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
    }

    // tslint:disable:no-bitwise
    isValidLuhn(strToTest, multiple = 10) {
        let digit = 0;
        let sum = 0;
        const length = strToTest.length;
        let odd = false;
        for (let i = (length - 1); i >= 0; i--) {
            digit = parseInt(strToTest[i], 10) | 0;
            if (odd === true) {
                digit = digit * 2 | 0;
            }
            if (digit > 9) {
                digit = digit - 9;
            }
            odd = !odd;
            sum += digit;
        }

        const res = sum % multiple;
        if (res === 0) {
            return 0;
        }

        return multiple - (res);
    }

    isValidIccid(iccid): boolean {
        // 89148000000745809013

        if (!iccid) {
            return false;
        }

        // 89       = telecom
        // 1        = united states
        // [000-999] = Provider
        // {13}     = sim account
        // {1}      = luhn check digit
        const regex: RegExp = new RegExp(/^(89)(\d{13,20})(\d)$/);

        // check to see if the pattern is valid followed by the Luhn checksum
        const validLuhn = this.isValidLuhn(iccid);
        const validRegex = iccid.toString().match(regex);

        return (validRegex != null && validLuhn === 0);

        return false;

    }
}
