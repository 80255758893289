import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { ScheduleAssignment } from 'app/models/triggerSchedule.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { TriggerScheduleService } from 'app/services/triggerSchedule/triggerSchedule.service';
import { ConfirmationModalComponent } from './confirmationModal.component';
import { ScheduleAssignmentModalComponent } from './scheduleAssignmentModal.component';

import * as moment from 'moment-timezone';

@Component({
    selector: 'fh-schedule-assignments',
    templateUrl: 'scheduleAssignmentsOverview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ScheduleAssignmentsOverviewComponent implements OnInit, OnChanges {
    @Input() assetId: any;
    @Input() assetGroupId: any;
    @Input() projectId: any;
    @Input() accountId: any;
    @Input() selectedAccountId: any;
    @Input() formMode = FormMode.read;
    @Input() deletePermission: string;
    allowSelect = false;

    scheduleAssignment: ScheduleAssignment;
    scheduleAssignments: ScheduleAssignment[];
    permissions = {};

    saving = false;
    loading = false;

    filter;

    success;
    error;

    constructor(private authenticationService: AuthenticationService, private triggerScheduleService: TriggerScheduleService, private cd: ChangeDetectorRef) {
        this.permissions = this.authenticationService.permissions;
        this.scheduleAssignment = new ScheduleAssignment();
        this.scheduleAssignments = [];
    }

    ngOnInit(): void {
        this.getScheduleAssignments();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getScheduleAssignments();
    }

    getScheduleAssignments() {
        this.loading = true;

        if (this.assetId) {
            this.triggerScheduleService.getScheduleAssignmentsByAssetId(this.assetId).subscribe(scheduleAssignments => {
                this.scheduleAssignments = scheduleAssignments;
                this.scheduleAssignment.assetId = this.assetId;
                this.loading = false;
                this.cd.markForCheck();
            });
        } else if (this.assetGroupId) {
            this.triggerScheduleService.getScheduleAssignmentsByAssetGroupId(this.assetGroupId).subscribe(scheduleAssignments => {
                this.scheduleAssignments = scheduleAssignments;
                this.scheduleAssignment.assetgroupId = this.assetGroupId;
                this.loading = false;
                this.cd.markForCheck();
            });
        } else if (this.projectId) {
            this.triggerScheduleService.getScheduleAssignmentsByProjectId(this.projectId).subscribe(scheduleAssignments => {
                this.scheduleAssignments = scheduleAssignments;
                this.scheduleAssignment.projectId = this.projectId;
                this.loading = false;
                this.cd.markForCheck();
            });
        } else if (this.accountId) {
            this.triggerScheduleService.getScheduleAssignmentsByAccountId(this.accountId).subscribe(scheduleAssignments => {
                this.scheduleAssignments = scheduleAssignments;
                this.scheduleAssignment.accountId = this.accountId;
                this.loading = false;
                this.cd.markForCheck();
            });
        }
    }

    deleteItem(scheduleAssignment: ScheduleAssignment) {
        this.triggerScheduleService.deleteScheduleAssignmentById(scheduleAssignment.id, scheduleAssignment.triggerScheduleId).subscribe(
            this.onDeleteItemSuccess.bind(this),
            this.onDeleteItemError.bind(this),
        );
    }

    setFormMode(mode: FormMode) {
        this.formMode = mode;
    }

    assignSchedule(_: number, confirmationModal: ConfirmationModalComponent, scheduleAssignmentComponent: ScheduleAssignmentModalComponent) {
        confirmationModal.hideModal();
        this.saving = true;

        if (this.scheduleAssignment.startDate) {
            this.scheduleAssignment.startDate = moment(this.scheduleAssignment.startDate).tz(this.authenticationService.getTimeZoneIana()).startOf('day').toDate();
        }

        if (this.scheduleAssignment.endDate) {
            this.scheduleAssignment.endDate = moment(this.scheduleAssignment.endDate).tz(this.authenticationService.getTimeZoneIana()).endOf('day').toDate();
        }

        this.triggerScheduleService.assignSchedule(this.scheduleAssignment).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Schedule is successfully assigned.'
            };

            this.saving = false;
            this.setFormMode(FormMode.read);
            this.getScheduleAssignments();
            this.cd.markForCheck();
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    onDeleteItemSuccess(result: any) {
        this.error = null;
        this.success = null;

        if (result.isSuccess) {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'ScheduleAssignment has been successfully removed.',
            };
        } else {
            this.success = null;
            this.error = {
                statusText: 'Error',
                error: result.message
            };
        }

        this.getScheduleAssignments();
    }

    onDeleteItemError(result: any) {
        this.error = null;
        this.success = null;

        if (!result.isSuccess) {
            this.error = {
                statusText: 'Error',
                error: 'Could not remove ScheduleAssignment.',
            };
        }
    }
}
