<div class="ibox" style="position:relative">
    <div class="ibox-title">
        <h5>
            <i class="fas fa-fw fa-list-ul"></i> {{'general.customFields' | translate }}
        </h5>
    </div>
    <div class="ibox-content slick" style="position: relative;">
        <form #customForm="ngForm">
            <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                <thead>
                    <tr>
                        <th>{{ 'general.name' | translate }}</th>
                        <th>{{ 'general.value' | translate }}</th>
                        <th *ngIf="!(formMode == 1)" style="width: 40px; padding-top: 18px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!(formMode == 1)">
                        <td style="width: auto">
                            <input [readonly]="formMode == 1" type="text" required name="customName"
                                [(ngModel)]="customName" class="form-control table-inline">
                        </td>
                        <td style="width: auto;">
                            <input [readonly]="formMode == 1" type="text" required name="customValue"
                                [(ngModel)]="customValue" class="form-control table-inline">
                        </td>
                        <td style="width: 40px; padding-top: 18px;">
                            <button class="btn btn-primary pull-right btn-grid" [disabled]="customForm.invalid"
                                (click)="addRow()">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let customProperties of customFields">
                        <td style="width: auto">
                            <input type="text" [readonly]="true" class="form-control table-inline"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="customProperties.key">
                        </td>
                        <td style="width: auto;">
                            <input type="text" [readonly]="true" class="form-control table-inline"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="customProperties.value">
                        </td>
                        <td *ngIf="!(formMode == 1)" style="width: 40px; padding-top: 18px;">
                            <button class="btn btn-primary pull-right btn-grid" (click)="removeRow(customProperties)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="customFields.length === 0">
                        <td colspan="3">
                            {{'general.nodatafound' | translate }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>