<!-- Wrapper-->
<div id="wrapper">

  <!-- Left navigation bar -->
  <fh-navigation></fh-navigation>
  <!-- Main page wrapper -->
  <div id="page-wrapper" class="gray-bg">

    <!-- Top navigation -->
    <fh-topnavbar></fh-topnavbar>

    <fh-notify-notifications-container></fh-notify-notifications-container>

    <!-- Main view/routes wrapper-->
    <router-outlet></router-outlet>

    <!-- Footer -->
    <fh-footer></fh-footer>

  </div>
  <!-- End page wrapper-->

</div>
<!-- End wrapper-->