import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { CarSharingGarage } from 'app/models/carsharingGarage.model';
import { CarSharingService } from 'app/services/carsharing/carsharing.service';

@Component({
  selector: 'fh-fh-garage-add',
  templateUrl: 'garageAdd.template.html'
})
export class CarSharingGaragesAddViewComponent implements OnInit {
  garage: CarSharingGarage;

  token: string;
  loading = false;
  loadingGroups = false;

  allowEdit = true;
  allowBookmarks = false;

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string; };
  selectedGroups = [];
  selectedGroupsSource = [];
  groupName;

  permissionName = 'Modules_HasPrivateFleet';

  constructor(private translateServe: TranslateService, private carsharingService: CarSharingService, private authenticationService: AuthenticationService, private router: Router) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();

    this.selectedGroups = [];
  }

  ngOnInit() {
    this.garage = new CarSharingGarage();
  }

  onInsert() {
    this.loading = true;

    this.carsharingService.saveGarage(this.garage).subscribe(result => {
      this.success = {
        statusText: 'Success',
        success: this.translateServe.instant('general.saveSuccessRedirect', { entity: 'garage' })
      };

      setTimeout(() => {
        this.router.navigate(['/CarSharing/GarageDetails/Index/' + result.id]);
      }, 2000);

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }
}
