<!-- When showHeader is set -->
<ng-container *ngIf="showHeader && languageLoaded">
    <div class="modal-header" style="padding: 0px; overflow: hidden;position: relative;">
        <div class="f1-steps">
            <div class="f1-step" *ngFor="let stepItem of steps"
                [ngClass]="stepItem.id < step ? 'activated' : stepItem.id == step ? 'active' : '' ">
                <span class="f1-step-header-text">
                    <i class="fa fa-fw" [ngClass]="stepItem.icon"></i> <span
                        class="f1-step-header-title">{{stepItem.stepTitle | translate}}</span>
                </span>
            </div>
        </div>
    </div>
</ng-container>

<div [ngClass]="showHeader ? 'ibox f1-step-ibox' : ''">
    <div [ngClass]="showHeader ? 'ibox-content f1-step-ibox-content slick' : ''" style="position:relative">
        <ng-content>
        </ng-content>

        <div class="modal-footer">
            <div class="my-wizard__footer">
                <button class="btn btn-primary" style="min-width:120px"
                [style.visibility]="isOnFirstStep() ? 'hidden' : 'visible'" (click)="handlePrevious()">
                    <i class="fa fa-chevron-left"></i> {{ 'grid.previous' | translate }}
                </button>
                {{step}} / {{stepCount}}
                <button class="btn btn-primary" style="min-width:120px" *ngIf="!isOnFinalStep()"
                    [disabled]="checkDisabled()" (click)="handleNext()">
                    {{ 'grid.next' | translate }}
                    <i class="fa fa-chevron-right"></i>
                </button>
                <button class="btn btn-primary" style="min-width:120px" *ngIf="isOnFinalStep()"
                    [disabled]="checkDisabled()" (click)="handleFinish()">
                    <i class="fa fa-save"></i> {{ 'grid.finish' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>