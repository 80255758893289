import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode, Form, StorageType } from 'app/common/enums';
import { EntityType } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { WaslService } from 'app/services/wasl/wasl.service';
import { GeofenceService } from 'app/services/geofence/geofence.service';
import { Geofence } from 'app/models/geofence.model';
import { GeofenceGroupsService } from 'app/services/geofence/geofenceGroups.service';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { LocationService } from 'app/services/locations/locations.service';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { timer } from 'rxjs/internal/observable/timer';
import { TranslateService } from '@ngx-translate/core';
import { StorageHelper } from 'app/common/storagehelper';
import { GeofenceDetailsComponent } from '../shared/usercontrols/geofenceDetails.component';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';

window['moment'] = Moment;
mTZ()

declare var L;

@Component({
    selector: 'fh-fh-geofence-details',
    templateUrl: 'geofenceDetails.template.html'
})
export class GeofenceDetailsViewComponent implements OnInit, OnDestroy {
    @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;
    @ViewChild(GeofenceDetailsComponent, { static: false }) geofenceDetailsControl: GeofenceDetailsComponent;

    sub: any;
    geofence: Geofence;
    device: any;
    tagType = EntityType.Geofence.valueOf();
    formMode = FormMode.read;

    loading = false;
    saving = false;

    error: any;
    success: any;
    warning: any;

    permissions: {};
    waslSuccess: { statusText: string; success: any; };
    waslError: any;

    geoJsonData: any;
    geoJson: any;
    geofenceLayer: any;

    loadingGroups = false;
    allowEdit = true;
    allowBookmarks = false;
    allowDelete = true;

    geofenceGroups = [];
    geofenceGroupLink = 'GeofenceGroupDetails';

    filter;
    currentPage;

    devices = [];
    locations = [];

    loadingDevices = false;
    locationSubscription: any;
    loadingLocation: boolean;
    previousLookupTimestamp: Date;
    timezoneIana: string;

    permissionName = 'Geofences_View';

    mapHeight = 250;
    geofenceId: any;
    showMapOnSide = false;
    storageType = StorageType.LocalStorage;

    constructor(private cd: ChangeDetectorRef, private locationService: LocationService, private router: Router, private translateServe: TranslateService, private waslService: WaslService, private authenticationService: AuthenticationService, private geofenceGroupService: GeofenceGroupsService, private authentication: AuthenticationService, private geofenceService: GeofenceService, private route: ActivatedRoute, private storageHelper: StorageHelper) {
        this.permissions = this.authentication.permissions;
        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showMapOnSide').subscribe((result) => {
            this.showMapOnSide = JSON.parse(result) === true;

            if (this.showMapOnSide) { this.mapHeight = 330; }
            this.cd.markForCheck();
        });
    }

    ngOnDestroy(): void {
        if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
        }
    }

    ngOnInit() {
        this.loading = true;
        this.loadingGroups = true;
        this.loadingLocation = true;
        this.loadingDevices = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.geofenceId = id;
            this.geofenceService.getGeofenceById(id).subscribe(geofence => {
                this.geofence = geofence;
                this.loading = false;
            }, error => {
                this.error = error;
                this.error.statusText = 'Error fetching geofence';
                this.loading = false;

                setTimeout(() => {
                    this.router.navigate(['/Geofences/Overview']);
                }, 3000);
            });

            this.geofenceGroupService.getGeofenceGroupItemsById(id, 0).subscribe(res => {
                this.geofenceGroups = res;
                this.loadingGroups = false;
                this.cd.markForCheck();
            });

            this.locationSubscription = timer(0, 60000).pipe(
                mergeMap(_ => this.locationService.getGeofenceLocations([+id]))
            ).subscribe(result => {
                this.loadingLocation = false;
                this.loadingDevices = false;

                if (result === null) {
                    return;
                }

                const geofenceStates = Object.values(result.geofenceStates);

                let geofenceState;

                for (const currentState of geofenceStates) {
                    geofenceState = currentState;
                }

                if (!geofenceState || Object.keys(geofenceState).length === 0) {
                    return;
                }

                this.locations = [];
                this.devices = [];

                // tslint:disable-next-line:forin
                for (const key in geofenceState) {
                    const device = geofenceState[key];

                    this.devices.push({
                        id: key,
                        name: device.name,
                        rtcBasedTimestamp: Moment.utc(device.timestamp)['tz'](this.timezoneIana),
                    });

                    if ((device.deviceState ?? null) === null) {
                        continue;
                    }

                    let latitude = device.deviceState.currentPosition?.latitude ?? null;
                    let longitude = device.deviceState.currentPosition?.longitude ?? null;
                    let lastCommunication = device.deviceState.currentPosition?.updateTimestamp;

                    // tslint:disable-next-line:no-bitwise
                    const hasGpsFix = (device.deviceState.communicationState?.locationType & 2) > 0;

                    if (device.deviceState.cellPosition && ((latitude === null && longitude === null) || !hasGpsFix)) {
                        latitude = device.deviceState.cellPosition.latitude ?? null;
                        longitude = device.deviceState.cellPosition.longitude ?? null;
                        lastCommunication = device.deviceState.cellPosition.updateTimestamp;
                    }

                    const location = {
                        deviceId: key,
                        icon: device.iconId,
                        assetName: device.name,
                        latitude: latitude,
                        longitude: longitude,
                        deviceState: device.deviceState.calculatedDeviceState?.deviceState,
                        headingInDegrees: device.deviceState.currentPosition?.heading,
                        timestamp: Moment.utc(lastCommunication)['tz'](this.timezoneIana),
                        rtcBasedTimestamp: Moment.utc(lastCommunication)['tz'](this.timezoneIana),
                        lastKnownRtcBasedTimestamp: Moment.utc(lastCommunication)['tz'](this.timezoneIana),
                    }

                    this.locations.push(location);
                }

                this.cd.markForCheck();
            });
        });
    }

    finishTask(task) {
        if (task?.completed) {
            this.error = null;

            if (task.result?.isSuccess) {
                this.warning = null;
                this.success = {
                    statusText: 'Success',
                    success: task.result?.message,
                };
            } else {
                this.success = null;
                this.warning = {
                    statusText: 'Warning',
                    warning: task.result?.message,
                };
            }

            this.getGeofenceInfo();
            this.cd.markForCheck();
        } else {
            this.error = task?.error;
        }
    }

    onMapReady(leafletMapComponent) {
        leafletMapComponent.map.invalidateSize();
    }

    // Form
    onDelete() {
        this.loading = true;

        this.geofenceService.deleteGeofence(this.geofence).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: this.translateServe.instant('general.deleteSuccess')
            };

            setTimeout(() => {
                this.router.navigate(['/Geofences/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.getGeofenceInfo();
        });
    }

    onSave() {
        this.saving = true;
        this.cd.markForCheck();

        if (!this.geofence.isPersonal) {
            this.geofence.userId = null;
        }

        if (this.geofence.layerRef instanceof L.Corridor && 'corridor' in this.geofence.layerRef) {
            this.geofence.radius = this.geofence.layerRef.corridor;
        }

        this.geofence.layerRef = null;
        this.geofence.accountId = +this.geofence.accountId;

        this.geofenceService.updateGeofence(this.geofence).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: this.translateServe.instant('general.updateSuccess')
            };

            this.saving = false;
            this.cd.markForCheck();

            this.setFormMode(FormMode.read);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    onSaveShape(layer) {
        this.geofenceDetailsControl.geofence.layerRef = layer;
        this.geofenceService.parseLayer(layer, this.geofence);

        setTimeout(() => {
            this.cd.detectChanges();
        }, 1);
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getGeofenceInfo();
            this.leafletMapComponent.removeDrawControls();
        } else {
            this.leafletMapComponent.addDrawControls();
        }
    }

    getGeofenceInfo() {
        this.loading = true;
        this.loadingGroups = true;

        this.geofenceService.getGeofenceById(this.geofence.id).subscribe(geofence => {
            this.geofence = geofence;
            this.loading = false;
            this.cd.markForCheck();
        });

        this.geofenceGroupService.getGeofenceGroupItemsById(this.geofence.id, 0).subscribe(res => {
            this.geofenceGroups = res;
            this.loadingGroups = false;
            this.cd.markForCheck();
        });
    }

    addToGroup(_, confirmationModal: ConfirmationModalComponent, geofenceComponent) {
        confirmationModal.hideModal();
        this.loadingGroups = true;

        const filteredGeofences = geofenceComponent.value.filter(x => x > -1);
        geofenceComponent.selector?.clearModel();
        if (filteredGeofences.length === 0) {
            this.getGeofenceInfo();
            return;
        }

        this.geofenceService.addToGroup(this.geofence.id, filteredGeofences).subscribe(result => {
            this.loading = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Adding geofence to groups was successful',
            };

            this.getGeofenceInfo();
        }, error => {
            this.loading = false;
            this.success = null;
            this.error = error;

            this.getGeofenceInfo();
        });
    }
}
