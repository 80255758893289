import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';

import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication/authentication.service';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { getIconPath } from 'app/common/globals';
import { DriverService } from 'app/services/driver/driver.service';
import { Driver } from 'app/models/driver.model';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-drivers-cards',
  templateUrl: 'cards.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverCardsViewComponent implements OnInit {
  token: string;
  countrySelect = false;

  excludingColumns = ['timeStamp', 'assetDateStart', 'assetDateEnd', 'lastCommunication', 'input1', 'input2', 'input3', 'input4', 'input5', 'input6', 'output1', 'output2'];
  loading = false;

  permissionName = 'Drivers_View';
  constructorName = 'DriverScoreViewComponent';

  selectedResellerId;
  selectedAccountId;
  selectedDriverGroups;

  error: any;
  success: any;
  timezoneIana: string;
  languageLoaded: boolean;

  driverSource: Driver[] = [];
  drivers: Driver[] = [];

  currentPage;
  filter;
  itemsPerPage = 10;
  orderBy = 'created';
  orderByReversed = false;
  status;

  constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private driverService: DriverService, private deviceTypeService: DeviceTypeService) {

    const that = this;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana()

    // Get all the date for dropdown boxes
    Observable.forkJoin([
      this.translateService.get('general.date'),
      this.deviceTypeService.getDeviceTypes()]
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.cd.markForCheck();

      },
      err => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  resellerChanged(event) {
    this.driverSource = [];
    this.drivers = [];
    this.selectedDriverGroups = [];

    this.selectedAccountId = null;
  }

  accountChanged(event) {
    this.driverSource = [];
    this.drivers = [];
    this.selectedDriverGroups = [];

    this.getData(this.selectedAccountId);
  }


  ngOnInit() {

  }

  getIcon(iconId) {
    return getIconPath(iconId)[2];
  }

  filterDrivers() {
    this.drivers = this.driverSource;
    if (this.status) {
      // this.drivers = this.drivers.filter(x => x.deviceState === this.status);
    }

    // Filter groups
    if (this.selectedDriverGroups && this.selectedDriverGroups.length > 0) {
      this.drivers = this.drivers.filter(x => x.driverGroups.some(ag => this.selectedDriverGroups.includes(+ag.id)));
    }

    console.log(this.selectedDriverGroups);
    console.log(this.drivers);

    this.cd.markForCheck();
  }

  getData(accountId) {
    this.loading = true;

    this.driverSource = [];
    this.drivers = [];

    if (accountId == null) {
      this.loading = false;
      return;
    }

    this.driverService.getDriversByAccount(accountId, true).subscribe(result => {
      this.driverSource = result;
      this.filterDrivers();
      this.loading = false;
      this.cd.markForCheck();
    },
      err => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      });
  }
}
