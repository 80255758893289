<div class="modal-body">
    <div class="wizard-content" style="padding-bottom: 10px">
        {{'general.addANote' | translate }}
    </div>
    <div class="wizard-content">
        <textarea class=" form-control noresize stickypad" style="height:100px; width:100%; resize: none"
            placeholder="{{ 'placeholder.comments' | translate}}" [(ngModel)]="logItem"></textarea>
    </div>
</div>
<div class="modal-footer">
    <button type="button" style="min-width: 120px;" class="btn btn-primary" (click)="accept()"
        [disabled]="logItem?.length < 3"><i class="fa fa-save"></i>
        {{'general.finish' | translate }}</button>
    <button type="button" style="min-width: 120px;" class="btn btn-info" (click)="decline()"><i class="fa fa-times"></i>
        {{'general.cancel' | translate }}</button>
</div>