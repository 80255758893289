import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { Trailer } from 'app/models/trailer.model';
import { TrailerService } from 'app/services/trailer/trailer.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'fh-trailers-add',
  templateUrl: 'add.template.html'
})
export class TrailersAddViewComponent implements OnInit {
  token: string;
  loading = false;
  loadingGroups = false;
  trailer: Trailer;

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string; };
  selectedGroups = [];
  selectedGroupsSource = [];
  groupName;

  permissionName = 'Trailers_Add';

  constructor(private translateServe: TranslateService, private trailerService: TrailerService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();

    this.selectedGroups = [];
  }

  ngOnInit() {
    this.trailer = new Trailer();
  }

  accountChanged(accountId) {
    this.cd.markForCheck();
  }

  onInsert() {
    this.loading = true;

    this.trailerService.saveTrailer(this.trailer).subscribe(result => {

      this.success = {
        statusText: 'Success',
        success: this.translateServe.instant('general.saveSuccessRedirect', { entity: 'trailer' })
      };

      setTimeout(() => {
        this.router.navigate(['/TrailerDetails/Index/' + result.id]);
      }, 2000);

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }


}
