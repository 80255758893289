<div class="col-lg-12">
    <div class='horizontalMenuWrapper'>
        <ul>
            <li [ngClass]="{'active': activeRoute('Index')}"><a href="/#/DeviceDetails/Index/{{deviceId}}"><i
                        class="fas fa-fw fa-microchip"></i>
                    <div>{{ 'general.dashboard' |
                        translate }}</div>
                </a></li>
            <li *ngIf="permissions['Assets_View']" [ngClass]="{'active': activeRoute('Assets')}"><a
                    href="/#/DeviceDetails/Assets/{{deviceId}}"><i class="fas fa-fw fa-car-bus"></i>
                    <div>{{ 'general.assets' | translate }}</div>
                </a></li>
            <li *ngIf="permissions['Drivers_View']" [ngClass]="{'active': activeRoute('Drivers')}"><a
                    href="/#/DeviceDetails/Drivers/{{deviceId}}"><i class="fas fa-fw fa-id-card-alt"></i>
                    <div>{{ 'general.drivers' | translate }}</div>
                </a></li>
            <li *ngIf="!hideTrailers && permissions['Trailers_View']" [ngClass]="{'active': activeRoute('Trailers')}"><a
                    href="/#/DeviceDetails/Trailers/{{deviceId}}"><i class="fas fa-fw fa-trailer"></i>
                    <div>{{ 'general.trailers' | translate }}</div>
                </a></li>
            <li *ngIf="!hideProjects && permissions['Projects_View']" [ngClass]="{'active': activeRoute('Projects')}"><a
                    href="/#/DeviceDetails/Projects/{{deviceId}}"><i class="fas fa-fw fa-gavel"></i>
                    <div>{{ 'general.projects' | translate }}</div>
                </a></li>
            <li [ngClass]="{'active': isData()}"><a href="/#/DeviceDetails/Trips/{{deviceId}}"><i
                        class="fas fa-fw fa-database"></i>
                    <div>{{ 'menu.data' | translate }}</div>
                </a></li>
            <li *ngIf="permissions['Devices_Edit']" [ngClass]="{'active': isSettings()}"><a
                    href="/#/DeviceDetails/Settings/{{deviceId}}"><i class="fas fa-fw fa-cog"></i>
                    <div>{{ 'general.settings' | translate }}</div>
                </a></li>
            <li *ngIf="permissions['FleetManagement_Log']" [ngClass]="{'active': activeRoute('Log')}"><a
                    href="/#/DeviceDetails/Log/{{deviceId}}"><i class="fas fa-fw fa-file-alt"></i>
                    <div>{{ 'menu.log' | translate }}</div>
                </a></li>
        </ul>
    </div>
    <div class='horizontalMenuWrapper' style="float:right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div class="col-lg-12" style="padding: 5px" *ngIf="isData()">
    <div class='horizontalMenuSubWrapper'>
        <ul>
            <li *ngIf="permissions['FleetManagement_Trips']" [ngClass]="{active: activeRoute('DeviceDetails/Trips')}">
                <a [routerLink]="['/DeviceDetails/Trips/', deviceId]">
                    <i class="fas fa-fw fa-route"></i>
                    {{ 'menu.trips' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Messages']"
                [ngClass]="{active: activeRoute('DeviceDetails/Messages')}">
                <a [routerLink]="['/DeviceDetails/Messages/', deviceId]">
                    <i class="fa fa-fw fa-comment-alt-dots"></i>
                    {{ 'general.messages' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Notifications']"
                [ngClass]="{active: activeRoute('DeviceDetails/Notifications')}">
                <a [routerLink]="['/DeviceDetails/Notifications/', deviceId]">
                    <i class="fas fa-fw fa-envelope-open-text "></i>
                    {{ 'general.notifications' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Episodes']"
                [ngClass]="{active: activeRoute('DeviceDetails/Episodes')}">
                <a [routerLink]="['/DeviceDetails/Episodes/', deviceId]">
                    <i class="fas fa-fw fa-traffic-light-stop"></i>
                    {{ 'general.episodes' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_GeofenceStates']"
                [ngClass]="{active: activeRoute('DeviceDetails/GeofenceStates')}">
                <a [routerLink]="['/DeviceDetails/GeofenceStates/', deviceId]">
                    <i class="fa fa-fw fa-hexagon"></i>
                    {{ 'general.geofenceStates' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Sensors']"
                [ngClass]="{active: activeRoute('DeviceDetails/SensorsHistory')}">
                <a [routerLink]="['/DeviceDetails/SensorsHistory/', deviceId]">
                    <i class="fas fa-fw fa-chart-line"></i>
                    {{ 'menu.sensors' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_DeviceUtilization']"
                [ngClass]="{active: activeRoute('DeviceDetails/Utilization')}">
                <a [routerLink]="['/DeviceDetails/Utilization/', deviceId]">
                    <i class="fas fa-fw fa-engine"></i>
                    {{ 'general.utilization' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_RawData']"
                [ngClass]="{active: activeRoute('DeviceDetails/RawData')}">
                <a [routerLink]="['/DeviceDetails/RawData/', deviceId]">
                    <i class="fas fa-fw fa-database"></i>
                    {{ 'general.rawData' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>
<div class="col-lg-12" style="padding: 5px" *ngIf="isSettings()">
    <div class='horizontalMenuSubWrapper'>
        <ul>
            <li *ngIf="permissions['Devices_Edit']" [ngClass]="{active: activeRoute('DeviceDetails/Settings')}">
                <a [routerLink]="['/DeviceDetails/Settings/', deviceId]">
                    <i class="fas fa-fw fa-random"></i>
                    {{ 'general.ioSettings' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_DeviceSettingsView']"
                [ngClass]="{active: activeRoute('DeviceDetails/Changes')}">
                <a [routerLink]="['/DeviceDetails/Changes/', deviceId]">
                    <i class="fas fa-fw fa-cubes"></i>
                    {{ 'general.settingsChanges' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_OdoView']" [ngClass]="{active: activeRoute('DeviceDetails/Odo')}">
                <a [routerLink]="['/DeviceDetails/Odo/', deviceId]">
                    <i class="fas fa-fw fa-tachometer-alt"></i>
                    {{ 'general.odo' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_EngineHourView']"
                [ngClass]="{active: activeRoute('DeviceDetails/EngineHour')}">
                <a [routerLink]="['/DeviceDetails/EngineHour/', deviceId]">
                    <i class="fas fa-fw fa-tachometer-alt"></i>
                    {{ 'general.engineHour' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_ImmobilizeVehicle']"
                [ngClass]="{active: activeRoute('DeviceDetails/Immobilization')}">
                <a [routerLink]="['/DeviceDetails/Immobilization/', deviceId]">
                    <i class="fas fa-fw fa-scanner"></i>
                    {{ 'general.immobilization' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Sensors']"
                [ngClass]="{active: activeRoute('DeviceDetails/SensorSettings')}">
                <a [routerLink]="['/DeviceDetails/SensorSettings/', deviceId]">
                    <i class="fas fa-fw fa-sensor"></i>
                    {{ 'general.sensors' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FuelEvents_View']" [ngClass]="{active: activeRoute('DeviceDetails/Fuel')}">
                <a [routerLink]="['/DeviceDetails/Fuel/', deviceId]">
                    <i class="fas fa-fw fa-gas-pump"></i>
                    {{ 'menu.fuel' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['Maintenances_View']" [ngClass]="{active: activeRoute('DeviceDetails/Maintenance')}">
                <a [routerLink]="['/DeviceDetails/Maintenance/', deviceId]">
                    <i class="fas fa-fw fa-car-mechanic"></i>
                    {{ 'general.maintenance' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_External']"
                [ngClass]="{active: activeRoute('DeviceDetails/External')}">
                <a [routerLink]="['/DeviceDetails/External/', deviceId]">
                    <i class="fas fa-fw fa-link"></i>
                    {{ 'menu.externalApi' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>
<div style="clear: both"></div>