<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="device?.asset && device?.asset.accountId">
                <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate }}</a>
            </li>
            <li>
                <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                    device?.name}}</a>
            </li>
            <li class="active">{{'general.dashboard' | translate }}</li>
        </ol>

        <span class="fa-filter-container noselect" style="margin-right:8px">
            <a style="margin-left:2px" class="btn btn-info margin-fix hidden-sm hidden-xs" title="Help"
                href="https://help.360locate.com/knowledge-base/device-dashboard/" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="hidden-sm hidden-xs">{{'general.needHelp'
                    |
                    translate }}</span></a>

            <i tooltip="{{'general.updated' | translate }} {{ previousLookupDisplay | amDateFormat: 'LLL' }}"
                style="margin-left: 3px;" class="fa fa-fw"
                [ngClass]="isSpinning ? 'fa-spinner fa-spin eye-active green' : 'fa-sync-alt'"></i>
        </span>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId" [device]="device">
        <span class="top-car-icon" *ngIf="device?.asset?.icon" style="position: absolute;right: 30px;top: -5px;"
            [innerHTML]="formatIconId(device?.asset?.icon)"></span>
    </fh-device-details-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0;">
        <ngx-loading [show]="loadingGeofences"></ngx-loading>
        <fh-leaflet-map (mapReady)="onMapReady($event)" [device]="device" [locations]="locationData"
            [geofences]="geofences" [geofenceEnabled]="false" [allowBookmarks]="false" [height]="mapHeight">
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>

                <div class="alert alert-warning shadow" *ngIf="showWarningMessage && device?.asset?.comment">
                    <p style="font-size: 15px;"><strong>{{ 'general.warning' | translate }}</strong></p>
                    <p>{{device.asset.comment}}</p>
                </div>

                <div class="alert alert-warning shadow" *ngIf="violations.length > 0">
                    <p style="font-size: 15px;"><strong>{{ 'general.violations' | translate }}
                            ({{violations.length}})</strong></p>
                    <p *ngFor="let violation of violations">{{violation}}</p>
                </div>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <form #deviceForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-microchip"></i> {{ 'general.device' | translate }}
                            </h5>
                        </div>
                        <fh-device-details [device]="device" [formMode]="formMode" [loading]="loading || saving">
                        </fh-device-details>
                        <fh-save-footer [valid]="!deviceForm.invalid" (onSave)="onSave()"
                            [allowEdit]="permissions['Devices_Edit']" [allowDelete]=false
                            (setFormMode)="setFormMode($event)" (onDelete)="onDelete()" [formMode]="formMode">
                            <span class="pull-right">
                                <fh-task-dropdown (onFinishAction)="finishTask($event)" #taskDropdown
                                    [entityTypes]="tagType" [entity]="device"></fh-task-dropdown>
                            </span>
                        </fh-save-footer>
                    </div>
                </form>

                <form #assetForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-car-bus"></i> {{'general.asset' | translate }}
                            </h5>
                        </div>
                        <fh-asset-details [asset]="device.asset" [formMode]="formModeAsset"
                            [loading]="loading || saving"></fh-asset-details>
                        <fh-save-footer [valid]="!assetForm.invalid" (onInsert)="onInsertAsset()"
                            (onSave)="onSaveAsset()" (onDelete)="onArchiveAsset($event)"
                            [allowAdd]="((!device?.asset?.id || device?.asset?.assignmentEnd) && device?.accountId) && permissions['Assets_Add']"
                            [allowCancelOnInsert]=true [allowEdit]="(device?.asset?.id) && permissions['Assets_Edit']"
                            [allowDelete]=false (setFormMode)="setFormModeAsset($event)" [formMode]="formModeAsset">
                            <span class="pull-right">
                                <span *ngIf="(device && device?.asset && device?.asset?.id)">
                                    <ng-container *ngIf="!device.asset.assignmentEnd; else reactivate">
                                        <button class="btn btn-danger" (click)="showArchive(device.asset?.id);"
                                            *ngIf="permissions['Actions_Archive']"
                                            title="{{ 'general.archive' | translate }}"><i
                                                class="fa fa-fw fa-file-archive"></i>
                                            {{'general.archive' | translate }}</button>
                                    </ng-container>
                                    <ng-template #reactivate>
                                        <button class="btn btn-primary" (click)="reactivateAsset(device.asset.id)"
                                            *ngIf="permissions['Actions_Archive']"
                                            title="{{ 'general.unArchiveDevice' | translate }}"><i
                                                class="fa fa-fw fa-recycle"></i>
                                            {{'general.reactivate' | translate }}</button>
                                    </ng-template>
                                </span>
                            </span>
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">

                <div class="ibox" style="position: relative;" *ngIf="showMapOnSide">
                    <ngx-loading [show]="loadingGeofences"></ngx-loading>
                    <fh-leaflet-map (mapReady)="onMapReady($event)" [device]="device" [locations]="locationData"
                        [geofences]="geofences" [geofenceEnabled]="false" [allowBookmarks]="false" [height]="mapHeight">
                    </fh-leaflet-map>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fas fa-fw fa-satellite"></i> {{'general.deviceState' | translate }}
                            </h5>
                        </span>
                        <span class="pull-right" [tooltip]="'Show GPS items'">
                            <i (click)="flipPosition()" class="fa-duotone fa-lg fa-fw"
                                [ngClass]="showPosition ? 'fa-toggle-on' : 'fa-toggle-off'">
                            </i>
                        </span>
                        <span style="padding-right:7px" class="pull-right" tooltip="Device communicated: {{ lastCommunication | amDateFormat: 'LL h:mm:ss A'
                        }} | {{ lastCommunication | amTimeAgo:true }} ago">
                            <span class="hidden-sm hidden-xs"><strong>
                                    {{ lastCommunication | amDateFormat: 'LLL'
                                    }}</strong></span>
                        </span>
                    </div>

                    <div class="ibox-content" style="position: relative;"
                        *ngIf="deviceState == null && !loadingLocation">
                        {{ ('general.noDeviceState') | translate }}
                    </div>
                    <div class="ibox-content" style="position: relative;"
                        *ngIf="deviceState == null && loadingLocation">
                        <ngx-loading [show]="loadingLocation"></ngx-loading>
                        {{ ('general.loadingDeviceState') | translate }}
                    </div>
                    <div class=" ibox-content slick" style="position: relative;" *ngIf="deviceState != null">
                        <ngx-loading [show]="loadingLocation"></ngx-loading>
                        <div class="" [@enter]>
                            <div class="col-xl-3 col-xs-6" style="padding:0">
                                <div [title]="deviceState?.calculatedDeviceState?.stateChangedTimestamp"
                                    class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-map-marker-alt eye-active"
                                            [ngClass]="colorMapper(deviceState?.calculatedDeviceState?.deviceState)"></i>
                                        {{ 'general.deviceState_' + deviceState?.calculatedDeviceState?.deviceState
                                        | translate }}
                                    </h3>
                                    <div *ngIf="deviceState?.calculatedDeviceState?.updateTimestamp; else noTimeStamp">
                                        {{ 'general.stateSince' | translate }} {{
                                        deviceState?.calculatedDeviceState?.stateChangedTimestamp | amTimeAgo:true
                                        }}
                                        <i *ngIf="deviceState?.calculatedDeviceState?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.calculatedDeviceState?.updateTimestamp }}"></i>
                                    </div>
                                    <ng-template #noTimeStamp>
                                        {{ 'general.undefined' | translate }}
                                    </ng-template>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.currentPosition?.speed ?
                                        deviceState?.currentPosition?.speed :
                                        '-' }} km/h
                                    </h3>
                                    <div>{{'general.speed' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i *ngIf="deviceState" [ngClass]="deviceState?.markerColor"
                                            [ngStyle]="{'transform': 'rotate('+deviceState?.currentPosition?.heading+'deg)', '-webkit-transform': 'rotate('+deviceState?.currentPosition?.heading+'deg)', '-ms-transform': 'rotate('+deviceState?.currentPosition?.heading+'deg)'}"
                                            class=" fas fa-arrow-up eye-active "></i>
                                        {{ deviceState?.currentPosition?.heading ? deviceState?.currentPosition?.heading
                                        : '-' }}
                                        °
                                    </h3>
                                    <div>{{'general.heading' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.externalBattery?.batteryLevelInVoltage != undefined">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-car-battery"
                                            [ngStyle]="{'color': (!deviceState?.externalBattery?.batteryLevelInVoltage) ? '#ccc' : ((deviceState?.externalBattery?.batteryLevelInVoltage > 9) ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{
                                        deviceState?.externalBattery?.batteryLevelInVoltage?
                                        actualRound(deviceState?.externalBattery?.batteryLevelInVoltage,1).toLocaleString()
                                        :
                                        '-' }} V
                                    </h3>
                                    <div>{{'general.battery' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.temperature1 && deviceState?.temperature1.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-thermometer-three-quarters" style="opacity: 0.3;"></i>
                                        {{ deviceState?.temperature1?.temperatureInCelcius.toLocaleString() }} °C
                                    </h3>
                                    <div>{{'general.temperature' | translate }} 1 <i
                                            *ngIf="deviceState?.temperature1?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.temperature1?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.temperature2 && deviceState?.temperature2.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-thermometer-three-quarters" style="opacity: 0.3;"></i>
                                        {{ deviceState?.temperature2?.temperatureInCelcius.toLocaleString() }} °C
                                    </h3>
                                    <div>{{'general.temperature' | translate }} 2 <i
                                            *ngIf="deviceState?.temperature2?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.temperature2?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.temperature3 && deviceState?.temperature3.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-thermometer-three-quarters" style="opacity: 0.3;"></i>
                                        {{ deviceState?.temperature3?.temperatureInCelcius.toLocaleString() }} °C
                                    </h3>
                                    <div>{{'general.temperature' | translate }} 3 <i
                                            *ngIf="deviceState?.temperature3?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.temperature3?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.temperature4 && deviceState?.temperature4.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-thermometer-three-quarters" style="opacity: 0.3;"></i>
                                        {{ deviceState?.temperature4?.temperatureInCelcius.toLocaleString() }} °C
                                    </h3>
                                    <div>{{'general.temperature' | translate }} 4 <i
                                            *ngIf="deviceState?.temperature4?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.temperature4?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.fuel && deviceState?.fuel.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-gas-pump" style="opacity: 0.3;"></i>
                                        {{ deviceState?.fuel?.fuelLevelInPercentage.toLocaleString() }}%
                                    </h3>
                                    <div>{{'general.fuel' | translate }}<i
                                            *ngIf="deviceState?.fuel?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.fuel?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.fuel1 && deviceState?.fuel1.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-gas-pump" style="opacity: 0.3;"></i>
                                        {{ deviceState?.fuel1?.fuelLevelInPercentage.toLocaleString() }}%
                                    </h3>
                                    <div>{{'general.fuel' | translate }} 2 <i
                                            *ngIf="deviceState?.fuel1?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.fuel1?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.fuel2 && deviceState?.fuel2.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-gas-pump" style="opacity: 0.3;"></i>
                                        {{ deviceState?.fuel2?.fuelLevelInPercentage.toLocaleString() }}%
                                    </h3>
                                    <div>{{'general.fuel' | translate }} 2 <i
                                            *ngIf="deviceState?.fuel2?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.fuel2?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.humidity1 && deviceState?.humidity1.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-humidity" style="opacity: 0.3;"></i>
                                        {{ deviceState?.humidity1?.humidityInPercent.toLocaleString() }} %
                                    </h3>
                                    <div>{{'general.humidity' | translate }} 1 <i
                                            *ngIf="deviceState?.humidity1?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.humidity1?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.humidity2 && deviceState?.humidity2.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-humidity" style="opacity: 0.3;"></i>
                                        {{ deviceState?.humidity2?.humidityInPercent.toLocaleString() }} %
                                    </h3>
                                    <div>{{'general.humidity' | translate }} 2 <i
                                            *ngIf="deviceState?.humidity2?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.humidity2?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.axleWeight1 && deviceState?.axleWeight1.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-weight" style="opacity: 0.3;"></i>
                                        {{ deviceState?.axleWeight1?.axleWeightInKg.toLocaleString() }} kg
                                    </h3>
                                    <div title="{{ deviceState?.axleWeight1?.updateTimestamp }}">{{'general.weight'
                                        | translate }} 1 <i
                                            *ngIf="deviceState?.axleWeight1?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"></i></div>
                                </div>
                            </div>

                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.axleWeight2 && deviceState?.axleWeight2.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-weight" style="opacity: 0.3;"></i>
                                        {{ deviceState?.axleWeight2?.AxleWeightInKg.toLocaleString() }} kg
                                    </h3>
                                    <div title="{{ deviceState?.axleWeight2?.updateTimestamp }}">{{'general.weight'
                                        | translate }} 2 <i
                                            *ngIf="deviceState?.axleWeight2?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.totalAxleWeight && deviceState?.totalAxleWeight.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-weight" style="opacity: 0.3;"></i>
                                        {{ deviceState?.totalAxleWeight?.totalWeightInKg.toLocaleString() }} kg
                                    </h3>
                                    <div>{{'general.totalWeight' | translate }} <i
                                            *ngIf="deviceState?.totalAxleWeight?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.totalAxleWeight?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.rpmStatus?.rpm != undefined && deviceState?.rpmStatus?.updateTimestamp > compareDate">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-engine" style="opacity: 0.3;"></i>
                                        {{ deviceState?.rpmStatus?.rpm ?
                                        deviceState?.rpmStatus?.rpm.toLocaleString(): '-' }}
                                    </h3>
                                    <div>{{'general.rpm' | translate }} <i
                                            *ngIf="deviceState?.rpmStatus?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.rpmStatus?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="deviceState?.tagScanStatus?.tag != undefined">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">

                                        <a *ngIf="permissions['Drivers_View'] && scannedDriver"
                                            class="secondary link_bolder"
                                            href="/#/DriverDetails/Index/{{scannedDriver?.id }}">
                                            <i class="fas fa-fw fa-tag" style="opacity: 0.8;"></i>
                                            {{ scannedDriver?.name }}</a>

                                        <span *ngIf="!scannedDriver"> <i class="fas fa-fw fa-tag"
                                                style="opacity: 0.3;"></i>
                                            {{ deviceState?.tagScanStatus?.tag.toLocaleString() }}</span>

                                    </h3>
                                    <div>{{'general.scannedDriver' | translate }} <i
                                            *ngIf="deviceState?.tagScanStatus?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.tagScanStatus?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0" *ngIf="device?.asset">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ (deviceState?.odometer?.gpsOdometer !== null)
                                        ? (actualRound((device?.asset?.calibrationOdoOffsetInKm ?? 0) +
                                        (deviceState?.odometer?.gpsOdometer / 1000), 0)).toLocaleString() : '-' }} km
                                    </h3>
                                    <div>{{'general.assetOdo' | translate }} <i
                                            *ngIf="device?.asset?.calibrationOdoOffsetInKm === null"
                                            class="fa fa-fw warning fa-exclamation-triangle"
                                            title="{{'general.addCalibration' | translate}}"></i></div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="device?.asset && permissions['FleetManagement_EngineHourView']">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-engine" style="opacity: 0.3;"></i>
                                        {{ deviceState?.engineHour?.engineHourInSeconds !== null ?
                                        ( actualRound(((device?.asset?.engineHourCalibrationOdoOffset ?? 0) +
                                        deviceState?.engineHour?.engineHourInSeconds) / 3600, 0)).toLocaleString() : '-'
                                        }} H
                                    </h3>
                                    <div>{{'general.engineHours' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="device?.asset && deviceState?.odometer?.canBusOdometer">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{
                                        actualRound((deviceState?.odometer?.canBusOdometer /1000),0).toLocaleString()
                                        }} km
                                    </h3>
                                    <div>{{'general.canbusOdo' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="device?.asset?.driver && permissions['Drivers_View']">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <a class="secondary link_bolder"
                                            href="/#/DriverDetails/Index/{{device?.asset?.driver?.id }}">
                                            <i class="fa fa-fw fa-id-card-alt" style="opacity: 0.8;"></i>
                                            {{
                                            device?.asset?.driver?.name }}</a>
                                    </h3>
                                    <div>{{'general.assignedDriver' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="device?.asset?.activeProject && permissions['Projects_View']">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <a class="secondary link_bolder"
                                            href="/#/ProjectDetails/Index/{{device?.asset?.activeProject?.id }}">
                                            <i class="fa fa-fw fa-gavel" style="opacity: 0.8;"></i>
                                            {{
                                            device?.asset?.activeProject?.name }}</a>
                                    </h3>
                                    <div>{{'general.assignedProject' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition" class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 [ngSwitch]="true" class="margin-bottom font-bold">
                                        <div
                                            *ngSwitchCase="deviceState?.hasGpsFix == false && deviceState?.hasCellFix == true && deviceState?.cellPosition != null && !(deviceState?.cellPosition.latitude === 0 && deviceState?.cellPosition.longitude === 0)">
                                            <i title="Cell Position" class=" fas fa-fw fa-location-crosshairs-slash"
                                                [ngStyle]="{'color': 'rgba(151,28,36,0.9)' }"></i>
                                            {{ deviceState?.cellPosition?.latitude.toLocaleString() + ' - ' +
                                            deviceState?.cellPosition?.longitude.toLocaleString() }}
                                        </div>
                                        <div
                                            *ngSwitchCase="deviceState?.hasGpsFix == true && deviceState?.currentPosition != null">
                                            <i title="GPS Position" class=" fas fa-fw fa-location-crosshairs"
                                                [ngStyle]="{'color': 'rgba(0,142,67,0.9)' }"></i>
                                            {{ deviceState?.currentPosition?.latitude.toLocaleString() + ' - ' +
                                            deviceState?.currentPosition?.longitude.toLocaleString() }}
                                        </div>
                                        <div
                                            *ngSwitchCase="deviceState?.hasGpsFix == false && deviceState?.currentPosition != null">
                                            <i title="Outdated position" class=" fas fa-fw fa-location-crosshairs-slash"
                                                [ngStyle]="{'color': 'rgba(151,28,36,0.9)' }"></i>
                                            {{ deviceState?.currentPosition?.latitude.toLocaleString() + ' - ' +
                                            deviceState?.currentPosition?.longitude.toLocaleString() }}
                                        </div>
                                        <div *ngSwitchDefault>
                                            &ndash;
                                        </div>
                                    </h3>
                                    <div>{{'general.location' | translate }} <i
                                            *ngIf="deviceState?.currentPosition != null && deviceState?.currentPosition?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                            class="fa fa-fw fa-history"
                                            title="{{ deviceState?.currentPosition?.updateTimestamp }}"></i></div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.currentPosition?.satellites"
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.currentPosition?.satellites ?
                                        deviceState?.currentPosition?.satellites.toLocaleString():
                                        '-'
                                        }}
                                    </h3>
                                    <div>{{'general.satellites' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.currentPosition?.hdop" class="col-xl-3 col-xs-6"
                                style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.currentPosition?.hdop ?
                                        deviceState?.currentPosition?.hdop.toLocaleString(): '-' }}
                                    </h3>
                                    <div>{{'general.hdop' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.currentPosition?.pdop" class="col-xl-3 col-xs-6"
                                style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.currentPosition?.pdop ?
                                        deviceState?.currentPosition?.pdop.toLocaleString(): '-' }}
                                    </h3>
                                    <div>{{'general.pdop' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.currentPosition?.radiusInMeters"
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.currentPosition?.radiusInMeters ?
                                        deviceState?.currentPosition?.radiusInMeters.toLocaleString() + ' m': '-' }}
                                    </h3>
                                    <div>{{'general.radiusInMeters' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.actualAccellerationForce != undefined "
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.actualAccellerationForce != undefined ?
                                        deviceState?.actualAccellerationForce.toLocaleString() + ' mg': '-' }}
                                    </h3>
                                    <div>{{'general.actualAccellerationForce' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.actualBrakingForce != undefined"
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.actualBrakingForce != undefined ?
                                        deviceState?.actualBrakingForce.toLocaleString() + ' mg': '-' }}
                                    </h3>
                                    <div>{{'general.actualBrakingForce' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="showPosition && deviceState?.actualCorneringForce != undefined"
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.actualCorneringForce != undefined ?
                                        deviceState?.actualCorneringForce.toLocaleString() + ' mg': '-' }}
                                    </h3>
                                    <div>{{'general.actualCorneringForce' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.batteryChargeState != undefined"
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.batteryChargeState != undefined ?
                                        deviceState?.batteryChargeState.toLocaleString(): '-' }}
                                    </h3>
                                    <div>{{'general.batteryChargeState' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.batteryChargeLevelInPercentage != undefined"
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.batteryChargeLevelInPercentage != undefined ?
                                        deviceState?.batteryChargeLevelInPercentage.toLocaleString() + '%' : '-' }}
                                    </h3>
                                    <div>{{'general.batteryChargeLevelInPercentage' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.batteryPowerConsumptionInKWhPer100Km != undefined"
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.batteryPowerConsumptionInKWhPer100Km != undefined ?
                                        deviceState?.batteryPowerConsumptionInKWhPer100Km.toLocaleString() : '-' }}
                                    </h3>
                                    <div>{{'general.batteryChargeLevelInPercentage' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.remainingDistanceInKm != undefined"
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        {{ deviceState?.remainingDistanceInKm != undefined ?
                                        deviceState?.remainingDistanceInKm.toLocaleString() + ' km' : '-' }}
                                    </h3>
                                    <div>{{'general.remainingDistanceInKm' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.angle1InDegrees != undefined"
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i [ngStyle]="{'transform': 'rotate('+deviceState?.angle1InDegrees+'deg)', '-webkit-transform': 'rotate('+deviceState?.angle1InDegrees+'deg)', '-ms-transform': 'rotate('+deviceState?.angle1InDegrees+'deg)'}"
                                            class=" fas fa-fw fa-arrow-up"></i>

                                        {{ deviceState?.angle1InDegrees != undefined ?
                                        deviceState?.angle1InDegrees.toLocaleString() + ' °' : '-' }}
                                    </h3>
                                    <div>{{'general.angle1InDegrees' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.angle2InDegrees != undefined"
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i [ngStyle]="{'transform': 'rotate('+deviceState?.angle2InDegrees+'deg)', '-webkit-transform': 'rotate('+deviceState?.angle2InDegrees+'deg)', '-ms-transform': 'rotate('+deviceState?.angle2InDegrees+'deg)'}"
                                            class=" fas fa-fw fa-arrow-up"></i>

                                        {{ deviceState?.angle2InDegrees != undefined ?
                                        deviceState?.angle2InDegrees.toLocaleString() + ' °' : '-' }}
                                    </h3>
                                    <div>{{'general.angle2InDegrees' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.angle3InDegrees != undefined"
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i [ngStyle]="{'transform': 'rotate('+deviceState?.angle3InDegrees+'deg)', '-webkit-transform': 'rotate('+deviceState?.angle3InDegrees+'deg)', '-ms-transform': 'rotate('+deviceState?.angle3InDegrees+'deg)'}"
                                            class=" fas fa-fw fa-arrow-up"></i>

                                        {{ deviceState?.angle3InDegrees != undefined ?
                                        deviceState?.angle3InDegrees.toLocaleString() + ' °' : '-' }}
                                    </h3>
                                    <div>{{'general.angle3InDegrees' | translate }}</div>
                                </div>
                            </div>

                            <div *ngIf="showPosition && deviceState?.angle4InDegrees != undefined"
                                class="col-xl-3 col-xs-6" style="padding:0">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i [ngStyle]="{'transform': 'rotate('+deviceState?.angle4InDegrees+'deg)', '-webkit-transform': 'rotate('+deviceState?.angle4InDegrees+'deg)', '-ms-transform': 'rotate('+deviceState?.angle4InDegrees+'deg)'}"
                                            class=" fas fa-fw fa-arrow-up"></i>

                                        {{ deviceState?.angle4InDegrees != undefined ?
                                        deviceState?.angle4InDegrees.toLocaleString() + ' °' : '-' }}
                                    </h3>
                                    <div>{{'general.angle4InDegrees' | translate }}</div>
                                </div>
                            </div>

                            <ng-container *ngIf="showPosition">
                                <div *ngIf="deviceState?.calculatedIgnitionState" class="col-xl-3 col-xs-6"
                                    style="padding:0">
                                    <div [title]="deviceState?.calculatedIgnitionState?.stateChangedTimestamp"
                                        class="kpi-item">
                                        <h3 class="margin-bottom font-bold">
                                            <i class=" fas fa-fw fa-key"
                                                [ngStyle]="{'color': (!deviceState?.calculatedIgnitionState) ? '#ccc' : (deviceState.calculatedIgnitionState.isIgnitionOn ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                            {{ 'general.stateSince' | translate }} {{
                                            deviceState?.calculatedIgnitionState?.stateChangedTimestamp | amTimeAgo:true
                                            }}
                                        </h3>
                                        <div> {{ 'general.stateIgnition' | translate }}
                                            <i *ngIf="deviceState?.calculatedIgnitionState?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                                class="fa fa-fw fa-history"
                                                title="{{ deviceState?.calculatedIgnitionState?.updateTimestamp }}"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-xs-6" style="padding:0">
                                    <div [title]="deviceState?.idlingState?.stateChangedTimestamp" class="kpi-item">
                                        <h3 class="margin-bottom font-bold">
                                            <i class=" fas fa-fw fa-snooze"
                                                [ngStyle]="{'color': (!deviceState?.idlingState) ? '#ccc' : (deviceState.idlingState.isCurrentlyIdling ? 'rgba(238, 146, 52, 0.9)' : 'rgba(255, 255, 255, 0.3)') }"></i>
                                            <span *ngIf="deviceState.idlingState?.isCurrentlyIdling">{{
                                                'general.stateSince' | translate }} {{
                                                deviceState?.idlingState?.stateChangedTimestamp | amTimeAgo:true
                                                }}</span>
                                            <span *ngIf="!deviceState.idlingState?.isCurrentlyIdling">-</span>
                                        </h3>
                                        <div>
                                            {{ 'general.stateIdling' | translate }}
                                            <i *ngIf="deviceState?.idlingState?.calculatedTimestamp < deviceState.communicationState?.updateTimestamp"
                                                class="fa fa-fw fa-history"
                                                title="{{ deviceState?.idlingState?.calculatedTimestamp }}"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-xs-6" style="padding:0">
                                    <div [title]="deviceState?.calculatedCommunicatingState?.updateTimestamp"
                                        class="kpi-item">
                                        <h3 class="margin-bottom font-bold">
                                            <i class=" fas fa-fw fa-satellite-dish"
                                                [ngStyle]="{'color': (!deviceState?.calculatedCommunicatingState) ? '#ccc' : (deviceState.calculatedCommunicatingState.isCommunicating ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                            {{ 'general.' + (deviceState?.calculatedCommunicatingState?.isCommunicating
                                            ?? undefined) | translate }}
                                        </h3>
                                        <div> {{ 'general.stateCommunicating' | translate }}
                                            <i *ngIf="deviceState?.calculatedCommunicatingState?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                                class="fa fa-fw fa-history"
                                                title="{{ deviceState?.calculatedCommunicatingState?.updateTimestamp }}"></i>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="deviceState?.calculatedExternalPowerState" class="col-xl-3 col-xs-6"
                                    style="padding:0">
                                    <div [title]="deviceState?.calculatedExternalPowerState?.updateTimestamp"
                                        class="kpi-item">
                                        <h3 class="margin-bottom font-bold">
                                            <i class="fas fa-fw fa-car-battery"
                                                [ngStyle]="{'color': (!deviceState?.calculatedExternalPowerState) ? '#ccc' : (deviceState.calculatedExternalPowerState.isExternalPowerOn ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                            {{ 'general.' + deviceState?.calculatedExternalPowerState?.isExternalPowerOn
                                            | translate }}
                                        </h3>
                                        <div> {{ 'general.stateExternalPower' | translate }}
                                            <i *ngIf="deviceState?.calculatedExternalPowerState?.updateTimestamp < deviceState.communicationState?.updateTimestamp"
                                                class="fa fa-fw fa-history"
                                                title="{{ deviceState?.calculatedExternalPowerState?.updateTimestamp }}"></i>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fas fa-fw fa-random"></i> {{'general.ioStates' | translate }} &nbsp;
                            </h5>
                            <span [tooltip]="'Some of your input ports are unmapped'" *ngIf="unmappedPorts">
                                <i style="color: #CB711D;" class="fas fa-fw fa-exclamation-triangle"></i>
                            </span>
                        </span>
                        <span class="pull-right" [tooltip]="'Show unmapped items'">
                            <i (click)="flipUnMapped()" class="fa-duotone fa-lg fa-fw"
                                [ngClass]="showUnMapped ? 'fa-toggle-on' : 'fa-toggle-off'">
                            </i>
                        </span>
                    </div>
                    <div class="ibox-content" style="position: relative;"
                        *ngIf="deviceState == null && !loadingLocation">
                        {{ ('general.noDeviceState') | translate }}
                    </div>
                    <div class="ibox-content" style="position: relative;"
                        *ngIf="deviceState == null && loadingLocation">
                        <ngx-loading [show]="loadingLocation"></ngx-loading>
                        {{ ('general.loadingDeviceState') | translate }}
                    </div>
                    <div class="ibox-content slick" style="position: relative;" *ngIf="deviceState != null">
                        <ngx-loading [show]="loadingLocation"></ngx-loading>

                        <div [@enter]>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.inputPorts[0] && device.settings.inputPorts[0].byte != null && device.settings.inputPorts[0].byte != 0) || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.input1 == null) ? '#ccc' : (deviceState?.input1?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.inputFormatted' | translate:{ value: '1' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.inputPorts && 'enums.locationEventType.' +
                                        device.settings.inputPorts[0]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.inputPorts[1] && device.settings.inputPorts[1].byte != null && device.settings.inputPorts[1].byte != 0) || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.input2 == null) ? '#ccc' : (deviceState?.input2?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.inputFormatted' | translate:{ value: '2' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.inputPorts && 'enums.locationEventType.' +
                                        device.settings.inputPorts[1]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.inputPorts[2] && device.settings.inputPorts[2].byte != null && device.settings.inputPorts[2].byte != 0) || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.input3 == null) ? '#ccc' : (deviceState?.input3?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.inputFormatted' | translate:{ value: '3' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.inputPorts && 'enums.locationEventType.' +
                                        device.settings.inputPorts[2]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.inputPorts[3] && device.settings.inputPorts[3].byte != null && device.settings.inputPorts[3].byte != 0) || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.input4 == null) ? '#ccc' : (deviceState?.input4?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.inputFormatted' | translate:{ value: '4' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.inputPorts && 'enums.locationEventType.' +
                                        device.settings.inputPorts[3]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.inputPorts[4] && device.settings.inputPorts[4].byte != null && device.settings.inputPorts[4].byte != 0) || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.input5 == null) ? '#ccc' : (deviceState?.input5?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.inputFormatted' | translate:{ value: '5' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.inputPorts && 'enums.locationEventType.' +
                                        device.settings.inputPorts[4]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.inputPorts[5] && device.settings.inputPorts[5].byte != null && device.settings.inputPorts[5].byte != 0) || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.input6 == null) ? '#ccc' : (deviceState?.input6?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.inputFormatted' | translate:{ value: '6' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.inputPorts && 'enums.locationEventType.' +
                                        device.settings.inputPorts[5]?.byte | translate }}
                                    </div>
                                </div>

                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.ignition && device.settings.ignition != null && device.settings.ignition != 0) || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState == null || deviceState?.ignition == null) ? '#ccc' : (deviceState?.ignition?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.ignition' | translate}} ({{ 'general.port' | translate}})
                                    </h3>
                                    <div> {{device.settings.ignition && 'enums.locationEventType.' +
                                        device.settings.ignition | translate }}</div>
                                </div>

                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.externalPower && device.settings.externalPower != null && device.settings.externalPower != 0) || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-arrow-alt-circle-up"
                                            [ngStyle]="{'color': (deviceState?.externalPower == null) ? '#ccc' : (deviceState?.externalPower?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.externalPower' | translate}} ({{ 'general.port' | translate}})
                                    </h3>
                                    <div> {{device.settings.externalPower && 'enums.locationEventType.' +
                                        device.settings.externalPower | translate }}</div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.outputPorts[0] && device.settings.outputPorts[0].byte != null && device.settings.outputPorts[0].byte != 0) || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-arrow-alt-circle-down"
                                            [ngStyle]="{'color': (deviceState?.output1 == null) ? '#ccc' : (deviceState?.output1?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.outputFormatted' | translate:{ value: '1' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.outputPorts && 'enums.deviceOutput.' +
                                        device.settings.outputPorts[0]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.outputPorts[1] && device.settings.outputPorts[1].byte != null && device.settings.outputPorts[1].byte != 0) || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-arrow-alt-circle-down"
                                            [ngStyle]="{'color': (deviceState?.output2 == null) ? '#ccc' : (deviceState?.output2?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.outputFormatted' | translate:{ value: '2' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.outputPorts && 'enums.deviceOutput.' +
                                        device.settings.outputPorts[1]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.outputPorts[2] && device.settings.outputPorts[2].byte != null && device.settings.outputPorts[2].byte != 0)  || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-arrow-alt-circle-down"
                                            [ngStyle]="{'color': (deviceState?.output3 == null) ? '#ccc' : (deviceState?.output3?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.outputFormatted' | translate:{ value: '3' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.outputPorts && 'enums.deviceOutput.' +
                                        device.settings.outputPorts[2]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.outputPorts[3] && device.settings.outputPorts[3].byte != null && device.settings.outputPorts[3].byte != 0)  || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class=" fas fa-fw fa-arrow-alt-circle-down"
                                            [ngStyle]="{'color': (deviceState?.output4 == null) ? '#ccc' : (deviceState?.output4?.state ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{ 'general.outputFormatted' | translate:{ value: '4' } }}
                                    </h3>
                                    <div>
                                        {{device.settings.outputPorts && 'enums.deviceOutput.' +
                                        device.settings.outputPorts[3]?.byte | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.analogPorts[0] && device.settings.analogPorts[0].byte != null && device.settings.analogPorts[0].byte != 0)  || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-bolt" style="opacity: 0.3;"></i>
                                        {{deviceState?.analog1 ?
                                        deviceState?.analog1?.value?.toLocaleString() : '-' }} V
                                    </h3>
                                    <div>
                                        {{device.settings.analogPorts && 'enums.analogFunctions.' +
                                        device.settings.analogPorts[0]?.byte | translate }} (Analog 1)
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngIf="(device.settings.analogPorts[1] && device.settings.analogPorts[1].byte != null && device.settings.analogPorts[1].byte != 0)  || showUnMapped">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-bolt" style="opacity: 0.3;"></i>
                                        {{deviceState?.analog2 ?
                                        deviceState?.analog2?.value?.toLocaleString() : '-' }} V
                                    </h3>
                                    <div>
                                        {{device.settings.analogPorts && 'enums.analogFunctions.' +
                                        device.settings.analogPorts[1]?.byte | translate }} (Analog 2)
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                </div>

                <div class="ibox" *ngIf="deviceState != null && deviceState.virtualSensors?.length > 0">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fas fa-fw fa-sensor"></i> {{'general.virtualSensors' | translate }} &nbsp;
                            </h5>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative;" *ngIf="deviceState != null">
                        <div [@enter]>
                            <div class="col-xl-3 col-xs-6" style="padding:0"
                                *ngFor="let virtualSensor of deviceState.virtualSensors">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-sensor"
                                            [ngStyle]="{'color': (virtualSensor.value == null || virtualSensor.value == '') ? '#ccc' : (virtualSensor.value == 'True' ? 'rgba(0,142,67,0.9)' : 'rgba(151,28,36,0.9)') }"></i>
                                        {{('enums.virtualSensorOutputType.' + virtualSensor.sensorOutputType) |
                                        translate}}
                                    </h3>
                                    <div>
                                        {{ virtualSensor.name }}
                                    </div>
                                </div>
                            </div>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                </div>

                <div class="ibox" *ngIf="showUtilization && !hideUtilization">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-engine"></i> {{'general.utilization' | translate }}
                        </h5>
                        <span class="pull-right"
                            title="{{daterangepickerModel[0].toLocaleString() }} - {{daterangepickerModel[1].toLocaleString()}}">
                            {{daterangepickerModel[0] | amDateFormat: 'll' }} - {{daterangepickerModel[1] |
                            amDateFormat: 'll'}}
                        </span>
                    </div>
                    <div class="ibox-content" style="position: relative; padding: 2px;">
                        <ngx-loading [show]="loadingUtilization"></ngx-loading>
                        <div class="form-group input-group" style="margin-bottom: 0px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-calendar"></i>
                            </span>
                            <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker" type="text"
                                [bsConfig]="dpConfig" class="form-control" bsDaterangepicker
                                (onHidden)="dateChanged('hidden')" [(ngModel)]="daterangepickerModel">
                        </div>
                    </div>

                    <div class="ibox-content slick" style="position: relative;">
                        <ngx-loading [show]="loadingUtilization"></ngx-loading>
                        <div style="padding: 10px" *ngIf="!deviceUtilization && loadingUtilization"> {{
                            ('general.loadingData') | translate }}
                        </div>
                        <div *ngIf="deviceUtilization">
                            <div class="">
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.segmentsDistance ?
                                            deviceUtilization?.segmentsDistance.toLocaleString() : 0 }} km
                                        </h3>
                                        <div>{{'general.distance' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.workingHoursInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.workingHoursInSeconds) : '-'
                                            }}
                                        </h3>
                                        <div>{{'general.duration' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.idlingDurationInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.idlingDurationInSeconds) : '-'
                                            }}
                                        </h3>
                                        <div>{{'general.idling' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            <a class='secondary link_bolder' (click)="$event.stopPropagation();"
                                                href="/#/DeviceDetails/Trips/{{device.id}}"> {{
                                                deviceUtilization?.tripCount ?
                                                deviceUtilization?.tripCount?.toLocaleString() : 0 }}</a>
                                        </h3>
                                        <div>{{'general.trips' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{actualRound(deviceUtilization?.utilization,2)?.toLocaleString() }} %
                                        </h3>
                                        <div>{{'general.utilization' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.maxSpeed ?
                                            deviceUtilization?.maxSpeed : '-' }} km/h
                                        </h3>
                                        <div>{{'general.maxSpeed' | translate }}</div>
                                    </div>
                                </div>
                                <div *ngIf="permissions['FleetManagement_Equipment']" class="col-lg-4 col-xs-6"
                                    style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.workDurationInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.workDurationInSeconds) : '-' }}
                                        </h3>
                                        <div>{{'general.workDurationInSeconds' | translate }}</div>
                                    </div>
                                </div>
                                <div *ngIf="permissions['FleetManagement_Equipment']" class="col-lg-4 col-xs-6"
                                    style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">{{
                                            deviceUtilization?.equipmentIdlingDurationInSeconds ?
                                            actualRoundSeconds(deviceUtilization?.equipmentIdlingDurationInSeconds) :
                                            '-'
                                            }}

                                        </h3>
                                        <div>{{'general.equipmentIdlingDurationInSeconds' | translate }}</div>
                                    </div>
                                </div>
                                <div *ngIf="permissions['FleetManagement_Equipment']" class="col-lg-4 col-xs-6"
                                    style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.crossOverDurationInSeconds
                                            ? actualRoundSeconds(deviceUtilization?.crossOverDurationInSeconds) : '-'
                                            }}
                                        </h3>
                                        <div>{{'general.crossOverDurationInSeconds' | translate }}</div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.fuelLostWhileIdling
                                            ? actualRound(deviceUtilization?.fuelLostWhileIdling, 1).toLocaleString() :
                                            '-'
                                            }} L
                                        </h3>
                                        <div>{{'general.fuelLostWhileIdling' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.fuelLostPercentage
                                            ? actualRound(deviceUtilization?.fuelLostPercentage, 1).toLocaleString() :
                                            '-'
                                            }}%
                                        </h3>
                                        <div>{{'general.fuelLostPercentage' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.fuelUsedTotal
                                            ? actualRound(deviceUtilization?.fuelUsedTotal, 1).toLocaleString() : '-'
                                            }} L
                                        </h3>
                                        <div>{{'general.fuelUsedTotal' | translate }}</div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.totalEmissionCO2 ?
                                            actualRound(deviceUtilization?.totalEmissionCO2, 1).toLocaleString() : '-'
                                            }} kg
                                        </h3>
                                        <div>{{'general.totalEmissionCO2' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">{{
                                            deviceUtilization?.totalEmissionParticlesLight ?
                                            actualRound(deviceUtilization?.totalEmissionParticlesLight,
                                            1).toLocaleString() : '-'
                                            }} / {{
                                            deviceUtilization?.totalEmissionParticlesHeavy ?
                                            actualRound(deviceUtilization?.totalEmissionParticlesHeavy,
                                            1).toLocaleString() : '-'
                                            }}

                                        </h3>
                                        <div>{{'general.totalEmissionParticlesLight' | translate }} /
                                            {{'general.totalEmissionParticlesHeavy' | translate }} </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ deviceUtilization?.totalConsumptionMixed
                                            ? actualRound(deviceUtilization?.totalConsumptionMixed, 1).toLocaleString()
                                            : '-'
                                            }} L
                                        </h3>
                                        <div>{{'general.totalConsumptionMixed' | translate }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-ruler"></i> {{'general.distanceperday' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart *ngIf="chartDistance" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:214px" [Highcharts]="Highcharts"
                            [options]="chartDistance">
                        </highcharts-chart>
                    </div>
                </div>

                <div class="ibox" *ngIf="permissions['Platform_IsReseller']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-comment-alt-dots"></i> {{'general.locationCount' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart *ngIf="chartLocationCount" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:214px" [Highcharts]="Highcharts"
                            [options]="chartLocationCount">
                        </highcharts-chart>
                    </div>
                </div>

                <div class="ibox" *ngIf="!hideTriggers && permissions['Triggers_View']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-bell-on"></i> {{'general.triggers' |
                            translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="loadingTriggers"></ngx-loading>

                        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th>{{'general.name' | translate }}</th>
                                    <th class="elipses_overflow" style="width: 100px"
                                        title="{{'general.episodeCountLastWeek' | translate }}">
                                        {{'general.episodeCountLastWeek' | translate }}</th>
                                    <th class="elipses_overflow" style="width: 100px"
                                        title="{{'general.notificationCountLastWeek' | translate }}">
                                        {{'general.notificationCountLastWeek' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="triggers.length == 0">
                                <tr>
                                    <td colspan="3">
                                        {{'general.noTriggersFound' | translate }}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr *ngFor="let trigger of triggers">
                                    <td class="elipses_overflow" title="{{trigger?.name}}">
                                        <a href="/#/System/TriggerDetails/Index/{{trigger?.id}}">
                                            <span class="secondary link_bolder"><i
                                                    class="fas fa-fw fa-angle-right"></i></span>
                                            {{trigger?.name}}</a>
                                    </td>
                                    <td class="elipses_overflow" style="width: 100px">{{trigger?.episodeCount}}</td>
                                    <td class="elipses_overflow" style="width: 100px">{{trigger?.notificationCount}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <fh-group-overview name="{{'general.assetGroups' | translate }}" [loading]="loadingGroups"
                    [childId]="device.asset?.id" (onFinish)="getDeviceInfo()" [groups]="assetGroups"
                    [linkUrl]="assetGroupLink" [deletePermission]="'Assets_Edit'">
                    <span groupAddSlot (click)="confirmationModal.showModal(device.asset.id)"
                        [title]="'general.addToGroup' | translate" container="body">
                        <button class="btn btn-primary">
                            <i class="fa fa-fw fa-add"></i> {{ 'general.assign' | translate}}
                        </button>
                    </span>
                </fh-group-overview>

                <fh-schedule-assignments [assetId]="device?.asset?.id" [selectedAccountId]="device?.accountId"
                    [deletePermission]="'Assets_Edit'">
                </fh-schedule-assignments>

                <fh-tagging [objectId]="device.id" [objectType]="tagType">
                </fh-tagging>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal #confirmationModal (onFinish)="addToGroup($event, confirmationModal, groupInputVar)"
    [invalid]="!(groupInputVar.value?.length > 0) || groupInputVar.loadingAssetGroups">
    <h4 header class="modal-title pull-left"><i class="fa fa-fw fa-bolt"></i> {{'general.action' | translate }}: {{
        'general.addToGroup' | translate }}</h4>
    <form body #groupForm="ngForm" style="width: 100%; border-top: 0px;">
        <div class="modal-body">
            <fh-asset-group-input #groupInputVar ngDefaultControl required [tableInline]="false"
                [selectedAccountId]="device.accountId" [hideSystemGroups]="true" [hideEmptyGroups]="false"
                [activeOnly]="true" [autoSelect]="false" [canAddNew]="true"
                placeholder="{{ 'general.chooseAssetGroups' | translate }}">
            </fh-asset-group-input>
        </div>
    </form>
</fh-confirmation-modal>

<fh-confirmation-modal #archiveModal (onFinish)="onArchiveAsset($event)">
    <h4 header class="modal-title pull-left"><i class="fa fa-fw fa-bolt"></i> {{'general.action' | translate }}:
        {{ 'general.archiveDevice' | translate }}</h4>
    <fh-archiving-modal #archiveBody [device]="device" body></fh-archiving-modal>
</fh-confirmation-modal>