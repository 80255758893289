import { Component, OnInit } from '@angular/core';
import { environment } from 'app/../environments/environment';
import { InfoService } from 'app/services/info/info.service';

@Component({
    selector: 'fh-about',
    templateUrl: 'about.component.html'
})
export class AboutViewComponent implements OnInit {

    appDate: Date;
    appVersion: string;
    appName: string;
    endPoint: string;

    constructor(private infoService: InfoService) {
        infoService.getInfo().subscribe(result => {
            this.appDate = new Date(result.date);
            this.appName = result.application;
        }, error => {
            console.log(error);
        })

        this.endPoint = infoService.getEndPoint();
    }

    ngOnInit(): void {
        this.appVersion = environment.version;
    }
}
