import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUser } from 'app/models/user.model';
import { UserService } from 'app/services/users/user.service';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';

@Component({
    selector: 'fh-user-log',
    templateUrl: 'userLog.template.html',
    styleUrls: ['userLog.template.css']
})
export class UserLogViewComponent implements OnInit {
    sub;
    user: AppUser;
    userLog: AuditLog[];
    loading = false;

    error: any;
    success: any;

    permissionName = 'FleetManagement_Log';
    userId: any;

    constructor(private http: HttpClient, private userService: UserService, private auditLogService: AuditLogService, private route: ActivatedRoute, private router: Router) {
        this.user = null;
        this.userLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.user = new AppUser;
        this.user.id = '';
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.userId = id;
            this.userService.getUserById(id).subscribe(user => {
                this.user = user;

                if (this.user == null) {
                    this.router.navigate(['/Users/Overview'])
                }
            });

            this.auditLogService.getAuditLogByUser(id).subscribe(userLog => {
                this.userLog = userLog;
                this.loading = false;
            }, error => {
                this.error = error;
                this.loading = false;
            });
        });
    }
}
