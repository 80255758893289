<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="device?.asset && device?.asset.accountId">
                <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate }}</a>
            </li>
            <li>
                <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                    device?.name}}</a>
            </li>
            <li class="active">{{'menu.trips' | translate }}</li>
        </ol>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId"></fh-device-details-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0;">
        <ngx-loading [show]="loadingGeofences"></ngx-loading>
        <fh-leaflet-map (mapReady)="onMapReady($event)" [geofences]="geofences" [height]="mapHeight"
            [geofenceEnabled]="false" [allowBookmarks]="false" (mapResized)="onMapResized()" [geofenceFromTrip]="true">
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="">
            <div class="col-lg-6">

                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative">
                        <div class="col-lg-9">
                            <div class="form-group input-group" style="margin-bottom: 5px;">
                                <span class="input-group-addon">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker"
                                    type="text" [bsConfig]="dpConfig" class="form-control" bsDaterangepicker
                                    [maxDate]="maxDate" (onHidden)="dateChanged('hidden')"
                                    [(ngModel)]="daterangepickerModel">
                                <span class="input-group-calendar-datatables">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group input-group" style="margin-bottom: 5px;">
                                <span class="input-group-addon" title="Items per page">
                                    <i class="fa fa-fw fa-stream"></i>
                                </span>
                                <ng-select [(ngModel)]="itemsPerPage" class="form-control" name="itemsPerPage" required
                                    [clearable]="false" style="width:100%">
                                    <ng-option [value]="10">10</ng-option>
                                    <ng-option [value]="17">17</ng-option>
                                    <ng-option [value]="25">25</ng-option>
                                    <ng-option [value]="50">50</ng-option>
                                    <ng-option [value]="99999">{{'general.all' | translate }}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fas fa-fw fa-route"></i> {{'menu.trips' | translate }}
                            </h5>
                        </span>
                        <span class="pull-right">
                            <div class="filter-form hidden-sm hidden-xs">
                                <input type="text" class="form-control table-inline top-filter hidden-sm hidden-xs"
                                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: hidden">
                        <ngx-loading [show]="loading || loadingTrips"></ngx-loading>
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <thead>
                                <tr>
                                    <th style="width:42px"></th>
                                    <th style="width:50px"></th>
                                    <th style="width:60px; text-align: right;">{{'general.start' | translate }}</th>
                                    <th style="width:60px; text-align: right;">{{'general.duration' | translate }}</th>
                                    <th style="width:65px; text-align: right;">{{'general.km' | translate }}</th>
                                    <th style="width:auto">{{'general.start' | translate }}</th>
                                    <th style="width:auto">{{'general.end' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container
                                    *ngFor="let trip of trips | stringFilter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, id: 'first'}; let i = index;">
                                    <tr *ngIf="isNewDate(trip)">
                                        <td colspan="7" style="font-weight: bold; text-transform: capitalize;">
                                            {{ trip.beginDateTime | amDateFormat:'dddd LL' }}</td>
                                    </tr>
                                    <tr *ngIf="(currentPage + '.' + i) as id" class="hand"
                                        [ngClass]="{ 'rowSelected': trip.ident == selectedTrip?.ident, 'messages_orange' : trip.tripMethod == 5 , 'messages_green' : trip.isPrivate, 'messages_red' : trip.isHidden}"
                                        (click)="clearSelectionAndGetTripData($event, trip)">
                                        <td [ngStyle]="{'color': colorArray[trip.ident % colorArray.length]}"
                                            style="text-align: right; font-weight: bold;" class="elipses_overflow">
                                            {{ trip.ident }}
                                        </td>
                                        <td
                                            [ngStyle]="{'border-left': '7px solid ' + colorArray[trip.ident % colorArray.length]}">
                                            <span (click)="getTripData($event, trip)"
                                                *ngIf="trip.tripMethod !== 5 && !trip.isHidden">
                                                <i class='far fa-fw fa-lg'
                                                    [ngClass]="tripPolyLineDict[trip.id] ? 'fa-check-square' : 'fa-square'"></i>
                                            </span>
                                            <span *ngIf="trip.tripMethod == 5 || trip.isHidden">
                                                <i *ngIf="trip.isHidden" class='fa fa-fw fa-lg fa-user-secret'></i>

                                                <i *ngIf="trip.tripMethod == 5"
                                                    class='fa fa-fw fa-lg fa-tachometer-alt'></i>
                                            </span>
                                        </td>
                                        <td style="text-align: right;" class="elipses_overflow"
                                            title="{{ trip.beginDateTime.toLocaleString() }} - {{ trip.endDateTime.toLocaleString()}}">
                                            {{ trip.beginDateTime | amDateFormat:'HH:mm' }}
                                        </td>
                                        <td style="text-align: right;" class="elipses_overflow"
                                            title="{{ trip.beginDateTime.toLocaleString() }} - {{ trip.endDateTime.toLocaleString()}}">
                                            {{ trip.tripType != 3 ? (trip.duration) : '-' }}
                                        </td>
                                        <td class="elipses_overflow" style="text-align: right;">
                                            {{ actualRound(trip.segmentDistanceInKilometers,2)?.toLocaleString() }}
                                        </td>
                                        <td style="width:auto" class="elipses_overflow">
                                            <span *ngIf="trip.tripMethod == 5">{{ ('general.calibration' | translate )
                                                }}</span>

                                            {{ trip.beginAddressFull }}
                                        </td>
                                        <td style="width:auto" class="elipses_overflow">
                                            <container-element [ngSwitch]="trip.tripType">
                                                <span *ngSwitchCase="3">{{ ('general.ongoing' | translate ) }}</span>
                                                <span *ngSwitchCase="5">{{ ('general.calibration' | translate )}}</span>
                                                <span *ngSwitchDefault>{{ trip.endAddressFull }}</span>
                                            </container-element>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr *ngIf="trips.length === 0">
                                    <td colspan="7">
                                        {{'general.nodatafound' | translate }}
                                    </td>
                                </tr>
                                <tr *ngIf="resetDate()"></tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="first">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">

                <div class="ibox" style="position: relative;" *ngIf="showMapOnSide">
                    <ngx-loading [show]="loadingGeofences"></ngx-loading>
                    <fh-leaflet-map (mapReady)="onMapReady($event)" [geofences]="geofences" [height]="mapHeight"
                        [geofenceEnabled]="false" [allowBookmarks]="false" (mapResized)="onMapResized()"
                        [geofenceFromTrip]="true">
                    </fh-leaflet-map>
                </div>

                <div class="ibox" style="position: relative;">
                    <ngx-loading [show]="loadingDetails || loadingGeofences || mapBusy">
                    </ngx-loading>
                    <div header class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fa fa-fw fa-map-marked-alt"></i> {{'general.animation' | translate }}
                            </h5>
                        </span>
                    </div>
                    <div *ngIf="!selectedTrip" class="ibox-content" style="position: relative;">
                        {{'general.noTripSelected' | translate }}
                    </div>
                    <div class="ibox-content slick">
                        <table class="table nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                            <tbody>
                                <tr>
                                    <td style="width:50%">
                                        {{'general.showAbuse' | translate }}
                                    </td>
                                    <td style="width:50%">
                                        <i id="checkBox_11" class="auto_checkbox fa fa-fw hand"
                                            (click)="flipShowAbuseIcons();"
                                            [ngClass]="showAbuseIcons ? 'fa-check' : 'fa-times'"
                                            title="Filter on 0 values"
                                            [style.color]="showAbuseIcons ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer" *ngIf="selectedTrip">
                        <ng-container>
                            <button *ngIf="!myMovingMarker" class="btn btn-info"
                                title="{{ 'general.playTrip' | translate }}" (click)="playTrips()">
                                <i class="fa fa-fw fa-play"></i>
                                <span class="hidden-sm hidden-xs"> {{'general.play' | translate
                                    }}</span>
                            </button>

                            <button *ngIf="myMovingMarker" class="btn btn-info"
                                title="{{ 'general.resumeTrip' | translate }}" (click)="resumeTrip()">
                                <i class="fa fa-fw fa-play"></i>
                                <span class="hidden-sm hidden-xs"> {{'general.resume' | translate
                                    }}</span>
                            </button>

                            <button *ngIf="myMovingMarker" class="btn btn-info"
                                title="{{ 'general.pauseTrip' | translate }}" (click)="pauseTrip()">
                                <i class="fa fa-fw fa-pause"></i>
                                <span class="hidden-sm hidden-xs"> {{'general.pause' | translate
                                    }}</span>
                            </button>

                            <button *ngIf="myMovingMarker" class="btn btn-info"
                                title="{{ 'general.stopTrip' | translate }}" (click)="stopTrip()">
                                <i class="fa fa-fw fa-stop"></i>
                                <span class="hidden-sm hidden-xs"> {{'general.stop' | translate
                                    }}</span>
                            </button>
                            <!-- <button class="btn btn-info" title="{{ 'general.showReport' | translate }}"
                                    (click)="showReport()">
                                    <i class="fa fa-fw fa-user-chart"></i>
                                    <span class="hidden-sm hidden-xs"> {{'general.showReport' | translate
                                        }}</span>
                                </button> -->
                        </ng-container>

                        <span class="pull-right">
                            <ng-select class="form-control" name="speedCalculator" [(ngModel)]="speedCalculator"
                                (ngModelChange)="speedChanged()" [searchable]="false" [clearable]="false"
                                style="width:100px">
                                <ng-option [value]=0.5>Fast</ng-option>
                                <ng-option [value]=2>Normal</ng-option>
                                <ng-option [value]=10>Slow</ng-option>
                            </ng-select>
                        </span>
                        <div style="clear: both"></div>
                    </div>
                </div>

                <div class="ibox" style="position: relative;">
                    <ngx-loading [show]="loadingDetails || loadingGeofences || mapBusy">
                    </ngx-loading>
                    <div header class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fa fa-fw fa-map-marked-alt"></i> {{'general.chart' | translate }}
                            </h5>
                        </span>
                        <span *ngIf="showChart" class="pull-right">
                            <ibox-chart-export [chartObject]="speedChart">
                            </ibox-chart-export>
                        </span>
                        <span class="pull-right" [tooltip]="'Show chart'">
                            <i (click)="flipShowChart()" class="fa-duotone fa-lg fa-fw"
                                [ngClass]="showChart ? 'fa-toggle-on' : 'fa-toggle-off'">
                            </i>
                        </span>
                    </div>

                    <div [hidden]="!showChart" class="ibox-content" style="padding: 5px; position: relative;">

                        <highcharts-chart [runOutsideAngular]="true" *ngIf="speedChart && selectedTrip"
                            style="display: block; width: 100%;height:350px" [Highcharts]="Highcharts"
                            [options]="speedChart">
                        </highcharts-chart>
                    </div>
                    <div *ngIf="!selectedTrip" class="ibox-content" style="position: relative;">
                        {{'general.noTripSelected' | translate }}
                    </div>
                    <div class="ibox-footer" *ngIf="selectedTrip">
                        <ng-container *ngIf="leafletMapComponent.locationLayer.getLayers().length; else showLocations">
                            <button class="btn btn-info" title="{{ 'general.removeLocations' | translate }}"
                                (click)="leafletMapComponent.locationLayer.clearLayers()">
                                <i class="fas fa-fw fa-eraser"></i>
                                <span class="hidden-sm hidden-xs"> {{'general.removeLocations' | translate
                                    }}</span>
                            </button>
                        </ng-container>

                        <ng-template #showLocations>
                            <button class="btn btn-info" title="{{ 'general.showLocations' | translate }}"
                                (click)="showLocationDetails()">
                                <i class="fa fa-fw fa-edit"></i>
                                <span class="hidden-sm hidden-xs"> {{'general.showLocations' | translate
                                    }}</span>
                            </button>
                        </ng-template>

                        <div style="clear: both"></div>
                    </div>
                </div>

                <div class="ibox" style="position: relative;">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-location-arrow"></i> {{'general.selectedTrip' | translate }}
                        </h5>

                        <span class="pull-right" *ngIf="hasPrivateBusinessButtons() == true">
                            <i (click)="setPrivate(!selectedTrip.isPrivate)" title="{{ 'general.private' | translate }}"
                                style="vertical-align: sub; " class='fa-lg fa-duotone'
                                [ngClass]=" selectedTrip.isPrivate ? 'fa-toggle-on toggle-green' : 'fa-toggle-off toggle-red'"></i>
                        </span>
                    </div>
                    <div class=" ibox-content" style="position: relative;"
                        *ngIf="!selectedTrip || leafletMapComponent?.tripLayer.getLayers().length > 1">
                        <ngx-loading [show]="loadingDetails || loadingGeofences || mapBusy">
                        </ngx-loading>
                        <span style="width: 100%" *ngIf="leafletMapComponent?.tripLayer.getLayers().length > 1">
                            {{'general.selectOnlyOneTrip' | translate }}
                        </span>
                        <span *ngIf="!selectedTrip">
                            {{'general.noTripSelected' | translate }}
                        </span>
                    </div>
                    <div class=" ibox-content slick" style="position: relative;"
                        *ngIf="selectedTrip && !(leafletMapComponent?.tripLayer.getLayers().length > 1)">
                        <ngx-loading [show]="loadingDetails || loadingGeofences || mapBusy">
                        </ngx-loading>
                        <div class="">
                            <div class="col-xl-4 col-xs-6" style="padding:0">
                                <div class="kpi-item border-active">
                                    <h3 class="margin-bottom font-bold"
                                        title="{{selectedTrip.beginDateTime.toLocaleString()}}">
                                        {{ selectedTrip.beginDateTime | amDateFormat:'ll HH:mm' }}
                                    </h3>
                                    <div> {{'general.start' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-xs-6" style="padding:0">
                                <div class="kpi-item border-active">
                                    <h3 class="margin-bottom font-bold">
                                        {{ selectedTrip.duration }}
                                    </h3>
                                    <div> {{'general.duration' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-xs-6" style="padding:0">
                                <div class="kpi-item border-active">
                                    <h3 class="margin-bottom font-bold"
                                        title="{{selectedTrip.endDateTime.toLocaleString()}}">
                                        {{ selectedTrip.endDateTime | amDateFormat:'ll HH:mm' }}
                                    </h3>
                                    <div> {{'general.end' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-xs-6" style="padding:0">
                                <div class="kpi-item border-active">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-tachometer-alt"></i>
                                        {{ actualRound(selectedTrip.calibratedOdoValueEnd, 0)?.toLocaleString() }}
                                    </h3>
                                    <div> {{'general.odo' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-xs-6" style="padding:0" *ngIf="permissions['Drivers_View']">
                                <div class="kpi-item border-active">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-user"></i>

                                        <a *ngIf="scannedDriver?.id" class="secondary link_bolder"
                                            href="/#/DriverDetails/Index/{{scannedDriver.id}}">
                                            {{ scannedDriver.name }}</a>

                                        <a *ngIf="selectedTrip?.driverId" class="secondary link_bolder"
                                            href="/#/DriverDetails/Index/{{selectedTrip.driverId}}">
                                            {{ selectedTrip.driverName }}</a>

                                        <span *ngIf="!selectedTrip.driverId && !scannedDriver">{{'general.unknown' |
                                            translate }}</span>
                                    </h3>
                                    <div> {{'general.driver' | translate }}</div>
                                </div>
                            </div>
                            <!-- <div class="col-xl-4 col-xs-6" style="padding:0">
                                <div class="kpi-item border-active">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-tachometer-fastest"></i>
                                        {{ selectedTrip.maxSpeed }} {{'general.kmh' | translate }}
                                    </h3>
                                    <div> {{'general.maxSpeed' | translate }}</div>
                                </div>
                            </div> -->
                            <div class="col-xl-4 col-xs-6" style="padding:0">
                                <div class="kpi-item border-active">
                                    <h3 class="margin-bottom font-bold">
                                        <i class="fas fa-fw fa-route "></i>
                                        {{ actualRound(selectedTrip.segmentDistanceInKilometers,2)?.toLocaleString() }}
                                        {{'general.km' | translate }}
                                    </h3>
                                    <div> {{'general.distanceGpsBased' | translate }}</div>
                                </div>
                            </div>
                            <div style="clear:both"></div>
                        </div>
                    </div>
                </div>

                <div class="ibox" style="position: relative;">
                    <div class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fas fa-fw fa-location-arrow"></i> {{'general.episodes' | translate }}
                            </h5>
                        </span>
                    </div>
                    <div class=" ibox-content slick" style="position: relative;"
                        *ngIf="selectedTrip && !(leafletMapComponent?.tripLayer.getLayers().length > 1)">

                        <div class="ibox-content slick" style="position: relative;"
                            *ngIf="selectedTrip && !(leafletMapComponent?.tripLayer.getLayers().length > 1)">
                            <table class="table nowrap dtr-inline no-footer low-opacity"
                                style="width:100%; margin-bottom:0px ">
                                <tbody>
                                    <tr class="hand" *ngIf="selectedTrip.tripType != 3"
                                        (click)="displayLocation(selectedTrip.endLocation)">
                                        <td style="width:50px; padding-left:18px">
                                            <i class="fa fa-fw fa-flag-checkered"></i>
                                        </td>
                                        <td style="width:60px; text-align: right;"
                                            title="{{selectedTrip.endDateTime.toLocaleString()}}"> {{
                                            selectedTrip.endDateTime | amDateFormat:'HH:mm' }}</td>
                                        <td> {{'general.end' | translate }} in {{ selectedTrip.endAddressFull }}
                                        </td>
                                    </tr>
                                    <tr class="hand" (click)="displayLocation(event.location, event.geofence, event)"
                                        *ngFor="let event of selectedTrip.concatenatedEvents; let i = index;">
                                        <td style="width:50px; padding-left:18px"><i class="fa fa-fw "
                                                [ngClass]="event?.icon"></i></td>
                                        <td style="width:60px; text-align: right;"
                                            title="{{event.startUtc?.toLocaleString()}}">{{event.startUtc
                                            |
                                            amDateFormat: 'HH:mm' }}</td>
                                        <td>{{event.description }}</td>
                                    </tr>
                                    <tr class="hand" (click)="displayLocation(selectedTrip.beginLocation)">
                                        <td style="width:50px; padding-left:18px"><i class="fa fa-fw fa-car-bus"></i>
                                        </td>
                                        <td style="width:60px; text-align: right;"
                                            title="{{selectedTrip.beginDateTime?.toLocaleString()}}"> {{
                                            selectedTrip.beginDateTime | amDateFormat:'HH:mm' }}</td>
                                        <td> {{'general.start' | translate }} in {{
                                            selectedTrip.beginAddressFull }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</fh-permission-handler>