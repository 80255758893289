import { Component, Input, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'fh-project-devices',
    templateUrl: 'projectDevices.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDevicesOverviewComponent implements OnInit {

    @Input() deviceAssignments = [];
    @Input() loading = false;
    allowSelect = false;

    // Pagination
    currentPage;

    filter;

    constructor(private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

}

