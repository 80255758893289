import { Component, EventEmitter, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { CarSharingGarage } from 'app/models/carsharingGarage.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-garage-details',
    templateUrl: 'carsharingGarageDetails.template.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CarSharingGarageDetailsComponent {
    @Input() garage: CarSharingGarage;
    @Input() useCompact = false;
    @Input() loading = false;
    @Input() identifier = 0;
    @Input() formMode: any = FormMode.read;

    permissions: {};

    selectedResellerId;

    constructor(private authenticationService: AuthenticationService) {
        this.permissions = this.authenticationService.permissions;
    }

    resellerChanged(resellerId) {
        this.selectedResellerId = resellerId;
    }

    accountChanged(accountId) {
        console.log('accountId set to ' + accountId);
        this.garage.accountId = accountId;
    }
}
