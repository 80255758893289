<div class="ibox-content slick" style="position:relative;">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!maintenanceOccurrence">
            <tr>
                <td colspan="2">
                    {{ 'general.noMaintenance' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="maintenanceOccurrence">
            <ng-container *ngIf="!useCompact">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder"
                            href="/#/ResellerDetails/Index/{{maintenanceOccurrence?.resellerId}}">{{maintenanceOccurrence?.resellerDescription
                            }}</a>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{maintenanceOccurrence?.accountId}}">{{maintenanceOccurrence?.companyName
                            }}</a>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">
                        {{ 'general.typeName' | translate}}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder"
                            href="/#/Maintenances/MaintenanceTypeDetails/Index/{{maintenanceOccurrence?.maintenanceTypeId}}">{{
                            maintenanceOccurrence?.typeName }}</a>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">
                        {{ 'general.device' | translate}}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder"
                            href="/#/DeviceDetails/Maintenance/{{maintenanceOccurrence?.deviceId}}">{{
                            maintenanceOccurrence?.assetName }}</a>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">
                        {{ 'general.occurrenceDate' | translate}}
                        <span class="error" *ngIf="occurrenceDate.invalid && formMode != 1">*</span>
                    </td>
                    <td class="row_content">
                        <input type="text" [disabled]="formMode == 1" [name]="'occurrenceDate_' + identifier" identifier
                            #occurrenceDate="ngModel" class="form-control table-inline"
                            [(ngModel)]="maintenanceOccurrence.occurrenceDate" autocomplete="off" [bsConfig]="dpConfig"
                            placeholder="{{ 'placeholder.occurrenceDate' | translate}}" required bsDatepicker
                            #occurrenceDateDp="bsDatepicker">
                        <span *ngIf="formMode != 1" class="input-group-calendar"><i
                                class="fa fa-calendar-alt"></i></span>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">
                        {{ 'general.supplierName' | translate}}
                        <span class="error" *ngIf="supplierName.invalid && formMode != 1">*</span>
                    </td>
                    <td class="row_content">
                        <input type="text" [readonly]="formMode == 1" [name]="'supplierName_' + identifier"
                            placeholder="{{ 'placeholder.supplierName' | translate}}" class="form-control table-inline"
                            [(ngModel)]="maintenanceOccurrence.supplierName" #supplierName="ngModel">
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">
                        {{ 'general.localPurchaseOrderNumber' | translate}}
                        <span class="error" *ngIf="localPurchaseOrderNumber.invalid && formMode != 1">*</span>
                    </td>
                    <td class="row_content">
                        <input type="text" [readonly]="formMode == 1" [name]="'localPurchaseOrderNumber_' + identifier"
                            placeholder="{{ 'placeholder.localPurchaseOrderNumber' | translate}}"
                            class="form-control table-inline"
                            [(ngModel)]="maintenanceOccurrence.localPurchaseOrderNumber"
                            #localPurchaseOrderNumber="ngModel">
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">
                        {{ 'general.materialReceiptInspectionVoucherNumber' | translate}}
                        <span class="error"
                            *ngIf="materialReceiptInspectionVoucherNumber.invalid && formMode != 1">*</span>
                    </td>
                    <td class="row_content">
                        <input type="text" [readonly]="formMode == 1"
                            [name]="'materialReceiptInspectionVoucherNumber_' + identifier"
                            class="form-control table-inline"
                            [(ngModel)]="maintenanceOccurrence.materialReceiptInspectionVoucherNumber"
                            placeholder="{{ 'placeholder.materialReceiptInspectionVoucherNumber' | translate}}"
                            #materialReceiptInspectionVoucherNumber="ngModel">
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">
                        {{ 'general.controlNumber' | translate}}
                        <span class="error" *ngIf="controlNumber.invalid && formMode != 1">*</span>
                    </td>
                    <td class="row_content">
                        <input type="text" [readonly]="formMode == 1" [name]="'controlNumber_' + identifier"
                            placeholder="{{ 'placeholder.controlNumber' | translate}}" class="form-control table-inline"
                            [(ngModel)]="maintenanceOccurrence.controlNumber" #controlNumber="ngModel">
                    </td>
                </tr>                
            </ng-container>
            <tr>
                <td class="row_opening">
                    {{ 'general.partNumber' | translate}}
                    <span class="error" *ngIf="partNumber.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" [name]="'partNumber_' + identifier"
                        placeholder="{{ 'placeholder.partNumber' | translate}}" class="form-control table-inline"
                        [(ngModel)]="maintenanceOccurrence.partNumber" #partNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.quantity' | translate}}
                    <span class="error" *ngIf="quantity.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="number" [readonly]="formMode == 1" [name]="'quantity_' + identifier"
                        placeholder="{{ 'placeholder.quantity' | translate}}" class="form-control table-inline"
                        [(ngModel)]="maintenanceOccurrence.quantity" #quantity="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.unitPrice' | translate}}
                    <span class="error" *ngIf="unitPrice.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" [name]="'unitPrice_' + identifier"
                        placeholder="{{ 'placeholder.unitPrice' | translate}}" class="form-control table-inline"
                        [(ngModel)]="maintenanceOccurrence.unitPrice" required pattern="\d+\.?\d*"
                        title="Only numbers and 1 dot" #unitPrice="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.discount' | translate}}
                    <span class="error" *ngIf="discount.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" [name]="'discount_' + identifier"
                        class="form-control table-inline" [(ngModel)]="maintenanceOccurrence.discount"
                        placeholder="{{ 'placeholder.discount' | translate}}" pattern="\d+\.?\d*"
                        title="Only numbers and 1 dot" #discount="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.remarks' | translate}}
                    <span class="error" *ngIf="remarks.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" [name]="'remarks_' + identifier"
                        class="form-control table-inline" [(ngModel)]="maintenanceOccurrence.remarks"
                        placeholder="{{ 'placeholder.remarks' | translate}}" #remarks="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.reference' | translate}}
                    <span class="error" *ngIf="reference.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" [name]="'reference_' + identifier"
                        class="form-control table-inline" [(ngModel)]="maintenanceOccurrence.reference"
                        placeholder="{{ 'placeholder.reference' | translate}}" #reference="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.description' | translate}}
                    <span class="error" *ngIf="description.invalid && formMode != 1">*</span>
                </td>
                <td>
                    <textarea [readonly]="formMode == 1" [name]="'description_' + identifier"
                        class="noresize stickypad" style="height:100px; width:100%; resize: none" id="encCss"
                        [(ngModel)]="maintenanceOccurrence.description" required minlength="4"
                        placeholder="{{ 'placeholder.comments' | translate}}" #description="ngModel"></textarea>

                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.dateCalibration' | translate}}                                            
                </td>
                <td class="row_content" style="width:auto">
                    <input type="text" [disabled]="formMode == 1" name="calibrationDate"
                        #calibrationDateVar="ngModel" class="form-control table-inline"
                        [(ngModel)]="maintenanceOccurrence.dateCalibration" autocomplete="off" [bsConfig]="dpConfig"
                        bsDatepicker>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.odo' | translate}}                                            
                </td>
                <td class="row_content">
                    <input type="number" [readonly]="formMode == 1" name="calibrationOdo"
                        class="form-control table-inline" [(ngModel)]="maintenanceOccurrence.realAssetOdo"
                        minlength="3" #calibrationOdoVar="ngModel">
                </td>
            </tr>
        </tbody>
    </table>
</div>