export class DeviceType {

    public id: any;
    public modelName: string;
    public modelNumber: string;
    public description: string;
    public latestVersion: string;
    public port: any;
    public supplier: Supplier;
    public deviceCount: any;
    public deviceCountActive: any;
}

export class Supplier {

    public id: any;
    public name: string;
}
