import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FhChartService } from 'app/services/charts/charts.service';
import { ColorService } from 'app/services/common/color.service';

import '../../../../vendor/jspdf/IBMPlexSansArabic-Bold-normal.js';
import '../../../../vendor/jspdf/IBMPlexSansArabic-Regular-normal.js';
import '../../../../vendor/jspdf/fa-solid-900-normal.js';
import '../../../../vendor/leaflet-export/leaflet-export.js';

import htmlToSvg from "htmlsvg";
declare var html2canvas;
declare var pdfMake;
declare var leafletImage;

import * as XLSX from 'xlsx';
import * as L from 'leaflet';
import jsPDF from 'jspdf';
import 'svg2pdf.js'
import autoTable from 'jspdf-autotable';

import { colorArray2, deepCopy, getIconPath, localizeSystemGroupNames, roundAsNumber, roundAsString, roundMinutes, roundSeconds } from 'app/common/globals';
import { groupByDate, formatDetailsFromTimezone, formatDetailsWithMinutesFromTimezone, formatFromTimezone, formatFromTimezoneWithFormat, fromTimezone, getUTCStartOfDayDateTimeFromTimezone, groupByString } from 'app/services/common/functions.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';

import * as Highcharts from 'highcharts';

import { parseEpisode } from 'app/services/common/episode.parser';
import { DistanceUnitService } from 'app/common/distanceunit.service';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { ReportTemplate } from 'app/models/reporting.model.js';
window['moment'] = Moment;
mTZ();

// Math
import { create, all } from '../../../../vendor/mathjs/math.js';


const config = {}
const math = create(all, config)

@Component({
    selector: 'fh-report-details',
    templateUrl: 'reportDisplay.template.html',
})
export class ReportDisplayDetailsComponent implements OnChanges {
    Highcharts: typeof Highcharts = Highcharts;

    @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

    @Input() selectedReport;
    @Input() loading;

    @Input() template: ReportTemplate;

    @Input() activeReport;
    @Input() reportData;
    @Input() selectedAccountName;
    @Output() onResetWizard = new EventEmitter();

    timezoneIana;

    charts = [];
    filter = [];
    filterCategories;

    currentPageArray = [];
    currentPageSections;
    resettedPage = 0;
    p = [];
    p2;
    renderedChart;
    locationData = [];
    tripFeatureGroup;
    colorArray = colorArray2;

    reportDataFlat: any;
    reportDataGrouped: any;

    formulaErrors = 0;
    mathError: {};
    displayedTripId: any;

    delay = ms => new Promise(res => setTimeout(res, ms));
    mapAsImage: boolean = true;

    constructor(private cd: ChangeDetectorRef, private distance: DistanceUnitService, private colorService: ColorService, private chartService: FhChartService, private translateService: TranslateService, private authenticationService: AuthenticationService) {
        this.timezoneIana = authenticationService.getTimeZoneIana();
    }

    resetWizard() {
        this.onResetWizard.emit(true);
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['reportData'] || (changes['template'] && changes['template'].firstChange)) {
            // Data checks
            this.reportData?.data?.forEach((data, index) => {
                data?.data?.forEach((subData, index2) => {
                    if (subData.TripMethod) {
                    }
                });
            });

            this.reportData?.charts?.forEach((chart, index) => {
                this.charts[index] = this.generateChart(chart);
            });

            if (this.reportData?.data?.length > 0) {
                this.formatData(this.template);
            }
        }
    }

    abs(value) {
        return Math.abs(value);
    }

    evaluateFormula(columnConfiguration, row) {
        let result = '';
        let formula = columnConfiguration.formula;

        const scope: { [id: string]: any; } = {};

        formula = formula.replace(/\[(.*?)\]/g, function (match, p1, p2, p3, offset, string) {
            let data: number;

            const entity = p1.split('.')[0];
            const source = p1.split('.')[1];

            if (entity && entity !== 'Base') {
                data = row[entity]?.[source];
            } else {
                data = row[source];
            }

            scope[p1.replace('.', '_')] = data;

            return p1.replace('.', '_');
        });

        try {
            result = math.evaluate(formula, scope);
        } catch (error) {
            return { isSuccess: false, result: null, error: error };
        }

        if (isNaN(+result)) {
            return { isSuccess: false, result: result, error: null };
        } else {
            return { isSuccess: true, result: result, error: null };
        }
    }

    public formatData(template) {

        const columns = template.columnConfiguration;

        this.reportDataFlat = [];
        this.reportDataFlat.data = [];

        this.reportDataGrouped = [];
        this.reportDataGrouped.data = [];

        this.mathError = {};
        this.formulaErrors = 0;

        let idx = 0;
        this.reportData.kpiList.forEach(kpi => {
            if (this.reportData?.previousKpiList[idx]) {
                kpi.delta = roundAsNumber(kpi.value - this.reportData?.previousKpiList[idx]?.value, 0);
                kpi.deltaPercentage = roundAsNumber(((kpi.value - this.reportData?.previousKpiList[idx]?.value) / this.reportData?.previousKpiList[idx]?.value) * 100, 0);
                kpi.previousPeriodStart = Moment.utc(this.reportData?.previousPeriodStart)['tz'](this.timezoneIana).format('ll');
                kpi.previousPeriodEnd = Moment.utc(this.reportData?.previousPeriodEnd)['tz'](this.timezoneIana).format('ll');
            }
            idx++;
        });

        this.reportDataFlat.displayLocations = this.reportData.displayLocations;
        this.reportDataFlat.displayLocationsAsTrip = this.reportData.displayLocationsAsTrip;

        if (columns == null || columns.length === 0) {
            console.log('Skipping formatting data');
            return;
        }

        // If TWD then we must format trip data
        if (this.activeReport?.ReportType == 2) {
            this.reportData.data.forEach(asset => {
                asset.Data.forEach(trip => {
                    this.prepareTripData(trip);
                });
            });
        }

        const flattenedSource = deepCopy(this.reportData);

        // Flatten data object
        flattenedSource.data.forEach(dataRow => {
            // Copy original row
            const flattenedObject = { ...dataRow };

            // Reset data list
            flattenedObject.Data = null;

            if (dataRow.Data && dataRow.Data.length > 0) {

                flattenedObject.Data = [];
                dataRow.Data?.forEach(row => {
                    const newRow = {}

                    columns.forEach((columnConfiguration, index) => {

                        if (columnConfiguration.enabled) {
                            let data;

                            if (columnConfiguration.entity && columnConfiguration.entity !== 'Base') {
                                data = row[columnConfiguration.entity]?.[columnConfiguration.source];
                            } else {
                                data = row[columnConfiguration.source];
                            }

                            if (columnConfiguration.dataType === 'Formula') {
                                const formulaResult = this.evaluateFormula(columnConfiguration, row);
                                data = formulaResult.result;

                                if (formulaResult.error) {
                                    this.formulaErrors++;

                                    this.mathError = {
                                        error: 'Some errors in the formulas',
                                        statusText: 'Error',
                                    };

                                    data = formulaResult.error;
                                }
                            }

                            newRow[index] = data ?? '-';
                        }
                    });

                    // Custom data checks
                    if (row['Trip']?.['TripMethod'] === 5) {
                        newRow['isHighlighted'] = true;
                    }

                    flattenedObject.Data.push(newRow);
                });
            }

            this.reportDataFlat.data.push(flattenedObject);
        });


        this.reportDataFlat.data?.forEach(dataItem => {

            // Ordering
            if (template.orderByIndex !== undefined && template.orderByIndex > -1) {
                if (template.orderByAscending !== false) {
                    // Ascending
                    if (columns[template.orderByIndex].dataType === 'String' || columns[template.orderByIndex].dataType === 'DateTime') {
                        dataItem.Data?.sort((a, b) => a[template.orderByIndex]?.localeCompare(b[template.orderByIndex]));
                    } else {
                        dataItem.Data?.sort((a, b) => a[template.orderByIndex] - b[template.orderByIndex]);
                    }
                } else {
                    // Descending
                    if (columns[template.orderByIndex].dataType === 'String' || columns[template.orderByIndex].dataType === 'DateTime') {
                        dataItem.Data?.sort((a, b) => b[template.orderByIndex]?.localeCompare(a[template.orderByIndex]));
                    } else {
                        dataItem.Data?.sort((a, b) => b[template.orderByIndex] - a[template.orderByIndex]);
                    }
                }
            }

            // Create total summary
            const totalSummary = this.createSummary(columns, dataItem.Data);

            // Grouping
            if (template.groupByIndex !== undefined && template.groupByIndex > -1) {

                const groupedResults = [];
                let tempGrouped = [];

                switch (template.groupByType) {
                    case 1:
                        tempGrouped = groupByString(dataItem.Data, template.groupByIndex, 'Group: ');
                        break;
                    case 2:
                        tempGrouped = groupByDate(dataItem.Data, template.groupByIndex, 'LL', 'Date: ');
                        break;
                    case 3:
                        tempGrouped = groupByDate(dataItem.Data, template.groupByIndex, 'YYYY w', 'Week: ');
                        break;
                    case 4:
                        tempGrouped = groupByDate(dataItem.Data, template.groupByIndex, 'YYYYY W', 'Week: ');
                        break;
                    case 5:
                        tempGrouped = groupByDate(dataItem.Data, template.groupByIndex, 'YYYY MMM', 'Month: ');
                        break;
                    case 6:
                        tempGrouped = groupByDate(dataItem.Data, template.groupByIndex, 'YYYY', 'Year: ');
                        break;
                    default:
                        tempGrouped = groupByString(dataItem.Data, 999, '');
                        break;
                }
                // Reset the data
                dataItem.Data = [];

                Object.values(tempGrouped).forEach((groupedDataItem, index) => {
                    // Add header
                    dataItem.Data.push({ isHeader: true, headerName: Object.keys(tempGrouped)[index] });

                    groupedDataItem.forEach(theDataItem => {
                        dataItem.Data.push(theDataItem);
                    });

                    // Add footer
                    dataItem.Data.push(this.createSummary(columns, groupedDataItem));
                });

                // Add the total summary
                dataItem.Data.push(totalSummary);
            } else {
                // Draw summary
                if (totalSummary) {
                    dataItem.Data.push(totalSummary);
                }
            }

            // Add rownumbers
            const rowNumber = this.template.columnConfiguration.find(x => x.source?.indexOf('RowNumber') > -1);
            const rowNumberIndex = this.template.columnConfiguration.indexOf(rowNumber);
            let counter = 1;

            if (rowNumberIndex > -1) {
                dataItem.Data.slice().reverse().forEach(data => {
                    if (!data) {
                        return;
                    }

                    if (data.isHeader || data.isFooter) {
                        // Reset counter
                        counter = 1;
                    } else {
                        data[rowNumberIndex] = counter;
                        counter++;
                    }
                });
            }
        });
    }

    createSummary(columns, sourceData) {
        if (columns.some(x => x.grouping !== 0)) {

            // Add summary column
            const summary = {};
            summary['isSummary'] = true;

            columns.forEach((column, index) => {
                if (column.grouping) {

                    switch (column.grouping) {
                        case 1:
                            const sum = sourceData?.reduce((partial_sum, d) => partial_sum + (isNaN(d[index]) ? 0 : d[index]), 0);
                            summary[index] = sum;
                            break;
                        case 2:
                            const sumForAvg = sourceData?.reduce((partial_sum, d) => partial_sum + (isNaN(d[index]) ? 0 : d[index]), 0);
                            const count = sourceData?.reduce((partial_sum, d) => partial_sum + (isNaN(d[index]) ? 0 : 1), 0);

                            summary[index] = sumForAvg / count;
                            break;
                        case 3:
                            const min = sourceData?.reduce((partial_sum, d) => (partial_sum > d[index]) ? partial_sum : d[index], 0);

                            summary[index] = min;
                            break;
                        case 4:
                            const max = sourceData?.reduce((partial_sum, d) => (partial_sum > d[index]) ? partial_sum : d[index], 0);

                            summary[index] = max;
                            break;
                        default:

                            summary[index] = null;
                            break;
                    }
                } else {
                    summary[index] = null;
                }
            });

            return summary;
        }
    }

    resetSubPage(page) {

        // Reset subpage
        if (page !== this.resettedPage) {
            this.currentPageArray[page] = 0;

            this.renderedChart = null;
            this.locationData = [];

            this.resettedPage = page;
        }
    }

    checkPage(page, active) {
        let name = '';
        let selectedData;

        if (this.reportDataFlat?.data) {
            selectedData = this.reportDataFlat?.data[page.value - 1];
        }

        if (selectedData?.IconId !== undefined) {
            name += this.formatIconId(selectedData.IconId);
        }

        name += `<span>${selectedData?.Name}</span>`; // (${selectedData.Data?.length})
        return name;
    }

    formatIconId(iconId) {
        return '<img style="position: relative; margin-top: -10px; margin-bottom: -3px; padding-right:10px" src="' + getIconPath(iconId)[1] + '">';
    }

    createHeader(item, index = 0) {
        if (item.IconId !== undefined) {
            return this.formatIconId(item.IconId);
        }

        return '<i class="fas fa-fw ' + (item.IconId ?? 'fa-file-chart-pie') + '"></i>';
    }

    checkSpecialFormatting(value, formatting, uom) {
        let digits = 0;

        if (value === '-') {
            return value;
        }

        if (isNaN(value)) {
            value = 0;
        }

        switch (formatting) {
            case 'NUMERIC_num0':
                digits = 0;
                break;
            case 'NUMERIC_num1':
                digits = 1;
                break;
            case 'NUMERIC_num2':
                digits = 2;
                break;
            case 'NUMERIC_num3':
                digits = 3;
                break;
            case 'NUMERIC_m':
                // Conversion to minutes
                return Moment.utc(new Date(value * 1_000)).diff(0, 'minute');
            case 'NUMERIC_mm:ss':
                // Conversion to minutes seconds
                const minutes = Moment.utc(new Date(value * 1_000)).diff(0, 'minute');
                return `${minutes}`.padStart(2, '0') + ':' + `${Moment.utc(new Date(value * 1_000)).diff(0, 'seconds') - (minutes * 60)}`.padStart(2, '0');
            case 'NUMERIC_HH:mm:ss':
                return roundSeconds(value)
            default:
                // Conversion from seconds
                return Moment.utc(new Date(value * 1_000)).format(formatting.substring('NUMERIC_'.length));
        }

        const no: any = value + 'e' + digits;
        const formattedValue = Number(Math.round(no) + 'e-' + digits).toLocaleString(undefined, {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
        });

        return `${formattedValue}${formattedValue !== '-' ? uom : ''}`;
    }

    checkFormat(key, item, displayFormat, column) {

        if (!column) {
            console.log('columns not found');
            return;
        }

        const value = item[key];
        const nullMarker = item.isSummary ? '' : '-';

        let uom = column.uom;
        if (!uom || +uom === 0 || displayFormat === 'XLSX') {
            uom = '';
        } else {
            uom = this.translateService.instant('enums.uom.' + column.uom);
        }

        // Get from column configuration
        if (column != null) {
            key = column.source;
        }

        if (value == null || value === undefined || (typeof value.trim === 'function' && value.trim() === ',')) {
            return nullMarker;
        }

        if (key.endsWith('IconId')) {
            if (displayFormat !== 'HTML') {
                return value;
            }

            return this.formatIconId(value);
        }

        let dataType = column?.dataType
        if (dataType === 'Formula' && column.formatting?.startsWith('DATE_')) {
            dataType = 'DateTime';
        }

        switch (dataType) {
            case 'DateTime':
                if (value == null || value === undefined || value === '-') {
                    return nullMarker;
                }

                if (!column.formatting || column.formatting === '') {
                    column.formatting = 'DATE_LLL';
                }

                if (displayFormat !== 'HTML') {
                    return formatFromTimezoneWithFormat(value, this.timezoneIana, column.formatting.substring('DATE_'.length))
                }

                return '<span title="' + fromTimezone(value, this.timezoneIana).toLocaleString() + '">' + formatFromTimezoneWithFormat(value, this.timezoneIana, column.formatting.substring('DATE_'.length)) + '</span>';
            case 'Float':
                if (!column.formatting || column.formatting === '') {
                    return `${value}${value !== '-' ? uom : ''}`;
                }

                if (displayFormat === 'XLSX') {
                    switch (column.formatting) {
                        case 'NUMERIC_m':
                            return { t: 'n', v: value / 86400, z: '[m]' };
                        case 'NUMERIC_H':
                            return { t: 'n', v: value / 86400, z: '[h]' };
                        case 'NUMERIC_mm:ss':
                            return { t: 'n', v: value / 86400, z: '[m]:ss' };
                        case 'NUMERIC_HH:mm':
                            return { t: 'n', v: value / 86400, z: '[h]:mm' };
                        case 'NUMERIC_HH:mm:ss':
                            return { t: 'n', v: value / 86400, z: '[h]:mm:ss' };
                        default:
                            return value;
                    }
                }

                return this.checkSpecialFormatting(value, column.formatting, uom);
            case 'Formula':
            case 'Int32':
            case 'Int64':
                if (displayFormat === 'XLSX') {
                    switch (column.formatting) {
                        case 'NUMERIC_m':
                            return { t: 'n', v: value / 86400, z: '[m]' };
                        case 'NUMERIC_H':
                            return { t: 'n', v: value / 86400, z: '[h]' };
                        case 'NUMERIC_mm:ss':
                            return { t: 'n', v: value / 86400, z: '[m]:ss' };
                        case 'NUMERIC_HH:mm':
                            return { t: 'n', v: value / 86400, z: '[h]:mm' };
                        case 'NUMERIC_HH:mm:ss':
                            return { t: 'n', v: value / 86400, z: '[h]:mm:ss' };
                        default:
                            return value;
                    }
                }

                if (key.endsWith('Id')) {
                    return `${value}${uom}`;
                } else if (!column.formatting || column.formatting === '') {
                    return `${value.toLocaleString()}${uom}`;
                }

                return this.checkSpecialFormatting(value, column.formatting, uom);
            case 'Boolean':
                let booleanValue = value;

                if (column.inverted) {
                    booleanValue = !booleanValue;
                }

                if (!column.formatting || column.formatting === '') {
                    return booleanValue;
                }

                switch (column.formatting) {
                    case 'BOOL_onoff':
                        return booleanValue ? 'On' : 'Off';
                    case 'BOOL_highlow':
                        return booleanValue ? 'High' : 'Low';
                    default:
                        return booleanValue;
                }
            default:
                if (key.indexOf('Groups') > -1) {
                    let returnObject = '';

                    try {
                        const groups = JSON.parse(value);
                        groups.forEach(group => {
                            if (returnObject !== '') {
                                returnObject += ', ';
                            }
                            returnObject += localizeSystemGroupNames(group.name, this.translateService);
                        });

                        if (displayFormat === 'PDF') {
                            if (returnObject.length > 30) {
                                return returnObject.substring(0, 27) + '...';
                            }
                            return returnObject;
                        }

                        return returnObject;
                    } catch (error) {
                        return value;
                    }
                }

                if (key.indexOf('Score') > -1) {
                    let color = '';
                    switch (value) {
                        case value < 0.1:
                            color = 'green';
                            break;
                        case value < 0.5:
                            color = 'greenyellow';
                            break;
                        case value < 0.2:
                            color = 'orange';
                            break;
                        case value < 0.5:
                            color = 'orangered';
                            break;
                        default:
                            color = 'red';
                            break;
                    }

                    if (displayFormat !== 'HTML') {
                        return roundAsString(value, 1);
                    } else {
                        return '<span title="score: ' + value + '" style="font-weight: 700; color: ' + color + '">' + roundAsString(value, 1) + '</span>';
                    }
                }

                break;
        }

        return value;
    }

    generateMap(item, store = false) {

        if (this.locationData.length > 0) {
            console.log('Returning true because it is already rendered.');
            return true;
        }

        const latColumn = this.template.columnConfiguration.find(x => x.source?.indexOf('Latitude') > -1);
        const lonColumn = this.template.columnConfiguration.find(x => x.source?.indexOf('Longitude') > -1)

        const latIndex = this.template.columnConfiguration.indexOf(latColumn);
        const lonIndex = this.template.columnConfiguration.indexOf(lonColumn);

        if (latIndex < 0 || lonIndex < 0) {
            return false;
        }

        item?.Data?.forEach(data => {
            if (data != null) {
                if (data[latIndex] != null && data[lonIndex] != null) {
                    this.locationData.push({
                        latitude: data[latIndex],
                        longitude: data[lonIndex]
                    });
                }
            }
        });

        if (this.locationData.length > 0) {

            setTimeout(() => {
                this.drawLocations();
            }, 100);

            // Draw the map
            return true;
        } else {
            // Dont draw the map
            return false;
        }
    }

    drawLocations(displayAsTrip = this.reportDataFlat?.DisplayLocationsAsTrip, map = this.leafletMapComponent.map, locationData = this.locationData, tripLayer = this.leafletMapComponent.tripLayer) {
        console.log('Drawing locations');

        const pointList = [];
        const color = '#000';
        if (this.tripFeatureGroup) {
            map.removeLayer(this.tripFeatureGroup);
        }

        this.tripFeatureGroup = L.featureGroup();

        locationData.forEach(location => {
            if (location.latitude != null && location.latitude !== '0' && location.latitude !== 0 && location.latitude !== '-') {
                pointList.push(new L.LatLng(location.latitude, location.longitude));
            }
        });

        if (pointList.length == 0) {
            console.log('Return null');
            return null;
        }

        if (displayAsTrip) {
            const startIcon = new L['NumberMarker'].Icon({
                backgroundColor: color,
                className: 'm360',
                color: '#fff',
                number: 1,
            });

            const startMarker = L.marker(pointList[0], { icon: startIcon });
            startMarker.addTo(tripLayer);

            const finishIcon = L.icon({
                iconUrl: 'assets/images/icons/end.png',
                className: 'markerEnd',
            });
            const endMarker = L.marker(pointList[pointList.length - 1], { icon: finishIcon });
            endMarker.addTo(tripLayer);

            const tripPolyLine = new L.Polyline(pointList, {
                color,
                weight: 2,
                opacity: 1,
                smoothFactor: 1,
                dashArray: '10, 5'
            });

            const tripPolyLine2 = new L.Polyline(pointList, {
                color: '#fff',
                weight: 6,
                opacity: 0.8,
            });

            const tripPolyLine3 = new L.Polyline(pointList, {
                color: '#000',
                weight: 9,
                opacity: 0.3,
            });

            const decoratorLine = L['polylineDecorator'](tripPolyLine, {
                patterns: [
                    { offset: 24, repeat: 100, symbol: L['Symbol']['arrowHead']({ pixelSize: 15, pathOptions: { fillOpacity: 0.5, color, weight: 0, stroke: true } }) }
                ]
            });

            this.tripFeatureGroup = L.featureGroup([startMarker, endMarker, tripPolyLine3, tripPolyLine2, tripPolyLine, decoratorLine]);
            this.tripFeatureGroup.addTo(tripLayer);

            const bounds = this.tripFeatureGroup.getBounds();

            if (bounds.isValid()) {
                map.fitBounds(bounds, { padding: [5, 5] }, { animate: false, duration: 0 });
            }
        } else {
            this.tripFeatureGroup.addTo(tripLayer);

            pointList.forEach((point, index) => {

                const color2 = this.colorArray[index % this.colorArray.length];

                const pointIcon = new L['NumberMarker'].Icon({
                    backgroundColor: color2,
                    className: 'm360',
                    color: '#fff',
                    number: index + 1,
                });
                const pointMarker = L.marker(point, { icon: pointIcon });
                pointMarker.addTo(tripLayer);
                pointMarker.addTo(this.tripFeatureGroup);
            });

            const bounds = this.tripFeatureGroup.getBounds();

            if (bounds.isValid()) {
                map.fitBounds(bounds, { padding: [5, 5] }, { animate: false, duration: 0 });
            }
        }
    }


    parseChartData(theData, chart, chartType) {
        let yAxis = 0;

        if (chart.Name) {
            if (chart.Name.indexOf('Speed') > -1) {
                yAxis = 6;
            }

            if (chart.Name.indexOf('Temp') > -1) {
                yAxis = 1;
            }

            if (chart.Name.indexOf('Humidity') > -1) {
                yAxis = 2;
            }

            if (chart.Name.indexOf('Weight') > -1) {
                yAxis = 3;
            }

            if (chart.Name.indexOf('Ignition') > -1) {
                yAxis = 4;
            }

            if (chart.Name.indexOf('power') > -1) {
                yAxis = 5;
            }

            if (chart.Name.indexOf('Analog') > -1) {
                yAxis = 7;
            }

            if (chart.Name.indexOf('Odometer') > -1) {
                yAxis = 0;
            }
        }

        theData.push({
            data: chart.Data,
            type: chart.Type ?? chartType,
            yAxis: yAxis,
            visible: chart.Visible ?? true,
            name: chart.Name,
        });
    }

    // Generate chart locationcount
    generateChart(data, store = false) {
        if (store && this.renderedChart) {
            return this.renderedChart;
        }

        const theData = [];
        let categories = [];

        if (data.chartData) {
            categories = data.categories;
            data.chartData.forEach(chart => {
                this.parseChartData(theData, chart, data.chartType);
            });
        }

        if (data.ChartData) {
            categories = data.Categories;
            data.ChartData?.forEach(chart => {
                this.parseChartData(theData, chart, data.chartType);
            });
        }

        let theChart;

        if (data.isDateTimeBased || data.IsDateTimeBased) {
            // For instance sensor based
            theChart = this.chartService.generateReportChartDateTime(theData, data.chartType);
        } else {
            theChart = this.chartService.generateReportChart(theData, categories, data.chartType);
        }

        if (store) {
            this.renderedChart = theChart;
        }
        return theChart;
    }

    // Export to XLSX
    downloadXls(exportAsCsv = true) {

        // If TWD then do a custom xls download
        if (this.activeReport?.ReportType == 2) {
            return this.downloadTWDXls(exportAsCsv);
        }

        const columns = this.template.columnConfiguration;

        const workbook = XLSX.utils.book_new();

        const requestData = this.generateHeaderData();

        requestData.unshift({ Name: this.translateService.instant('general.reportName'), Value: this.activeReport.ReportName ? this.activeReport.ReportName : this.selectedReport });

        const requestWorkSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(requestData, { skipHeader: true });
        XLSX.utils.book_append_sheet(workbook, requestWorkSheet, 'Request');

        const kpiDeepCopy = JSON.parse(JSON.stringify(this.reportData?.kpiList ?? []));

        kpiDeepCopy.forEach((item, _) => {
            if (item?.hasOwnProperty('icon')) {
                delete item['icon'];
            }
        });

        const kpiWorkSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(kpiDeepCopy);

        XLSX.utils.book_append_sheet(workbook, kpiWorkSheet, 'Kpis');

        this.reportDataFlat.data.forEach((sheet, index) => {
            const contentData = [];

            sheet.Data.forEach(dataRow => {
                const item = {};

                if (dataRow != null) {
                    Object.keys(dataRow).forEach((key, columnIndex) => {
                        if (key !== 'isSummary' && key !== 'isHeader' && key !== 'isHighlighted') {
                            item[columns[columnIndex].name] = this.checkFormat(key, dataRow, 'XLSX', columns[columnIndex]);
                        }
                    });
                }

                contentData.push(item);
            });

            const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(contentData);
            XLSX.utils.book_append_sheet(workbook, workSheet, sheet.Name.replace(/[|&;$%@"<>()+,\/]/g, '').substring(0, 30));
        });

        const filename = ('fm_export_' + this.selectedAccountName).replace(/\s/g, '').replace(' ', '').replace('.', '').toLowerCase();

        if (exportAsCsv) {
            XLSX.writeFile(workbook, (filename + '.csv'), { bookType: 'csv' });
        } else {
            XLSX.writeFile(workbook, (filename + '.xlsx'));
        }
    }

    // Export to XLSX
    downloadTWDXls(exportAsCsv = true) {

        const columns = this.template.columnConfiguration;

        const workbook = XLSX.utils.book_new();

        const requestData = this.generateHeaderData();

        requestData.unshift({ Name: this.translateService.instant('general.reportName'), Value: this.activeReport.ReportName ? this.activeReport.ReportName : this.selectedReport });

        const requestWorkSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(requestData, { skipHeader: true });
        XLSX.utils.book_append_sheet(workbook, requestWorkSheet, 'Request');

        const kpiDeepCopy = JSON.parse(JSON.stringify(this.reportData?.kpiList ?? []));

        kpiDeepCopy.forEach((item, _) => {
            if (item?.hasOwnProperty('icon')) {
                delete item['icon'];
            }
        });

        const kpiWorkSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(kpiDeepCopy);

        XLSX.utils.book_append_sheet(workbook, kpiWorkSheet, 'Kpis');

        this.reportData.data.forEach((asset, index) => {
            asset.Data.forEach(trip => {
                if (trip != null) {
                    const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([trip]);
                    const sheetName = (asset.Name + '_' + trip.Id);
                    XLSX.utils.book_append_sheet(workbook, workSheet, sheetName.replace(/[|&;$%@"<>()+,\/]/g, '').substring(0, 30));
                }
            });
        });

        const filename = ('fm_export_' + this.selectedAccountName).replace(/\s/g, '').replace(' ', '').replace('.', '').toLowerCase();

        if (exportAsCsv) {
            XLSX.writeFile(workbook, (filename + '.csv'), { bookType: 'csv' });
        } else {
            XLSX.writeFile(workbook, (filename + '.xlsx'));
        }
    }

    generateHeaderData() {
        const start = Moment.utc(this.activeReport?.periodStart)['tz'](this.timezoneIana);
        const end = Moment.utc(this.activeReport?.periodEnd)['tz'](this.timezoneIana);
        const timestamp = Moment.utc(this.activeReport?.executedTimestamp)['tz'](this.timezoneIana);

        return [
            { Name: this.translateService.instant('general.account'), Value: this.activeReport.companyName },
            { Name: this.translateService.instant('general.selection'), Value: start.format('lll') + ' - ' + end.format('lll') },
            { Name: this.translateService.instant('general.generatedBy'), Value: this.activeReport.userName },
            { Name: this.translateService.instant('general.generatedOn'), Value: timestamp.format('lll') },
            { Name: this.translateService.instant('dates.timezone'), Value: `${start.tz()} (${start.format('z Z')})` },
        ];
    }

    getIndexOfArray(arr, find: string[]) {
        const indexes = [];
        for (let i = 0; i < arr.length; i++) {
            if (find.indexOf(arr[i]) > -1) {
                indexes.push(i);
            }
        }

        return indexes;
    }

    generateChartBase64(options, key, theme, parentContainer = document.body) {
        return new Promise((resolve, reject) => {
            const container = document.createElement('div');
            container.setAttribute('style', 'position: relative;');
            parentContainer.appendChild(container);

            const chartObject = options?.chart;
            chartObject['renderTo'] = container;

            if (options.plotOptions) {
                options.plotOptions.pie = {
                    animation: false,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                };

                if (options.plotOptions.column) {

                    options.plotOptions.column.animation = false;
                }
            }

            const legend = {
                enabled: chartObject?.type === 'pie',
            };

            const fontSize = 8;

            const xAxis = options?.xAxis;
            if (xAxis?.labels && xAxis.labels.style) {
                xAxis.labels.style.fontSize = fontSize;
            } else {
                xAxis.labels = {
                    style: {
                        fontSize,
                    }
                }
            }

            let yAxis = options?.yAxis;

            const labels = {
                style: {
                    fontSize,
                }
            }

            if (yAxis.length > 0) {
                for (let i = 0; i < yAxis.length; i++) {
                    if (yAxis[i]?.labels && yAxis[i].labels.style) {
                        yAxis[i].labels.style.fontSize = fontSize;
                    } else {
                        yAxis[i] = { ...yAxis[i], labels };
                    }
                }
            } else {
                yAxis = { ...yAxis, labels };
            }

            const colors = [theme.overwrite ? theme.chartPrimary : '#CB711D', theme.overwrite ? theme.chartSecondary : '#D6A282', theme.overwrite ? theme.chartThird : '#D3C6BE', theme.overwrite ? theme.chartFourth : '#D3C6BE', theme.overwrite ? theme.chartFifth : '#D3C6BE']

            const _ = Highcharts.chart({ ...options, legend, xAxis, yAxis, colors, chart: chartObject }, (chart) => {
                const activeSerie = chart.series.find((serie) => serie.visible);
                resolve([container.querySelector('svg'), activeSerie?.name]);
            });
        });
    }


    generateMapBase64(trip, parentContainer = document.body) {
        return new Promise((resolve, reject) => {
            const container = document.createElement('div');
            container.setAttribute('style', 'height: 200px; width: 600px; position: relative;');
            parentContainer.appendChild(container);

            var map = L.map(container).setView([-33.8650, 151.2094], 10);

            // Set up the OSM layer
            L.tileLayer(
                'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 18
            }).addTo(map);

            const tripLayer = L.featureGroup();
            tripLayer.addTo(map);

            this.drawLocations(true, map, trip.Messages.map((data) => {
                return { latitude: data.Latitude, longitude: data.Longitude }
            }), tripLayer);

            resolve([map, container]);
        });
    }

    resolveMaps(container = document.body) {
        return new Promise((resolve, reject) => {
            resolve(container);
        });
    }

    async renderCharts(chartContainer, theme) {
        const chartPromises = [];

        for (let i = 0; i < this.charts.length; i++) {
            const chartItem = this.charts[i];
            chartPromises.push(this.generateChartBase64(chartItem, 'CHART_RENDER_' + i, theme, chartContainer));
        }

        return await Promise.all(chartPromises);
    }

    async downloadPdf(includeCharts = true, includeData = true, includeMaps = false) {

        // If TWD then do a custom pdf download
        if (this.activeReport?.ReportType == 2) {
            return this.downloadTWDPdf(includeCharts, includeData, includeMaps);
        }

        const doc = new jsPDF('l', 'px', 'A4', true);

        const totalPagesExp = '{total_pages_count_string}'

        const theme = this.colorService.getThemeByUserType(this.activeReport.userType);

        const headerColor = theme.secondary;

        const currentPage = doc.getCurrentPageInfo();

        // Add charts container
        const chartContainer = document.createElement('div');
        chartContainer.setAttribute('id', 'reportChart');
        chartContainer.setAttribute('style', 'position: absolute; visibility: hidden;');
        document.body.appendChild(chartContainer);

        const reportImage = await new Promise<JQuery>((resolve, _) => {
            const image = $('<img style="max-width: 300px; max-height: 135px;" src="data:image/png;base64,' + theme.reportBinary + '" />').appendTo('body');

            setTimeout(() => {
                resolve(image);
            }, 500);
        });

        const base64Img = { width: reportImage.innerWidth(), height: reportImage.innerHeight(), url: theme.reportBinary };
        if (base64Img?.url) {
            doc.addImage(base64Img.url, 'PNG', (currentPage.pageContext.mediaBox.topRightY - (base64Img.width * 0.5)), 25, base64Img.width * 0.5, base64Img.height * 0.5);
        }

        reportImage.remove();

        doc.setFont('IBMPlexSansArabic-Regular', 'normal');

        doc.setTextColor(40);
        doc.setFontSize(28);

        doc.textWithLink(this.activeReport.ReportName ? this.activeReport.ReportName : this.selectedReport, 20, 43, { url: window.location.href });

        autoTable(doc, {
            body: this.generateHeaderData(),
            startY: 63,
            theme: 'plain',
            tableWidth: 'wrap',
            rowPageBreak: 'avoid',
            bodyStyles:
            {
                font: 'IBMPlexSansArabic-Regular',
                textColor: 40,
                fontSize: 10,
                fontStyle: 'normal',
                cellPadding: {
                    top: 4,
                    bottom: 4,
                    right: 10,
                    left: 0,
                },
            },
            margin: {
                left: 20,
            },
        });

        const MAX_ROW_KPI = 4;

        for (let i = 0; i < this.reportData.kpiList.length; i++) {
            doc.setDrawColor(230);
            doc.setFillColor(250, 250, 250);

            const rowPadding = 10;
            const kpiWidth = 152.5;
            const kpiHeight = 50

            const spacerHeight = rowPadding + kpiHeight;
            const centerWidth = (rowPadding + kpiWidth) / 2;

            const bottomPadding = 300;
            const textPadding = 17;

            const iconWidth = 9;

            doc.rect(((i % MAX_ROW_KPI) * kpiWidth) + rowPadding, (currentPage.pageContext.mediaBox.topRightY - (bottomPadding + 35)) + (Math.floor(i / MAX_ROW_KPI) * spacerHeight), kpiWidth - rowPadding, kpiHeight, 'FD');

            doc.setFont('IBMPlexSansArabic-Regular', 'normal');

            doc.setTextColor(80);
            doc.setFontSize(15);
            doc.text((this.reportData.kpiList[i].value.toLocaleString() + ' ' + this.reportData.kpiList[i].uom).replace(/\s+$/g, ''), iconWidth + (((i % MAX_ROW_KPI) * kpiWidth) + centerWidth), (currentPage.pageContext.mediaBox.topRightY - (bottomPadding + textPadding)) + (Math.floor(i / MAX_ROW_KPI) * spacerHeight), null, 'center');

            const textWidth = (doc.getTextWidth((this.reportData.kpiList[i].value.toLocaleString() + ' ' + this.reportData.kpiList[i].uom).replace(/\s+$/g, '')) * 0.45) + 22;

            doc.setFont('fa-solid-900', 'normal');

            doc.setFontSize(16);
            doc.text(this.reportData.kpiList[i].icon, iconWidth + ((((i % MAX_ROW_KPI) * kpiWidth) + centerWidth) - textWidth), (currentPage.pageContext.mediaBox.topRightY - bottomPadding) + (Math.floor(i / MAX_ROW_KPI) * spacerHeight) - 16);

            doc.setFont('IBMPlexSansArabic-Regular', 'normal');

            doc.setTextColor(150);
            doc.setFontSize(12);
            // doc.text(this.reportData.kpiList[i].name.split(' ').join('\n'), ((i % MAX_ROW_KPI) * kpiWidth) + centerWidth, (currentPage.pageContext.mediaBox.topRightY - bottomPadding) + (Math.floor(i / MAX_ROW_KPI) * spacerHeight), null, 'center');
            doc.text(this.reportData.kpiList[i].name, ((i % MAX_ROW_KPI) * kpiWidth) + centerWidth, (currentPage.pageContext.mediaBox.topRightY - bottomPadding) + (Math.floor(i / MAX_ROW_KPI) * spacerHeight), null, 'center');
        }

        // Print charts
        if (includeCharts && this.charts.length > 0) {
            const charts = await this.renderCharts(chartContainer, theme);

            const chartWidth = 562;
            const chartHeight = 374;
            doc.setTextColor(40);

            for (let i = 0; i < charts.length; i++) {
                doc.addPage();

                // Add title
                if (this.reportData?.charts[i]?.icon) {
                    doc.setFont('fa-solid-900', 'normal');

                    doc.setFontSize(20);
                    doc.text(this.reportData?.charts[i].icon, 15, 32);
                }

                if (this.reportData?.charts[i]?.name) {
                    doc.setFont('IBMPlexSansArabic-Regular', 'normal');

                    doc.setFontSize(16);
                    doc.text(this.reportData?.charts[i].name, 40, 30);
                }

                await doc.svg(charts[i][0], { x: 30, y: 55, width: chartWidth, height: chartHeight });
            }
        }

        doc.setFontSize(10);
        doc.setTextColor(40);

        let chartCollection = [];

        // Prefetch all charts upfront
        if (includeCharts) {
            const chartsPromises = [];

            for (const rowData of this.reportDataFlat.data) {
                if (rowData.Charts?.length > 0) {
                    const data = this.generateChart(rowData.Charts[0]);
                    chartsPromises.push(this.generateChartBase64(data, 'CHART_RENDER_' + rowData.Name, theme, chartContainer));
                }
            }

            chartCollection = await Promise.all(chartsPromises);
        }

        // Foreach over data
        for (let i = 0; i < this.reportDataFlat.data.length; i++) {
            const rowData = this.reportDataFlat.data[i];

            // Print charts - Please note this is very slow
            if (includeCharts && rowData.Charts && chartCollection[i][1]) {
                // New page
                doc.addPage();

                doc.setFont('fa-solid-900', 'normal');
                doc.setFontSize(24);

                doc.text('\uf2db', 15, 32);

                doc.setFont('IBMPlexSansArabic-Regular', 'normal');
                doc.setFontSize(16);

                doc.textWithLink(rowData.Name + ' [' + chartCollection[i][1] + ']', 40, 30, { url: location.protocol + '//' + location.host + (rowData?.DriverId ? '#/DriverDetails/Index/' + rowData?.DriverId : '#/DeviceDetails/Index/' + rowData?.DeviceId) });

                const chartWidth = 562;
                const chartHeight = 374;

                try {
                    await doc.svg(chartCollection[i][0], { x: 30, y: 55, width: chartWidth, height: chartHeight });
                } catch (error) {
                    console.log(error);
                }
            }

            // Print data
            if (includeData) {
                // New page
                doc.addPage();

                doc.setFont('fa-solid-900', 'normal');
                doc.setFontSize(24);

                let iconUnicode = '\uf2db';

                if (rowData.Icon !== undefined) {
                    iconUnicode = rowData.Icon;
                }

                doc.text(iconUnicode, 15, 26);

                doc.setFont('IBMPlexSansArabic-Regular', 'normal');
                doc.setFontSize(16);

                let externalLink: string;

                if (rowData.DeviceId > -1) {
                    externalLink = '#/DeviceDetails/Index/' + rowData.DeviceId;
                } else if (rowData.AssetId > -1) {
                    externalLink = '#/AssetDetails/Index/' + rowData.AssetId;
                } else if (rowData.DriverId > -1) {
                    externalLink = '#/DriverDetails/Index/' + rowData.DriverId;
                } else if (rowData.GeofenceId > -1) {
                    externalLink = '#/GeofenceDetails/Index/' + rowData.GeofenceId;
                } else if (rowData.ProjectId > -1) {
                    externalLink = '#/ProjectDetails/Index/' + rowData.ProjectId;
                }

                if (externalLink) {
                    doc.textWithLink(String(rowData.Name), 40, 24, { url: location.protocol + '//' + location.host + externalLink });
                } else {
                    doc.text(String(rowData.Name), 40, 24);
                }

                // Filter unwanted columns in export
                const columns = this.template.columnConfiguration;

                // Remove useless columns
                const reportHead = [];
                const reportStyles = {};

                for (let j = 0; j < columns.length; j++) {
                    reportStyles[j] = {
                        cellWidth: columns[j].width > 0 ? columns[j].width / 2 : 'auto',
                        halign: columns[j].alignment === 3 ? 'right' : (columns[j].alignment === 2 ? 'center' : 'left')
                    };

                    reportHead.push(columns[j].name);
                }

                const reportData = [];
                rowData.Data?.filter(x => x !== undefined).forEach(item => {
                    const items = [];

                    Object.keys(item).forEach((key, index) => {
                        if (key === 'headerName') {
                            items.push(item[key]);
                        } else if (key !== 'isSummary' && key !== 'isHeader' && key !== 'isHighlighted') {
                            items.push(this.checkFormat(key, item, 'PDF', columns[index]));
                        }
                    });

                    reportData.push(items);
                });

                // Draw table
                autoTable(doc, {
                    head: [reportHead],
                    body: reportData, // .slice(reportData.length - 20),
                    // theme: 'grid',
                    startY: 39,
                    headStyles:
                    {
                        font: 'IBMPlexSansArabic-Bold',
                        fillColor: headerColor,
                        textColor: 255,
                        fontSize: 7,
                        fontStyle: 'bold',
                    },
                    pageBreak: 'auto',
                    rowPageBreak: 'avoid',
                    didParseCell: (hookData) => {
                        if (hookData.section === 'head') {
                            hookData.cell.styles.halign = reportStyles[hookData.column.dataKey].halign ?? 'left';
                        } else if (hookData.section === 'body') {
                            if (hookData.row.raw['length'] === 1) {
                                hookData.row.cells[0].colSpan = Object.keys(hookData.row.cells).length;
                            }
                        }
                    },
                    willDrawCell: function (data) {
                        if (data.section === 'body') {
                            // Check the source data for special rendering
                            if (rowData?.Data[data.row.index]?.isHeader) {
                                // Draw header

                                doc.setDrawColor(255, 255, 255);
                                doc.setLineWidth(2);
                                doc.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);

                                doc.setFillColor(225, 225, 225);
                                doc.setTextColor(40, 40, 40);

                            } else if (rowData?.Data[data.row.index]?.isSummary) {
                                // Draw footer
                                doc.setDrawColor(255, 255, 255);
                                doc.setLineWidth(1);
                                doc.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);

                                doc.setFillColor(237, 237, 237);
                                doc.setTextColor(40, 40, 40);

                            } else if (rowData?.Data[data.row.index]?.isHighlighted) {
                                // Draw calibration
                                doc.setFillColor(255, 248, 225);
                            }
                        }
                    },
                    didDrawPage: function (data) {
                        // Header
                        doc.setFontSize(20);
                        doc.setTextColor(40);
                        doc.setFont('IBMPlexSansArabic-Regular', 'normal');

                        const leftMargin = data.settings.margin.left

                        // Footer
                        let str = 'Page ' + doc.internal['getNumberOfPages']()
                        // Total page number plugin only available in jspdf v1.0+
                        if (typeof doc.putTotalPages === 'function') {
                            str = str + ' of ' + totalPagesExp
                        }
                        doc.setFontSize(10);

                        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                        const pageSize = doc.internal.pageSize
                        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                        doc.text(str, data.settings.margin.left, pageHeight - 11)
                    },
                    margin: { top: 10, left: 10, right: 10, bottom: 20 },
                    styles: {
                        fontSize: 7,
                        cellPadding: 3,
                        font: 'IBMPlexSansArabic-Regular',
                    },
                    columnStyles: reportStyles,
                    tableWidth: 'auto',
                });
            }
        }

        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp)
        }

        const filename = ('fm_report_' + this.selectedAccountName + '_' + this.selectedReport).replace(/\s/g, '').replace(' ', '').replace('.', '').toLowerCase();

        // Cleanup
        $('#reportChart').remove();

        doc.save(filename + '.pdf', { returnPromise: true }).then(function () {
            console.log('returning true');
            return true;
        });
    }

    testUnicode(string) {
        return /[^\u0000-\u00ff]/.test(string);
    }

    /////////////////////////////////////////
    // TRIPS WITH DETAILS
    /////////////////////////////////////////
    generateTwdMap(trip, store = false, delay = true) {

        // reset locationdata
        this.locationData = [];

        trip?.Messages?.forEach(data => {
            if (data != null) {
                this.locationData.push({
                    latitude: data.Latitude,
                    longitude: data.Longitude
                });
            }
        });

        if (this.locationData.length > 0) {

            if (delay) {
                setTimeout(() => {
                    this.drawLocations(true);
                }, 100);
            } else {
                this.drawLocations(true);
            }

            // Draw the map
            return true;
        } else {
            // Dont draw the map
            return false;
        }
    }

    resetSubPageTripsWithDetails(trip, page) {

        // Reset subpage
        if (trip.Id !== this.displayedTripId) {
            this.generateTwdMap(trip, true)
            this.generateTwdChart(trip, true)
        }

        this.cd.markForCheck();

        this.displayedTripId = trip.Id;

        return trip;
    }

    displayLocation(episode) {
    }

    prepareTripData(trip) {
        trip.Details = [];

        trip.Start = ({ timestamp: Moment.utc(trip.BeginTS)['tz'](this.timezoneIana), classNameIcon: 'fa-play', name: this.translateService.instant('general.startTrip'), beginLocation: trip.BeginAddressFull });
        trip.End = ({ timestamp: Moment.utc(trip.EndTS)['tz'](this.timezoneIana), classNameIcon: 'fa-flag-checkered', name: this.translateService.instant('general.endTrip'), beginLocation: trip.EndAddressFull });
        trip.DurationInSeconds = trip.End.timestamp.diff(trip.Start.timestamp, 'seconds');
        trip.Duration = Moment.duration(trip.DurationInSeconds, 'seconds');
        trip.DurationHumanized = trip.Duration.humanize();

        trip.Episodes.forEach(episode => {
            let classNameIcon = 'fa-alert';
            let classNameRow = '';
            // Idling
            if (episode.FkDeviceEpisodeTypeId == 131) {
                classNameIcon = 'fa-snooze';
                classNameRow = 'idling';
            }

            const begin = Moment.utc(episode.EpisodeStart)['tz'](this.timezoneIana);
            const end = episode.EpisodeEnd ? Moment.utc(episode.EpisodeEnd)['tz'](this.timezoneIana) : Moment.utc()['tz'](this.timezoneIana);
            const diff = end.diff(begin, 'seconds');
            const humanized = Moment.duration(diff, 'seconds').humanize();
            const beginLocation = (episode.BeginLocation != '' ? episode.BeginLocation : roundAsNumber(episode.BeginLatitude, 4) + ' - ' + roundAsNumber(episode.BeginLongitude, 4));
            const name = episode.FkDeviceEpisodeTypeId == 0 ? episode.Description : this.translateService.instant(('enums.deviceEpisode.' + episode.FkDeviceEpisodeTypeId));

            const episodeFormatted = {
                beginLatitude: episode.BeginLatitude,
                beginLocation: episode.BeginLocation,
                beginLongitude: episode.BeginLongitude,
                description: episode.Description,
                episodeEnd: episode.EpisodeEnd,
                episodeStart: episode.EpisodeStart,
                fkDeviceEpisodeTypeId: episode.FkDeviceEpisodeTypeId,
                fkDeviceId: episode.FkDeviceId,
                triggerId: episode.FkTriggerId,
                id: episode.Id,
                tripId: episode.TripId
            }

            const episode2 = parseEpisode(episodeFormatted, this.translateService, Moment, this.timezoneIana, this.distance);
            if (episode2) {
                classNameIcon = episode2.icon;
            }

            trip.Details.push({ classNameRow: classNameRow, location: episode2.location, classNameIcon: classNameIcon, timestamp: Moment.utc(episode.EpisodeStart)['tz'](this.timezoneIana), name: name, status: humanized, beginLocation: beginLocation });
        });

        trip.GeofenceStates.forEach(geofenceState => {
            trip.Details.push({ timestamp: Moment.utc(geofenceState.StateChangeDateTime)['tz'](this.timezoneIana), classNameIcon: 'fa-draw-polygon', name: geofenceState.HasEntered ? this.translateService.instant('general.enteredGeofence') : this.translateService.instant('general.leftGeofence'), beginLocation: geofenceState.GeoFenceLabel });
        });
    }

    // Generate chart locationcount
    generateTwdChart(trip, store = false, delay = true) {

        const iconPath = getIconPath(trip.IconId)[1];
        const theIcon = L.icon({
            iconUrl: iconPath,
            // className: 'markerPlayTrip',
            iconAnchor: [16, 16],
        });

        let theChart;

        let theData = this.drawChart(trip.Messages);

        if (delay) {
            setTimeout(() => {
                theChart = this.chartService.generateMapChart(theData, [], [], this.leafletMapComponent.map, theIcon);

                if (store) {
                    this.renderedChart = theChart;
                }

                this.cd.markForCheck();

                return true;
            }, 200);
        } else {
            theChart = this.chartService.generateMapChart(theData, [], [], this.leafletMapComponent.map, theIcon);

            if (store) {
                this.renderedChart = theChart;
            }

            return theChart;
        }

        return true;
    }


    drawChart(locations) {

        // The speed gauge
        // data, km, fuel percentage, deviation, symbol
        // The speed gauge
        // data, km, fuel percentage, deviation, symbol
        const theChartDataDistance = [];
        const theChartDataIgnition = [];
        const theChartDataSpeed = [];
        const theChartDataExternalPower = [];
        const theChartDataGpsFix = [];
        const theChartFuelLevel = [];
        const theChartDataTemperature = [];
        const theChartDataTemperature2 = [];
        const theChartDataWeight = [];
        const theChartDataAvgWeight = [];
        const theChartDataWeight2 = [];
        const theChartDataAvgWeight2 = [];
        const theChartDataHumidity = [];
        const theChartDataExternalPowerVoltage = [];

        const theChartDataAcceleration = [];
        const theChartDataBraking = [];
        const theChartDataCornering = [];

        const theChartDataRpm = [];

        const theChartDataAnalog1 = [];
        const theChartDataAnalog2 = [];

        const theChartDataIO = [];

        const theChartFuelConsumed = [];

        const that = this;

        const cachedDistanceOffset = 0;
        let lastlocation = null;

        if (locations.length === 0) {
            return;
        }

        $.each(locations.sort((a, b) => (a.Timestamp < b.Timestamp ? -1 : 1)), function (index, value) {

            const distance = (value.OdometerValueInMetres - cachedDistanceOffset);

            // Set lastlocation + 1 milisecond to stop distancechart from making a big leap
            if (lastlocation !== null && distance === 0 && lastlocation.y !== 0) {
                lastlocation.x = lastlocation.x + 1;
                lastlocation.y = 0;
                theChartDataDistance.push(lastlocation);
            }

            const dateTime = Moment.utc(value.Timestamp)['tz'](that.timezoneIana).unix() * 1000;
            const coordinate = [value.Latitude, value.Longitude];

            if (value.Latitude !== 0) {
                const location = { x: dateTime, y: Math.max(0, (distance / 1000)), suffix: 'km', latlon: coordinate };
                lastlocation = location;
                theChartDataDistance.push(location);
            }

            theChartDataIgnition.push({ x: dateTime, y: value.IgnitionSignal ? 1 : 0, latlon: coordinate });
            theChartDataExternalPower.push({ x: dateTime, y: value.ExternalPowerSignal ? 1 : 0, latlon: coordinate });
            theChartDataGpsFix.push({ x: dateTime, y: value.HasGpsFix ? 1 : 0, latlon: coordinate });

            if (value.SpeedInKph !== undefined || this.filterZeroValues) {
                theChartDataSpeed.push({ x: dateTime, y: value.SpeedInKph, suffix: 'km/h', latlon: coordinate });
            }

            if (value.ExternalPowerVoltage !== undefined || this.filterZeroValues) {
                theChartDataExternalPowerVoltage.push({ x: dateTime, y: value.ExternalPowerVoltage, suffix: ' V', latlon: coordinate });
            }

            if (value.FuelLevel !== undefined && (value.FuelLevel !== 0 || this.filterZeroValues)) {
                theChartFuelLevel.push({ x: dateTime, y: value.FuelLevel, suffix: '%', latlon: coordinate });
            }

            if (value.Weight1 !== undefined && (value.Weight1 !== 0 || this.filterZeroValues)) {
                theChartDataWeight2.push({ x: dateTime, y: value.Weight1, suffix: ' kg', latlon: coordinate });
            }

            if (value.Weight2 !== undefined && (value.Weight2 !== 0 || this.filterZeroValues)) {
                theChartDataWeight2.push({ x: dateTime, y: value.Weight2, suffix: ' kg', latlon: coordinate });
            }

            if (value.Humidity1 !== undefined && (value.Humidity1 !== 0 || this.filterZeroValues)) {
                theChartDataHumidity.push({ x: dateTime, y: value.Humidity1, suffix: '%', latlon: coordinate });
            }

            if (value.Temperature1 !== undefined && (value.Temperature1 !== 0 || this.filterZeroValues)) {
                theChartDataTemperature.push({ x: dateTime, y: value.Temperature1, suffix: '°C', latlon: coordinate });
            }

            if (value.Temperature2 !== undefined && (value.Temperature2 !== 0 || this.filterZeroValues)) {
                theChartDataTemperature2.push({ x: dateTime, y: value.Temperature2, suffix: '°C', latlon: coordinate });
            }

            if (value.Rpm !== undefined && (value.Rpm !== 0 || this.filterZeroValues)) {
                theChartDataRpm.push({ x: dateTime, y: value.Rpm, suffix: ' rpm', latlon: coordinate });
            }


            theChartDataIO.push({ x: dateTime, y: { i1: value.input1, i2: value.input2 }, latlon: coordinate });
        });


        let theData = [];

        theData = [{
            name: 'Distance',
            type: 'area',
            //  threshold: null,
            // step: 'right',
            dashStyle: 'dash',
            fillOpacity: 0.1,
            opacity: 0.3,
            color: '#ccc',
            zIndex: 5,
            yAxis: 1,
            data: theChartDataDistance
        }, {
            name: 'Speed',
            type: 'spline',
            color: '#5AB867',
            visible: true,
            step: 'left',
            yAxis: 0,
            marker: {
                enabled: false,
                lineWidth: 2,
                symbol: 'square'
            },
            zIndex: 3,
            data: theChartDataSpeed
        }];

        if (theChartDataIgnition.some(x => x.y)) {
            theData.push(
                {
                    name: 'Ignition',
                    type: 'line',
                    color: '#00E0C6',
                    visible: false,
                    step: 'left',
                    marker: {
                        enabled: false,
                        lineWidth: 2,
                        symbol: 'square'
                    },
                    yAxis: 3,
                    zIndex: 3,
                    data: theChartDataIgnition
                });
        };

        if (theChartDataExternalPower.some(x => x.y)) {
            theData.push({
                name: 'ExternalPower',
                type: 'line',
                color: '#00E0C6',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 4,
                zIndex: 3,
                data: theChartDataExternalPower
            });
        };

        if (theChartDataExternalPowerVoltage.some(x => x.y)) {
            theData.push({
                name: 'Voltage',
                type: 'line',
                dashStyle: 'ShortDashDot',
                color: '#00E0C6',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 6,
                zIndex: 3,
                data: theChartDataExternalPowerVoltage
            });
        };

        if (theChartDataRpm.some(x => x.y)) {
            theData.push({
                name: 'RPM',
                type: 'line',
                dashStyle: 'ShortDashDot',
                color: '#ff0000',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 13,
                zIndex: 3,
                data: theChartDataRpm
            });
        };

        if (theChartDataAnalog1.some(x => x.y)) {
            theData.push({
                name: 'Analog1',
                type: 'line',
                dashStyle: 'ShortDashDot',
                color: '#00E0C6',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 6,
                zIndex: 3,
                data: theChartDataAnalog1
            });
        };

        if (theChartDataAnalog2.some(x => x.y)) {
            theData.push({
                name: 'Analog2',
                type: 'line',
                dashStyle: 'ShortDashDot',
                color: '#00E0C6',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 6,
                zIndex: 3,
                data: theChartDataAnalog2
            });
        };

        if (theChartDataGpsFix.some(x => x.y)) {
            theData.push({
                name: 'HasGpsFix',
                type: 'line',
                color: '#1A4467',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 5,
                zIndex: 3,
                data: theChartDataGpsFix
            });
        };

        if (theChartFuelLevel.some(x => x.y)) {
            theData.push({
                name: 'FuelLevel',
                type: 'spline',
                color: '#7589FF',
                dashStyle: 'ShortDot',
                fillOpacity: 0.2,
                visible: true,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 2,
                zIndex: 3,
                data: theChartFuelLevel
            });
        };

        if (theChartFuelConsumed.some(x => x.y)) {
            theData.push({
                name: 'Fuel Consumed',
                type: 'spline',
                color: '#A982FF',
                dashStyle: 'LongDash',
                fillOpacity: 0.2,
                visible: true,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 12,
                zIndex: 3,
                data: theChartFuelConsumed
            });
        };

        if (theChartDataWeight.some(x => x.y)) {
            theData.push({
                name: 'Weight',
                type: 'spline',
                color: '#FF0015',
                dashStyle: 'Dash',
                fillOpacity: 0.2,
                visible: true,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 9,
                zIndex: 3,
                data: theChartDataWeight
            });
        };

        if (theChartDataAvgWeight.some(x => x.y)) {
            theData.push({
                name: 'AvgWeight',
                type: 'spline',
                color: '#FF6666',
                dashStyle: 'Dash',
                fillOpacity: 0.2,
                visible: true,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 9,
                zIndex: 3,
                data: theChartDataAvgWeight
            });
        };

        if (theChartDataAvgWeight2.some(x => x.y)) {
            theData.push({
                name: 'AvgWeight2',
                type: 'spline',
                color: '#FF6666',
                dashStyle: 'Dash',
                fillOpacity: 0.2,
                visible: true,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 9,
                zIndex: 3,
                data: theChartDataAvgWeight2
            });
        };

        if (theChartDataWeight2.some(x => x.y)) {
            theData.push({
                name: 'Weight',
                type: 'spline',
                color: '#FFFF15',
                dashStyle: 'Dash',
                fillOpacity: 0.2,
                visible: true,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 9,
                zIndex: 3,
                data: theChartDataWeight2
            });
        };

        if (theChartDataHumidity.some(x => x.y)) {
            theData.push({
                name: 'Humidity',
                type: 'spline',
                color: '#FF0090',
                fillOpacity: 0.2,
                visible: true,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 8,
                zIndex: 3,
                data: theChartDataHumidity
            });
        };

        if (theChartDataTemperature.some(x => x.y)) {
            theData.push({
                name: 'Temperature',
                type: 'spline',
                color: '#FF0015',
                fillOpacity: 0.2,
                visible: true,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 7,
                zIndex: 3,
                data: theChartDataTemperature
            });
        };

        if (theChartDataTemperature2.some(x => x.y)) {
            theData.push({
                name: 'Temperature2',
                type: 'spline',
                color: '#8700FF',
                fillOpacity: 0.2,
                visible: true,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 7,
                zIndex: 3,
                data: theChartDataTemperature2
            });
        };

        if (theChartDataAcceleration.some(x => x.y)) {
            theData.push({
                name: 'Accelleration',
                type: 'spline',
                color: '#ffa600',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 10,
                zIndex: 4,
                data: theChartDataAcceleration
            });
        };

        if (theChartDataBraking.some(x => x.y)) {
            theData.push({
                name: 'Braking',
                type: 'spline',
                color: '#ff6361',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 10,
                zIndex: 4,
                data: theChartDataBraking
            });
        };

        if (theChartDataCornering.some(x => x.y)) {
            theData.push({
                name: 'Cornering',
                type: 'spline',
                color: '#bc5090',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 10,
                zIndex: 4,
                data: theChartDataCornering
            });
        };

        const plotLines = [];
        const plotBands = [];

        return theData;
    }

    async downloadTWDPdf(includeCharts = true, includeData = true, includeMaps = false) {

        const doc = new jsPDF('p', 'px', 'A4', true);

        const theme = this.colorService.getThemeByUserType(this.activeReport.userType);

        const headerColor = theme.secondary;

        const currentPage = doc.getCurrentPageInfo();

        // Add charts container
        const chartContainer = document.createElement('div');
        chartContainer.setAttribute('id', 'reportChart');
        chartContainer.setAttribute('style', 'height: 400px; width: 1200px; position: absolute; visibility: hidden;');
        document.body.appendChild(chartContainer);

        const mapContainer = document.createElement('div');
        mapContainer.setAttribute('id', 'reportMap');
        mapContainer.setAttribute('style', 'height: 400px; width: 1200px; position: absolute; visibility: normal; right: -1300px');
        document.body.appendChild(mapContainer);

        const reportImage = await new Promise<JQuery>((resolve, _) => {
            const image = $('<img style="max-width: 300px; max-height: 135px;" src="data:image/png;base64,' + theme.reportBinary + '" />').appendTo('body');

            setTimeout(() => {
                resolve(image);
            }, 500);
        });

        const base64Img = { width: reportImage.innerWidth(), height: reportImage.innerHeight(), url: theme.reportBinary };
        if (base64Img?.url) {
            doc.addImage(base64Img.url, 'PNG', (currentPage.pageContext.mediaBox.topRightY - (base64Img.width * 0.5)), 25, base64Img.width * 0.5, base64Img.height * 0.5);
        }

        reportImage.remove();

        doc.setFont('IBMPlexSansArabic-Regular', 'normal');

        doc.setTextColor(40);
        doc.setFontSize(20);

        doc.textWithLink(this.activeReport.ReportName ? this.activeReport.ReportName : this.selectedReport, 15, 43, { url: window.location.href });

        autoTable(doc, {
            body: this.generateHeaderData(),
            startY: 55,
            theme: 'plain',
            tableWidth: 'wrap',
            rowPageBreak: 'avoid',
            bodyStyles:
            {
                font: 'IBMPlexSansArabic-Regular',
                textColor: 40,
                fontSize: 9,
                fontStyle: 'normal',
                cellPadding: {
                    top: 2,
                    bottom: 2,
                    right: 10,
                    left: 0,
                },
                lineColor: [248, 248, 248],
            },
            margin: { top: 10, left: 15, right: 10, bottom: 20 },
            styles: {
                fontSize: 7,
                cellPadding: 3,
                font: 'IBMPlexSansArabic-Regular',
            },
        });

        // Generate trip charts
        let chartCollection = [];
        let mapCollection = [];

        // Prefetch all charts upfront
        if (includeCharts) {
            const chartsPromises = [];
            const mapPromises = [];

            // Loop over assets
            this.reportData.data.forEach(asset => {

                // Loop over trips
                asset.Data.forEach(trip => {
                    const options = this.generateTwdChart(trip, false, false);

                    chartsPromises.push(this.generateChartBase64(options, 'CHART_RENDER_' + trip.TripId, theme, chartContainer));

                    if (includeMaps) {
                        mapPromises.push(this.generateMapBase64(trip, mapContainer));
                    }
                });
            });

            console.log('start await charts');
            chartCollection = await Promise.all(chartsPromises);
            console.log('done await charts');

            if (includeMaps) {
                console.log('start await maps display');
                mapCollection = await Promise.all(mapPromises);
                console.log('done await maps display');


                console.log('start await chart display');
                const resolut = await Promise.resolve(this.resolveMaps(mapContainer));
                console.log('done await chart display');

                // Make sure maps are loaded
                console.log('Delay 2000ms to load trip on map');
                await this.delay(2000);
                console.log('Done delay....');
            }
        }

        let g = 0;

        // Loop over assets
        for (let i = 0; i < this.reportData.data.length; i++) {
            const asset = this.reportData.data[i];

            // Loop over trips
            for (let t = 0; t < asset.Data.length; t++) {
                const trip = asset.Data[t];
                doc.addPage();

                g++;

                // Trip icon
                doc.setFont('fa-solid-900', 'normal');
                doc.setFontSize(20);
                let iconUnicode = '\uf4d7';
                doc.text(iconUnicode, 15, 36);

                // Trip start
                doc.setFont('IBMPlexSansArabic-Regular', 'normal');
                doc.setFontSize(16);

                doc.text(String(asset.Name + ' - ' + trip.Start.timestamp.format('YYYY-MM-DD HH:mm') + ' - ' + trip.DurationHumanized), 39, 34);

                // Trip header
                autoTable(doc, {
                    body: [
                        { Name: 'Started at', Value: trip.Start.timestamp.format('YYYY-MM-DD HH:mm:ss'), Data: trip.BeginAddressFull },
                        { Name: 'Ended at', Value: trip.End.timestamp.format('YYYY-MM-DD HH:mm:ss'), Data: trip.EndAddressFull },
                        { Name: 'Duration', Value: roundSeconds(trip.Duration.asSeconds()), Data: '' },
                        { Name: 'Driver', Value: trip.DriverName, Data: '' },
                    ],
                    startY: 48,
                    theme: 'plain',
                    tableWidth: 'wrap',
                    rowPageBreak: 'avoid',
                    bodyStyles:
                    {
                        font: 'IBMPlexSansArabic-Regular',
                        textColor: 40,
                        fontSize: 9,
                        fontStyle: 'normal',
                        cellPadding: {
                            top: 2,
                            bottom: 2,
                            right: 10,
                            left: 0,
                        },
                        lineColor: [248, 248, 248],
                    },
                    columnStyles: {
                        Name: {
                            cellWidth: 60
                        },
                        Value: {
                            cellWidth: 80,
                            textColor: 30
                        },
                        Data: {
                            cellWidth: 400
                        },
                    },
                    margin: { top: 10, left: 15, right: 10, bottom: 10 },
                    styles: {
                        fontSize: 7,
                        cellPadding: 3,
                        font: 'IBMPlexSansArabic-Regular',
                    },
                });

                // Trip chart
                const chartWidth = 428;
                const chartHeight = 200;

                try {
                    const chartSource = chartCollection[g - 1][0];
                    await doc.svg(chartSource, { x: 10, y: 75, width: chartWidth, height: chartHeight });
                } catch (error) {
                    console.log(error);
                }

                if (includeMaps) {
                    try {
                        console.log('Adding map ' + (g - 1));

                        // Make sure tiles are loaded
                        const map = mapCollection[g - 1];

                        // Try to use SVG of the trip
                        if (this.mapAsImage == true) {
                            // Try to export trip to image
                            var printOptions = {
                                container: map[1],
                                exclude: ['.leaflet-control-zoom'],
                                format: 'image/png',
                                fileName: 'map_' + g + '.svg',
                                // afterRender: afterRender,
                                // afterExport: afterExport
                            };

                            await map[0]['downloadExport'](printOptions).then(async function (result) {
                                await doc.addImage(result.data.toString(), 'PNG', 10, 250, 1200 / 2.8, 400 / 2.8);
                            });
                        } else {
                            // Try to use SVG of the trip
                            const source = await htmlToSvg(map[1]);
                            await doc.svg(source, { x: 10, y: 250, width: 1200 / 2.8, height: 400 / 2.8 });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }

                // Add episodes
                const reportHead = ['Time', 'Event', 'Location', 'Status'];
                const sortedEpisodes = trip.Details.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));
                const episodes = sortedEpisodes.map((episode) => {
                    return [episode.timestamp?.format('YYYY-MM-DD HH:mm:ss'), episode.name, episode.beginLocation, episode.status]
                });

                const start = [[trip.Start.timestamp?.format('YYYY-MM-DD HH:mm:ss'), this.translateService.instant('general.startTrip'), trip.BeginAddressFull, '']];
                const end = [[trip.End.timestamp?.format('YYYY-MM-DD HH:mm:ss'), this.translateService.instant('general.endTrip'), trip.EndAddressFull, '']];

                const reportData = start.concat(episodes).concat(end);

                // Trip episodes
                autoTable(doc, {
                    head: [reportHead],
                    body: reportData, // .slice(reportData.length - 20),
                    theme: 'grid',
                    //theme: 'plain',
                    tableWidth: 'auto',
                    startY: includeMaps ? 400 : 270,
                    headStyles:
                    {
                        font: 'IBMPlexSansArabic-Bold',
                        fillColor: headerColor,
                        textColor: 255,
                        fontSize: 7,
                        fontStyle: 'bold',
                    },
                    pageBreak: 'auto',
                    rowPageBreak: 'avoid',
                    margin: { top: 10, left: 10, right: 10, bottom: 10 },
                    bodyStyles: { lineColor: [248, 248, 248] },
                    willDrawCell: function (data) {
                        if (data.section === 'body') {
                            if (sortedEpisodes[data.row.index - 1] && sortedEpisodes[data.row.index - 1].classNameRow == 'idling') {
                                // Draw idling
                                doc.setFillColor(255, 248, 225);
                            }
                        }
                    },
                    styles: {
                        fontSize: 7,
                        cellPadding: 3,
                        font: 'IBMPlexSansArabic-Regular',
                    },
                });
            };
        };

        const filename = ('fm_report_' + this.selectedAccountName + '_' + this.selectedReport).replace(/\s/g, '').replace(' ', '').replace('.', '').toLowerCase();

        doc.save(filename + '.pdf', { returnPromise: true }).then(function () {
            console.log('returning true');
            return true;
        });
    }
}
