<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#System/">{{'general.system' | translate }}</a>
            </li>
            <li>
                <a href="/#/System/TriggerSchedules/Overview">{{'general.triggerSchedules' | translate }}</a>
            </li>
            <li class="active">{{'general.details' | translate }}</li>
        </ol>
    </div>
    <fh-system-navigation>
    </fh-system-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-9">

                <form #form="ngForm">
                    <div class="ibox" style="position: relative;">
                        <ngx-loading [show]="loading || saving"></ngx-loading>
                        <div class="ibox-title">
                            <span style="float: left; width: calc(100% - 30px); overflow:hidden">
                                <h5>
                                    <i class="fas fa-fw fa-calendar"></i> {{'general.triggerSchedules' | translate }}
                                </h5>
                            </span>
                            <span class="pull-right" style="width:30px">
                                <i class="fa fa-fw fa-calendar-clock"
                                    title="{{'general.timezoneSelectorToolTip' | translate}}"
                                    (click)="hideTimezoneSelector = !hideTimezoneSelector"></i>
                            </span>
                        </div>
                        <fh-trigger-schedule-details [loading]="loading" [triggerSchedule]="triggerSchedule"
                            [hideTimezoneSelector]="hideTimezoneSelector" [formMode]="formMode">
                        </fh-trigger-schedule-details>
                        <fh-save-footer [valid]="!form.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                            (setFormMode)="setFormMode($event)" [allowEdit]=true [allowDelete]=true
                            [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                    <div class="ibox">
                        <div class="ibox-content slick" style="position:relative">
                            <ngx-loading [show]="loading || saving"></ngx-loading>
                            <fh-working-hours-selector #workingHoursVar [schedule]="triggerSchedule.schedule"
                                [formMode]="formMode">
                            </fh-working-hours-selector>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-lg-3">
                <div class="ibox">
                    <div class="ibox-title">
                        <span style="float: left; width: calc(100% - 30px); overflow:hidden">
                            <h5>
                                <i class="fas fa-fw fa-circle-info"></i> {{'general.info' | translate }}
                            </h5>
                        </span>
                    </div>
                    <div class="ibox-content" style="position:relative">
                        <span>
                            {{'general.scheduleInfo' | translate }}
                        </span>

                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>