<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()">
    <fh-wizard-step title='SelectAsset' icon='fa-car-bus' [padding]=false>
        <div class="wizard-content grow-element" style="padding: 7.5px 3px;">
            <h5 style="padding: 0px 2.5px; font-weight: 500;">{{'general.cloneDeviceSettings' | translate }}</h5>
            <div class="input-group seach-background input-grow" style="display: flex; width: 100%;">
                <input [typeahead]="autoComplete$" [(ngModel)]="asyncSelected" [ngModelOptions]="{standalone: true}"
                    [typeaheadMinLength]="2" (typeaheadLoading)="changeTypeaheadLoading($event)"
                    (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadWaitMs]="1000"
                    typeaheadGroupField="groupname" [typeaheadOptionsLimit]="50" [typeaheadScrollable]="true"
                    [typeaheadOptionsInScrollableView]="10" typeaheadOptionField="name"
                    placeholder="{{'search.searchdevices' | translate }}" class="form-control typeahead">

                <div class="input-group-btn" style="width: 35px;">
                    <button class="btn btn-default" style="border: none; background: none; color:#fff;" type="submit">
                        <span *ngIf="typeaheadLoading">
                            <i class="fa fa-spinner fa-spin fa-hourglass-start"></i>
                        </span>
                        <span *ngIf="!typeaheadLoading">
                            <i class="fa fa-search"></i>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="wizard-content">
            <ng-container *ngIf="selectedDevice?.id">
                <fh-calibration-details #calibrationComponent [sensorNumber]="sensorNumber"
                    [calibrationPoints]="sensorValues" [formMode]="formModeCalibration">
                </fh-calibration-details>
            </ng-container>
            <div style="clear:both"></div>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon='fa-file-pdf'>
        <div class="wizard-content" style="padding-bottom: 10px">
            {{ 'wizard.optionalComment' | translate }}
        </div>
        <div class="wizard-content">
            <textarea class=" form-control noresize stickypad" style="height:100px; width:100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}" [(ngModel)]="logItem"></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon='fa-summary-pdf'>
        <div class="wizard-content" style="padding-bottom: 10px">
            {{ 'wizard.summary' | translate:{ count: gridSelection.length } }}
        </div>
    </fh-wizard-step>
</fh-wizard>