import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { TranslateService } from '@ngx-translate/core';
import { WorkingHoursSelectorComponent } from 'app/views/shared/usercontrols/workingHours.component';
import { Observable } from 'rxjs-compat/Observable';
import { Schedule } from 'app/models/commutingSchedule.model';
import { of } from 'rxjs';
import { WorkingHoursSettings } from 'app/models/workinghours.model';


@Injectable()
export class WorkingHoursService {

    base_url = '';

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService, private translateService: TranslateService) {
        this.base_url = this.authenticationService.getWebserviceURL('');
    }

    getWorkingHoursByAccount(accountId: string): Observable<any> {
        return this.http.get(this.base_url + 'Account/' + accountId + '/WorkingHours', { headers: this.authenticationService.headers })
            .map((data: any) => {
                const result = new WorkingHoursSettings();

                if (data == null || data.schedule == null) {
                    return of(result)
                }

                result.id = data.id;
                result.accountId = data.accountId;
                result.assetId = data.assetId;
                result.markAsPrivate = data.markAsPrivate;
                result.timezoneIana = data.timezoneIana;
                result.schedule = [];

                data.schedule.forEach(item => {
                    const schedule = this.parseResponseDetails(item);
                    result.schedule.push(schedule);
                });

                result.schedule = result.schedule.sort((a, b) => (a.day < b.day ? -1 : 1));

                return result;
            })
            .catch(this.handleError);
    }

    deleteWorkingHoursByAccount(accountId: any, workingHourId: any) {
        return this.http.delete(this.base_url + 'Account/' + accountId + '/WorkingHours?workingHourId=' + workingHourId, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    updateWorkingHoursByAccount(accountId: any, workingHours: any) {
        return this.http.put(this.base_url + 'Account/' + accountId + '/WorkingHours', workingHours, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    addWorkingHoursByAccount(accountId: any, workingHours: any) {
        return this.http.post(this.base_url + 'Account/' + accountId + '/WorkingHours', workingHours, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    getWorkingHoursByAsset(assetId: any): Observable<any> {
        return this.http.get(this.base_url + 'Asset/' + assetId + '/WorkingHours', { headers: this.authenticationService.headers })
            .map((data: any) => {
                const result = new WorkingHoursSettings();

                if (data == null || data.schedule == null) {
                    return of(null)
                }

                result.id = data.id;
                result.accountId = data.accountId;
                result.assetId = data.assetId;
                result.markAsPrivate = data.markAsPrivate;
                result.timezoneIana = data.timezoneIana;
                result.schedule = [];

                data.schedule.forEach(item => {
                    const schedule = this.parseResponseDetails(item);
                    result.schedule.push(schedule);
                });

                result.schedule = result.schedule.sort((a, b) => (a.day < b.day ? -1 : 1));

                return result;
            })
            .catch(this.handleError);
    }

    deleteWorkingHoursByAsset(assetId: any, workingHourId: any) {
        return this.http.delete(this.base_url + 'Asset/' + assetId + '/WorkingHours?workingHourId=' + workingHourId, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    updateWorkingHoursByAsset(assetId: any, workingHours: any) {
        return this.http.put(this.base_url + 'Asset/' + assetId + '/WorkingHours', workingHours, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    addWorkingHoursByAsset(assetId: any, workingHours: any) {
        return this.http.post(this.base_url + 'Asset/' + assetId + '/WorkingHours', workingHours, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        return throwError(error);
    }

    parseResponseDetails(item) {
        const schedule = new Schedule(item.day, item.commuting, item.startTime, item.endTime);
        return schedule;
    }
}
