import { mergeRanges } from 'app/common/globals';

export class GanttStatistic {
    private _idling = 0;
    private _equipmentIdling = 0;
    private _engineOn = 0;
    private _working = 0;
    private _crossover = 0;

    working = [];
    idling = [];
    equipmentIdling = [];
    engineOn = [];
    crossover = [];

    get getWorkingTotal(): number {
        if (!this._working) {
            return (this._working = countTotal(this.working));
        }

        return this._working;
    }

    get getIdlingTotal(): number {
        if (!this._idling) {
            return (this._idling = countTotal(this.idling));
        }

        return this._idling;
    }

    get getEquipmentIdlingTotal(): number {
        if (!this._equipmentIdling) {
            return (this._equipmentIdling = countTotal(this.equipmentIdling));
        }

        return this._equipmentIdling;
    }

    get getEngineOnTotal(): number {
        if (!this._engineOn) {
            return (this._engineOn = countTotal(this.engineOn));
        }

        return this._engineOn;
    }

    get getCrossoverTotal(): number {
        if (!this._crossover) {
            return (this._crossover = countTotal(this.crossover));
        }

        return this._crossover;
    }

    get getAllTotals(): number[] {
        return [this.getWorkingTotal, this.getIdlingTotal, this.getEquipmentIdlingTotal, this.getEngineOnTotal, this.getCrossoverTotal];
    }
}

function countTotal(list: any) {
    let sum = 0;
    for (const [workingStart, workingEnd] of mergeRanges(list)) {
        if (workingStart !== undefined && workingEnd !== undefined) {
            const workingDuration = workingEnd.diff(workingStart, 'seconds');
            sum += workingDuration;
        }
    }

    return sum;
}
