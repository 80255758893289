<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Dashboard">{{'general.dashboard' | translate }}</a>
            </li>
            <li class="active">{{'general.trends' | translate }}</li>
        </ol>
    </div>
    <fh-dashboard-navigation> </fh-dashboard-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class='col-md-12'>
            <fh-error [error]="error" [success]="success" [warning]="warning"></fh-error>

            <!-- Custom filters -->
            <div class="ibox" [hidden]="!showFilter">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                </div>
                <div class="ibox-content slick_toppadding" style="position: relative;">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div id="dynamicFilters">
                        <div class="col-lg-4">
                            <div class="form-group input-group" style="margin-bottom: 6px; display:block">
                                <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                    (onChanged)="resellerChanged($event)" required
                                    placeholder="{{ 'general.chooseReseller' | translate}}"
                                    [(ngModel)]="selectedResellerId">
                                </fh-reseller-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group input-group" style="margin-bottom: 6px; display:block">
                                <fh-account-input name="account" #accountVar="ngModel"
                                    (onChanged)="accountChanged($event)" required
                                    [selectedResellerId]="selectedResellerId" [showEmptyAccountsButton]="false"
                                    placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                    [(ngModel)]="selectedAccountId">
                                </fh-account-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group input-group" style="margin-bottom: 0px; display:block">
                                <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker"
                                    type="text" [bsConfig]="dpConfig" class="form-control" bsDaterangepicker
                                    [(ngModel)]="daterangepickerModel">
                                <span class="input-group-calendar-datatables">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                                <div style="clear:both"></div>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                        <div class="col-lg-2">
                            <div class="form-group input-group" style="margin-bottom: 6px; display:block">
                                <ng-select [(ngModel)]="groupBy" class="form-control" name="itemsPerPage" required
                                    [clearable]="false" style="width:100%">
                                    <ng-option [value]="1">{{ 'enums.durationGroupingType.1' | translate}} </ng-option>
                                    <ng-option [value]="2">{{ 'enums.durationGroupingType.2' | translate}} </ng-option>
                                    <ng-option [value]="3">{{ 'enums.durationGroupingType.3' | translate}} </ng-option>
                                </ng-select>
                                <div style="clear:both"></div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group input-group" style="margin-bottom: 6px;">
                                <span class="input-group-addon" title="Target idling percentage">
                                    <i class="fas fa-fw fa-snooze"></i>
                                </span>
                                <input name="checkboxIdling" id="checkboxIdling" style="width:100%"
                                    [(ngModel)]="targetIdling" placeholder="{{'general.targetIdling' | translate }}"
                                    class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group input-group" style="margin-bottom: 6px;">
                                <span class="input-group-addon" title="Target utilization percentage">
                                    <i class="fas fa-fw fa-engine"></i>
                                </span>
                                <input name="targetUtilization" id="targetUtilization" style="width:100%"
                                    [(ngModel)]="targetUtilization"
                                    placeholder="{{'general.targetUtilization' | translate }}" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group input-group" style="margin-bottom: 6px;">
                                <span class="input-group-addon" title="Target distance per month">
                                    <i class="fas fa-fw fa-route"></i>
                                </span>
                                <input name="targetDistance" id="targetDistance" style="width:100%"
                                    [(ngModel)]="targetDistance" placeholder="{{'general.targetDistance' | translate }}"
                                    class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group input-group" style="margin-bottom: 6px;" title="Filter on 0 values">
                                <span id="checkBox11" class="input-group-addon hand" title="Filter on 0 values">
                                    <i id="checkBox_11" class="auto_checkbox fa fa-fw"
                                        (click)="filterZeroValues = !filterZeroValues;"
                                        [ngClass]="filterZeroValues ? 'fa-check' : 'fa-times'"
                                        title="Filter on 0 values"
                                        [style.color]="filterZeroValues ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                                </span>
                                <input name="checkboxName_11" id="checkboxName_11"
                                    style="width:100%; cursor: pointer!important;"
                                    placeholder="{{'general.filterZeroValues' | translate }}" disabled="disabled"
                                    class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="form-group input-group" style="margin-bottom: 6px;" title="Filter on 0 values">
                                <span id="checkBox11" class="input-group-addon hand" title="Filter on 0 values">
                                    <i id="checkBox_11" class="auto_checkbox fa fa-fw"
                                        (click)="splitVehicleTypes = !splitVehicleTypes;"
                                        [ngClass]="splitVehicleTypes ? 'fa-check' : 'fa-times'"
                                        title="Filter on 0 values"
                                        [style.color]="splitVehicleTypes ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                                </span>
                                <input name="checkboxName_11" id="checkboxName_11" style="width:100%"
                                    placeholder="{{'general.splitVehicleTypes' | translate }}" disabled="disabled"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                    <div style="clear:both"></div>
                </div>
                <div class="ibox-content" style="position: relative; display:none">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div id="dynamicFilters"></div>
                    <div style="clear:both"></div>
                </div>
                <div class="ibox-footer">
                    <button type="button" name="search" class="btn btn-primary" (click)="dateChanged('hidden')"
                        [disabled]="selectedAccountId == null">{{ 'general.fetchData' | translate}}</button>
                </div>
            </div>
            <!-- End default dynamic filters -->
        </div>
    </div>

    <div class="row" *ngIf="trends.length > 0">
        <div class="col-md-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-engine"></i> {{'general.utilization' | translate }}
                    </h5>
                    <span class="pull-right">
                        <ibox-chart-export [chartObject]="chartUtilization">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                                [options]="chartUtilization"></highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-car"></i> {{'general.activeAssets' | translate }}
                    </h5>
                    <span class="pull-right">
                        <ibox-chart-export [chartObject]="chartAssets">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                                [options]="chartAssets">
                            </highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-clock"></i> {{'general.avgWorkingHours' | translate }}
                    </h5>
                    <span class="pull-right">
                        <ibox-chart-export [chartObject]="chartWorkingHours">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                                [options]="chartWorkingHours"></highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-route"></i> {{'general.distance' | translate }}
                    </h5>
                    <span class="pull-right">
                        <ibox-chart-export [chartObject]="chartDistance">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>

                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                                [options]="chartDistance">
                            </highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-snooze"></i> {{'general.idling' | translate }}
                    </h5>
                    <span class="pull-right">
                        <ibox-chart-export [chartObject]="chartIdling">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                                [options]="chartIdling">
                            </highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-clock"></i> {{'general.avgPureDriving' | translate }}
                    </h5>
                    <span class="pull-right">
                        <ibox-chart-export [chartObject]="chartPureDriving">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                                [options]="chartPureDriving"></highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="trends.length > 0">
        <div class="col-md-4">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-snooze"></i> {{'general.thresholdIdling' | translate }}
                    </h5>
                    <span class="pull-right">
                        <ibox-chart-export [chartObject]="chartThresholdIdling">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                                [options]="chartThresholdIdling"></highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-engine"></i> {{'general.thresholdUtilization' | translate }}
                    </h5>
                    <span class="pull-right">
                        <ibox-chart-export [chartObject]="chartThresholdUtilization">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                                [options]="chartThresholdUtilization"></highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-route"></i> {{'general.thresholdDistance' | translate }}
                    </h5>
                    <span class="pull-right">
                        <ibox-chart-export [chartObject]="chartThresholdDistance">
                        </ibox-chart-export>
                    </span>
                </div>
                <div class="ibox-content slicker" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                        <tbody>
                            <highcharts-chart [runOutsideAngular]="true"
                                style="display: block; width: 100%; height:150px" [Highcharts]="Highcharts"
                                [options]="chartThresholdDistance"></highcharts-chart>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>