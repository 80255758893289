import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';

import { GridBase360Directive } from 'app/common/360Grid.base';
import { getDefaultDpConfig, getGridButtons, getGridLanguages } from 'app/common/gridhelper';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

import { CarSharingService } from 'app/services/carsharing/carsharing.service';
import { StorageHelper } from 'app/common/storagehelper';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';

window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-garages',
    templateUrl: 'garages.template.html',
})
export class CarSharingGaragesViewComponent extends GridBase360Directive implements OnDestroy {
    loading = false;

    permissions = {};

    timezoneIana: string;
    token: string;

    languageLoaded = false;
    episodesLoaded = false;

    randomKey: number;

    permissionName = 'Modules_HasPrivateFleet';
    constructorName = 'CarSharingGarageViewComponent';

    error;
    success;

    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];

    constructor(private carsharingService: CarSharingService, private translateService: TranslateService, private authenticationService: AuthenticationService, private deviceTypeService: DeviceTypeService, private cd: ChangeDetectorRef, protected storageHelper: StorageHelper) {
        super(storageHelper);

        this.permissions = authenticationService.permissions;

        this.timezoneIana = authenticationService.getTimeZoneIana();
        this.token = authenticationService.getAuthToken();

        this.daterangepickerModel = [
            Moment().subtract(7, 'days').startOf('day').toDate(),
            Moment().add(0, 'days').endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

        this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

        // Get all the date for dropdown boxes
        this.translateService.get('general.date').subscribe(
            _ => {
                this.languageLoaded = true;
                this.loading = false;
                this.cd.markForCheck();

                this.initGrid();
            },
            err => {
                this.error = err;
                this.languageLoaded = true;
                this.loading = false;
                this.cd.markForCheck();
            });
    }

    initGrid(): void {
        const that = this;

        this.columns = [
            {
                name: 'id',
                data: 'id',
                className: 'noVis',
                orderable: false,
                title: '<div class="hideDropdown"></div>',
                width: '20',
                render: function (data, type, row) {
                    return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/CarSharing/GarageDetails/Index/' + data + '\'><span class="hidden-sm hidden-xs" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
                }
            },
            {
                name: 'name',
                data: 'name',
                title: this.translateService.instant('general.name'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            {
                name: 'assetCount',
                data: 'assetCount',
                type: 'num',
                title: this.translateService.instant('general.assetCount'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            {
                name: 'driverCount',
                data: 'driverCount',
                type: 'num',
                title: this.translateService.instant('general.driverCount'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            {
                name: 'timestamp',
                data: 'timestamp',
                type: 'date',
                title: this.translateService.instant('general.timestamp'),
                render: function (data, type, row) {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
                },
            },
        ];

        this.dtOptions = {
            buttons: getGridButtons(this.commonExportOptions, 'carsharing_overview', this.translateService.instant('general.carsharing')),
            pagingType: 'simple_numbers',
            serverSide: true,
            processing: true,
            searchDelay: 500,
            deferRender: true,
            scrollX: true,
            deferLoading: 0,
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                that.saveState(that.constructorName, data);
            },
            stateLoadCallback: function (_, callback) {
                (async () => { try {
                    const columnSettings = await that.loadState(that.constructorName);
                    that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
                    return columnSettings;
                } catch (e) {
                    that.error = {};
                    that.error.error = e;
                    that.error.statusText = 'Error fetching column settings';

                    return null;
                }})().then(result => {
                    callback(result);
                });
            },
            order: [[1, 'asc']],
            ajax: {
                beforeSend: () => {
                    that.drawFilterRow();
                    $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
                },
                url: that.carsharingService.getGaragesPagingUrl(),
                data: (d) => {
                    return d;
                },
                dataSrc: function (json) {
                    // // if selection and returning keys
                    // if (that.processing.tableIdsSelection && that.processing.tableIdsSelection.length === 0) {
                    // that.processing.tableIdsSelection = json.keys;
                    // }
                    return json.data;
                },
                type: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + that.token
                }
            },
            initComplete: function (settings, json) {
                that.loading = false;
                that.episodesLoaded = false;

                console.log('init complete');
                that.checkFilters();
                that.drawFilterRow();
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
                exclude: ['id', 'id_export', 'plusButton']
            },
            columns: this.columns,
            pageLength: 25,
            lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
        };
    }
}
