import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AssetType } from 'app/models/assetType.model';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';
import { Asset } from '../../models/asset.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';


@Injectable()
export class AssetTypeService {

    token: string;
    timestamp;
    url = '';
    Assets: Asset[] = [];

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getWebserviceURL('assetType');
    }

    getPagingUrl() {
        return this.url + 'Paging';
    }

    getAssetTypes(accountId?: number): Observable<AssetType[]> {
        return this.http.get(this.url + (accountId ? `?accountId=${accountId}` : ''), { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getAssetTypeById(id: string): Observable<AssetType> {
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseReponseDetails(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    resetAssetTypesCache(): Observable<boolean> {
        return this.http.get(this.url + 'ResetCache', { headers: this.authenticationService.headers })
            .map((data: any) => {
                return data;
            })
            .catch(this.handleError);
    }

    saveAssetType(assetType: AssetType): Observable<any> {
        console.log('save assetType');
        return this.http.post(this.url, assetType, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }


    updateAssetType(assetType: AssetType): Observable<any> {
        console.log('save assetType');
        return this.http.put(this.url + assetType.id, assetType, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    deleteAssetType(assetType: AssetType): Observable<any> {
        console.log('save assetType');
        return this.http.delete(this.url + assetType.id, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    parseResponse(json: any): AssetType[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' AssetTypes.');

        const ident = 1;
        const assets: AssetType[] = [];

        json.forEach(item => {

            const asset = this.parseReponseDetails(item);
            assets.push(asset);
        });

        this.timestamp = new Date().getTime();
        return assets;
    }

    parseReponseDetails(item) {
        //   this.loggingService.log(this.constructor().name + " - New", item);
        const assetType = new AssetType();
        assetType.id = item.id;
        assetType.name = item.name;
        assetType.accountId = item.accountId;
        assetType.description = item.description;
        assetType.companyName = item.companyName;
        assetType.resellerDescription = item.resellerDescription;
        assetType.resellerId = item.resellerId;
        assetType.assetCount = item.assetCount;

        return assetType;
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
