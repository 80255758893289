import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { Maintenance, MaintenanceOccurrence } from 'app/models/maintenance.model';
import { EntityType } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { WaslService } from 'app/services/wasl/wasl.service';
// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { MaintenanceService } from 'app/services/maintenance/maintenance.service';
import { UserService } from 'app/services/users/user.service';
import { AppUser } from 'app/models/user.model';
import { FhChartService } from 'app/services/charts/charts.service';

window['moment'] = Moment;
mTZ()


@Component({
    providers: [FhChartService],
    selector: 'fh-fh-maintenance-details',
    templateUrl: 'maintenanceDetails.template.html'
})
export class MaintenanceDetailsViewComponent implements OnInit {
    sub: any;
    maintenanceOccurrence = new MaintenanceOccurrence;
    device: any;
    tagType = EntityType.Maintenance.valueOf();
    formMode = FormMode.read;
    formModeWasl = FormMode.read;

    loading = false;
    saving = false;
    loadingMaintenanceWasl = false;

    filter;

    error: any;
    success: any;

    permissions: {};

    permissionName = 'Maintenances_View';

    waslSuccess: { statusText: string; success: any; };
    waslError: any;

    activeDevices = 0;
    activeDrivers = 0;
    activeGeofences = 0;

    geofences = [];
    loadingGeofences = false;
    loadingUsers = false;
    loadingKpi = false;
    users: AppUser[];

    loadingDeviceCount = false;
    chartDeviceCount: any;

    // Pagination
    totalItems = 0;
    currentPage = 1;
    smallnumPages = 0;
    deviceCountData = [];
    distanceChartData = [];
    loadingCount = false;

    constructor(private router: Router, private waslService: WaslService, private cd: ChangeDetectorRef, private authentication: AuthenticationService, private maintenanceService: MaintenanceService, private route: ActivatedRoute, private userService: UserService) {
        this.permissions = this.authentication.permissions;
    }

    ngOnInit() {

        this.loading = true;
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.maintenanceService.getMaintenanceById(id).subscribe(maintenance => {
                this.maintenanceOccurrence = maintenance;
                this.loading = false;

            }, error => {
                this.error = error;
                this.error.statusText = 'Error fetching maintenance';

                this.cd.markForCheck();

                setTimeout(() => {
                    this.router.navigate(['/Maintenances/Overview']);
                }, 3000);
            });
        });
    }


    // Form

    onDelete() {
        this.loading = true;

        this.maintenanceService.deleteMaintenance(this.maintenanceOccurrence).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Maintenance is successfully deleted.'
            };

            setTimeout(() => {
                this.router.navigate(['/Maintenances/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    onSave() {
        this.saving = true;

        this.maintenanceService.updateMaintenance(this.maintenanceOccurrence).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Maintenance is successfully updated.'
            };

            this.saving = false;
            this.cd.markForCheck();

            this.setFormMode(FormMode.read);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getMaintenanceInfo();
        }
    }

    setFormModeWasl(mode) {
        this.formModeWasl = mode;

        if (this.formModeWasl === FormMode.read) {
            this.loadingMaintenanceWasl = true;
            this.getMaintenanceInfo();
        }
    }

    getMaintenanceInfo() {
        if (this.loading !== true && this.loadingMaintenanceWasl !== true) {
            this.loading = true;
        }

        this.maintenanceService.getMaintenanceById(this.maintenanceOccurrence.id).subscribe(maintenance => {
            this.maintenanceOccurrence = maintenance;
            this.loading = false;
            this.loadingMaintenanceWasl = false;
        });
    }
}
