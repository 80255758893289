import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getGridButtons, getGridLanguages, createSearch, setTableStars, setSelection, setSelectionRows, getDefaultDpConfig, createdCellCheckbox } from 'app/common/gridhelper';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { ActivatedRoute } from '@angular/router';
import { BatchItemStatus } from 'app/common/enums';

import { ReportService } from 'app/services/reports/report.service';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

import { StorageHelper } from 'app/common/storagehelper';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { roundAsString, roundAsNumber } from 'app/common/globals';
window['moment'] = Moment;
mTZ();
@Component({
  selector: 'fh-reporting-subscriptions',
  templateUrl: 'subscriptions.template.html'
})
export class ReportingSubscriptionsViewComponent extends GridBase360Directive implements OnDestroy {
  token: string;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  availableReports = {};

  excludingColumns = ['timestamp'];

  success;
  error;

  randomKey;

  constructorName = 'ReportingViewComponent';
  permissionName = 'FleetManagement_Reporting';
  timezoneIana: string;

  // Daterange
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
  daterangepickerModel: Date[];

  constructor(private route: ActivatedRoute, private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private reportService: ReportService, protected storageHelper: StorageHelper) {
    super(storageHelper);

    const that = this;
    this.loading = true;

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().subtract(1, 'months').startOf('day').toDate(),
      Moment().add(0, 'days').endOf('day').toDate()
    ];

    this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

    // Get all the date for dropdown boxes
    forkJoin([
      this.reportService.getReportColumnsByReportType(),
      this.translateService.get('general.date')
    ]).subscribe(
      ([columns, _]) => {

        this.languageLoaded = true;
        this.loading = false;

        for (const item in columns) {
          if (item === null) {
            continue;
          }

          this.availableReports[columns[item].reportType] = true;
        }

        delete this.availableReports['0'];

        this.cd.markForCheck();

        this.initGrid();
      },
      err => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  dateChanged(event) {
    const that = this;
    if (event !== null) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.url(that.reportService.getSubscriptionPagingUrl(moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.randomKey)).load();
      });
    }
  }

  actualRound(value, decimals) {
    return roundAsNumber(value, decimals);
  }

  initGrid(): void {
    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    // Id = (Guid)row.GeneratedReportId,
    // AccountId = (int)row.AccountId,
    // SelectedReportTypeId = (int)row.SelectedReportTypeId,
    // FkUserId = (int)row.FkUserId,
    // Timestamp = (DateTime)row.Timestamp,
    // Filters = (string)row.Filters,
    // ReportData = (string)row.ReportData,

    const reportSubscriptionTypeOptions = [];
    for (let i = 1; i < 3; i++) {
      reportSubscriptionTypeOptions.push({ id: i, value: this.translateService.instant('enums.reportSubscriptionType.' + i) });
    }

    const reportTypeOptions = [];
    for (const i of Object.keys(this.availableReports)) {
      reportTypeOptions.push({ id: i, value: this.translateService.instant('enums.reportType.' + i) });
    }

    this.columns = [
      {
        name: 'id',
        data: 'id',
        className: 'noVis',
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: '20',
        render: function (data, type, row) {
          return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/ReportSubscriptionDetails/Index/' + data + '\'><span class="hidden-sm hidden-xs" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
        }
      },
      {
        name: 'createdDate',
        data: 'createdDate',
        type: 'date',
        title: this.translateService.instant('general.createdDate'),
        render: function (data, type, row) {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
        },
      },
      {
        name: 'selectedReportType',
        data: 'selectedReportType',
        title: this.translateService.instant('general.selectedReportType'),
        type: 'select',
        options: reportTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
        render: function (data, type, row) {
          return that.translateService.instant(('enums.reportType.' + data));
        }
      },
      {
        name: 'name',
        data: 'name',
        defaultContent: '',
        title: this.translateService.instant('general.name'),
        render: function (data, type, row) {
          return data ? data : '-';
        }
      },
      {
        name: 'executionCount',
        data: 'executionCount',
        defaultContent: '',
        type: 'num',
        title: this.translateService.instant('general.executionCount'),
        render: function (data, type, row) {
          return data ? roundAsString(data, 0) : '-';
        }
      },
      {
        name: 'maxExecutedTimestamp',
        data: 'maxExecutedTimestamp',
        defaultContent: '',
        type: 'date',
        title: this.translateService.instant('general.maxExecutedTimestamp'),
        render: function (data, type, row) {
          if (data == null) {
            return 'N/A';
          } else {
            const date = Moment.utc(data)['tz'](that.timezoneIana);
            return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
          }
        }
      },
      {
        name: 'reportSubscriptionType',
        data: 'reportSubscriptionType',
        title: this.translateService.instant('general.reportSubscriptionType'),
        type: 'select',
        options: reportSubscriptionTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
        render: function (data, type, row) {
          return that.translateService.instant(('enums.reportSubscriptionType.' + data));
        }
      },
      {
        name: 'reportingInterval',
        data: 'reportingInterval',
        defaultContent: '',
        visible: false,
        title: this.translateService.instant('general.reportingInterval'),
        render: function (data, type, row) {
          return data ? data : '-';
        }
      },
      {
        name: 'nextOccurrence',
        data: 'nextOccurrence',
        defaultContent: '',
        type: 'date',
        title: this.translateService.instant('general.nextOccurrence'),
        render: function (data, type, row) {
          if (data == null) {
            return 'N/A';
          } else {
            const date = Moment.utc(data)['tz'](that.timezoneIana);
            return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
          }
        }
      },
      {
        name: 'userName',
        data: 'userName',
        defaultContent: '-',
        title: this.translateService.instant('general.userName'),
      },
      {
        name: 'companyName',
        data: 'companyName',
        defaultContent: '-',
        title: this.translateService.instant('general.companyName')
      },
      {
        name: 'accountId',
        data: 'accountId',
        defaultContent: '-',
        title: this.translateService.instant('general.accountId'),
        visible: false
      },
      {
        name: 'resellerDescription',
        data: 'resellerDescription',
        defaultContent: '-',
        title: this.translateService.instant('general.resellerDescription'),
        visible: false
      },
      {
        name: 'notifyOnCompletion',
        data: 'notifyOnCompletion',
        title: this.translateService.instant('general.notifyOnCompletion'),
        type: 'checkBox',
        defaultContent: '',
        visible: true,
        createdCell: createdCellCheckbox,
        width: '40',
        render: function (data, type, row) {
          console.log(row.notificationConfiguration);
          console.log(row.notificationConfiguration?.userContactGroupRecipients?.length);
          if (row.notificationConfiguration?.userContactGroupRecipients?.length > 0) {
            return true;
          }
          if (row.notificationConfiguration?.userContactRecipients.length > 0) {
            return true;
          }
          return false;
        }
      }];

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'reporting_overview', this.translateService.instant('general.job')),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => { try {
          const columnSettings = await that.loadState(that.constructorName);
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          that.error = {};
          that.error.error = e;
          that.error.statusText = 'Error fetching column settings';

          return null;
        }})().then(result => {
          callback(result);
        });
      },
      order: [[1, 'desc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.reportService.getSubscriptionPagingUrl(moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'), this.randomKey),
        data: (d) => {
          return d;
        },
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        that.checkFilters();
        that.setEvents();
        that.drawFilterRow();
        that.loading = false;
        that.cd.markForCheck();
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'id_export']
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }
}
