import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'fh-copyinput',
    templateUrl: 'copyInputBox.template.html',
})
export class CopyInputBoxComponent {

    @Input() hide = false;
    @Input() textMode = false;

    @ViewChild('inputWrapper') inputWrapper;

    copyCompleted = false;
    copyTimeout;
    languageLoaded = false;

    constructor(private translateService: TranslateService) {
        this.translateService.get('general.date').subscribe(
            data => {
                this.languageLoaded = true;
            }
        );
    }

    textToClipboard(text) {
        const value = text ? text : $(this.inputWrapper.nativeElement.getElementsByTagName('input'))?.val();

        if (!value) {
            return;
        }

        clearTimeout(this.copyTimeout);

        const input = document.createElement('input');
        input.value = String(value)?.trim();
        input.setAttribute('readonly', '');
        input.style.position = 'absolute';
        input.style.left = '-9999px';
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(input);
        this.copyCompleted = true;
        this.copyTimeout = setTimeout(() => { this.copyCompleted = false }, 2000);
    }
}
