import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getGridButtons, getGridLanguages, setSelection, setTableStars, setSelectionRows, createdCellCheckbox } from 'app/common/gridhelper';
import { DeviceType } from 'app/models/devicetype.model';

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslatorService } from '../../services/common/translator.service';
import { DeviceService } from '../../services/device/device.service';
import { EntityType, BatchStatus } from 'app/common/enums';
import { getIconPath } from 'app/common/globals';
import { GridBase360Directive } from 'app/common/360Grid.base';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';

import { StorageHelper } from 'app/common/storagehelper';
import { slideInOut } from 'app/common/animations';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-devices',
  templateUrl: 'devices.template.html',
  styleUrls: ['devices.template.css'], 
  animations: [
    slideInOut
  ]
})
export class DevicesViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;
  countrySelect = false;

  dropdown_IssueType = '';
  dropdown_DeviceType = '';

  excludingColumns = ['iconId', 'timeStamp', 'assetDateStart', 'assetDateEnd', 'lastCommunication'];
  loading = false;

  showOnlyFlagged = false;

  showActiveOnly = true;
  hideArchived = true;

  deviceTypes: DeviceType[];

  dropdown_CustomerName = '';
  languageLoaded: boolean;
  timezoneIana: string;
  dropdown_CustomerNameSettings: any;
  selectedCustomers = [];

  dropdownSettingsDeviceTypes: any;
  selectedDeviceTypes: any[] = [];

  customersMultiselect: any[] = [];
  deviceTypesMultiselect: any[] = [];

  permissionName = 'Devices_View';
  constructorName = 'DevicesViewComponent';

  error: any;
  success: any;

  enableSelecting = true;

  gridSelection = [];
  showSelection = false;

  entityTypes = EntityType.Device;
  isAllSelected = false;
  permissions: {};

  showExtendedDeviceFilter = false;

  selectedResellerId: any;
  selectedAccountId: any;
  selectedAssetGroups: any[] = [];

  imeiText;
  imeiChanged: Subject<string> = new Subject<string>();

  constructor(private localeService: BsLocaleService, private route: ActivatedRoute, private translatorService: TranslatorService, private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private deviceService: DeviceService, private deviceTypeService: DeviceTypeService, protected storageHelper: StorageHelper) {
    super(storageHelper);

    const that = this;
    this.loading = true;

    this.permissions = this.authenticationService.permissions;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana()

    this.showExtendedDeviceFilter = localStorage.getItem('ShowExtendedDeviceFilter_' + this.constructorName) === 'true';

    this.imeiChanged
      .debounceTime(2000) // wait 2 sec after the last event before emitting last event
      .distinctUntilChanged() // only emit if value is different from previous value
      .subscribe(model => {
        this.imeiText = model;

        // Call your function which calls API or do anything you would like do after a lag of 2 sec
        this.fireFilter(1);
      });

    // Get all the date for dropdown boxes
    Observable.forkJoin(
      this.translateService.get('general.date'),
      this.deviceTypeService.getDeviceTypes()
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();

        this.deviceTypes = data[1].filter(x => x.deviceCount > 0);
        this.deviceTypes = this.deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1));

        this.initGrid();
      },
      err => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  onImeiChanged(text) {
    this.imeiChanged.next(text);
  }

  clearAllFilters() {
    console.log('Resetting all');
    $('.auto_search:input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
    $('.auto_checkbox').removeClass('fa-check').removeClass('fa-times').removeClass('fa-th').addClass('fa-th').css('color', '');

    this.imeiText = '';
    this.selectedResellerId = null;
    this.selectedAccountId = null;
    this.selectedAssetGroups = [];

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.search('').columns().search('').draw();
    });
  }

  flipExtendedDeviceFilter() {
    this.showExtendedDeviceFilter = !this.showExtendedDeviceFilter;
    localStorage.setItem('ShowExtendedDeviceFilter_' + this.constructorName, this.showExtendedDeviceFilter.toString());

    if (this.showExtendedDeviceFilter === false) {
      this.imeiText = '';
      this.selectedResellerId = null;
      this.selectedAccountId = null;
      this.selectedAssetGroups = [];

      this.fireFilter(1);
    }
  }

  resellerChanged(event) {
    this.selectedAccountId = null;
    this.selectedAssetGroups = [];
    this.fireFilter(1);
    this.cd.markForCheck();
  }

  accountChanged(event) {
    this.selectedAssetGroups = [];
    this.fireFilter(1);
    this.cd.markForCheck();
  }

  assetGroupsChanged() {
    this.fireFilter(1);
    this.cd.markForCheck();
  }

  applyFilters() {
    this.fireFilter(1);
  }

  ngOnInit(): void {

  }

  // Check custom filters from grid save
  checkFilters() {

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.showActiveOnly = dtInstance.column('isActive:name').search() !== '@ignore';
      this.hideArchived = dtInstance.column('isArchived:name').search() !== '@ignore';
    });

    this.fireFilter(1);
  }

  // Check route to apply filters
  checkRoute() {
    this.route.queryParams.subscribe(params => {
      const companyName = params['companyName'];
      const assetType = params['assetType'];
      const resellerDescription = params['resellerDescription'];
      let requireDraw = false;

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        if (!assetType && companyName) {
          dtInstance.column('resellerDescription:name').search('');
          dtInstance.column('companyName:name').search(companyName);
          dtInstance.column('assetName:name').search('');
          dtInstance.column('assetTypeName:name').search('');
          requireDraw = true;
        }
        if (assetType) {
          dtInstance.column('resellerDescription:name').search('');
          dtInstance.column('companyName:name').search(companyName);
          dtInstance.column('assetName:name').search('');
          dtInstance.column('assetTypeName:name').search(assetType);
          dtInstance.column('isArchived:name').search('@ignore');
          requireDraw = true;
        }
        if (resellerDescription) {
          dtInstance.column('resellerDescription:name').search(resellerDescription);
          dtInstance.column('companyName:name').search('');
          dtInstance.column('assetName:name').search('');
          dtInstance.column('assetTypeName:name').search('');
          requireDraw = true;
        }
        // Fire the request
        if (requireDraw) {
          dtInstance.draw();
        }
      });
    });
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log('Fire update');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

        if (this.showActiveOnly === true) {
          dtInstance.column('isActive:name').search('true');
        } else {
          dtInstance.column('isActive:name').search('@ignore');
        }

        if (this.hideArchived === true) {
          dtInstance.column('isArchived:name').search('false');
        } else {
          dtInstance.column('isArchived:name').search('@ignore');
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  initGrid(): void {

    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    const deviceTypeOptions = [];
    this.deviceTypes.forEach(function (item, index) {
      if (item.modelName !== '') {
        deviceTypeOptions.push({ id: item.modelName, value: item.modelName + (that.permissions['Platform_IsReseller'] ? ' (' + item.deviceCountActive + ' / ' + item.deviceCount + ')' : ' (' + item.deviceCountActive + ')') });
      }
    });

    const commonExportOptions = {
      // orthogonal: 'export',
      // format: {
      //   body: function (data, row, column, node) {
      //     return data;
      //   }
      // },
      modifier: {
        page: 'all',
        search: 'none'
      },
      columns: ['id_export:name', 'device_id:name', 'asset_id:name', 'accountId:name', ':visible[tabindex]']
    };

    const excludedColumns = ['id', 'id_export', 'isSelected'];
    let selectorVisible = true;

    if (!this.permissions['FleetManagement_Actions']) {
      selectorVisible = false;
    }

    this.columns = [{
      name: 'isSelected',
      data: 'id',
      className: 'noVis',
      title: '<div class="hideDropdown" id="selectorHeader"></div>',
      orderable: false,
      visible: selectorVisible,
      width: '20',
      render: function (data, type, row) {
        if (!that.processing.tableIdsSelection) {
          that.processing.tableIdsSelection = [];
        }
        if (that.processing.tableIdsSelection.indexOf(row.id.toString()) === -1) {
          that.processing.tableIdsSelection.push(row.id.toString());
        }

        if (!that.processing.tableIds) {
          that.processing.tableIds = [];
        }
        if (that.processing.tableIds.indexOf(row.id.toString()) === -1) {
          that.processing.tableIds.push(row.id.toString());
        }

        const isSelected = that.processing.gridSelection.indexOf(data.toString()) !== -1;
        return '<i id="selection_' + row.id.toString() + '" class="far fa-fw fa-lg ' + (isSelected ? 'fa-check-square ' : 'fa-square ') + '" style="cursor: pointer;"></i>';
      }
    }, {
      name: 'id',
      data: 'id',
      className: 'noVis',
      orderable: false,
      title: '<div class="hideDropdown"></div>',
      width: '20',
      render: function (data, type, row) {
        return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/DeviceDetails/Index/' + data + '\'><span class="hidden-sm hidden-xs" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
      }
    }, {
      name: 'id_export',
      data: 'id',
      className: 'noVis',
      title: this.translateService.instant('general.id'),
      visible: false,
    },
    {
      name: 'iconId',
      data: 'asset.iconId',
      title: this.translateService.instant('general.icon'),
      render: function (data, type, row) {
        if (data == null) {
          return '-';
        } else {
          return data ? '<img style="width:32px; height:32px; margin-top: -20px; margin-bottom: -10px;" src="' + (data > 0 ? getIconPath(data)[1] : '/assets/images/icons/vista/Trucks/32x32/TankerTruck_Black.png') + '">' : '';
        }
      },
      visible: true,
      width: '30',
      orderable: false,
    },
    {
      name: 'device_id',
      data: 'id',
      title: this.translateService.instant('general.deviceId'),
      visible: false,
    },
    {
      name: 'asset_id',
      data: 'asset.id',
      defaultContent: '-',
      title: this.translateService.instant('general.assetId'),
      visible: false,
    },
    {
      name: 'assetName',
      data: 'asset.name',
      defaultContent: '-',
      title: this.translateService.instant('general.name'),
    },
    {
      name: 'erpCode',
      data: 'erpCode',
      defaultContent: '-',
      visible: false,
      title: this.translateService.instant('general.assetErpCode'),
    },
    {
      name: 'accountErpCode',
      data: 'accountErpCode',
      defaultContent: '-',
      visible: false,
      title: this.translateService.instant('general.accountErpCode'),
    },
    {
      name: 'unitId',
      data: 'unitId',
      title: this.translateService.instant('general.unitId'),
      visible: true
    },
    {
      name: 'deviceTypeName',
      data: 'deviceTypeName',
      defaultContent: '-',
      iconName: 'fas fa-fw fa-router',
      type: 'select',
      options: deviceTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
      title: this.translateService.instant('general.devicetype')
    },
    {
      name: 'manufacturer',
      data: 'manufacturer',
      defaultContent: '-',
      title: this.translateService.instant('general.manufacturer'),
      visible: false,
      render: function (data, type, row) {
        return data ? data : '-';
      }
    },
    {
      name: 'assetCode',
      data: 'asset.code',
      defaultContent: '-',
      title: this.translateService.instant('general.assetCode'),
      visible: false,
      render: function (data, type, row) {
        return data ? data : '-';
      }
    },
    {
      name: 'assetPlateNumber',
      data: 'asset.plateNumber',
      defaultContent: '-',
      title: this.translateService.instant('general.plateNumber'),
      visible: false,
      render: function (data, type, row) {
        return data ? data : '-';
      }
    },
    {
      name: 'assetBrand',
      data: 'asset.brand',
      defaultContent: '-',
      title: this.translateService.instant('general.assetBrand'),
      render: function (data, type, row) {
        return data ? data : '-';
      }
    },
    {
      name: 'assetModel',
      data: 'asset.model',
      defaultContent: '-',
      title: this.translateService.instant('general.assetModel'),
      render: function (data, type, row) {
        return data ? data : '-';
      }
    },
    {
      name: 'assetColor',
      data: 'asset.color',
      defaultContent: '-',
      title: this.translateService.instant('general.color'),
      visible: false,
    },
    {
      name: 'assetYear',
      data: 'asset.year',
      defaultContent: '-',
      title: this.translateService.instant('general.year'),
      visible: false,
    },
    {
      name: 'assetVIN',
      data: 'asset.vin',
      defaultContent: '-',
      title: this.translateService.instant('general.chassisNumber'),
      visible: false,
    },
    {
      name: 'assetComment',
      data: 'asset.comment',
      defaultContent: '-',
      title: this.translateService.instant('general.comment'),
      visible: false,
    },
    {
      name: 'companyName',
      data: 'companyName',
      defaultContent: '-',
      title: this.translateService.instant('general.companyName')
    }, {
      name: 'accountId',
      data: 'accountId',
      defaultContent: '-',
      title: this.translateService.instant('general.accountId'),
      visible: false
    },
    {
      name: 'timeStamp',
      data: 'timestamp',
      title: this.translateService.instant('general.timeStamp'),
      render: function (data, type, row) {
        const date = Moment.utc(data)['tz'](that.timezoneIana);
        return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
      },
      visible: false
    },
    {
      name: 'simIdentifier',
      data: 'simIdentifier',
      defaultContent: '-',
      title: this.translateService.instant('general.simIdentifier'),
      visible: false
    },
    {
      name: 'simActivationStatus',
      data: 'simActivationStatus',
      defaultContent: '-',
      title: this.translateService.instant('general.simActivationStatus'),
      visible: false,
      render: function (data, type, row) {
        return that.translateService.instant(('enums.simActivationStatus.' + data));
      }
    },
    {
      name: 'msisdn',
      data: 'msisdn',
      defaultContent: '-',
      title: this.translateService.instant('general.msisdn'),
      visible: false
    },
    {
      name: 'imsi',
      data: 'imsi',
      defaultContent: '-',
      title: this.translateService.instant('general.imsi'),
      visible: false
    }, 
    {
      name: 'project',
      data: 'asset.activeProject.name',
      defaultContent: '-',
      title: this.translateService.instant('general.project'),
      visible: false
    },
    {
      name: 'resellerDescription',
      data: 'resellerDescription',
      defaultContent: '-',
      title: this.translateService.instant('general.resellerDescription'),
      visible: false
    },
    {
      name: 'assetCount',
      data: 'assetCount',
      type: 'num',
      defaultContent: '-',
      title: this.translateService.instant('general.assetCount'),
      visible: false
    },
    {
      name: 'assetDateStart',
      data: 'asset.assetDateStart',
      defaultContent: '',
      title: this.translateService.instant('general.assetDateStart'),
      render: function (data, type, row) {
        if (data == null) {
          return '-';
        } else {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
        }
      },
      visible: false
    },
    {
      name: 'assetDateEnd',
      data: 'asset.assetDateEnd',
      title: this.translateService.instant('general.assetDateEnd'),
      render: function (data, type, row) {
        if (data == null) {
          return '-';
        } else {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
        }
      },
      visible: false
    },
    {
      name: 'assetTypeName',
      data: 'asset.assetTypeName',
      defaultContent: '-',
      title: this.translateService.instant('general.assetTypeName'),
      visible: false
    },
    {
      name: 'isActive',
      data: 'isActive',
      type: 'checkBox',
      title: this.translateService.instant('general.isActivated'),
      visible: true,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'isArchived',
      data: 'isArchived',
      type: 'checkBox',
      title: this.translateService.instant('general.isArchived'),
      visible: true,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'deviceAuxiliary',
      data: 'deviceAuxiliary',
      defaultContent: '-',
      iconName: 'fas fa-fw fa-toggle-off',
      title: this.translateService.instant('general.deviceAuxiliary'),
      visible: false,
      width: '40'
    }];

    this.dtOptions = {
      buttons: getGridButtons(commonExportOptions, 'device_overview', this.translateService.instant('menu.deviceoverview')),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 2 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => {
          try {
            const columnSettings = await that.loadState(that.constructorName);
            that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            that.error = {};
            that.error.error = e;
            that.error.statusText = 'Error fetching column settings';

            return null;
          }
        })().then(result => {
          callback(result);
        });
      },
      order: [[4, 'asc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.deviceService.getPagingUrl(),
        data: (d) => {
          const imeiList = [];

          if (this.imeiText && this.imeiText.length > 10) {
            if (this.imeiText?.indexOf(';') > -1) {
              const tempList = this.imeiText?.split(';');
              tempList.forEach(item => {
                item = item.trim();
                if (item.length > 5) {
                  imeiList.push(item);
                }
              });
            } else {
              const tempList = this.imeiText?.split(/\r?\n/);
              tempList.forEach(item => {
                item = item.trim();
                if (item.length > 5) {
                  imeiList.push(item);
                }
              });
            }
          }

          return {
            ...d, filterOptions: { imeiList: imeiList, accountId: this.selectedAccountId, resellerId: this.selectedResellerId, assetGroups: this.selectedAssetGroups }
          };
        },
        dataSrc: function (json) {
          // if selection and returning keys
          if (that.processing.tableIdsSelection && that.processing.tableIdsSelection.length === 0) {
            that.processing.tableIdsSelection = json.keys;
          }
          return json.data;
        },
        error: (jqXHR, ajaxOptions, thrownError) => {
          that.loading = false;
          that.error = thrownError;
          that.cd.markForCheck();
        },
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        that.checkRoute();
        that.checkFilters();
        that.setEvents();
        that.drawFilterRow();
        $('#grid_select_or').appendTo($('th > #selectorHeader'));
        that.loading = false;
        that.cd.markForCheck();
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: excludedColumns
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
      drawCallback: (data) => {
        setTableStars(that.processing.tableIds);
        setSelection(that.processing.tableIds, that.processing.gridSelection);
      },
      rowCallback: (row, data) => {
        setSelectionRows(that, row, data, that.processing.gridSelection);
      }
    };
  }

  // batch processing
  onClose(event) {
    console.log('Closing batch popup');

    this.success = null;
    this.error = null;

    if (event && event.status === BatchStatus.Success) {
      console.log('success');

      this.success = {};
      this.success.statusText = 'Successfully posted batch';
      this.success.success = 'Your batch was accepted and scheduled to be proccesed';
      this.success.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
    if (event && event.status === BatchStatus.Error) {
      this.error = {};
      this.error.statusText = 'Error';
      this.error.error = 'Error in processing the batch';
      this.error.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
  }
}
