<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/System/">{{'general.system' | translate }}</a>
            </li>
            <li>
                <a href="/#/System/Batch/Overview">{{'general.jobs' | translate }}</a>
            </li>
            <li>
                <a href="/#/System/BatchDetails/Index/{{batchJob?.id}}">{{batchJob?.id}}</a>
            </li>
            <li class="active">{{'general.dashboard' | translate }}</li>
        </ol>
    </div>
    <fh-system-navigation></fh-system-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <fh-error [error]="error" [success]="success"></fh-error>

            <div class="col-lg-6">
                <div class="ibox" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-sync-alt"></i> {{'general.jobs' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative;">
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <!-- <tr>
                                <td class="row_opening">{{ 'general.id' | translate}}</td>
                                <td class="row_content">{{batchJob?.id}}</td>
                            </tr> -->
                                <tr>
                                    <td class="row_opening">{{ 'general.jobRequested' | translate}}</td>
                                    <td class="row_content" title="{{batchJob?.jobRequested?.toLocaleString()}}">
                                        {{batchJob?.jobRequested | amDateFormat: 'LLL'}}
                                    </td>
                                </tr>
                                <!-- <tr>
                                <td class="row_opening">{{ 'general.jobStarted' | translate}}</td>
                                <td class="row_content" title="{{batchJob?.jobStarted?.toLocaleString()}}">
                                    {{batchJob?.jobStarted | amDateFormat: 'LLL'}}</td>
                            </tr> -->
                                <tr>
                                    <td class="row_opening">{{ 'general.jobCompleted' | translate}}</td>
                                    <td class="row_content" title="{{batchJob?.jobCompleted?.toLocaleString()}}">
                                        {{batchJob?.jobCompleted | amDateFormat: 'LLL'}}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.batchEntity' | translate}}</td>
                                    <td class="row_content">{{ ('enums.entityTypes.' + batchJob?.entityTypes) |
                                        translate }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.batchActionType' | translate}}</td>
                                    <td class="row_content">{{ ('enums.batchAction.' + batchJob?.batchActionType) |
                                        translate }}</td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.itemCount' | translate}}</td>
                                    <td class="row_content"><b>{{batchJob?.items.length}}</b></td>
                                </tr>
                                <tr>
                                    <td class="row_opening">{{ 'general.status' | translate}}</td>
                                    <td class="row_content">{{ ('enums.batchStatus.' + batchJob?.status) | translate }}
                                    </td>
                                </tr>
                                <tr *ngIf="batchJob?.errorDescription">
                                    <td colspan="2" style=" white-space: normal;text-overflow: ellipsis;">
                                        {{ batchJob?.errorDescription }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="ibox" style="position:relative" *ngIf="batchJob.log">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-th-list"></i> {{'general.log' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content" style="position: relative;">
                        {{ batchJob.log }}
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="ibox" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-th-list"></i> {{'general.items' | translate }}
                        </h5>
                        <span class="pull-right">
                            <div class="filter-form hidden-sm hidden-xs">
                                <input type="text" class="form-control table-inline top-filter hidden-sm hidden-xs"
                                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th style="width:100px">
                                        {{'general.item' | translate }}
                                    </th>
                                    <th style="width:100px">
                                        {{'general.status' | translate }}
                                    </th>
                                    <th style="width:auto">
                                        {{'general.message' | translate }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of batchJob?.items | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage }">

                                    <td class="elipses_overflow">
                                        <a class="secondary link_bolder" *ngIf="batchActionType !== 9"
                                            href="{{ item.link }}">{{ item.entityId }}</a>
                                        <span *ngIf="batchActionType === 9">
                                            {{ item.entityId }}
                                        </span>
                                    </td>
                                    <td class="elipses_overflow">
                                        {{ "enums.batchItemStatus." + item.status | translate }}
                                    </td>
                                    <td class="elipses_overflow" title="{{item.message}}">
                                        {{ item.message }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <div class="ibox" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-th-list"></i> {{'general.json' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative;">
                        <pretty-json *ngIf="batchJob.jsonObject" [obj]="batchJob.jsonObject"></pretty-json>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>