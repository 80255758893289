<div class="ibox-content slick" style="position:relative">
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!userContact">
            <tr>
                <td colspan="4">
                    {{ 'general.noUserContact' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="userContact">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{userContact?.resellerId}}">{{userContact?.resellerDescription
                            }}</a>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                    </td>
                    <td class="row_content padding-readonly-correct">
                        <a class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{userContact?.accountId}}">{{userContact?.companyName
                            }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position:relative; overflow: visible">
                        <fh-reseller-input name="reseller" #resellerVar="ngModel" (onChanged)="resellerChanged($event)"
                            required placeholder="{{ 'general.chooseReseller' | translate}}" [tableInline]="true"
                            [(ngModel)]="selectedResellerId">
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && userContact.accountId">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position:relative; overflow: visible">
                        <fh-account-input name="account" #accountVar="ngModel" (onChanged)="accountChanged($event)"
                            required [selectedResellerId]="selectedResellerId" [tableInline]="true"
                            placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                            [(ngModel)]="userContact.accountId">
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <tr *ngIf="formMode == 2">
                <td class="row_opening">
                    {{ 'general.user' | translate}}
                    <span class="error" *ngIf="userSelect.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="position:relative; overflow: visible">
                    <fh-user-input name="users" #userSelect="ngModel" [selectedAccountId]="userContact.accountId"
                        placeholder="{{ (userContact.accountId ? 'general.chooseUser' : 'general.firstChooseAccount') | translate}}"
                        [(ngModel)]="userContact.userId">
                    </fh-user-input>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.firstName' | translate}}
                    <span class="error" *ngIf="firstName.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="firstName" class="form-control table-inline"
                        [(ngModel)]="userContact.firstName" #firstName="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.lastName' | translate}}
                    <span class="error" *ngIf="lastName.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="lastName" class="form-control table-inline"
                        [(ngModel)]="userContact.lastName" #lastName="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.email' | translate}}
                    <span class="error" *ngIf="email.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="email" class="form-control table-inline"
                        [(ngModel)]="userContact.email" #email="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.phone' | translate}}
                    <span class="error" *ngIf="msisdn.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="msisdn" class="form-control table-inline"
                        [(ngModel)]="userContact.msisdn" #msisdn="ngModel">
                </td>
            </tr>
            <tr [hidden]="!permissions['Platform_IsReseller']">
                <td class="row_opening">
                    {{ 'general.webserviceToken' | translate}} <i class="fa fa-fw fa-key-skeleton"
                        title="{{ 'general.hiddenForNormalUsers' | translate }}"></i>
                    <span class="error" *ngIf="webserviceToken.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="webserviceToken"
                        class="form-control table-inline" [(ngModel)]="userContact.webserviceToken"
                        #webserviceToken="ngModel">
                </td>
            </tr>
            <tr [hidden]="!permissions['Platform_IsReseller']">
                <td class="row_opening">
                    {{ 'general.userContactType' | translate}} <i class="fa fa-fw fa-key-skeleton"
                        title="{{ 'general.hiddenForNormalUsers' | translate }}"></i>
                    <span class="error" *ngIf="userContactType.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select placeholder="{{ 'general.chooseUserContactGroup' | translate}}" name="userContactType"
                        [disabled]="formMode == 1" [(ngModel)]="userContact.userContactType" [clearable]="false"
                        class="form-control table-inline" #userContactType="ngModel" required style="width:100%">
                        <ng-option *ngFor="let userContactType of userContactTypes" [value]="userContactType.value">
                            {{userContactType.name}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
        </tbody>
    </table>
</div>