import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';
import { Project } from '../../models/project.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { fixDate } from '../common/functions.service';
import { LoggingService } from '../logging/logging.service';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
window['moment'] = Moment;
mTZ()

@Injectable()
export class ProjectService {
    url = '';
    base_url = '';
    inventoryUrl = '';
    Projects: Project[] = [];
    timezoneIana: string;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getWebserviceURL('project');
        this.base_url = this.authenticationService.getWebserviceURL('');
        this.timezoneIana = this.authenticationService.getTimeZoneIana();
    }

    getPagingUrl(accountId?: number) {
        return this.url + 'Paging' + (accountId > 0 ? `?accountId=${accountId}` : '');
    }

    getProjects(): Observable<Project[]> {
        console.log('getting projects from service');
        return this.http.get(this.url, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getProjectById(id: string): Observable<Project> {
        console.log('Fetch project by id ' + id);
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseReponseDetails(data);
            })
            .catch(this.handleError);
    }

    getProjectsByAccount(accountId): Observable<Project[]> {
        console.log('getting projects from service');
        return this.http.get(this.base_url + 'Account/' + accountId + '/Projects', { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getProjectAssignmentsById(id: string): Observable<any> {
        console.log('Fetch project assignments by id ' + id);
        return this.http.get(this.url + id + '/Assignments', { headers: this.authenticationService.headers })
            .map((data: any) => {
                data.forEach(item => {
                    item.dateStart = item.dateStart !== undefined ? Moment.utc(item.dateStart)['tz'](this.timezoneIana) : undefined;
                    item.dateEnd = item.dateEnd !== undefined ? Moment.utc(item.dateEnd)['tz'](this.timezoneIana) : undefined;
                });
                return data;
            })
            .catch(this.handleError);
    }


    saveProject(project: Project): Observable<any> {
        console.log('save project', project);

        return this.http.post(this.url, project, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    updateProjectAssignments(project: Project): Observable<any> {
        console.log('update project assignments', project);

        return this.http.put(this.url + 'Assignments/' + project.id, project, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    updateProject(project: Project): Observable<any> {
        console.log('update project', project);

        return this.http.put(this.url + project.id, project, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    deleteProject(project: Project): Observable<any> {
        console.log('delete project', project);

        return this.http.delete(this.url + project.id, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    resetCache(): Observable<boolean> {
        return this.http.get(this.url + 'ResetCache', { headers: this.authenticationService.headers })
            .map((data: any) => {
                return data;
            })
            .catch(this.handleError);
    }

    getProjectsByDeviceId(id: string): Observable<Project[]> {
        console.log('Fetch projects by id ' + id);
        return this.http.get(this.url + 'Device/' + id, { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getProjectsByAssetId(id: string): Observable<Project[]> {
        console.log('Fetch projects by id ' + id);
        return this.http.get(this.url + 'Asset/' + id, { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        return throwError(error);
    }

    parseResponse(json: any): Project[] {
        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Projects.');

        const ident = 1;
        const projects: Project[] = [];

        json.forEach(item => {

            const project = this.parseReponseDetails(item);
            projects.push(project);
        });

        return projects;
    }

    parseReponseDetails(item) {
        const project = new Project();
        project.id = item.id;
        project.name = item.name;
        project.description = item.description;

        project.companyName = item.companyName;
        project.accountId = item.accountId;

        project.deviceId = item.deviceId;
        project.assetName = item.assetName;
        project.projectAssignmentStart = item.projectAssignmentStart;
        project.projectAssignmentEnd = item.projectAssignmentEnd;
        project.resellerId = item.resellerId;
        project.resellerDescription = item.resellerDescription;

        return project;
    }
}
