import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { AuditLog } from '../../../models/auditlog.model';

import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

// Moment
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';

import { FormMode } from 'app/common/enums';

import { AccountService } from 'app/services/account/account.service';
import { AccountInventory } from 'app/models/account.model';
import { WorkingHoursSelectorComponent } from 'app/views/shared/usercontrols/workingHours.component';
import { TriggerScheduleService } from 'app/services/triggerSchedule/triggerSchedule.service';
import { TriggerSchedule } from 'app/models/triggerSchedule.model';
window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-trigger-schedules-add',
    templateUrl: 'addTriggerSchedule.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerScheduleAddViewComponent implements OnInit, AfterViewInit {
    @ViewChild(WorkingHoursSelectorComponent, { static: false }) workingHoursVar: WorkingHoursSelectorComponent;

    sub;

    loading = false;
    saving = false;

    error: any;
    success: any;

    formMode: FormMode = FormMode.add;

    triggerSchedule: TriggerSchedule;
    account: AccountInventory;

    timezoneIana;
    hideTimezoneSelector = true;

    constructor(private cd: ChangeDetectorRef, private triggerScheduleService: TriggerScheduleService, private accountService: AccountService, private route: ActivatedRoute, private router: Router, private translateService: TranslateService, private authenticationService: AuthenticationService) {
        this.account = null;

        this.triggerSchedule = new TriggerSchedule();
        this.timezoneIana = this.authenticationService.getTimeZoneIana();
        this.triggerSchedule.timezoneIana = this.timezoneIana;
        this.triggerSchedule.schedule = [];
        this.cd.markForCheck();
    }

    ngOnInit() {
        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            const accountId = paramMap.get('selectedAccountId');
            if (accountId !== null && accountId !== undefined && accountId !== '0') {
                this.accountService.getAccountById(accountId).subscribe(account => {
                    this.triggerSchedule.resellerId = account.resellerId;
                    setTimeout(() => {
                        this.triggerSchedule.accountId = account.id;
                        this.cd.markForCheck();
                    }, 100);

                    this.cd.markForCheck();
                })
            }
        })
    }

    ngAfterViewInit() {
        this.workingHoursVar.createSchedule();
        this.cd.markForCheck();
    }

    onInsert() {
        this.saving = true;
        console.log('insert');
        this.triggerSchedule.schedule = this.workingHoursVar.schedule;
        this.triggerScheduleService.saveTriggerSchedule(this.triggerSchedule).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Trigger schedule is successfully added.'
            };

            setTimeout(() => {
                this.router.navigate(['/System/TriggerScheduleDetails/Index/' + result.id]);
            }, 2000);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
        });
    }
}
