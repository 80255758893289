<table style="width:100%" class="table nowrap dtr-inline no-footer">
    <tbody>
        <tr>
            <td class="row_opening">
                {{ 'general.occurrence' | translate}}
            </td>
            <td class="row_content" style="position:relative; overflow: visible">
                <select style="width:100%" name="minutes" class="timeFormControl" (change)="activeTabSelected()"
                    [(ngModel)]="activeTab" class="form-control table-inline">
                    <option value="minutes" *ngIf="!options.hideMinutesTab">Minutes</option>
                    <option value="hourly" *ngIf="!options.hideHourlyTab">Hourly</option>
                    <option value="daily" *ngIf="!options.hideDailyTab">Daily</option>
                    <option value="weekly" *ngIf="!options.hideWeeklyTab">Weekly</option>
                    <option value="monthly" *ngIf="!options.hideMonthlyTab">Monthly</option>
                    <option value="yearly" *ngIf="!options.hideYearlyTab">Yearly</option>
                    <option value="advanced" *ngIf="!options.hideAdvancedTab">Advanced</option>
                </select>
            </td>
        </tr>
        <tr>
            <td class="row_content" colspan="2" style="position:relative; white-space: initial;">

                <!-- Minutes-->
                <div class="tab-pane" *ngIf="!options.hideMinutesTab && activeTab === 'minutes'"
                    [ngClass]="{'active': activeTab === 'minutes'}">
                    <div class="well well-small cron-well">
                        Every
                        <select class="minutes" [disabled]="disabled || activeTab !== 'minutes'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.minutes.minutes" [ngClass]="options.formSelectClass">
                            <option *ngFor="let minute of selectOptions.minutes" [ngValue]="minute">
                                {{minute}}
                            </option>
                        </select> minute(s)
                        <span *ngIf="!options.hideSeconds">on second</span>
                        <select class="seconds" *ngIf="!options.hideSeconds"
                            [disabled]="disabled || activeTab !== 'minutes'" (change)="regenerateCron()"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="state.minutes.seconds"
                            [ngClass]="options.formSelectClass">
                            <option *ngFor="let second of selectOptions.seconds" [ngValue]="second">
                                {{second | number:'2.0'}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Hourly-->
                <div class="tab-pane" *ngIf="!options.hideHourlyTab && activeTab === 'hourly'"
                    [ngClass]="{'active': activeTab === 'hourly'}">
                    <div class="well well-small cron-well">
                        Every
                        <select class="hours" [disabled]="disabled || activeTab !== 'hourly'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.hourly.hours" [ngClass]="options.formSelectClass">
                            <option *ngFor="let hour of selectOptions.hours" [ngValue]="hour">
                                {{hour | number:'2.0'}}
                            </option>
                        </select> hour(s) on minute
                        <select class="minutes" [disabled]="disabled || activeTab !== 'hourly'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.hourly.minutes" [ngClass]="options.formSelectClass">
                            <option *ngFor="let minute of selectOptions.fullMinutes" [ngValue]="minute">
                                {{minute| number:'2.0'}}
                            </option>
                        </select>
                        <span *ngIf="!options.hideSeconds">and second</span>
                        <select class="seconds" *ngIf="!options.hideSeconds"
                            [disabled]="disabled || activeTab !== 'hourly'" (change)="regenerateCron()"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="state.hourly.seconds"
                            [ngClass]="options.formSelectClass">
                            <option *ngFor="let second of selectOptions.seconds" [ngValue]="second">
                                {{second| number:'2.0'}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Daily-->
                <div class="tab-pane" *ngIf="!options.hideDailyTab &&  activeTab === 'daily'"
                    [ngClass]="{'active': activeTab === 'daily'}">
                    <div class="well well-small cron-well">
                        <input type="radio" name="daily-radio" value="everyDays" [disabled]="disabled"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.daily.subTab" value="everyDays" [disabled]="disabled"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.daily.subTab" [ngClass]="state.formRadioClass" checked="checked">
                        Every
                        <select class="days"
                            [disabled]="disabled || activeTab !== 'daily' || state.daily.subTab !== 'everyDays'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.daily.everyDays.days" [ngClass]="options.formSelectClass">
                            <option *ngFor="let monthDay of selectOptions.monthDays" [ngValue]="monthDay">
                                {{monthDay}}
                            </option>
                        </select> day(s) at

                        <fh-cron-time-picker
                            [disabled]="disabled || activeTab !== 'daily' || state.daily.subTab !== 'everyDays'"
                            (change)="regenerateCron()" [time]="state.daily.everyDays"
                            [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                            [hideSeconds]="options.hideSeconds">
                        </fh-cron-time-picker>
                    </div>

                    <div class="well well-small cron-well">
                        <input type="radio" name="daily-radio" value="everyWeekDay" [disabled]="disabled"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.daily.subTab" [ngClass]="state.formRadioClass"> Every working day
                        at
                        <fh-cron-time-picker
                            [disabled]="disabled || activeTab !== 'daily' || state.daily.subTab !== 'everyWeekDay'"
                            (change)="regenerateCron()" [time]="state.daily.everyWeekDay"
                            [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                            [hideSeconds]="options.hideSeconds">
                        </fh-cron-time-picker>
                    </div>
                </div>

                <!-- Weekly-->
                <div class="tab-pane" *ngIf="!options.hideWeeklyTab && activeTab === 'weekly'"
                    [ngClass]="{'active': activeTab === 'weekly'}">
                    <div class="well well-small cron-well">
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox"
                                        [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="state.weekly.MON"
                                        [ngClass]="options.formCheckboxClass">
                                    Monday</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox"
                                        [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="state.weekly.TUE"
                                        [ngClass]="options.formCheckboxClass">
                                    Tuesday</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox"
                                        [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="state.weekly.WED"
                                        [ngClass]="options.formCheckboxClass">
                                    Wednesday</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox"
                                        [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="state.weekly.THU"
                                        [ngClass]="options.formCheckboxClass">
                                    Thursday</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox"
                                        [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="state.weekly.FRI"
                                        [ngClass]="options.formCheckboxClass">
                                    Friday</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox"
                                        [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="state.weekly.SAT"
                                        [ngClass]="options.formCheckboxClass">
                                    Saturday</label>
                            </div>
                            <div class="col-sm-6">
                                <label class="advanced-cron-editor-label"><input type="checkbox"
                                        [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="state.weekly.SUN"
                                        [ngClass]="options.formCheckboxClass">
                                    Sunday</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                at
                                <fh-cron-time-picker [disabled]="disabled || activeTab !== 'weekly'"
                                    (change)="regenerateCron()" [time]="state.weekly"
                                    [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                                    [hideSeconds]="options.hideSeconds">
                                </fh-cron-time-picker>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Monthly-->
                <div class="tab-pane" *ngIf="!options.hideMonthlyTab && activeTab === 'monthly'"
                    [ngClass]="{'active': activeTab === 'monthly'}">
                    <div class="well well-small cron-well">
                        <input type="radio" name="monthly-radio" value="specificDay" [disabled]="disabled"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.monthly.subTab" [ngClass]="state.formRadioClass"> On the
                        <select class="month-days"
                            [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificDay'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.monthly.specificDay.day" [ngClass]="options.formSelectClass">
                            <option *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts"
                                [ngValue]="monthDaysWithLast">
                                {{monthDayDisplay(monthDaysWithLast)}}</option>
                        </select> of every
                        <select class="months-small"
                            [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificDay'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.monthly.specificDay.months" [ngClass]="options.formSelectClass">
                            <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                                {{month}}
                            </option>
                        </select> month(s) at
                        <fh-cron-time-picker
                            [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificDay'"
                            (change)="regenerateCron()" [time]="state.monthly.specificDay"
                            [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                            [hideSeconds]="options.hideSeconds">
                        </fh-cron-time-picker>&nbsp;
                        <label class="advanced-cron-editor-label"><input type="checkbox"
                                [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificDay'"
                                (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="state.monthly.runOnWeekday" [ngClass]="options.formCheckboxClass">
                            during
                            the nearest weekday</label>
                    </div>
                    <div class="well well-small cron-well">
                        <input type="radio" name="monthly-radio" value="specificWeekDay" [disabled]="disabled"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.monthly.subTab" [ngClass]="state.formRadioClass">
                        On the
                        <select class="day-order-in-month"
                            [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.monthly.specificWeekDay.monthWeek" [ngClass]="options.formSelectClass">
                            <option *ngFor="let monthWeek of selectOptions.monthWeeks" [ngValue]="monthWeek">
                                {{monthWeekDisplay(monthWeek)}}
                            </option>
                        </select>
                        <select class="week-days"
                            [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.monthly.specificWeekDay.day" [ngClass]="options.formSelectClass">
                            <option *ngFor="let day of selectOptions.days" [ngValue]="day">
                                {{dayDisplay(day)}}
                            </option>
                        </select> of every
                        <select class="months-small"
                            [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.monthly.specificWeekDay.months" [ngClass]="options.formSelectClass">
                            <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                                {{month}}
                            </option>
                        </select> month(s) starting in
                        <select class="months"
                            [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.monthly.specificWeekDay.startMonth" [ngClass]="options.formSelectClass">
                            <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                                {{monthDisplay(month)}}
                            </option>
                        </select>

                        at
                        <fh-cron-time-picker
                            [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                            (change)="regenerateCron()" [time]="state.monthly.specificWeekDay"
                            [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                            [hideSeconds]="options.hideSeconds">
                        </fh-cron-time-picker>
                    </div>
                </div>

                <!-- Yearly-->
                <div class="tab-pane" *ngIf="!options.hideYearlyTab && activeTab === 'yearly'"
                    [ngClass]="{'active': activeTab === 'yearly'}">
                    <div class="well well-small cron-well">
                        <input type="radio" name="yearly-radio" value="specificMonthDay" [disabled]="disabled"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.yearly.subTab" [ngClass]="state.formRadioClass">
                        Every
                        <select class="months"
                            [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthDay'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.yearly.specificMonthDay.month" [ngClass]="options.formSelectClass">
                            <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                                {{monthDisplay(month)}}
                            </option>
                        </select> on the
                        <select class="month-days"
                            [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthDay'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.yearly.specificMonthDay.day" [ngClass]="options.formSelectClass">
                            <option *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts"
                                [ngValue]="monthDaysWithLast">
                                {{monthDayDisplay(monthDaysWithLast)}}
                            </option>
                        </select> at
                        <fh-cron-time-picker
                            [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthDay'"
                            (change)="regenerateCron()" [time]="state.yearly.specificMonthDay"
                            [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                            [hideSeconds]="options.hideSeconds">
                        </fh-cron-time-picker>&nbsp;
                        <label class="advanced-cron-editor-label"><input type="checkbox" (change)="regenerateCron()"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="state.yearly.runOnWeekday"
                                [ngClass]="options.formCheckboxClass">
                            during the nearest weekday</label>
                    </div>
                    <div class="well well-small cron-well">
                        <input type="radio" name="yearly-radio" value="specificMonthWeek" [disabled]="disabled"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.yearly.subTab" [ngClass]="state.formRadioClass">
                        On the
                        <select class="day-order-in-month"
                            [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthWeek'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.yearly.specificMonthWeek.monthWeek" [ngClass]="options.formSelectClass">
                            <option *ngFor="let monthWeek of selectOptions.monthWeeks" [ngValue]="monthWeek">
                                {{monthWeekDisplay(monthWeek)}}
                            </option>
                        </select>
                        <select class="week-days"
                            [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthWeek'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.yearly.specificMonthWeek.day" [ngClass]="options.formSelectClass">
                            <option *ngFor="let day of selectOptions.days" [ngValue]="day">
                                {{dayDisplay(day)}}
                            </option>
                        </select> of
                        <select class="months"
                            [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthWeek'"
                            (change)="regenerateCron()" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="state.yearly.specificMonthWeek.month" [ngClass]="options.formSelectClass">
                            <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                                {{monthDisplay(month)}}
                            </option>
                        </select> at
                        <fh-cron-time-picker
                            [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthWeek'"
                            (change)="regenerateCron()" [time]="state.yearly.specificMonthWeek"
                            [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                            [hideSeconds]="options.hideSeconds">
                        </fh-cron-time-picker>
                    </div>
                </div>

                <!-- Advanced-->
                <div class="tab-pane" *ngIf="!options.hideAdvancedTab && activeTab === 'advanced'"
                    [ngClass]="{'active': activeTab === 'advanced'}">
                    Cron Expression
                    <input type="text" class="advanced-cron-editor-input"
                        ng-disabled="disabled || activeTab !== 'advanced'" (change)="regenerateCron()"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="state.advanced.expression"
                        [ngClass]="options.formInputClass">
                </div>

                <div class="row" *ngIf="!state.validation.isValid">
                    <code>{{state.validation.errorMessage}}</code>
                </div>
            </td>
        </tr>
        <!-- <tr>
            <td class="row_opening">
                {{ 'general.cronExpression' | translate}}
            </td>
            <td class="row_content" style="position:relative; overflow: visible">
                <input type="text" class="form-control table-inline" disabled="disabled"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="localCron">
            </td>
        </tr> -->
    </tbody>
</table>