import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { ConfirmationModalComponent } from './confirmationModal.component';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { getDefaultDpConfig } from 'app/common/gridhelper';

@Component({
    selector: 'fh-rerun-report-modal',
    templateUrl: 'rerunReportModal.template.html',
})

export class RerunReportModalComponent implements OnChanges, OnInit {
    @Input() reportSubscription;
    @Input() timezoneIana: string;
    @Input() daterangepickerModel: Date[];

    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();

    constructor(@Inject(ConfirmationModalComponent) private parent: ConfirmationModalComponent, private authenticationService: AuthenticationService) {
        this.timezoneIana = this.authenticationService.getTimeZoneIana();
        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

        // Set daterange
        // this.daterangepickerModel = [
        //     Moment().subtract(this.reportSubscription.nextOccurrence, 'day').startOf('day').toDate(),
        //     Moment().add(0, 'days').endOf('day').toDate()
        // ];
    }
    ngOnInit(): void {
        this.parent.invalid = true;
    }

    ngOnChanges(): void {
    }

    dateChanged(event) {
        if (event === null || event === undefined) {
            this.parent.invalid = true;
            return;
        }
        if (event === 'hidden') {
            return;
        }

        this.daterangepickerModel = event;
        this.parent.invalid = false;
    }
}
