import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';
import { Device } from '../../models/device.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { generateEntityDetailLink } from 'app/common/globals';
import { EntityType } from 'app/common/enums';
import { AssetService } from '../asset/asset.service';
import { NotificationLog } from 'app/models/notificationlog.model';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import * as moment from 'moment-timezone';

window['moment'] = Moment;
mTZ()

@Injectable()
export class NotificationLogService {
    token: string;
    timestamp;
    url = '';
    urlBase = '';
    timezoneIana: string;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService, private assetService: AssetService) {
        this.url = authenticationService.getWebserviceURL('notificationlog');
        this.urlBase = authenticationService.getWebserviceURL('');
        this.timezoneIana = authenticationService.getTimeZoneIana();
    }

    getPagingUrl(start, end, accountId) {
        return this.url + 'Paging?startRange=' + start.unix() + '&endRange=' + end.unix() + '&accountId=' + accountId;
    }

    getNoticationLogById(id: string): Observable<NotificationLog> {
        console.log('getting notificationLog from service');
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponseDetails(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getNoticationLogByDeviceId(id: string, start, end): Observable<any> {
        return this.http.get(this.urlBase + 'Device/' + id + '/Notifications?start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .map((data: any) => {
                const notifications = this.parseResponse(data);
                return notifications;
            })
            .catch(this.handleError);
    }

    getNotificationLogByTriggerId(id: string, start, end): Observable<any> {
        return this.http.get(this.urlBase + 'Trigger/' + id + '/Notifications?start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .map((data: any) => {
                const notifications = this.parseResponse(data);
                return notifications;
            })
            .catch(this.handleError);
    }

    getNoticationLogByAccountId(id: string, start, end): Observable<any> {
        return this.http.get(this.urlBase + 'Account/' + id + '/Notifications?start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .map((data: any) => {
                const notifications = this.parseResponse(data);
                return notifications;
            })
            .catch(this.handleError);
    }

    getNoticationLogChart(start, end, accountId): Observable<any> {
        console.log('getting notificationLog from service');
        return this.http.get(this.url + 'Chart?startRange=' + start.unix() + '&endRange=' + end.unix() + '&accountId=' + accountId, { headers: this.authenticationService.headers })
            .map((data) => {
                return data;
            })
            .catch(this.handleError);
    }


    private handleError(error: Response) {
        return throwError(error);
    }

    parseResponse(json: any): NotificationLog[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Logitems.');
        const that = this;

        const ident = 1;
        const auditlogs: NotificationLog[] = [];

        json.forEach(item => {
            const log = this.parseResponseDetails(item);
            auditlogs.push(log);
        });

        this.timestamp = new Date().getTime();
        return auditlogs;
    }

    parseResponseDetails(item) {
        const log = new NotificationLog();
        log.id = item.id;
        log.deviceId = item.deviceId;
        log.dataTriggerId = item.dataTriggerId;
        log.recipient = item.recipient;
        log.notificationType = item.notificationType;
        log.responseCode = item.responseCode;
        log.notificationMethod = item.notificationMethod;
        log.timeStampSending = Moment.utc(item.timeStampSending)['tz'](this.timezoneIana);
        log.emailLoginName = item.emailLoginName;
        log.timeStampAcknowledged = item.timeStampAcknowledged;
        log.transmissionStatus = item.transmissionStatus;
        log.fkUserId = item.fkUserId;
        log.subject = item.subject;
        log.triggerId = item.triggerId;
        log.triggerName = item.triggerName;
        log.smsExternalMessageReference = item.smsExternalMessageReference;
        log.assetName = item.assetName;
        log.dataTriggerName = item.dataTriggerName;

        try {
            log.message = item.message && item.message.charAt(0) === '{' ? JSON.parse(item.message.replace(/\r?\n|\r/g, '')) : item.message;
            log.responseMessage = item.responseMessage && item.responseMessage.charAt(0) === '{' ? JSON.parse(item.responseMessage.replace(/\r?\n|\r/g, '')) : item.responseMessage;
        } catch (error) {
            log.message = item.message;
            log.responseMessage = item.responseMessage;
            console.log(error);
        }

        return log;
    }
}
