import { Form } from 'app/common/enums';

export class Geofence {
    public id: number;
    public name: string;
    public color: any = '#0033FF';
    public form: Form;
    public type: number;
    public referenceId: any;
    public latitude: any;
    public longitude: any;
    public polygonIncludingRadius: any;
    public geoJson: any;
    public companyName: any;
    public resellerDescription: any;
    public resellerId: any;
    public accountId: any;
    public routeProperty: any = 0;
    public radius: any;
    public created: any;
    public isPersonal: boolean;
    public isPop: any;
    public userId: any;
    public userName: any;
    public isArchived: any;
    public layerRef: any;
}
