<div class="ibox-content slick" style="position:relative;">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!reseller">
            <tr>
                <td colspan="2">
                    {{ 'general.noReseller' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="reseller">
            <tr *ngIf="formMode == 1">
                <td class="row_opening">
                    {{ 'general.parent' | translate}}
                </td>
                <td class="padding-readonly-correct row_content">
                    <a class="secondary link_bolder"
                        href="/#/ResellerDetails/Index/{{reseller?.parentResellerId}}">{{reseller?.parentDescription
                        }}</a>
                </td>
            </tr>
            <tr *ngIf="formMode != 1">
                <td class="row_opening">
                    {{ 'general.parent' | translate}}

                    <span class="error" *ngIf="resellerVar?.invalid">*</span>
                </td>
                <td class="row_content" style="position:relative; overflow: visible">
                    <fh-reseller-input name="reseller" #resellerVar="ngModel" (onChanged)="resellerChanged($event)"
                        [tableInline]="true" placeholder="{{ 'general.chooseReseller' | translate}}"
                        [(ngModel)]="reseller.parentResellerId">
                    </fh-reseller-input>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.resellerDescription' | translate}}
                    <span class="error" *ngIf="resellerDescription.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="resellerDescription"
                        placeholder="{{ 'placeholder.resellerDescription' | translate}}"
                        class="form-control table-inline" [(ngModel)]="reseller.resellerDescription" required
                        minlength="4" #resellerDescription="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.resellerEmail' | translate}}
                    <span class="error" *ngIf="resellerEmail.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="resellerEmail" class="form-control table-inline"
                        placeholder="{{ 'placeholder.resellerEmail' | translate}}" [(ngModel)]="reseller.resellerEmail"
                        required minlength="4" #resellerEmail="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.resellerTechnicalContactEmail' | translate}}
                    <span class="error" *ngIf="resellerTechnicalContactEmail.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="resellerTechnicalContactEmail"
                        placeholder="{{ 'placeholder.resellerTechnicalContactEmail' | translate}}"
                        class="form-control table-inline" [(ngModel)]="reseller.resellerTechnicalContactEmail" required
                        minlength="4" #resellerTechnicalContactEmail="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.isNotSelectable' | translate}}
                </td>
                <td class="padding-readonly-correct row_content">
                    <div class="fa_checkbox">
                        <input id="checkbox_1" #checkbox_1="ngModel" type="checkbox" [disabled]="formMode == 1"
                            [(ngModel)]="reseller.isNotSelectable" name="checkbox_1" />
                        <label for="checkbox_1"></label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.resellerAuthorizationCode' | translate}}
                    <span class="error" *ngIf="resellerAuthorizationCode.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="resellerAuthorizationCode"
                        placeholder="{{ 'placeholder.resellerAuthorizationCode' | translate}}"
                        class="form-control table-inline" [(ngModel)]="reseller.resellerAuthorizationCode" required
                        minlength="4" #resellerAuthorizationCode="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.defaultResellerIcon' | translate}}
                </td>
                <td class="row_content" style="overflow:visible">
                    <fh-asset-icon-chooser [iconId]="reseller.defaultAssetIconId" [formMode]="formMode"
                        (onIconChanged)="reseller.defaultAssetIconId = $event"></fh-asset-icon-chooser>
                </td>
            </tr>
        </tbody>
    </table>
</div>