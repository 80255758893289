export class AppDashboard {
  public deviceCount: number;
  public customerCount: number;
  public geofenceCount: number;
  public driverCount: number;
  public userCount: number;
  public warehouseCount: number;
  public tagCount: number;
}

export class DeviceCountDashboard {
  public customerId: number;
  public name: number;
  public deviceCount: number;
}

