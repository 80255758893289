<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="device?.asset && device?.asset.accountId">
                <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate }}</a>
            </li>
            <li>
                <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                    device?.name}}</a>
            </li>
            <li class="active">{{'general.sensorThresholds' | translate }}</li>
        </ol>

        <span class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix hidden-sm hidden-xs" title="Help"
                href="https://help.360locate.com/knowledge-base/fleet-management/virtual-sensors" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="hidden-sm hidden-xs">{{'general.needHelp' |
                    translate }}</span></a>
        </span>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId"></fh-device-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="">
            <div class="col-lg-12">

                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>
        <div class="row" data-equalizer="">
            <div class="col-lg-12">

                <fh-error [success]="successVirtualSensors" [error]="errorVirtualSensors" [autoRemove]=false>
                </fh-error>

                <div class="ibox">
                    <form #sensorForm="ngForm">
                        <div class="ibox-title">
                            <h5><i class="fas fa-fw fa-sensor"></i> {{'general.virtualSensors' | translate }}</h5>
                        </div>
                        <form #deviceForm="ngForm">
                            <div class="ibox-content slick" style="position: relative">
                                <ngx-loading [show]="loadingVirtualSensors">
                                </ngx-loading>
                                <table style="width: 100%" class="table nowrap dtr-inline no-footer">
                                    <thead>
                                        <tr>
                                            <th style="width: 200px">{{'general.sensor' | translate }}</th>
                                            <th>{{'general.configuration' | translate }}</th>
                                            <th style="width: 150px">{{'general.output' | translate }}</th>
                                            <th style="width: 50px">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="formModeVirtualSensors !== 3 && virtualSensors?.length === 0">
                                            <td colspan="4">{{'general.noSensorsFound' | translate }}</td>
                                        </tr>
                                        <tr *ngFor="let sensor of virtualSensors; let i = index">
                                            <td style="width: 200px">
                                                <input type="text" [readonly]="formModeVirtualSensors == 1"
                                                    [name]="'name_' + i"
                                                    placeholder="{{ 'placeholder.driverTag' | translate}}"
                                                    class="form-control table-inline" required
                                                    [(ngModel)]="sensor.name">
                                            </td>
                                            <td style="width:auto;">
                                                <textarea style="width: 100%; height: 75px; resize: none;"
                                                    [readonly]="formModeVirtualSensors == 1"
                                                    [name]="'configuration_' + i"
                                                    placeholder="{{ 'placeholder.formula' | translate}}"
                                                    class="form-control table-inline" required
                                                    [(ngModel)]="sensor.formula">
                                                </textarea>
                                            </td>
                                            <td style="width: 150px">
                                                <ng-select [name]="'sensorOuput_' + i" ngDefaultControl
                                                    [searchable]="true" [clearable]="false"
                                                    [readonly]="formModeVirtualSensors == 1"
                                                    [(ngModel)]="sensor.sensorOutputType"
                                                    class="form-control table-inline" style="width:100%" required>
                                                    <ng-option
                                                        *ngFor="let virtualSensorOutputType of virtualSensorOutputTypes | orderBy : 'name':false"
                                                        [value]="virtualSensorOutputType.value">
                                                        {{ ('enums.virtualSensorOutputType.' +
                                                        virtualSensorOutputType.value) |
                                                        translate}}
                                                    </ng-option>
                                                </ng-select>
                                            </td>

                                            <td style="text-align: right; width: 60px;">
                                                <button class="btn btn-danger btn-inline"
                                                    *ngIf="formModeVirtualSensors == 3" (click)="removeSensor(sensor)">
                                                    <i class="fa fa-fw fa-trash-alt"></i>
                                                </button>

                                                <i class="fa fa-fw fa-check" title="activated"
                                                    style="color: rgb(0, 142, 67);"
                                                    *ngIf="formModeVirtualSensors == 1 && sensor.active == 1"></i>
                                                <i class="fa fa-fw fa-triangle-exclamation" title="pending"
                                                    *ngIf="formModeVirtualSensors == 1 && sensor.active == 0"></i>
                                            </td>
                                        </tr>
                                        <tr *ngIf="formModeVirtualSensors == 3">
                                            <td style="width:auto;">
                                                <input type="text" name="newDriverTag"
                                                    placeholder="{{ 'placeholder.newSensor' | translate}}"
                                                    class="form-control table-inline" [(ngModel)]="newSensorName">
                                            </td>
                                            <td style="width:auto;">
                                                <textarea style="width: 100%; height: 75px; resize: none;"
                                                    name="newSensorFormula"
                                                    placeholder="{{ 'placeholder.formula' | translate}}"
                                                    class="form-control table-inline"
                                                    [(ngModel)]="newSensorFormula"></textarea>
                                            </td>
                                            <td style="width:auto;">
                                                <ng-select name="newSensorOutputType" ngDefaultControl
                                                    [searchable]="true" [clearable]="false"
                                                    [(ngModel)]="newSensorOutputType"
                                                    class="form-control table-inline" style="width:100%">
                                                    <ng-option
                                                        *ngFor="let virtualSensorOutputType of virtualSensorOutputTypes | orderBy : 'name':false"
                                                        [value]="virtualSensorOutputType.value">
                                                        {{ ('enums.virtualSensorOutputType.' +
                                                        virtualSensorOutputType.value) |
                                                        translate}}
                                                    </ng-option>
                                                </ng-select>
                                            </td>

                                            <td><button class="btn btn-primary btn-inline"
                                                    [disabled]="!newSensorName || newSensorName.length < 2 || !newSensorFormula || newSensorFormula.length < 2 || newSensorOutputType < 0"
                                                    (click)="add()">
                                                    <i class="fa fa-fw fa-plus"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                        <fh-save-footer [valid]="!deviceForm.invalid && (!newSensorName || newSensorName.length == 0)"
                            (onSave)="onSaveVirtualSensors()" [allowEdit]="permissions['Devices_Edit']"
                            [allowDelete]=false (setFormMode)="setFormModeVirtualSensors($event)"
                            [formMode]="formModeVirtualSensors">
                        </fh-save-footer>
                    </form>
                </div>
            </div>

            <div class="col-lg-12">
                <!-- Live -->
                <div class="ibox">
                    <form #sensorForm="ngForm">
                        <div class="ibox-title">
                            <h5><i class="fas fa-fw fa-sensor"></i> {{'general.sensorThresholds' | translate }}</h5>
                            <span class="pull-right"
                                tooltip="{{'general.updated' | translate }} {{ previousLookupDisplay | amDateFormat: 'LLL' }}">
                                <i style="margin-left: 3px;" class="fa fa-fw"
                                    [ngClass]="isSpinning ? 'fa-spinner fa-spin eye-active green' : 'fa-sync-alt'"></i>
                            </span>
                        </div>
                        <div class="ibox-content slick" style="position: relative">
                            <ngx-loading [show]="loadingLocation || saving"></ngx-loading>
                            <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                                <thead>
                                    <tr>
                                        <th style="width: 200px">
                                            {{ 'general.sensor' | translate}}
                                        </th>
                                        <th>
                                            {{ 'general.setting' | translate}}
                                        </th>
                                        <th style="width: 150px">
                                            {{ 'general.value' | translate}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngIf="activeLocation?.temperature1?.temperatureInCelcius  || showProp || device.properties.sensorTemplateSettings.temp1">
                                        <td class="row_opening">
                                            <i class="fas fa-fw fa-thermometer-three-quarters"
                                                style="opacity: 0.3;"></i> {{ 'general.temperature' | translate}} 1
                                        </td>
                                        <td>
                                            <fh-sensor-template-input name="sensorType_Temp1" [autoSelect]="false"
                                                placeholder="{{ 'general.sensorTypes' | translate}}"
                                                [sensorTemplates]="sensorTemplates"
                                                [loadingSensorTemplates]="loadingSensorTemplates" [sensorType]="1"
                                                #sensorTemplateTemp1="ngModel" [selectedAccountId]="device.accountId"
                                                [readonly]="formMode == 1"
                                                [(ngModel)]="device.properties.sensorTemplateSettings.temp1">
                                            </fh-sensor-template-input>
                                        </td>
                                        <td>
                                            <i class="fas fa-fw fa-bell eye-active "
                                                [ngClass]="activeLocation?.temperature1?.temperatureInCelcius  == undefined && device.properties.sensorTemplateSettings.temp1 == undefined ? '' : (checkSensorOnTempate(activeLocation?.temperature1?.temperatureInCelcius , device.properties.sensorTemplateSettings.temp1) == true ?  'green' : 'red') ">
                                            </i>

                                            <span *ngIf="activeLocation?.temperature1?.temperatureInCelcius ">{{
                                                activeLocation?.temperature1?.temperatureInCelcius ?.toLocaleString() }}
                                                °C</span>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="activeLocation?.temperature2?.temperatureInCelcius  || showProp || device.properties.sensorTemplateSettings.temp2">
                                        <td class="row_opening">
                                            <i class="fas fa-fw fa-thermometer-three-quarters"
                                                style="opacity: 0.3;"></i> {{
                                            'general.temperature' | translate}} 2
                                        </td>
                                        <td>
                                            <fh-sensor-template-input name="sensorType_Temp2" [autoSelect]="false"
                                                placeholder="{{ 'general.sensorTypes' | translate}}"
                                                [sensorTemplates]="sensorTemplates"
                                                [loadingSensorTemplates]="loadingSensorTemplates" [sensorType]="1"
                                                #sensorTemplateTemp2="ngModel" [selectedAccountId]="device.accountId"
                                                [readonly]="formMode == 1"
                                                [(ngModel)]="device.properties.sensorTemplateSettings.temp2">
                                            </fh-sensor-template-input>
                                        </td>
                                        <td>
                                            <i class="fas fa-fw fa-bell eye-active "
                                                [ngClass]="activeLocation?.temperature2?.temperatureInCelcius  == undefined && device.properties.sensorTemplateSettings.temp2 == undefined ? '' : (checkSensorOnTempate(activeLocation?.temperature2?.temperatureInCelcius , device.properties.sensorTemplateSettings.temp2) == true ?  'green' : 'red') ">
                                            </i>
                                            <span *ngIf="activeLocation?.temperature2?.temperatureInCelcius ">
                                                {{ activeLocation?.temperature2?.temperatureInCelcius
                                                ?.toLocaleString() }} °C
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="activeLocation?.temperature3?.temperatureInCelcius  || showProp|| device.properties.sensorTemplateSettings.temp3">
                                        <td class="row_opening">
                                            <i class="fas fa-fw fa-thermometer-three-quarters"
                                                style="opacity: 0.3;"></i> {{
                                            'general.temperature' | translate}} 3
                                        </td>
                                        <td>
                                            <fh-sensor-template-input name="sensorType_Temp3" [autoSelect]="false"
                                                placeholder="{{ 'general.sensorTypes' | translate}}"
                                                [sensorTemplates]="sensorTemplates"
                                                [loadingSensorTemplates]="loadingSensorTemplates" [sensorType]="1"
                                                #sensorTemplateTemp3="ngModel" [selectedAccountId]="device.accountId"
                                                [readonly]="formMode == 1"
                                                [(ngModel)]="device.properties.sensorTemplateSettings.temp3">
                                            </fh-sensor-template-input>
                                        </td>
                                        <td>
                                            <i class="fas fa-fw fa-bell eye-active "
                                                [ngClass]="activeLocation?.temperature3?.temperatureInCelcius  == undefined && device.properties.sensorTemplateSettings.temp3 == undefined ? '' : (checkSensorOnTempate(activeLocation?.temperature3?.temperatureInCelcius , device.properties.sensorTemplateSettings.temp3) == true ?  'green' : 'red') ">
                                            </i>
                                            <span *ngIf="activeLocation?.temperature3?.temperatureInCelcius ">
                                                {{ activeLocation?.temperature3?.temperatureInCelcius
                                                ?.toLocaleString() }} °C
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="activeLocation?.temperature4?.temperatureInCelcius  || showProp|| device.properties.sensorTemplateSettings.temp4">
                                        <td class="row_opening">
                                            <i class="fas fa-fw fa-thermometer-three-quarters"
                                                style="opacity: 0.3;"></i> {{
                                            'general.temperature' | translate}} 4
                                        </td>
                                        <td>
                                            <fh-sensor-template-input name="sensorType_Temp4" [autoSelect]="false"
                                                placeholder="{{ 'general.sensorTypes' | translate}}"
                                                [sensorTemplates]="sensorTemplates"
                                                [loadingSensorTemplates]="loadingSensorTemplates" [sensorType]="1"
                                                #sensorTemplateTemp4="ngModel" [selectedAccountId]="device.accountId"
                                                [readonly]="formMode == 1"
                                                [(ngModel)]="device.properties.sensorTemplateSettings.temp4">
                                            </fh-sensor-template-input>
                                        </td>
                                        <td>
                                            <i class="fas fa-fw fa-bell eye-active "
                                                [ngClass]="activeLocation?.temperature4?.temperatureInCelcius  == undefined && device.properties.sensorTemplateSettings.temp4 == undefined ? '' : (checkSensorOnTempate(activeLocation?.temperature4?.temperatureInCelcius , device.properties.sensorTemplateSettings.temp4) == true ?  'green' : 'red') ">
                                            </i>
                                            <span *ngIf="activeLocation?.temperature4?.temperatureInCelcius ">
                                                {{ activeLocation?.temperature4?.temperatureInCelcius
                                                ?.toLocaleString() }} °C
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="activeLocation?.totalAxleWeight?.totalWeightInKg || showProp|| device.properties.sensorTemplateSettings.weight1">
                                        <td class="row_opening">
                                            <i class="fas fa-fw fa-weight " style="opacity: 0.3;"></i> {{
                                            'general.weight' | translate}} 1
                                        </td>
                                        <td>
                                            <fh-sensor-template-input name="sensorType_Weight1" [autoSelect]="false"
                                                placeholder="{{ 'general.sensorTypes' | translate}}"
                                                [sensorTemplates]="sensorTemplates"
                                                [loadingSensorTemplates]="loadingSensorTemplates" [sensorType]="2"
                                                #sensorTemplateWeight1="ngModel" [selectedAccountId]="device.accountId"
                                                [readonly]="formMode == 1"
                                                [(ngModel)]="device.properties.sensorTemplateSettings.weight1">
                                            </fh-sensor-template-input>
                                        </td>
                                        <td>
                                            <i class="fas fa-fw fa-bell eye-active "
                                                [ngClass]="activeLocation?.totalAxleWeight?.totalWeightInKg == undefined && device.properties.sensorTemplateSettings.weight1 == undefined? '' : (checkSensorOnTempate(activeLocation?.totalAxleWeight?.totalWeightInKg, device.properties.sensorTemplateSettings.weight1) == true ?  'green' : 'red') ">
                                            </i>
                                            <span *ngIf="activeLocation?.totalAxleWeight?.totalWeightInKg">
                                                {{
                                                activeLocation?.totalAxleWeight?.totalWeightInKg?.toLocaleString()
                                                }} kg
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="activeLocation?.axleWeightInKg2 || showProp|| device.properties.sensorTemplateSettings.weight2">
                                        <td class="row_opening">
                                            <i class="fas fa-fw fa-weight " style="opacity: 0.3;"></i> {{
                                            'general.weight' | translate}} 2
                                        </td>
                                        <td>
                                            <fh-sensor-template-input name="sensorType_Weight2" [autoSelect]="false"
                                                placeholder="{{ 'general.sensorTypes' | translate}}"
                                                [sensorTemplates]="sensorTemplates"
                                                [loadingSensorTemplates]="loadingSensorTemplates" [sensorType]="2"
                                                #sensorTemplateWeight2="ngModel" [selectedAccountId]="device.accountId"
                                                [readonly]="formMode == 1"
                                                [(ngModel)]="device.properties.sensorTemplateSettings.weight2">
                                            </fh-sensor-template-input>
                                        </td>
                                        <td>
                                            <i class="fas fa-fw fa-bell eye-active "
                                                [ngClass]="activeLocation?.axleWeightInKg2 == undefined&& device.properties.sensorTemplateSettings.weight2 == undefined ? '' : (checkSensorOnTempate(activeLocation?.axleWeightInKg2, device.properties.sensorTemplateSettings.weight2) == true ?  'green' : 'red') ">
                                            </i>
                                            <span *ngIf="activeLocation?.axleWeightInKg2">
                                                {{ activeLocation?.axleWeightInKg2?.toLocaleString() }} kg
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="activeLocation?.humidity1?.humidityInPercent || showProp|| device.properties.sensorTemplateSettings.humidity1">
                                        <td class="row_opening">
                                            <i class="fas fa-fw fa-humidity" style="opacity: 0.3;"></i> {{
                                            'general.humidity' | translate}} 1
                                        </td>
                                        <td>
                                            <fh-sensor-template-input name="sensorType_Humidity1" [autoSelect]="false"
                                                placeholder="{{ 'general.sensorTypes' | translate}}"
                                                [sensorTemplates]="sensorTemplates"
                                                [loadingSensorTemplates]="loadingSensorTemplates" [sensorType]="3"
                                                #sensorTemplateHumidity1="ngModel"
                                                [selectedAccountId]="device.accountId" [readonly]="formMode == 1"
                                                [(ngModel)]="device.properties.sensorTemplateSettings.humidity1">
                                            </fh-sensor-template-input>
                                        </td>
                                        <td>
                                            <i class="fas fa-fw fa-bell eye-active "
                                                [ngClass]="activeLocation?.humidity1?.humidityInPercent == undefined&& device.properties.sensorTemplateSettings.humidity1 == undefined ? '' : (checkSensorOnTempate(activeLocation?.humidity1?.humidityInPercent, device.properties.sensorTemplateSettings.humidity1) == true ?  'green' : 'red') ">
                                            </i>
                                            <span *ngIf="activeLocation?.humidity1?.humidityInPercent">
                                                {{
                                                activeLocation?.humidity1?.humidityInPercent?.toLocaleString()
                                                }} %
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="activeLocation?.humidity2?.humidityInPercent || showProp|| device.properties.sensorTemplateSettings.humidity2">
                                        <td class="row_opening">
                                            <i class="fas fa-fw fa-humidity" style="opacity: 0.3;"></i> {{
                                            'general.humidity' | translate}} 2
                                        </td>
                                        <td>
                                            <fh-sensor-template-input name="sensorType_Humidity2" [autoSelect]="false"
                                                placeholder="{{ 'general.sensorTypes' | translate}}"
                                                [sensorTemplates]="sensorTemplates"
                                                [loadingSensorTemplates]="loadingSensorTemplates" [sensorType]="3"
                                                #sensorTemplateHumidity2="ngModel"
                                                [selectedAccountId]="device.accountId" [readonly]="formMode == 1"
                                                [(ngModel)]="device.properties.sensorTemplateSettings.humidity2">
                                            </fh-sensor-template-input>
                                        </td>
                                        <td>
                                            <i class="fas fa-fw fa-bell eye-active "
                                                [ngClass]="activeLocation?.humidity2?.humidityInPercent == undefined && device.properties.sensorTemplateSettings.humidity2 == undefined  ? '' : (checkSensorOnTempate(activeLocation?.humidity2?.humidityInPercent, device.properties.sensorTemplateSettings.humidity2) == true ?  'green' : 'red') ">
                                            </i>
                                            <span *ngIf="activeLocation?.humidity2?.humidityInPercent">
                                                {{
                                                activeLocation?.humidity2?.humidityInPercent?.toLocaleString()
                                                }} %
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="activeLocation?.fuel1?.fuelLevelInPercentage || showProp|| device.properties.sensorTemplateSettings.fuel1">
                                        <td class="row_opening">
                                            <i class="fas fa-fw fa-gas-pump" style="opacity: 0.3;"></i> {{
                                            'general.fuel' | translate}} 1
                                        </td>
                                        <td>
                                            <fh-sensor-template-input name="sensorType_Fuel1" [autoSelect]="false"
                                                placeholder="{{ 'general.sensorTypes' | translate}}"
                                                [sensorTemplates]="sensorTemplates"
                                                [loadingSensorTemplates]="loadingSensorTemplates" [sensorType]="4"
                                                #sensorTemplateFuel1="ngModel" [selectedAccountId]="device.accountId"
                                                [readonly]="formMode == 1"
                                                [(ngModel)]="device.properties.sensorTemplateSettings.fuel1">
                                            </fh-sensor-template-input>
                                        </td>
                                        <td>
                                            <i class="fas fa-fw fa-bell eye-active "
                                                [ngClass]="activeLocation?.fuel?.fuelLevelInPercentage == undefined && device.properties.sensorTemplateSettings.fuel1== undefined  ? '' : (checkSensorOnTempate(activeLocation?.fuel?.fuelLevelInPercentage, device.properties.sensorTemplateSettings.fuel1) == true ?  'green' : 'red') ">
                                            </i>
                                            <span *ngIf="activeLocation?.fuel?.fuelLevelInPercentage">
                                                {{ activeLocation?.fuel?.fuelLevelInPercentage?.toLocaleString() }} %
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="activeLocation?.rpmStatus?.rpm || showProp|| device.properties.sensorTemplateSettings.rpm1">
                                        <td class="row_opening">
                                            <i class="fas fa-fw fa-tire " style="opacity: 0.3;"></i> {{
                                            'general.rpm' | translate}} 1
                                        </td>
                                        <td>
                                            <fh-sensor-template-input name="sensorType_Rpm1" [autoSelect]="false"
                                                placeholder="{{ 'general.sensorTypes' | translate}}"
                                                [sensorTemplates]="sensorTemplates"
                                                [loadingSensorTemplates]="loadingSensorTemplates" [sensorType]="5"
                                                #sensorTemplateRpm1="ngModel" [selectedAccountId]="device.accountId"
                                                [readonly]="formMode == 1"
                                                [(ngModel)]="device.properties.sensorTemplateSettings.rpm1">
                                            </fh-sensor-template-input>
                                        </td>
                                        <td>
                                            <i class="fas fa-fw fa-bell eye-active "
                                                [ngClass]="activeLocation?.rpmStatus?.rpm == undefined && device.properties.sensorTemplateSettings.rpm1 == undefined  ? '' : (checkSensorOnTempate(activeLocation?.rpmStatus?.rpm, device.properties.sensorTemplateSettings.rpm1) == true ?  'green' : 'red') ">
                                            </i>
                                            <span *ngIf="activeLocation?.rpmStatus?.rpm">
                                                {{ activeLocation?.rpmStatus?.rpm?.toLocaleString() }} RPM
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <fh-save-footer [valid]="!sensorForm.invalid && device.accountId" (onSave)="onSave()"
                            [allowEdit]="permissions['Devices_Edit']" [allowDelete]=false
                            (setFormMode)="setFormMode($event)" [formMode]="formMode">
                        </fh-save-footer>
                    </form>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>