import { NgModule } from '@angular/core';
import { SafeJsonPipe } from './json.pipe';
import { PrettyJsonComponent } from './prettyjson.component';
import { PrettyJsonPipe } from './prettyjson.pipe';

@NgModule({
    declarations: [
        PrettyJsonComponent,
        PrettyJsonPipe,
        SafeJsonPipe
    ],
    exports: [
        PrettyJsonComponent,
        PrettyJsonPipe,
        SafeJsonPipe
    ]
})
export class PrettyJsonModule {

}
