import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';
import { AssetService } from '../asset/asset.service';
import { DeviceService } from '../device/device.service';
import { of } from 'rxjs/internal/observable/of';
import { timer } from 'rxjs/internal/observable/timer';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import type { Subscription } from 'rxjs';
import { LocationService } from '../locations/locations.service';
import { AssetGroupsService } from '../asset/assetGroups.service';
import { DeviceStatesItem } from 'app/models/StateObject';

// Recursively reduce sub-arrays to the specified depth
function flatten<T>(arr: T[], depth = 1): T[] {
    // If depth is 0, return the array as-is
    if (depth < 1) {
        return arr.slice();
    }

    // Otherwise, concatenate into the parent array
    return arr.reduce(function (_acc, _val) {
        return _acc.concat(Array.isArray(_val) ? flatten(_val, depth - 1) : _val);
    }, []);
};

class Asset {

}

const FleetOverviewState = [
    'Initialize',
    'LoadingMap',
    'Loaded',
] as const;

const FleetOverviewMode = [
    'Overview',
    'Live',
    'History',
] as const;

export type FleetOverviewStateCase = (typeof FleetOverviewState)[number];
export type FleetOverviewModeCase = (typeof FleetOverviewMode)[number];

@Injectable({ providedIn: 'root' })
export class FleetOverviewStoreService implements OnDestroy {
    private locationSubscription: Subscription;
    private previousLookupTimestamp: Date;

    private assetGroups = new Map();

    private readonly _assets = new BehaviorSubject<Asset[]>([]);
    private readonly _deviceState = new BehaviorSubject<Map<number, number>>(new Map());
    private readonly _lastCommunication = new BehaviorSubject<Map<number, string>>(new Map());
    private readonly _lastDeviceStates = new BehaviorSubject<DeviceStatesItem[]>([]);

    private readonly _fleetOverviewState = new BehaviorSubject<FleetOverviewStateCase>('Initialize');
    private readonly _fleetOverviewMode = new BehaviorSubject<FleetOverviewModeCase>('Overview');

    readonly assets$ = this._assets.asObservable();
    readonly deviceState$ = this._deviceState.asObservable();
    readonly lastCommunication$ = this._lastCommunication.asObservable();
    readonly lastDeviceStates$ = this._lastDeviceStates.asObservable();
    readonly fleetOverviewState$ = this._fleetOverviewState.asObservable();
    readonly fleetOverviewMode$ = this._fleetOverviewMode.asObservable();

    selectedTrip = new Subject<L.FeatureGroup<any>[]>();
    readonly selectedTrip$ = this.selectedTrip.asObservable();

    removeTrip = new Subject<L.FeatureGroup<any>[]>();
    readonly removeTrip$ = this.removeTrip.asObservable();

    hiddenAssets = new BehaviorSubject<Map<number, boolean>>(new Map());
    readonly hiddenAssets$ = this.hiddenAssets.asObservable();

    searchFilter = new BehaviorSubject<string>('');
    readonly searchFilter$ = this.searchFilter.asObservable();

    constructor(private deviceService: DeviceService, private assetGroupService: AssetGroupsService, private locationService: LocationService) {
        this.getDevices().finally(() => {
            this.deviceStateSubscription();
        });
    }

    private async deviceStateSubscription() {
        const deviceIds = flatten(Array.from(this.assetGroups.values()).map(x => x.items)).map(x => x.id);

        this.locationSubscription = timer(0, 30000).pipe(
            mergeMap(_ => this.locationService.getDeviceStates(deviceIds, null, this.previousLookupTimestamp, 0))
        ).subscribe((result) => {
            const newStates = this.deviceState;
            const newLastCommunication = this.lastCommunication;

            for (const state of result.deviceStates) {
                newStates.set(+state.id, state.calculatedDeviceState?.deviceState ?? 0);

                if (state.communicationState?.updateTimestamp !== undefined) {
                    newLastCommunication.set(+state.id, state.communicationState!.updateTimestamp);
                }
            }

            this.deviceState = newStates;
            this.lastCommunication = newLastCommunication;
            this.lastDeviceStates = result.deviceStates;

            this.previousLookupTimestamp = result.timestamp;
        });
    }

    private async getDevices() {
        const assetGroups = await this.assetGroupService
            .getAssetGroups(null, false)
            .toPromise();

        for (const assetGroup of assetGroups) {
            const name = assetGroup.displayName + ' - ' + assetGroup.companyName;
            this.assetGroups.set(assetGroup.id, { _order: name, name, items: [] });
        }

        const devices = await this.deviceService
            .getDevicesLimited(true, null, null, true, false, true)
            .toPromise();

        for (const device of devices) {
            if ((device?.asset?.name?.length ?? 0) === 0) {
                continue;
            }

            for (const id of device.asset.assetGroupIds) {
                const assetGroup = this.assetGroups.get(id)
                if (assetGroup !== undefined) {
                    assetGroup.items.push({ id: device.id, name: device.asset.name } );
                }
            }
        }
    }

    get assets(): Asset[] {
        return this._assets.getValue();
    }

    private set assets(val: Asset[]) {
        this._assets.next(val);
    }

    get deviceState(): Map<number, number> {
        return this._deviceState.getValue();
    }

    private set deviceState(val: Map<number, number>) {
        this._deviceState.next(val);
    }

    get lastCommunication(): Map<number, string> {
        return this._lastCommunication.getValue();
    }

    private set lastCommunication(val: Map<number, string>) {
        this._lastCommunication.next(val);
    }

    get lastDeviceStates(): DeviceStatesItem[] {
        return this._lastDeviceStates.getValue();
    }

    private set lastDeviceStates(val: DeviceStatesItem[]) {
        this._lastDeviceStates.next(val);
    }

    get fleetOverviewState(): FleetOverviewStateCase {
        return this._fleetOverviewState.getValue();
    }

    set fleetOverviewState(val: FleetOverviewStateCase) {
        this._fleetOverviewState.next(val);
    }

    get fleetOverviewMode(): FleetOverviewModeCase {
        return this._fleetOverviewMode.getValue();
    }

    set fleetOverviewMode(val: FleetOverviewModeCase) {
        this._fleetOverviewMode.next(val);
    }

    ngOnDestroy(): void {
        this.previousLookupTimestamp = null;
        this.locationSubscription.unsubscribe?.();
    }
}
