import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as signalR from '@microsoft/signalr';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { AuthenticationService } from '../authentication/authentication.service';
import { StorageHelper } from 'app/common/storagehelper';
import { StorageType } from 'app/common/enums';

window['moment'] = Moment;
mTZ()

@Injectable({
    providedIn: 'root'
})
export class SignalrService {
    connection: signalR.HubConnection;
    hubStatusMessage: BehaviorSubject<string>;
    hubUserStatusMessage: BehaviorSubject<string>;
    hubToast: BehaviorSubject<object>;
    progressPercentage: BehaviorSubject<object>;
    completeMessage: BehaviorSubject<object>;
    errorMessage: BehaviorSubject<object>;
    batchCreated: BehaviorSubject<string>;
    notifications: BehaviorSubject<any>;

    // Vars
    batches = [];
    userIdSubscribed: any;
    accountIdSubscribed: any;
    keepSubscribing = false;

    public notificationList = [];
    timezoneIana;

    storageType = StorageType.LocalStorage;
    notifyAsToast = false;

    constructor(private authenticationService: AuthenticationService, private storageHelper: StorageHelper) {
        this.hubStatusMessage = new BehaviorSubject<string>(null);
        this.hubUserStatusMessage = new BehaviorSubject<string>(null);
        this.hubToast = new BehaviorSubject<object>(null);
        this.progressPercentage = new BehaviorSubject<object>(null);
        this.completeMessage = new BehaviorSubject<object>(null);
        this.errorMessage = new BehaviorSubject<object>(null);
        this.batchCreated = new BehaviorSubject<string>(null);
        this.notifications = new BehaviorSubject<string>(null);

        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'notifyAsToast').subscribe((result) => {
            this.notifyAsToast = JSON.parse(result) === true;
        });
    }

    // Establish a connection to the SignalR server hub
    public initiateSignalrConnection(): Promise<any> {

        const url = window['server_variables'].AuthenticationUrl;
        return new Promise((resolve, reject) => {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(url.replace('api/', 'signalrhub'))
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: retryContext => {
                        if (retryContext.elapsedMilliseconds < 60000) {
                            // If we've been reconnecting for less than 60 seconds so far,
                            // wait 1 seconds before the next reconnect attempt.
                            return 1000;
                        } if (retryContext.elapsedMilliseconds < 3600000) {
                            // If we've been reconnecting for less than 60 minutes so far,
                            // wait between 10 seconds before the next reconnect attempt.
                            return 60000;
                        } else {
                            // If we've been reconnecting for more than 60 minutes so far, stop reconnecting.
                            return null;
                        }
                    }
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            this.setSignalrClientMethods();

            // Fix conflict with pace
            Object.defineProperty(WebSocket, 'OPEN', { value: 1, });

            this.connection
                .start()
                .then(() => {
                    console.log(`SignalR connection success! connectionId: ${this.connection.connectionId} `);
                    resolve(0);
                })
                .catch((error) => {
                    console.log(`SignalR connection error: ${error}`);
                    // reject();
                    resolve(0);
                });
        });
    }

    // This method will implement the methods defined in the ISignalrHub inteface in the API
    private setSignalrClientMethods(): void {
        this.connection.on('GetCallerStatus', (message: string) => {
            this.hubStatusMessage.next(message);
        });

        this.connection.on('GetUserStatus', (message: string) => {
            this.hubUserStatusMessage.next(message);
        });

        this.connection.on('Toast', (header: string, message: string, link: string) => {
            this.hubToast.next([header, message, link]);
            // Clear the message
            this.hubToast.next(null);
        });

        this.connection.on('Notification', (date: string, priority: string, entityType: string, entityId: string, subject: string, message: string) => {
            this.notificationList.push({ date: Moment.utc(date)['tz'](this.timezoneIana), priority: priority, entityType: entityType, entityId: entityId, subject: subject, message: message, isRead: false });
            this.notifications.next(this.notificationList);

            console.log(this.notifyAsToast);

            if (this.notifyAsToast) {
                this.hubToast.next(["Notification", subject, '/DeviceDetails/Index/' + entityId]);
                // Clear the message
                this.hubToast.next(null);
            }
        });

        this.connection.on('UpdateProgressBar', (batchId: string, percentage: number) => {

            const batchItem = this.batches.find(x => x.id === batchId);
            if (batchItem != null) {
                batchItem.percentage = percentage;
            }

            this.progressPercentage.next([batchId, percentage]);

            // Reset message so it is send only once
            this.progressPercentage.next(null);
        });

        this.connection.on('DisplayErrorMessage', (batchId: string, message: string, error: string) => {
            const batchItem = this.batches.find(x => x.id === batchId);
            if (batchItem != null) {

                setTimeout(() => {
                    this.batches.splice(batchItem, 1);
                    this.progressPercentage.next(null);
                }, 5000);

                this.errorMessage.next([batchId, message, error]);
            }

            this.errorMessage.next(null);
        });

        this.connection.on('DisplayCompleteMessage', (batchId: string, message: string) => {

            const batchItem = this.batches.find(x => x.id === batchId);
            if (batchItem != null) {

                batchItem.percentage = 100;

                setTimeout(() => {
                    this.batches.splice(batchItem, 1);
                    this.progressPercentage.next(null);
                }, 5000);

            } else {
                console.log('Batch not found any more');
            }

            this.completeMessage.next([batchId, message]);

            // Reset message so it is send only once
            this.completeMessage.next(null);
        });

        this.connection.on('BatchCreated', (batchId: string) => {
            if (batchId != null) {
                this.batches.push({ id: batchId, percentage: 0 });
            }
            this.batchCreated.next(batchId);
        });

        this.connection.on('notifyAdmin', (message: string) => {
            console.log('Connected users: ' + message);
        });
    }

    subscribeUser(userName, userId, accountId): void {
        // console.log('SIGNALR - Start listening on user ' + userId);
        if (userId.length === 0) { return; }

        const that = this;
        // console.log(this.userIdSubscribed);
        //  console.log(userId);

        if (this.userIdSubscribed &&
            this.userIdSubscribed.length > 0 &&
            this.userIdSubscribed !== userId) {
            // unsubscribe to stop to get notifications for old customer
            this.connection
                .invoke('UnsubscribeUser', userId, accountId)
                .then(() => {
                    that.userIdSubscribed = null;
                    that.accountIdSubscribed = null;
                    //  console.log('Successfully unsubscribed to signalr with user ' + userId);
                })
                .catch(error => {
                    console.log(error);
                });
        }

        // When not subscribed we need to subscribe
        if (this.userIdSubscribed !== userId || this.keepSubscribing) {
            // subscribe to start to get notifications for new customer
            this.connection
                .invoke('SubscribeUser', userName, userId, accountId)
                .then(() => {
                    that.userIdSubscribed = userId;
                    that.accountIdSubscribed = accountId;
                    //  console.log('Successfully subscribed to signalr with user ' + userId);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}
