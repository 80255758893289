<div class="ibox">
    <div class="ibox-title">
        <h5>
            <i class="fas fa-fw fa-location-arrow"></i> {{'general.devicesConnected' | translate }}
        </h5>
    </div>
    <div class="ibox-content slicker" style="min-height: 210px; position: relative">
        <ngx-loading [show]="loadingDeviceCount"></ngx-loading>

        <highcharts-chart *ngIf="chartDeviceCount" [runOutsideAngular]="true"
            style="display: block; width: 100%; height:200px" [Highcharts]="Highcharts" [options]="chartDeviceCount">
        </highcharts-chart>
    </div>
</div>