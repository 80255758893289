import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditLog } from 'app/models/auditlog.model';
import { Trailer } from 'app/models/trailer.model';
import { Trigger } from 'app/models/trigger.model';
import { AuditLogService } from 'app/services/auditlog/auditlog.service';
import { TrailerService } from 'app/services/trailer/trailer.service';
import { TriggerService } from 'app/services/triggers/triggers.service';



@Component({
    selector: 'fh-trigger-log',
    templateUrl: 'triggerLog.template.html'
})
export class TriggerLogViewComponentLogViewComponent implements OnInit {
    triggerLog: AuditLog[];
    sub;

    loading = false;

    error: any;
    success: any;

    permissionName = 'FleetManagement_Log';
    trigger: Trigger;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private triggerService: TriggerService, private route: ActivatedRoute, private router: Router) {
        this.trigger = null;
        this.triggerLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.trigger = new Trigger;
        this.trigger.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.triggerService.getTriggerById(id).subscribe(trigger => {
                this.trigger = trigger;

                if (this.trigger == null) {
                    this.router.navigate(['/System/Triggers/Overview'])
                }

                this.auditLogService.getAuditLogByTrigger(id).subscribe(deviceLog => {
                    console.log('loading done');
                    this.triggerLog = deviceLog;
                    this.loading = false;
                }, error => {
                    this.error = error;
                    this.loading = false;
                });
            });
        });
    }
}
