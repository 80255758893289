<ng-select [loading]="loadingGeofenceGroups" ngDefaultControl [searchable]="true" [clearable]="clearable"
    style="height: unset" [disabled]="loadingGeofenceGroups || (!canAddNew && geofenceGroups.length == 0)" [readonly]="readonly" 
    addTagText="{{'general.createNew' | translate}}" [addTag]="canAddNew ? createNew.bind(this) : false" placeholder="{{placeholder}} ({{geofenceGroups.length}})" 
    #input [multiple]="multiple" [(ngModel)]="value"
    [items]="geofenceGroups" bindLabel="name" bindValue="id" class="form-control"
    [ngClass]="multiple !== true ? 'table-inline' : '' " (ngModelChange)="geofenceGroupChanged()">
    <ng-template ng-label-tmp let-geofencegroup="item" let-clear="clear">
        <span><i class="fas fa-fw fa-layer-group" title="Items: {{geofencegroup.itemCount}}"></i> 
            {{ geofencegroup.displayName }} ({{geofencegroup.itemCount}})
            <span *ngIf="serverResponses[geofencegroup.displayName]" class="badge badge-danger">
                <i class="fas fa-fw fa-exclamation-triangle"></i>
            </span>
        </span>
        <span class="ng-value-icon right" (click)="clear(geofencegroup)" aria-hidden="true">&times;</span>
    </ng-template>
    <ng-template ng-option-tmp let-geofencegroup="item" let-search="searchTerm">
        <div>
            <div [ngOptionHighlight]="search">
                {{ geofencegroup.displayName }} ({{geofencegroup.itemCount}})
            </div>
            <div [ngSwitch]="true">
                <span class="badge badge-primary" tooltip="'Item added'" *ngSwitchCase="serverResponses[geofencegroup.displayName] === false">
                    <i class="fas fa-fw fa-square-check"></i> {{ 'general.created' | translate }}
                </span>
                <span class="badge badge-danger" tooltip="'Item failed'" *ngSwitchCase="serverResponses[geofencegroup.displayName]?.length > 0">
                    <i class="fas fa-fw fa-exclamation-triangle"></i> {{ serverResponses[geofencegroup.displayName] }}
                </span>
            </div>
        </div>
        <small class="text-muted">
            <span class="block-item"
                *ngFor="let geofenceGroupItem of geofencegroup.geofenceGroupItems | slice:0:(geofencegroup.showAll ? 99999999 : 5) | orderBy : 'geofenceName':false">
                {{geofenceGroupItem.geofenceName}}
            </span>

            <span class="block-item" *ngIf="geofencegroup.geofenceGroupItems?.length > 5 && !geofencegroup.showAll"
                (click)="showAll($event, geofencegroup)">
                <i class="fas fa-fw fa-layer-group"></i> {{ 'general.more' | translate}}
            </span>

            <span *ngIf="geofencegroup.geofenceGroupItems?.length == 0">{{'general.noDataFound' |
                translate}}</span>
        </small>
    </ng-template>
</ng-select>