import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import moment from 'moment';

declare var L;
declare var HeatmapOverlay;
declare var PruneCluster;
declare var PruneClusterForLeaflet;

@Injectable()
export class MapService {

    public static get LEAFLET_MAPTYPE(): string { return 'leaflet_maptype'; }

    constructor(public translateService: TranslateService) {
    }

    public getLeafletMapType(): string {
        return localStorage.getItem(MapService.LEAFLET_MAPTYPE);
    }

    public setLeafletMapType(mapType: string | undefined): void {
        if (!mapType) { return };
        localStorage.setItem(MapService.LEAFLET_MAPTYPE, mapType);
    }

    setPruneCluster(leafletView) {

        const that = this;

        leafletView.BuildLeafletClusterIcon = function (cluster) {
            const e = new L.Icon.MarkerCluster();
            e.stats = cluster.stats;
            e.population = cluster.population;
            return e;
        };

        const colors = ['#676767', '#008E43', '#971C24', '#EE9234', '#206EB4', '#000000', '#7D177B'],
            pi2 = Math.PI * 2;

        L.Icon.MarkerCluster = L.Icon.extend({
            options: {
                iconSize: new L.Point(44, 44),
                className: 'prunecluster leaflet-markercluster-icon'
            },
            createIcon: function () {
                // based on L.Icon.Canvas from shramov/leaflet-plugins (BSD licence)
                const e = document.createElement('canvas');
                this._setIconStyles(e, 'icon');
                const s = this.options.iconSize;
                e.width = s.x;
                e.height = s.y;
                // this.draw(e.getContext('2d'), s.x, s.y);
                this.draw(e.getContext('2d'), e.width, e.height);
                return e;
            },
            createShadow: function () {
                return null;
            },
            draw: function (canvas, width, height) {

                const lol = 0;

                let start = 0;
                for (let i = 0, l = colors.length; i < l; ++i) {

                    const size = this.stats[i] / this.population;


                    if (size > 0) {
                        canvas.beginPath();
                        canvas.moveTo(22, 22);
                        canvas.fillStyle = colors[i];
                        let from = start + 0.14,
                            // tslint:disable-next-line:prefer-const
                            to = start + size * pi2;

                        if (to < from) {
                            from = start;
                        }
                        canvas.arc(22, 22, 22, from, to);

                        start = start + size * pi2;
                        canvas.lineTo(22, 22);
                        canvas.fill();
                        canvas.closePath();
                    }
                }

                canvas.beginPath();
                canvas.fillStyle = 'white';
                canvas.arc(22, 22, 18, 0, Math.PI * 2);
                canvas.fill();
                canvas.closePath();

                canvas.fillStyle = '#555';
                canvas.textAlign = 'center';
                canvas.textBaseline = 'middle';
                canvas.font = 'bold 12px sans-serif';

                canvas.fillText(this.population, 22, 22, 40);
            }
        });

        leafletView.PrepareLeafletMarker = function (theMarker, data, category) {

            this.addLabel(theMarker);

            this.createPopup(theMarker, data, category);
        };

        return true;
    }

    addLabel(theMarker, label) {
        if (!label) {
            return;
        }

        const direction = 'bottom';
        const offset = L.point(0, 20);

        // theMarker.data.label
        theMarker['bindTooltip'](label, { permanent: true, direction: direction, opacity: 0.8, offset: offset });
    }

    createPopup(theMarker, data, category) {
        // parse data to icon
        theMarker.setIcon(this.createIcon());

        const markerPopup = `<div style="width:100%">
       <div style="width:300px;overflow: auto;" class="leaflet-mappopup">
       <div class="header">${data.title}</div>
       <div class="content">${this.translateService.instant('general.imei')}</div><div class="content">${data.imei}</div>
       <div class="content">${this.translateService.instant('general.lastCommunication')}</div><div class="content" title="${data.lastCommunication?.toLocaleString()}">${moment(data.lastCommunication).fromNow()}</div>
       <div class="content"><a href="/#/DeviceDetails/Index/${data.deviceId}">${this.translateService.instant('general.details')}</a></div><div class="content"></div>
       </div>`;

        if (theMarker.getPopup()) {
            theMarker.setPopupContent(markerPopup);
        } else {
            theMarker.bindPopup(markerPopup, {
                closeButton: false
            });
        }
    }

    createIcon() {
        return L.icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png'
        });
    }

    GetContextmenuItems(context) {

        const contextMenu: any = [{
            text: this.translateService.instant('general.GetNearbyPois'),
            callback: context.nearestPois
        }, {
            text: this.translateService.instant('general.GetNearbyAssets'),
            callback: context.nearestAssets
        }, {
            text: this.translateService.instant('general.CenterMap'),
            callback: context.centerMap
        }];

        contextMenu.push({
            text: this.translateService.instant('general.SaveLocation'),
            callback: context.showCoordinates
        });

        contextMenu.push('-', {
            text: this.translateService.instant('general.ZoomIn'),
            icon: 'assets/images/leaflet/zoom-in.png',
            callback: context.zoomIn
        }, {
            text: this.translateService.instant('general.ZoomOut'),
            icon: 'assets/images/leaflet/zoom-out.png',
            callback: context.zoomOut
        });

        return contextMenu;
    };
}
