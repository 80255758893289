import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode, UserContactType } from 'app/common/enums';
import { ServiceAnnouncement } from 'app/models/serviceAnnouncement.model';
import { UserContact } from 'app/models/userContact.model';
import { AccountService } from 'app/services/account/account.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { UserService } from 'app/services/users/user.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'fh-service-announcement-details',
    templateUrl: 'serviceAnnouncementDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ServiceAnnouncementDetailsComponent implements OnInit {

    @Input() serviceAnnouncement: ServiceAnnouncement;
    @Input() formMode: any = FormMode.read;

    permissions: {};

    // Datepicker
    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    ngOnInit(): void {
        // Datepicker
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'll';
        this.dpConfig.isAnimated = true;
        this.dpConfig.withTimepicker = false;
    }

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private accountService: AccountService, private userService: UserService) {

        this.permissions = this.authenticationService.permissions;
    }
}

