import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
    name: 'keys'
})
@Injectable()
export class KeysPipe implements PipeTransform {
    transform(value): any {
        const keys = [];
        // tslint:disable-next-line:forin
        for (const key in value) {
            keys.push(key);
        }
        return keys;
    }
}
