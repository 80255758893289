import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { ScheduleAssignment } from 'app/models/triggerSchedule.model';
import { NotificationsService } from 'app/modules/notification';
import { AccountService } from 'app/services/account/account.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { UserService } from 'app/services/users/user.service';

@Component({
  selector: 'fh-account-settings',
  templateUrl: 'settings.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountSettingsViewComponent implements OnInit {
  accountId;
  account;

  loading = false;
  saving = false;

  formMode = FormMode.read;

  loadingTokens: boolean;
  tokens = [];
  currentPage = 0;

  error;
  success;
  filter = '';

  loadingMapTokens: boolean;
  mapTokens = [];
  mapType = 7;
  mapToken;

  selectedScheduleId: number;
  scheduleAssignment: ScheduleAssignment;
  scheduleAssignments: ScheduleAssignment[];

  dpConfig: any;
  startDate: Date;
  endDate: Date;
  applyScheduleToTrip = false;

  constructor(private cd: ChangeDetectorRef, private userService: UserService, protected notificationsService: NotificationsService, private authenticationService: AuthenticationService, private accountService: AccountService, private route: ActivatedRoute) {
    this.scheduleAssignments = [];
    this.scheduleAssignment = new ScheduleAssignment();
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadingTokens = true;
    this.loadingMapTokens = true;

    this.route.params.subscribe(params => {
      const id = params['id'];

      this.accountId = id;
      this.scheduleAssignment.accountId = this.accountId;

      this.getAccountById(id);
    });
  }

  getAccountById(id) {
    this.loading = true;

    this.accountService.getAccountById(id).subscribe(account => {
      this.account = account;
      this.selectedScheduleId = account.defaultScheduleId;
      this.loading = false;
      this.getAuthenticationTokens();
      this.getMapTokens();
    }, error => {
      this.success = null;
      this.error = error;
      this.loading = false;
    });
  }

  getAuthenticationTokens() {
    this.loadingTokens = true;

    this.userService.getAuthTokens(this.account.adminUserId).subscribe(tokens => {
      this.tokens = tokens;
      this.loadingTokens = false;
      this.cd.markForCheck();
    }, error => {
      this.success = null;
      this.error = error;
      this.loadingTokens = false;
      this.cd.markForCheck();
    });
  }

  deleteToken(id) {
    this.loadingTokens = true;

    this.userService.deleteAuthToken(this.account.adminUserId, id).subscribe(deleteToken => {
      this.error = null;
      this.success = {
        statusText: 'Success',
        success: 'Token is successfully deleted.'
      };

      this.getAuthenticationTokens();
    }, error => {
      this.success = null;
      this.error = error;
      this.loadingTokens = false;
    });
  }

  addToken() {
    this.loadingTokens = true;

    this.userService.createAuthToken(this.account.adminUserId).subscribe(createToken => {
      this.error = null;
      this.success = {
        statusText: 'Success',
        success: 'Token is successfully added.'
      };

      this.getAuthenticationTokens();
    }, error => {
      this.success = null;
      this.error = error;
      this.loadingTokens = false;
    });
  }

  getMapTokens() {
    this.loadingMapTokens = true;

    this.accountService.getMapTokens(this.account.id).subscribe(mapTokens => {
      this.mapTokens = mapTokens;
      this.loadingMapTokens = false;
      this.cd.markForCheck();
    }, error => {
      this.success = null;
      this.error = error;
      this.loadingMapTokens = false;
      this.cd.markForCheck();
    });
  }

  deleteMapToken(id) {
    this.loadingMapTokens = true;

    this.accountService.deleteMapToken(this.account.id, id).subscribe(deleteToken => {
      this.error = null;
      this.success = {
        statusText: 'Success',
        success: 'Token is successfully deleted.'
      };

      this.getMapTokens();
    }, error => {
      this.success = null;
      this.error = error;
      this.loadingMapTokens = false;
    });
  }

  setFormMode(mode) {
    this.formMode = mode;

    if (this.formMode === FormMode.read) {
      this.getAccountById(this.accountId);
    }
  }

  addMapToken() {
    this.loadingMapTokens = true;

    this.accountService.createMapToken(this.account.id, this.mapType, this.mapToken).subscribe(createToken => {
      this.error = null;
      this.success = {
        statusText: 'Success',
        success: 'Token is successfully added.'
      };

      this.getMapTokens();
    }, error => {
      this.success = null;
      this.error = error;
      this.loadingMapTokens = false;
    });
  }
}
