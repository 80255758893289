<div class="ibox-content slick" style="position:relative;">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!account">
            <tr>
                <td colspan="2">
                    {{ 'general.noAccount' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="account">
            <ng-container *ngIf="formMode == 1">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder"
                            href="/#/ResellerDetails/Index/{{account?.resellerId}}">{{account?.resellerDescription
                            }}</a>
                    </td>
                </tr>
                <tr *ngIf="account?.parentAccountName && account?.parentAccountName != ''">
                    <td class="row_opening">
                        {{ 'general.parentAccount' | translate}}
                    </td>
                    <td class="row_content padding-readonly-correct">
                        <a class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{account?.parentAccountId}}">{{account?.parentAccountName
                            }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode != 1">
                <tr [hidden]="!permissions['Resellers_View'] && account.resellerId">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position:relative; overflow: visible">
                        <fh-reseller-input name="reseller" #resellerVar="ngModel" (onChanged)="resellerChanged($event)"
                            [tableInline]="true" required placeholder="{{ 'general.chooseReseller' | translate}}"
                            [(ngModel)]="account.resellerId">
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">
                        {{ 'general.parentAccount' | translate}}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td class="row_content" style="position:relative; overflow: visible">
                        <fh-account-input name="account" #accountVar="ngModel" (onChanged)="accountChanged($event)"
                            [tableInline]="true" [selectedResellerId]="account.resellerId" [autoSelect]=false
                            [filterEmptyAccounts]=false
                            placeholder="{{ (account.resellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                            [(ngModel)]="account.parentAccountId">
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="row_opening">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                        placeholder="{{ 'placeholder.name' | translate}}" [(ngModel)]="account.name" required
                        minlength="4" #name="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.erpCode' | translate}}
                    <span class="error" *ngIf="erpCode.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="erpCode" class="form-control table-inline"
                        placeholder="{{ 'placeholder.erpCode' | translate}}" [(ngModel)]="account.erpCode"
                        #erpCode="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.email' | translate}}
                    <span class="error" *ngIf="contactEmail.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="contactEmail" class="form-control table-inline"
                        placeholder="{{ 'placeholder.email' | translate}}" autocomplete="off"
                        [(ngModel)]="account.contactEmail" required minlength="4" #contactEmail="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.contact' | translate}}
                    <span class="error" *ngIf="(firstName.invalid || lastName.invalid ) && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; float: left"> <input type="text" [readonly]="formMode == 1" name="firstName"
                            placeholder="{{ 'placeholder.firstName' | translate}}" required
                            class="form-control table-inline" [(ngModel)]="account.firstName"
                            #firstName="ngModel"></span>
                    <span style="width:50%; float: left; padding-left: 10px"> <input type="text"
                            [readonly]="formMode == 1" name="lastName" required class="form-control table-inline"
                            placeholder="{{ 'placeholder.lastName' | translate}}" [(ngModel)]="account.lastName"
                            #lastName="ngModel"></span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.street' | translate}}
                    <span class="error" *ngIf="street.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="street" class="form-control table-inline"
                        placeholder="{{ 'placeholder.street' | translate}}" [(ngModel)]="account.street"
                        #street="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.street2' | translate}}
                    <span class="error" *ngIf="street2.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="street2" class="form-control table-inline"
                        placeholder="{{ 'placeholder.street2' | translate}}" [(ngModel)]="account.street2"
                        #street2="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.zip' | translate}} / {{ 'general.city' | translate}}
                    <span class="error" *ngIf="city.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; float: left"> <input type="text" [readonly]="formMode == 1" name="zip"
                            placeholder="{{ 'placeholder.zip' | translate}}" class="form-control table-inline"
                            [(ngModel)]="account.zip" #zip="ngModel"></span>
                    <span style="width:50%; float: left; padding-left: 10px"><input type="text"
                            [readonly]="formMode == 1" name="city" class="form-control table-inline"
                            placeholder="{{ 'placeholder.city' | translate}}" [(ngModel)]="account.city"
                            #city="ngModel"></span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.addressState' | translate}}
                    <span class="error" *ngIf="state.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="state" class="form-control table-inline"
                        placeholder="{{ 'placeholder.state' | translate}}" [(ngModel)]="account.state" #state="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.country' | translate}}
                    <span class="error" *ngIf="country.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="position:relative; overflow: visible">
                    <ng-select [loading]="loadingCountries" name="country" ngDefaultControl [searchable]="true"
                        [clearable]="true" placeholder="{{ 'general.chooseCountry' | translate}}"
                        [(ngModel)]="account.countryId" #country="ngModel" [disabled]="countries.length === 1"
                        [readonly]="formMode == 1" class="form-control table-inline" style="width:100%" required>
                        <ng-option *ngFor="let country of countries | orderBy : 'name':false" [value]="country.id">
                            {{ country.name }}</ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.language' | translate}}
                    <span class="error" *ngIf="language.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select placeholder="{{ 'general.chooseUserContactGroup' | translate}}" name="language"
                        [disabled]="formMode == 1" [(ngModel)]="account.language" required [clearable]="false"
                        placeholder="{{ 'general.chooseLanguage' | translate}}" class="form-control table-inline"
                        required #language="ngModel" style="width:100%">
                        <ng-option *ngFor="let language of languages" [value]="language.name">
                            {{language.name}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.location' | translate}}
                </td>
                <td class="row_content">
                    <span style="width:50%; float: left"> <input type="text" [readonly]="formMode == 1" name="latidude"
                            placeholder="{{ 'placeholder.latitude' | translate}}" class="form-control table-inline"
                            [(ngModel)]="account.latitude" #latidude="ngModel"></span>
                    <span style="width:50%; float: left; padding-left: 10px"><input type="text"
                            [readonly]="formMode == 1" name="longitude" class="form-control table-inline"
                            placeholder="{{ 'placeholder.longitude' | translate}}" [(ngModel)]="account.longitude"
                            #longitude="ngModel"></span>
                </td>
            </tr>
            <tr *ngIf="formMode != 2 && this.permissions['Platform_IsAdministrator']">
                <td class="row_opening">
                    {{ 'general.adminUser' | translate}}
                </td>
                <td class="padding-readonly-correct row_content">
                    <a class="secondary link_bolder"
                        href="/#/UserDetails/Index/{{account?.adminUserId}}">{{account?.adminUserName }}</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>