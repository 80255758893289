import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebhookEndpoint } from 'app/models/webhookEndpoint.model';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';

@Injectable()
export class WebhookEndpointService {
    base_url = '';
    url = '';

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService, private translateService: TranslateService) {
        this.base_url = this.authenticationService.getWebserviceURL('');
        this.url = this.authenticationService.getWebserviceURL('webhookEndpoint');
    }

    getPagingUrl() {
        return this.url + `Paging`;
    }

    getWebhookEndpointsByAccount(accountId: string): Observable<any> {
        return this.http.get(this.base_url + 'Account/' + accountId + '/WebhookEndpoints', { headers: this.authenticationService.headers })
            .map((data: any) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getWebhookEndpointById(webhookEndpointId: any): Observable<any> {
        return this.http.get(this.url + webhookEndpointId, { headers: this.authenticationService.headers })
            .map((data: any) => {
                return this.parseResponseDetails(data);
            })
            .catch(this.handleError);
    }

    deleteWebhookEndpoint(webhookEndpoint: WebhookEndpoint) {
        console.log(this.url + webhookEndpoint.id)
        return this.http.delete(this.url + webhookEndpoint.id, { headers: this.authenticationService.headers })
            .catch(this.handleError);

    }

    saveWebhookEndpoint(webhookEndpoint: WebhookEndpoint): Observable<any> {
        return this.http.post(this.url, webhookEndpoint, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    updateWebhookEndpoint(webhookEndpoint: WebhookEndpoint) {
        return this.http.put(this.url + webhookEndpoint.id, webhookEndpoint, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    parseResponse(json: any) {
        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' WebhookEndpoints.');

        const webhookEndpoints: WebhookEndpoint[] = [];

        json.forEach(item => {
            const webhookEndpoint = this.parseResponseDetails(item);
            webhookEndpoints.push(webhookEndpoint);
        });

        return webhookEndpoints;
    }

    parseResponseDetails(item) {
        const webhookEndpoint = new WebhookEndpoint();
        webhookEndpoint.id = item.id;
        webhookEndpoint.name = item.name;
        webhookEndpoint.headers = item.headers;
        webhookEndpoint.contentType = item.contentType;
        webhookEndpoint.method = item.method;
        webhookEndpoint.url = item.url;
        webhookEndpoint.timstamp = item.timstamp;
        webhookEndpoint.companyName = item.companyName;
        webhookEndpoint.accountId = item.accountId;
        webhookEndpoint.resellerId = item.resellerId;
        webhookEndpoint.resellerDescription = item.resellerDescription;
        webhookEndpoint.isEnabled = item.isEnabled;

        return webhookEndpoint;
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
