<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Settings/">{{'general.settings' | translate }}</a>
            </li>
            <li>
                <a href="/#/Settings/ClientAnnouncements/Overview">{{'general.clientAnnouncements' | translate }}</a>
            </li>
            <li class="active">{{'general.details' | translate }}</li>
        </ol>
    </div>
    <fh-settings-navigation></fh-settings-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">

                <form #userForm="ngForm">
                    <div class="ibox" style="position: relative;">
                        <ngx-loading [show]="loading || saving"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-calendar-exclamation"></i> {{'general.clientAnnouncements' |
                                translate
                                }}
                            </h5>
                        </div>
                        <fh-client-announcement-details [clientAnnouncement]="clientAnnouncement" [formMode]="formMode">
                        </fh-client-announcement-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()"
                            [allowEdit]="permissions['UserContacts_Edit']" (onDelete)="onDelete()"
                            [allowDelete]="permissions['UserContacts_Delete']" (setFormMode)="setFormMode($event)"
                            [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <div class="ibox" style="position:relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-city"></i> {{'general.info' | translate }}</h5>
                    </div>
                    <div class="ibox-content">
                        Info
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>