import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'fh-wizard',
    styleUrls: ['wizard.template.css'],
    templateUrl: 'wizard.template.html',
})
export class WizardComponent {
    @Input() step = 1;
    @Input() showHeader = false;

    @Input() canContinue: any = false;
    @Output() finish = new EventEmitter();
    @Output() stepChange = new EventEmitter();
    languageLoaded = false;

    public stepCount = 0;
    public steps = [];
    public progressWidth = 100 + '%';

    public isOnFinalStep = () => this.step === this.stepCount;
    public isOnFirstStep = () => this.step === 1;

    constructor(private translateService: TranslateService) {
        this.translateService.get('general.date').subscribe(
            data => {
                this.languageLoaded = true;
            }
        );
    }

    public addStep(stepTitle, icon, disabled, validate, padding) {
        const newSteps = this.stepCount + 1;
        this.steps.push({ id: newSteps, stepTitle, icon, disabled, validate, padding });
        this.stepCount = newSteps;
        return newSteps;
    }

    public reset() {
        this.stepChange.emit(1)
    }

    handleNext() {
        this.stepChange.emit(this.step + 1)
    }

    handlePrevious() {
        this.stepChange.emit(this.step - 1)
    }

    handleFinish() {
        this.finish.emit(this.step + 1)
    }

    checkDisabled() {
        const thestep = this.steps.filter(x => x.id === this.step)[0];

        if (thestep.validate || (thestep.validate = this.canContinue)) {
            const validateForm = thestep.validate;
            if (validateForm !== undefined && !validateForm.valid) {
                return true;
            }
        }

        return false;
    }
}
