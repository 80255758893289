import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { FormMode,  } from '../../common/enums';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { AccountService } from 'app/services/account/account.service';
import { AccountInventory } from 'app/models/account.model';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-my-account',
  templateUrl: 'myAccount.template.html'
})
export class MyAccountComponent implements OnInit {

  loading = false;
  saving = false;
  account: AccountInventory;
  formMode: FormMode = FormMode.read;
  success;
  error;

  loadingSchedules = false;
  accountId;

  permissions: {};

  constructor(private cd: ChangeDetectorRef, private accountService: AccountService, private authenticationService: AuthenticationService) {
    this.accountId = this.authenticationService.getAccountId();
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit(): void {
    this.getAccountInfo();
  }

  getAccountInfo() {
    this.loading = true;

    this.accountService.getAccountById(this.accountId).subscribe(account => {
      this.account = account;
      this.loading = false;
      this.cd.markForCheck();
    });
  }

  onSave() {
    this.saving = true;

    this.accountService.updateAccount(this.account).subscribe(result => {

      this.error = null;
      this.success = {
        statusText: 'Success',
        success: 'Account is successfully updated.'
      };

      this.saving = false;
      this.cd.markForCheck();

      this.setFormMode(FormMode.read);
    }, error => {
      this.saving = false;
      this.success = null;
      this.error = error;
      this.cd.markForCheck();
    });
  }

  setFormMode(mode) {
    this.formMode = mode;
    if (this.formMode === FormMode.read) {
      this.getAccountInfo();
    }
  }
}
