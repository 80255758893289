import { Component, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceService } from 'app/services/device/device.service';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'fh-batch-delete-history',
    templateUrl: 'deleteHistory.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchDeleteHistoryComponent {
    step: number;
    @Input() gridSelection = [];
    @Output() onFinish = new EventEmitter();
    disabled = true;
    logItem;

    assetForm = new FormGroup({
        createReports: new FormControl(false),
        checkbox: new FormControl('', Validators.required),
        dateFrom: new FormControl(moment().startOf('day').toDate(), Validators.required),
        dateTo: new FormControl(moment().endOf('day').toDate(), Validators.required),
    });

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    timezoneIana: string;

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private deviceService: DeviceService) {
        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.step = 1;
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.withTimepicker = true;
        this.dpConfig.adaptivePosition = true;
        this.dpConfig.isAnimated = true;
    }

    initFinish() {
        // Fetch all data and make sure it can be saved
        console.log(1);
    }

    onCheckOutFinish() {
        const result = {
            createReports: this.assetForm.value.createReports,
            checkbox: this.assetForm.value.checkbox,
            dateFrom: moment(this.assetForm.value.dateFrom).tz(this.timezoneIana),
            dateTo: moment(this.assetForm.value.dateTo).tz(this.timezoneIana),
        };

        this.onFinish.emit({ status: 'success', object: { ...result }, log: this.logItem })
        this.step = 1;
    }

    initForm() {
        this.step = 1;

        let activationDate = new Date(2010, 0);
        this.deviceService.getDeviceData(this.gridSelection).subscribe(devices => {
            if (devices.length === 1) {
                activationDate = new Date(devices[0].createdDate);
            }

            this.assetForm.patchValue({ dateFrom: moment(activationDate).tz(this.timezoneIana).startOf('day').toDate() });
        });

        console.log('init tab');
    }
}
