<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Accounts/Overview">{{'general.account' | translate }}</a>
            </li>
            <li *ngIf="account?.id">
                <a href="/#/AccountDetails/Index/{{account?.id}}">{{account?.name}}</a>
            </li>
            <li class="active">{{'general.dashboard' | translate }}</li>
        </ol>
    </div>
    <fh-account-details-navigation [accountId]="accountId"></fh-account-details-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0;">
        <ngx-loading [show]="loadingGeofences || loadingDevices"></ngx-loading>
        <fh-leaflet-map (mapReady)="onMapReady($event)" [geofences]="geofences" [locations]="locationData"
            [useClustering]=true [geofenceEnabled]="false" [height]="mapHeight" [allowBookmarks]="false">
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">

                <form #userForm="ngForm">
                    <div class="ibox" style="position: relative;">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-building"></i> {{'general.account' | translate }}
                            </h5>
                        </div>
                        <fh-account-details [account]="account" [formMode]="formMode" [loading]="loading || saving">
                        </fh-account-details>

                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()"
                            [allowEdit]="permissions['Accounts_Edit']" (onDelete)="onDelete()"
                            [allowDelete]="permissions['Accounts_Delete']" (setFormMode)="setFormMode($event)"
                            [formMode]="formMode">
                        </fh-save-footer>
                    </div>

                    <fh-custom-fields *ngIf="account.properties?.custom" [formMode]="formMode"
                        [(customFields)]="account.properties.custom">
                    </fh-custom-fields>
                </form>

                <div class="ibox" style="position: relative;" *ngIf="subAccounts.length > 0 || loadingSubAccounts">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-building"></i> {{'general.subAccounts' | translate }}
                        </h5>

                        <span class="pull-right">
                            <div class="filter-form hidden-sm hidden-xs">
                                <input type="text" class="form-control table-inline top-filter hidden-sm hidden-xs"
                                    [(ngModel)]="filterAccounts">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                        <ngx-loading [show]="loadingSubAccounts"></ngx-loading>
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th style="width:auto">
                                        {{ 'general.name' | translate}}
                                    </th>
                                    <th style="width:70px">
                                        {{ 'general.deviceCount' | translate}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let subAccount of subAccounts | orderBy : 'name':false | stringFilter: filterAccounts | paginate: { itemsPerPage: 10, currentPage: currentPageAccounts, id: 'accounts'}">
                                    <td><a href='/#/AccountDetails/Index/{{subAccount.id}}'>
                                            <span class='secondary link_bolder'><i
                                                    class="fas fa-fw fa-angle-right"></i></span>
                                            {{ subAccount.name }}</a>
                                    </td>
                                    <td> {{ subAccount.deviceCount }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #pAccounts="paginationApi" (pageChange)="currentPageAccounts = $event"
                            id="accounts">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous"
                                        [class.disabled]="pAccounts.isFirstPage()">
                                        <a class="page-link" (click)="pAccounts.previous()"
                                            *ngIf="!pAccounts.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="pAccounts.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of pAccounts.pages"
                                        [class.current]="pAccounts.getCurrent() === page.value">
                                        <a class="page-link" (click)="pAccounts.setCurrent(page.value)"
                                            *ngIf="pAccounts.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="pAccounts.setCurrent(page.value)"
                                            *ngIf="pAccounts.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next"
                                        [class.disabled]="pAccounts.isLastPage()">
                                        <a class="page-link" (click)="pAccounts.next()" *ngIf="!pAccounts.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="pAccounts.isLastPage()"> {{ 'grid.next' | translate
                                            }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <div class="ibox" style="position: relative;">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-users"></i> {{'general.users' | translate }}
                        </h5>

                        <span class="pull-right">
                            <div class="filter-form hidden-sm hidden-xs">
                                <input type="text" class="form-control table-inline top-filter hidden-sm hidden-xs"
                                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                        <ngx-loading [show]="loadingUsers"></ngx-loading>
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th style="width:auto">
                                        {{ 'general.name' | translate}}
                                    </th>
                                    <th style="width:200px">{{'general.account' | translate }}</th>
                                    <th style="width:90px">
                                        {{ 'general.userRole' | translate}}
                                    </th>
                                    <th style="width:70px">
                                        {{ 'general.deviceCount' | translate}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let user of users | orderBy : 'name':false | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage }">
                                    <td><a href='/#/UserDetails/Index/{{user.id}}'>
                                            <span class='secondary link_bolder'><i
                                                    class="fas fa-fw fa-angle-right"></i></span>
                                            {{ user.name }}</a>
                                    </td>
                                    <td style="width:200px"><a href='/#/AccountDetails/Index/{{ user.accountId }}'>
                                            <span class='secondary link_bolder'><i
                                                    class=" fas fa-fw fa-angle-right"></i></span>
                                            {{ user.companyName }}</a>
                                    </td>
                                    <td> {{ user.userRoleName }}</td>
                                    <td> {{ user.deviceCount }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <fh-group-overview name="{{'general.assetGroups' | translate }}" [loading]="loadingGroups"
                    [showCount]=true [showAccount]=false [groups]="assetGroups" [linkUrl]="assetGroupLink">
                </fh-group-overview>
            </div>
            <div class="col-lg-6">

                <div class="ibox" *ngIf="showMapOnSide">
                    <ngx-loading [show]="loadingGeofences"></ngx-loading>
                    <fh-leaflet-map (mapReady)="onMapReady($event)" [geofences]="geofences" [locations]="locationData"
                        [useClustering]=true [geofenceEnabled]="false" [height]="mapHeight" [allowBookmarks]="false">
                    </fh-leaflet-map>
                </div>

                <div class="row7">
                    <div class="col-lg-4 col-md-12">
                        <div class="ibox hand" [routerLink]="['/Devices/Overview/']"
                            [queryParams]="{companyName: account.linkName}">
                            <div class="ibox-title">
                                <h5>
                                    <i class="fas fa-fw fa-microchip"></i> {{'general.devices' | translate }}
                                </h5>
                            </div>
                            <div class="ibox-content ibox-kpi" style="position: relative;">
                                <ngx-loading [show]="loadingKpi"></ngx-loading>
                                <h2 class="margin-bottom font-bold">{{activeDevices?.toLocaleString()}}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="ibox hand" [routerLink]="['/Drivers/Overview/']"
                            [queryParams]="{companyName: account.linkName}">
                            <div class="ibox-title">
                                <h5>
                                    <i class="fa fa-fw fa-id-card-alt"></i> {{'general.drivers' | translate }}
                                </h5>
                            </div>
                            <div class="ibox-content ibox-kpi" style="position: relative;">
                                <ngx-loading [show]="loadingKpi"></ngx-loading>
                                <h2 class="margin-bottom font-bold">{{activeDrivers?.toLocaleString()}}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="ibox hand" [routerLink]="['/Geofences/Overview/']"
                            [queryParams]="{companyName: account.linkName}">
                            <div class="ibox-title">
                                <h5>
                                    <i class="fa fa-fw fa-draw-polygon"></i> {{'general.geofences' | translate }}
                                </h5>
                            </div>
                            <div class="ibox-content ibox-kpi" style="position: relative;">
                                <ngx-loading [show]="loadingKpi"></ngx-loading>
                                <h2 class="margin-bottom font-bold">{{activeGeofences?.toLocaleString()}}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ibox" *ngIf="showUtilization && !hideUtilization">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-engine"></i> {{'general.utilization' | translate }}
                        </h5>
                        <span class="pull-right"
                            title="{{daterangepickerModel[0].toLocaleString() }} - {{daterangepickerModel[1].toLocaleString()}}">
                            {{daterangepickerModel[0] | amDateFormat: 'll' }} - {{daterangepickerModel[1] |
                            amDateFormat: 'll'}}
                        </span>
                    </div>
                    <div class="ibox-content" style="position: relative; padding: 2px;">
                        <ngx-loading [show]="loadingUtilization"></ngx-loading>
                        <div class="form-group input-group" style="margin-bottom: 0px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-calendar"></i>
                            </span>
                            <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker" type="text"
                                [bsConfig]="dpConfig" class="form-control" bsDaterangepicker
                                (onHidden)="dateChanged('hidden')" [(ngModel)]="daterangepickerModel">
                        </div>
                    </div>

                    <div class="ibox-content slick" style="position: relative;">
                        <ngx-loading [show]="loadingUtilization"></ngx-loading>
                        <div style="padding: 10px" *ngIf="!accountUtilization && loadingUtilization"> {{
                            ('general.loadingData') | translate }}
                        </div>
                        <div *ngIf="accountUtilization">
                            <div class="">
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ accountUtilization?.assetCountWithTrips ?
                                            accountUtilization?.assetCountWithTrips : 0 }} / {{
                                            accountUtilization?.assetCount ?
                                            accountUtilization?.assetCount : 0 }}
                                        </h3>
                                        <div>{{'general.assetCount' | translate }} / {{'general.active' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ accountUtilization?.totalDistance ?
                                            accountUtilization?.totalDistance.toLocaleString() : 0 }} km
                                        </h3>
                                        <div>{{'general.distance' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{
                                            accountUtilization?.tripCount ?
                                            accountUtilization?.tripCount?.toLocaleString() : 0 }}
                                        </h3>
                                        <div>{{'general.trips' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ accountUtilization?.totalDuration ?
                                            actualRoundSeconds(accountUtilization?.totalDuration) : 0 }}
                                        </h3>
                                        <div>{{'general.duration' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ accountUtilization?.totalIdling ?
                                            actualRoundSeconds(accountUtilization?.totalIdling) : 0 }}
                                        </h3>
                                        <div>{{'general.idling' | translate }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{actualRound(((accountUtilization?.utilization /
                                            accountUtilization?.assetCount)),2)?.toLocaleString() }} %
                                        </h3>
                                        <div>{{'general.utilization' | translate }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-ruler"></i> {{'general.distance' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height:214px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart *ngIf="chartDistance" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:214px" [Highcharts]="Highcharts"
                            [options]="chartDistance">
                        </highcharts-chart>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-car"></i> {{'general.communicatingAssets' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height:214px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart *ngIf="chartActiveDevices" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:214px" [Highcharts]="Highcharts"
                            [options]="chartActiveDevices">
                        </highcharts-chart>
                    </div>
                </div>

                <div class="ibox" *ngIf="permissions['Platform_IsReseller']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-comment-alt-dots"></i> {{'general.locationCount' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height:214px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart *ngIf="chartLocationCount" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:214px" [Highcharts]="Highcharts"
                            [options]="chartLocationCount">
                        </highcharts-chart>
                    </div>
                </div>

                <fh-devices-connected [loadingDeviceCount]="loadingDeviceCount" [data]="deviceCountData">
                </fh-devices-connected>

                <fh-schedule-assignments [accountId]="account?.id" [selectedAccountId]="accountId"
                    [deletePermission]="'Accounts_Edit'">
                </fh-schedule-assignments>

                <fh-tagging [objectId]="account?.id" [objectType]="tagType">
                </fh-tagging>

            </div>
        </div>
    </div>
</fh-permission-handler>