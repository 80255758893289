import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, NgModule, OnChanges, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { ScheduleAssignment } from 'app/models/triggerSchedule.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

const noop = () => {
};

// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { ConfirmationModalComponent } from './confirmationModal.component';

window['moment'] = Moment;
mTZ()
@Component({
    selector: 'fh-schedule-assignment-input',
    templateUrl: 'scheduleAssignmentModal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ScheduleAssignmentModalComponent }
    ]
})
export class ScheduleAssignmentModalComponent {
    @Input() placeholder: string;
    @Input() autoSelect = true;
    @Input() selectedAccountId: any;
    @Input() formMode = FormMode.read;
    @Input() scheduleAssignment: ScheduleAssignment;
    @Input() tableInline;
    @Output() onInsertEmitter = new EventEmitter();

    private _value: any[];
    private _onChange: (_: any) => void = noop;

    loading: false;
    saving: false;

    dpConfig: any;
    startDate: Date = null;
    endDate: Date = null;
    applyScheduleToTrip = false;

    selectedScheduleId: number;

    constructor(@Inject(ConfirmationModalComponent) private parent: ConfirmationModalComponent, private authenticationService: AuthenticationService) {
        this.dpConfig = getDefaultDpConfig(Moment, this.authenticationService);

        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'll';
        this.dpConfig.isAnimated = true;
        this.dpConfig.withTimepicker = false;

        this.scheduleAssignment = new ScheduleAssignment();

        if (this.scheduleAssignment.triggerScheduleId === null || isNaN(this.scheduleAssignment.triggerScheduleId)) {
            this.parent.invalid = true;
        }
    }

    setFormMode(mode) {
        this.formMode = mode;
    }

    selectedScheduleChanged(event) {
        this.scheduleAssignment.triggerScheduleId = event;

        if (this.scheduleAssignment.triggerScheduleId === null || isNaN(this.scheduleAssignment.triggerScheduleId)) {
            this.parent.invalid = true;
        } else {
            this.parent.invalid = false;
        }
    }

    onInsert() {
        this.onInsertEmitter.emit();
    }
}
