<div class="ibox-tools" dropdown>
    <ng-content>
    </ng-content>

    <a dropdownToggle>
        <i class="fa fa-fw fa-wrench"></i>
    </a>
    <ul class="dropdown-menu dropdown-user" style="width: 150px;" *dropdownMenu>
        <li>
            <a (click)="chartAction('exportToPdf')"><i class="fas fa-fw fa-file-pdf" style="font-size: 1.3em;"></i>
                Export to pdf</a>
        </li>
        <li>
            <a (click)="chartAction('exportToJpg')"><i class="fas fa-fw fa-file-image" style="font-size: 1.3em;"></i>
                Export to jpg</a>
        </li>
        <li>
            <a (click)="chartAction('exportToSvg')"><i class="fas fa-fw fa-file-signature"
                    style="font-size: 1.3em;"></i> Export to svg</a>
        </li>
        <li>
            <a (click)="chartAction('fullscreen')"><i class="fas fa-fw fa-arrows-alt" style="font-size: 1.3em;"></i>
                Fullscreen</a>
        </li>
    </ul>
</div>
<div style="display:none">
    <highcharts-chart *ngIf="initChart" style="background-color: #fff; display: block; width: 600px;height:400px"
        [Highcharts]="Highcharts" (chartInstance)="logChartInstance($event)" [options]="chartObject">
    </highcharts-chart>
</div>