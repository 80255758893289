import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { AuditLogService } from 'app/services/auditlog/auditlog.service';
import { AuditLog } from 'app/models/auditlog.model';
// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';

window['moment'] = Moment;
mTZ()

@Component({
    selector: 'fh-audit-log-details',
    templateUrl: 'auditLogDetails.template.html'
})
export class AuditLogDetailsViewComponent implements OnInit {
    permissions: {};
    loading = false;
    error;
    auditLog: AuditLog;
    sub: any;

    permissionName = 'FleetManagement_Audit';

    constructor(private router: Router, private cd: ChangeDetectorRef, private route: ActivatedRoute, private authentication: AuthenticationService, private auditLogService: AuditLogService) {
        this.permissions = this.authentication.permissions;
    }

    ngOnInit() {

        this.loading = true;
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.getAuditLogDetails(id);
        });
    }

    getAuditLogDetails(id = 0) {
        this.loading = true;
        this.cd.markForCheck();

        if (id === 0) {
            id = this.auditLog.id;
        }

        this.auditLogService.getAuditLogById(id.toString()).subscribe(auditLog => {
            this.auditLog = auditLog;
            this.loading = false;
            this.cd.markForCheck();
        }, error => {
            this.error = error;
            this.error.statusText = 'Error fetching Audit Details';
            this.cd.markForCheck();

            setTimeout(() => {
                this.router.navigate(['/System/AuditLog/Overview']);
            }, 3000);
        });
    }
}
