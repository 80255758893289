<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Resellers/Overview">{{'general.resellers' | translate }}</a>
            </li>
            <li>
                <a href="/#/ResellerDetails/Index/{{reseller?.id}}">{{reseller?.resellerDescription}}</a>
            </li>
            <li class="active">{{'menu.log' | translate }}</li>
        </ol>

        <span style="margin-left: auto;" class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix hidden-sm hidden-xs" title="Help"
                href="https://help.360locate.com/knowledge-base/fleet-management/auditing/" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="hidden-sm hidden-xs">{{'general.needHelp' |
                    translate }}</span></a>
        </span>
    </div>
    <fh-reseller-details-navigation [resellerId]="resellerId"></fh-reseller-details-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>

            <fh-logger [logItems]="resellerLog" [loading]="loading"></fh-logger>
        </div>
    </div>
</div>