import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { Project } from 'app/models/project.model';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-project-details',
    templateUrl: 'projectDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProjectDetailsComponent implements OnInit {

    @Input() project: Project;
    @Input() loading = false;
    @Input() formMode: any = FormMode.read;
    @Output() onAccountChanged = new EventEmitter();
    loadingAccounts = false;
    selectedResellerId;

    // Datepicker
    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    accountPlatforms = [];
    accounts = [];
    permissions: {};

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
        this.permissions = this.authenticationService.permissions;
    }

    ngOnInit(): void {

        // Datepicker
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.dpConfig.withTimepicker = true;

        this.loadingAccounts = true;
    }

    resellerChanged(resellerId) {
        this.project.accountId = null;

        this.selectedResellerId = resellerId;
    }

    accountChanged(accountId) {
        this.onAccountChanged.emit(this.project.accountId);
    }
}

