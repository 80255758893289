import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { Warehouse } from 'app/models/warehouse.model';
import { EntityType } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { WaslService } from 'app/services/wasl/wasl.service';
// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { WarehouseService } from '../../services/warehouse/warehouse.service';
import { Subscription } from 'rxjs';
import { timer } from 'rxjs/internal/observable/timer';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';
import { Geofence } from 'app/models/geofence.model';
import { GeofenceService } from 'app/services/geofence/geofence.service';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { roundAsNumber } from 'app/common/globals';

@Component({
    selector: 'fh-fh-warehouse-details',
    templateUrl: 'warehouseDetails.template.html'
})
export class WarehousesDetailsViewComponent implements OnInit, OnDestroy {
    @ViewChild('confirmModal', { static: false }) confirmModal: ConfirmationModalComponent;
    @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;
    geofence: Geofence;

    sub: any;
    warehouse: Warehouse = new Warehouse();
    warehouseAzure: Warehouse = new Warehouse();

    tagType = EntityType.Warehouse.valueOf();
    formMode = FormMode.read;

    loading = false;
    saving = false;
    loadingZoneData = false;
    displayAsList = false;
    allowEdit = true;

    error: any;
    success: any;

    selectedAction;

    permissions: {};

    allowDelete = true;
    isSpinning = false;

    warehouseAssignments = [];
    zoneData = [];
    sortedResult = [];
    previousLookupDisplay;

    permissionName = 'Warehouses_View';
    locationSubscription: Subscription;
    timezoneIana: string;
    warehouseId: any;

    constructor(private router: Router, private cd: ChangeDetectorRef, private geofenceService: GeofenceService, private authentication: AuthenticationService, private warehouseService: WarehouseService, private route: ActivatedRoute) {
        this.permissions = this.authentication.permissions;
        this.timezoneIana = this.authentication.getTimeZoneIana();
    }

    ngOnInit() {

        this.loading = true;
        this.loadingZoneData = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.warehouseId = id;
            this.warehouseService.getWarehouseById(id).subscribe(warehouse => {
                this.warehouse = warehouse;

                this.drawGeofence();

                if (this.locationSubscription !== undefined) {
                    this.locationSubscription.unsubscribe();
                }

                // Get warehouse from azure
                this.warehouseService.getWarehouseByRowKey(this.warehouse.referenceId).subscribe(warehouseAzure => {
                    this.warehouseAzure = warehouseAzure;
                }, error => {
                    this.error = error;
                    this.error.statusText = 'Error fetching warehouse from azure';

                    this.cd.markForCheck();
                });

                // Get sensors from azure
                this.getSensorData();

                this.loading = false;
            }, error => {
                this.error = error;
                this.error.statusText = 'Error fetching warehouse';

                this.cd.markForCheck();

                setTimeout(() => {
                    this.router.navigate(['/Warehouses/Overview']);
                }, 3000);
            });
        });
    }

    ngOnDestroy(): void {
        if (this.locationSubscription !== undefined) {
            this.locationSubscription.unsubscribe();
        }
    }

    refresh() {
        this.getSensorData();
    }

    getSensorData() {
        this.loadingZoneData = true;

        this.locationSubscription = timer(0, 60000).pipe(
            mergeMap(() => {
                // Start the spinner
                this.isSpinning = true;
                this.cd.markForCheck();

                return this.warehouseService.getWarehouseLatestAvgPerZoneByReference(this.warehouse.referenceId);
            })
        ).subscribe(result => {

            this.loadingZoneData = false;
            this.previousLookupDisplay = Moment.utc(result.timestamp)['tz'](this.timezoneIana);

            // Stop the spinner
            setTimeout(() => { this.isSpinning = false; this.cd.markForCheck(); }, 500);

            result.forEach(item => {

                const start = Moment(item.maxTimestamp);
                const end = Moment();

                const duration = Moment.duration(end.diff(start));
                const mins = duration.asMinutes();

                item.markerColor = mins > 300 ? 'red' : (item.markerColor = mins > 30 ? 'orange' : 'green');
            });

            this.zoneData = result;

        }, error => {
            if (this.locationSubscription !== undefined) {
                this.locationSubscription.unsubscribe();
            }
            this.loadingZoneData = false;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    onSaveShape(layer) {
        this.geofenceService.parseLayer(layer, this.geofence);
        this.cd.markForCheck();
    }

    // Form
    onDelete() {
        this.loading = true;

        this.warehouseService.deleteWarehouse(this.warehouse.id).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Warehouse is successfully deleted.'
            };

            setTimeout(() => {
                this.router.navigate(['/Warehouses/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    onSave() {
        this.saving = true;

        this.warehouseService.updateWarehouse(this.warehouse, this.geofence.geoJson).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Warehouses is successfully updated.'
            };

            this.saving = false;
            this.cd.markForCheck();

            this.setFormMode(FormMode.read);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;

            this.getWarehousesInfo();
            this.leafletMapComponent.removeDrawControls();

            this.cd.markForCheck();
        } else {
            this.leafletMapComponent.addDrawControls();
        }
    }

    getWarehousesInfo() {
        if (this.loading !== true) {
            this.loading = true;
        }

        this.warehouseService.getWarehouseById(this.warehouse.id).subscribe(warehouse => {
            this.warehouse = warehouse;
            this.drawGeofence();
            this.loading = false;
        });
    }

    drawGeofence() {
        if (this.warehouse.geoJson) {
            this.geofence = new Geofence();
            this.geofence.name = this.warehouse.name;
            this.geofence.radius = 200;

            if (this.warehouse.geoJson) {
                this.geofence.geoJson = this.warehouse.geoJson;
            }
        }
    }

    actualRound(value, decimals) {
        return roundAsNumber(value, decimals);
    }
}
