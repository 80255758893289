<div class="ibox-content slick" style="position:relative;">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!(trigger && trigger.id) && formMode == 1">
            <tr>
                <td colspan="3">
                    {{ 'general.noDataFound' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="(trigger && trigger.id) || formMode != 1">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                    </td>
                    <td colspan="2" class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder"
                            href="/#/ResellerDetails/Index/{{trigger?.resellerId}}">{{trigger?.resellerDescription
                            }}</a>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                    </td>
                    <td colspan="2" class="row_content padding-readonly-correct">
                        <a class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{trigger?.accountId}}">{{trigger?.companyName }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td colspan="2" class="row_content" style="position:relative; overflow: visible">
                        <fh-reseller-input name="reseller" #resellerVar="ngModel" (onChanged)="resellerChanged($event)"
                            required placeholder="{{ 'general.chooseReseller' | translate}}" [tableInline]="true"
                            [(ngModel)]="selectedResellerId">
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && trigger.accountId">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td colspan="2" class="row_content" style="position:relative; overflow: visible">
                        <fh-account-input name="account" #accountVar="ngModel" (onChanged)="accountChanged($event)"
                            required [selectedResellerId]="selectedResellerId" [tableInline]="true"
                            placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                            [(ngModel)]="trigger.accountId">
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <!-- name -->
            <tr>
                <td class="row_opening">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                        placeholder="{{ 'placeholder.triggerName' | translate}}" [(ngModel)]="trigger.name" required
                        minlength="3" #name="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.type' | translate}}
                    <span class="error" *ngIf="triggerType.invalid && formMode != 1">*</span>
                    / {{ 'trigger.priority' | translate}}
                    <span class="error" *ngIf="priorityVar.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" class="row_content" style="overflow: visible">
                    <span style="width:50%; float:left">
                        <ng-select class="form-control table-inline" required #triggerType="ngModel" name="triggerType"
                            [clearable]="false" [disabled]="formMode == 1" [searchable]="false"
                            (change)="changeTriggerType($event)" placeholder="{{ 'general.triggerType' | translate }}"
                            [(ngModel)]="trigger.triggerType" style="width:100%">
                            <ng-option [value]=1> {{ 'enums.triggerType.1' | translate}} </ng-option>
                            <ng-option [value]=2> {{ 'enums.triggerType.2' | translate}} </ng-option>
                        </ng-select>
                    </span>
                    <span style="width:50%; float:left; padding-left: 10px">
                        <ng-select name=" priority" ngDefaultControl [searchable]="false" [clearable]="false"
                            [disabled]="formMode == 1" placeholder="{{ 'trigger.priority' | translate}}"
                            [(ngModel)]="trigger.priority" #priorityVar="ngModel" class="form-control table-inline"
                            style="width:100%" required>
                            <ng-option [value]="0"> {{ 'enums.priority.0' | translate }}</ng-option>
                            <ng-option [value]="1"> {{ 'enums.priority.1' | translate }}</ng-option>
                            <ng-option [value]="2"> {{ 'enums.priority.2' | translate }}</ng-option>
                            <ng-option [value]="3"> {{ 'enums.priority.3' | translate }}</ng-option>
                        </ng-select>
                    </span>
                </td>
            </tr>
            <tr *ngIf="trigger.triggerType == 2">
                <td class="row_opening">
                    {{ 'general.debounce' | translate}}
                    <span class="error" *ngIf="debounce.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" class="row_content" style="overflow: visible">
                    <span style="width:10%; float:left">
                        <div class="fa_checkbox">
                            <input type="checkbox" [checked]="true" [disabled]="formMode == 1" id="useDebounce"
                                (change)="checkedChanged($event)" name="useDebounce"
                                [(ngModel)]="trigger.hasDebounce" />
                            <label for="useDebounce">
                            </label>
                        </div>
                    </span>
                    <span style="width:40%; float:left">
                        <ng-select class="form-control table-inline" name="debounceUnit" ngDefaultControl
                            [searchable]="false" [clearable]="false" [disabled]="formMode == 1 || !trigger.hasDebounce"
                            [(ngModel)]="debounceTimeUnitSelected" style="width:100%"
                            (change)="debounceTimeUnitChanged()">
                            <ng-option *ngFor="let debounceTimeUnit of debounceTimeUnits"
                                [value]="debounceTimeUnit.value">
                                {{debounceTimeUnit.name | translate}}
                            </ng-option>
                        </ng-select>
                    </span>
                    <span style="width:50%; float:left; padding-left: 10px;">
                        <input type="number" [disabled]="formMode == 1 || !trigger.hasDebounce" name="debounceTime"
                            class="form-control table-inline" placeholder="{{ 'placeholder.debounce' | translate}}"
                            [(ngModel)]="debounceTime" [required]="trigger.hasDebounce" #debounce="ngModel"
                            (change)="debounceChanged($event)" />
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.assetGroups' | translate}}
                    <span class="error" *ngIf="assetGroupVar.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" class="row_content" style="overflow: visible">
                    <fh-asset-group-input name="assetGroups" #assetGroupVar="ngModel" required [activeOnly]="true"
                        [selectedAccountId]="trigger.accountId" [readonly]="formMode == 1"
                        placeholder="{{ (trigger.accountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                        [(ngModel)]="trigger.assetGroups">
                    </fh-asset-group-input>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.isEnabled' | translate}}
                </td>
                <td colspan="2" class="row_content">
                    <div class="fa_checkbox">
                        <input [checked]="true" [disabled]="formMode == 1" id="isEnabled" type="checkbox"
                            name="isEnabled" [(ngModel)]="trigger.isEnabled" />
                        <label for="isEnabled">
                        </label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>