import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BatchAction, FormMode } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { BatchService } from 'app/services/batch/batch.service';
import { Batch, BatchJob } from 'app/models/batch.model';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
window['moment'] = Moment;
mTZ()
@Component({
    selector: 'fh-batch-details',
    templateUrl: 'batchDetails.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchDetailsViewComponent implements OnInit {
    loading: boolean;
    sub: any;
    batchJob: BatchJob;
    batchActionType: BatchAction;

    error;
    success;

    filter;

    // Pagination
    currentPage = 1;

    permissionName = 'FleetManagement_Jobs';

    timezoneIana: string;

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private route: ActivatedRoute, private batchService: BatchService) {

        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        this.batchJob = new BatchJob();
        this.batchJob.jsonObject = {};
        this.batchJob.items = [];
    }

    ngOnInit() {

        this.loading = true;
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.batchService.getBatchById(id).subscribe(res => {
                this.batchJob = res;
                if (res.jsonObject) {
                    this.batchJob.jsonObject = JSON.parse(res.jsonObject);
                }

                this.batchActionType = res.batchActionType;

                this.batchJob.jobRequested = Moment.utc(this.batchJob.jobRequested)['tz'](this.timezoneIana);
                this.batchJob.jobStarted = Moment.utc(this.batchJob.jobStarted)['tz'](this.timezoneIana);
                this.batchJob.jobCompleted = Moment.utc(this.batchJob.jobCompleted)['tz'](this.timezoneIana);

                this.loading = false;
                this.cd.markForCheck();
            });
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });

    }

    // Pagination
    setPage(pageNo: number): void {
        this.currentPage = pageNo;
    }

    pageChanged(event: any): void {
        console.log('Page changed to: ' + event.page);
        console.log('Number items per page: ' + event.itemsPerPage);
    }
}
