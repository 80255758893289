<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li class="active">{{'general.shared' | translate}}</li>
        </ol>
    </div>
</div>

<fh-sharing [accountId]="accountId"></fh-sharing>