import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Driver } from 'app/models/driver.model';
import { DriverService } from 'app/services/driver/driver.service';

// Moment timezone

@Component({
    selector: 'fh-driver-trips',
    templateUrl: 'trips.template.html'
})
export class DriverTripsViewComponent implements OnInit {
    sub;
    loading = false;

    error: any;
    warning: any;
    success: any;

    driver: Driver;
    driverId: any;

    constructor(private cd: ChangeDetectorRef, private driverService: DriverService, private route: ActivatedRoute, private router: Router) {
        this.driver = null;
    }

    ngOnInit() {
        this.loading = true;
        this.driver = new Driver;
        this.driver.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.driverId = id;
            this.driverService.getDriverById(id).subscribe(driver => {
                this.driver = driver;


                this.loading = false;
                if (this.driver == null) {
                    this.router.navigate(['/Drivers/Overview'])
                }
            });
        });
    }
}
