import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';

import { GridBase360Directive } from 'app/common/360Grid.base';
import { getDefaultDpConfig, getGridButtons, getGridLanguages } from 'app/common/gridhelper';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

import { CarSharingService } from 'app/services/carsharing/carsharing.service';
import { StorageHelper } from 'app/common/storagehelper';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';

window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-carsharing',
    templateUrl: 'dashboard.component.html',
})
export class CarSharingViewComponent extends GridBase360Directive implements OnDestroy {
    loading = false;

    timezoneIana: string;
    token: string;

    languageLoaded = false;
    episodesLoaded = false;

    permissionName = 'Modules_HasPrivateFleet';
    constructorName = 'CarSharingViewComponent';

    error;
    success;

    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];

    constructor(private carsharingService: CarSharingService, private translateService: TranslateService, private authenticationService: AuthenticationService, private deviceTypeService: DeviceTypeService, private cd: ChangeDetectorRef, protected storageHelper: StorageHelper) {
        super(storageHelper);

        this.timezoneIana = authenticationService.getTimeZoneIana();
        this.token = authenticationService.getAuthToken();

        this.daterangepickerModel = [
            Moment().subtract(7, 'days').startOf('day').toDate(),
            Moment().add(0, 'days').endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

        // Get all the date for dropdown boxes
        this.translateService.get('general.date').subscribe(
            _ => {
                this.languageLoaded = true;
                this.loading = false;
                this.cd.markForCheck();

                this.initGrid();
            },
            err => {
                this.error = err;
                this.languageLoaded = true;
                this.loading = false;
                this.cd.markForCheck();
            });
    }

    initGrid(): void {
        const that = this;

        this.columns = [
            {
                name: 'plusButton',
                className: 'dt-control noVis',
                orderable: false,
                data: null,
                defaultContent: '',
            },
            {
                name: 'garageName',
                data: 'garageName',
                title: this.translateService.instant('general.carsharingLocations'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            // {
            //     name: 'groupName',
            //     data: 'groupName',
            //     title: 'Group',
            //     visible: true
            // },
            {
                name: 'assetName',
                data: 'assetName',
                type: 'string',
                title: this.translateService.instant('general.assetName'),
                defaultContent: '-',
            },
            {
                name: 'assetModel',
                data: 'assetModel',
                type: 'string',
                title: this.translateService.instant('general.assetModel'),
                defaultContent: '-',
            },
            {
                name: 'utilization',
                data: 'utilization',
                type: 'num',
                title: this.translateService.instant('general.carsharingUtilization'),
                render: function (data, type, row) {
                    if (type === 'display') {
                        return (data ?? 0) + '%';
                    }

                    return data;
                },
            },
            {
                name: 'available',
                data: 'available',
                type: 'num',
                title: this.translateService.instant('general.carsharingAvailable'),
                visible: true,
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            {
                name: 'selfDrive',
                data: 'selfDrive',
                type: 'num',
                title: this.translateService.instant('general.carsharingSelfdrive'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            // {
            //     name: 'chauffeur',
            //     data: 'chauffeur',
            //     title: 'Chauffeur',
            //     render: function (data, type, row) {
            //         return data ?? 0;
            //     },
            // },
            // {
            //     name: 'interlocationTransfer',
            //     data: 'interlocationTransfer',
            //     title: 'Interlocation Transfer',
            //     render: function (data, type, row) {
            //         return data ?? 0;
            //     },
            // },
            // {
            //     name: 'fueling',
            //     data: 'fueling',
            //     title: 'Fueling',
            //     render: function (data, type, row) {
            //         return data ?? 0;
            //     },
            // },
            // {
            //     name: 'carWash',
            //     data: 'carWash',
            //     title: 'Car Wash',
            //     render: function (data, type, row) {
            //         return data ?? 0;
            //     },
            // },
            {
                name: 'garageRepair',
                data: 'garageRepair',
                type: 'num',
                title: this.translateService.instant('general.carsharingRepair'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
                visible: true,
            },
            {
                name: 'unknown',
                data: 'unknown',
                type: 'num',
                title: this.translateService.instant('general.carsharingUnknown'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
            {
                name: 'total',
                data: 'total',
                type: 'num',
                title: this.translateService.instant('general.carsharingTotal'),
                render: function (data, type, row) {
                    return data ?? 0;
                },
            },
        ];

        // tslint:disable
        const exportAction = function (_e, dt, button, config) {
            const self = this;
            const oldStart = dt.settings()[0]._iDisplayStart;

            dt.one('preXhr', function (e, s, data) {
                // Set the limit to -less
                data.start = 0;
                data.length = 2147483647;
                data.isExport = true;

                dt.one('preDraw', function (e, settings) {
                    const tableRows = self.rows().data().toArray();

                    let rows = [];
                    for (const item of tableRows) {
                        rows.push(item);
                        rows = rows.concat(item.child);
                    }
    
                    self.clear();
                    self.rows.add(rows);

                    // Call the original action function
                    if (button[0].className.indexOf('buttons-excel') >= 0) {
                        $.fn.dataTable.ext['buttons']['excelHtml5'].action.call(self, e, dt, button, config);
                    } else if (button[0].className.indexOf('buttons-csv') >= 0) {
                        $.fn.dataTable.ext['buttons']['csvHtml5'].action.call(self, e, dt, button, config);
                    } else if (button[0].className.indexOf('buttons-pdf') >= 0) {
                        $.fn.dataTable.ext['buttons']['pdfHtml5'].action.call(self, e, dt, button, config);
                    } else if (button[0].className.indexOf('buttons-print') >= 0) {
                        $.fn.dataTable.ext['buttons']['print'].action(e, dt, button, config);
                    }

                    dt.one('preXhr', function (e, s, data) {
                        // DataTables thinks the first item displayed is index 0, but we're not drawing that.
                        // Set the property to what it was before exporting.
                        settings._iDisplayStart = oldStart;
                        data.start = oldStart;
                    });

                    // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
                    setTimeout(dt.ajax.reload, 0);

                    // Prevent rendering of the full data to the DOM
                    return false;
                });
            });

            // Requery the server with the new one-time export settings
            dt.ajax.reload();
        };
        // tslint:enable

        this.dtOptions = {
            buttons: getGridButtons(this.commonExportOptions, 'carsharing_overview', this.translateService.instant('general.carsharing'), exportAction),
            pagingType: 'simple_numbers',
            serverSide: false,
            processing: false,
            scrollX: true,
            colReorder: { fixedColumnsLeft: 2 },
            stateSave: true,
            stateSaveCallback: function (settings, data) {
                that.saveState(that.constructorName, data);
            },
            stateLoadCallback: function (_, callback) {
                (async () => { try {
                    const columnSettings = await that.loadState(that.constructorName);
                    that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
                    return columnSettings;
                } catch (e) {
                    that.error = {};
                    that.error.error = e;
                    that.error.statusText = 'Error fetching column settings';

                    return null;
                }})().then(result => {
                    callback(result);
                });
            },
            order: [[1, 'asc']],
            ajax: (dataTablesParameters: any, callback) => {
                $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
                this.loading = true;
                this.carsharingService.getDashboard().subscribe(resp => {
                    callback({
                    recordsTotal: resp.length,
                    recordsFiltered: resp.length,
                    data: resp
                    });
                }, error => {
                    this.success = null;
                    this.error = error;
                    this.loading = false;
                });
            },
            initComplete: function (settings, json) {
                that.loading = false;
                that.episodesLoaded = false;

                console.log('init complete');
                that.checkFilters();
                that.drawFilterRow();
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
                exclude: ['id', 'id_export', 'plusButton']
            },
            columns: this.columns,
            rowCallback: function(row, data) {
                $(row).off().on('click', 'td.dt-control', () => {
                    const self = this;
                    const rowChild = this.api().row(row);

                    $(this).off().on('order.dt', function (e, settings, ordArr) {
                        const columnName = self.api().column(ordArr[0].col).dataSrc();

                        if (ordArr[0].type === 'string') {
                            data.child.sort(function(a, b) {  return (ordArr[0].dir === 'asc' ? '' : '-') + ('' + b[columnName]).localeCompare(a[columnName]) });
                        } else {
                            data.child.sort(function(a, b) {  return (ordArr[0].dir === 'asc' ? '' : '-') + (a[columnName] - b[columnName]) });
                        }

                        rowChild.child(that.formatChildColumn(data));
                        self.api().columns.adjust();
                    });

                    if (rowChild.child.isShown()) {
                        rowChild.child.hide();
                    } else {
                        if (rowChild.child() && rowChild.child().length) {
                            rowChild.child.show();
                            this.api().columns.adjust();
                            return;
                        }

                        rowChild.child(that.formatChildColumn(data)).show();
                    }

                    this.api().columns.adjust();
                });
            },
            pageLength: 25,
            lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
        };
    }

    formatChildColumn(data) {
        return $.map(data.child, function (col, i) {
            return $('<tr data-row="' + i.toString() + '">' +
                        '<td></td>' +
                        '<td>-</td>' +
                        '<td>' + col.assetName + '</td>' +
                        '<td>' + col.assetModel + '</td>' +
                        '<td>' + col.utilization + '%</td>' +
                        '<td>' + col.available + '</td>' +
                        '<td>' + col.selfDrive + '</td>' +
                        // '<td>' + col.chauffeur + '</td>' +
                        // '<td>' + col.interlocationTransfer + '</td>' +
                        // '<td>' + col.fueling + '</td>' +
                        // '<td>' + col.carWash + '</td>' +
                        '<td>' + col.garageRepair + '</td>' +
                        '<td>' + col.unknown + '</td>' +
                        '<td>' + col.total + '</td>' +
                    '</tr>'
                );
        });
    }
}
