import { Pipe, PipeTransform } from '@angular/core';

/**
 * A simple string filter, since Angular does not yet have a filter pipe built in.
 */
@Pipe({
    name: 'reportCategoryFilter'
})
export class ReportCategoryFilterPipe implements PipeTransform {

    transform(items: any[], search: any, source: any[]): any[] {
        if (!items) {
            return null;
        }

        if (!source) {
            return null;
        }

        if (!search) {
            return items;
        }

        search = search.toLowerCase();

        const intermediateSource = source;

        const result = [];

        items.filter(function (item) {
            if (intermediateSource[item.value - 1].Name.toLowerCase().includes(search)) {
                result.push(item);
            }
        });

        return result;
    }
}
