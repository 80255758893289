import { Component, Input, OnChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { FormMode } from 'app/common/enums';
import { flattenObject } from 'app/common/permissionhelper';
import { UserRole } from 'app/models/userRole.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-user-role-permission-content',
    templateUrl: 'userRolePermissionContent.component.html',
    styleUrls: ['userRolePermissionContent.component.css'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    animations: [
        fadeInOnEnterAnimation({ anchor: 'enter', duration: 1000, delay: 100 }),
    ]
})

export class UserRolePermissionContentComponent implements OnChanges {
    @Input() permissionsMap = [];
    @Input() formMode: any = FormMode.read;
    @Input() userRole: UserRole;
    @Input() loading = false;
    permissions = {};
    flattened = [];

    constructor(private authenticationService: AuthenticationService) {
        this.permissions = authenticationService.permissions;
        this.flattened = [];
    }

    ngOnChanges() {
        this.flattened = [];
        flattenObject(this.flattened, this.permissionsMap);
    }

    hasPermission(name) {
        // console.log(this.permissionsMap);

        const index = this.flattened.find(x => x.name === name);
        if (index) {
            return index.value;
        }
        return true;
    }
}
