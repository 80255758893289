import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { DeviceFuelThreshold } from 'app/models/device.model';
import { SensorTemplate } from 'app/models/sensorTemplate.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-sensor-template-details',
    templateUrl: 'sensorTemplateDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SensorTemplateDetailsComponent implements OnInit, OnChanges {

    @Input() sensorTemplate: SensorTemplate;
    @Input() loading = false;

    @Input() formMode: any = FormMode.read;
    selectedResellerId;
    permissions = {}

    constructor(private authenticationService: AuthenticationService) {
        this.permissions = this.authenticationService.permissions;
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
    }

    resellerChanged(event) {

    }

    accountChanged(event) {

    }
}

