import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { getGridButtons, getGridLanguages, createSearch, createdCellBackgroundColor, createdCellCheckbox } from 'app/common/gridhelper';
import { Observable } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslatorService } from '../../services/common/translator.service';
import { UserService } from '../../services/users/user.service';
import { GridBase360Directive } from 'app/common/360Grid.base';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { StorageHelper } from 'app/common/storagehelper';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-users',
  templateUrl: 'users.template.html',
  styleUrls: ['users.template.css']
})
export class UsersViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;
  countrySelect = false;
  showActiveOnly = true;

  loading = false;
  excludingColumns = ['isInstaller', 'isReseller', 'isSetupUser'];

  dropdown_IssueType = '';
  dropdown_DeviceType = '';
  dropdown_Severity = '';
  timezoneIana: string;
  permissions: {};
  languageLoaded: boolean;


  permissionName = 'Users_View';
  constructorName = 'UsersViewComponent';
  isImpersonated: boolean;

  error;

  impersonatePublic(userId) {
    this.ngZone.run(() => this.impersonate(userId));
  }

  constructor(private ngZone: NgZone, private localeService: BsLocaleService, private route: ActivatedRoute, private translatorService: TranslatorService, private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private userService: UserService, protected storageHelper: StorageHelper) {
    super(storageHelper);

    const that = this;
    this.permissions = this.authenticationService.permissions;
    this.timezoneIana = authenticationService.getTimeZoneIana();
    this.token = this.authenticationService.getAuthToken();
    this.isImpersonated = this.authenticationService.getIsImpersonated();

    // Get all the date for dropdown boxes
    Observable.forkJoin(
      this.translateService.get('general.date')
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      err => console.error(err)
    );
  }

  impersonate(userId) {
    const user = this.userService.getUserById(userId).subscribe(appUser => {
      this.userService.getTokenForUser(appUser.id).subscribe(result => {
        this.authenticationService.setImpersonationToken(result);
      });
    });
  }

  // Check route to apply filters
  checkRoute() {
    // Set issuetype from get
    this.route.queryParams.subscribe(params => {
      const companyName = params['companyName'];
      let requireDraw = false;

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        if (companyName) {
          dtInstance.column('companyName:name').search(companyName);
          requireDraw = true;
        }
        // Fire the request
        if (requireDraw) {
          dtInstance.draw();
        }
      });
    });
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log('Fire update');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

        if (this.showActiveOnly === true) {
          dtInstance.column('isActive:name').search('true');
        } else {
          dtInstance.column('isActive:name').search('@ignore');
        }

        // Fire the request
        dtInstance.draw();
      });
    }
  }

  ngOnDestroy(): void {
    window['my'].namespace.impersonate = null;

    super.ngOnDestroy();
  }

  ngOnInit(): void {
    window['my'] = window['my'] || {};
    window['my'].namespace = window['my'].namespace || {};
    window['my'].namespace.impersonate = this.impersonatePublic.bind(this);

    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true; this.initGrid();
    });
  }

  // Check custom filters from grid save
  checkFilters() {

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.showActiveOnly = dtInstance.column('isActive:name').search() !== '@ignore';
    });

    this.fireFilter(1);
  }

  initGrid(): void {

    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: 'id',
        data: 'id',
        className: 'noVis',
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: '20',
        render: function (data, type, row) {
          return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/UserDetails/Index/' + data + '\'><span class="hidden-sm hidden-xs" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
        }
      }, {
        name: 'id_export',
        data: 'id',
        className: 'noVis',
        title: this.translateService.instant('general.id'),
        visible: false,
      },
      {
        name: 'userName',
        data: 'userName',
        title: this.translateService.instant('general.userName'),
        render: function (data, type, row) {
          return '<div class="truncate">' + data + '</div>';
        }
      },
      {
        name: 'fullName',
        data: 'fullName',
        title: this.translateService.instant('general.name')
      },
      {
        name: 'createdDate',
        data: 'createdDate',
        type: 'date',
        title: this.translateService.instant('general.created'),
        render: function (data, type, row) {
          if (type === 'display') {
            const date = Moment.utc(data)['tz'](that.timezoneIana);
            return date.format('lll')
          }
          return data;
        },
      },
    ];

    if (this.permissions['Platform_IsAdministrator']) {
      this.columns.push({
        name: 'isAdministrator',
        data: 'isAdministrator',
        width: '40',
        createdCell: createdCellCheckbox,
        title: this.translateService.instant('general.isAdministrator'),
        visible: false
      });
    }

    this.columns.push({
      name: 'userRoleName',
      data: 'userRoleName',
      defaultContent: '-',
      title: this.translateService.instant('general.userRole')
    });

    if (this.permissions['Platform_IsReseller']) {
      this.columns.push({
        name: 'isReseller',
        data: 'isReseller',
        width: '40',
        createdCell: createdCellCheckbox,
        title: this.translateService.instant('general.isReseller'),
        visible: false
      });
      this.columns.push({
        name: 'isFleetManager',
        data: 'isFleetManager',
        width: '40',
        createdCell: createdCellCheckbox,
        title: this.translateService.instant('general.isFleetManager'),
        visible: false
      });
      this.columns.push({
        name: 'isInstaller',
        data: 'isInstaller',
        width: '40',
        createdCell: createdCellCheckbox,
        title: this.translateService.instant('general.isInstaller'),
        visible: false
      });
    }

    this.columns = this.columns.concat([
      {
        name: 'deviceCount',
        data: 'deviceCount',
        width: '40',
        type: 'num',
        title: this.translateService.instant('general.deviceCount')
      },
      {
        name: 'companyName',
        data: 'companyName',
        defaultContent: '-',
        title: this.translateService.instant('general.companyName')
      }, {
        name: 'resellerDescription',
        data: 'resellerDescription',
        defaultContent: '-',
        title: this.translateService.instant('general.resellerDescription'),
        visible: false
      },
    ]);

    // Specific field for only administrators
    if (this.permissions['Platform_IsReseller']) {
      this.columns.push({
        name: 'isActive',
        data: 'isActive',
        type: 'checkBox',
        title: this.translateService.instant('general.isActive'),
        createdCell: createdCellCheckbox,
        width: '40',
        visible: false
      });
    }

    this.columns = this.columns.concat([
      {
        name: 'impersonate',
        data: 'id',
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: '30',
        render: function (data, type, row) {
          return that.permissions['Platform_IsReseller'] && !that.isImpersonated ? `
          <a class=\'btn btn-primary btn-outline btn-grid\' href="javascript:my.namespace.impersonate('` + data + `')"><i class="fa fa-fw fa-user-secret"></i></a>` : '';
        }
      },
    ])

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'users_overview', this.translateService.instant('menu.useroverview')),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => { try {
          const columnSettings = await that.loadState(that.constructorName);
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          that.error = {};
          that.error.error = e;
          that.error.statusText = 'Error fetching column settings';

          return null;
        }})().then(result => {
          callback(result);
        });
      },
      order: [[0, 'asc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.userService.getPagingUrl(),
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        console.log('init complete');
        that.checkRoute();
        that.checkFilters();
        that.drawFilterRow();
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'id_export', 'impersonate']
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }
}
