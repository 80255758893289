import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { smoothlyMenu } from '../../../app.helpers';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { CacheService } from '../../../services/common/cache.service';
import { SearchService } from '../../../services/search/search.service';

declare var jQuery: any;

@Component({
  selector: 'fh-topnavbar',
  templateUrl: 'topnavbar.template.html',
  providers: [SearchService]
})
export class TopNavbarComponent implements OnInit {
  @Input() includeSearch = true;
  asyncSelected: any = '';
  form: any;
  api_url: string;
  id: string;
  results: Object;
  autoComplete$: Observable<string[]>;
  isImpersonated = false;

  autoCompleteSearchTerm: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  permissions: {};
  clusterUrl: string;

  constructor(private router: Router, private cacheService: CacheService, private cookieService: CookieService, private authenticationService: AuthenticationService, private searchService: SearchService) {
    this.permissions = authenticationService.permissions;
  }

  stopImpersonation() {
    this.router.navigate(['/'], { skipLocationChange: false }).then(response => {
      this.authenticationService.stopImpersonation();
    });
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) === 1;
  }

  ngOnInit(): void {
    this.isImpersonated = this.authenticationService.getIsImpersonated();

    this.id = this.authenticationService.getId();

    this.autoComplete$ = Observable.create((observer: any) => {
      this.actualSearch(this.asyncSelected).subscribe((result: any) => {
        observer.next(result);
      })
    });

    this.clusterUrl = window.location.origin;


  }

  submenu(event) {
    event.preventDefault();
    event.stopPropagation();

    $('.dropdown-submenu').parent().removeClass('open');
    $(event.target).parent().toggleClass('open');
  }

  replaceLinks(appName) {
    if (this.clusterUrl.indexOf('fleetmanagement') > -1) {
      return this.clusterUrl.replace('fleetmanagement', appName);
    }

    if (this.clusterUrl.indexOf('app.tripsinthecloud.com') > -1) {
      const clusterC2Url = 'https://app-clust02.gpscockpit.com/';
      return clusterC2Url.replace('app', appName);
    }

    if (this.clusterUrl.indexOf('ls1-sa.locationsolutions.') > -1) {
      const clusterC3Url = 'https://app-clust03.gpscockpit.com/';
      return clusterC3Url.replace('app', appName);
    }

    if (this.clusterUrl.indexOf('ls1-ae.locationsolutions.') > -1) {
      const clusterC4Url = 'https://app-clust04.gpscockpit.com/';
      return clusterC4Url.replace('app', appName);
    }

    if (this.clusterUrl.indexOf('ls1-om.locationsolutions.') > -1) {
      const clusterC5Url = 'https://app-clust05.gpscockpit.com/';
      return clusterC5Url.replace('app', appName);
    }

    return this.clusterUrl;
  }

  actualSearch(search) {
    console.log(search);
    return this.searchService.searchEntries(search).debounceTime(300).distinctUntilChanged();
  }

  toggleNavigation(): void {
    jQuery('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }

  logout() {
    // Logout from application ... clearing tokens and redirecting
    this.authenticationService.stopImpersonation(false);
    this.cookieService.delete('SsoToken', '/');
    this.cookieService.delete('RefreshToken', '/');
    this.cacheService.clearCache();
    this.authenticationService.clearToken();
  }

  onSubmit() {
    console.log('Search on');
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    console.log('Selected value: ', e);
    // Device
    if (e.item.group === 1) {
      this.redirectTo(['/DeviceDetails/Index/' + e.item.id])
    }

    // DeviceType
    if (e.item.group === 2) {
      this.redirectTo(['/DeviceTypeDetails/Index/' + e.item.id])
    }

    // Issue
    if (e.item.group === 3) {
      this.redirectTo(['/IssueDetails/Index/' + e.item.id])
    }

    // Driver
    if (e.item.group === 4) {
      this.redirectTo(['/DriverDetails/Index/' + e.item.id])
    }

    // Account
    if (e.item.group === 5) {
      this.redirectTo(['/AccountDetails/Index/' + e.item.id])
    }

    // Asset
    if (e.item.group === 6) {
      this.redirectTo(['/AssetDetails/Index/' + e.item.id])
    }

    // Asset
    if (e.item.group === 6) {
      this.redirectTo(['/AssetDetails/Index/' + e.item.id])
    }

    // Geofence
    if (e.item.group === 7) {
      this.redirectTo(['/GeofenceDetails/Index/' + e.item.id])
    }

    // User
    if (e.item.group === 8) {
      this.redirectTo(['/UserDetails/Index/' + e.item.id])
    }
  }

  redirectTo(path) {
    this.router.navigateByUrl('/Search', { skipLocationChange: true }).then(() => {
      this.router.navigate(path);
    });
  }

  goToOverview() {
    window.open(environment.FORedirectUrl + 'Login/?token=' + this.authenticationService.getAuthToken() + '&expired=' + this.authenticationService.getAuthTokenExpired());
  }
}
