import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { roundAsString, roundSeconds } from 'app/common/globals';
import { simplifyDouglasPeucker } from 'app/common/simplify';
import { icon, marker } from 'leaflet';
// Moment timezone
declare var L;
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { AuthenticationService } from '../authentication/authentication.service';
import { ColorService } from '../common/color.service';

window['moment'] = Moment;
mTZ();

// Highcharts
import * as Highcharts from 'highcharts';

import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import ExportData from 'highcharts/modules/export-data';
import HighchartsMore from 'highcharts/highcharts-more';

import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsBoostCanvas from 'highcharts/modules/boost-canvas';
import HighchartsStock from 'highcharts/modules/stock';

Exporting(Highcharts);
OfflineExporting(Highcharts);
ExportData(Highcharts);
HighchartsMore(Highcharts);
HighchartsBoost(Highcharts);
HighchartsBoostCanvas(Highcharts);
HighchartsStock(Highcharts);

@Injectable()
export class FhChartService {
    timezoneIana: string;

    constructor(private colors: ColorService, private authenticationService: AuthenticationService) {
        this.timezoneIana = authenticationService.getTimeZoneIana();

        if (this.timezoneIana !== 'undefined') {
            Highcharts.setOptions({
                time: {
                    timezone: this.timezoneIana
                    // timezoneOffset: -4 * 60
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                chart: {
                    style: {
                        fontFamily: 'Inter, Helvetica, Arial, sans-serif',
                        fontSize: '13px',
                        fontWeight: '400'
                    },
                },
                title: {
                    style: {
                        fontSize: '13px',
                    }
                },
                yAxis: {
                    labels: {
                        style: {
                            fontSize: '13px',
                        }
                    }
                },
                legend: {
                    itemStyle: {
                        fontSize: '13px',
                    }
                },
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '13px',
                        }
                    }
                },
                exporting: {
                    sourceWidth: 900,
                    sourceHeight: 450,
                    scale: 2,
                    fallbackToExportServer: false
                },
                accessibility: {
                    enabled: false,
                },
            });
        }
    }

    countKeys(t) {
        switch (t?.constructor) {
            case Object:                                     // 1
                return Object
                    .values(t)
                    .reduce((r: any, v) => r + 1 + this.countKeys(v), 0)
            case Array:                                      // 2
                return t
                    .reduce((r, v) => r + this.countKeys(v), 0)
            default:                                         // 3
                return 0
        }
    }

    simplifyPathDouglasPecker(data, epsilon) {
        // CALL RDP Function
        const result = [];

        const beforeCount = this.countKeys(data);

        data.forEach(series => {
            const seriesData = simplifyDouglasPeucker(series.data, epsilon);
            series.data = seriesData;
            result.push(series);
        });

        const afterCount = this.countKeys(data);

        console.log(`Using simplifyPathDouglasPecker with epsilon ${epsilon} to format from '${beforeCount}' to '${afterCount}' (${roundAsString((afterCount / beforeCount) * 100, 1)}%)`);

        return result;
    }

    hideUndefinedForLegend(data: any[]) {
        return data.length > 1 || (data.length === 1 && data[0]?.name !== 'Undefined');
    }

    generateOdoChart(theData) {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: 'area'
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: ''
                },
                labels: {
                    rotation: -45,
                },
            },
            yAxis: { // left y axis
                title: {
                    text: 'Odo'
                },
                showFirstLabel: false
            },

            legend: {
                align: 'left',
                verticalAlign: 'top',
                y: 5,
                x: 80,
                floating: true,
                borderWidth: 1,
            },

            tooltip: {
                shared: true,
                crosshairs: true
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    marker: {
                        lineWidth: 1
                    }
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };

        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateColumnChart(theData, theDrilldownData = {}, xAxis = []) {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    text: null
                }, labels: {
                    rotation: -45,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                            const point = event.point as any;
                            location.href = '/#/AccountDetails/Index/' + point.clientData;
                        }
                    }
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: false
            },
            series: theData,
            drilldown: theDrilldownData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateUtilizationScoreChart(theData, xAxis = [], chartType = 'column', valueSuffix = null, useCustomFormatter = false) {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: chartType,
                panning: true,
                panKey: 'shift'
            },
            boost: {
                useGPUTranslations: true
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                endOnTick: false,
                title: {
                    text: ''
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                shared: false,
                valueSuffix: valueSuffix,
                formatter: function (tooltip) {
                    if (!useCustomFormatter) {
                        return tooltip.defaultFormatter.call(this, tooltip);
                    } else {
                        return `${this.x}<br/>Utilization: <b>${this.y}%</b><br/>Hours: <b>${this.point.hours}</b>`;
                    }
                },
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                            const point = event.point as any;
                            if (point.deviceId) {
                                location.href = '/#/DeviceDetails/Index/' + point.deviceId;
                            }
                            if (point.driverId) {
                                location.href = '/#/DriverDetails/Index/' + point.driverId;
                            }
                        }
                    }
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: false
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateReportChart(theData, xAxis = [], chartType = 'column', enableLegend = false) {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: chartType,
                panning: true,
                panKey: 'shift'
            },
            boost: {
                useGPUTranslations: true
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    cursor: 'pointer',
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: '110%',
                    dataLabels: {
                        enabled: !enableLegend,
                        style: {
                            fontWeight: 'normal'
                        },
                        formatter: function () {
                            if (this.point.percentage > 2) {
                                return '<b>' + this.point.name + '</b>:' + Highcharts.numberFormat(this.point.percentage, 1) + '%';
                            }
                        }
                    },
                    events: {
                        click: function (event) {
                            const point = event.point as any;

                            if (point.deviceTypeId) {
                                location.href = '/#/Devices/DeviceTypeDetails/Index/' + point.deviceTypeId;
                            }

                            if (point.deviceId) {
                                location.href = '/#/DeviceDetails/Index/' + point.deviceId;
                            }
                        }
                    },
                    showInLegend: enableLegend,
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: enableLegend,
                align: 'center',
                verticalAlign: chartType === 'pie' ? 'bottom' : 'top',
                floating: true,
                x: 0,
                y: 0,
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateReportChartDateTime(theData, chartType = 'column') {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: chartType,
                panning: true,
                panKey: 'shift'
            },
            boost: {
                useGPUTranslations: true
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%Y'
                },
                title: {
                    text: ''
                },
                labels: {
                    rotation: -45,
                },
            },
            yAxis: [{
                title: {
                    text: ''
                },
            },
            {
                opposite: true,
                title: {
                    text: '' // Temperature
                },
                labels: {
                    format: '{value} °'
                }
            },
            {
                opposite: true,
                title: {
                    text: '' // Humidity
                },
                labels: {
                    format: '{value} %'
                },
                visible: false,
                min: 0,
                max: 101,
            }, {
                opposite: true,
                title: {
                    text: '' // Weight
                },
                labels: {
                    format: '{value} kg'
                },
            }, {
                min: 0,
                max: 20,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'Ignition'
                },
                visible: false,
                opposite: true,
            }, {
                min: -2,
                max: 18,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'Ignition'
                },
                visible: false,
                opposite: true,
            }, {
                opposite: false,
                title: {
                    text: '' // Speed
                },
                labels: {
                    format: '{value} km/h'
                },
                min: 0,
            }, {
                opposite: false,
                title: {
                    text: '' // voltage
                },
                labels: {
                    format: '{value} V'
                },
            }],
            tooltip: {
                shared: true,
                valueSuffix: ''
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    cursor: 'pointer',
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: true,
                align: 'center',
                verticalAlign: 'top',
                floating: true,
                x: 0,
                y: 0
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };

        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateColumnChartDates(theData, theDrilldownData = {}, xAxis = [], plotLines = null, stacking = undefined, enableLegend = null, valueSuffix = null, maxvalue = null) {
        if (enableLegend === null) {
            enableLegend = this.hideUndefinedForLegend(theData);
        }

        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: 'area'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%Y'
                },
                title: {
                    text: ''
                },
                labels: {
                    format: '{value:%e. %b}',
                    rotation: -45,
                },
                plotLines: plotLines
            },
            yAxis: [{
                min: 0,
                max: maxvalue,
                endOnTick: maxvalue === null ? false : true,
                title: {
                    text: ''
                },
            },
            {
                opposite: true,
                min: 0,
                max: maxvalue,
                title: {
                    text: ''
                },
            }],
            tooltip: {
                shared: true,
                valueSuffix: valueSuffix,
            },
            plotOptions: {
                area: {
                    stacking: stacking,
                },
                column: {
                    pointPadding: 0,
                    borderWidth: 0,
                    stacking: stacking,
                }
            },
            credits: { enabled: false },
            legend: {
                enabled: enableLegend,
                align: 'center',
                verticalAlign: 'top',
                floating: true,
                x: 0,
                y: 0
            },
            series: theData,
            drilldown: theDrilldownData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };

        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateMapChart(theData, plotLines = null, plotBands = null, map = null, theIcon, plotLinesXAxis = null, hideSidebar = false) {
        let theMarker;

        const chart: Highcharts.Options = (<any>{
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x'
            },
            boost: {
                useGPUTranslations: true,
                debug: {
                    showSkipSummary: true,
                    timeBufferCopy: true,
                    timeKDTree: true,
                    timeRendering: true,
                    timeSeriesProcessing: true,
                    timeSetup: true
                }
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: ''
                },
                plotLines: plotLines,
                plotBands: plotBands
            },
            yAxis: [{ // Primary yAxis
                min: 0,
                labels: {
                    format: '{value} km/h'
                },
                title: {
                    text: ''
                },
                opposite: true,
                visible: !hideSidebar,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 0) ?? null,
            }, {
                min: 0,
                labels: {
                    format: '{value} km'
                },
                title: {
                    text: ''
                },
                visible: !hideSidebar,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 1) ?? null,
            }, { // Secondary yAxis
                max: 100,
                title: {
                    text: ''
                },
                labels: {
                    format: '{value}%'
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 2) ?? null,
            }, {
                min: 0,
                max: 20,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'Ignition'
                },
                visible: false,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 3) ?? null,
            }, {
                min: -2,
                max: 18,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'External power'
                },
                visible: false,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 4) ?? null,
            }, {
                min: -4,
                max: 16,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value === 1 ? 'On' : 'Off';
                    }
                },
                title: {
                    text: 'Gps fix'
                },
                visible: false,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 5) ?? null,
            }, {
                max: 30,
                labels: {
                    format: '{value} V'
                },
                title: {
                    text: ''
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 6) ?? null,
            }, {
                labels: {
                    format: '{value} °C'
                },
                title: {
                    text: ''
                },
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 7) ?? null,
            }, {
                max: 100,
                labels: {
                    format: '{value} %'
                },
                title: {
                    text: ''
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 8) ?? null,
            }, {
                labels: {
                    format: '{value} kg'
                },
                title: {
                    text: ''
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 9) ?? null,
            },
            {
                labels: {
                    format: '{value} mg'
                },
                title: {
                    text: ''
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 10) ?? null,
            }, {
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} %'
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 11) ?? null,
            }, {
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} L'
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 12) ?? null,
            }, {
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} RPM'
                },
                visible: !hideSidebar,
                opposite: true,
                plotLines: plotLinesXAxis?.filter(x => x.yAxis === 13) ?? null,
            }],
            tooltip: {
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#ccc',
                shadow: false,
                shared: true,
                useHTML: true,
                valueDecimals: 0,
                headerFormat:
                    '<table class="tip"><caption>{point.key}</caption>'
                    + '<tbody>',
                pointFormat:
                    '<tr><th style="color: {series.color}">{series.name}: </th>'
                    + '<td style="text-align: right">{point.y:.2f} {point.suffix}</td></tr>',
                footerFormat:
                    '</tbody></table>'
            },
            legend: {
                enabled: true,
                align: 'center',
                verticalAlign: 'top',
                floating: true,
                x: 0,
                y: 0,
                // backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
                // borderWidth: 1
            },

            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    turboThreshold: 0,
                    cursor: 'pointer',
                    point: {
                        events: {
                            mouseOver: function (evt) {
                                if (this.latlon) {
                                    if (theMarker) {
                                        map.removeLayer(theMarker);
                                    }
                                    theMarker = marker(this.latlon, { icon: theIcon }).addTo(map);

                                    const duration = (evt.target.category - evt.target.series.data[0].category);

                                    theMarker.bindTooltip(roundSeconds(duration / (1000), false) + ' / ' + evt.target.series.name + ' : ' + (Math.round(evt.target.options.y * 10) / 10) + (evt.target.options.suffix ? ' ' + evt.target.options.suffix : ''),
                                        {
                                            permanent: true,
                                            offset: L.point(0, 15),
                                            direction: 'bottom'
                                        });
                                    map.setView(this.latlon, 14);
                                }
                            },
                            click: function () {
                                const seriesName = this.series.name;
                                return true;
                            },
                            mouseOut: function () {
                                if (theMarker) {
                                    map.removeLayer(theMarker);
                                }
                            }
                        }
                    }
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        });

        return chart;
    }


    generateSensorChart(theData) {

        const chart: Highcharts.Options = (<any>{
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: 'spline'
            },
            boost: {
                useGPUTranslations: true,
                debug: {
                    showSkipSummary: true,
                    timeBufferCopy: true,
                    timeKDTree: true,
                    timeRendering: true,
                    timeSeriesProcessing: true,
                    timeSetup: true
                }
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: ''
                },
            },
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value} °'
                },
                title: {
                    text: 'Temperature °C'
                },
                opposite: false
            }, { // Secondary yAxis
                min: 0,
                max: 100,
                title: {
                    text: 'Humidity %'
                },
                labels: {
                    format: '{value} %'
                },
                opposite: true
            }],
            tooltip: {
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#ccc',
                shadow: false,
                shared: true,
            },
            legend: {
                itemWidth: 150,
                symbolWidth: 40,
                align: 'center',
                verticalAlign: 'bottom',
                floating: false,
                x: 0,
                y: 10
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        });

        return chart;
    }


    generateFuelChart(theData, plotLines = null, plotBands = null, map = null, theIcon) {
        let theMarker;

        const chart: Highcharts.Options = (<any>{
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: ''
                },
                plotLines: plotLines,
                plotBands: plotBands
            },
            yAxis: [{
                min: 0,
                labels: {
                    format: '{value} km'
                },
                title: {
                    text: ''
                },
                opposite: true
            }, {
                min: 0,
                labels: {
                    format: '{value} L'
                },
                title: {
                    text: ''
                }
            }, {
                min: 0,
                max: 101,
                title: {
                    text: 'Fuel tank'
                },
                labels: {
                    format: '{value}%'
                },
                visible: false,
                opposite: true
            }, {
                min: -2,
                max: 10,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'Speed'
                },
                visible: false,
                opposite: true
            }, {
                min: 0,
                max: 11,
                categories: ['OFF', 'ON', ''],
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'Ignition'
                },
                visible: false,
                opposite: true
            }, {
                min: 0,
                max: 10,
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                },
                title: {
                    text: 'Events'
                },
                visible: false,
                opposite: true
            }],
            tooltip: {
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#ccc',
                shadow: false,
                shared: true,
                useHTML: true,
                valueDecimals: 0,
                headerFormat:
                    '<table class="tip"><caption>{point.key}</caption>'
                    + '<tbody>',
                pointFormat:
                    '<tr><th style="color: {series.color}">{series.name}: </th>'
                    + '<td style="text-align: right">{point.y:.2f} {point.suffix}</td></tr>',
                footerFormat:
                    '</tbody></table>'
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                floating: false,
                x: 0,
                y: 10
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            mouseOver: function () {
                                if (this.latlon) {
                                    if (theMarker) {
                                        map.removeLayer(theMarker);
                                    }
                                    theMarker = marker(this.latlon, { icon: theIcon }).addTo(map);
                                    map.panTo(this.latlon);
                                }
                            },
                            click: function () {
                                const seriesName = this.series.name;
                                return true;
                            },
                            mouseOut: function () {
                                if (theMarker) {
                                    map.removeLayer(theMarker);
                                }
                            }
                        }
                    }
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        });

        return chart;
    }

    generateColumnChartDevice(theData, theDrilldownData = {}, xAxis = [], enableLegend = false, yAxisName = '') {

        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    text: null
                },
                labels: {
                    rotation: -45,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: yAxisName
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                            const point = event.point as any;
                            location.href = '/#/DeviceDetails/Index/' + point.deviceId;
                        }
                    }
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: enableLegend,
                align: 'center',
                verticalAlign: 'top',
                floating: true,
                x: 0,
                y: 0
            },
            series: theData,
            drilldown: theDrilldownData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateTopUsageChartDevice(theData, theDrilldownData = {}, xAxis = [], enableLegend = false, yAxisName = '') {

        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                zoomType: 'x',
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    text: null
                },
                labels: {
                    rotation: -45,
                },
            },
            yAxis: {
                title: {
                    text: yAxisName
                },
                type: 'logarithmic',
                labels: {
                    overflow: 'justify'
                },
                plotLines: [{
                    value: 1000,
                    dashStyle: 'dash',
                    width: 1,
                    color: 'rgba(243, 156, 18, 1)',
                    label: {
                        align: 'right',
                        style: {
                            fontStyle: 'italic',
                            color: 'rgba(243, 156, 18, 1)'
                        },
                        text: 'Normal usage 1000/day',
                        x: -10
                    }
                },
                {
                    value: 1500,
                    dashStyle: 'dash',
                    width: 1,
                    color: 'rgba(217, 30, 24, 1)',
                    label: {
                        align: 'right',
                        style: {
                            fontStyle: 'italic',
                            color: 'rgba(217, 30, 24, 1)'
                        },
                        text: 'Excessive usage 1500/day',
                        x: -10
                    }
                }],
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    cursor: 'pointer',
                    events: {
                        click: function (event) {
                            const point = event.point as any;
                            if (point.deviceId) {
                                location.href = '/#/DeviceDetails/Index/' + point.deviceId;
                            }
                        }
                    }
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: enableLegend,
                align: 'center',
                verticalAlign: 'top',
                floating: true,
                x: 0,
                y: 0
            },
            series: theData,
            drilldown: theDrilldownData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generatePieChartDevice(theData, theDrilldownData = {}, xAxis = [], enableLegend = false, yAxisName = '') {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: xAxis,
                title: {
                    text: null
                },
                labels: {
                    rotation: -45,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: yAxisName
                },
                labels: {
                    overflow: 'justify'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: '110%',
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'normal'
                        },
                        formatter: function () {
                            if (this.point.percentage > 2) {
                                return '<b>' + this.point.name + '</b>:' + Highcharts.numberFormat(this.point.percentage, 1) + '%';
                            }
                        }
                    },
                    events: {
                        click: function (event) {
                            const point = event.point as any;
                            if (point.deviceTypeId) {
                                location.href = '/#/Devices/DeviceTypeDetails/Index/' + point.deviceTypeId;
                            }
                        }
                    },
                },
            },
            credits: { enabled: false },
            legend: {
                enabled: enableLegend,
                align: 'center',
                verticalAlign: 'top',
                floating: true,
                x: 0,
                y: 0
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }

    generateVarPieChartDevice(theData, theDrilldownData = {}, xAxis = [], enableLegend = false, yAxisName = '') {
        const chartObject = {
            chart: {
                backgroundColor: 'rgba(0,0,0,0)',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            credits: { enabled: false },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y:.1f} ({point.percentage:.1f}%)</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            series: theData,
            colors: [this.colors.theme.overwrite ? this.colors.theme.chartPrimary : '#CB711D', this.colors.theme.overwrite ? this.colors.theme.chartSecondary : '#D6A282', this.colors.theme.overwrite ? this.colors.theme.chartThird : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFourth : '#D3C6BE', this.colors.theme.overwrite ? this.colors.theme.chartFifth : '#D3C6BE']
        };
        const chart: Highcharts.Options = (<any>chartObject);
        return chart;
    }
}
