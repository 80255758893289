<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="device?.asset && device?.asset.accountId">
                <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate }}</a>
            </li>
            <li>
                <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                    device?.name}}</a>
            </li>
            <li class="active">{{'menu.sensors' | translate }}</li>
        </ol>

        <span class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix hidden-sm hidden-xs" title="Help"
                href="https://help.360locate.com/knowledge-base/device-sensors/" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="hidden-sm hidden-xs">{{'general.needHelp' |
                    translate }}</span></a>
        </span>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId"></fh-device-details-navigation>
    <div *ngIf="!showMapOnSide" class="col-lg-12" style="padding: 1px 0 0 0;">
        <ngx-loading [show]="loading"></ngx-loading>
        <fh-leaflet-map [geofences]="geofences" [height]="mapHeight" [geofenceEnabled]="false" [heatmapEnabled]="true"
            (mapReady)="onMapReady($event)">
        </fh-leaflet-map>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="">
            <div class="col-lg-12">

                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>
        <div class="row" data-equalizer="">
            <div [ngClass]="{'col-lg-12': !showMapOnSide, 'col-lg-8': showMapOnSide}">

                <!-- History -->
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}
                        </h5>
                        <span class="pull-right">
                            <ibox-chart-export [chartObject]="chart">
                            </ibox-chart-export>
                        </span>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div class="col-lg-6">
                            <div class="form-group input-group" style="margin-bottom: 6px;">
                                <span class="input-group-addon">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
  <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker" type="text"
                                [bsConfig]="dpConfig" class="form-control" bsDaterangepicker
                                (onHidden)="dateChanged('hidden')" [(ngModel)]="daterangepickerModel">
                            <span class="input-group-calendar-datatables">
                                <i class="fa fa-calendar-alt"></i>
                            </span>
                            <div style="clear:both"></div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group input-group" title="Filter on 0 values" style="margin-bottom: 8px;">
                                <span id="checkBox11" class="input-group-addon hand" title="Filter on 0 values">
                                    <i id="checkBox_11" class="auto_checkbox fa fa-fw"
                                        (click)="filterZeroValues = !filterZeroValues; dateChanged('hidden')"
                                        [ngClass]="filterZeroValues ? 'fa-check' : 'fa-times'"
                                        title="Filter on 0 values"
                                        [style.color]="filterZeroValues ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                                </span>
                                <input name="checkboxName_11" id="checkboxName_11" style="width:100%; cursor: pointer!important;"
                                    placeholder="{{'general.filterZeroValues' | translate }}" disabled="disabled"
                                    class="form-control auto_search">
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group input-group" title="Simplify data" style="margin-bottom: 8px;">
                                <span id="checkBox11" class="input-group-addon hand" title="Filter on 0 values">
                                    <i id="checkBox_11" class="auto_checkbox fa fa-fw"
                                        (click)="simplify = !simplify; dateChanged('hidden')"
                                        [ngClass]="simplify ? 'fa-check' : 'fa-times'" title="Filter on 0 values"
                                        [style.color]="simplify ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                                </span>
                                <input name="checkboxName_simplify" id="checkboxName_simplify" style="width:100%"
                                    placeholder="{{'general.simplify' | translate }}" disabled="disabled"
                                    class="form-control auto_search">
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <div class="ibox">
                    <div class="ibox-content slicker" style="min-height: 350px; position: relative;">
                        <ngx-loading [show]="loadingSensors"></ngx-loading>

                        <highcharts-chart [runOutsideAngular]="true" *ngIf="locations.length > 0 && chart"
                            style="display: block; width: 100%; height:350px" [Highcharts]="Highcharts"
                            [options]="chart"></highcharts-chart>
                        <span *ngIf="locations.length == 0">No messages found</span>
                    </div>
                </div>
            </div>
            <div class="sticky col-lg-4" *ngIf="showMapOnSide">
                <div class="ibox">
                    <fh-leaflet-map [geofences]="geofences" [height]="mapHeight" [geofenceEnabled]="false"
                        [heatmapEnabled]="true" (mapReady)="onMapReady($event)">
                    </fh-leaflet-map>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>