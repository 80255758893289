<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Warehouses/Overview">{{'general.warehouses' | translate }}</a>
            </li>
            <li>
                <a href="/#/WarehouseDetails/Index/{{warehouse?.id}}">{{warehouse?.name}}</a>
            </li>
            <li>
                <a href="/#/WarehouseZoneDetails/Index/{{warehouse?.id}}?zoneId={{zone?.rowKey}}">{{zone?.name}}</a>
            </li>
            <li class="active">{{'general.dashboard' | translate }}</li>
        </ol>
    </div>
    <fh-warehouse-details-navigation [warehouseId]="warehouseId"></fh-warehouse-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">

                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-border-none"></i> {{'general.zone' | translate }}
                            </h5>
                        </div>
                        <fh-warehouse-zone-details [zone]="zone" [formMode]="formMode" [loading]="loading || saving">
                        </fh-warehouse-zone-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                            [allowEdit]="permissions['Warehouses_Edit']"
                            [allowDelete]="permissions['Warehouses_Delete']" (setFormMode)="setFormMode($event)"
                            [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">
                <div class="ibox" style="position:relative">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-sensor"></i> {{'general.sensors' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
                        <ngx-loading [show]="loadingSensors"></ngx-loading>

                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <tbody *ngIf="this.sensors.length == 0">
                                <tr>
                                    <td colspan="4">
                                        {{ 'general.noSensorData' | translate}}
                                    </td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>
                                    </th>
                                    <th>
                                        {{ 'general.name' | translate}}
                                    </th>
                                    <th>
                                        {{ 'general.devEUI' | translate}}
                                    </th>
                                    <th>
                                        {{ 'general.lowerTemperatureThreshold' | translate}}
                                        /
                                        {{ 'general.upperTemperatureThreshold' | translate}}
                                    </th>
                                    <th>
                                        {{ 'general.lowerHumidityThreshold' | translate}}
                                        /
                                        {{ 'general.upperHumidityThreshold' | translate}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="this.sensors.length > 0">
                                <tr *ngFor="let sensor of sensors; let i = index">
                                    <td>
                                        <a class="btn btn-primary btn-grid" title="Edit sensor"
                                            (click)="editSensor(sensor)"><i class="fa fa-fw fa-edit"></i></a>

                                        <a class="btn btn-danger btn-grid" title="Delete sensor"
                                            (click)="showDelete(sensor)"> <i class="fa fa-fw fa-trash-alt"></i></a>
                                    </td>
                                    <td>{{ sensor.name}}</td>
                                    <td>{{ sensor.devEUI}}</td>
                                    <td>{{ sensor.lowerTemperatureThreshold ?? '-'}} / {{
                                        sensor.upperTemperatureThreshold ?? '-'}}</td>
                                    <td>{{ sensor.lowerHumidityThreshold ?? '-'}} / {{ sensor.upperHumidityThreshold ??
                                        '-'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-footer" style="position: relative">
                        <button class="btn btn-primary" (click)="showModal()">
                            <i class="fa fa-fw fa-plus"></i> {{'general.add' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal #deleteModal (onFinish)="deleteSensor($event)"></fh-confirmation-modal>

<!-- Adding a sensor -->
<div (onHidden)="hideModal()" [config]="{ backdrop: true, ignoreBackdropClick: true }" bsModal #addModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <form #sensorForm="ngForm">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title pull-left"> <i class="fa fa-sensor"></i> {{'general.sensor' | translate }}
                    </h5>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="popup-content" style="position: relative;">
                    <fh-warehouse-sensor-details [sensor]="sensor" [formMode]="formModeSensor" [loading]="loading">
                    </fh-warehouse-sensor-details>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="confirm()" [disabled]="sensorForm.invalid"><i
                            class="fa fa-fw fa-save"></i>
                        {{'general.finish' | translate }}</button>
                    <button type="button" class="btn btn-info" (click)="hideModal()"><i class="fa fa-fw fa-times"></i>
                        {{'general.cancel' | translate }}</button>
                </div>
            </div>
        </form>
    </div>
</div>