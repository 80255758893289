import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BatchAction, FormMode } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { roundAsNumber, roundSeconds } from 'app/common/globals';
import { ReportService } from 'app/services/reports/report.service';
import { FhChartService } from 'app/services/charts/charts.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';
import { CronOptions } from 'app/modules/cron-editor/CronOptions';
import { RerunReportModalComponent } from '../shared/usercontrols/rerunReportModal.component';
import { RerunReportSettings } from 'app/models/reporting.model';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';

window['moment'] = Moment;
mTZ();

@Component({
    selector: 'fh-subsciption-details',
    templateUrl: 'subscriptionDetails.template.html',
    providers: [FhChartService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionDetailsViewComponent implements OnInit {
    @ViewChild('deleteModal', { static: false }) deleteModal: ConfirmationModalComponent;
    @ViewChild('rerunModal', { static: false }) rerunModal: ConfirmationModalComponent;
    @ViewChild('rerunBody', { static: false }) rerunBody: RerunReportModalComponent;

    loading: boolean;
    sub: any;

    reportSubscription;
    selectedReport;

    loadingExecutions = false;
    executions = [];

    showJson = false;

    formMode = FormMode.read;

    success;
    error;

    filter;

    // Pagination
    currentPage = 1;

    permissionName = 'FleetManagement_Reporting';

    timezoneIana: string;
    reportData: any;

    permissions = {};

    // Rerun
    daterangepickerModel: Date[];

    // Edit

    step = 1;
    selectedAssetGroups: any[] = [];
    selectedGeofenceGroups: any[] = [];
    selectedDriverGroups: any[] = [];
    selectedUserContactGroups: any[] = [];
    useCustomSubject = true;
    sendOutputMessage = true;
    defaultSourceColumns = [];

    public isCronDisabled = false;
    public cronOptions: CronOptions = {
        formInputClass: 'cron-editor-input table-inline',
        formSelectClass: 'cron-editor-select table-inline',
        formRadioClass: 'cron-editor-radio',
        formCheckboxClass: 'cron-editor-checkbox',

        defaultTime: '10:00:00',
        use24HourTime: true,

        hideMinutesTab: true,
        hideHourlyTab: true,
        hideDailyTab: false,
        hideWeeklyTab: false,
        hideMonthlyTab: false,
        hideYearlyTab: false,
        hideAdvancedTab: true,

        hideSeconds: true,
        removeSeconds: true,
        removeYears: true
    };

    constructor(private router: Router, private cd: ChangeDetectorRef, private translate: TranslateService, private authenticationService: AuthenticationService, private route: ActivatedRoute, private reportService: ReportService) {
        this.permissions = this.authenticationService.permissions;
        this.timezoneIana = this.authenticationService.getTimeZoneIana();
    }

    onCheckOutFinish() {
    }

    setFormMode(mode) {
        this.formMode = mode;
    }

    onSave() {
    }

    getDefaultColumns() {
        this.reportService.getReportColumnsByReportType(this.reportSubscription.reportType).subscribe((result) => {
            this.defaultSourceColumns = result;
        });
    }

    actualRound(value, decimals) {
        return roundAsNumber(value, decimals);
    }

    actualRoundSeconds(value) {
        return roundSeconds(value);
    }

    ngOnInit() {
        this.loading = true;
        this.loadingExecutions = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.reportService.getReportSubscriptionById(id).subscribe(res => {
                this.reportSubscription = res;

                this.selectedAssetGroups = [];
                this.selectedDriverGroups = [];
                this.selectedGeofenceGroups = [];

                // Assets
                this.reportSubscription.assetSelection.selectedAssets.forEach(asset => {
                    this.selectedAssetGroups.push(asset);
                });

                this.reportSubscription.assetSelection.selectedAssetGroups.forEach(assetGroup => {
                    this.selectedAssetGroups.push({ assetGroupId: assetGroup });
                });

                // Drivers
                this.reportSubscription.driverSelection.selectedDrivers.forEach(driver => {
                    this.selectedDriverGroups.push(driver);
                });

                this.reportSubscription.driverSelection.selectedDriverGroups.forEach(driverGroup => {
                    this.selectedDriverGroups.push({ driverGroupId: driverGroup });
                });

                // Geofences
                this.reportSubscription.geofenceSelection.selectedGeofences.forEach(asset => {
                    this.selectedGeofenceGroups.push(asset);
                });

                this.reportSubscription.geofenceSelection.selectedGeofenceGroups.forEach(geofenceGroup => {
                    this.selectedGeofenceGroups.push({ geofenceGroupId: geofenceGroup });
                });

                this.reportSubscription.createdDate = Moment.utc(this.reportSubscription.createdDate)['tz'](this.timezoneIana);
                this.reportSubscription.maxExecutedTimestamp = this.reportSubscription.maxExecutedTimestamp ? Moment.utc(this.reportSubscription.maxExecutedTimestamp)['tz'](this.timezoneIana) : null;

                this.getDefaultColumns();

                this.loading = false;
                this.cd.markForCheck();
            });

            this.reportService.getReportSubscriptionExecutionsById(id).subscribe(res => {

                this.executions = res;

                this.executions.forEach(execution => {
                    execution.executedTimestamp = Moment.utc(execution.executedTimestamp)['tz'](this.timezoneIana);
                    execution.periodStart = Moment.utc(execution.periodStart)['tz'](this.timezoneIana);
                    execution.periodEnd = Moment.utc(execution.periodEnd)['tz'](this.timezoneIana);
                });

                this.loadingExecutions = false;
                this.cd.markForCheck();
            });
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.loadingExecutions = false;
            this.cd.markForCheck();
        });
    }

    onDelete(event) {
        this.loading = true;

        this.reportService.deleteReportSubscriptionById(this.reportSubscription).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Report is successfully deleted.'
            };

            setTimeout(() => {
                this.router.navigate(['/Reporting/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    showRerun() {
        this.rerunModal.showModal(this.reportSubscription.id);
    }

    rerunReport(event) {
        this.loading = true;
        this.rerunModal.hideModal();

        const data = new RerunReportSettings();
        data.reportSubscriptionId = event;
        data.start = moment(this.rerunBody.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day').unix();
        data.end = moment(this.rerunBody.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day').unix();

        this.reportService.rerunReport(data).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Rerun request is successfully processed.'
            };
            this.loading = false;
            this.cd.markForCheck();
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        })
    }
}
