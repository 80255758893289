import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getDefaultDpConfig, getGridLanguages, getGridButtons, clientSideExportAction } from 'app/common/gridhelper';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { WarehouseService } from 'app/services/warehouse/warehouse.service';
import { Warehouse } from 'app/models/warehouse.model';

// Moment timezone
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { roundAsString } from 'app/common/globals';
import { StorageHelper } from 'app/common/storagehelper';


window['moment'] = Moment;
mTZ()

@Component({
    selector: 'fh-warehouse-messages',
    templateUrl: 'messages.template.html'
})
export class WarehouseMessageViewComponent extends GridBase360Directive implements OnInit, OnDestroy {

    loading: boolean;
    asset: any;
    sub: any;
    messages: any;

    theMarker;

    excludingColumns = ['timestamp'];

    // Datepicker
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    to: any;
    from: any;
    daterangepickerModel: any[];
    permissions: {};
    languageLoaded: boolean;
    dtOptions;
    maxDate = new Date();
    sensors = [];
    constructorName = 'WarehouseMessageViewComponent';

    timezoneIana: string;
    token: string;

    error: any;
    success: any;
    limit = 1000;

    loadingLocations = false;
    previousLookupTimestamp;
    locationSubscription: any;
    loadingLocation: boolean;

    updatesActive = true;
    isLoaded = false;
    randomKey: number;
    warehouse: Warehouse;
    warehouseId: any;

    constructor(private warehouseService: WarehouseService, private translateService: TranslateService, private authenticationService: AuthenticationService, private route: ActivatedRoute, private router: Router, protected storageHelper: StorageHelper) {

        super(storageHelper);

        this.warehouse = null;
        this.sensors = [];

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().subtract(2, 'days').startOf('day').toDate(),
            Moment().endOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);
    }

    ngOnInit() {
        this.warehouse = new Warehouse;
        this.loading = true;
        this.warehouse.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.warehouseId = id;
            this.warehouseService.getWarehouseById(id).subscribe(warehouse => {
                this.warehouse = warehouse;

                this.dateChanged(null);

                this.translateService.get('general.date').subscribe(value => {
                    this.initGrid();
                    this.languageLoaded = true;
                });

                this.loading = false;
            }, error => {
                this.error = error;
                this.error.statusText = 'Error fetching warehouse';

                setTimeout(() => {
                    this.router.navigate(['/Warehouses/Overview']);
                }, 3000);
            });
        });
    }


    dateChanged(event) {
        const that = this;
        console.log('Changed date');
        if (event !== null) {
            this.loadingLocations = true;

            this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.ajax.reload();
            });
        }
    }

    initGrid(): void {

        console.log('Init grid');

        const that = this;

        const commonExportOptions: any = {
            exportOptions: {
                rows: ':visible'
            },
            rows: ':visible',
            modifier: {
                page: 'all',
                search: 'none'
            },
            columns: ['id_export:name', 'accountId:name', ':visible[tabindex]']
        };


        this.columns = [
            {
                name: 'timestamp',
                data: 'timestamp',
                width: 130,
                defaultContent: '-',
                title: this.translateService.instant('general.timestamp'),
                render: function (data, type, row) {
                    const date = Moment.utc(data)['tz'](that.timezoneIana);
                    return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('YYYY-MM-DD HH:mm:ss') + '</span>' : '';
                },
            },
            {
                name: 'zoneName',
                data: 'zoneName',
                defaultContent: '-',
                title: this.translateService.instant('general.zoneName')
            }, {
                name: 'sensorName',
                data: 'sensorName',
                defaultContent: '-',
                title: this.translateService.instant('general.sensorName')
            }, {
                name: 'sensorId',
                data: 'sensorId',
                defaultContent: '-',
                title: this.translateService.instant('general.sensorId')
            },
            {
                name: 'temperature',
                data: 'temperature',
                defaultContent: '-',
                title: this.translateService.instant('general.temperature'),
                iconName: 'fas fa-fw fa-thermometer-three-quarters',
                render: function (data, type, row) {
                    return data ? roundAsString(data, 1) + ' °C' : '-';
                },
            },
            {
                name: 'humidity',
                data: 'humidity',
                defaultContent: '-',
                title: this.translateService.instant('general.humidity'),
                iconName: 'fas fa-fw fa-humidity',
                render: function (data, type, row) {
                    return data ? roundAsString(data, 1) + '%' : '-';
                },
            }];

        this.dtOptions = {
            buttons: getGridButtons(commonExportOptions, 'warehousemessages_overview', this.translateService.instant('menu.messagesoverview'), clientSideExportAction),
            pagingType: 'simple_numbers',
            serverSide: false,
            processing: false,
            scrollX: true,
            colReorder: { fixedColumnsLeft: 1 },
            order: [[0, 'desc']],
            stateSave: true,
            stateSaveCallback: function (settings, data) {
              that.saveState(that.constructorName, data);
            },
            stateLoadCallback: function (_, callback) {
              (async () => {
                try {
                  const columnSettings = await that.loadState(that.constructorName);
                  that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
                  return columnSettings;
                } catch (e) {
                  that.error = {};
                  that.error.error = e;
                  that.error.statusText = 'Error fetching column settings';
      
                  return null;
                }
              })().then(result => {
                callback(result);
              });
            },
            ajax: (dataTablesParameters: any, callback) => {
                $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));

                this.warehouseService.getWarehouseDataByReference(this.warehouse.referenceId, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day')).subscribe(resp => {
                    callback({
                        recordsTotal: resp.length,
                        recordsFiltered: resp.length,
                        data: resp
                    });
                }, error => {
                    this.success = null;
                    this.error = error;
                    this.error.statusText = 'Error fetching data';
                    this.loading = false;
                });
            },
            initComplete: function (settings, json) {
                that.loading = false;
                that.loadingLocations = false;

                console.log('init complete');
                that.checkFilters();
                that.drawFilterRow();
                that.loading = false;
            },
            colVis: {
                restore: this.translateService.instant('general.restore'),
                showAll: this.translateService.instant('general.showAll'),
                showNone: this.translateService.instant('general.hideAll'),
            },
            columns: this.columns,
            pageLength: 25,
            lengthMenu: [[10, 17, 25, 50, 200, -1], [10, 17, 25, 50, 200, this.translateService.instant('general.all')]],
            language: getGridLanguages(this.translateService),
            rowCallback: (row, data) => { }
        };
    }
}


