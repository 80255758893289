<div class="ibox">
    <div class="ibox-title">
        <h5>
            <i class="fas fa-fw fa-map"></i> {{'devicedetails.nearby' | translate }}</h5>
    </div>
    <div class="ibox-content slick" style="min-height:280px;position:relative">
        <ngx-loading [show]="loading"></ngx-loading>
        <table style="width:100%;" class="table  table-striped nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <td style="width: 0px"></td>
                    <td>{{'general.name' | translate }}</td>
                    <td>{{'general.distance' | translate }}</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let device of nearbyDevices">
                    <td style="width: 0px">
                        <a class='btn btn-primary btn-outline btn-grid' href='#/DeviceDetails/Index/{{device.id}}'>
                            <i class='fas fa-fw fa-info'></i> <span class="hidden-sm hidden-xs">{{'general.details' | translate }}</span></a>
                    </td>
                    <td>{{device.name}}</td>
                    <td style="width: 150px">{{device.distance}} km</td>
                </tr>
                <tr *ngIf="nearbyDevices.length == 0">
                    <td colspan="3">{{'general.nodatafound' | translate }}</td>
                </tr>
            </tbody>
        </table>
        <div style="clear:both"></div>
    </div>
</div>