import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode, languages, userTypes, AssetDisplayName, DistanceUnit, VolumeUnit } from 'app/common/enums';
import { AccountInventory } from 'app/models/account.model';
import { AppUser } from 'app/models/user.model';
import { AccountService } from 'app/services/account/account.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { UserRoleService } from 'app/services/users/userRole.service';

@Component({
    selector: 'fh-account-user-details',
    templateUrl: 'accountUserDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AccountUserDetailsComponent implements OnInit {

    @Input() user: AppUser = new AppUser();
    @Input() formMode: any = FormMode.read;
    @Output() onAccountChanged = new EventEmitter();
    @Output() onUserRoleChanged = new EventEmitter();

    accounts: AccountInventory[] = [];

    passwordConfirmation = '';
    languages: { name: string; path: any; }[];
    permissions: {};
    userTypes = [];
    displayNameOptions = [];
    distanceUnits = [];
    volumeUnits = [];
    userRoles = [];
    loadingUserRoles = false;
    isImpersonated: boolean;
    loadingAccounts = false;

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private accountService: AccountService, private userRoleService: UserRoleService) {
        this.permissions = this.authenticationService.permissions;
        this.isImpersonated = this.authenticationService.getIsImpersonated();

        this.languages = Object.keys(languages)
            .filter(k => typeof languages[k] === 'string')
            .map(n => ({ name: n, path: languages[n] }));

        this.userTypes = Object.keys(userTypes)
            .filter(k => typeof userTypes[k] === 'string')
            .map(n => ({ value: +n, name: userTypes[n] }))
            .sort((a, b) => a.name.localeCompare(b.name));

        this.displayNameOptions = Object.keys(AssetDisplayName)
            .filter(k => typeof AssetDisplayName[k] === 'string')
            .map(n => ({ value: +n, name: AssetDisplayName[n] }));

        this.distanceUnits = Object.keys(DistanceUnit)
            .filter(k => typeof DistanceUnit[k] === 'string')
            .map(n => ({ value: +n, name: DistanceUnit[n] }));

        this.volumeUnits = Object.keys(VolumeUnit)
            .filter(k => typeof VolumeUnit[k] === 'string')
            .map(n => ({ value: +n, name: VolumeUnit[n] }));

        this.user.whitelabel = this.authenticationService.getWhitelabel();
    }

    ngOnInit(): void {
        if (this.formMode === FormMode.add) {
            this.user.clientPermissionId = -1;

            if (this.user.userType == null) {
                this.user.userType = this.userTypes[0].name;
            }

            if (this.user.language == null) {
                this.user.language = this.languages[0].name;
            }

            if (this.user.password != null) {
                this.passwordConfirmation = this.user.password;
            }
        }

        this.loadingAccounts = true;

        this.accountService.getAccounts().subscribe(result => {
            this.accounts = result.filter(x => x.deviceCount > 0);

            if (this.accounts.length === 1) {
                console.log('Setting account');
                this.user.accountId = this.accounts[0].id;
                this.onAccountChanged.emit(this.user.accountId);
            }

            this.loadingAccounts = false;
            this.cd.markForCheck();
        });

        this.loadingUserRoles = true;
        this.userRoleService.getUserRoles().subscribe(result => {
            this.userRoles = result;
            this.loadingUserRoles = false;
            this.cd.markForCheck();
        })
    }


    accountChanged() {
        this.onAccountChanged.emit(this.user.accountId);
    }

    userRoleChanged() {
        this.onUserRoleChanged.emit(this.user.userRoleId);
    }
}
