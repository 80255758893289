<div class="col-lg-12">
    <div class='horizontalMenuWrapper'>
        <ul>
            <li [ngClass]="{active: activeRoute('ResellerDetails/Index')}">
                <a [routerLink]="['/ResellerDetails/Index/', resellerId]">
                    <i class="fa fa-fw fa-city"></i>
                    <div>{{ 'menu.dashboard' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Log']" [ngClass]="{active: activeRoute('ResellerDetails/Log')}">
                <a [routerLink]="['/ResellerDetails/Log/', resellerId]">
                    <i class="fas fa-fw fa-file-alt"></i>
                    <div>{{ 'menu.log' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class='horizontalMenuWrapper' style="float:right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>