<form #groupForm="ngForm" style="width: 100%; border-top: 0px">
    <table class="table nowrap dtr-inline no-footer" *ngIf="device">
        <thead>
            <tr>
                <th class="small_padding">{{ 'general.imei' | translate}}</th>
                <th class="small_padding">{{ 'general.assetName' | translate}}</th>
                <th class="small_padding">{{ 'general.assetCode' | translate}}</th>
                <th class="small_padding">{{ 'general.assignmentEnd' | translate}} *</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="small_padding">
                    <input readonly class="form-control table-inline" [value]="device?.name">
                </td>
                <td class="small_padding">
                    <input readonly class="form-control table-inline" [value]="device?.asset?.name">
                </td>
                <td class="small_padding">
                    <input readonly class="form-control table-inline" [value]="device?.asset?.code">
                </td>
                <td class="small_padding"><input [ngStyle]="{'border': dateEnd.errors ? '1px solid red' : ''}"
                        class="form-control table-inline" [(ngModel)]="archiveDate"
                        (ngModelChange)="dateChanged($event)" name="dateEnd" #dateEnd="ngModel" required
                        autocomplete="off" [bsConfig]="dpConfig" bsDatepicker>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table nowrap dtr-inline no-footer" *ngIf="driver">
        <thead>
            <tr>
                <th class="small_padding">{{ 'general.name' | translate}}</th>
                <th class="small_padding">{{ 'general.assignmentStart' | translate}}</th>
                <th class="small_padding">{{ 'general.assignmentEnd' | translate}} *</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="small_padding">
                    <input readonly class="form-control table-inline" [value]="driver?.driverName">
                </td>
                <td class="small_padding">
                    <input readonly class="form-control table-inline"
                        [value]="driver?.dateStart | amDateFormat: 'LLL' ">
                </td>
                <td class="small_padding"><input [ngStyle]="{'border': dateEnd.errors ? '1px solid red' : ''}"
                        class="form-control table-inline" [(ngModel)]="archiveDate"
                        (ngModelChange)="dateChanged($event)" name="dateEnd" #dateEnd="ngModel" required
                        autocomplete="off" [bsConfig]="dpConfig" bsDatepicker>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table nowrap dtr-inline no-footer" *ngIf="trailer">
        <thead>
            <tr>
                <th class="small_padding">{{ 'general.name' | translate}}</th>
                <th class="small_padding">{{ 'general.assignmentStart' | translate}}</th>
                <th class="small_padding">{{ 'general.assignmentEnd' | translate}} *</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="small_padding">
                    <input readonly class="form-control table-inline" [value]="trailer?.trailerName">
                </td>
                <td class="small_padding">
                    <input readonly class="form-control table-inline"
                        [value]="trailer?.dateStart | amDateFormat: 'LLL' ">
                </td>
                <td class="small_padding"><input [ngStyle]="{'border': dateEnd.errors ? '1px solid red' : ''}"
                        class="form-control table-inline" [(ngModel)]="archiveDate"
                        (ngModelChange)="dateChanged($event)" name="dateEnd" #dateEnd="ngModel" required
                        autocomplete="off" [bsConfig]="dpConfig" bsDatepicker>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table nowrap dtr-inline no-footer" *ngIf="project">
        <thead>
            <tr>
                <th class="small_padding">{{ 'general.name' | translate}}</th>
                <th class="small_padding">{{ 'general.assignmentStart' | translate}}</th>
                <th class="small_padding">{{ 'general.assignmentEnd' | translate}} *</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="small_padding">
                    <input readonly class="form-control table-inline" [value]="project?.projectName">
                </td>
                <td class="small_padding">
                    <input readonly class="form-control table-inline"
                        [value]="project?.dateStart | amDateFormat: 'LLL' ">
                </td>
                <td class="small_padding"><input [ngStyle]="{'border': dateEnd.errors ? '1px solid red' : ''}"
                        class="form-control table-inline" [(ngModel)]="archiveDate"
                        (ngModelChange)="dateChanged($event)" name="dateEnd" #dateEnd="ngModel" required
                        autocomplete="off" [bsConfig]="dpConfig" bsDatepicker>
                </td>
            </tr>
        </tbody>
    </table>
</form>