import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { DeviceSettingIds, DeviceTypes } from 'app/common/enums';
import { Device, DeviceSettingChange } from 'app/models/device.model';
import { DeviceType } from 'app/models/devicetype.model';

@Component({
    selector: 'fh-devicesettingchanges',
    templateUrl: 'deviceSettingChanges.template.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DeviceSettingChangesComponent implements OnInit, OnChanges {

    @Input() deviceSettingChange: DeviceSettingChange;
    @Input() device: Device;

    settingIds: DeviceSettingIds[] = [];
    valueInputIsDisabled = false;

    deviceType: DeviceType;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
        this.populateSettingsIds();
    }

    checkIfValueInputIsNeeded(id: number) {
        this.deviceSettingChange.settingValue = '';

        if (Number(+id) === DeviceSettingIds.EnforaVersionUpgrade || Number(+id) === DeviceSettingIds.GetConfiguration) {
            this.valueInputIsDisabled = true;
        } else {
            this.valueInputIsDisabled = false;
        }
    }

    populateSettingsIds() {
        this.settingIds = [];

        // Add EnforaVersionUpgrade option only for devices where applicable
        let typeId = +DeviceTypes.EnforaMiniMt;
        if (this.device !== undefined) {
            typeId = +this.device.deviceTypeId;
        }

        switch (typeId) {
            case DeviceTypes.EnforaMiniMt:
            case DeviceTypes.EnforaSpider2500:
            case DeviceTypes.EnforaSpider3000:
            case DeviceTypes.EnforaSpider4000:
            case DeviceTypes.EnforaSpider4100:
            case DeviceTypes.EnforaSpiderAT3000:
            case DeviceTypes.EnforaSpiderMt:
            case DeviceTypes.EnforaSpiderMt1200:
            case DeviceTypes.EnforaSpiderMt2238:
            case DeviceTypes.EnforaSpiderMt2356:
            case DeviceTypes.EnforaSpiderMtWithAccel:
            case DeviceTypes.EnforaSpiderWithRmac:
                this.settingIds.push(DeviceSettingIds.EnforaVersionUpgrade);
                break;
        }

        // These are the current supported settings for now. (back-end filters to)
        this.settingIds.push(DeviceSettingIds.HomeInterval);
        this.settingIds.push(DeviceSettingIds.RoamingInterval);
        this.settingIds.push(DeviceSettingIds.MotionInterval);
        this.settingIds.push(DeviceSettingIds.NoGpsInterval);
        this.settingIds.push(DeviceSettingIds.HeadingChangeThresholdInDegrees);
        this.settingIds.push(DeviceSettingIds.GetConfiguration);
        this.settingIds.push(DeviceSettingIds.Manual);
        this.settingIds.push(DeviceSettingIds.RfidListChange);
    }
}
