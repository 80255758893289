<ng-container *ngIf="deviceForm; else loadingDevices">
    <fh-wizard [(step)]="step" (finish)="onCheckOutFinish()" [canContinue]="deviceForm">
        <fh-wizard-step title='general.archiveAssets' icon='fa-user' [validate]="{valid: deviceForm?.controls.length}" (open)="initForm()">
            <ng-container *ngIf="deviceForm?.controls.length; else noValidDevices">
                <div class="wizard-content" style="padding-bottom: 10px">
                    {{ 'wizard.optionalRemoveFromAccount' | translate }}
                </div>
            </ng-container>
            <ng-template #noValidDevices>
                <alert type="warning">
                    <i class="fas fa-fw fa-info"></i> {{ 'wizard.optionalReplaceDeviceWarn' | translate }}
                </alert>
            </ng-template>
        </fh-wizard-step>
        <fh-wizard-step title="Remarks" icon='fa-file-pdf'>
            <div class="wizard-content" style="padding-bottom: 10px">
                {{ 'wizard.optionalComment' | translate }}
            </div>
            <div class="wizard-content">
                <textarea class=" form-control noresize stickypad" style="height:100px; width:100%; resize: none"
                    placeholder="{{ 'placeholder.comments' | translate}}" [(ngModel)]="logItem"></textarea>
            </div>
        </fh-wizard-step>
        <fh-wizard-step title="Summary" icon='fa-summary-pdf'>
            <div class="wizard-content" style="padding-bottom: 10px" [innerHTML]="'wizard.summary' | translate:{ count: deviceForm?.controls?.length }">
            </div>
            <div class="ibox-content slick popupOverflow" style="position: relative; min-height:72px; overflow: auto">
                <table class="table nowrap dtr-inline no-footer table-summary">
                    <thead>
                        <tr>
                            <th class="small_padding">{{ 'general.imei' | translate}}</th>
                            <th class="small_padding">{{ 'general.assetName' | translate}}</th>
                            <th class="small_padding">{{ 'general.assetCode' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let formGroup of deviceForm?.controls; let i = index;">
                            <tr>
                                <td class="small_padding">
                                    <input [value]="formGroup.get('imei').value" readonly class="form-control table-inline">
                                </td>
                                <td class="small_padding">
                                    <input [value]="formGroup.get('name').value" readonly class="form-control table-inline">
                                </td>
                                <td class="small_padding">
                                    <input [value]="formGroup.get('code').value" readonly class="form-control table-inline">
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </fh-wizard-step>
    </fh-wizard>
</ng-container>
<ng-template #loadingDevices>
    <ngx-loading [show]="true"></ngx-loading>
</ng-template>