import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountInventory } from 'app/models/account.model';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { ResellerService } from 'app/services/resellers/resellers.service';
import { Reseller } from 'app/models/reseller.model';

@Component({
    selector: 'fh-reseller-log',
    templateUrl: 'resellerLog.template.html'
})
export class ResellerLogViewComponent implements OnInit {
    resellerLog: AuditLog[];
    sub;
    loading = false;

    constructorName = 'ResellerLogViewComponent';

    error: any;
    success: any;
    reseller: Reseller;
    resellerId: any;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private resellerService: ResellerService, private route: ActivatedRoute, private router: Router) {
        this.reseller = null;
        this.resellerLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.reseller = new Reseller;
        this.reseller.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.resellerId = id;
            this.resellerService.getResellerById(id).subscribe(result => {
                this.reseller = result;

                if (this.reseller == null) {
                    this.router.navigate(['/Resellers/Overview'])
                }

                this.auditLogService.getAuditLogByAccount(id).subscribe(resellerLog => {
                    console.log('loading done');
                    this.resellerLog = resellerLog;
                    this.loading = false;
                }, error => {
                    this.error = error;
                    this.loading = false;
                });
            });
        });
    }
}
