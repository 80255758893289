<div class="ibox-content slick" style="position:relative;">
    <table style="width:100%" class="table nowrap dtr-inline no-footer table-fixed">
        <tbody *ngIf="!fuelThresholds">
            <tr>
                <td colspan="4">
                    {{ 'general.noDataFound' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="fuelThresholds">
            <tr>
                <td class="row_opening" style="width:50%">
                    {{ 'general.fuelCapacity' | translate}}1 L
                    <span class="error" *ngIf="fuelTank1Capacity.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="width:50%">
                    <input type="number" [readonly]="formMode == 1" name="fuelTank1Capacity" 
                        class="form-control table-inline" placeholder="{{ 'placeholder.liter' | translate}}"
                        [(ngModel)]="fuelThresholds.fuelTank1Capacity" #fuelTank1Capacity="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening" style="width:50%">
                    {{ 'general.fuelCapacity' | translate}}2 L
                    <span class="error" *ngIf="fuelTank2Capacity.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="width:50%">
                    <input type="number" [readonly]="formMode == 1" name="fuelTank2Capacity"
                        class="form-control table-inline" placeholder="{{ 'placeholder.liter' | translate}}"
                        [(ngModel)]="fuelThresholds.fuelTank2Capacity" #fuelTank2Capacity="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening" style="width:50%">
                    {{ 'general.fillupThreshold' | translate}}%
                    <span class="error" *ngIf="fillupThreshold.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="width:50%">
                    <input type="number" [readonly]="formMode == 1" name="fillupThreshold" 
                        class="form-control table-inline" placeholder="{{ 'placeholder.percentage' | translate}}"
                        [(ngModel)]="fuelThresholds.fillupThreshold" #fillupThreshold="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening" style="width:50%">
                    {{ 'general.theftThreshold' | translate}}%
                    <span class="error" *ngIf="theftThreshold.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="width:50%">
                    <input type="number" [readonly]="formMode == 1" name="theftThreshold" 
                        class="form-control table-inline" placeholder="{{ 'placeholder.percentage' | translate}}"
                        [(ngModel)]="fuelThresholds.theftThreshold" #theftThreshold="ngModel">
                </td>
            </tr>
    </table>
</div>