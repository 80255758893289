import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { Reseller } from 'app/models/reseller.model';
import { EntityType } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { WaslService } from 'app/services/wasl/wasl.service';
// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { ResellerService } from 'app/services/resellers/resellers.service';
import { UserService } from 'app/services/users/user.service';
import { AppUser } from 'app/models/user.model';
import { FhChartService } from 'app/services/charts/charts.service';
import { AccountService } from 'app/services/account/account.service';
import { AccountInventory } from 'app/models/account.model';

window['moment'] = Moment;
mTZ()


@Component({
    providers: [FhChartService],
    selector: 'fh-fh-reseller-details',
    templateUrl: 'resellerDetails.template.html'
})
export class ResellerDetailsViewComponent implements OnInit {
    sub: any;
    reseller = new Reseller;
    device: any;
    tagType = EntityType.Reseller.valueOf();
    formMode = FormMode.read;
    formModeWasl = FormMode.read;

    loading = false;
    saving = false;
    loadingResellerWasl = false;

    filter;
    filterAccounts;

    error: any;
    success: any;

    permissions: {};
    waslSuccess: { statusText: string; success: any; };
    waslError: any;

    activeDevices = 0;
    activeDrivers = 0;
    activeGeofences = 0;
    activeDevicesRecursiveCount = 0;

    geofences = [];
    loadingGeofences = false;
    loadingUsers = false;
    loadingKpi = false;
    loadingAccounts = false;
    users: AppUser[];

    loadingDeviceCount = false;
    chartDeviceCount: any;

    // Pagination
    totalItems = 0;
    currentPage = 1;
    currentPageAccounts = 1;
    smallnumPages = 0;
    deviceCountData = [];
    distanceChartData = [];
    loadingCount = false;
    loadingChildren = false;
    children = [];

    permissionName = 'Resellers_View';
    accounts: AccountInventory[] = [];
    resellerId: any;

    constructor(private router: Router, private accountService: AccountService, private cd: ChangeDetectorRef, private authentication: AuthenticationService, private resellerService: ResellerService, private route: ActivatedRoute, private userService: UserService) {
        this.permissions = this.authentication.permissions;
    }

    ngOnInit() {

        this.loading = true;
        this.loadingChildren = true;
        this.loadingDeviceCount = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.resellerId = id;
            this.resellerService.getResellerById(id).subscribe(reseller => {
                this.reseller = reseller;
                this.loading = false;
                this.loadingKpi = true;

                this.resellerService.getKPISCount(id).subscribe(kpiResults => {
                    this.loadingKpi = false;
                    this.activeDevices = kpiResults.deviceCount;
                    this.activeGeofences = kpiResults.geofenceCount;
                    this.activeDrivers = kpiResults.driverCount;
                    this.activeDevicesRecursiveCount = kpiResults.deviceRecursiveCount;
                    this.cd.markForCheck();
                });

                this.resellerService.getDevicesAdded(id).subscribe((devices) => {
                    this.loadingDeviceCount = false;
                    devices = devices.sort((a, b) => (a.date < b.date ? -1 : 1));
                    this.deviceCountData = devices;
                    this.cd.markForCheck();
                });

                this.loadingAccounts = true;
                this.accountService.getAccountByResellerId(id).subscribe(accounts => {
                    this.accounts = accounts.filter(x => x.isActive === true);
                    this.loadingAccounts = false;
                });

            }, error => {
                this.error = error;
                this.error.statusText = 'Error fetching reseller';

                this.cd.markForCheck();

                setTimeout(() => {
                    this.router.navigate(['/Resellers/Overview']);
                }, 3000);
            });

            this.resellerService.getResellersByParentId(id).subscribe(children => {
                this.children = children;
                this.loadingChildren = false;
            }, error => {
                this.error = error;
                this.error.statusText = 'Error fetching reseller children';
            });
        });
    }

    // Form
    onDelete() {
        this.error = 'Deleting is not implemented';
    }

    onSave() {
        this.saving = true;

        this.resellerService.updateReseller(this.reseller).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Reseller is successfully updated.'
            };

            this.saving = false;
            this.cd.markForCheck();

            this.setFormMode(FormMode.read);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getResellerInfo();
        }
    }

    setFormModeWasl(mode) {
        this.formModeWasl = mode;

        if (this.formModeWasl === FormMode.read) {
            this.loadingResellerWasl = true;
            this.getResellerInfo();
        }
    }

    getResellerInfo() {
        if (this.loading !== true && this.loadingResellerWasl !== true) {
            this.loading = true;
        }

        this.resellerService.getResellerById(this.reseller.id).subscribe(reseller => {
            this.reseller = reseller;
            this.loading = false;
            this.loadingResellerWasl = false;
        });
    }

    // Pagination
    setPage(pageNo: number): void {
        this.currentPage = pageNo;
    }

    pageChanged(event: any): void {
        console.log('Page changed to: ' + event.page);
        console.log('Number items per page: ' + event.itemsPerPage);
    }
}
