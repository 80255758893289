<div class="col-lg-12">
    <div class='horizontalMenuWrapper'>
        <ul>
            <li *ngIf="permissions['Platform_IsReseller']"
                [ngClass]="{active: activeRoute('Settings/ClientAnnouncement')}">
                <a [routerLink]="['/Settings/ClientAnnouncements']">
                    <i class="fas fa-fw fa-calendar-exclamation"></i>
                    <div>{{ 'general.clientAnnouncements' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsReseller']"
                [ngClass]="{active: activeRoute('Settings/ArchiveRetentionRule')}">
                <a [routerLink]="['/Settings/ArchiveRetentionRules/Overview']">
                    <i class="fas fa-fw fa-alarm-exclamation"></i>
                    <div>{{ 'general.archiveRetentionRules' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsAdministrator']"
                [ngClass]="{active: activeRoute('Settings/ServiceAnnouncement')}">
                <a [routerLink]="['/Settings/ServiceAnnouncements']">
                    <i class="fas fa-fw fa-exclamation-triangle"></i>
                    <div>{{ 'general.serviceAnnouncements' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class='horizontalMenuWrapper' style="float:right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>