import { Component, ViewChild, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { GridBase360Directive } from 'app/common/360Grid.base';
import { FormMode } from 'app/common/enums';
import { SearchService } from 'app/services/search/search.service';
import { AppUser } from 'app/models/user.model';
import { generatePassword } from 'app/common/globals';
import { StorageHelper } from 'app/common/storagehelper';

@Component({
    selector: 'fh-sharing-create-modal',
    templateUrl: 'sharingCreate.template.html',
    providers: [SearchService],
})
export class SharingCreateViewerComponent extends GridBase360Directive implements OnInit, OnDestroy {
    @ViewChild('autoShownModal') autoShownModal: ModalDirective;
    @Input() accountId;
    @Output() onSelect = new EventEmitter();

    modalRef: BsModalRef;
    isModalShown = false;

    token: string;

    dropdown_IssueType = '';

    dtOptions = {};

    min: number;
    max: number;

    loading = false;
    languageLoaded: boolean;
    permissions: {};

    formMode = FormMode.add;
    userName;
    loadingUser = false;

    user: AppUser;

    constructor(private authenticationService: AuthenticationService, private searchService: SearchService, protected storageHelper: StorageHelper) {
        super(storageHelper);

        this.token = this.authenticationService.getAuthToken();
    }

    close() {
        this.user = undefined;
        this.hideModal();
    }

    onHidden() {
        this.hideModal();
    }

    findUsers() {
        this.loadingUser = true;

        this.searchService.searchUsers(this.userName).subscribe((result) => {

            this.user = new AppUser();
            this.user.accountId = this.accountId;
            this.user.name = this.userName;

            if (result?.length) {
                this.user.id = result[0].id;
            } else {
                this.user.password = generatePassword(8);
            }

            this.loadingUser = false;
        });
    }

    finish() {
        if (!this.user.id) {
            this.user.timezoneIana = this.authenticationService.getTimeZoneIana();
            this.user.whitelabel = this.authenticationService.getWhitelabel();
            this.user.language = this.authenticationService.getServerCulture();
            this.user.volumeUnit = +this.authenticationService.getVolumeUnit();
            this.user.distanceUnit = +this.authenticationService.getDistanceUnit();

            this.onSelect.emit(this.user);
        } else {
            this.onSelect.emit({ id: this.user.id });
        }

        this.close();
    }

    ngOnInit(): void {
        this.permissions = this.authenticationService.permissions;
    }

    // Model
    public showModal(): void {
        console.log('show modal');
        this.isModalShown = true;
    }

    public hideModal(): void {
        this.isModalShown = false;
    }
}
