import {
    Component, AfterViewInit, Input, ElementRef, ViewChild, OnInit, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CarSharingService } from 'app/services/carsharing/carsharing.service';

const noop = () => {
};

@Component({
    selector: 'fh-garage-selector',
    templateUrl: './garageSelector.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: GarageSelectorComponent }
    ]
})

export class GarageSelectorComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
    @Input() placeholder: string;
    @Input() clearable = true;
    @Input() readonly = false;

    @Output() onChanged = new EventEmitter();

    garages = [];
    loadingGarages = true;

    @ViewChild('input') el: ElementRef;

    private _value: string;
    private _onChange: (_: any) => void = noop;

    get value(): any {
        return this._value;
    };

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this._onChange(v);
            this.cd.markForCheck();
        }
    }

    constructor(private carsharingService: CarSharingService, private cd: ChangeDetectorRef) {
    }

    garageChanged() {
        this.onChanged.emit(this.value);
        this.loadingGarages = false;
        this.cd.markForCheck();
    }

    ngOnChanges() {
        setTimeout(() => {
            this.loadGarages();
        }, 0);
    }

    loadGarages() {
        this.loadingGarages = true;
        this.cd.markForCheck();

        this.carsharingService.getGarages().subscribe(result => {
            this.garages = result;

            this.loadingGarages = false;
            this.cd.markForCheck();
        });
    }

    ngOnInit() {
        this.loadGarages();
    }

    ngAfterViewInit() {
        const __this = this;
    }

    writeValue(value: any) {
        this._value = value;
        this.cd.markForCheck();
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
        this.cd.markForCheck();
    }

    registerOnTouched(fn: any) {
        this.cd.markForCheck();
    }
}
