import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';
import { Config } from '../../models/config.model';
import { ErrorLog } from '../../models/errorlog.model';
import { AuthenticationService } from '../authentication/authentication.service';

// Moment timezone
import * as moment from 'moment-timezone';
import { environment } from 'environments/environment';
@Injectable()
export class ErrorLogService {

    url: string;
    token: string;
    public config: Config;
    timezoneIana: string;

    // I initialize the service.
    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        this.http = http;
        this.url = this.authenticationService.getWebserviceURL('errorlog');
        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.config = this.environmentToConfig();
    }

    private environmentToConfig(): Config {
        const cfg = new Config();

        cfg.version = environment.version;
        cfg.AuthenticationUrl = window['server_variables'].AuthenticationUrl;
        cfg.ConsumerToken = environment.ConsumerToken;
        cfg.Debug = environment.Debug;
        cfg.Environment = environment.Environment;
        cfg.SSOAuthenticationUrl = environment.SSOAuthenticationUrl;
        cfg.SSOPostLogoutRedirectUrl = environment.SSOPostLogoutRedirectUrl;
        cfg.SSORedirectUrl = environment.SSORedirectUrl;

        return cfg;
    }

    getPagingUrl(start, end) {
        this.url = this.authenticationService.getWebserviceURL('errorlog');
        return this.url + 'Paging?startRange=' + start.unix() + '&endRange=' + end.unix();
    }

    // I log the given error to various aggregation and tracking services.
    public logError(error: any): void {
        // Internal tracking.
        this.sendToConsole(error);
        this.sendToServer(error);
    }

    public logErrorString(caller: any, error: any): void {
        this.logError(new CustomError(caller + ' , ' + error));
    }

    // I send the error the browser console (safely, if it exists).
    private sendToConsole(error: any): void {
        if (console && console.group && console.error) {
            console.group('Error Log Service');
            console.error(error);
            console.error(error.message);
            console.error(error.stack);
            console.groupEnd();
        }
    }

    getErrorLogs(): Observable<ErrorLog[]> {

        this.token = AuthenticationService.getStaticToken();
        this.url = AuthenticationService.getStaticClusterUrl();

        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting errorlog from service');
        return this.http.get(this.url + 'errorlog', { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getErrorLogById(id: string): Observable<ErrorLog> {
        this.token = AuthenticationService.getStaticToken();
        this.url = AuthenticationService.getStaticClusterUrl();
        this.timezoneIana = this.authenticationService.getTimeZoneIana();

        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        console.log('getting errorlog from service');
        return this.http.get(this.url + 'errorlog/' + id, { headers: headers })
            .map((data) => {
                const parsedResponse = this.parseReponseDetails(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    parseResponse(json: any): ErrorLog[] {
        const ident = 1;
        const errorlogs: ErrorLog[] = [];

        json.forEach(item => {
            const errorlog = this.parseReponseDetails(item);
            errorlogs.push(errorlog);
        });

        return errorlogs;
    }

    parseReponseDetails(item) {
        const errorlog = new ErrorLog();
        errorlog.id = item.id;
        errorlog.description = item.description;
        errorlog.stack = item.stack;
        errorlog.location = item.location;
        errorlog.username = item.userName;
        errorlog.message = item.message;
        errorlog.createdDate = moment.utc(item.createdDate)['tz'](this.timezoneIana);

        return errorlog;
    }

    private handleError(error: Response) {
        console.log(error);
        return throwError(error);
    }

    // I send the error to the server-side error tracking end-point.
    private sendToServer(error: any): void {

        console.log('Error send to server');

        this.token = AuthenticationService.getStaticToken();
        this.url = AuthenticationService.getStaticClusterUrl();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + this.token });

        const theError = {
            Location: window.location.href,
            Name: error.name,
            Message: error.message,
            Description: 'FM APP',
            Stack: error.stack
        };

        if (this.config && !this.config.Debug) {
            this.http.post(this.url + 'ErrorLog', theError, { headers: headers }).subscribe(result => {
                console.log('Error posted');
            }, hasError => {
                console.log(hasError);
            });
        } else {
            console.log('Skipping error post');
            console.log(theError);
        }
    }
}

export function CustomError(message) {
    this.name = 'Custom Error';
    this.message = message || 'Default Message';
    this.stack = (new Error()).stack;
}
