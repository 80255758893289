import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Trailer } from 'app/models/trailer.model';
import { TrailerService } from 'app/services/trailer/trailer.service';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';

@Component({
    selector: 'fh-trailer-log',
    templateUrl: 'trailerLog.template.html'
})
export class TrailerLogViewComponent implements OnInit {
    trailerLog: AuditLog[];
    sub;
    trailer: Trailer;
    loading = false;

    error: any;
    success: any;

    permissionName = 'FleetManagement_Log';
    trailerId: any;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private trailerService: TrailerService, private route: ActivatedRoute, private router: Router) {
        this.trailer = null;
        this.trailerLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.trailer = new Trailer;
        this.trailer.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.trailerId = id;
            this.trailerService.getTrailerById(id).subscribe(trailer => {
                this.trailer = trailer;

                if (this.trailer == null) {
                    this.router.navigate(['/Trailers/Overview'])
                }

                this.auditLogService.getAuditLogByTrailer(id).subscribe(deviceLog => {
                    console.log('loading done');
                    this.trailerLog = deviceLog;
                    this.loading = false;
                }, error => {
                    this.error = error;
                    this.loading = false;
                });
            });
        });
    }
}
