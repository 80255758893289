import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { DriverService } from 'app/services/driver/driver.service';
import { Driver } from 'app/models/driver.model';
import { DriverGroup } from 'app/models/group.model';
import { DriverGroupsService } from 'app/services/driver/driverGroups.service';

@Component({
    selector: 'fh-driver-group-log',
    templateUrl: 'driverGroupLog.template.html'
})
export class DriverGroupLogViewComponent implements OnInit {
    driverGroupLog: AuditLog[];
    sub;
    driverGroup: DriverGroup;
    loading = false;

    permissionName = 'FleetManagement_Log';
    constructorName = 'DriverLogViewComponent';

    error: any;
    success: any;
    driverId: any;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private driverGroupService: DriverGroupsService, private route: ActivatedRoute, private router: Router) {
        this.driverGroup = null;
        this.driverGroupLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.driverGroup = new DriverGroup();

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.driverId = id;
            this.driverGroupService.getDriverGroupById(id).subscribe(driverGroup => {
                this.driverGroup = driverGroup;

                if (this.driverGroup == null) {
                    this.router.navigate(['/DriverGroups/Overview'])
                }

                this.auditLogService.getAuditLogByDriverGroup(id).subscribe(deviceLog => {
                    console.log('loading done');
                    this.driverGroupLog = deviceLog;
                    this.loading = false;
                }, error => {
                    this.error = error;
                    this.loading = false;
                });
            });
        });
    }
}
