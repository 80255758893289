<div class="col-lg-12">
    <div class='horizontalMenuWrapper'>
        <ul>
            <li [ngClass]="{'active': activeRoute('Maintenances/Overview')}"><a href="/#/Maintenances/Overview/"><i
                        class="fas fa-fw fa-car-mechanic"></i>
                    <div>{{ 'general.occurrences' |translate }}</div>
                </a></li>
            <li [ngClass]="{'active': activeRoute('Maintenances/Due')}"><a href="/#/Maintenances/Due/"><i
                        class="fas fa-fw fa-car-mechanic"></i>
                    <div>{{ 'general.due' |translate }}</div>
                </a></li>
            <li *ngIf="permissions['Maintenances_View']"
                [ngClass]="{active: activeRoute('Maintenances/MaintenanceType')}">
                <a [routerLink]="['/Maintenances/MaintenanceTypes/Overview']">
                    <i class="fas fa-fw fa-screwdriver-wrench"></i>
                    <div>{{ 'menu.maintenanceTypes' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class='horizontalMenuWrapper' style="float:right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>