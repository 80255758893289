<div class="col-lg-12">
    <div class='horizontalMenuWrapper'>
        <ul>
            <li [ngClass]="{'active': activeRoute('Warehouses/Overview')}"><a href="/#/Warehouses/Overview/"><i
                        class="fas fa-fw fa-warehouse"></i>
                    <div>{{ 'general.overview' |translate }}</div>
                </a></li>
        </ul>
    </div>
    <div class='horizontalMenuWrapper' style="float:right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>