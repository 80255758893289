import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device, EngineHourCalibration } from 'app/models/device.model';
import { AuditLog } from '../../models/auditlog.model';
import { DeviceService } from '../../services/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { FormMode } from 'app/common/enums';
import { FhChartService } from 'app/services/charts/charts.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LocationService } from 'app/services/locations/locations.service';
import { AssetService } from 'app/services/asset/asset.service';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { Asset } from 'app/models/asset.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

import * as Highcharts from 'highcharts';

window['moment'] = Moment;
mTZ()
@Component({
    selector: 'fh-device-enginehour',
    providers: [FhChartService],
    templateUrl: 'enginehour.template.html'
})
export class DeviceEngineHourViewComponent implements OnInit {
    Highcharts: typeof Highcharts = Highcharts;

    @ViewChild('deleteModal', { static: false }) deleteModal: ConfirmationModalComponent;

    deviceLog: AuditLog[];
    sub;
    device: Device;
    deviceId;

    loading = false;
    saving = false;
    error: any;
    success: any;
    warning: any;

    formMode = FormMode.add;

    loadingHistory = false;
    loadingCacher = false;
    loadingAssets: boolean;
    assets: Asset[] = [];

    chartEngineHour;
    calibrations: EngineHourCalibration[] = [];

    calibratedEngineHour = 0;
    assetCalibratedEngineHour = 0;
    calibrationDate = new Date();

    engineHourOffset = 0;
    tripEngineHour = 0;
    isCalibrationOutdated = false;


    filter;
    currentPage;

    selectedAssetId: any;
    selectedAsset: Asset;

    // Datepicker
    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    lastCalibration: any;

    permissionName = 'FleetManagement_EngineHourView';
    permissions: {};

    constructor(private cd: ChangeDetectorRef, private locationService: LocationService, private chartService: FhChartService, private authenticationService: AuthenticationService, private http: HttpClient, private assetService: AssetService, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router, private translateService: TranslateService) {
        this.device = null;
        this.deviceLog = [];
        this.permissions = this.authenticationService.permissions;

        // Datepicker
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.dpConfig.withTimepicker = true;
    }

    ngOnInit() {
        this.loading = true;
        this.loadingHistory = true;
        this.device = new Device;
        this.device.id = '';

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.loading = true;
            this.loadingHistory = true;

            this.deviceId = id;
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;

                // Fill asset dropdown
                this.loadingAssets = true;

                this.deviceService.getAssets(this.device.id).subscribe(assets => {

                    this.assets = assets;

                    this.selectedAssetId = this.device.asset?.id;
                    this.assetChanged(this.selectedAssetId);

                    this.loadingAssets = false;
                    this.cd.markForCheck();
                });

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }

                this.loading = false;

            });
        }, error => {
            this.error = error;
            this.error.statusText = 'Error fetching device';

            setTimeout(() => {
                this.router.navigate(['/Devices/Overview']);
            }, 3000);
        });
    }

    assetChanged(assetId) {
        this.selectedAsset = this.assets.find(x => x.id === assetId);
        if (this.selectedAsset) {
            this.calibratedEngineHour = 123; // Math.round(this.selectedAsset.calibratedOdo / 1000);
            this.lastCalibration = this.selectedAsset.lastCalibration;
            this.selectedAssetId = assetId;
            this.getEngineHourDetails();
        } else {
            this.loadingAssets = false;
            this.loadingHistory = false;
            this.cd.markForCheck();
        }
    }

    refresh() {
        this.getEngineHourDetails();
    }

    round(value) {
        return Math.round(value);
    }

    getEngineHourDetails() {
        this.getCalibrations(this.selectedAssetId);
    }

    getCalibrations(assetId) {

        this.loadingHistory = true;
        this.cd.markForCheck();

        // Get calibrations
        this.assetService.getEngineHourCalibrationsByAssetId(assetId).subscribe(calibrations => {

            this.calibrations = calibrations;
            this.cd.markForCheck();

            if (this.calibrations && this.calibrations.length > 0) {
                this.calibrations = this.calibrations.sort((a, b) => (a.dateCalibration > b.dateCalibration ? -1 : 1));

                // Get the latest offset
                this.engineHourOffset = Math.round(this.calibrations[0].offsetInSeconds / 3600);
                this.isCalibrationOutdated = this.calibrations[0].dateCalibration < Moment().subtract(3, 'months').startOf('day').toDate();
                this.cd.markForCheck();
            } else {
                this.engineHourOffset = 0;
                this.isCalibrationOutdated = true;
                this.cd.markForCheck();
            }

            // Get latest location
            this.loadingCacher = true;
            this.locationService.getDeviceStates([+this.device.id], null, null, 0).subscribe(result => {

                if (result?.deviceStates?.length > 0) {
                    // Get most current location/trip engine hour

                    const state = result.deviceStates[0];

                    if (!state.engineHour || state.engineHour?.engineHourInSeconds < 1) {
                        this.warning = 'No current engine hour found for device. This will offset the calibration. Please do not calibrate.';
                        this.loadingCacher = false;
                        this.cd.markForCheck();
                        return;
                    }

                    this.tripEngineHour = (Math.round(state.engineHour?.engineHourInSeconds / 3600)) ?? 0;
                    this.calibratedEngineHour = Math.round((this.engineHourOffset + (state.engineHour?.engineHourInSeconds / 3600))) ?? 0;
                    this.assetCalibratedEngineHour = this.calibratedEngineHour;
                    this.loadingCacher = false;
                    this.cd.markForCheck();
                } else {
                    this.warning = 'No current location found for device. This will offset the odocalibration. Please do not calibrate.';
                    this.loadingCacher = false;
                    this.cd.markForCheck();
                }
            });
            this.drawChart(calibrations);

            this.loadingHistory = false;
            this.cd.markForCheck();
        });
    }

    drawChart(calibrations) {

        const theDataTripEngineHour = [];
        const theDataRealEngineHour = [];

        this.loading = false;

        calibrations.forEach(calibration => {
            theDataTripEngineHour.push({ x: new Date(calibration.dateCalibration).getTime(), y: Math.round(calibration.tripEngineHourInSeconds / 3600), calibrationId: calibration.id });
            theDataRealEngineHour.push({ x: new Date(calibration.dateCalibration).getTime(), y: Math.round(calibration.realEngineHourInSeconds / 3600), calibrationId: calibration.id });
        });

        const theDataEvents = [];

        if (this.permissions['Platform_IsReseller']) {
            theDataEvents.push({
                type: 'line',
                name: this.translateService.instant('general.tripEngineHour'),
                data: theDataTripEngineHour,
                lineWidth: 3,
                marker: {
                    radius: 4
                }
            });
        }

        theDataEvents.push({
            type: 'line',
            name: this.translateService.instant('general.realEngineHour'),
            data: theDataRealEngineHour,
            lineWidth: 3,
            marker: {
                radius: 4
            }
        });

        this.chartEngineHour = this.chartService.generateOdoChart(theDataEvents);
        this.cd.markForCheck();
    }

    setFormMode(mode) {
        this.formMode = mode;
    }

    showDelete(calibrationId) {
        this.deleteModal.showModal(calibrationId);
    }

    deleteCalibration(calibrationId) {
        this.deleteModal.hideModal();

        this.assetService.deleteEngineHourCalibration(this.device.asset?.id, calibrationId).subscribe(result => {
            this.saving = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Engine Hour calibration is successfully deleted.'
            };

            setTimeout(() => {
                this.refresh();
            }, 2000);

            this.cd.markForCheck();

            this.setFormMode(FormMode.add)
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    onSave() {
        this.saving = true;

        // Set ticepicker values to date
        const calibration = {
            assetId: this.selectedAssetId,
            realEngineHourInSeconds: this.calibratedEngineHour * 3600,
            dateCalibration: this.calibrationDate,
        };

        this.assetService.postEngineHourCalibration(this.device.asset?.id, calibration).subscribe(result => {
            this.saving = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Engine Hour calibration is successfully updated.'
            };

            setTimeout(() => {
                this.refresh();
            }, 2000);

            this.cd.markForCheck();

            this.setFormMode(FormMode.add)
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }
}
