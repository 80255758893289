<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li class="active">{{'general.changeLog' | translate }}</li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">

    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-pencil-ruler"></i> {{'general.changeLog' | translate }}
                    </h5>
                </div>
                <div class="ibox-content">
                    <markdown class="markdown" [src]="'/assets/changelog/changelog.md'"></markdown>
                </div>
            </div>
        </div>
    </div>
</div>