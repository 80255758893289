<table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
    <tbody>
        <tr>
            <td class="row_opening"> {{ 'general.source' | translate}}
                <span class="error" *ngIf="valuetriggerSource?.invalid">*</span>
            </td>
            <td class="row_content" style="overflow: visible;">
                <span style="width:100%; float:left">
                    <ng-select class="form-control table-inline" name="triggerSource" [clearable]="false"
                        #valuetriggerSource="ngModel" [disabled]="formMode == 1" (change)="sourceChanged($event)"
                        [(ngModel)]="trigger.triggerInputSource" required style="width:100%">
                        <ng-option [value]=0>
                            <i class="fas fa-fw fa-comment-alt-dots"></i> {{
                            'general.newMessageTrigger' | translate }}
                        </ng-option>
                        <ng-option [value]=1>
                            <i class="fas fa-fw fa-clock"></i> {{ 'general.timerEvent' |
                            translate }}
                        </ng-option>
                        <ng-option [value]=2 [disabled]="true">
                            <i class="fas fa-fw fa-add"></i> {{ 'general.crudEvent' | translate
                            }}
                        </ng-option>
                        <ng-option [value]=3>
                            <i class="fas fa-fw fa-weight-scale"></i> {{ 'general.weightEvent' |
                            translate }}
                        </ng-option>
                        <ng-option [value]=4 [disabled]="true">
                            <i class="fas fa-fw fa-thermometer-three-quarters"></i> {{ 'general.temperatureEvent' |
                            translate }}
                        </ng-option>
                        <ng-option [value]=5 [disabled]="true">
                            <i class="fas fa-fw fa-humidity"></i> {{ 'general.humidityEvent' |
                            translate }}
                        </ng-option>
                        <ng-option *ngIf="trigger.triggerType === 1" [value]=6>
                            <i class="fa fa-fw fa-draw-polygon"></i> {{ 'general.geofenceEvent' |
                            translate }}
                        </ng-option>
                    </ng-select>
                </span>
            </td>
        </tr>
        <tr *ngIf="trigger.triggerInputSource == 1">
            <td class="row_opening"> {{ 'general.intervalInMinutes' | translate}}
                <span class="error" *ngIf="valuetriggerInterval?.invalid">*</span>
            </td>
            <td class="row_content" style="overflow: visible;">
                <span style="width:100%; float:left">
                    <input type="text" [readonly]="formMode == 1" name="interval" #valuetriggerInterval="ngModel"
                        required placeholder="{{ 'placeholder.intervalInMinutes' | translate}}"
                        class="form-control table-inline" (change)="timerIntervalChanged()"
                        [(ngModel)]="intervalInMinutes">
                </span>
            </td>
        </tr>
        <tr *ngIf="trigger.triggerInputSource == 2">
            <td class="row_opening"> {{ 'general.domainEventInput' | translate}}
                <span class="error" *ngIf="valuetriggerEntity?.invalid">*</span>
            </td>
            <td class="row_content" style="overflow: visible;">
                <span style="width:100%; float:left">
                    <ng-select class="form-control table-inline" name="domainEventInput" [clearable]="false"
                        #valuetriggerEntity="ngModel" [disabled]="formMode == 1" [(ngModel)]="trigger.domainEventInput"
                        required style="width:100%">
                        <ng-option *ngFor="let domainEventInputItem of domainEventInput;"
                            [value]="domainEventInputItem.value">
                            {{ domainEventInputItem.name | translate }}
                        </ng-option>
                    </ng-select>
                </span>
            </td>
        </tr>
        <tr *ngIf="trigger.triggerInputSource == 6">
            <td class="row_opening"> {{ 'general.geofenceEventType' | translate}}
                <span class="error" *ngIf="geofenceEventType?.invalid">*</span>
            </td>
            <td class="row_content" style="overflow: visible;">
                <span style="width:100%; float:left">
                    <ng-select class="form-control table-inline" name="geofenceEventType" ngDefaultControl
                        [multiple]="false" #geofenceEventType="ngModel" [readonly]="formMode == 1"
                        [closeOnSelect]="true" [clearable]="false" [searchable]="false"
                        [(ngModel)]="trigger.geofenceEventType">
                        <ng-option [value]="0">{{ 'enums.geofenceEventType.0' | translate}}</ng-option>
                        <ng-option [value]="1">{{ 'enums.geofenceEventType.1' | translate}}</ng-option>
                        <ng-option [value]="2">{{ 'enums.geofenceEventType.2' | translate}}</ng-option>
                    </ng-select>
                </span>
            </td>
        </tr>
        <tr *ngIf="trigger.triggerInputSource == 6">
            <td class="row_opening">{{ 'general.allGeofences' | translate}}
            </td>
            <td class="row_content" style="overflow: visible;">
                <div class="fa_checkbox">
                    <input [checked]="true" [disabled]="formMode == 1" id="allGeofences" type="checkbox"
                        name="allGeofences" [(ngModel)]="trigger.allGeofences" />
                    <label for="allGeofences">
                    </label>
                </div>
            </td>
        </tr>
        <tr *ngIf="trigger.triggerInputSource == 6">
            <td class="row_opening"> {{ 'general.geofences' | translate}}
                <span class="error" *ngIf="geofenceGroupVar?.invalid">*</span>
                <i class="fa fa-fw fa-draw-polygon" tooltip="{{'general.geofences' | translate }}"
                    [routerLink]="['/Geofences/Overview/']"></i>
            </td>
            <td class="row_content" style="overflow: visible;">
                <fh-geofence-group-item-input name="geofenceGroups" #geofenceGroupVar="ngModel"
                    [required]="!trigger.allGeofences" [selectedAccountId]="trigger.accountId"
                    [readonly]="trigger.allGeofences || formMode === 1" placeholder="{{ 'general.chooseGeofenceGroups' | translate}}"
                    [(ngModel)]="trigger.geofenceSource" >
                </fh-geofence-group-item-input>
            </td>
        </tr>
    </tbody>
</table>