import { Component, Input, OnInit, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormMode } from 'app/common/enums';
import { Group } from 'app/models/group.model';
import { UserService } from 'app/services/users/user.service';

@Component({
    selector: 'fh-viewer-overview',
    templateUrl: 'viewerOverview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewerOverviewComponent implements OnInit, OnChanges {
    @Input() groups: Group[] = [];
    @Input() name: string;
    @Input() showIbox = true;
    @Input() itemsPerPage = 10;
    @Input() linkUrl = 'GeofenceGroupDetails';

    @Input() formMode: any = FormMode.read;

    selectedItems = {};
    loading = false;
    tooltip = 'general.sharedGroupToolTip';

    // Pagination
    totalItems = 0;
    currentPage = 1;
    smallnumPages = 0;

    filter;

    constructor(private cd: ChangeDetectorRef, private userService: UserService) {

    }

    ngOnChanges(): void {
        this.totalItems = this.groups.length;
        this.cd.markForCheck();
    }

    ngOnInit(): void {

    }

    loadAssetItems(model) {
        this.loading = true;
        this.cd.markForCheck();

        this.userService.getAssetGroupsbyViewerId(model.id).subscribe((result) => this.parseGroupRequest(result));
    }

    loadDriverItems(model) {
        this.loading = true;
        this.cd.markForCheck();

        this.userService.getDriversGroupsbyViewerId(model.id).subscribe((result) => this.parseGroupRequest(result));
    }

    checkedAllGroups() {
        if (!this.groups?.length) {
            return false;
        }

        return !!this.groups.every(x => this.selectedItems[x.id] === true);
    }

    selectAllGroups({ currentTarget }) {
        if (!this.groups?.length) {
            return;
        }

        this.groups.forEach(item => {
            this.selectedItems[item.id] = currentTarget.checked;
        });
    }

    parseGroupRequest(assetGroups) {
        assetGroups.forEach(item => {
            item['name'] = item['groupName'];
            item['itemCount'] = item['itemCount'];
            if (item['accountName']) {
                item['reference'] = `${item['accountName']} (${item['userName']})`;
            } else {
                item['reference'] = `${item['userName']}`;
            }
            item['id'] = item['id'];
            item['userId'] = item['userId'];
            item['selected'] = item['viewerPermission'];
        })

        this.groups = assetGroups.sort((a, b) => -(a.selected - b.selected) || b.userName.startsWith('RA_') - a.userName.startsWith('RA_') || a.name.localeCompare(b.name));

        this.groups.forEach((item) => {
            this.selectedItems[item.id] = item['selected'];
        })

        this.loading = false;
        this.cd.markForCheck();
    }

    changeSelectedItem(item) {
        this.selectedItems[item.id] = !this.selectedItems[item.id];
    }

    // Pagination
    setPage(pageNo: number): void {
        this.currentPage = pageNo;
    }

    pageChanged(event: any): void {
        console.log('Page changed to: ' + event.page);
        console.log('Number items per page: ' + event.itemsPerPage);
    }
}

