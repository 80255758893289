import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormMode, languages, userTypes, AssetDisplayName, DistanceUnit, VolumeUnit } from 'app/common/enums';
import { AccountInventory } from 'app/models/account.model';
import { AppUser } from 'app/models/user.model';
import { AccountService } from 'app/services/account/account.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { UserRoleService } from 'app/services/users/userRole.service';

@Component({
    selector: 'fh-user-details',
    templateUrl: 'userDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class UserDetailsComponent implements OnInit {

    @Input() user: AppUser = new AppUser();
    @Input() loading = false;
    @Input() formMode: any = FormMode.read;
    @Input() profileMode = false;
    @Input() smallMode = false;
    @Output() onAccountChanged = new EventEmitter();
    @Output() onUserRoleChanged = new EventEmitter();

    accounts: AccountInventory[] = [];

    passwordConfirmation = '';
    languages = [];
    permissions: {};
    userTypes = [];
    displayNameOptions = [];
    distanceUnits = [];
    volumeUnits = [];
    userRoles = [];
    loadingUserRoles = false;
    isImpersonated: boolean;
    loadingAccounts = false;
    selectedResellerId;

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private accountService: AccountService, private translate: TranslateService, private userRoleService: UserRoleService) {
        this.permissions = this.authenticationService.permissions;
        this.isImpersonated = this.authenticationService.getIsImpersonated();

        this.languages = Object.keys(languages)
            .filter(k => typeof languages[k] === 'string')
            .map(n => ({ name: n, path: languages[n] }));

        this.userTypes = Object.keys(userTypes)
            .filter(k => typeof userTypes[k] === 'string')
            .map(n => ({ value: +n, name: userTypes[n] }))
            .sort((a, b) => a.name.localeCompare(b.name));

        this.displayNameOptions = Object.keys(AssetDisplayName)
            .filter(k => typeof AssetDisplayName[k] === 'string')
            .map(n => ({ value: +n, name: AssetDisplayName[n] }));

        this.distanceUnits = Object.keys(DistanceUnit)
            .filter(k => typeof DistanceUnit[k] === 'string')
            .map(n => ({ value: +n, name: DistanceUnit[n] }));

        this.volumeUnits = Object.keys(VolumeUnit)
            .filter(k => typeof VolumeUnit[k] === 'string')
            .map(n => ({ value: +n, name: VolumeUnit[n] }));
    }

    ngOnInit(): void {
        if (this.formMode === FormMode.add) {
            this.user.clientPermissionId = -1;

            if (this.user.userType === null) {
                this.user.userType = this.userTypes[0].name;
            }

            if (this.user.language === null) {
                this.user.language = this.languages[0].name;
            }

            if (this.user.password != null) {
                this.passwordConfirmation = this.user.password;
            }
        }

        this.loadingUserRoles = true;
        this.userRoleService.getUserRoles().subscribe(result => {
            this.userRoles = result;
            this.loadingUserRoles = false;
            this.cd.markForCheck();
        })
    }

    resellerChanged(resellerId) {
        this.user.accountId = null;

        if (!this.permissions['Resellers_View']) {
            this.selectedResellerId = this.authenticationService.getResellerId();
        } else {
            this.selectedResellerId = resellerId;
        }

        this.accountChanged();
    }

    accountChanged() {
        this.onAccountChanged.emit(this.user.accountId);
    }

    userRoleChanged() {
        this.onUserRoleChanged.emit(this.user.userRoleId);
    }

}
