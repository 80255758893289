<!-- Loading indicator -->
<div *ngIf="isLoading"
    style="position: absolute; text-align: center; top: 0; left: 0; width: 100%; height: 100%; background-color:#eee;">
    <div style="position: relative; top: calc(55px + 10%)">
        <div style="width: 100%">
            <img width="100%" style="padding: 15px; max-width: 330px" src='/assets/images/360_logo.png' alt='' />
        </div>

        <ng-container *ngIf="(countSub$ | async) as timer; else showLoading">
            <span>Retrying to connect in {{ timer }}</span>
        </ng-container>
        <ng-template #showLoading>
            <span>Loading system configuration.....</span>
        </ng-template>
    </div>
</div>

<!-- Main view/routes wrapper-->
<router-outlet></router-outlet>

<!-- Native preloading icon -->
<div style="width:0px; height:0px; overflow:hidden;">
    <i class="far fa-square"></i>
</div>