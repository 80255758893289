import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FormMode } from 'app/common/enums';
// Moment timezone
import { WaslService } from 'app/services/wasl/wasl.service';
import { AccountService } from 'app/services/account/account.service';
import { AccountInventory } from 'app/models/account.model';

@Component({
    selector: 'fh-account-wasl',
    templateUrl: 'wasl.template.html'
})
export class AccountWaslViewComponent implements OnInit {
    sub;
    account: AccountInventory;
    loading = false;

    error: any;
    warning: any;
    success: any;

    formMode = FormMode.read;
    accountId: any;

    constructor(private cd: ChangeDetectorRef, private waslService: WaslService, private accountService: AccountService, private route: ActivatedRoute, private router: Router) {
        this.account = null;
    }

    ngOnInit() {
        this.loading = true;
        this.account = new AccountInventory;
        this.account.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.accountId = id;
            this.accountService.getAccountById(id).subscribe(account => {
                this.account = account;
                this.loading = false;
                if (this.account == null) {
                    this.router.navigate(['/accounts/Overview'])
                }
            });
        });
    }

    getAccountInfo() {
        if (this.loading !== true) {
            this.loading = true;
        }

        this.accountService.getAccountById(this.account.id).subscribe(account => {
            this.account = account;
            this.loading = false;
        });
    }

    // Wasl
    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getAccountInfo();
        }
    }


    inquire(company) {
        this.loading = true;

        this.waslService.getInquireAccount(this.account.id, company).subscribe(registerResult => {

            this.loading = false;
            if (registerResult.isSuccess) {
                this.success = {
                    statusText: 'Success',
                    json: JSON.parse(registerResult.entity.json),
                    success: registerResult.message
                };
                this.error = null;
            } else if (registerResult.isWarning) {
                this.warning = {
                    statusText: 'Warning',
                    success: registerResult.message
                };
                this.error = null;
            } else {
                this.error = {
                    statusText: 'Error',
                    error: registerResult.message
                };
            }
            this.getAccountInfo();
        }, error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    register(company) {
        this.loading = true;

        this.waslService.registerCompany(this.account.id, this.account.properties.wasl, company).subscribe(registerResult => {

            this.loading = false;
            if (registerResult.isSuccess) {
                this.success = {
                    statusText: 'Success',
                    success: registerResult.message
                };
                this.error = null;
            } else if (registerResult.isWarning) {
                this.warning = {
                    statusText: 'Warning',
                    success: registerResult.message
                };
                this.error = null;
            } else {
                this.error = {
                    statusText: 'Error',
                    error: registerResult.message
                };
            }
            this.getAccountInfo();
        }, error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    unRegister(company) {
        this.loading = true;

        this.waslService.unregisterCompany(this.account.id, company).subscribe(unregisterResult => {
            console.log(unregisterResult);
            this.loading = false;
            if (unregisterResult.isSuccess) {
                this.success = {
                    statusText: 'Success',
                    success: unregisterResult.resultCode
                };
                this.error = null;
            } else {
                this.error = {
                    statusText: 'Error',
                    error: unregisterResult.resultCode
                };
            }
            this.getAccountInfo();
        }, error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }
}
