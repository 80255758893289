import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { Warehouse } from 'app/models/warehouse.model';
import { WarehouseService } from 'app/services/warehouse/warehouse.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { Geofence } from 'app/models/geofence.model';
import { GeofenceService } from 'app/services/geofence/geofence.service';


@Component({
  selector: 'fh-warehousess-add',
  templateUrl: 'add.template.html'
})
export class WarehousesAddViewComponent implements OnInit {
  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;
  geofence: Geofence = new Geofence();

  token: string;
  loading = false;
  loadingGroups = false;
  warehouse: Warehouse;

  allowEdit = true;
  allowBookmarks = false;

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string; };
  selectedGroups = [];
  selectedGroupsSource = [];
  groupName;

  permissionName = 'Warehouses_Add';

  constructor(private translateServe: TranslateService, private geofenceService: GeofenceService, private warehouseService: WarehouseService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();

    this.selectedGroups = [];
  }

  ngOnInit() {
    this.warehouse = new Warehouse();
  }

  accountChanged(accountId) {
    this.cd.markForCheck();
  }

  onSaveShape(layer) {
    this.geofenceService.parseLayer(layer, this.geofence);

    setTimeout(() => {
      this.cd.detectChanges();
    }, 1);
  }

  onMapReady() {
    setTimeout(() => {
      this.leafletMapComponent.addDrawControls();
    }, 10);
  }

  onInsert() {
    this.loading = true;

    this.warehouseService.saveWarehouse(this.warehouse, this.geofence.geoJson).subscribe(result => {

      this.success = {
        statusText: 'Success',
        success: this.translateServe.instant('general.saveSuccessRedirect', { entity: 'warehouse' })
      };

      setTimeout(() => {
        this.router.navigate(['/WarehouseDetails/Index/' + result.id]);
      }, 2000);

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }


}
