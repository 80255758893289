<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/Overview">{{'general.users' | translate }}</a>
            </li>
            <li class="active">{{'menu.add' | translate }}</li>
        </ol>
    </div>
    <fh-user-navigation>
        <li class="active"><a href="/#/Users/Add/"><i class="fas fa-fw fa-users"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a></li>
    </fh-user-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row" data-equalizer="">
            <div class="col-md-6">
                <div class="ibox" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-users"></i> {{'menu.addUser' | translate }}
                        </h5>
                    </div>
                    <form #userForm="ngForm" autocomplete="off">
                        <fh-user-details [user]="user" [formMode]="formMode" (onAccountChanged)="accountChanged()">
                        </fh-user-details>
                        <fh-save-footer [valid]="!userForm.invalid" [allowAdd]="permissions['Users_Add']"
                            (onInsert)="onInsert()" [formMode]="formMode">
                        </fh-save-footer>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <div class="ibox" style="position:relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-question"></i> {{'general.options' | translate }}</h5>
                    </div>
                    <div class="ibox-content slick">
                        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.createDriver' | translate}}
                                    </td>
                                    <td class="row_content">
                                        <div class="hand fa_checkbox fa_toggle">
                                            <input [checked]="true" id="createDriver" type="checkbox"
                                                name="createDriver" [disabled]="formMode == 1"
                                                [(ngModel)]="user.createDriver" />
                                            <label for="createDriver"></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.createNewAssetGroup' | translate}}
                                    </td>
                                    <td class="row_content">
                                        <div class="hand fa_checkbox fa_toggle">
                                            <input [checked]="true" id="createAssetGroup" type="checkbox"
                                                name="createAssetGroup" [disabled]="formMode == 1"
                                                [(ngModel)]="user.createAssetGroup" />
                                            <label for="createAssetGroup"></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.assignExistingAssetGroups' | translate}}
                                    </td>
                                    <td class="row_content" style="overflow:visible">
                                        <fh-asset-group-input name="assetGroups" #assetGroupVar="ngModel" required
                                            [selectedAccountId]="user.accountId" [tableInline]="false"
                                            [activeOnly]="true"
                                            placeholder="{{ (user.accountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                                            [(ngModel)]="selectedAssetGroups">
                                        </fh-asset-group-input>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>