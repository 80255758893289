import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { FuelService } from 'app/services/fuel/fuel.service';
import { Fuel } from 'app/models/fuel.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fh-fuel-add',
  templateUrl: 'add.template.html'
})
export class FuelAddViewComponent implements OnInit {
  token: string;
  loading = false;
  fuel = new Fuel;

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string; };

  permissionName = 'FuelEvents_Add';

  constructor(private translateServe: TranslateService, private fuelService: FuelService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
  }

  ngOnInit(): void {
    this.fuel = new Fuel();
    this.fuel.timestamp = new Date();
  }

  onInsert() {
    this.loading = true;

    this.fuelService.saveFuel(this.fuel).subscribe(result => {

      this.success = {
        statusText: 'Success',
        success: this.translateServe.instant('general.saveSuccessRedirect', { entity: 'fuel' })
      };

      setTimeout(() => {
        this.router.navigate(['/FuelDetails/Index/' + result.id]);
      }, 2000);

      this.loading = false;
    }, error => {
      this.success = null;
      this.error = error;
      this.loading = false;
    });
  }
}
