import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserContact } from 'app/models/userContact.model';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';


@Injectable()
export class UserContactService {

    token: string;
    timestamp;
    url = ''; UserContactService

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getWebserviceURL('userContact');
    }

    getPagingUrl() {
        return this.url + 'Paging';
    }

    getUserContacts(accountId?: number): Observable<UserContact[]> {
        return this.http.get(this.url + (accountId ? `?accountId=${accountId}` : ''), { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getUserContactById(id: string): Observable<UserContact> {
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseReponseDetails(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    saveUserContact(userContact: UserContact): Observable<any> {
        console.log('save userContact');
        return this.http.post(this.url, userContact, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    updateUserContact(userContact: UserContact): Observable<any> {
        console.log('save userContact');
        return this.http.put(this.url + userContact.id, userContact, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    deleteUserContact(userContact: UserContact): Observable<any> {
        console.log('save userContact');
        return this.http.delete(this.url + userContact.id, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    parseResponse(json: any): UserContact[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' UserContacts.');

        const ident = 1;
        const assets: UserContact[] = [];

        json.forEach(item => {

            const asset = this.parseReponseDetails(item);
            assets.push(asset);
        });

        this.timestamp = new Date().getTime();
        return assets;
    }

    parseReponseDetails(item) {
        //   this.loggingService.log(this.constructor().name + " - New", item);
        const userContact = new UserContact();
        userContact.id = item.id;
        userContact.emailLoginName =  item.emailLoginName;
        userContact.userId =  item.userId;
        userContact.firstName = item.firstName;
        userContact.lastName = item.lastName;
        userContact.accountId = item.accountId;
        userContact.companyName = item.companyName;
        userContact.msisdn = item.msisdn;
        userContact.userContactType = item.userContactType;
        userContact.webserviceToken = item.webserviceToken;
        userContact.email = item.email;
        userContact.resellerId = item.resellerId;
        userContact.resellerDescription = item.resellerDescription;
        return userContact;
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
