import { EntityType } from 'app/common/enums';

export class Tag {

  public name: any;
  public type: any;
  public metrics: any;
  public parameter: any;
  public deviceSensorTypeId: any;
  public sensorCount: any;
  public isChanged = false;
  public isCustomDeviceSensorTypeId = false;
  public deviceSensorType: any;
}

export class TagSearch {
  public id: string;
  public name: string;
  public objectId: string;
  public objectType: EntityType;
  public objectName: string;
}

export class TagUsage {
  public id: string;
  public name: string;
  public useCount: number;
  public nameWithCount: string;
}

export class TagPost {
  public name: string;
  public objectId: string;
  public objectType: EntityType;
}

