<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="user?.accountId">
                <a href="/#/AccountDetails/Index/{{user?.accountId}}">{{user?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Users/Overview">{{'general.users' | translate }}</a>
            </li>
            <li>
                <a href="/#/UserDetails/Index/{{user?.id}}">{{user?.name}}</a>
            </li>
            <li class="active">{{'general.notifications' | translate }}</li>
        </ol>
    </div>
    <fh-user-details-navigation [userId]="userId"></fh-user-details-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <div class="row" data-equalizer="">
        <fh-notifications-list [notifications]="notifications" [loading]="loadingNotifications"
            (onDateChanged)="dateChanged($event)" [daterangepickerModel]="daterangepickerModel" [dpConfig]="dpConfig">
        </fh-notifications-list>
    </div>
</div>