import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'fh-general-system',
    templateUrl: 'general.template.html'
  })

export class SystemGeneralComponent implements OnInit {
    loading: boolean;

    constructor() {
    }


    ngOnInit(): void {
    }

}
