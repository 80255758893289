import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode, LocationEventType, StorageType, EntityType } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { Geofence } from 'app/models/geofence.model'
import { GeofenceGroupsService } from 'app/services/geofence/geofenceGroups.service';
import { GeofenceGroup } from 'app/models/group.model';
import { GeofenceService } from 'app/services/geofence/geofence.service';
import { UserContactGroupService } from 'app/services/users/userContactGroups.service'
import { DeviceService } from 'app/services/device/device.service';
import { getIconPath } from 'app/common/globals';
import { ConfirmationModalComponent } from '../shared/usercontrols/confirmationModal.component';
import { TriggerService } from 'app/services/triggers/triggers.service';
import { Trigger } from 'app/models/trigger.model';
import { StorageHelper } from 'app/common/storagehelper';

@Component({
    selector: 'fh-fh-geofence-group-details',
    templateUrl: 'groupDetails.template.html'
})
export class GeofenceGroupDetailsViewComponent implements OnInit {
    @ViewChild('triggerModal') triggerModal: ConfirmationModalComponent;
    sub: any;
    geofenceGroup: GeofenceGroup = new GeofenceGroup();
    geofence: Geofence = new Geofence();
    devices: any[];
    tagType = EntityType.GeofenceGroup.valueOf();
    formMode = FormMode.read;
    geofences = [];

    loading = false;
    saving = false;
    loadingGroups = false;
    loadingGeofences = false;
    loadingUserContactGroups = false;

    locationEventTypeOccurrence = 0;

    geofenceGroupOptions = {
        checkboxIgnitionOff: false,
        checkboxDoorSensor: false,
        checkboxSecondaryDoorSensor: false,
        checkboxExternalPowerOff: false,

        inputFieldOutsideThresholdInMinutes: null,
        inputFieldInsideThresholdInMinutes: null,
        inputFieldOutSideAllThresholdsInMinutes: null,
    }

    userContactGroups = [];

    error: any;
    permissions: {};
    permissionName = 'GeofenceGroups_View';
    success: any;

    geofenceGroupsItems = [];
    geofenceLink = 'GeofenceDetails';

    // Selector
    source = [];
    confirmed = [];
    format = {
        add: 'Add', remove: 'Remove', all: 'All', none: 'None', draggable: true
    };
    loadingDevices = false;
    display = 'name';

    mapHeight = 250;
    geofenceId: any;

    loadingTriggers = false;
    triggers: Trigger[] = [];
    hideTriggers = false;
    storageType = StorageType.LocalStorage;

    constructor(private cd: ChangeDetectorRef,
        private geofenceService: GeofenceService,
        private authentication: AuthenticationService,
        private geofenceGroupsService: GeofenceGroupsService,
        private userContactGroupService: UserContactGroupService,
        private route: ActivatedRoute,
        private router: Router,
        private deviceService: DeviceService,
        private triggerService: TriggerService,
        private storageHelper: StorageHelper) {

        this.permissions = this.authentication.permissions;

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'hideTriggers').subscribe((result) => {
            this.hideTriggers = JSON.parse(result) === true;
        });
    }

    ngOnInit() {
        this.loading = true;
        this.loadingGroups = true;
        this.loadingUserContactGroups = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.geofenceId = id;

            this.geofenceGroupsService.getGeofenceGroupById(+id).subscribe(geofenceGroup => {
                this.geofenceGroup = geofenceGroup;
                this.loading = false;
                this.cd.markForCheck();

                this.setGeofenceGroupProperties();

                this.getUserContactGroups();

                this.getDevices();

                this.getGeofenceGroupItemsById(+id);

                this.loadingTriggers = true;

                this.triggerService.getTriggerByGeofenceGroupId(+id).subscribe(triggers => {
                    this.triggers = triggers;
                    this.loadingTriggers = false;
                    this.cd.markForCheck();
                });
            }, error => {
                this.error = error;
                this.error.statusText = 'Error fetching geofenceGroup';
                this.loading = false;
            });
        });
    }

    getUserContactGroups() {
        // Fetching correct usercontactgroups
        this.loadingUserContactGroups = true;
        this.userContactGroupService.getUserContactGroups(this.geofenceGroup.accountId, true).subscribe(userContactGroups => {
            this.userContactGroups = userContactGroups;
            this.loading = false;
            this.loadingUserContactGroups = false;
            this.cd.markForCheck();
        }, error => {
            this.error = error;
            this.loadingUserContactGroups = false;
            this.error.statusText = 'Error fetching user contact groups';
            this.cd.markForCheck();
        });
    }

    getDevices() {
        this.deviceService.getDevicesLimited(false, this.geofenceGroup.accountId, null, null, false).subscribe(devices => {
            this.devices = devices.filter(x => x.isActive === true && x.isArchived === false);

            this.devices = this.devices.sort((a, b) => (a.asset?.name < a.asset?.name ? -1 : 1));

            this.devices.forEach(device => {
                device.iconPath = getIconPath(device.asset?.icon)[1];
            });

            this.filterDevices();
        });
    }

    setGeofenceGroupProperties() {
        this.locationEventTypeOccurrence = this.geofenceGroup.notifyOnEventTypeOccurrence;

        if (this.locationEventTypeOccurrence == null) {
            return;
        }

        this.geofenceGroupOptions.inputFieldOutsideThresholdInMinutes = this.geofenceGroup.outsideThresholdInSeconds && this.geofenceGroup.outsideThresholdInSeconds / 60;
        this.geofenceGroupOptions.inputFieldInsideThresholdInMinutes = this.geofenceGroup.insideThresholdInSeconds && this.geofenceGroup.insideThresholdInSeconds / 60;
        this.geofenceGroupOptions.inputFieldOutSideAllThresholdsInMinutes = this.geofenceGroup.outsideAllThresholdsInSeconds && this.geofenceGroup.outsideAllThresholdsInSeconds / 60;

        // tslint:disable:no-bitwise
        this.geofenceGroupOptions.checkboxIgnitionOff = (BigInt(LocationEventType['Ignition Off']) & BigInt(this.locationEventTypeOccurrence)) > 0;
        this.geofenceGroupOptions.checkboxDoorSensor = (BigInt(LocationEventType['Door Sensor']) & BigInt(this.locationEventTypeOccurrence)) > 0;
        this.geofenceGroupOptions.checkboxSecondaryDoorSensor = (BigInt(LocationEventType['Secondary Door Sensor']) & BigInt(this.locationEventTypeOccurrence)) > 0;
        this.geofenceGroupOptions.checkboxExternalPowerOff = (BigInt(LocationEventType['External Power Off']) & BigInt(this.locationEventTypeOccurrence)) > 0;
    }

    // Form
    onDelete() {
        this.loading = true;

        this.geofenceGroupsService.deleteGeofenceGroup(this.geofenceGroup).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Geofence group is successfully deleted.'
            };
            setTimeout(() => {
                this.router.navigate(['/GeofenceGroups/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.getGeofenceGroupInfo(+this.geofenceGroup.id);
        });
    }

    onSave() {
        this.saving = true;

        this.geofenceGroup.items = this.confirmed.map((geofence) => +geofence.id);

        this.geofenceGroup.notifyOnEventTypeOccurrence = 0;
        if (this.geofenceGroupOptions.checkboxIgnitionOff === true) {
            this.geofenceGroup.notifyOnEventTypeOccurrence += LocationEventType['Ignition Off'];
        }
        if (this.geofenceGroupOptions.checkboxDoorSensor === true) {
            this.geofenceGroup.notifyOnEventTypeOccurrence += LocationEventType['Door Sensor'];
        }
        if (this.geofenceGroupOptions.checkboxSecondaryDoorSensor === true) {
            this.geofenceGroup.notifyOnEventTypeOccurrence += LocationEventType['Secondary Door Sensor'];
        }
        if (this.geofenceGroupOptions.checkboxExternalPowerOff === true) {
            this.geofenceGroup.notifyOnEventTypeOccurrence += LocationEventType['External Power Off'];
        }

        this.geofenceGroup.outsideThresholdInSeconds = this.geofenceGroupOptions.inputFieldOutsideThresholdInMinutes && this.geofenceGroupOptions.inputFieldOutsideThresholdInMinutes * 60;
        this.geofenceGroup.insideThresholdInSeconds = this.geofenceGroupOptions.inputFieldInsideThresholdInMinutes && this.geofenceGroupOptions.inputFieldInsideThresholdInMinutes * 60;
        this.geofenceGroup.outsideAllThresholdsInSeconds = this.geofenceGroupOptions.inputFieldOutSideAllThresholdsInMinutes && this.geofenceGroupOptions.inputFieldOutSideAllThresholdsInMinutes * 60;

        this.geofenceGroupsService.updateGeofenceGroup(this.geofenceGroup).subscribe(result => {
            this.saving = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Geofence group is successfully updated.'
            };

            this.setFormMode(FormMode.read);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    showTriggerModal() {
        this.triggerModal.showModal(0);
    }

    convertToTrigger() {
        this.triggerModal.hideModal();
        this.saving = true;

        this.geofenceGroupsService.convertGeofenceGroup(this.geofenceGroup).subscribe(result => {
            this.saving = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Geofence group is successfully converted.'
            };

            this.setFormMode(FormMode.read);

            setTimeout(() => {
                this.router.navigate(['/System//System/TriggerDetails/Index/' + result.id]);
            }, 2000);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getGeofenceGroupInfo(+this.geofenceGroup.id);
        } else {
            if (this.geofenceGroup.accountId) {
                this.loadSelector();
            } else {
                console.log('Timing error ');
            }
        }
    }

    loadSelector() {
        this.loadingGeofences = true;

        this.source = this.geofenceGroupsItems;
        this.confirmed = this.geofenceGroupsItems;

        if (+this.geofenceGroup.accountId !== 0) {
            this.geofenceService.getGeofencesByAccount(this.geofenceGroup.accountId).subscribe(result => {
                this.source = result;
                this.loadingGeofences = false;
                this.cd.markForCheck();
            });
        }
    }

    getGeofenceGroupInfo(id) {
        if (this.loading !== true) {
            this.loading = true;
        }

        this.geofenceGroupsService.getGeofenceGroupById(id).subscribe(geofenceGroup => {
            this.geofenceGroup = geofenceGroup;
            this.filterDevices();
            this.setGeofenceGroupProperties();
            this.getGeofenceGroupItemsById(id);
            this.loading = false;
        }, error => {
            this.loading = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    getGeofenceGroupItemsById(id: number) {
        this.loadingGroups = true;

        this.geofenceGroupsService.getGeofenceGroupItemsById(0, id).subscribe(res => {
            this.geofenceGroupsItems = res;
            this.geofences = res;
            this.loadingGroups = false;
        });
    }

    // Filter out devices that are deactivated/archived or moved to another account.
    filterDevices() {
        const selectedDevices: number[] = [];

        this.geofenceGroup.selectedDevices?.forEach(id => {
            id = Number(id);
            if (this.devices.findIndex(item => item.id === id) !== -1) {
                selectedDevices.push(id);
            }
        });

        this.geofenceGroup.selectedDevices = selectedDevices;
    }
}
