<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/Overview">{{'general.users' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/UserContacts/Overview">{{'general.userContacts' | translate }}</a>
            </li>
            <li class="active">{{'general.details' | translate }}</li>
        </ol>
    </div>
    <fh-user-navigation></fh-user-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">

                <form #userForm="ngForm">
                    <div class="ibox" style="position: relative;">
                        <ngx-loading [show]="loading || saving"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-address-card"></i> {{'general.userContacts' | translate }}
                            </h5>
                        </div>
                        <fh-user-contact-details [userContact]="userContact" [formMode]="formMode">
                        </fh-user-contact-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()"
                            [allowEdit]="permissions['UserContacts_Edit']" (onDelete)="onDelete()"
                            [allowDelete]="permissions['UserContacts_Delete']" (setFormMode)="setFormMode($event)"
                            [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">

                <fh-tagging [objectId]="userContact?.id" [objectType]="tagType">
                </fh-tagging>

            </div>
        </div>
    </div>
</fh-permission-handler>