import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'fh-error',
    templateUrl: 'error.component.html',
})
export class ErrorComponent implements OnInit, OnChanges, OnDestroy {

    @Input() error = null;
    @Input() success = null;
    @Input() warning = null;

    @Input() copyText = null;
    @Input() autoRemove = true;

    removeTimeout;
    show = false;

    copyCompleted = false;
    copyTimout;

    constructor(private cd: ChangeDetectorRef) {
    }

    ngOnDestroy(): void {
        clearTimeout(this.removeTimeout);
    }

    clearError() {
        this.success = null;
        this.error = null;
        this.warning = null;

        this.show = false;
        this.cd.markForCheck();
    }

    textToClipboard() {
        if (this.copyText == null) {
            return;
        }

        clearTimeout(this.copyTimout);

        const input = document.createElement('input');
        input.value = this.copyText?.trim();
        input.setAttribute('readonly', '');
        input.style.position = 'absolute';
        input.style.left = '-9999px';
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(input);
        this.copyCompleted = true;
        this.copyTimout = setTimeout(() => { this.copyCompleted = false }, 2000);
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
        clearTimeout(this.removeTimeout);

        if (this.success || this.error || this.warning) {
            this.show = true;
        }

        if (this.success != null && this.autoRemove) {
            this.removeTimeout = setTimeout(() => {
                this.show = false;
                this.cd.markForCheck();
            }, 5000);
        }
        if (this.warning != null && this.autoRemove) {
            this.removeTimeout = setTimeout(() => {
                this.show = false;
                this.cd.markForCheck();
            }, 100000);
        }
        if (this.error != null && this.autoRemove) {
            this.removeTimeout = setTimeout(() => {
                this.show = false;
                this.cd.markForCheck();
            }, 100000);
        }
    }
}

