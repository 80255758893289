<div class="col-lg-12">
    <div class='horizontalMenuWrapper'>
        <ul>
            <li *ngIf="!hideTriggers &&permissions['Triggers_View']"
                [ngClass]="{active:  activeRoute('System/Triggers') || isTriggerDetails()}">
                <a [routerLink]="['/System/Triggers/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-bell-on"></i>
                    <div>{{ 'general.triggers' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsFleetManager']"
                [ngClass]="{active: activeRoute('System/TriggerSchedule')}">
                <a [routerLink]="['/System/TriggerSchedules/Overview']">
                    <i class="fas fa-fw fa-calendar"></i>
                    <div>{{ 'general.triggerSchedules' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsFleetManager']"
                [ngClass]="{active: activeRoute('System/WebhookEndpoint')}">
                <a [routerLink]="['/System/WebhookEndpoints/Overview']">
                    <i class="fas fa-fw fa-network-wired"></i>
                    <div>{{ 'general.webhookEndpoints' | translate }}</div>
                </a>
            </li>
            <li *ngIf="!hideTags && permissions['FleetManagement_Tags']"
                [ngClass]="{active: activeRoute('System/Tags')}">
                <a [routerLink]="['/System/Tags/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-tags"></i>
                    <div>{{ 'general.tags' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['HasNotifications']" [ngClass]="{active: activeRoute('System/Notifications')}">
                <a [routerLink]="['/System/Notifications/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-exclamation-triangle"></i>
                    <div>{{ 'general.notifications' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Jobs']" [ngClass]="{active: activeRoute('System/Batch')}">
                <a [routerLink]="['/System/Batch/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-sync-alt"></i>
                    <div>{{ 'general.jobs' | translate }}</div>
                </a>
            </li>
            <li [ngClass]="{active: activeRoute('System/Import')}">
                <a [routerLink]="['/System/Import']">
                    <i class="fas fa-fw fa-file-import"></i>
                    <div>{{ 'general.import' | translate }}</div>
                </a>
            </li>
            <li [ngClass]="{active: activeRoute('System/Export')}">
                <a [routerLink]="['/System/Export']">
                    <i class="fas fa-fw fa-file-export"></i>
                    <div>{{ 'general.export' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsReseller']" [ngClass]="{active: isLogs()}">
                <a [routerLink]="['/System/AuditLog/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-archive"></i>
                    <div>{{ 'general.logs' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class='horizontalMenuWrapper' style="float:right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div class="col-lg-12" style="padding: 5px" *ngIf="isLogs()">
    <div class='horizontalMenuSubWrapper'>
        <ul>
            <li *ngIf="permissions['Platform_IsReseller']" [ngClass]="{active: activeRoute('AuditLog')}">
                <a [routerLink]="['/System/AuditLog/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-archive"></i>
                    {{ 'general.auditLog' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsReseller']" [ngClass]="{active: activeRoute('NotificationLog')}">
                <a [routerLink]="['/System/NotificationLog/Overview/']" aria-expanded="true" aria-disabled="true">
                    <i class="fas fa-fw fa-envelope-open-text"></i>
                    {{ 'general.notificationLog' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsAdministrator']" [ngClass]="{active: activeRoute('System/ErrorLog')}">
                <a [routerLink]="['/System/ErrorLog']">
                    <i class="fas fa-fw fa-bug"></i>
                    {{ 'menu.errorlog' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsAdministrator']" [ngClass]="{active: activeRoute('System/StateViewer')}">
                <a [routerLink]="['/System/StateViewer']">
                    <i class="fas fa-fw fa-code"></i>
                    {{ 'menu.stateViewer' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['Platform_IsAdministrator']" [ngClass]="{active: activeRoute('System/ActiveUsers')}">
                <a [routerLink]="['/System/ActiveUsers']">
                    <i class="fas fa-fw fa-users"></i>
                    {{ 'general.activeUsers' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>
<div class="col-lg-12" style="padding: 5px" *ngIf="isTriggerDetails()">
    <div class='horizontalMenuSubWrapper'>
        <ul>
            <li [ngClass]="{active: activeRoute('TriggerDetails/Index')}">
                <a [routerLink]="['/System/TriggerDetails/Index/', activeId]">
                    <i class="fas fa-fw fa-bell-on"></i>
                    {{ 'menu.dashboard' | translate }}
                </a>
            </li>
            <li [ngClass]="{active: activeRoute('TriggerDetails/Details')}">
                <a [routerLink]="['/System/TriggerDetails/Details/', activeId]">
                    <i class="fas fa-fw fa-th-list"></i>
                    {{ 'menu.details' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Notifications']"
                [ngClass]="{active: activeRoute('TriggerDetails/Notifications')}">
                <a [routerLink]="['/System/TriggerDetails/Notifications/', activeId]">
                    <i class="fas fa-fw fa-envelope-open-text "></i>
                    {{ 'general.notifications' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Episodes']"
                [ngClass]="{active: activeRoute('TriggerDetails/Episodes')}">
                <a [routerLink]="['/System/TriggerDetails/Episodes/', activeId]">
                    <i class="fas fa-fw fa-traffic-light-stop"></i>
                    {{ 'general.episodes' | translate }}
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Log']" [ngClass]="{active: activeRoute('TriggerDetails/Log')}">
                <a [routerLink]="['/System/TriggerDetails/Log/', activeId]">
                    <i class="fas fa-fw fa-file-alt"></i>
                    {{ 'menu.log' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>
<div style="clear: both"></div>