import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EntityType, FormMode } from 'app/common/enums';
import { AccountInventory } from 'app/models/account.model';
import { SensorTemplate } from 'app/models/sensorTemplate.model';
import { AccountService } from 'app/services/account/account.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FhChartService } from 'app/services/charts/charts.service';
import { SensorTemplateService } from 'app/services/sensorTemplate/sensorTemplate.service';
import { WorkingHoursSelectorComponent } from 'app/views/shared/usercontrols/workingHours.component';

@Component({
    providers: [FhChartService],
    selector: 'fh-fh-sensor-templates-details',
    templateUrl: 'sensorTemplateDetails.template.html'
})
export class SensorTemplateDetailsViewComponent implements OnInit {
    @ViewChild(WorkingHoursSelectorComponent, { static: false }) workingHoursVar: WorkingHoursSelectorComponent;

    sub;

    loading = false;
    saving = false;

    tagType = EntityType.SensorTemplate.valueOf();

    error: any;
    success: any;

    formMode: FormMode = FormMode.read;

    sensorTemplate: SensorTemplate;
    account: AccountInventory;

    permissionName = 'Triggers_View';
    permissions: {};

    constructor(private sensorTemplateService: SensorTemplateService, private cd: ChangeDetectorRef, private accountService: AccountService, private route: ActivatedRoute, private router: Router, private translateService: TranslateService, private authenticationService: AuthenticationService) {
        this.account = null;

        this.sensorTemplate = new SensorTemplate();
    }

    ngOnInit(): void {
        this.loading = true;
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.getSensorTemplate(id)
        });
    }

    getSensorTemplate(sensorTemplateId = 0) {
        if (this.loading !== true) {
            this.loading = true;
        }

        if (sensorTemplateId === 0 ) {
            this.sensorTemplateService.getSensorTemplateById(this.sensorTemplate.id).subscribe(sensorTemplate => {
                this.sensorTemplate = sensorTemplate;
                this.loading = false;
            });
        } else {
            this.sensorTemplateService.getSensorTemplateById(sensorTemplateId).subscribe(sensorTemplate => {
                this.sensorTemplate = sensorTemplate;
                this.loading = false;
            });
        }
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
        }

        this.getSensorTemplate()
    }

    onDelete() {
        this.loading = true;

        this.sensorTemplateService.deleteSensorTemplate(this.sensorTemplate).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Trigger schedule is successfully deleted.'
            };

            setTimeout(() => {
                this.router.navigate(['/Devices/SensorTemplates/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    onSave() {
        this.saving = true;
        this.loading = true;

        this.sensorTemplateService.updateSensorTemplate(this.sensorTemplate).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Trigger schedule is successfully updated.'
            };

            this.saving = false;
            this.loading = false;
            this.cd.markForCheck();

            this.setFormMode(FormMode.read);
        }, error => {
            this.loading = false;
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }
}
