import { Component, Input, OnInit, ChangeDetectorRef, OnChanges, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode, IconColors, languages, VehicleType } from 'app/common/enums';
import { Asset } from 'app/models/asset.model';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { AssetType } from 'app/models/assetType.model';
import { AccountService } from 'app/services/account/account.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { getIconPath, iconCategories, iconIcon } from 'app/common/globals';


@Component({
    selector: 'fh-asset-icon-chooser',
    templateUrl: 'assetIconChooser.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssetIconChooserDetailsComponent implements OnChanges, OnInit {

    @Input() iconId: number;
    @Input() formMode: any = FormMode.read;
    @Output() onIconChanged = new EventEmitter();

    iconPath: string;
    iconPathLarge: string;

    categories = [];
    iconValues = [];

    iconSelector = [];

    iconCategory;
    iconSelection;
    iconColor = '0';
    colors: { value: any; name: any; }[];

    constructor(private cd: ChangeDetectorRef, private assetTypeService: AssetTypeService, private accountService: AccountService) {
        this.categories = Object.values(iconCategories);

        this.iconValues = Object.values(iconIcon);

        this.colors = Object.keys(IconColors)
            .filter(k => typeof IconColors[k] === 'string')
            .map(n => ({ value: n.toString(), name: IconColors[n] }));
    }

    ngOnInit(): void {
        const icon = getIconPath(this.iconId);
        this.iconPath = icon[1];
        this.iconPathLarge = icon[2];
    }

    ngOnChanges(): void {
        if (this.iconId) {

            const icon = getIconPath(this.iconId);
            this.iconPath = icon[1];
            this.iconPathLarge = icon[2];

            let category: number = (this.iconId) / 1000;
            let vehicle: number = (this.iconId) % 1000 / 10;
            let color: number = (this.iconId) % 10;

            category = Math.floor(category);
            vehicle = Math.floor(vehicle);
            color = Math.floor(color);

            this.iconCategory = category;
            this.iconColor = color.toString();

            this.iconCategory = Object.values(iconCategories)[category - 1].Category;
            this.catChanged(null);
            this.iconSelection = this.iconValues[vehicle].Category;
        }
    }

    catChanged(event) {
        this.iconValues = Object.values(iconIcon.filter(x => x.Category.indexOf(this.iconCategory) > -1));
    }

    itemSelected(event) {

        let selectedCatIndex = 0;
        let selectedIconIndex = 0;
        // const selectedIconColor = 8;

        // Get index for cat
        this.categories.forEach((cat, index) => {
            if (cat.Category === this.iconCategory) {
                selectedCatIndex = index;
            }
        });

        // Get index for icon
        // tslint:disable-next-line:no-shadowed-variable
        this.iconValues.forEach((icon, index) => {
            if (icon.Category === this.iconSelection) {
                selectedIconIndex = index;
            }
        });

        const iconId = ((selectedCatIndex + 1) * 1000) + (selectedIconIndex * 10) + +this.iconColor;

        const icon = getIconPath(this.iconId);
        this.iconPath = icon[1];
        this.iconPathLarge = icon[2];

        this.onIconChanged.emit(iconId);
    }
}

