<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="#/System/">{{'general.system' | translate }}</a>
            </li>
            <li class="active">{{'general.import' | translate }}</li>
        </ol>

        <span style="margin-left: auto;" class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix hidden-sm hidden-xs" title="Help"
                href="https://help.360locate.com/knowledge-base/export-import/" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="hidden-sm hidden-xs">{{'general.needHelp' |
                    translate }}</span></a>
        </span>
    </div>
    <fh-system-navigation></fh-system-navigation>
</div>


<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <fh-error [success]="success" [error]="error" [warning]="warning" [autoRemove]=false></fh-error>

            <fh-error [error]="errorItems" [autoRemove]=false></fh-error>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <fh-wizard [(step)]="step" [showHeader]=true (finish)="onCheckOutFinish()">
                <fh-wizard-step title="general.chooseEntity" [validate]="entityChooserForm" icon='fa-umbrella'
                    [padding]=false>
                    <form #entityChooserForm="ngForm" style="width: 100%; border-top: 0px">
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.entity' | translate}}
                                        <span class="error" *ngIf="entityVar.invalid">*</span>
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        <ng-select name="actionId" ngDefaultControl [searchable]="false"
                                            [clearable]="false" placeholder="{{ 'general.chooseAction' | translate}}"
                                            [(ngModel)]="selectedEntityId" #entityVar="ngModel"
                                            class="form-control table-inline" style="width:100%"
                                            (change)="entityTypeChanged()" required>
                                            <ng-option [value]=entityType.Device>
                                                <i class="fa fa-fw fa-microchip"></i>
                                                {{ 'general.devices' | translate}}
                                            </ng-option>
                                            <ng-option [value]=entityType.Sims>
                                                <i class="fa fa-fw fa-phone"></i>
                                                {{ 'general.sims' | translate}}
                                            </ng-option>
                                            <ng-option [value]=entityType.Asset>
                                                <i class="fa fa-fw fa-car"></i>
                                                {{ 'general.assets' | translate}}
                                            </ng-option>
                                            <ng-option [value]=entityType.Driver>
                                                <i class="fa fa-fw fa-id-card-alt"></i>
                                                {{ 'general.drivers' | translate}}
                                            </ng-option>
                                            <ng-option [value]=entityType.Geofence>
                                                <i class="fa fa-fw fa-draw-polygon"></i>
                                                {{ 'general.geofences' | translate}}
                                            </ng-option>
                                            <ng-option [value]=entityType.Trailer>
                                                <i class="fa fa-fw fa-trailer"></i>
                                                {{ 'general.trailers' | translate}}
                                            </ng-option>
                                            <ng-option [value]=entityType.Fuel>
                                                <i class="fa fa-fw fa-gas-pump"></i>
                                                {{ 'general.fuel' | translate}}
                                            </ng-option>
                                            \ <ng-option [value]=entityType.Maintenance>
                                                <i class="fa fa-fw fa-car-mechanic"></i>
                                                {{ 'general.maintenance' | translate}}
                                            </ng-option>
                                            <ng-option [value]=entityType.AssetCalibration>
                                                <i class="fa fa-fw fa-tachometer-alt"></i>
                                                {{ 'general.odo' | translate}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                </tr>

                                <tr *ngIf="selectedActionId == actionType.Import">
                                    <td class="row_opening">
                                        {{ 'general.clearItems' | translate}}

                                        <i tooltip="{{ 'general.clearItemsOnImport' |
                                                translate}}" class="fas fa-fw fa-question"></i>
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        <div class="fa_checkbox" style="padding: 5px;">
                                            <input name="clearItemsOnImport" id="clearItemsOnImport" type="checkbox"
                                                [(ngModel)]="clearItemsOnImport" />
                                            <label for="clearItemsOnImport"></label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </fh-wizard-step>
                <fh-wizard-step title="general.selectAccount" [validate]="accountChooserForm" icon='fa-building'
                    [padding]=false>
                    <form #accountChooserForm="ngForm" style="width: 100%; border-top: 0px">
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.reseller' | translate}}
                                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                            (onChanged)="resellerChanged($event)" required [tableInline]="true"
                                            placeholder="{{ 'general.chooseReseller' | translate}}"
                                            [(ngModel)]="selectedResellerId">
                                        </fh-reseller-input>
                                    </td>
                                </tr>
                                <tr *ngIf="useAccount">
                                    <td class="row_opening">
                                        {{ 'general.account' | translate}}
                                        <span class="error" *ngIf="accountVar.invalid">*</span>
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        <fh-account-input name="account" #accountVar="ngModel"
                                            (onChanged)="accountChanged($event)" required
                                            [selectedResellerId]="selectedResellerId" [tableInline]="true"
                                            placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                            [(ngModel)]="selectedAccountId">
                                        </fh-account-input>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </fh-wizard-step>
                <fh-wizard-step title="general.upload" icon='fa-upload' [validate]="uploaderForm" [padding]=false
                    (open)="prepareUploader()">
                    <form #uploaderForm="ngForm" style="width: 100%; border-top: 0px">
                        <div *ngIf="headers.length == 0 && processing == false" style="position:relative"
                            style="padding:10px">
                            <ngx-loading [show]="uploading"></ngx-loading>

                            <div *ngIf="!useButton" class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
                                (onFileDropped)="onFileChange($event)">
                                <div style="text-align: center; margin-top: 10px; opacity: 0.6;"><i
                                        class="fas fa-fw fa-file-alt fa-4x"></i></div>
                                <input hidden type="file" #fileInput (change)="onFileChange($event)" required
                                    style="display: none;">
                            </div>

                            <div *ngIf="useButton">
                                <label for="file-upload" class="btn btn-primary">
                                    <i class="fas fa-fw fa-file-alt"></i> {{'general.browseFile' | translate }}
                                </label>
                                <input id="file-upload" type="file" (change)="onFileChange($event)" multiple="false"
                                    style="display: none;" />
                            </div>

                            <input name="file" class="form-control table-inline" id="file" type="text" required
                                style="display: none;" ngDefaultControl [(ngModel)]="fileName">
                        </div>
                        <div *ngIf="headers.length > 0">
                            <table class="table table-fixed nowrap dtr-inline no-footer" style="width: 100%;">
                                <thead>
                                    <tr>
                                        <th>{{'general.fieldsForEntity' | translate }} ({{ ('enums.entityTypes.' +
                                            selectedEntityId) | translate}})</th>
                                        <th>{{'general.chooseMapping' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let column of columns">
                                        <td class="row_opening">
                                            {{ ('fields.' + column.name) | translate }}
                                            <span class="error" *ngIf="column.isRequired && !column.mapping">*</span>
                                            <!-- && fieldVar[column.name].invalid" -->
                                        </td>
                                        <td *ngIf="column.name !== 'deviceType'" class="row_content"
                                            style="overflow: visible">
                                            <ng-select [name]="column.name"
                                                placeholder="{{'general.chooseField' | translate }}"
                                                class="form-control table-inline" style="width:100%" ngDefaultControl
                                                [(ngModel)]="column.mapping" [required]="column.isRequired">
                                                <ng-option *ngIf="!column.isRequired" [value]="-2">{{'general.ignore' |
                                                    translate }}</ng-option>
                                                <ng-option *ngFor="let header of headers; let i = index"
                                                    [value]="header.id">
                                                    [ {{ header.name }} ]
                                                </ng-option>
                                            </ng-select>
                                        </td>
                                        <td *ngIf="column.name === 'deviceType'" class="row_content"
                                            style="overflow: visible">
                                            <ng-select placeholder="{{ 'general.chooseTrackerTypeSource' | translate}}"
                                                class="form-control table-inline" style="float:left; width:49%"
                                                [clearable]="false" (change)="trackerTypeSourceChanged($event)"
                                                [(ngModel)]="selectedTrackerTypeSource"
                                                [ngModelOptions]="{standalone: true}">
                                                <ng-option [value]="1">{{'enums.trackerTypeSource.1' | translate}}
                                                </ng-option>
                                                <ng-option [value]="2">{{'enums.trackerTypeSource.2' | translate}}
                                                </ng-option>
                                            </ng-select>
                                            <ng-select *ngIf="selectedTrackerTypeSource === 1" [name]="column.name"
                                                placeholder="{{'general.chooseField' | translate }}"
                                                class="form-control table-inline" style="float:right; width:49%"
                                                ngDefaultControl [(ngModel)]="column.mapping"
                                                [required]="column.isRequired">
                                                <ng-option *ngIf="!column.isRequired" [value]="-2">{{'general.ignore' |
                                                    translate }}</ng-option>
                                                <ng-option *ngFor="let header of headers; let i = index"
                                                    [value]="header.id">
                                                    [ {{ header.name }} ]
                                                </ng-option>
                                            </ng-select>
                                            <ng-select *ngIf="selectedTrackerTypeSource === 2" [name]="column.name"
                                                [clearable]="true"
                                                placeholder="{{ 'general.chooseDeviceType' | translate}}"
                                                class="form-control table-inline" style="float:right; width:49%"
                                                [(ngModel)]="selectedTrackerTypeId" [required]="column.isRequired">
                                                <ng-option *ngFor="let deviceType of deviceTypes"
                                                    [value]="deviceType.id">
                                                    {{deviceType.modelName}} | {{deviceType.description}}
                                                </ng-option>
                                            </ng-select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </fh-wizard-step>
                <fh-wizard-step title="general.validate" icon='fa-glasses' [padding]=false (open)="parseData()">
                    <div style="max-height: 500px; display: block; overflow-y: auto;">
                        <table class="table nowrap dtr-inline no-footer" style="width: 100%;">
                            <thead>
                                <tr>
                                    <th title="Validated">{{'general.validated' | translate}}</th>
                                    <th title="Action">{{'general.action' | translate}}</th>
                                    <th *ngFor="let key of uploadData[0] | keys" style="text-transform: capitalize; ">
                                        <span *ngIf="key !== 'isNewEntity' && key !== 'hasError'">{{key}}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let c of uploadData"
                                    [ngClass]="{'error': c.hasError === true, 'new-entity': c.isNewEntity === true}">
                                    <td>
                                        <i *ngIf="c.hasError === false" class="fa-solid fa-check"
                                            style="color:green"></i>
                                        <i *ngIf="c.hasError === true" class="fa-solid fa-xmark" style="color:red"></i>
                                    </td>
                                    <td *ngIf="c.isNewEntity === true">{{'general.create' | translate}}</td>
                                    <td *ngIf="c.isNewEntity === false">{{'general.update' | translate}}</td>
                                    <td *ngFor="let key of c | keys">
                                        <span
                                            *ngIf="key !== 'isNewEntity' && key !== 'hasError' && key !== identifierName">{{c[key]}}</span>
                                        <span *ngIf="key === identifierName">
                                            <span *ngIf="c[identifierName] === -1">-</span>
                                            <span
                                                *ngIf="c[identifierName] !== -1 && c[identifierName] !== 0">{{c[identifierName]}}</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </fh-wizard-step>
                <fh-wizard-step title="general.confirm" icon='fa-check' [padding]=false (open)="getConfirm()">
                    <ngx-loading [show]="processing"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.reseller' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    {{ selectedReseller?.resellerDescription }}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.account' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    {{ selectedAccount?.name }}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.action' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    {{ 'general.import' | translate}}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.entity' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    {{ ('enums.entityTypes.' + selectedEntityId) | translate}}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.insertCount' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    {{ insertCount }}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.updateCount' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    {{ updateCount }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fh-wizard-step>
            </fh-wizard>
        </div>
    </div>
</div>