import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'app/modules/notification';
import { AccountService } from 'app/services/account/account.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { UserService } from 'app/services/users/user.service';

@Component({
  selector: 'fh-account-matrix',
  templateUrl: 'matrix.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountMatrixComponent implements OnInit {
  loading = false;
  loadingAccountViewers = false;

  viewers = [];
  currentPage = 0;
  account;
  accountId;
  groups;
  selectedItems = {};
  groupedUserGroups = [];

  error;
  success;
  filter = '';

  constructor(private userService: UserService, private cd: ChangeDetectorRef, protected notificationsService: NotificationsService, private authenticationService: AuthenticationService, private accountService: AccountService, private route: ActivatedRoute) {
    this.accountId = this.authenticationService.getAccountId();
  }

  ngOnInit(): void {
    this.loading = true;

    this.route.params.subscribe(params => {
      const id = params['id'];

      if (id) {
        this.accountId = id;
        this.accountService.getAccountById(id).subscribe(account => {
          this.account = account;
          this.cd.markForCheck();
        });
      }

      this.getAccountViewers();
    });
  }

  saveAccountViewers() {
    const matrixObjects = [];

    for (const [key, value] of Object.entries(this.selectedItems)) {
      const keys = Object.keys(value);
      const viewers = keys.filter((active) => value[active]);
      matrixObjects.push({ id: key, viewers });
    }

    this.loading = true;
    this.cd.markForCheck();

    this.success = null;
    this.error = null;

    this.accountService.updateSharingMatrix(this.accountId, matrixObjects).subscribe((matrixResult) => {

      this.success = {
        statusText: 'Success',
        success: 'Sharing is successfully updated.'
      };
      this.cd.markForCheck();

      this.getAccountViewers();
    }, error => {
      this.error = error;
      this.cd.markForCheck();
      this.getAccountViewers();
    });
  }

  getAccountViewers() {
    this.loadingAccountViewers = true;
    this.accountService.getAccountSharedAssetGroups(this.accountId).subscribe(assetGroups => {
      this.loadingAccountViewers = false;
      this.cd.markForCheck();

      this.groups = assetGroups.sort((a, b) => -(a.selected - b.selected));

      this.groupedUserGroups = Object.values(this.groups.reduce((object, item) => {

        this.selectedItems[item.id] = {};
        if (item.viewers) {
          this.selectedItems[item.id] = item.viewers.reduce((acc, curr) => (acc[curr] = 1, acc), {});
        }

        (object[item['ownerName']] = object[item['ownerName']] || []).push(item);
        return object;
      }, {}));
    });

    this.cd.markForCheck();

    this.userService.getViewersbyAccountId(this.accountId).subscribe(viewerObject => {
      this.viewers = viewerObject.sort((a, b) => a.emailLoginName.localeCompare(b.emailLoginName));
      this.loading = false;
      this.cd.markForCheck();
    }, error => {
      this.success = null;
      this.error = error;
      this.loading = false;
      this.cd.markForCheck();
    });
  }
}
