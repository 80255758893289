<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="">{{'general.settings' | translate }}</a>
            </li>
            <li class="active">{{'general.deviceRoutingConfiguration' | translate}}</li>
        </ol>
    </div>
    <fh-device-navigation></fh-device-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class='col-lg-12'>
            <fh-error [error]="error" [success]="success"></fh-error>

            <!-- Custom filters -->
            <div class="ibox" [hidden]="!showFilter">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                    <span class="pull-right hand btn btn-info filter-margin-fix" (click)="clearAllFilters();">
                        <i class="fa fa-fw fa-times"></i>
                        <span class="hidden-sm hidden-xs"> {{'general.clearFilters' | translate }}</span>
                    </span>
                </div>
                <div class="ibox-content slick_toppadding" style="position: relative;">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div id="dynamicFilters"></div>
                    <div style="clear:both"></div>
                </div>
            </div>
            <!-- End default dynamic filters -->

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fa fa-fw fa-shuffle"></i> {{ 'general.deviceRoutingConfiguration' | translate }}</h5>
                    <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)" [constructorName]="constructorName" (filterChanged)="showFilter = $event" (presetLoaded)="onPresetLoaded($event)"
                        (columnsChanged)="drawFilterRow()"></iboxseach>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <table style="width:100%; min-height:50px;" fh-datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger" class="table  nowrap margin-bottom-0 dataTable dtr-inline">
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>