import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { GeofenceGroup } from 'app/models/group.model';
import { AccountService } from 'app/services/account/account.service';
import { AccountInventory } from 'app/models/account.model';
import { UserContactGroup } from 'app/models/userContactGroup.model';

@Component({
    selector: 'fh-geofence-group-details',
    templateUrl: 'geofenceGroupDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class GeofenceGroupDetailsComponent {

    @Input() geofenceGroup: GeofenceGroup;
    @Input() userContactGroups: UserContactGroup[] = [];
    @Input() formMode: any = FormMode.read;
    @Input() loadingUserContactGroups = false;
    @Input() geofenceGroupOptions;

    loadingAccounts = false;

    constructor() {
    }
}

