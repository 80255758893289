import { ChangeDetectorRef, Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormMode, Form, StorageType } from 'app/common/enums';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { GeofenceService } from 'app/services/geofence/geofence.service';
import { Geofence } from 'app/models/geofence.model';
import { GeofenceGroupsService } from 'app/services/geofence/geofenceGroups.service';
import { GeofenceGroup } from 'app/models/group.model';
import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { TranslateService } from '@ngx-translate/core';
import { StorageHelper } from 'app/common/storagehelper';
import { GeofenceDetailsComponent } from '../shared/usercontrols/geofenceDetails.component';

declare var L;

@Component({
  selector: 'fh-geofences-add',
  templateUrl: 'add.template.html'
})
export class GeofencesAddViewComponent implements OnInit {
  @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;
  @ViewChild(GeofenceDetailsComponent, { static: false }) geofenceDetailsControl: GeofenceDetailsComponent;

  token: string;
  loading = false;
  geofence: Geofence = new Geofence();

  formMode: FormMode = FormMode.add;
  error: any;
  permissions: {};
  success: { statusText: string; success: string; };
  loadingGroups = false;

  geofenceGroups: GeofenceGroup[];
  selectedGroups: any;
  allowEdit = true;
  allowBookmarks = false;
  userForm;

  geofences = [];
  selectedGeofenceGoups = [];
  selectedGeofenceGoupsSource = [];

  permissionName = 'Geofences_Add';

  mapHeight = 250;
  showMapOnSide = false;
  storageType = StorageType.LocalStorage;

  constructor(private translateServe: TranslateService, private geofenceService: GeofenceService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router, private geofenceGroupService: GeofenceGroupsService, private storageHelper: StorageHelper) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();

    this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showMapOnSide').subscribe((result) => {
      this.showMapOnSide = JSON.parse(result) === true;

      if (this.showMapOnSide) { this.mapHeight = 330; }
      this.cd.markForCheck();
    });

    this.selectedGroups = [];
    this.geofenceGroups = [];
  }

  ngOnInit(): void {
  }

  onSaveShape(layer) {
    this.geofenceDetailsControl.geofence.layerRef = layer;
    this.geofenceService.parseLayer(layer, this.geofence);

    setTimeout(() => {
      this.leafletMapComponent.map.fitBounds(layer.getBounds());
      this.cd.detectChanges();
    }, 1);
  }

  onMapReady(leafletMapComponent) {
    leafletMapComponent.addDrawControls();

    if (window.history.state?.newGeofence) {
      const geofencePopup = `<div style="width: 300px; overflow: auto;" class="leaflet-mappopup">
      <div class="header">Start Position</div></div>`;

      L.geoJSON(window.history.state.newGeofence, {
        pointToLayer: function (geoJsonPoint, latlng): L.Circle<any> {
          return L.circle(latlng, 20, {
            fillColor: 'var(--svgcolor)',
            color: 'var(--svgcolor)',
            weight: 4,
            opacity: 0.5,
            fillOpacity: 0.2
          });
        },
      })
      .eachLayer((_layer) => {
        if (_layer.feature.geometry?.type === 'LineString') {
          const corridor = L.corridor(_layer.getLatLngs(), {
            corridor: 150,
            className: 'route-corridor',
            opacity: '0.35',
            color: 'var(--svgcolor)',
          });

          corridor.bindPopup(geofencePopup, {
            closeButton: false
          });

          this.geofence.layerRef = corridor;
          this.geofence.radius = corridor.corridor;

          setTimeout(() => {
            leafletMapComponent.map.fire('pm:create', { layer: corridor });
          }, 50);
        } else {
          _layer.bindPopup(geofencePopup, {
            closeButton: false
          });

          this.geofence.layerRef = _layer;

          setTimeout(() => {
            leafletMapComponent.map.fire('pm:create', { layer: _layer });
          }, 50);
        }
      });
    } else {
      leafletMapComponent.map.invalidateSize();
    }
  }

  accountChanged(accountId) {
    if (accountId == null) {
      this.geofenceGroups = [];
    } else {
      this.loadingGroups = true;
      this.geofenceGroupService.getGeofenceGroups(accountId).subscribe(res => {
        this.geofenceGroups = res;
        this.loadingGroups = false;
      });
    }

    this.cd.markForCheck();
  }

  onInsert() {
    this.loading = true;

    if (!this.geofence.isPersonal) {
      this.geofence.userId = null;
    }

    this.geofence.layerRef = null;
    this.geofence.accountId = +this.geofence.accountId;

    this.geofenceService.saveGeofence(this.geofence).subscribe(result => {
      this.success = {
        statusText: 'Success',
        success: this.translateServe.instant('general.saveSuccessRedirect', { entity: 'geofence' })
      };

      setTimeout(() => {
        this.router.navigate(['/GeofenceDetails/Index/' + result.id]);
      }, 2000);

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }
}
