import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { ErrorLogService } from './error-log.service';

@Injectable()
export class LoggingService {

    constructor(private errorLogService: ErrorLogService, private authenticationService: AuthenticationService) { }

    public log(caller: string, object: any) {
        if (this.authenticationService.config.Debug === true) {
            console.log(caller, object);
        }
    }
    public warn(caller: string, object: any) {
        console.warn(caller, object);
    }

    public error(caller: string, error: any) {
        this.errorLogService.logErrorString(caller, error);
    }
}
