<ng-select [loading]="loadingAssetGroups" ngDefaultControl [searchable]="true" [clearable]="clearable"
    [ngStyle]="{'height': (multiple === true) ? 'unset' : '' }" [disabled]="loadingAssetGroups || (!canAddNew && assetGroups.length == 0)"
    [readonly]="readonly" placeholder="{{placeholder}} ({{assetGroups.length}})" #input [multiple]="multiple"
    [(ngModel)]="value" class="form-control"
    [ngClass]="tableInline === true ? 'table-inline' : ''" addTagText="{{'general.createNew' | translate}}" [addTag]="canAddNew ? createNew.bind(this) : false"
    (ngModelChange)="assetGroupChanged()" [items]="assetGroups" bindLabel="name" bindValue="id" [virtualScroll]="false">
    <ng-template ng-label-tmp let-assetgroup="item" let-clear="clear">
        <span><i class="fas fa-fw fa-layer-group" title="Items: {{assetgroup.itemCount}}"></i>
            {{assetgroup.displayName }} ({{assetgroup.itemCount}})
            <span *ngIf="serverResponses[assetgroup.displayName]" class="badge badge-danger">
                <i class="fas fa-fw fa-exclamation-triangle"></i>
            </span>
        </span>
        <span class="ng-value-icon right" (click)="clear(assetgroup)" aria-hidden="true">&times;</span>
    </ng-template>
    <ng-template ng-option-tmp let-assetgroup="item" let-search="searchTerm">
        <div>
            <div [ngOptionHighlight]="search">
                {{ assetgroup.displayName }} ({{assetgroup.itemCount}})
            </div>
            <div [ngSwitch]="true">
                <span class="badge badge-primary" tooltip="'Item added'" *ngSwitchCase="serverResponses[assetgroup.displayName] === false">
                    <i class="fas fa-fw fa-square-check"></i> {{ 'general.created' | translate }}
                </span>
                <span class="badge badge-danger" tooltip="'Item failed'" *ngSwitchCase="serverResponses[assetgroup.displayName]?.length > 0">
                    <i class="fas fa-fw fa-exclamation-triangle"></i> {{ serverResponses[assetgroup.displayName] }}
                </span>
            </div>
        </div>
        <small class="text-muted">
            <span class="block-item"
                *ngFor="let assetGroupItem of assetgroup.assetGroupItems | slice:0:(assetgroup.showAll ? 99999999 : 5) | orderBy : 'assetName':false">
                {{assetGroupItem.assetName}}
            </span>

            <span class="block-item" *ngIf="assetgroup.assetGroupItems?.length > 5 && !assetgroup.showAll"
                (click)="showAll($event, assetgroup)">
                <i class="fas fa-fw fa-layer-group"></i> {{ 'general.more' | translate}}
            </span>

            <span *ngIf="assetgroup.assetGroupItems?.length == 0">{{'general.noDataFound' | translate}}</span>
        </small>
    </ng-template>
</ng-select>
