import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientAnnouncement } from 'app/models/clientAnnouncement.model';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';
import { LoggingService } from '../logging/logging.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class ClientAnnouncementService {

    token: string;
    timestamp;
    url = '';

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getWebserviceURL('clientAnnouncement');
    }

    getPagingUrl() {
        return this.url + 'Paging';
    }

    getServerAnnouncements(accountId?: number): Observable<ClientAnnouncement[]> {
        return this.http.get(this.url + (accountId ? `?accountId=${accountId}` : ''), { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getClientAnnouncementById(id: string): Observable<ClientAnnouncement> {
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseReponseDetails(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    saveClientAnnouncement(clientAnnouncement: ClientAnnouncement): Observable<any> {
        console.log('save clientAnnouncement');
        return this.http.post(this.url, clientAnnouncement, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    updateClientAnnouncement(clientAnnouncement: ClientAnnouncement): Observable<any> {
        console.log('save clientAnnouncement');
        return this.http.put(this.url + clientAnnouncement.id, clientAnnouncement, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    deleteClientAnnouncement(clientAnnouncement: ClientAnnouncement): Observable<any> {
        console.log('save clientAnnouncement');
        return this.http.delete(this.url + clientAnnouncement.id, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    parseResponse(json: any): ClientAnnouncement[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' ClientAnnouncements.');

        const ident = 1;
        const assets: ClientAnnouncement[] = [];

        json.forEach(item => {

            const asset = this.parseReponseDetails(item);
            assets.push(asset);
        });

        this.timestamp = new Date().getTime();
        return assets;
    }

    parseReponseDetails(item) {
        //   this.loggingClient.log(this.constructor().name + " - New", item);
        const clientAnnouncement = new ClientAnnouncement();
        clientAnnouncement.id = item.id;
        clientAnnouncement.accountId = item.accountId;
        clientAnnouncement.resellerId = item.resellerId;
        clientAnnouncement.companyName = item.companyName;

        clientAnnouncement.startDate = item.startDate ? new Date(item.startDate) : null;
        clientAnnouncement.timestamp = item.timestamp ? new Date(item.timestamp) : null;

        clientAnnouncement.messageLevel1 = item.messageLevel1;
        clientAnnouncement.messageLevel2 = item.messageLevel2;
        clientAnnouncement.messageLevel3 = item.messageLevel3;

        clientAnnouncement.daysToLevel2 = item.daysToLevel2;
        clientAnnouncement.daysToLevel3 = item.daysToLevel3;
        clientAnnouncement.daysToBlock = item.daysToBlock;
        clientAnnouncement.useBlock = item.useBlock;

        return clientAnnouncement;
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
