/**
 * Authentication request object.
 */
export class AuthenticateRequest {

    constructor(public Username: string, public Password: string, public LoginAsRegularUser: boolean, public Token: string, public firebaseToken, public UseRefreshToken: boolean) { }
}



