<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetGroups/Overview">{{'general.assetGroups' | translate }}</a>
            </li>
            <li>
                <a href="/#/AssetGroupDetails/Index/{{assetGroup?.id}}">{{assetGroup?.displayName}}</a>
            </li>
            <li class="active">{{'general.assetGroupDetails' | translate }}</li>
        </ol>
    </div>
    <fh-device-navigation>
    </fh-device-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-5">
                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-layer-group"></i> {{'general.assetGroup' | translate }}
                            </h5>
                        </div>
                        <fh-group-details [group]="assetGroup" [formMode]="formMode" [loading]="loading || saving">
                        </fh-group-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()"
                            [allowEdit]="permissions['AssetGroups_Edit']" (onDelete)="onDelete()"
                            [allowDelete]="permissions['AssetGroups_Delete']" (setFormMode)="setFormMode($event)"
                            [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>

                <fh-usertable-overview name="{{'general.sharedWith' | translate }}" [loading]="loadingUsers"
                    [groups]="userItems" [showItem]="true" [linkUrl]="'UserDetails'" [sharingIcon]=true>
                </fh-usertable-overview>

            </div>
            <div class="col-lg-7">
                <div class="ibox" style="position:relative" *ngIf="formMode == 3">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-list-ul"></i> {{'general.assets' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content" style="position:relative">
                        <ngx-loading [show]="loadingAssets"></ngx-loading>
                        <fh-dual-list [source]="source" [(destination)]="confirmed" [format]="format"
                            [display]="display" [sort]=true>
                        </fh-dual-list>
                    </div>
                </div>

                <fh-group-overview *ngIf="formMode == 1" name="{{'general.assets' | translate }}"
                    [loading]="loading || saving || loadingGroups" [groups]="assetGroupsItems" [showItem]="true"
                    [linkUrl]="deviceLink">
                </fh-group-overview>

                <fh-schedule-assignments [assetGroupId]="assetGroup.id" [selectedAccountId]="assetGroup?.accountId"
                    [deletePermission]="'AssetGroups_Edit'">
                </fh-schedule-assignments>

                <fh-tagging [objectId]="assetGroup?.id" [objectType]="tagType">
                </fh-tagging>
            </div>
        </div>
    </div>
</fh-permission-handler>