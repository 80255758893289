<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/System/">{{'general.system' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/System/AuditLog">{{'general.auditLogs' | translate }}</a>
            </li>
            <li class="active">{{'general.dashboard' | translate }}</li>
        </ol>
    </div>
    <fh-system-navigation></fh-system-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error">
            <div class="col-lg-12">
                <fh-error [error]="error"></fh-error>
            </div>
        </div>
        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <div class="ibox" style="position: relative;">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-archive"></i> {{'general.auditLog' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position:relative">
                        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody *ngIf="!auditLog">
                                <tr>
                                    <td colspan="4">
                                        {{ 'general.noAuditLog' | translate}}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="auditLog">
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.date' | translate}}
                                    </td>
                                    <td class="row_content" title="{{ auditLog.createdDate?.toLocaleString() }}">
                                        {{ auditLog.createdDate | amDateFormat: 'YYYY-MM-DD HH:mm:ss '}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.user' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ auditLog.user }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.description' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ auditLog.description }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.entityType' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ ('enums.entityTypes.' + auditLog.entityType) | translate }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.logType' | translate}}
                                    </td>
                                    <td class="row_content" style="white-space: normal;">
                                        {{ ('enums.logType.' + auditLog.logType) | translate }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.companyName' | translate}}
                                    </td>
                                    <td class="row_content">
                                        <a class="secondary link_bolder"
                                            href="/#/AccountDetails/Index/{{auditLog?.accountId}}">{{auditLog?.companyName
                                            }}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.entity' | translate}}
                                    </td>
                                    <td class="row_content">
                                        <a class="secondary link_bolder" href="{{auditLog?.link}}">{{auditLog?.entityId
                                            }}</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div *ngIf="permissions['Platform_IsReseller'] && auditLog && auditLog.delta != null " class="ibox"
                    style="position: relative;">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-file-alt"></i> {{'general.json' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative; word-break: break-all;">
                        <pretty-json [obj]="auditLog.delta"></pretty-json>
                    </div>
                </div>

                <div *ngIf="permissions['Platform_IsReseller'] && auditLog && auditLog.deltaItems != null " class="ibox"
                    style="position: relative;">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-file-alt"></i> {{'general.json' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position:relative">
                        <pretty-json [obj]="auditLog.deltaItems"></pretty-json>
                    </div>
                </div>

                <div *ngIf="auditLog && auditLog.deltaItems != null && (auditLog.deltaItems.length > 0)" class="ibox"
                    style="position: relative;">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-file-alt"></i> {{'general.delta' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position:relative"
                        *ngIf="auditLog.deltaItems != null && auditLog.deltaItems.Differences != null">

                        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th style="width:33%">
                                        {{ 'general.item' | translate}}
                                    </th>
                                    <th style="width:33%">
                                        {{ 'general.from' | translate}}
                                    </th>
                                    <th style="width:33%">
                                        {{ 'general.to' | translate}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of auditLog.deltaItems.Differences; let i = index">
                                    <td style="width:33%">
                                        {{ item.PropertyName}}
                                    </td>
                                    <td style="width:33%" class="elipses_overflow">
                                        {{ item.OldValue | json}}
                                    </td>
                                    <td style="width:33%" class="elipses_overflow">
                                        {{ item.NewValue | json}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-content slick" style="position:relative"
                        *ngIf="auditLog.deltaItems != null && auditLog.deltaItems.length > 0">
                        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <thead>
                                <tr>
                                    <th style="width:33%">
                                        {{ 'general.item' | translate}}
                                    </th>
                                    <th style="width:33%">
                                        {{ 'general.from' | translate}}
                                    </th>
                                    <th style="width:33%">
                                        {{ 'general.to' | translate}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of auditLog.deltaItems; let i = index">
                                    <td style="width:33%">
                                        {{ item.Item}}
                                    </td>
                                    <td style="width:33%" class="elipses_overflow">
                                        {{ item.From}}
                                    </td>
                                    <td style="width:33%" class="elipses_overflow">
                                        {{ item.To}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>