import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'fh-driver-navigation',
    templateUrl: 'driverNavigation.template.html'
})

export class DriverNavigationComponent implements OnInit {
    sub: any;
    activeId: any;

    constructor(private route: ActivatedRoute, private router: Router) {
    }

    activeRoute(routename: string): boolean {
        const active = this.router.url.indexOf(routename) !== -1;
        return active;
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.activeId = id;
        });
    }

    isDriverGroupDetails() {
        const routes = ['DriverGroupDetails'];

        let routeActive = false;

        routes.forEach(route => {
            const active = this.router.url.indexOf(route);
            if (active > -1) {
                routeActive = true;
            }
        });

        return routeActive;
    }

    isData() {
        const routes = ['Assignments', 'DayViewAssignments', 'DayViewTrips'];

        let routeActive = false;

        routes.forEach(route => {
            const active = this.router.url.indexOf(route);
            if (active > -1) {
                routeActive = true;
            }
        });

        return routeActive;
    }
}
