import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ReportCategory } from 'app/common/enums';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { WizardComponent } from 'app/modules/wizard/wizard';
import { AccountService } from 'app/services/account/account.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ReportService } from 'app/services/reports/report.service';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { ResellerService } from 'app/services/resellers/resellers.service';
import { CronOptions } from 'app/modules/cron-editor/CronOptions';
import { ReportSubscription, ReportTemplate } from 'app/models/reporting.model';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';

window['moment'] = Moment;
mTZ();
@Component({
  selector: 'fh-reporting-create',
  templateUrl: 'reportingCreate.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingCreateViewComponent implements OnInit {
  @ViewChild(WizardComponent, { static: true }) wizard: WizardComponent;

  reportCategory = ReportCategory;

  availableReportCategories = {};

  step = 1;
  isReportReady = false;
  processing = false;

  success;
  error;

  // Resellers
  selectedReseller;
  loadingResellers = false;
  selectedResellerId;

  // Accounts
  selectedAccount;

  loadingAccounts = false;

  // Reports
  reports = [];
  reportsAll = [];
  reportTemplates = [];
  reportTemplatesAll = [];

  customFields = [];

  name = '';
  selectedReportTemplateId = null;
  selectedReport;

  // Filters
  daterangepickerModel: Date[];
  assetGroups = [];
  driverGroups = [];

  selectedAssetGroups;
  selectedDriverGroups;
  selectedGeofenceGroups;

  loadingAssetGroups = false;
  loadingDriverGroups = false;
  filterSmallTrips = false;
  filterGrouping = 0;
  filterDateGrouping = 0;

  loadingColumns = false;

  // Report
  reportShown = false;
  reportData = {
    request: null,
    kpiList: [],
    charts: [],
    data: [],
  };

  // Grid
  dtOptions;
  dtTrigger;

  // Datepicker
  timezoneIana: string;
  public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();

  // Notifications
  sendOutputMessage = false;
  selectedUserContactGroups = [];
  useCustomSubject = false;

  // Cron selector
  // Hangfire 1.7+ compatible expression: '3 2 12 1/1 ?'
  // Quartz compatible expression: '4 3 2 12 1/1 ? *'
  public reportingInterval = '10 8 ? * MON';

  public isCronDisabled = false;
  public cronOptions: CronOptions = {
    formInputClass: 'cron-editor-input table-inline',
    formSelectClass: 'cron-editor-select table-inline',
    formRadioClass: 'cron-editor-radio',
    formCheckboxClass: 'cron-editor-checkbox',

    defaultTime: '10:00:00',
    use24HourTime: true,

    hideMinutesTab: true,
    hideHourlyTab: true,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: true,

    hideSeconds: true,
    removeSeconds: true,
    removeYears: true
  };

  defaultSourceColumns = [];

  reportSubscription: ReportSubscription;
  permissions: {};

  constructor(private resellerService: ResellerService, private cd: ChangeDetectorRef, private translate: TranslateService, private reportService: ReportService, private accountService: AccountService, private authenticationService: AuthenticationService) {
    this.timezoneIana = this.authenticationService.getTimeZoneIana();
    this.dpConfig = getDefaultDpConfig(Moment, authenticationService);
    this.permissions = this.authenticationService.permissions;
  }

  ngOnInit(): void {
    this.reportSubscription = new ReportSubscription();
  }

  resellerChanged(resellerId) {
    this.reportSubscription.accountId = null;

    this.selectedResellerId = resellerId;
    this.selectedReseller = this.resellerService.resellers.find(x => x.id === resellerId);
  }

  accountChanged(accountId) {
    this.reportSubscription.accountId = accountId;
    this.selectedAccount = this.accountService.accounts.find(x => x.id === this.reportSubscription.accountId);

    this.selectedAssetGroups = [];
    this.selectedDriverGroups = [];
    this.selectedGeofenceGroups = [];
  }

  listReports() {
    this.reportSubscription.selectedReportType = null;

    const reportsArray = [];
    this.selectedAssetGroups = [];
    this.selectedDriverGroups = [];
    this.selectedGeofenceGroups = [];
    this.reportTemplates = [];

    this.selectedReportTemplateId = null;

    // tslint:disable-next-line:forin
    for (const key in this.reportsAll) {
      reportsArray.push({ ...this.reportsAll[key], id: key });
    }

    this.reports = reportsArray.filter(x => x.reportCategory.toString() === this.reportSubscription.selectedReportCategory.toString());

    this.cd.markForCheck();
  }

  prepareFilters() {
    this.selectedAssetGroups = [];
    this.selectedDriverGroups = [];
    this.selectedGeofenceGroups = [];
    this.reportTemplates = [];
    this.customFields = [];

    this.selectedReportTemplateId = null;

    this.reportTemplates = this.reportTemplatesAll.filter(x => x.reportType.toString() === this.reportSubscription?.selectedReportType?.toString());

    if (this.reportTemplates.length === 0) {
      this.selectedReportTemplateId = 'default';
    }
    if (this.reportTemplates.length === 1) {
      this.selectedReportTemplateId = this.reportTemplates[0].id;
    }

    this.cd.markForCheck();
  }

  dateChanged(event) {
  }

  onCheckOutFinish() {
    this.processing = true;

    this.fillReportSubscription(this.reportSubscription);

    this.reportService.saveReportSubscription(this.reportSubscription).subscribe(result => {
      this.processing = false;

      this.reportShown = true;
      this.reportData = result;

      this.success = {
        statusText: 'Success',
        success: result.message
      };

      this.resetWizard();
    }, error => {
      this.error = error
      this.processing = false;
    });
  }

  fillReportSubscription(reportSubscription: ReportSubscription) {
    if (this.reportSubscription.subscriptionType === 2) { // 2 = scheduled
      reportSubscription.reportingInterval = this.reportingInterval;
    }

    reportSubscription.start = moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day').unix();
    reportSubscription.end = moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day').unix();

    this.selectedAssetGroups.forEach(element => {
      if (typeof element === 'number') {
        reportSubscription.selectedAssets.push(element);
      } else if (element.length > 0) {
        if (reportSubscription.selectedAssetGroups.indexOf(element[0].assetGroupId) === -1) {
          reportSubscription.selectedAssetGroups.push(element[0].assetGroupId);
        }
      }
    });

    this.selectedDriverGroups.forEach(element => {
      if (typeof element === 'number') {
        reportSubscription.selectedDrivers.push(element);
      } else if (element.length > 0) {
        if (reportSubscription.selectedDriverGroups.indexOf(element[0].driverGroupId) === -1) {
          reportSubscription.selectedDriverGroups.push(element[0].driverGroupId);
        }
      }
    });

    this.selectedGeofenceGroups.forEach(element => {
      if (typeof element === 'number') {
        reportSubscription.selectedGeofences.push(element);
      } else if (element.length > 0) {
        if (reportSubscription.selectedGeofenceGroups.indexOf(element[0].geofenceGroupId) === -1) {
          reportSubscription.selectedGeofenceGroups.push(element[0].geofenceGroupId);
        }
      }
    });

    this.selectedUserContactGroups.forEach(element => {
      if (typeof element === 'number') {
        reportSubscription.notificationConfiguration.userContactRecipients.push(element);
      } else if (typeof element === 'string') {
        reportSubscription.notificationConfiguration.emailRecipients.push(element);
      } else if (element.length > 0) {
        if (reportSubscription.notificationConfiguration.userContactGroupRecipients.indexOf(element[0].userContactGroupId) === -1) {
          reportSubscription.notificationConfiguration.userContactGroupRecipients.push(element[0].userContactGroupId);
        }
      }
    });
  }

  resetWizard() {
    const oldReport = { ...this.reportSubscription };

    this.reportShown = false;
    this.reportSubscription = new ReportSubscription();

    this.reportSubscription.selectedReportCategory = oldReport.selectedReportCategory;
    this.reportSubscription.selectedReportType = oldReport.selectedReportType;
    this.reportSubscription.accountId = oldReport.accountId;
    this.reportSubscription.name = oldReport.name;

    this.reportSubscription.parameterConfiguration = oldReport.parameterConfiguration;
    this.reportSubscription.template = oldReport.template;

    this.reportSubscription.selectedAssetGroups = oldReport.selectedAssetGroups;
    this.reportSubscription.selectedAssets = oldReport.selectedAssets;
    this.reportSubscription.selectedDriverGroups = oldReport.selectedDriverGroups;
    this.reportSubscription.selectedDrivers = oldReport.selectedDrivers;
    this.reportSubscription.selectedGeofenceGroups = oldReport.selectedGeofenceGroups;
    this.reportSubscription.selectedGeofences = oldReport.selectedGeofences;

    this.wizard.reset();
  }

  getReportTypes() {
    this.loadingColumns = true;

    forkJoin([this.reportService.getReportColumnsByReportType(this.reportSubscription.selectedReportType), this.reportService.getReportTemplates()]).subscribe(([columns, templates]) => {
      this.reportsAll = columns;

      for (const item in columns) {
        if (item === null) {
          continue;
        }

        this.availableReportCategories[columns[item].reportCategory] = true;
      }

      this.reportTemplatesAll = templates;

      this.selectedReport = columns['0'];
      this.defaultSourceColumns = this.selectedReport?.template?.columnConfiguration;

      // Set daterange
      this.daterangepickerModel = [
        Moment().subtract(this.selectedReport.defaultDayCount, 'day').startOf('day').toDate(),
        Moment().add(0, 'days').endOf('day').toDate(),
      ];

      this.loadingColumns = false;
      this.cd.markForCheck();
    }, error => {
      this.success = null;
      this.error = error;
    });
  }

  getDefaultColumns() {
    this.selectedReport = this.reportsAll[this.reportSubscription.selectedReportType];

    if (this.customFields.length === 0) {
      for (const name in this.selectedReport.filterDefinitions) {
        if (name.toLowerCase().startsWith('select') || name === 'filterGrouping' || name === 'filterDateGrouping') {
          continue;
        }

        if (this.selectedReport.filterDefinitions[name].type === 'bool') {
          this.reportSubscription.parameterConfiguration[name] = this.selectedReport.filterDefinitions[name].defaultValue === 'true';
        } else if (this.selectedReport.filterDefinitions[name].type === 'enum') {
          // Set json options
          this.selectedReport.filterDefinitions[name].options = JSON.parse(this.selectedReport.filterDefinitions[name].defaultValue);
          // Set defaults
          this.reportSubscription.parameterConfiguration[name] = [];
          this.selectedReport.filterDefinitions[name].options.forEach(option => {
            if (option.Value == true) {
              this.reportSubscription.parameterConfiguration[name].push(option.Key);
            }
          });
        } else {
          this.reportSubscription.parameterConfiguration[name] = this.selectedReport.filterDefinitions[name].defaultValue;
        }

        this.customFields.push({ ...this.selectedReport.filterDefinitions[name], name });
      }
    }

    this.defaultSourceColumns = this.selectedReport?.template?.columnConfiguration;
    this.applyColumnConfiguration();
  }

  applyColumnConfiguration() {
    if (this.reportSubscription.selectedReportType == 2){
      this.selectedReportTemplateId = 'default';
    }

    if (this.selectedReportTemplateId === 'default') {
      // Get default report

      const template = this.selectedReport?.template;
      this.reportSubscription.template = new ReportTemplate();
      this.reportSubscription.template = { ...template };
      this.reportSubscription.template.name = 'default';

      this.reportSubscription.template.columnConfiguration = template?.columnConfiguration?.filter(x => x.enabled === true);
    } else {
      // Find template
      const reportTemplate = this.reportTemplatesAll.find(x => x.id === this.selectedReportTemplateId);

      // Set properties
      this.reportSubscription.template = { ...reportTemplate };
      this.reportSubscription.template.columnConfiguration = reportTemplate.columnConfiguration.filter(x => x.enabled === true);
    }
  }

  saveReportTemplate(reportType, event) {
    const reportTemplate = {
      ReportType: reportType,
      Name: event.form.name,
      ColumnConfiguration: event.template.columnConfiguration.filter(x => x.enabled === true),
      TargetResellerId: event.form.targetResellerId,
      TargetAccountId: event.form.targetAccountId,
      TargetUserId: this.authenticationService.getUserId(),
      GroupByIndex: event.template.groupByIndex,
      GroupByType: event.template.groupByType,
      OrderByIndex: event.template.orderByIndex,
      OrderByAscending: event.template.orderByAscending
    };

    this.reportService.saveReportTemplate(reportTemplate).subscribe(_ => {
      this.error = null;
      this.success = null;
      this.success = {
        statusText: 'Success',
        success: this.translate.instant('general.reportTemplateSaved'),
      };
      this.cd.markForCheck();
    }, error => {
      this.success = null;
      this.error = error;
    });
  }

  updateReportTemplate(event) {
    this.reportService.updateReportTemplate(event.template.id, event.template).subscribe(entity => {
      this.error = null;
      this.success = null;

      if (entity.isSuccess) {
        this.success = {
          statusText: 'Success',
          success: this.translate.instant('general.reportTemplateSaved'),
        };
      } else {
        this.error = entity.message;
      }
      this.cd.markForCheck();
    }, error => {
      this.success = null;
      this.error = error;
      this.cd.markForCheck();
    });
  }
}
