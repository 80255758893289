import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { MaintenanceType } from 'app/models/maintenance.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-maintenance-type-details',
    templateUrl: 'maintenanceTypeDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class MaintenanceTypeDetailsComponent implements OnInit {

    @Input() maintenanceType: MaintenanceType;
    @Output() onAccountChanged = new EventEmitter();
    @Input() formMode: any = FormMode.read;

    loadingAccounts = false;
    selectedResellerId;
    permissions: {};

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
        this.permissions = this.authenticationService.permissions;
    }

    accountChanged(accountId) {
        this.onAccountChanged.emit(this.maintenanceType.accountId);
    }

    resellerChanged(resellerId) {
        this.maintenanceType.accountId = null;

        this.selectedResellerId = resellerId;
    }
    ngOnInit(): void {
    }
}
