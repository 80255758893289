import { Component, Input, OnChanges, Inject } from '@angular/core';
import { Asset } from 'app/models/asset.model';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ConfirmationModalComponent } from './confirmationModal.component';

@Component({
    selector: 'fh-assignment-modal',
    templateUrl: 'assignmentModal.template.html',
})
export class AssignmentModalComponent implements OnChanges {

    @Input() device;
    @Input() asset: Asset;

    public archiveDate;
    public startDate;

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    constructor(@Inject(ConfirmationModalComponent) private parent: ConfirmationModalComponent) {
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.dpConfig.withTimepicker = true;
    }

    ngOnChanges(): void {
        if (this.asset) {
            this.startDate = this.asset?.assignmentStart;
            this.archiveDate = this.asset?.assignmentEnd;
        }
    }
}
