import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fh-settings',
  templateUrl: 'settings.template.html'
})
export class SettingsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  constructor() { }

  ngOnInit(): void {
  }
}
