<div class="wrapper wrapper-content" *ngIf="!showContent">
    <div class="row">
        <div class="col-lg-12">
            <div class="alert alert-warning shadow">{{ 'general.noPermissions' | translate:{ value: permissionName } }}
            </div>
        </div>
    </div>
</div>

<ng-content *ngIf="showContent">
</ng-content>