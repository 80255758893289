<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Accounts/Overview">{{'general.accounts' | translate }}</a>
            </li>
            <li *ngIf="account?.id">
                <a href="/#/AccountDetails/Index/{{account.id}}">{{account.name}}</a>
            </li>
            <li class="active">{{'general.matrix' | translate}}</li>
        </ol>
    </div>
    <fh-account-details-navigation [accountId]="accountId"></fh-account-details-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="" *ngIf="error || success">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <form #matrixForm="ngForm">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-podcast"></i>{{ 'general.matrix' | translate }}
                        </h5>
                        <span class="pull-right">
                            <div class="filter-form hidden-sm hidden-xs">
                                <input type="text" name="filter"
                                    class="form-control table-inline top-filter hidden-sm hidden-xs"
                                    [(ngModel)]="filter">
                            </div>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position:relative; overflow-y: auto;">
                        <ngx-loading [show]="loading || loadingAccountViewers"></ngx-loading>
                        <table width="100%" cellpadding="0" cellspacing="0" class="table nowrap dtr-inline no-footer"
                            *ngIf="viewers?.length" style="padding: 0px;">
                            <thead class="dataTables_scrollHead">
                                <tr>
                                    <th style="height: 200px;"></th>
                                    <th *ngFor="let viewer of viewers" style="position: relative; padding: 10px;">
                                        <span title="{{ viewer.emailLoginName }}" style="transform-origin: 0 50%; transform: rotate(-90deg); white-space: nowrap;
                                    display: block; position: absolute; bottom: 0;">
                                            <i class="fa fa-user-circle fa-fw"></i>&nbsp;{{ viewer.emailLoginName
                                            }}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let userGroup of groupedUserGroups">
                                    <tr>
                                        <td [attr.colspan]="viewers.length+1"><i
                                                class="fa fa-user-circle fa-fw"></i>&nbsp;
                                            <b>{{ userGroup[0].ownerName }}</b>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let group of userGroup">
                                        <td>
                                            <i class="fa fa-layer-group fa-fw" style="margin-left: 10px"></i>&nbsp;
                                            {{ group.groupName }} ({{ group.assetCount }})
                                        </td>
                                        <td *ngFor="let viewer of viewers" style="padding: 5px; padding-top:13px">
                                            <div class="fa_checkbox"
                                                title="{{  group.groupName  }} - {{ viewer.emailLoginName}}">
                                                <input [checked]="true" [id]="'g_' + group.id + '_' + viewer.id"
                                                    type="checkbox" [name]="'g_' + group.id + '_' + viewer.id"
                                                    [(ngModel)]="selectedItems[group.id][viewer.id]" />
                                                <label [for]="'g_' + group.id + '_' + viewer.id">
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <div style="padding:10px" *ngIf="!viewers?.length">
                            {{ 'general.sharedInfo' | translate }}
                        </div>
                    </div>
                    <div class="ibox-footer">
                        <button class="btn btn-primary" (click)="saveAccountViewers()"
                            [disabled]="matrixForm.invalid || loading">
                            <i class="fa fa-fw fa-save"></i> {{'general.assign' | translate }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>