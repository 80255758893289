import {
    Component, AfterViewInit, Input, ElementRef, ViewChild, OnInit, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserContactGroup } from 'app/models/userContactGroup.model';
import { UserContactGroupService } from 'app/services/users/userContactGroups.service';

const noop = () => {
};

@Component({
    selector: 'fh-user-contact-group-input',
    templateUrl: './userContactGroupSelector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: UserContactGroupInputComponent }
    ]
})

export class UserContactGroupInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {

    @Input() placeholder: string;
    @Input() clearable = true;
    @Input() selectedAccountId;
    @Input() autoSelect = true;
    @Input() multiple = true;
    @Input() userContactGroupData = null;
    @Input() readonly = false;
    @Input() hideSystemGroups = true;
    @Input() onlyNormalContacts = true;

    @Output() onChanged = new EventEmitter();

    userContactGroups: UserContactGroup[] = [];

    loadingUserContactGroups = false;

    @ViewChild('input') el: ElementRef;

    private _value: string;
    private _onChange: (_: any) => void = noop;

    get value(): any {
        return this._value;
    };

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this._onChange(v);
            this.cd.markForCheck();
        }
    }

    constructor(private userContactGroupService: UserContactGroupService, private cd: ChangeDetectorRef) {
    }

    assetGroupChanged() {
        this.onChanged.emit(this.value);
        this.cd.markForCheck();
    }

    ngOnChanges() {
        setTimeout(() => {
            // Get accounts again
            this.loadUserContactGroups();
            this.cd.markForCheck();
        }, 0);
    }

    showAll(event, userContactGroup) {
        userContactGroup.showAll = !userContactGroup.showAll;
        event.stopPropagation();
    }

    loadUserContactGroups() {

        this.userContactGroups = [];
        this.cd.markForCheck();

        // Get data from input
        if (this.userContactGroupData != null) {
            this.userContactGroups = this.userContactGroupData;
            this.loadingUserContactGroups = false;
            this.cd.markForCheck();
            return;
        }

        if (this.selectedAccountId) {

            this.loadingUserContactGroups = true;
            this.cd.markForCheck();

            if (this.selectedAccountId && this.selectedAccountId !== 0) {
                this.userContactGroupService.getUserContactGroups(this.selectedAccountId, true).subscribe(result => {
                    this.userContactGroups = result.filter(x => !(x.userContactCount === 0 || x.name.startsWith('PushNotifications')));

                    this.userContactGroups.forEach(userContactGroup => {
                        if (this.onlyNormalContacts) {
                            userContactGroup.userContactItems = userContactGroup.userContactItems.filter(x => x.userContactType === 0);
                        }

                        if (this.hideSystemGroups) {
                            userContactGroup.userContactItems = userContactGroup.userContactItems.filter(x => x.isSystemGroup !== true);
                        }
                    });


                    // Autoselect
                    if (!this.readonly && this.userContactGroups.length === 1 && this.autoSelect) {
                        console.log('Setting default to ' + this.userContactGroups[0].id);
                        this.value = this.multiple ? [this.userContactGroups[0].id] : this.userContactGroups[0].id;
                        this.cd.markForCheck();
                    }

                    this.loadingUserContactGroups = false;
                    this.cd.markForCheck();
                });
            } else {
                this.loadingUserContactGroups = false;
                this.cd.markForCheck();
            }
        }
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        const __this = this;
    }

    writeValue(value: any) {
        this._value = value;
        this.cd.markForCheck();
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
        this.cd.markForCheck();
    }

    registerOnTouched(fn: any) {
        this.cd.markForCheck();
    }

}
