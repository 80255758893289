<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="device?.asset && device?.asset.accountId">
                <a href="/#/AccountDetails/Index/{{device?.asset.accountId}}">{{device?.asset?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview?companyName={{device?.companyName}}">{{'general.devices' | translate }}</a>
            </li>
            <li>
                <a href="/#/DeviceDetails/Index/{{device?.id}}">{{device?.asset?.name ? device?.asset?.name :
                    device?.name}}</a>
            </li>
            <li class="active">{{'general.messages' | translate }}</li>
        </ol>

        <fh-filter-toggler style="margin-left: auto;" [constructorName]="constructorName"
            (filterChanged)="showFilter = $event" (presetsChanged)="showPresets = $event">
            <a style="margin-left:2px" class="btn btn-info margin-fix hidden-sm hidden-xs" title="Help"
                href="https://help.360locate.com/knowledge-base/fleet-management/messages/" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="hidden-sm hidden-xs">{{'general.needHelp' |
                    translate }}</span></a>
        </fh-filter-toggler>
    </div>
    <fh-device-details-navigation [deviceId]="deviceId"></fh-device-details-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div [ngClass]="!showPresets ? 'col-lg-12' : 'col-xl-10 col-lg-9 col-md-8'">
            <fh-error [error]="error" [success]="success"></fh-error>

            <!-- Custom filters -->
            <div class="ibox" [hidden]="!showFilter">
                <div class="ibox-title">
                    <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                    <span class="pull-right hand btn btn-info filter-margin-fix" (click)="clearAllFilters();">
                        <i class="fa fa-fw fa-times"></i>
                        <span class="hidden-sm hidden-xs"> {{'general.clearFilters' | translate }}</span>
                    </span>
                </div>
                <div class="ibox-content slick_toppadding" style="position: relative;">
                    <ngx-loading [show]="loadingLocations"></ngx-loading>
                    <div class="col-lg-4">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon">
                                <i class="fa fa-fw fa-calendar"></i>
                            </span>
                            <input [(ngModel)]="daterangepickerModel" (onHidden)="dateChanged('hidden')" name="from"
                                type="text" autocomplete="off" class="form-control" [maxDate]="maxDate"
                                [bsConfig]="dpConfig" bsDaterangepicker>
                            <span class="input-group-calendar-datatables">
                                <i class="fa fa-calendar-alt"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon" title="Limit the results">
                                <i class="fa fa-fw fa-fire"></i>
                            </span>
                            <input type="text" name="limit" id="limit" style="width:100%" class="form-control"
                                [(ngModel)]="limit" (change)="dateChanged($event)" />
                        </div>
                    </div>
                    <div class="col-lg-4" style="overflow-y: hidden;">
                        <div class="form-group input-group" style="margin-bottom: 5px;">
                            <span class="input-group-addon"
                                title="Source of the locations, messages (smaller timeframe) or trips and stops (longer history but less column)">
                                <i class="fa fa-fw fa-filter"></i>
                            </span>
                            <select name="messageSource" [(ngModel)]="source" class="form-control"
                                (change)="dateChanged($event)" style="width:100%">
                                <option *ngFor="let source of sources" [value]="source.id">
                                    {{ ('enums.messageSource.' + source.id) | translate }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div style="clear:both"></div>
                </div>
                <div class="ibox-content slick_toppadding" style="position: relative;">
                    <ngx-loading [show]="loadingLocations"></ngx-loading>
                    <div id="dynamicFilters"></div>
                    <div style="clear:both"></div>
                </div>
            </div>
            <!-- End default dynamic filters -->

            <div class="ibox">
                <div class="ibox-title">
                    <h5><i class="fa fa-comment-alt-dots"></i> {{ 'general.messages' | translate }}</h5>
                    <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"
                        [constructorName]="constructorName" (filterChanged)="showFilter = $event" (presetLoaded)="onPresetLoaded($event)"
                        (columnsChanged)="drawFilterRow()"></iboxseach>
                </div>
                <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                    <table style="width:100%; min-height:50px;" fh-datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger" class="table  nowrap margin-bottom-0 dataTable dtr-inline">
                    </table>
                </div>
            </div>
        </div>
        <div class="sticky col-xl-2 col-lg-3 col-md-4" [hidden]="!showPresets">

            <div *ngIf="permissions['Platform_IsReseller']" class="ibox">
                <div class="ibox-title">
                    <h5><i class="fa fa-signal"></i> {{ 'general.commands' | translate }}</h5>
                </div>
                <div class="ibox-footer" style="position: relative">
                    <button class="btn btn-primary" (click)="refresh();" title="{{ 'general.refresh' | translate }}"><i
                            class="fa fa-fw fa-recycle"></i>
                        {{'general.refresh' | translate }}</button>
                </div>
            </div>

            <div class="ibox">
                <fh-leaflet-map [allowBookmarks]=false [showFitMap]=false [geofences]="geofences"
                    [geofenceEnabled]="false" [fly]=false>
                    <div header class="ibox-title">
                        <span class="pull-left">
                            <h5>
                                <i class="fa fa-fw fa-map-marked-alt"></i> {{'general.map' | translate }}
                            </h5>
                        </span>
                    </div>
                </fh-leaflet-map>
            </div>
        </div>
    </div>
</div>