<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/CarSharing/Dashboard">{{'general.carsharing' | translate }}</a>
            </li>
            <li class="active">{{ 'general.dashboard' | translate }}</li>
        </ol>
    </div>
    <fh-carsharing-navigation>
        <!-- *ngIf="permissions['Garages_Add']" -->
        <li><a href="/#/CarSharing/Garages/Add/"><i class="fa fa-fw fa-garage"></i>
            <div>{{ 'general.add' | translate }}</div>
        </a></li>
    </fh-carsharing-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-garage"></i> {{'general.garages' | translate }}
                            </h5>
                        </div>
                        <fh-garage-details [garage]="garage" [formMode]="formMode" [loading]="loading">
                        </fh-garage-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()"
                            [allowAdd]="permissions['Modules_HasPrivateFleet']" [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
        </div>
    </div>
</fh-permission-handler>