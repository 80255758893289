<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="geofence?.accountId">
                <a href="/#/AccountDetails/Index/{{geofence?.accountId}}">{{geofence?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Geofences/Overview">{{'general.geofences' | translate }}</a>
            </li>
            <li>
                <a href="/#/GeofenceDetails/Index/{{geofence?.id}}">{{geofence?.name}}</a>
            </li>
            <li class="active">{{'general.geofenceStates' | translate }}</li>
        </ol>

        <fh-filter-toggler style="margin-left: auto;" [constructorName]="constructorName"
            (filterChanged)="showFilter = $event" (presetsChanged)="showPresets = $event"></fh-filter-toggler>
    </div>
    <fh-geofence-details-navigation [geofenceId]="geofenceId"></fh-geofence-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <fh-error [error]="error" [success]="success"></fh-error>

            <div class="col-lg-12">


                <!-- Custom filters -->
                <div class="ibox" [hidden]="!showFilter">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                        <span class="pull-right hand btn btn-info filter-margin-fix" (click)="clearAllFilters();">
                            <i class="fa fa-fw fa-times"></i>
                            <span class="hidden-sm hidden-xs"> {{'general.clearFilters' | translate }}</span>
                        </span>
                    </div>
                    <div class="ibox-content" style="position: relative;">
                        <ngx-loading [show]="geofenceStatesLoaded"></ngx-loading>
                        <div class="col-lg-12">
                            <div class="form-group input-group" style="margin-bottom: 5px;">
                                <span class="input-group-addon">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
                                <input [(ngModel)]="daterangepickerModel" (onHidden)="dateChanged('hidden')" name="from"
                                    type="text" autocomplete="off" class="form-control" [maxDate]="maxDate"
                                    [bsConfig]="dpConfig" bsDaterangepicker>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                    <div class="ibox-content" style="position: relative;">
                        <ngx-loading [show]="geofenceStatesLoaded"></ngx-loading>
                        <div id="dynamicFilters"></div>
                        <div style="clear:both"></div>
                    </div>
                </div>
                <!-- End default dynamic filters -->

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fa fa-hexagon"></i> {{ 'general.geofenceStates' | translate }}</h5>
                        <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"
                            [constructorName]="constructorName" (filterChanged)="showFilter = $event" (presetLoaded)="onPresetLoaded($event)"
                            (columnsChanged)="drawFilterRow()"></iboxseach>
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                        <table style="width:100%; min-height:50px;" fh-datatable [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger" class="table  nowrap margin-bottom-0 dataTable dtr-inline">
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>