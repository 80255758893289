
/**
 * User info result object.
 */
export class User {
    public Id: string;
    public TimeZone: string;
    public TimeZoneIana: string;

    public Culture: string;
    public MapProvider: string;
    public ResellerId: number;

    public WhiteLabel: string;
    public Email: string;

    public DistanceUnit: string;
}

export class AppUser {
    referenceId: any;
    name: any;
    id: any;
    createdDate: any;
    accountId: any;
    companyName: any;
    firstName: any;
    lastName: any;
    fullName: string;
    language: any;
    password = '';
    whitelabel: any;
    timezoneIana = 'Europe/Amsterdam';
    countryIsoCode: any;
    cronTabPattern: string;
    email: string;
    deviceCount: any;
    isBackofficeOwner: any;
    isSetupUser: any;
    created: any;
    isActive: any;
    assetDisplayName = 3;
    volumeUnit = 0;
    distanceUnit = 0;
    userType: number;
    userLevel: number;
    assetGroups: any[];
    userRoleId: any;
    userRoleName: any;
    clientPermissionId: any;
    userName: any;
    linkName: any;
    driverId;
    driverName;
    resellerDescription;
    resellerId;
    viewerId: any;

    createAssetGroup = false;
    createDriver = false;
}

export class UpdatePasswordRequest {
    id: any;
    userName: string;
    oldPassword: string;
    newPassword: string;
}

export class UserRole {
    name: any;
    id: any;
    permissions: any;
    userLevel: any;
}
