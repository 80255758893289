<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Reporting/Overview">{{'general.reporting' | translate }}</a>
            </li>
            <li>
                <a href="/#/ReportDetails/Index/{{activeReport?.id}}">{{ activeReport?.ReportName != "" ?
                    activeReport?.ReportName : ('enums.reportType.' + activeReport?.ReportType) | translate }}</a>
            </li>
            <li class="active">{{'general.dashboard' | translate }}</li>
        </ol>
    </div>
    <fh-reporting-navigation></fh-reporting-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-user-chart"></i> {{ activeReport?.ReportName != "" ?
                            (('enums.reportType.' + activeReport?.ReportType) | translate) + ' - ' +
                            activeReport?.ReportName : ('enums.reportType.' + activeReport?.ReportType) | translate }}
                        </h5>

                        <span class="pull-right" style="padding-right: 20px" tooltip="Show JSON for log">
                            <i class="fas fa-2x fa-bug hand" style="font-size: 1.3em;"
                                [ngStyle]="{'color': showJson ? '#ccc': '#555' }" (click)="showJson = !showJson"></i>

                            <i class="fas fa-2x fa-table-columns hand" style="padding-left: 10px; font-size: 1.3em;"
                                *ngIf="permissions['FleetManagementReporting_HasReportColumnSelection']"
                                [ngStyle]="{'color': showColumnSelector ? '#ccc': '#555' }"
                                (click)="displayColumnSelector()"></i>
                        </span>
                    </div>

                    <div class="ibox-content slick" style="position: relative; overflow: auto">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening_even">
                                        {{ 'general.account' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        {{ activeReport?.companyName }}
                                    </td>
                                    <td class="row_opening_even">
                                        {{ 'general.selection' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible"
                                        title="{{activeReport?.periodStart}} - {{activeReport?.periodEnd}}">
                                        {{ activeReport?.periodStart | amDateFormat: 'lll' }} - {{
                                        activeReport?.periodEnd | amDateFormat: 'lll' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_even">
                                        {{ 'general.generatedBy' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        {{ activeReport?.userName }}
                                    </td>
                                    <td class="row_opening_even">
                                        {{ 'general.generatedOn' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible"
                                        [title]="activeReport?.executedTimestamp">
                                        {{ activeReport?.executedTimestamp | amDateFormat: 'lll' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_even">
                                        {{ 'general.successful' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        <i class="fa fa-fw"
                                            title="{{ 'general.executionDurationInSeconds' | translate }}: {{ actualRound(activeReport?.executionDurationInMilliseconds / 1000,0) }}s, {{ 'general.executionResultBytes' | translate }}: {{ actualRound(activeReport?.executionResultBytes / 1000,0).toLocaleString() }}KB, {{ 'general.executionResultRecordCount' | translate }}: {{ activeReport?.executionResultRecordCount?.toLocaleString() }}"
                                            [ngClass]="activeReport?.isSuccessful ? 'fa-check' : 'fa-times'"
                                            [style.color]="activeReport?.isSuccessful ? 'rgba(0, 142, 67, 0.8)' : 'rgba(151, 28, 36, 0.8)'"></i>
                                    </td>
                                    <td class="row_opening_even">
                                        {{ 'general.recipient' | translate }}
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        {{ activeReport?.recipients != '' ? activeReport?.recipients : '-' }}
                                    </td>
                                </tr>
                                <tr *ngIf="activeReport?.resultMessage != ''">
                                    <td class="row_opening_even">
                                        {{'general.message' | translate }}
                                    </td>
                                    <td colspan="3" class="row_content" style="position:relative; overflow: visible">
                                        {{ activeReport?.resultMessage != '' ? activeReport?.resultMessage : '-' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="activeReportJson && showJson" class="ibox-content slick" style="position:relative;">
                        <pretty-json [obj]="activeReportJson"></pretty-json>
                    </div>
                    <div *ngIf="activeReport" class="ibox-footer" style="position: relative">
                        <button class="btn btn-danger" (click)="showDelete()"
                            [disabled]="!permissions['Platform_IsReseller'] && activeReport?.userId != userId"
                            title="{{ 'general.delete' | translate }}"><i class="fa fa-fw fa-trash-alt"></i>
                            {{'general.delete' | translate }}</button>

                        <button class="btn btn-primary"
                            [routerLink]="['/ReportSubscriptionDetails/Index/', activeReport?.reportSubscriptionId]"
                            [disabled]="!permissions['Platform_IsReseller'] && activeReport?.userId != userId"
                            title="{{ 'general.subscription' | translate }}"><i class="fa fa-fw fa-rocket"></i>
                            {{'general.subscription' | translate }}</button>

                        <span class="pull-right">
                            <div class="ibox-content slick" style="position: relative; border: 0;">
                                <div dropdown style="display: flex;">
                                    <button [disabled]="error != null || !activeReport?.isSuccessful"
                                        style="margin-right: 0;" type="button" (click)="downloadPdf(true, true)"
                                        title="{{'general.downloadPdf' | translate }}" class="btn btn-primary">
                                        <i class="fa fa-fw fa-file-pdf"></i>
                                        <span class="hidden-sm hidden-xs" style="padding-left: 7px">
                                            {{'general.downloadPdf' | translate }}</span>
                                    </button>
                                    <button id="button-basic" dropdownToggle type="button"
                                        [disabled]="error != null || !activeReport?.isSuccessful"
                                        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                        aria-controls="dropdown-basic" style="margin-right: 0">
                                        <span class="sr-only">Toggle Dropright</span>
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-basic" *dropdownMenu
                                        class="dropdown-menu dropdown-menu-fix dropdown-menu-right" role="menu"
                                        aria-labelledby="button-basic">
                                        <li role="menuitem"><a class="dropdown-item"
                                                title="{{'general.downloadPdfCharts' | translate }}"
                                                (click)="downloadPdf(true, false)">
                                                <i class="fa fa-fw fa-file-pdf"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadPdfCharts' | translate }}</span></a>
                                        </li>
                                        <li role="menuitem"><a class="dropdown-item"
                                                title="{{'general.downloadPdfData' | translate }}"
                                                (click)="downloadPdf(false, true)">
                                                <i class="fa fa-fw fa-file-pdf"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadPdfData' | translate }}</span></a>
                                        </li>
                                        <li *ngIf="activeReport?.ReportType == 2" role="menuitem">
                                            <a class="dropdown-item"
                                                title="{{'general.downloadPdfIncludingMaps' | translate }}"
                                                (click)="downloadPdf(true, true, true)">
                                                <i class="fa fa-fw fa-file-pdf"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadPdfIncludingMaps' | translate }}</span></a>
                                        </li>
                                        <li role="menuitem"><a class="dropdown-item"
                                                title="{{'general.downloadData' | translate }}"
                                                (click)="downloadJson()">
                                                <i class="fa fa-fw fa-file-spreadsheet"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadData' | translate }}</span></a>
                                        </li>
                                        <li role="menuitem"><a class="dropdown-item"
                                                title="{{'general.downloadXls' | translate }}" (click)="downloadXls()">
                                                <i class="fa fa-fw fa-file-excel"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadXls' | translate }}</span></a>
                                        </li>
                                        <!-- <li role="menuitem"><a class="dropdown-item"
                                                title="{{'general.downloadCsv' | translate }}" (click)="downloadCsv()">
                                                <i class="fa fa-fw fa-file-csv"></i>
                                                <span style="padding-left: 7px">
                                                    {{'general.downloadCsv' | translate }}</span></a>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </span>
                        <div style="clear: both;"></div>
                    </div>
                </div>

                <fh-error [success]="columnSuccess"></fh-error>

                <div *ngIf="showColumnSelector && activeReport" class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-table-columns"></i> {{'general.reportTemplate' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative;">
                        <form #columnForm="ngForm" style="width: 100%; border-top: 0px">
                            <fh-column-selector [defaultSourceColumns]="defaultSourceColumns" [loading]="false"
                                (saveReportTemplateEmitter)="saveReportTemplate(activeReport.ReportType, $event)"
                                (updateReportTemplateEmitter)="updateReportTemplate($event)"
                                [reportTemplates]="reportTemplates" (templateChange)="format($event)"
                                [selectedReportTemplateId]="selectedReportTemplateId" [(template)]="template">
                            </fh-column-selector>
                        </form>
                    </div>
                </div>

                <fh-report-details [selectedReport]="activeReport?.selectedReportType"
                    [selectedAccountName]="activeReport?.companyName" [reportData]="reportData"
                    [loading]="loadingDetails" [template]="template" [activeReport]="activeReport">
                </fh-report-details>
            </div>
        </div>
    </div>
</fh-permission-handler>

<fh-confirmation-modal #deleteModal (onFinish)="onDelete($event)">
</fh-confirmation-modal>