import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import * as L from 'leaflet';
import { icon, Map, marker, Marker } from 'leaflet';
import { DeviceService } from '../../services/device/device.service';
import { TripService } from '../../services/trip/trip.service';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { getDefaultDpConfig } from 'app/common/gridhelper';

import { LeafletMapComponent } from '../shared/usercontrols/leafletMap.component';
import { FhChartService } from 'app/services/charts/charts.service';

import { colorArray2, roundAsString, getIconPath } from 'app/common/globals';

import '../../../../node_modules/leaflet-polylinedecorator/dist/leaflet.polylineDecorator.js';

import '../../../../vendor/leaflet-movingmarker/leaflet-movingmarker.js';
import '../../../../vendor/leaflet-numbermarker/leaflet-numbermarker.js';

import { AccountService } from 'app/services/account/account.service';
import { DownloadService } from 'app/services/common/download.service';

import * as XLSX from 'xlsx';
type AOA = any[][];

import { forkJoin } from 'rxjs/internal/observable/forkJoin';

import * as Highcharts from 'highcharts';

// Moment
import * as Moment from 'moment';
import * as moment from 'moment-timezone';
import * as mTZ from 'moment-timezone';
import { drawGeofence } from 'app/common/leafletGlobals';
import { StorageType } from 'app/common/enums';
import { StorageHelper } from 'app/common/storagehelper';
import { LocationService } from 'app/services/locations/locations.service';
import { DriverService } from 'app/services/driver/driver.service';
window['moment'] = Moment;
mTZ()
@Component({
    selector: 'fh-device-trips',
    templateUrl: 'trips.template.html',
    providers: [FhChartService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                // css styles at start of transition
                style({ opacity: 0 }),

                // animation and styles at end of transition
                animate('0.5s', style({ opacity: 1 }))
            ]),
        ]
        )
    ],
})
export class DeviceTripsViewComponent implements OnInit, OnDestroy {
    Highcharts: typeof Highcharts = Highcharts;

    @ViewChild(LeafletMapComponent, { static: false }) leafletMapComponent: LeafletMapComponent;

    loading: boolean;
    tripMapLocations;
    selectedTrip: any;
    zone: any;
    menuState: string;
    map: any;
    marker: Marker;
    options;
    timeoutHandler;
    trips: any[];
    sub;
    device: Device;
    layersControl;
    success;

    tripPolyLineDict = {};

    colorArray = colorArray2;
    itemsPerPage = 10;
    colorIdx = 0;

    showMapOnSide = true;

    filter;
    filter2;

    hasAsset = false;
    loadingDetails = false;
    mapBusy = false;
    loadingTrips = false;
    deviceId;
    // Daterange
    public dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();
    daterangepickerModel: Date[];
    maxDate = new Date();
    mapHeight = 250;

    // Pagination
    totalItems = 0;
    currentPage = 1;
    currentPageDebug = 1;
    smallnumPages = 0;
    error: any;
    tripLocations = [];
    showLocations = false;
    speedChart: any;

    currentDate;

    timezoneIana;

    allTripLocations = [];
    geofences = [];
    theGeofence;

    fileName = 'TripLocations.xlsx';

    storageType = StorageType.LocalStorage;

    permissionName = 'FleetManagement_Trips';

    loadingGeofences = false;
    theMarker: any;
    movingMarkerIcon: L.Icon<L.IconOptions>;

    permissions: {};
    startMarker: L.Marker<any>;
    endMarker: L.Marker<any>;
    myMovingMarker: any;
    stopMarker: L.Marker<any>;
    scannedDriver: any;

    showChart = false;
    showAbuseIcons = false;

    constructorName = 'DeviceTripsViewComponent';

    speedCalculator = 2;
    rangeValue: number;

    constructor(private downloadService: DownloadService, private driverService: DriverService, private locationService: LocationService, private accountService: AccountService, private chartService: FhChartService, private tripService: TripService, private cd: ChangeDetectorRef, private http: HttpClient, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, private storageHelper: StorageHelper) {
        this.permissions = this.authenticationService.permissions;

        this.device = null;
        this.trips = [];
        this.tripLocations = [];

        this.timezoneIana = authenticationService.getTimeZoneIana();

        this.daterangepickerModel = [
            Moment().subtract(1, 'weeks').startOf('day').toDate(),
            Moment().startOf('day').toDate()
        ];

        this.dpConfig = getDefaultDpConfig(Moment, authenticationService);

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showMapOnSide').subscribe((result) => {
            this.showMapOnSide = JSON.parse(result) === true;

            if (this.showMapOnSide) { this.mapHeight = 330; }
            this.cd.markForCheck();
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showChart').subscribe((result) => {
            this.showChart = JSON.parse(result) !== false;
            this.cd.markForCheck();
        });

        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showAbuseIcons').subscribe((result) => {
            this.showAbuseIcons = JSON.parse(result) !== false;
            this.cd.markForCheck();
        });
    }

    ngOnInit() {
        this.device = new Device;
        this.device.id = ''
        this.loading = true;
        this.cd.markForCheck();

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.deviceId = id;
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;

                const iconPath = getIconPath(this.device.asset?.icon)[1];

                this.movingMarkerIcon = L.icon({
                    iconUrl: iconPath,
                    // className: 'markerPlayTrip',
                    iconAnchor: [16, 16],
                });

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }

                if (this.device && this.device.id) {
                    this.getGeofences();
                    this.getTripsForPeriod(this.device.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'));
                }

                this.loading = false;
                this.cd.markForCheck();
            });
        }, error => {
            this.error = error;
            this.error.statusText = 'Error fetching device';

            setTimeout(() => {
                this.router.navigate(['/Devices/Overview']);
            }, 3000);
        });
    }

    flipShowChart() {
        this.showChart = !this.showChart;
        this.storageHelper.saveStoreState(this.storageType, 'settings_', 'showChart', this.showChart.toString());
    }

    flipShowAbuseIcons() {
        this.showAbuseIcons = !this.showAbuseIcons;
        this.storageHelper.saveStoreState(this.storageType, 'settings_', 'showAbuseIcons', this.showAbuseIcons.toString());
    }

    isNewDate(trip) {
        const checkDate = Moment(trip.beginDateTime).date();
        if (checkDate === this.currentDate) {
            return false;
        } else {
            this.currentDate = checkDate;
            return true;
        }
    }

    actualRound(value, decimals) {
        return roundAsString(value, decimals);
    }

    resetDate() {
        this.currentDate = null;
        return false;
    }

    hasPrivateBusinessButtons() {
        if (this.selectedTrip != null && this.leafletMapComponent?.tripLayer.getLayers().length === 1) {
            if (this.selectedTrip.driverId === this.authenticationService.getDriverId() || this.permissions['Platform_IsReseller']) {
                return true;
            }
        }

        return false;
    }

    setPrivate(isPrivate) {
        this.loadingTrips = true;
        this.tripService.updateTripById(this.selectedTrip.id, isPrivate, 0, '').subscribe(result => {
            this.loadingTrips = false;
            this.selectedTrip.isPrivate = isPrivate;
            this.cd.markForCheck();
        });
    }

    getTripsForPeriod(deviceId, start, end) {
        this.currentPage = 1;
        this.loadingTrips = true;
        this.error = undefined;

        this.tripService.getTripsForDevice(deviceId, start, end).subscribe(trips => {
            trips.sort((a, b) => new Date(b.beginDateTime).getTime() - new Date(a.beginDateTime).getTime());

            this.trips = trips;

            const realTrips = trips.filter(x => x.tripMethod !== 5);
            if (realTrips.length > 0) {
                this.clearSelectionAndGetTripData(null, realTrips[0])
            }

            this.totalItems = this.trips.length;
            this.smallnumPages = Math.ceil(this.totalItems / 10);
            this.loadingTrips = false;

            this.cd.markForCheck();
        }, error => {
            this.loadingTrips = false;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    getGeofences() {
        if (this.geofences.length === 0 && this.device.accountId != null && this.device.accountId > 0) {
            this.loadingGeofences = true;
            this.accountService.getGeofencesByAccount(this.device.accountId).subscribe(geofences => {
                this.geofences = geofences;
                this.loadingGeofences = false;
                this.cd.markForCheck();
            });
        }
    }

    ngOnDestroy() {
        if (this.timeoutHandler) {
            console.log('Distroy timeouthandler');
            clearInterval(this.timeoutHandler);
        }
    }

    dateChanged(event) {
        this.currentDate = null;
        this.getTripsForPeriod(this.device.id, moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'));
    }

    // Drawing multiple trip
    playTrips() {

        let flattenedLocations = [];

        Object.values(this.allTripLocations).forEach(trip => {
            flattenedLocations = flattenedLocations.concat(Object.values(trip));
        });

        flattenedLocations.sort((a, b) => new Date(a.rtcBasedTimestamp).getTime() - new Date(b.rtcBasedTimestamp).getTime());

        this.prepareMovingMarker(flattenedLocations);

        setTimeout(() => {
            this.myMovingMarker?.start(true);
        }, 1000);
    }

    pauseTrip() {
        this.myMovingMarker?.pause();
    }

    resumeTrip() {
        this.myMovingMarker?.resume();
    }

    stopTrip() {
        this.myMovingMarker?.stop();
    }

    outputUpdate() {
        this.myMovingMarker?.setcurpos(this.rangeValue / 100);
    }

    speedChanged() {
        this.stopTrip();
    }

    prepareMovingMarker(flattenedLocations) {

        this.clearLocation();

        const latlngs = [];
        const locationsTimeDelta = [];

        // Remove locations
        this.leafletMapComponent.locationLayer.clearLayers()

        let timestamp = flattenedLocations.length && Moment.utc(flattenedLocations[0].rtcBasedTimestamp);

        flattenedLocations.forEach(location => {

            const speed = Moment.utc(location.rtcBasedTimestamp).diff(timestamp) / 1000;
            timestamp = Moment.utc(location.rtcBasedTimestamp);

            if (location.latitude !== 0 && location.longitude !== 0) {
                latlngs.push(new L.LatLng(location.latitude, location.longitude));
                locationsTimeDelta.push(speed * this.speedCalculator);
            }
        });

        if (this.myMovingMarker) {
            this.myMovingMarker.stop();
        }

        this.myMovingMarker = L['movingMarker'](latlngs, locationsTimeDelta, { icon: this.movingMarkerIcon, animate: true, autostart: false }, this.leafletMapComponent.map)
        this.myMovingMarker.addTo(this.leafletMapComponent.map);

        const stopMarkerIcon = L.icon({
            iconUrl: 'assets/images/icons/end.png',
            className: 'markerEnd'
        });

        this.stopMarker = L.marker(latlngs[latlngs.length - 1], { icon: stopMarkerIcon });
        this.stopMarker.addTo(this.leafletMapComponent.map);

        const startDate = Moment.utc(flattenedLocations[0].rtcBasedTimestamp)['tz'](this.timezoneIana);
        const endDate = Moment.utc(flattenedLocations[flattenedLocations.length - 1].rtcBasedTimestamp)['tz'](this.timezoneIana);
        const customPopup = `<div style="width:300px; overflow: auto;" class="leaflet-mappopup">
        <div class="header">` + 'End trip' + `</div>
        <div class="content">` + 'Start' + `:</div><div class="content">` + startDate.format('lll') + `</div>
        <div class="content">` + 'End' + `:</div><div class="content">` + endDate.format('lll') + `</div>
        </div>`;

        this.stopMarker.bindPopup(L.popup().setContent(customPopup), {
            closeButton: false
        });

        this.myMovingMarker.on('end', () => {
            this.stopMarker.openPopup();

            setTimeout(() => {
                this.leafletMapComponent.map.closePopup();
                this.leafletMapComponent.map.removeLayer(this.myMovingMarker);
                this.leafletMapComponent.map.removeLayer(this.stopMarker);
                this.myMovingMarker = null;
                this.cd.markForCheck();
            }, 1000);
        });

        const bounds = this.leafletMapComponent.tripLayer.getBounds();

        if (bounds.isValid()) {
            this.leafletMapComponent.map.flyToBounds(bounds, { padding: [15, 15], animate: true, duration: 0.5 });
        }
    }

    export(): void {
        this.loading = true;
        this.cd.markForCheck();
        setTimeout(() => {

            /* generate workbook and add the worksheet */
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            let i = 0;

            Object.values(this.allTripLocations).forEach(trip => {
                const locations = [];

                Object.values(trip).forEach(location => {
                    locations.push(location);
                });

                i++;

                const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([locations]);
                XLSX.utils.book_append_sheet(wb, ws, 'Trip : ' + i);
            });

            /* save to file */
            XLSX.writeFile(wb, this.fileName);

            this.loading = false;
            this.cd.markForCheck();
        }, 1);
    }

    showLocationDetails() {
        this.mapBusy = true;

        const tripLocations = [];
        Object.values(this.allTripLocations).forEach(trip => {
            Object.values(trip).forEach(location => {
                tripLocations.push(location);
            });
        });

        this.leafletMapComponent.locationLayer.clearLayers();

        if (tripLocations && tripLocations.filter(x => x.hasGpsFix === true).length > 1) {

            const startLocation = tripLocations[0];

            for (let index = 1; index < tripLocations.length; index++) {
                const location = tripLocations[index];

                if (location.latitude !== 0 && location.latitude !== 0) {

                    let markerIcon;

                    if (location.hasGpsFix) {
                        markerIcon = L['StatusMarker'].icon({
                            iconSize: [20, 20],
                            iconAnchor: [10, 10],
                            shadowSize: [0, 0],
                            shadowAnchor: [0, 0],
                            icon: 'fa-arrow-circle-up',
                            prefix: 'fa',
                            rotate: location.heading ?? 0,
                            iconColor: 'green',
                            className: 'm360_12',
                        });
                    } else {
                        markerIcon = L['StatusMarker'].icon({
                            iconSize: [20, 20],
                            iconAnchor: [10, 10],
                            shadowSize: [0, 0],
                            shadowAnchor: [0, 0],
                            icon: 'fa-rss',
                            prefix: 'fa',
                            iconColor: 'green',
                            className: 'm360_12',
                        });
                    }

                    const customPopup = `<div style="width:300px; overflow: auto;" class="leaflet-mappopup">
                            <div class="header">` + 'Location' + `</div>
                            <div class="content">` + 'Time' + `:</div><div class="content">` + Moment.utc(location.timestamp)['tz'](this.timezoneIana).format('YYYY-MM-DD HH:mm:ss') + `</div>
                            <div class="content">` + 'Heading' + `:</div><div class="content">` + location.heading + ` °</div>
                            <div class="content">` + 'Speed' + `:</div><div class="content">` + location.speed + ` km/h</div>
                            ${(location.fuelLevel ? `<div class="content">` + 'Fuel' + `:</div><div class="content">` + location.fuelLevel + `%</div>` : '')}
                            ${(location.temperature1 ? `<div class="content">` + 'Temperature' + `:</div><div class="content">` + location.temperature1 + ` °C</div>` : '')}
                            ${(location.temperature2 ? `<div class="content">` + 'Temperature' + `:</div><div class="content">` + location.temperature2 + ` °C</div>` : '')}
                            <div class="content">` + 'Location' + `:</div><div class="content">` + location.latitude.toFixed(4) + ` / ` + location.longitude.toFixed(4) + `</div>
                        </div>`;

                    const locationMarker = L.marker(new L.LatLng(location.latitude, location.longitude), { icon: markerIcon });
                    locationMarker.bindPopup(L.popup().setContent(customPopup), {
                        closeButton: false
                    });
                    locationMarker.addTo(this.leafletMapComponent.locationLayer);
                    this.leafletMapComponent.oms.addMarker(locationMarker);
                }
            }
        }

        this.mapBusy = false;
        this.cd.markForCheck();
    }

    clearSelectionAndGetTripData(event, selection) {
        this.tripPolyLineDict = {};
        this.leafletMapComponent.locationLayer.clearLayers();
        this.leafletMapComponent.tripLayer.clearLayers();

        this.allTripLocations = [];

        this.getTripData(event, selection);
    }

    displayLocation(location, geofence = null, episode = null) {

        // beginLongitude
        this.clearLocation();

        if (location != null && geofence == null) {

            let theEpisodeIcon = null;

            if (episode) {
                theEpisodeIcon = L['StatusMarker'].icon({
                    // iconUrl: iconPath,
                    icon: episode.icon,
                    markerColor: episode.markerColor,
                    rotate: 0,
                    shape: 'circle',
                    prefix: 'fas'
                });
            }

            this.theMarker = marker(location, { icon: episode ? theEpisodeIcon : this.movingMarkerIcon }).addTo(this.leafletMapComponent.map);
            this.leafletMapComponent.map.setView(location, 15);
        }

        if (location != null && geofence != null) {

            // Draw geofence
            if (this.theGeofence) {
                this.leafletMapComponent.map.removeLayer(this.theGeofence);
            }

            drawGeofence(L, geofence, this.leafletMapComponent.map, geofence.color);
            this.theGeofence = geofence.layerRef;

            setTimeout(() => {
                // Get accounts again
                this.leafletMapComponent.map.removeLayer(this.theGeofence);
            }, 4000);

            const bounds = geofence.layerRef && geofence.layerRef.getBounds();

            if (bounds) {
                this.leafletMapComponent.map.flyToBounds(bounds, { padding: [15, 15] });

                this.theMarker = marker(location, { icon: this.movingMarkerIcon }).addTo(this.leafletMapComponent.map);
            }
        }
    }

    clearLocation() {
        if (this.theMarker) {
            this.leafletMapComponent.map.removeLayer(this.theMarker);
        }

        if (this.myMovingMarker) {
            this.leafletMapComponent.map.removeLayer(this.myMovingMarker);
        }

        if (this.stopMarker) {
            this.leafletMapComponent.map.removeLayer(this.stopMarker);
        }
    }

    getTripData(event, selection) {
        if (!selection) {
            return;
        }

        // Check for calibration trip or private
        if (selection?.tripMethod === 5 || selection?.isHidden) {
            return;
        }

        // Remove locations
        this.leafletMapComponent.locationLayer.clearLayers();

        this.clearLocation();

        if (this.selectedTrip) {
            this.selectedTrip.concatenatedEvents = [];
        }

        this.loadingDetails = true;
        this.cd.markForCheck();

        event?.preventDefault();
        event?.stopPropagation();

        if (this.myMovingMarker) {
            this.myMovingMarker.stop();
        }

        if (this.tripPolyLineDict[selection.id]) {
            console.log('Remove trip');

            this.leafletMapComponent.tripLayer.removeLayer(this.tripPolyLineDict[selection.id]);
            this.tripPolyLineDict[selection.id] = undefined;
            this.allTripLocations[selection.id] = [];

            if (!(this.leafletMapComponent.tripLayer.getLayers().length > 0)) {
                this.selectedTrip = undefined;
                this.tripLocations = [];
            }

            this.drawChart(this.allTripLocations);

            this.loadingDetails = false;
            this.cd.markForCheck();
            return;
        }

        this.selectedTrip = selection;

        // Get trip by id
        this.tripService.getTripDetails(selection.id).subscribe(result => {

            this.error = null;

            this.tripLocations = result.messages.sort((a, b) => b.rtcBasedTimestamp - a.rtcBasedTimestamp);

            this.allTripLocations[selection.id] = result.messages;

            // If active trip
            this.scannedDriver = null;
            if (result.tripType == 3) {
                this.locationService.getDeviceStates([this.deviceId], null, null, 0).subscribe(deviceState => {
                    if (deviceState.deviceStates[0]?.tagScanStatus?.tag) {
                        this.driverService.getDriverByTag(deviceState.deviceStates[0]?.tagScanStatus?.tag).subscribe(res => {
                            this.scannedDriver = res;
                            this.cd.markForCheck();
                        });
                    }
                });
            }

            this.drawChart(this.allTripLocations);

            this.loadingDetails = false;
            this.cd.markForCheck();

            this.displayTrip(result, selection, selection.tripType === 3)

            result.geofenceStates.forEach(geofenceState => {
                geofenceState.geofence = this.geofences.find(x => x.id === geofenceState.geofenceId);

                // Link to locations
                const matchingLocation = result.messages.filter(x => x.rtcBasedTimestamp >= geofenceState.rtcBasedTimestamp);
                if (matchingLocation.length > 0) {
                    geofenceState.location = L.latLng(matchingLocation[0].latitude, matchingLocation[0].longitude);
                }
            });

            this.selectedTrip.concatenatedEvents = [...result.episodes, ...result.geofenceStates].sort((a, b) => b.date - a.date);

        }, error => {
            this.loadingDetails = false;
            this.error = error;
            this.cd.markForCheck();
        });

    }

    displayTrip(trip, selection, isOngoing = false) {

        const tripLocations = trip.messages

        const pointList = [];

        const color = this.colorArray[selection.ident % this.colorArray.length];

        const gpsFixLocations = tripLocations.filter(x => x.hasGpsFix === true);

        if (gpsFixLocations.length > 0) {

            const startIcon = new L['NumberMarker'].Icon({
                backgroundColor: color,
                className: 'm360',
                color: '#fff',
                number: selection.ident,
            });

            this.startMarker = L.marker(new L.LatLng(gpsFixLocations[0].latitude, gpsFixLocations[0].longitude), { icon: startIcon });
            this.leafletMapComponent.oms.addMarker(this.startMarker);

            const finishIcon = L.icon({
                iconUrl: 'assets/images/icons/end.png',
                className: 'markerEnd',
            });

            const iconPath = getIconPath(this.device.asset?.icon)[1];

            const theAssetIcon = L.icon({
                iconUrl: iconPath,
                // className: 'markerPlayTrip',
                iconAnchor: [16, 16],
            });

            this.endMarker = L.marker(new L.LatLng(gpsFixLocations[gpsFixLocations.length - 1].latitude, gpsFixLocations[gpsFixLocations.length - 1].longitude), { icon: isOngoing ? theAssetIcon : finishIcon });
            this.leafletMapComponent.oms.addMarker(this.endMarker);

            gpsFixLocations.forEach(location => {
                if (location.latitude !== 0) {
                    pointList.push(new L.LatLng(location.latitude, location.longitude));
                }
            });

            const tripPolyLine = new L.Polyline(pointList, {
                color,
                weight: 2,
                opacity: 1,
                smoothFactor: 1,
                dashArray: '10, 5'
            });

            const tripPolyLine2 = new L.Polyline(pointList, {
                color: '#fff',
                weight: 6,
                opacity: 0.8,
            });

            const tripPolyLine3 = new L.Polyline(pointList, {
                color: '#000',
                weight: 9,
                opacity: 0.3,
            });

            const decoratorLine = L['polylineDecorator'](tripPolyLine, {
                patterns: [
                    { offset: 24, repeat: 100, symbol: L['Symbol']['arrowHead']({ pixelSize: 15, pathOptions: { fillOpacity: 0.9, color, weight: 0, stroke: true } }) }
                ]
            });

            const tripFeatureGroup = L.featureGroup([this.startMarker, this.endMarker, tripPolyLine3, tripPolyLine2, tripPolyLine, decoratorLine]);
            tripFeatureGroup.addTo(this.leafletMapComponent.tripLayer);

            const bounds = tripFeatureGroup.getBounds();

            this.tripPolyLineDict[selection.id] = tripFeatureGroup;

            this.leafletMapComponent.eventsLayer.clearLayers();
            if (this.showAbuseIcons) {
                console.log('display episodes');
                this.addEpisodesToMap(trip, this.leafletMapComponent.eventsLayer);
            }

            if (bounds.isValid()) {
                this.leafletMapComponent.map.flyToBounds(bounds, { padding: [30, 30], animate: true, duration: 0.5 });
            }
        } else {
            this.error = 'No data to display';
        }
    }

    public addEpisodesToMap(trip, layer) {
        trip.episodes.forEach(episode => {
            this.addEpisodeToMap(episode, layer);
        });
    }

    public addEpisodeToMap(episode, layer) {
        const theEpisodeIcon = L['StatusMarker'].icon({
            // iconUrl: iconPath,
            icon: episode.icon,
            markerColor: episode.markerColor,
            rotate: 0,
            shape: 'square',
            prefix: 'fas'
        });

        const episodeMarker = L.marker([episode.beginLatitude, episode.beginLongitude], { icon: theEpisodeIcon }).addTo(layer);
        this.leafletMapComponent.oms.addMarker(episodeMarker);

        const direction = 'bottom';
        const offset = L.point(0, 0);

        const label = episode.description;

        episodeMarker.bindTooltip(label, { permanent: false, direction: direction, opacity: 0.9, offset: offset });
    }

    onMapReady(map) {
        setTimeout(() => {
            this.leafletMapComponent.invalidateSize();
        }, 10);
    }

    onMapResized() {
        setTimeout(() => {
            this.leafletMapComponent.centerMap();
        }, 200);
    }

    drawLocation(location) {
        setTimeout(() => {
            if (location) {

                if (this.marker) {
                    this.map.removeLayer(this.marker);
                }

                this.marker = marker([location.latitude, location.longitude], {
                    icon: icon({
                        iconSize: [25, 41],
                        iconAnchor: [13, 41],
                        iconUrl: 'assets/marker-icon.png',
                        shadowUrl: 'assets/marker-shadow.png'
                    })
                });

                this.map.addLayer(this.marker);
                this.map.setView([location.latitude, location.longitude], 6, { animate: true, duration: 0.5 });
            }
        }, 100);
    }

    drawChart(trips) {

        const iconPath = getIconPath(this.device.asset?.icon)[1];

        const theIcon = L.icon({
            iconUrl: iconPath,
            // className: 'markerPlayTrip',
            iconAnchor: [16, 16],
        });

        console.log('dwaring chart');
        let flattenedLocations = [];
        const plotLines = [];
        const plotBands = []

        Object.values(trips).forEach(trip => {
            const locations = Object.values(trip)

            // plotLines.push({
            //     color: '#ddd',
            //     dashStyle: 'dash',
            //     opacity: 0.2,
            //     width: 2,
            //     value: [Moment.utc(locations[0].timestamp)['tz'](this.timezoneIana).unix() * 1000],
            // });

            if (locations.length > 3) {
                plotBands.push({
                    color: '#ddd',
                    from: [Moment.utc(locations[0].timestamp)['tz'](this.timezoneIana).unix() * 1000],
                    to: [Moment.utc(locations[locations.length - 1].timestamp)['tz'](this.timezoneIana).unix() * 1000],
                    opacity: '0.02'
                });

                // Copy last location and set it to offset 0 to reset trip
                const lastLocation = Object.assign({}, locations[locations.length - 1]);
                lastLocation.odometerOffset = 0;
                lastLocation.timestamp = lastLocation.timestamp;
                lastLocation.rtcBasedTimestamp = lastLocation.rtcBasedTimestamp;
                locations.push(lastLocation);

                flattenedLocations = flattenedLocations.concat(locations);
            }
        });

        console.log('done pushing location');

        // The speed gauge
        // data, km, fuel percentage, deviation, symbol
        const theChartDataDistance = [];
        const theChartDataIgnition = [];
        const theChartDataSpeed = [];
        const theChartDataExternalPower = [];
        const theChartDataGpsFix = [];
        const theChartFuelLevel = [];
        const theChartDataWeight = [];
        const theChartDataHumidity = [];
        const theChartDataTemperature = [];
        const theChartDataTemperature2 = [];
        const theChartDataExternalPowerVoltage = [];

        const theChartDataAnalog1 = [];
        const theChartDataAnalog2 = [];

        const theChartFuelConsumed = [];

        const startOdometer = flattenedLocations.length && flattenedLocations[0].odometerOffset;
        const startFuelConsumed = (flattenedLocations.length && flattenedLocations[0].fuelConsumed) ?? 0;
        const that = this;

        const cachedDistanceOffset = 0;
        let lastlocation = null;

        $.each(flattenedLocations.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1)), function (index, value) {

            const distance = (value.odometerOffset - cachedDistanceOffset);

            const dateTime = Moment.utc(value.timestamp)['tz'](that.timezoneIana).unix() * 1000;
            const coordinate = [value.latitude, value.longitude];

            if (value.latitude !== 0) {
                const location = { x: dateTime, y: Math.max(0, (distance / 1000)), suffix: 'km', latlon: coordinate };
                lastlocation = location;
                theChartDataDistance.push(location);
            }

            theChartDataIgnition.push({ x: dateTime, y: value.ignition ? 1 : 0, latlon: coordinate });
            theChartDataExternalPower.push({ x: dateTime, y: value.externalPower ? 1 : 0, latlon: coordinate });
            theChartDataGpsFix.push({ x: dateTime, y: value.hasGpsFix ? 1 : 0, latlon: coordinate });

            if (value.speed !== undefined) {
                theChartDataSpeed.push({ x: dateTime, y: value.speed, suffix: 'km/h', latlon: coordinate });
            }

            if (value.externalBatteryLevelInVoltage !== undefined) {
                theChartDataExternalPowerVoltage.push({ x: dateTime, y: value.externalBatteryLevelInVoltage, suffix: ' V', latlon: coordinate });
            }

            if (value.fuelLevel !== undefined && (value.fuelLevel !== 0)) {
                theChartFuelLevel.push({ x: dateTime, y: value.fuelLevel, suffix: '%', latlon: coordinate });
            }

            if (value.axleWeightInKg1 !== undefined && (value.axleWeightInKg1 !== 0)) {
                theChartDataWeight.push({ x: dateTime, y: value.axleWeightInKg1, suffix: 'kg', latlon: coordinate });
            }

            if (value.humidityInPercent1 !== undefined && (value.humidityInPercent1 !== 0)) {
                theChartDataHumidity.push({ x: dateTime, y: value.humidityInPercent1, suffix: '%', latlon: coordinate });
            }

            if (value.temperature !== undefined && (value.temperature !== 0)) {
                theChartDataTemperature.push({ x: dateTime, y: value.temperature, suffix: '°C', latlon: coordinate });
            }

            if (value.temperature2 !== undefined && (value.temperature2 !== 0)) {
                theChartDataTemperature2.push({ x: dateTime, y: value.temperature2, suffix: '°C', latlon: coordinate });
            }

            if (value.analogInput1 !== undefined && (value.analogInput1 !== 0)) {
                theChartDataAnalog1.push({ x: dateTime, y: value.analogInput1, suffix: ' V', latlon: coordinate });
            }

            if (value.analogInput2 !== undefined && (value.analogInput2 !== 0)) {
                theChartDataAnalog2.push({ x: dateTime, y: value.analogInput2, suffix: ' V', latlon: coordinate });
            }

            if (value.fuelConsumed !== undefined && (value.fuelConsumed !== 0)) {
                theChartFuelConsumed.push({ x: dateTime, y: (value.fuelConsumed - startFuelConsumed), suffix: 'L', latlon: coordinate });
            }
        });

        let theData = [];

        theData = [{
            name: 'Distance',
            type: 'area',
            //  threshold: null,
            // step: 'right',
            dashStyle: 'dash',
            fillOpacity: 0.1,
            opacity: 0.3,
            color: '#ccc',
            zIndex: 5,
            yAxis: 1,
            data: theChartDataDistance
        }, {
            name: 'Speed',
            type: 'spline',
            color: '#5AB867',
            yAxis: 0,
            marker: {
                enabled: false,
                lineWidth: 2,
                symbol: 'square'
            },
            zIndex: 5,
            tooltip: {
                valueSuffix: ' km/h'
            },
            data: theChartDataSpeed
        }];

        if (theChartDataIgnition.some(x => x.y)) {
            theData.push(
                {
                    name: 'Ignition',
                    type: 'line',
                    color: '#00E0C6',
                    visible: false,
                    step: 'left',
                    marker: {
                        enabled: false,
                        lineWidth: 2,
                        symbol: 'square'
                    },
                    yAxis: 3,
                    zIndex: 3,
                    data: theChartDataIgnition
                });
        };

        if (theChartDataExternalPower.some(x => x.y)) {
            theData.push({
                name: 'ExternalPower',
                type: 'line',
                color: '#00E0C6',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 4,
                zIndex: 3,
                data: theChartDataExternalPower
            });
        };

        if (theChartDataExternalPowerVoltage.some(x => x.y)) {
            theData.push({
                name: 'Voltage',
                type: 'line',
                color: '#00E0C6',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 6,
                zIndex: 3,
                data: theChartDataExternalPowerVoltage
            });
        };

        if (theChartDataAnalog1.some(x => x.y)) {
            theData.push({
                name: 'Analog1',
                type: 'line',
                dashStyle: 'ShortDashDot',
                color: '#00E0C6',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 6,
                zIndex: 3,
                data: theChartDataAnalog1
            });
        };

        if (theChartDataAnalog2.some(x => x.y)) {
            theData.push({
                name: 'Analog2',
                type: 'line',
                dashStyle: 'ShortDashDot',
                color: '#00E0C6',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 6,
                zIndex: 3,
                data: theChartDataAnalog2
            });
        };

        if (theChartDataGpsFix.some(x => x.y)) {
            theData.push({
                name: 'HasGpsFix',
                type: 'line',
                color: '#1A4467',
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 5,
                zIndex: 3,
                data: theChartDataGpsFix
            });
        };

        if (theChartFuelLevel.some(x => x.y)) {
            theData.push({
                name: 'FuelLevel',
                type: 'spline',
                color: '#7589FF',
                dashStyle: 'ShortDot',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 2,
                zIndex: 3,
                data: theChartFuelLevel
            });
        };

        if (theChartFuelConsumed.some(x => x.y)) {
            theData.push({
                name: 'Fuel Consumed',
                type: 'spline',
                color: '#A982FF',
                dashStyle: 'LongDash',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 12,
                zIndex: 3,
                data: theChartFuelConsumed
            });
        };

        if (theChartDataTemperature.some(x => x.y)) {
            theData.push({
                name: 'Temperature',
                type: 'areaspline',
                color: '#0000ff',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 7,
                zIndex: 3,
                data: theChartDataTemperature
            });
        };

        if (theChartDataTemperature2.some(x => x.y)) {
            theData.push({
                name: 'Temperature',
                type: 'areaspline',
                color: '#0000ff',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 7,
                zIndex: 3,
                data: theChartDataTemperature2
            });
        };

        if (theChartDataWeight.some(x => x.y)) {
            theData.push({
                name: 'Weight',
                type: 'areaspline',
                color: '#FF0015',
                dashStyle: 'Dash',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 9,
                zIndex: 3,
                data: theChartDataWeight
            });
        };

        if (theChartDataHumidity.some(x => x.y)) {
            theData.push({
                name: 'Humidity',
                type: 'areaspline',
                color: '#FF0090',
                fillOpacity: 0.2,
                visible: false,
                step: 'left',
                marker: {
                    enabled: false,
                    lineWidth: 2,
                    symbol: 'square'
                },
                yAxis: 8,
                zIndex: 3,
                data: theChartDataHumidity
            });
        };

        // theData = this.chartService.simplifyPathDouglasPecker(theData, 1);

        this.speedChart = this.chartService.generateMapChart(theData, plotLines, plotBands, this.leafletMapComponent.map, theIcon, null, true);
        this.cd.markForCheck();
    }

    showReport() {

    }
}
