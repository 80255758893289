<div class="col-lg-12">
    <div class='horizontalMenuWrapper'>
        <ul>
            <li [ngClass]="{'active': activeRoute('Users/Overview')}"><a href="/#/Users/Overview/"><i
                        class="fas fa-fw fa-users"></i>
                    <div>{{ 'general.overview' |translate }}</div>
                </a></li>
            <li *ngIf="permissions['UserContacts_View']"
                [ngClass]="{active: activeRoute('Users/UserContacts') || activeRoute('Users/UserContactDetails')}">
                <a [routerLink]="['/Users/UserContacts/Overview']">
                    <i class="fas fa-fw fa-address-card"></i>
                    <div>{{ 'menu.userContacts' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['UserContactGroups_View']"
                [ngClass]="{active: activeRoute('Users/UserContactGroup')}">
                <a [routerLink]="['/Users/UserContactGroups/Overview']">
                    <i class="fas fa-fw fa-address-book"></i>
                    <div>{{ 'menu.userContactGroups' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['UserRoles_View']" [ngClass]="{active: activeRoute('Users/UserRole')}">
                <a [routerLink]="['/Users/UserRoles/Overview/']">
                    <i class="fas fa-fw fa-restroom"></i>
                    <div>{{ 'menu.userRoles' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class='horizontalMenuWrapper' style="float:right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>