import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'fh-project-navigation',
    templateUrl: 'projectNavigation.template.html'
})

export class ProjectNavigationComponent implements OnInit {
    sub: any;

    constructor(private route: ActivatedRoute, private router: Router) {
    }

    ngOnInit() {
    }

    activeRoute(routename: string): boolean {
        const active = this.router.url.indexOf(routename) !== -1;
        return active;
    }
}
