import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { Maintenance, MaintenanceOccurrence, MaintenanceUpload } from 'app/models/maintenance.model';
import { throwError } from 'rxjs/internal/observable/throwError';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
window['moment'] = Moment;
mTZ()
@Injectable()
export class MaintenanceService {
    url = '';
    base_url = '';
    inventoryUrl = '';
    Maintenances: Maintenance[] = [];
    timezoneIana: string;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService) {
        this.url = this.authenticationService.getWebserviceURL('maintenance');
        this.base_url = this.authenticationService.getWebserviceURL('');
        this.timezoneIana = this.authenticationService.getTimeZoneIana();
    }

    getPagingUrl(start, end) {
        return this.url + 'Paging?startRange=' + start.unix() + '&endRange=' + end.unix();
    }

    getPagingDueUrl(date) {
        return this.url + 'PagingDue?date=' + date;
    }

    getMaintenances(): Observable<MaintenanceOccurrence[]> {
        console.log('getting maintenance from service');
        return this.http.get(this.url, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getMaintenancesByDeviceId(id: string, start, end): Observable<MaintenanceOccurrence[]> {
        console.log('getting maintenance from service');
        return this.http.get(this.base_url + 'Device/' + id + '/Maintenance?start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getMaintenancesDueByDeviceId(id: string): Observable<any> {
        console.log('getting maintenance from service');
        return this.http.get(this.base_url + 'Device/' + id + '/MaintenanceDue', { headers: this.authenticationService.headers })
            .map((data) => {
                return data;
            })
            .catch(this.handleError);
    }

    getMaintenanceById(id: string): Observable<MaintenanceOccurrence> {
        console.log('Fetch maintenance by id ' + id);
        return this.http.get(this.url + id, { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseReponseDetails(data);
            })
            .catch(this.handleError);
    }

    getMaintenancesByParentId(id: string): Observable<MaintenanceOccurrence[]> {
        console.log('getting maintenance from service');
        return this.http.get(this.url + id + '/children', { headers: this.authenticationService.headers })
            .map((data) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    saveMaintenance(maintenance: MaintenanceOccurrence): Observable<any> {
        console.log('save maintenance', maintenance);

        return this.http.post(this.url, maintenance, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    updateMaintenance(maintenance: MaintenanceOccurrence): Observable<any> {
        console.log('update maintenance', maintenance);

        return this.http.put(this.url + maintenance.id, maintenance, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    uploadMaintenance(maintenance: MaintenanceUpload[]): Observable<any> {
        return this.http.post(this.url + 'upload', maintenance, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    deleteMaintenance(maintenance: MaintenanceOccurrence): Observable<any> {
        console.log('delete maintenance', maintenance);

        return this.http.delete(this.url + maintenance.id, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        return throwError(error);
    }

    parseResponse(json: any): MaintenanceOccurrence[] {
        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' Maintenances.');

        const ident = 1;
        const maintenances: MaintenanceOccurrence[] = [];

        json.forEach(item => {

            const maintenance = this.parseReponseDetails(item);
            maintenances.push(maintenance);
        });

        return maintenances;
    }

    parseReponseDetails(item) {
        const maintenance = new MaintenanceOccurrence();
        maintenance.id = item.id;
        maintenance.occurrenceDate = item.occurrenceDate !== undefined ? Moment.utc(item.occurrenceDate)['tz'](this.timezoneIana).toDate() : undefined;
        maintenance.assetName = item.assetName;
        maintenance.assetId = item.assetId;
        maintenance.deviceId = item.deviceId;
        maintenance.description = item.description;
        maintenance.reference = item.reference;
        maintenance.supplierName = item.supplierName;
        maintenance.typeName = item.typeName;
        maintenance.maintenanceTypeId = item.maintenanceTypeId;
        maintenance.localPurchaseOrderNumber = item.localPurchaseOrderNumber;
        maintenance.materialReceiptInspectionVoucherNumber = item.materialReceiptInspectionVoucherNumber;
        maintenance.controlNumber = item.controlNumber;
        maintenance.partNumber = item.partNumber;
        maintenance.quantity = item.quantity;
        maintenance.unitPrice = item.unitPrice;
        maintenance.discount = item.discount;
        maintenance.remarks = item.remarks;
        maintenance.companyName = item.companyName;
        maintenance.accountId = item.accountId;
        maintenance.resellerId = item.resellerId;
        maintenance.resellerDescription = item.resellerDescription;
        return maintenance;
    }
}
