<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Accounts/Overview">{{'general.accounts' | translate }}</a>
            </li>
            <li *ngIf="account?.id">
                <a href="/#/AccountDetails/Index/{{account.id}}">{{account.name}}</a>
            </li>
            <li class="active">{{'general.shared' | translate}}</li>
        </ol>
    </div>
    <fh-account-details-navigation [accountId]="accountId"></fh-account-details-navigation>
</div>

<fh-sharing [accountId]="accountId"></fh-sharing>