<div class="col-lg-12">
    <div class='horizontalMenuWrapper'>
        <ul>
            <li [ngClass]="{active: activeRoute('/Fuel/Dashboard')}">
                <a [routerLink]="['/Fuel/Dashboard']">
                    <i class="fas fa-fw fa-gauge"></i>
                    <div>{{ 'general.dashboard' | translate }}</div>
                </a>
            </li>
            <li [ngClass]="{'active': activeRoute('Fuel/Overview')}"><a href="/#/Fuel/Overview/"><i
                        class="fas fa-fw fa-gas-pump"></i>
                    <div>{{ 'general.fuels' |translate }}</div>
                </a></li>
            <li [ngClass]="{active: activeRoute('Fuel/Thresholds')}">
                <a [routerLink]="['/Fuel/Thresholds/']">
                    <i class="fas fa-fw fa-list-alt"></i>
                    <div>{{ 'menu.thresholds' | translate }}</div>
                </a>
            </li>
            <li *ngIf="permissions['FleetManagement_Map']" [ngClass]="{active: activeRoute('Fuel/Map')}">
                <a [routerLink]="['/Fuel/Map/']">
                    <i class="fas fa-fw fa-map"></i>
                    <div>{{ 'menu.map' | translate }}</div>
                </a>
            </li>
        </ul>
    </div>
    <div class='horizontalMenuWrapper' style="float:right">
        <ul>
            <ng-content></ng-content>
        </ul>
    </div>
</div>
<div style="clear: both"></div>