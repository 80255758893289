<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()" [canContinue]="deviceForm">
    <fh-wizard-step title="ReplaceDevice" icon='fa-user' [validate]="{valid: true}" (open)="initForm()">
        <alert type="info">
            <i class="fas fa-fw fa-info"></i> {{ 'wizard.optionalReplaceDeviceWarn' | translate }}
        </alert>
        <div class="wizard-content" style="padding-bottom: 10px;">
            {{ 'wizard.optionalReplaceDevice' | translate }}
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="DeviceFrom" icon='fa-file-pdf' [padding]=false (open)="loadDevices()">
        <div class="ibox-content slick" style="position: relative; min-height:72px">
            <ngx-loading [show]="loadingDeviceDetails"></ngx-loading>
            <ng-container *ngIf="deviceForm">
                <table class="table nowrap dtr-inline no-footer">
                    <thead>
                        <tr>
                            <th class="small_padding">{{ 'general.imei' | translate}}</th>
                            <th class="small_padding">{{ 'general.assetName' | translate}}</th>
                            <th class="small_padding">{{ 'general.assetCode' | translate}}</th>
                            <th class="small_padding">{{ 'general.newDevice' | translate}} *</th>
                            <th class="small_padding">{{ 'general.swapSim' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="loadingDeviceDetails == false && formControls.length === 0">
                            <tr>
                                <td colspan="5"> {{ 'general.noValidDevicesFound' | translate}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngFor="let formGroup of formControls; let i = index;">
                            <tr [formGroup]="formGroup">
                                <td class="small_padding">
                                    <input readonly class="form-control table-inline" formControlName="imei">
                                </td>
                                <td class="small_padding">
                                    <input readonly class="form-control table-inline" formControlName="name">
                                </td>
                                <td class="small_padding">
                                    <input readonly class="form-control table-inline" formControlName="code">
                                </td>
                                <td class="small_padding" style="overflow: visible;">
                                    <ng-select [loading]="loadingDevices" name="newDeviceId" ngDefaultControl
                                        [searchable]="true"
                                        [ngStyle]="{'border': formGroup.get('newDeviceId').errors ? '1px solid red' : '' }"
                                        [clearable]="false" placeholder="{{ 'general.chooseNewDevice' | translate}}"
                                        formControlName="newDeviceId" class="form-control table-inline"
                                        style="width:100%">
                                        <ng-option
                                            *ngFor="let device of devicesToChoose[formGroup.get('resellerId').value] | orderBy : 'name':false; let i = index"
                                            [value]="device.id">
                                            <i class="fas fa-fw fa-car" title="DeviceId: {{ device.id }}"></i>
                                            {{device.unitId }} / <i class="fas fa-fw fa-phone"
                                                title="SimIdentifier: {{ device.simIdentifier }}"></i>
                                            {{device.imsi }}
                                        </ng-option>
                                    </ng-select>
                                </td>
                                <td>
                                    <div class="fa_checkbox">
                                        <input [checked]="false" type="checkbox" formControlName="swapSim"
                                            [id]="'id_' + formGroup.get('deviceId').value" type="checkbox" />
                                        <label [for]="'id_' + formGroup.get('deviceId').value"></label>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </ng-container>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon='fa-file-pdf'>
        <div class="wizard-content" style="padding-bottom: 10px">
            {{ 'wizard.optionalComment' | translate }}
        </div>
        <div class="wizard-content">
            <textarea class=" form-control noresize stickypad" style="height:100px; width:100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}" [(ngModel)]="logItem"></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon='fa-summary-pdf'>
        <div class="wizard-content" style="padding-bottom: 10px"
            [innerHTML]="'wizard.summary' | translate:{ count: deviceForm?.controls?.length }">
        </div>
        <div class="ibox-content slick popupOverflow" style="position: relative; min-height:72px; overflow: auto">
            <table class="table nowrap dtr-inline no-footer table-summary">
                <thead>
                    <tr>
                        <th class="small_padding">{{ 'general.imei' | translate}}</th>
                        <th class="small_padding">{{ 'general.companyName' | translate}}</th>
                        <th class="small_padding">{{ 'general.devicetype' | translate}}</th>
                        <th class="small_padding">New {{ 'general.imei' | translate}}</th>
                        <th class="small_padding">New {{ 'general.companyName' | translate}}</th>
                        <th class="small_padding">New {{ 'general.devicetype' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let formGroup of deviceForm?.controls; let i = index;">
                        <tr>
                            <td class="small_padding">
                                <input [value]="formGroup.get('imei').value" readonly class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('companyName').value" readonly
                                    class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="hardwareDictionary[formGroup.get('deviceTypeId').value]?.modelName"
                                    readonly class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="deviceDictionary[formGroup.get('newDeviceId').value]?.unitId" readonly
                                    class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="deviceDictionary[formGroup.get('newDeviceId').value]?.companyName"
                                    readonly class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="deviceDictionary[formGroup.get('newDeviceId').value]?.modelName"
                                    readonly class="form-control table-inline">
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </fh-wizard-step>
</fh-wizard>