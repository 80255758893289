<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/CarSharing/Dashboard">{{'general.carsharing' | translate }}</a>
            </li>
            <li class="active">{{ 'general.dashboard' | translate }}</li>
        </ol>

        <fh-filter-toggler style="margin-left: auto;" [constructorName]="constructorName"
            (filterChanged)="showFilter = $event" (presetsChanged)="showPresets = $event">
            <a style="margin-left:2px" class="btn btn-info margin-fix hidden-sm hidden-xs" title="Help"
                href="https://help.360locate.com/knowledge-base/fleet-management/carsharing/" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="hidden-sm hidden-xs">{{'general.needHelp' | translate }}</span></a>
        </fh-filter-toggler>
    </div>
    <fh-carsharing-navigation></fh-carsharing-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">

                <!-- Custom filters -->
                <div class="ibox" [hidden]="!showFilter">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-filter"></i> {{'general.filter' | translate }}</h5>
                        <span class="pull-right hand btn btn-info filter-margin-fix" (click)="clearAllFilters();">
                            <i class="fa fa-fw fa-times"></i>
                            <span class="hidden-sm hidden-xs"> {{'general.clearFilters' | translate }}</span>
                        </span>
                    </div>
                    <div class="ibox-content slick_toppadding" style="position: relative;">
                        <ngx-loading [show]="episodesLoaded"></ngx-loading>
                        <div id="dynamicFilters"></div>
                        <div style="clear:both"></div>
                    </div>
                </div>
                <!-- End default dynamic filters -->

                <div class="ibox">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-garage-car"></i> {{ 'general.carsharing' | translate }}</h5>
                        <iboxseach [searchTerm]="searchTerm" (seachChanged)="seachChanged($event)"
                            [constructorName]="constructorName" (filterChanged)="showFilter = $event"
                            (presetLoaded)="onPresetLoaded($event)" (columnsChanged)="drawFilterRow()"></iboxseach>
                    </div>
                    <div class="ibox-content slick" style="position: relative" *ngIf="languageLoaded">
                        <table style="width:100%; min-height:50px; padding-bottom: 0px;" fh-datatable
                            [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" class="table nowrap dataTable dtr-inline">
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>