import { Component, Input, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Inject } from '@angular/core';
import { StorageType } from 'app/common/enums';
import { StorageHelper } from 'app/common/storagehelper';

@Component({
    selector: 'fh-driver-devices',
    templateUrl: 'driverDevices.template.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class DriverDevicesOverviewComponent implements OnInit {
    @Input() loading = false;

    allowSelect = false;

    storageType = StorageType.LocalStorage;
    showArchived = false;

    // Pagination
    currentPage: number;

    filter;

    refreshPagination = false;

    filteredDeviceAssignments = [];

    private _deviceAssignments: any[];
    @Input() set deviceAssignments(value: any[]) {
        this._deviceAssignments = value;
        this.filterAssignments();
    }

    get deviceAssignments(): any[] {
        return this._deviceAssignments;
    }

    constructor(private cd: ChangeDetectorRef, private storageHelper: StorageHelper) {
    }

    ngOnInit(): void {
        this.storageHelper.loadStoreState(this.storageType, 'settings_', 'showArchived').subscribe((result) => {
            this.showArchived = JSON.parse(result) === true;
            this.filterAssignments();
        });
    }

    flipArchived() {
        this.currentPage = 0;
        this.refreshPagination = true;
        this.cd.markForCheck();

        this.showArchived = !this.showArchived;
        this.storageHelper.saveStoreState(StorageType.LocalStorage, 'settings_', 'showArchived', this.showArchived.toString());
        this.filterAssignments();
    }

    filterAssignments() {
        this.filteredDeviceAssignments = this.deviceAssignments.filter(deviceAssignment => this.showArchived || deviceAssignment?.dateEnd === undefined);
        this.refreshPagination = false;
    }
}

