import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';

@Component({
    selector: 'fh-custom-fields',
    templateUrl: 'customFields.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CustomFieldsComponent implements OnInit {

    @Input() customFields: any;
    @Input() formMode: any = FormMode.read;
    @Output() customFieldsChange = new EventEmitter<any>();

    customName: string;
    customValue: string;

    constructor() {
    }

    ngOnInit(): void {
    }

    addRow() {
        this.customFields.push({ key: this.customName, value: this.customValue });

        this.customName = '';
        this.customValue = '';

        this.customFieldsChange.emit(this.customFields);
    }

    removeRow(data) {
        const index: number = this.customFields.indexOf(data);

        if (index !== -1) {
            this.customFields.splice(index, 1);
        }

        this.customFieldsChange.emit(this.customFields);
    }
}

