

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslatorService {
    constructor(private translateService: TranslateService) {
    }

    translate(translateString: string, prefix: string = '') {
        if (translateString != null && translateString.length > 0) {
            translateString = translateString.replace('translate:', '');
            return this.translateService.instant(prefix + (prefix !== '' ? '.' : '') + translateString);
        } else {
            return '';
        }
    }
}
