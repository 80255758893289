<ng-select [loading]="loadingSensorTemplates" ngDefaultControl [searchable]="true" [clearable]="clearable"
    [addTag]="addTag" [disabled]="sensorTemplates.length == 0" [readonly]="readonly"
    placeholder="{{placeholder}} ({{sensorTemplates.length}})" #input [(ngModel)]="value"
    class="form-control table-inline" (ngModelChange)="sensorTemplateChanged()">
    <ng-option *ngFor="let sensorTemplate of sensorTemplates | orderBy : 'name':false" [value]="sensorTemplate.id"> <i
            class="fas fa-fw fa-sensor"></i> {{ sensorTemplate.name }}
        ({{(sensorTemplate.minValue !== undefined ? sensorTemplate.minValue + ' &lt; ': '')}} x
        {{(sensorTemplate.maxValue !== undefined ? ' &lt; '
        + sensorTemplate.maxValue : '')}})</ng-option>
</ng-select>
<div style="clear:both"></div>