<div class="ibox-content slick" style="position:relative;">
    <ngx-loading [show]="loading"></ngx-loading>
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!(webhookEndpoint && webhookEndpoint.id) && formMode == 1">
            <tr>
                <td colspan="3">
                    {{ 'general.noDataFound' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="(webhookEndpoint && webhookEndpoint.id) || formMode != 1">
            <ng-container *ngIf="formMode != 2">
                <tr [hidden]="!permissions['Resellers_View']">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                    </td>
                    <td colspan="2" class="padding-readonly-correct row_content">
                        <a class="secondary link_bolder"
                            href="/#/ResellerDetails/Index/{{webhookEndpoint?.resellerId}}">{{webhookEndpoint?.resellerDescription
                            }}</a>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View']">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                    </td>
                    <td colspan="2" class="row_content padding-readonly-correct">
                        <a class="secondary link_bolder"
                            href="/#/AccountDetails/Index/{{webhookEndpoint?.accountId}}">{{webhookEndpoint?.companyName
                            }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode == 2">
                <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                    <td class="row_opening">
                        {{ 'general.resellerDescription' | translate}}
                        <span class="error" *ngIf="resellerVar.invalid">*</span>
                    </td>
                    <td colspan="2" class="row_content" style="position:relative; overflow: visible">
                        <fh-reseller-input name="reseller" #resellerVar="ngModel" (onChanged)="resellerChanged($event)"
                            [tableInline]="true" required placeholder="{{ 'general.chooseReseller' | translate}}"
                            [(ngModel)]="selectedResellerId">
                        </fh-reseller-input>
                    </td>
                </tr>
                <tr [hidden]="!permissions['Accounts_View'] && webhookEndpoint.accountId">
                    <td class="row_opening">
                        {{ 'general.account' | translate}}
                        <span class="error" *ngIf="accountVar.invalid">*</span>
                    </td>
                    <td colspan="2" class="row_content" style="position:relative; overflow: visible">
                        <fh-account-input name="account" #accountVar="ngModel" (onChanged)="accountChanged($event)"
                            required [selectedResellerId]="selectedResellerId" [tableInline]="true"
                            placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                            [(ngModel)]="webhookEndpoint.accountId">
                        </fh-account-input>
                    </td>
                </tr>
            </ng-container>
            <!-- name -->
            <tr>
                <td class="row_opening">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td colspan="2" class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                        placeholder="{{ 'placeholder.webhookEndpointName' | translate}}"
                        [(ngModel)]="webhookEndpoint.name" required minlength="3" #name="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening"> {{ 'general.webHookUrl' | translate}}
                    <span class="error" *ngIf="webHookUrlVar?.invalid">*</span>
                </td>
                <td colspan="2" class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="webHookUrl" class="form-control table-inline"
                        placeholder="{{ 'placeholder.webHookUrl' | translate}}" required
                        [(ngModel)]="webhookEndpoint.url" minlength="3" #webHookUrlVar="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening"> {{ 'general.webHookMethod' | translate}}
                    <span class="error" *ngIf="webHookMethodVar?.invalid">*</span>
                </td>
                <td colspan="2" class="row_content" style="overflow: visible;">
                    <ng-select class="form-control table-inline" name="webHookMethod" [clearable]="false"
                        #webHookMethodVar="ngModel" [(ngModel)]="webhookEndpoint.method"
                        placeholder="{{ 'general.chooseWebHookMethod' | translate }}" required
                        pattern="(5000|([1-4][0-9][0-9][0-9])|([1-9][0-9][0-9])|([1-9][0-9])|[1-9])"
                        [disabled]="formMode == 1" style="width:100%">
                        <ng-container *ngIf="webhookEndpoint.method == 0">
                            <ng-option [value]=0 [disabled]="true"> {{ 'enums.webHookMethod.0' | translate}}
                            </ng-option>
                        </ng-container>

                        <ng-option [value]=1> {{ 'enums.webHookMethod.1' | translate}} </ng-option>
                        <ng-option [value]=2> {{ 'enums.webHookMethod.2' | translate}} </ng-option>
                        <ng-option [value]=3> {{ 'enums.webHookMethod.3' | translate}} </ng-option>
                        <ng-option [value]=4> {{ 'enums.webHookMethod.4' | translate}} </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening"> {{ 'general.webHookContentType' | translate}}
                    <span class="error" *ngIf="webHookContentTypeVar?.invalid">*</span>
                </td>
                <td colspan="2" class="row_content" style="overflow: visible;">
                    <ng-select class="form-control table-inline" name="webHookContentType" [clearable]="false"
                        #webHookContentTypeVar="ngModel" [(ngModel)]="webhookEndpoint.contentType" [searchable]="false"
                        placeholder="{{ 'general.chooseWebHookContentType' | translate }}" required
                        [disabled]="formMode == 1" style="width:100%">
                        <ng-container *ngIf="webhookEndpoint.contentType == 0">
                            <ng-option [value]=0 [disabled]="true"> {{ 'enums.webHookContentType.0' | translate}}
                            </ng-option>
                        </ng-container>

                        <ng-option [value]=1> {{ 'enums.webHookContentType.1' | translate}} </ng-option>
                        <ng-option [value]=2> {{ 'enums.webHookContentType.2' | translate}} </ng-option>
                        <ng-option [value]=3> {{ 'enums.webHookContentType.3' | translate}} </ng-option>
                        <ng-option [value]=4> {{ 'enums.webHookContentType.4' | translate}} </ng-option>
                        <ng-option [value]=5> {{ 'enums.webHookContentType.5' | translate}} </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.isEnabled' | translate}}
                </td>
                <td colspan="2" class="row_content">
                    <div class="fa_checkbox">
                        <input [checked]="true" [disabled]="formMode == 1" id="isEnabled" type="checkbox"
                            name="isEnabled" [(ngModel)]="webhookEndpoint.isEnabled" />
                        <label for="isEnabled">
                        </label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="ibox-content slick" style="position: relative;">
    <form #headersForm="ngForm">
        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
            <thead *ngIf="!(formMode == 1 && !webhookEndpoint.headers)">
                <tr>
                    <th [colSpan]="2">{{ 'general.webHookHeadersInfo' | translate }}</th>
                    <th *ngIf="!(formMode == 1)" style="width: 40px; padding-top: 18px;">
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="!(formMode == 1)">
                    <td style="width: auto">
                        <input [readonly]="formMode == 1" type="text" required name="headerName"
                            [(ngModel)]="webHookHeaderName" class="form-control table-inline"
                            #webHookHeaderNameProp="ngModel">
                    </td>
                    <td style="width: auto;">
                        <input [readonly]="formMode == 1" type="text" required name="headerProperty"
                            [(ngModel)]="webHookHeaderProperty" class="form-control table-inline"
                            #webHookHeaderPropertyProp="ngModel">
                    </td>
                    <td style="width: 40px; padding-top: 18px;">
                        <button class="btn btn-primary pull-right btn-grid" [disabled]="headersForm.invalid"
                            (click)="addRow(webHookHeaderNameProp, webHookHeaderPropertyProp)">
                            <i class="fa fa-plus"></i>
                        </button>
                    </td>
                </tr>
                <tr *ngFor="let header of webhookEndpoint?.headers">
                    <td style="width: auto">
                        <input type="text" [readonly]="true" class="form-control table-inline"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="header.webHookHeaderName">
                    </td>
                    <td style="width: auto;">
                        <input type="text" [readonly]="true" class="form-control table-inline"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="header.webHookHeaderProperty">
                    </td>
                    <td *ngIf="!(formMode == 1)" style="width: 40px; padding-top: 18px;">
                        <button class="btn btn-primary pull-right btn-grid" (click)="removeRow(header)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>