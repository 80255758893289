<div class="ibox-content slick" style="position:relative">
    <table *ngIf="account" style="width:100%" class="table nowrap dtr-inline no-footer">
        <tbody>
            <tr>
                <td class="row_opening">
                    {{ 'general.waslIdentity' | translate}}
                    <span class="error" *ngIf="identityNumber.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="identityNumber"
                        class="form-control table-inline" [(ngModel)]="account.properties.wasl.identityNumber" required
                        #identityNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.commercialRecordNumber' | translate}}
                    <span class="error" *ngIf="commercialRecordNumber.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="commercialRecordNumber"
                        class="form-control table-inline" [(ngModel)]="account.properties.wasl.commercialRecordNumber"
                        required #commercialRecordNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.commercialRecordIssueDateHijri' | translate}}
                    <span class="error" *ngIf="commercialRecordIssueDateHijri.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="commercialRecordIssueDateHijri"
                        class="form-control table-inline"
                        [(ngModel)]="account.properties.wasl.commercialRecordIssueDateHijri" required
                        #commercialRecordIssueDateHijri="ngModel">

                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.phoneNumber' | translate}}
                    <span class="error" *ngIf="phoneNumber.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="phoneNumber" class="form-control table-inline"
                        [(ngModel)]="account.properties.wasl.phoneNumber" required #phoneNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.emailAddress' | translate}}
                    <span class="error" *ngIf="emailAddress.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="emailAddress" class="form-control table-inline"
                        [(ngModel)]="account.properties.wasl.emailAddress" required #emailAddress="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.managerName' | translate}}
                    <span class="error" *ngIf="managerName.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="managerName" class="form-control table-inline"
                        [(ngModel)]="account.properties.wasl.managerName" required #managerName="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.managerPhoneNumber' | translate}}
                    <span class="error" *ngIf="managerPhoneNumber.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="managerPhoneNumber"
                        class="form-control table-inline" [(ngModel)]="account.properties.wasl.managerPhoneNumber"
                        required #managerPhoneNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.managerMobileNumber' | translate}}
                    <span class="error" *ngIf="managerMobileNumber.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="managerMobileNumber"
                        class="form-control table-inline" [(ngModel)]="account.properties.wasl.managerMobileNumber"
                        required #managerMobileNumber="ngModel">
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.sfdaCompanyActivity' | translate}}
                </td>
                <td class="row_content" style="overflow:visible">

                    <ng-select class="form-control table-inline" name="sfdaCompanyActivity"
                        #sfdaCompanyActivity="ngModel" [disabled]="formMode == 1"
                        [(ngModel)]="account.properties.wasl.sfdaCompanyActivity" style="width:100%">
                        <ng-option value="STORE"> {{ 'enums.sfdaCompanyActivity.STORE' | translate}}</ng-option>
                        <ng-option value="TRANSPORT"> {{ 'enums.sfdaCompanyActivity.TRANSPORT' | translate}}
                        </ng-option>
                        <ng-option value="STORE_TRANSPORT"> {{ 'enums.sfdaCompanyActivity.STORE_TRANSPORT' |
                            translate}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
        </tbody>
    </table>
</div>