<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/Overview">{{'general.users' | translate }}</a>
            </li>
            <li>
                <a href="/#/Users/UserContactGroups/Overview">{{'general.userContactGroups' | translate }}</a>
            </li>
            <li class="active">{{'menu.add' | translate }}</li>
        </ol>
    </div>
    <fh-user-navigation>
        <li class="active">
            <a href="/#/Users/UserContactGroupDetails/Add/">
                <i class="fas fa-fw fa-address-book"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a>
        </li>
    </fh-user-navigation>
</div>

<fh-permission-handler [permissionName]='permissionName'>
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-address-book" style="margin-right: 0px;"></i>
                                {{'general.userContactGroup' | translate }}
                            </h5>
                        </div>
                        <fh-user-contact-group-details [userContactGroup]="userContactGroup" [formMode]="formMode"
                            (onAccountChanged)="accountChanged($event)">
                        </fh-user-contact-group-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()"
                            [allowAdd]="permissions['UserContactGroups_Add']" [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <form #userForm="ngForm">
                    <fh-user-contact-selector [userContacts]="userContacts" [formMode]="formMode"
                        [loading]="loadingUserContacts">
                    </fh-user-contact-selector>
                </form>
            </div>
        </div>
    </div>
</fh-permission-handler>