import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'pretty-json',
  styles: [
    `pre { outline: 0px; padding: 5px; margin: 5px; background-color: unset; color: unset; border: unset; }
    span { white-space: normal; }
    .string { color: green; }
    .number { color: darkorange; }
    .boolean { color: blue; }
    .null { color: magenta; }
    .key { color: red; }`
  ],
  template: `
    <pre [innerHtml]="obj | prettyjson">
    </pre>
  `,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class PrettyJsonComponent {
  @Input() public obj: any;
}
