import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-device-navigation',
    templateUrl: 'deviceNavigation.template.html'
})

export class DeviceNavigationComponent implements OnInit {
    sub: any;
    permissions: {};
    activeId: any;

    constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) {
        this.permissions = authenticationService.permissions;
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.activeId = id;
        });
    }

    isAssetGroupDetails() {
        const routes = ['AssetGroupDetails'];

        let routeActive = false;

        routes.forEach(route => {
            const active = this.router.url.indexOf(route);
            if (active > -1) {
                routeActive = true;
            }
        });

        return routeActive;
    }

    activeRoute(routename: string): boolean {
        const active = this.router.url.indexOf(routename) !== -1;
        return active;
    }

    isSettings() {
        const routes = ['AssetTypes', 'DeviceTypes', 'DeviceTypeDetails', 'SensorTemplate', 'DeviceRoutingConfiguration'];

        let routeActive = false;

        routes.forEach(route => {
            const active = this.router.url.indexOf(route);
            if (active > -1) {
                routeActive = true;
            }
        });

        return routeActive;
    }

    isData() {
        const routes = ['DayView', 'Assignments', 'Changes', 'Routes'];

        let routeActive = false;

        routes.forEach(route => {
            const active = this.router.url.indexOf(route);
            if (active > -1) {
                routeActive = true;
            }
        });

        return routeActive;
    }

}
