<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li class="active">{{'general.tokens' | translate}}</li>
        </ol>
    </div>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="" *ngIf="error || success">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>
    <div class="row" data-equalizer="">
        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-key"></i>{{ 'general.refreshTokens' | translate }}
                    </h5>
                    <span class="pull-right">
                        <div class="filter-form hidden-sm hidden-xs">
                            <input type="text" class="form-control table-inline top-filter hidden-sm hidden-xs"
                                [(ngModel)]="refreshFilter">
                        </div>
                    </span>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loadingRefreshTokens"></ngx-loading>
                    <table class="table table-fixed nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <thead>
                            <tr>
                                <th style="width:auto" scope="col">
                                    {{ 'general.refreshToken' | translate}}
                                </th>
                                <th scope="col">
                                    {{ 'general.name' | translate}}
                                </th>
                                <th scope="col">
                                    {{ 'general.type' | translate}}
                                </th>
                                <th style="width:60px;" scope="col">
                                    &nbsp;
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ngx-loading [show]="loading"></ngx-loading>
                            <ng-container *ngFor="let refreshToken of refreshTokens | stringFilter: refreshFilter">
                                <tr>
                                    <td style="width:auto" class="elipses_overflow">
                                        <fh-copyinput [textMode]=true>
                                            {{ refreshToken.refreshToken }}
                                        </fh-copyinput>
                                    </td>
                                    <td style="width: 155px;">
                                        {{ refreshToken.deviceName }}
                                    </td>
                                    <td style="width: 155px;">
                                        {{ refreshToken.deviceType }}
                                    </td>
                                    <td style="width:60px; text-align: right;">
                                        <a class='btn btn-danger btn-grid' (click)="deleteRefreshToken(refreshToken.id)"
                                            title="{{'general.delete' | translate }}">
                                            <i class="fas fa-fw fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="refreshTokens.length === 0">
                                <td colspan="3">
                                    {{'general.nodatafound' | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer">

                    <input type="text" class="form-control" style="float:left; width: 150px; margin-right: 6px;"
                        placeholder="{{ 'placeholder.name' | translate}}" [(ngModel)]="tokenName">

                    <button class="btn btn-primary" style="float:left;" [disabled]="!tokenName"
                        (click)="addRefreshToken()">
                        <i class="fa fa-fw fa-plus"></i> {{'general.add' | translate }}</button>

                    <div style="clear:both"></div>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fa fa-fw fa-key"></i>{{ 'general.authtokens' | translate }}
                    </h5>
                    <span class="pull-right">
                        <div class="filter-form hidden-sm hidden-xs">
                            <input type="text" class="form-control table-inline top-filter hidden-sm hidden-xs"
                                (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                        </div>
                    </span>
                </div>
                <div class="ibox-content slick" style="position:relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <table class="table table-fixed nowrap dtr-inline no-footer" style="width:100%; margin-bottom:0px ">
                        <thead>
                            <tr>
                                <th style="width:auto" scope="col">
                                    {{ 'general.token' | translate}}
                                </th>
                                <th style="width: 155px; " scope="col">
                                    {{ 'general.expiryDate' | translate}}
                                </th>
                                <th style="width:60px;" scope="col">
                                    &nbsp;
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ngx-loading [show]="loading"></ngx-loading>
                            <ng-container
                                *ngFor="let token of tokens | stringFilter: filter | paginate: { itemsPerPage: 10, currentPage: currentPage, id: 'first'}">
                                <tr>
                                    <td style="width:auto" class="elipses_overflow">
                                        <fh-copyinput [textMode]=true>
                                            {{ token.token }}
                                        </fh-copyinput>
                                    </td>
                                    <td style="width: 155px; text-align: left;">
                                        {{ token.expiryDate | amDateFormat: 'YYYY-MM-DD HH:mm:ss '}}
                                    </td>
                                    <td style="width:60px; text-align: right;">
                                        <a class='btn btn-danger btn-grid' (click)="deleteToken(token.id)"
                                            title="{{'general.delete' | translate }}">
                                            <i class="fas fa-fw fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="tokens.length === 0">
                                <td colspan="3">
                                    {{'general.nodatafound' | translate }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ibox-footer noselect dataTables_wrapper">

                    <span>
                        <button class="btn btn-primary" (click)="addToken()">
                            <i class="fa fa-fw fa-plus"></i> {{'general.add' | translate }}</button>
                    </span>

                    <pagination-template #p="paginationApi" (pageChange)="currentPage = $event">
                        <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                            <ul class="pagination">
                                <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                    <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                        'grid.previous' | translate }}</a>
                                    <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                </li>

                                <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                    [class.current]="p.getCurrent() === page.value">
                                    <a class="page-link" (click)="p.setCurrent(page.value)"
                                        *ngIf="p.getCurrent() !== page.value">
                                        <span>{{ page.label }}</span>
                                    </a>
                                    <a class="page-link" (click)="p.setCurrent(page.value)"
                                        *ngIf="p.getCurrent() === page.value">
                                        <span style="font-weight: bold;">{{ page.label }}</span>
                                    </a>
                                </li>

                                <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                    <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                        {{ 'grid.next' | translate }}
                                    </a>
                                    <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                                </li>
                            </ul>
                        </div>
                    </pagination-template>
                    <div style="clear:both"></div>
                </div>
            </div>
        </div>
    </div>
</div>