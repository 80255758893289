<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/SensorTemplates/Overview">{{'general.sensorTemplates' | translate }}</a>
            </li>
            <li class="active">{{'general.details' | translate }}</li>
        </ol>

        <span style="margin-left: auto;" class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix hidden-sm hidden-xs" title="Help"
                href="https://help.360locate.com/knowledge-base/use-workschedule-to-mark-trips-as-private-or-business/"
                target="_blank"><i class="fa fa-fa fa-question"></i> <span
                    class="hidden-sm hidden-xs">{{'general.needHelp' |
                    translate }}</span></a>
        </span>
    </div>
    <fh-device-navigation>
        <li class="active">
            <a href="/#/Devices/SensorTemplateDetails/Add/"><i class="fa fa-fw fa-sensor"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a>
        </li>
    </fh-device-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <form #form="ngForm">
        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-sensor"></i> {{'general.sensorTemplates' | translate }}
                        </h5>
                    </div>
                    <ngx-loading [show]="loading || saving"></ngx-loading>
                    <fh-sensor-template-details [sensorTemplate]="sensorTemplate" [loading]="loading"
                        [formMode]="formMode">
                    </fh-sensor-template-details>
                    <fh-save-footer [valid]="!form.invalid" [allowCancelOnInsert]=false (onInsert)="onInsert()"
                        [formMode]="formMode">
                    </fh-save-footer>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="ibox" style="position:relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-city"></i> {{'general.info' | translate }}</h5>
                    </div>
                    <div class="ibox-content">
                        Info
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>