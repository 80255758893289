import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TriggerResultName, FormMode } from 'app/common/enums';
import { Trigger, WebHookHeader } from 'app/models/trigger.model';
import { UserContactGroup } from 'app/models/userContactGroup.model';
import { WebhookEndpoint } from 'app/models/webhookEndpoint.model';
import { AccountService } from 'app/services/account/account.service';
import { WebhookEndpointService } from 'app/services/webhookendpoints/webhookEndpoint.service';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from './confirmationModal.component';

declare var require: any

@Component({
    selector: 'fh-trigger-output-details',
    templateUrl: 'triggerOutputDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TriggerOutputDetailsComponent implements OnInit, OnChanges {
    @ViewChild('popupModal') popupModal: ConfirmationModalComponent;

    @Input() trigger: Trigger;
    @Input() formMode: any = FormMode.read;

    success;
    error;

    triggerNames: { value: number; name: any; }[];

    webHookHeaderName;
    webHookHeaderProperty;
    webHookHeaders = [];
    webhookEndpoint: WebhookEndpoint;
    webhookMethod;
    webhookContentType;

    jsonValid;
    jsonInvalid;

    availableFields = {
        'ContactName': 'John Smith',
        'AccountName': 'Company ABC',
        'DriverName': 'Bus Driver',

        'AssetId': '12345',
        'AssetName': 'Audi 80',
        'AssetPlate': 'AB-CD-EF',
        'AssetBrand': 'Volkswagen',
        'AssetModel': 'Polo',
        'AssetComment': 'Comment 1234',

        'DeviceId': '12345',
        'Timestamp': Date.now().toLocaleString(),
        'TimestampShort': new Date().toLocaleDateString('en-us'),
        'TimestampBegin': Date.now().toLocaleString(),
        'TimestampEnd': Date.now().toLocaleString(),
        'DateBegin': '6/15/2022',
        'DateEnd': '6/15/2022',
        'DateShortBegin': 'June 15',
        'DateShortEnd': 'June 15',
        'TimeBegin': '1:45 PM',
        'TimeEnd': '1:45 PM',

        'TriggerName': 'TriggerName',

        'DurationInMinutes': '2',
        'DurationInSeconds': '120',

        'NotificationMoment': 'Finished',
        'Speed': '100km/h',
        'Location': 'location',
        'GeofenceAction': 'GeofenceAction',
        'GeofenceNames': 'GeofenceNames',
        'GeofenceIds': 'GeofenceIds',
        'GeofenceGroupNames': 'GeofenceGroupNames',
        'Ignition': 'on',
        'ExternalPower': 'on',
        'Priority': 'High',
        'DebounceInSeconds': '1200',
        'DebounceInMinutes': '20',

        'TotalWeight': '20000kg',
        'Temperature1': '25°',
        'Temperature2': '25°',
        'Humidity1': '60%',
        'Humidity2': '60%',
        'FuelLevelInPercentage': '60%',

        'EngineHours': '4',
        'CalibratedOdometer': '14 km',
        'Latitude': '73.8777',
        'Longitude': '19.0760',
    };

    htmlEntities = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        '\'': '&apos;'
    };

    fieldList = [];

    displayAvailableFields = false;
    displayPreview = false;
    previewText: string;
    previewSubject: string;

    constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private accountService: AccountService, private webhookEndpointService: WebhookEndpointService) {
    }


    ngOnInit(): void {
        // Get all the date for dropdown boxes
        Observable.forkJoin(
            this.translateService.get('general.date')
        ).subscribe(
            data => {
                this.triggerNames = Object.keys(TriggerResultName)
                    .filter(k => typeof TriggerResultName[k] === 'string')
                    .map(n => ({ value: +n, name: this.translateService.instant('enums.episodeType.' + n) }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                this.fieldList = Object.keys(this.availableFields)
                    .filter(k => typeof this.availableFields[k] === 'string')
                    .map(n => ({ key: n, value: this.availableFields[n] }))
                    .sort((a, b) => a.key.localeCompare(b.key));

                // console.log('constructor - WebhookEndpoint: ' + this.trigger.webhookEndpointId);
            });

        this.webHookTypeChanged(2);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.trigger.webhookEndpointId && this.trigger.webhookEndpointId !== 0) {
            this.getWebhookEndpoint(this.trigger.webhookEndpointId);
        }
    }

    webhookEndpointChanged(webhookEndpointId) {
        this.getWebhookEndpoint(webhookEndpointId);
    }

    addRow(webHookHeaderName: NgModel, webHookHeaderProperty: NgModel) {
        this.trigger.webHookHeaders.push({
            webHookHeaderName: webHookHeaderName.value,
            webHookHeaderProperty: webHookHeaderProperty.value
        } as WebHookHeader);

        this.webHookHeaderName = '';
        this.webHookHeaderProperty = '';
    }

    removeRow(data) {
        const index: number = this.trigger.webHookHeaders.indexOf(data);

        if (index !== -1) {
            this.trigger.webHookHeaders.splice(index, 1);
        }
    }

    preview(text, subject) {

        this.availableFields['TriggerName'] = this.trigger.name ?? 'Trigger name';
        this.availableFields['AccountName'] = this.trigger.companyName ?? 'Company ABC';

        this.displayAvailableFields = false;
        this.displayPreview = true;

        this.previewText = text;
        this.previewSubject = subject;

        const that = this;

        const mustache = require('mustache');
        text = mustache.render(text, this.availableFields);
        subject = mustache.render(subject, this.availableFields);

        this.previewText = text.replace(/\n/g, '<br/>');
        this.previewSubject = subject.replace(/\n/g, '<br/>');

        this.popupModal.showModal(null);
    }

    decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }

    showAvailableFields() {
        this.displayAvailableFields = true;
        this.displayPreview = false;

        this.popupModal.showModal(null);
    }

    webHookMethodChanged(method) {
        switch (method) {
            case 1:
            case 2:
            case 3:
            case 4:
                this.trigger.webHookMethod = method;
                break;
            case -1:
                this.webhookMethod = -1;
                this.trigger.webHookMethod = this.webhookEndpoint.method;
                break;
        }
    }

    webhookContentTypeChanged(type) {
        this.trigger.webHookContentType = type;
        switch (type) {
            case 0:
                this.webHookTypeChanged(1); // text
                break;
            case 1:
                this.webHookTypeChanged(2); // application/json
                break;
            case 2:
                this.webHookTypeChanged(3); // application/xml
                break;
            case -1:
                this.webhookContentType = -1;
                this.trigger.webHookContentType = this.webhookEndpoint.contentType;
                this.webhookContentTypeChanged(this.webhookEndpoint.contentType);
                break;
            default:
                this.webHookTypeChanged(0);
                break;
        }
    }

    webHookTypeChanged(type) {
        this.trigger.webHookType = type;
        if (this.trigger.json.length === 0) {
            switch (type) {
                case 1:
                    this.trigger.json = `We have an event on our system named {{TriggerName}} on {{Timestamp}}.`;

                    break;
                case 2:
                    this.trigger.json = `{
                    "name": "{{TriggerName}}",
                    "deviceId": "{{DeviceId}}",
                    "timestamp": "{{Timestamp}}",
                    "accountName": "{{AccountName}}"
                    }`;

                    break;
                case 3:
                    this.trigger.json = `<event>
                    <name>{{TriggerName}}</name>
                    <deviceId>{{DeviceId}}</deviceId>
                    <timestamp>{{Timestamp}}</timestamp>
                    <accountName>{{AccountName}}</accountName>
                    </event>`;

                    break;
                default:
                    this.trigger.json = '';
                    break;
            }
        }
    }

    getWebhookEndpoint(webhookEndpointId) {
        this.webhookEndpointService.getWebhookEndpointById(webhookEndpointId).subscribe((webhookEndpoint) => {
            this.webhookEndpoint = webhookEndpoint;
            this.webhookContentTypeChanged(-1);
            this.webHookMethodChanged(-1);
        });
    }

    validateJson() {
        try {
            JSON.parse(this.trigger.json);

            this.jsonInvalid = '';
            this.jsonValid = 'Json validation successful!';
        } catch (error) {
            console.log(error);
            this.jsonValid = '';
            this.jsonInvalid = error;
        };
    }
}
