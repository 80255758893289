import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionType, EntityType, ExportFileType } from 'app/common/enums';
import { WizardComponent } from 'app/modules/wizard/wizard';
import { AccountService } from 'app/services/account/account.service';
import { ExportService } from 'app/services/export/export.service';
import { ResellerService } from 'app/services/resellers/resellers.service';

import * as XLSX from 'xlsx';

@Component({
  selector: 'fh-export',
  templateUrl: 'export.template.html'
})
export class ExportViewComponent implements OnInit {
  @ViewChild(WizardComponent, { static: false }) wizard: WizardComponent;

  entityType = EntityType;
  actionType = ActionType;
  exportFileType = ExportFileType;

  step = 1;
  processing = false;

  success;
  error;

  // Resellers
  selectedReseller;
  loadingResellers = false;
  resellers = [];
  selectedResellerId;

  // Accounts
  selectedAccount;
  selectedAccountId;
  useAccount = true;
  loadingAccounts = false;
  accounts = [];

  // Actions
  selectedActionId;

  // FileType
  selectedFileTypeId = ExportFileType.CSV;

  // Entities
  selectedEntityId;
  clearItemsOnImport = false;

  constructor(private accountService: AccountService, private resellerService: ResellerService, private exportService: ExportService, private cd: ChangeDetectorRef, private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  resellerChanged(resellerId) {
    this.accounts = [];
    this.selectedAccountId = null;

    this.selectedResellerId = resellerId;
    this.selectedReseller = this.resellerService.resellers.find(x => x.id === resellerId);
  }

  accountChanged(accountId) {
    this.selectedAccountId = accountId;
    this.selectedAccount = this.accountService.accounts.find(x => x.id === this.selectedAccountId);
  }

  accountSelected() {
    this.selectedAccount = this.accountService.accounts.find(x => x.id === this.selectedAccountId);
  }

  onCheckOutFinish() {
    this.processing = true;

    this.exportService.getExport(this.selectedAccountId, this.selectedResellerId, this.selectedEntityId).subscribe(result => {
      this.processing = false;

      this.export(result);

      this.success = {
        statusText: 'Success',
        success: 'Data has been succesfully exported. File will be downloaded to your device.'
      };

      this.resetWizard();
    }, error => {
      this.error = error
      this.processing = false;
    });
  }

  export(data) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'FleetManagement_Export');

    const filename = ('fm_export_' + (this.useAccount ? this.selectedAccount.name : this.selectedReseller?.resellerDescription) + '_' + this.translateService.instant('enums.entityTypes.' + this.selectedEntityId)).replace(/\s/g, '').replace(' ', '').replace('.', '').toLowerCase();

    if (this.selectedFileTypeId === ExportFileType.CSV) {
      XLSX.writeFile(wb, filename + '.csv', { bookType: 'csv' });
    } else {
      XLSX.writeFile(wb, filename + '.xlsx');
    }
  }

  resetWizard() {
    this.selectedAccountId = null;
    this.selectedActionId = null;
    this.selectedEntityId = null;
    this.selectedResellerId = null;
    this.useAccount = true;

    this.wizard.reset();
  }
}
