<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/"><i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/">{{'general.system' | translate }}</a>
            </li>
            <li class="active">{{'general.system' | translate}}</li>
        </ol>
    </div>
    <fh-system-navigation></fh-system-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>
                        <i class="fas fa-fw fa-toolbox"></i>{{'general.system' | translate}}
                    </h5>
                </div>
                <div class="ibox-content" style="position: relative">
                    <ngx-loading [show]="loading"></ngx-loading>
                    <!-- Button -->
                    Please select option in top bar
                    <div style="clear:both "></div>
                </div>
            </div>
        </div>
    </div>
</div>