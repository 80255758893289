import { UserContact } from './userContact.model';

export class UserContactGroup {
    accountId: any;
    name: any;
    id: any;
    companyName: any;
    isSystemGroup: any;
    items: any[] = [];
    userContactCount: any;
    resellerDescription: any;
    resellerId: any;
    userContactItems: any[] = [];
}

export class UserContactGroupItem {
    userContactId: any;
    notificationMethod: any;
}
