import { Component, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { EntityType, VehicleType } from 'app/common/enums';
import { AssetType } from 'app/models/assetType.model';
import { DeviceService } from 'app/services/device/device.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { OrderByPipe } from 'app/common/orderBy.pipe';
import { ResellerService } from 'app/services/resellers/resellers.service';
@Component({
    selector: 'fh-batch-change-inventory',
    templateUrl: 'changeInventory.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchChangeInventoryComponent implements OnChanges, OnInit {
    step: number;

    @Input() gridSelection = [];
    @Output() onFinish = new EventEmitter();
    disabled = true;
    logItem;

    selectedReseller = undefined;
    loadingResellers = false;
    loadingDevices = true;
    deviceForm;

    groupForm = new FormGroup({
        selectedReseller: new FormControl(null, Validators.required),
    });

    allowedDevices = [];

    selectedResellerName = '';

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    constructor(private resellerService: ResellerService, private cd: ChangeDetectorRef, private deviceService: DeviceService) {
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.step = 1;
    }

    ngOnInit(): void {
        console.log('init');
        this.step = 1;
        this.cd.markForCheck();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('changes');
        this.step = 1;
        this.cd.markForCheck();
    }

    initFinish() {
        // Fetch all data and make sure it can be saved
        console.log(1);
        console.log(this.groupForm.value);
    }

    onCheckOutFinish() {
        this.onFinish.emit({ status: 'success', object: this.groupForm.value, log: this.logItem })
        this.step = 1;
    }

    initForm() {
        console.log('init tab');
        this.step = 1;

        this.deviceService.getDeviceData(this.gridSelection).subscribe(devices => {
            this.allowedDevices = devices.filter(i => !(i.assetId > 0));
            this.loadingDevices = false;
            this.cd.markForCheck();
        });
    }

    resellerChanged(resellerId) {
        this.selectedResellerName = this.resellerService.resellers.find(x => x.id === resellerId)?.resellerDescription;

        setTimeout(() => {
            this.cd.markForCheck();
        }, 100);
    }
}
