import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { Fuel } from 'app/models/fuel.model';
import { AccountService } from 'app/services/account/account.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { FuelService } from 'app/services/fuel/fuel.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'fh-fuel-details',
    templateUrl: 'fuelDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FuelDetailsComponent implements OnInit {

    @Input() fuel: Fuel;
    @Input() loading = false;
    @Input() smallMode = false;
    @Input() formMode: any = FormMode.read;
    @Output() error = new EventEmitter();
    @Output() success = new EventEmitter<string>();
    @Output() onAccountChanged = new EventEmitter();
    loadingAccounts = false;
    selectedResellerId;

    // Datepicker
    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    permissions: {};

    constructor(private fuelService: FuelService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
        this.permissions = this.authenticationService.permissions;
        // Datepicker
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.dpConfig.withTimepicker = true;
        // this.dpConfig.maxDateRange = 14;
    }

    resellerChanged(resellerId) {
        this.fuel.accountId = null;

        this.selectedResellerId = resellerId;
    }

    accountChanged(accountId) {
        this.fuel.deviceId = null;
        this.fuel.assetId = null;

        this.onAccountChanged.emit(this.fuel.accountId);
    }

    ngOnInit(): void {
        this.fuel.eventTimeStamp = new Date();
    }
}
