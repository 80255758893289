import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import { DomainEventInput, FormMode } from 'app/common/enums';
import { Trigger } from 'app/models/trigger.model';


@Component({
    providers: [],
    selector: 'fh-trigger-source',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'triggerSource.component.html'
})
export class TriggerSourceViewComponent implements OnChanges {
    @Input() trigger: Trigger
    @Input() formMode: any = FormMode.read;
    @Output() onSourceChanged = new EventEmitter();

    domainEventInput = [];

    intervalInMinutes: number;

    timerIntervalChanged() {
        this.trigger.intervalInSeconds = this.intervalInMinutes * 60;
    }

    constructor() {
        this.domainEventInput = Object.keys(DomainEventInput)
            .filter(k => typeof DomainEventInput[k] === 'string')
            .map(n => ({ value: +n, name: ('enums.domainEventInput.' + n) }));
    }

    ngOnChanges(): void {
        this.intervalInMinutes = this.trigger.intervalInSeconds / 60;
    }

    sourceChanged(event) {
        this.onSourceChanged.emit(event);
    }
}
