import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, OnChanges } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-logger',
    templateUrl: 'logger.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoggerComponent implements OnInit, OnChanges {


    @Input() logItems = [];
    @Input() loading = false;

    showJson = false;

    // Pagination
    totalItems = 0;
    currentPage = 1;

    filter;
    permissions: {};

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
        this.permissions = this.authenticationService.permissions;
    }

    ngOnInit(): void {
        this.totalItems = this.logItems.length
        // console.log(this.logItems);
    }

    ngOnChanges(): void {
        // console.log(this.logItems);
    }
}

