export class Episode {
    startUtc: any;
    endUtc: any;
    date: number;
    conditions: any;
    deviation: any;
    locations: any[];
    driverId: any;
    roadPolygon: any;
    episodeStart: any;
    geoJson: any;
    episodeEnd: any;
    id: any;
    episodeType: any;
    locationEventType: any;
    count: any;
    maxValue: any;
    limitValue: any;
    description: any;
    beginLocationType: any;
    endLocationType: any;
    tripIdsList: any[];
    locationId: any;
    duration: string;
    footer: string;
    longitude: any;
    latitude: any;
    beginLongitude: any;
    beginLatitude: any;
    endLongitude: any;
    endLatitude: any;
    iconFontColor: string;
    iconUrl: string;
    iconBackgroundClass: string;
    markerColor: string;
    assetId: any;
    deviceId: any;
    geofenceId: any;
    icon: string;
    header: any;
    display: boolean;
    actualValueDescription: string;
    limitValueDescription: string;
    event: any;
    location: any;
    assetName: any;
    durationInSeconds: any;
    fkDeviceEpisodeTypeId: any;
}
