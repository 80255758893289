import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { DeviceFuelThreshold } from 'app/models/device.model';

@Component({
    selector: 'fh-fuel-edit-thresholds',
    templateUrl: 'fuelThreshold.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FuelThresholdEditComponent implements OnInit, OnChanges {

    @Input() fuelThresholds: DeviceFuelThreshold;
    @Input() formMode: any = FormMode.read;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
    }
}

