import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FhChartService } from 'app/services/charts/charts.service';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment-timezone';

import * as Highcharts from 'highcharts';

@Component({
    selector: 'fh-devices-connected',
    templateUrl: 'devicesConnected.component.html',
    viewProviders: [],
})
export class DevicesConnectedComponent implements OnInit, OnChanges {
    Highcharts: typeof Highcharts = Highcharts;

    @Input() loadingDeviceCount = false;
    @Input() data = [];

    chartDeviceCount: any;

    constructor(private chartService: FhChartService, private translateService: TranslateService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges() {
        const assets: any[] = [];

        this.data.forEach(item => {
            assets.push(({
                date: item['date'],
                assets: item['assets'],
            }));
        });

        this.generateChartDevicetypeCount(this.data.map((dta) => ({
            summaryDate: moment(dta.date).clone().startOf('day').toDate(),
            locationCount: (dta.assets),
        })));
    }

    generateChartDevicetypeCount(data) {

        const theActualData = [];
        const theCategories = [];
        let lastvalue = 0;

        data.forEach(value => {
            if (value.locationCount > 0 || theActualData.length > 0) {
                theActualData.push({ x: moment.utc(value.summaryDate).toDate().getTime(), y: value.locationCount });
                lastvalue = value.locationCount;
            }
        });

        if (data.length > 0) {
            theActualData.push({ x: moment.utc(new Date()).toDate().getTime(), y: lastvalue });

            const theData = [{
                name: this.translateService.instant('general.devices'),
                data: theActualData,
                dashStyle: 'dash',
                fillOpacity: 0.5,
                opacity: 1,
                marker: {
                    enabled: false,
                    lineWidth: 1,
                    symbol: 'square'
                },
            }];

            this.chartDeviceCount = this.chartService.generateColumnChartDates(theData, {}, theCategories, null, null, false);
        }
    }
}
