<ng-select ngDefaultControl #input ngDefaultControl id="select" [searchable]="true" [clearable]="false"
  [(ngModel)]="value" style="width: 100%;" class="form-control table-inline" [disabled]="disabled">
  <ng-container *ngFor="let timezone of allTimezones;">
    <optgroup *ngIf="timezone.zones.length > 1" [label]="iso2country(timezone.iso)">
      <ng-option *ngFor="let zone of timezone.zones" [value]="zone">
        {{ iso2country(timezone.iso) }} - {{ formatTimezoneString(zone) }}
        <span *ngIf="showOffset">{{offsetOfTimezone(zone)}}</span>
      </ng-option>
    </optgroup>

    <ng-option *ngIf="timezone.zones.length === 1" [value]="timezone.zones[0]">
      {{iso2country(timezone.iso)}}
      <span *ngIf="showOffset">{{offsetOfTimezone(timezone.zones[0])}}</span>
    </ng-option>

  </ng-container>
</ng-select>