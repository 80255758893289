import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const SERVER_VARS = async () => window['server_variables'] = environment;

window['paceOptions'] = {
  'ajax': false,
  'ghostTime': 11200,
  'minTime': 11200,
  restartOnRequestAfter: false,
}

if (environment.production) {
  enableProdMode();
}

const loadApplication = (() =>
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err)));

$.getJSON('./server_variables.json')
  .then((json) => {
    window['server_variables'] = json;
    window['server_variables']['Live'] = true;
    loadApplication();
  }).catch(() => {
    SERVER_VARS()
      .then(() => loadApplication());
  });
