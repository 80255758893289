<div *ngIf="isModalShown" [config]="{ show: true, backdrop: true, ignoreBackdropClick: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <div *ngIf="headerWrapper?.childNodes.length === 0">
                    <h5 class="modal-title pull-left"><i class="fa fa-trash-alt" style="margin-right: 5px"></i>
                        {{ title }}
                    </h5>
                </div>
                <div #headerWrapper>
                    <ng-content select="[header]"></ng-content>
                </div>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div *ngIf="bodyWrapper?.childNodes.length === 0" class="modal-body">
                {{ message | translate }}
            </div>
            <div #bodyWrapper>
                <ng-content select="[body]"></ng-content>
            </div>
            <div *ngIf="showButtons" class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="finish()" style="min-width: 120px;"
                    [disabled]="invalid"><i class="fa fa-save"></i>
                    {{ 'general.yes' | translate }}</button>
                <button type="button" class="btn btn-info" style="min-width: 120px;" (click)="decline()"><i
                        class="fa fa-times"></i>
                    {{ 'general.cancel' | translate }}</button>
            </div>
        </div>
    </div>
</div>