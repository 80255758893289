import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormMode } from 'app/common/enums';
import { AccountInventory } from 'app/models/account.model';
import { WebhookEndpoint } from 'app/models/webhookEndpoint.model';
import { AccountService } from 'app/services/account/account.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { WebhookEndpointService } from 'app/services/webhookendpoints/webhookEndpoint.service';

@Component({
    selector: 'fh-webhook-endpoints-add',
    templateUrl: 'addWebhookEndpoint.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebhookEndpointAddViewComponent implements OnInit, AfterViewInit {
    sub;

    loading = false;
    saving = false;

    error: any;
    success: any;

    formMode: FormMode = FormMode.add;

    webhookEndpoint: WebhookEndpoint;
    account: AccountInventory;

    constructor(private cd: ChangeDetectorRef, private webhookEndpointService: WebhookEndpointService, private accountService: AccountService, private route: ActivatedRoute, private router: Router, private translateService: TranslateService, private authenticationService: AuthenticationService) {
        this.account = null;

        this.webhookEndpoint = new WebhookEndpoint();
        this.webhookEndpoint.headers = [];
        this.cd.markForCheck();
    }

    ngOnInit() {
    }

    ngAfterViewInit() {

        this.cd.markForCheck();
    }

    onInsert() {
        this.saving = true;
        console.log('insert');
        this.webhookEndpointService.saveWebhookEndpoint(this.webhookEndpoint).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Webhook endpoint is successfully added.'
            };

            setTimeout(() => {
                this.router.navigate(['/System/WebhookEndpointDetails/Index/' + result.id]);
            }, 2000);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
        });
    }
}
