import {
    Component, AfterViewInit, Input, ElementRef, ViewChild, OnInit, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TrailerService } from 'app/services/trailer/trailer.service';

const noop = () => {
};

@Component({
    selector: 'fh-trailer-input',
    templateUrl: './trailerSelector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: TrailerInputComponent }
    ]
})

export class TrailerInputComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {

    @Input() placeholder: string;
    @Input() clearable = true;
    @Input() selectedAccountId;
    @Input() autoSelect = true;
    @Input() readonly = false;
    @Input() multiple = false;

    @Output() onChanged = new EventEmitter();

    trailers = []

    loadingTrailers = false;

    @ViewChild('input') el: ElementRef;

    private _value: string;
    private _onChange: (_: any) => void = noop;

    get value(): any {
        return this._value;
    };

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this._onChange(v);
            this.cd.markForCheck();
        }
    }

    constructor(private trailerService: TrailerService, private cd: ChangeDetectorRef) {
    }

    trailerChanged() {
        this.onChanged.emit(this.value);
        this.cd.markForCheck();
    }

    ngOnChanges() {
        setTimeout(() => {
            // Get accounts again
            this.loadTrailers();
            this.cd.markForCheck();
        }, 0);
    }

    loadTrailers() {

        this.trailers = [];
        this.cd.markForCheck();

        if (this.selectedAccountId) {

            this.loadingTrailers = true;
            this.cd.markForCheck();

            if (this.selectedAccountId && this.selectedAccountId !== 0) {
                this.trailerService.getTrailersByAccount(this.selectedAccountId).subscribe(result => {
                    this.trailers = result;

                    // Autoselect
                    if (!this.readonly && this.trailers.length === 1 && this.autoSelect) {
                        console.log('Setting default to ' + this.trailers[0].id);
                        this.value = this.trailers[0].id;
                        this.cd.markForCheck();
                    }

                    this.loadingTrailers = false;
                    this.cd.markForCheck();
                });
            } else {
                this.loadingTrailers = false;
                this.cd.markForCheck();
            }
        }
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        const __this = this;
    }

    writeValue(value: any) {
        this._value = value;
        this.cd.markForCheck();
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
        this.cd.markForCheck();
    }

    registerOnTouched(fn: any) {
        this.cd.markForCheck();
    }

}
