<div class="ibox-content slick" style="position:relative">
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!serviceAnnouncement">
            <tr>
                <td colspan="4">
                    {{ 'general.noServiceAnnounceMent' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="serviceAnnouncement">
            <tr>
                <td class="row_opening">
                    {{ 'general.resellerDescription' | translate}}
                </td>
                <td class="row_content" style="position:relative; overflow: visible">
                    <fh-reseller-input name="reseller" #resellerVar="ngModel" [readonly]="formMode == 1"
                        placeholder="{{ 'general.chooseReseller' | translate}}"
                        [(ngModel)]="serviceAnnouncement.resellerId">
                    </fh-reseller-input>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.severity' | translate}}
                    <span class="error" *ngIf="severity.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="position:relative; overflow: visible">
                    <ng-select class="form-control table-inline" name="severity" [disabled]="formMode == 1" required
                        placeholder="{{ 'general.severity' | translate }}" [(ngModel)]="serviceAnnouncement.severity"
                        #severity="ngModel" style="width:100%">
                        <ng-option value='success'>
                            {{ 'general.success' | translate}}
                        </ng-option>
                        <ng-option value='info'>
                            {{ 'general.info' | translate}}
                        </ng-option>
                        <ng-option value='warning'>
                            {{ 'general.warning' | translate}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.culture' | translate}}
                    <span class="error" *ngIf="culture.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="position:relative; overflow: visible">
                    <ng-select class="form-control table-inline" name="culture" [disabled]="formMode == 1" required
                        placeholder="{{ 'general.culture' | translate }}" [(ngModel)]="serviceAnnouncement.cultures"
                        #culture="ngModel" style="width:100%">
                        <ng-option value='nl-NL'>
                            nl-NL
                        </ng-option>
                        <ng-option value='en-US'>
                            en-US
                        </ng-option>
                        <ng-option value='ar-AE'>
                            ar-AE
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.validFrom' | translate}}
                    <span class="error" *ngIf="validFrom.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [disabled]="formMode == 1" name="validFrom" #validFrom="ngModel"
                        class="form-control table-inline" [(ngModel)]="serviceAnnouncement.validFrom" required
                        placeholder=" {{ 'placeholder.validFrom' | translate}}" [disabled]="formMode == 1"
                        autocomplete="off" [bsConfig]="dpConfig" bsDatepicker>
                    <span *ngIf="formMode != 1" class="input-group-calendar">
                        <i class="fa fa-calendar-alt"></i>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.validTill' | translate}}
                    <span class="error" *ngIf="validTill.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [disabled]="formMode == 1" name="validTill" #validTill="ngModel"
                        class="form-control table-inline" [(ngModel)]="serviceAnnouncement.validTill"
                        placeholder=" {{ 'placeholder.validFrom' | translate}}" [disabled]="formMode == 1"
                        autocomplete="off" [bsConfig]="dpConfig" bsDatepicker>
                    <span *ngIf="formMode != 1" class="input-group-calendar">
                        <i class="fa fa-calendar-alt"></i>
                    </span>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.message' | translate}}
                    <span class="error" *ngIf="message.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <textarea name="message" [readonly]="formMode == 1" name="message" class="noresize stickypad"
                        style="height:100px; width: 100%; resize: none;" id="encCss" #message="ngModel" required
                        placeholder="{{ 'placeholder.message' | translate}}"
                        [(ngModel)]="serviceAnnouncement.message"></textarea>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.details' | translate}}
                </td>
                <td class="row_content">
                    <textarea name="details" [readonly]="formMode == 1" name="details" class="noresize stickypad"
                        style="height:100px; width:100%; resize: none" id="encCss"
                        placeholder="{{ 'placeholder.details' | translate}}"
                        [(ngModel)]="serviceAnnouncement.details"></textarea>

                </td>
            </tr>
        </tbody>
    </table>
</div>