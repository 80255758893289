import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { WarehouseSensor } from 'app/models/warehouse.model';

@Component({
    selector: 'fh-warehouse-sensor-details',
    templateUrl: 'warehouseSensorDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class WarehouseSensorDetailsComponent implements OnInit {

    @Input() sensor: WarehouseSensor;
    @Input() loading = false;
    @Input() formMode: any = FormMode.read;

    constructor() {
    }

    ngOnInit(): void {
    }
}

