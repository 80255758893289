<fh-error [success]="success" [error]="error"></fh-error>

<div class="ibox" style="position:relative">
    <div class="ibox-title">
        <h5 *ngIf="showItem">
            <i class="fas fa-fw fa-list"></i> {{ name ? name : ('general.groups' | translate) }}
        </h5>
        <h5 *ngIf="!showItem">
            <i class="fas fa-fw fa-layer-group"></i> {{ name ? name : ('general.groups' | translate) }}
        </h5>
        <span class="pull-right">
            <div class="filter-form hidden-sm hidden-xs">
                <input type="text" class="form-control table-inline top-filter hidden-sm hidden-xs"
                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
            </div>
        </span>
    </div>
    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
        <ngx-loading [show]="loading"></ngx-loading>
        <table style="width: 100%;" class="table nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <th>{{'general.name' | translate }}</th>
                    <th *ngIf="showAccount && permissions['Accounts_View']">{{'general.account' | translate }}</th>
                    <th *ngIf="showCount">{{'general.count' | translate }}</th>
                    <th>{{'general.timestamp' | translate }}</th>
                    <th *ngIf="showItem">{{'general.startDate' | translate }}</th>
                    <th *ngIf="showItem">{{'general.endDate' | translate }}</th>
                    <th *ngIf="deletePermission && permissions[deletePermission] && formMode === 1 && childId > -1"
                        style="width:60px;">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="groups.length == 0">
                    <td colspan="4">{{'general.noGroupsFound' | translate }}</td>
                </tr>
                <tr
                    *ngFor="let group of groups | stringFilter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, id: 'groups' }">
                    <td>
                        <a href="/#/{{linkUrl}}/Index/{{ group.deviceId ? group.deviceId : group.id }}">
                            <span class='secondary link_bolder'><i class="fas fa-fw fa-angle-right"></i></span>
                            {{ group.displayName }}
                        </a>
                    </td>
                    <td *ngIf="showAccount && permissions['Accounts_View']"><a
                            href='/#/AccountDetails/Index/{{ group.accountId }}'>
                            <span class='secondary link_bolder'><i class="fas fa-fw fa-angle-right"></i></span>
                            {{ group.companyName }}</a>
                    </td>
                    <td *ngIf="showCount">{{group.itemCount}}</td>
                    <td style="max-width: 200px" title="{{ group.timestamp?.toLocaleString() }}">{{group.timestamp |
                        amDateFormat: 'll'}}</td>
                    <td *ngIf="showItem" style="max-width: 200px" title="{{ group.startDate?.toLocaleString() }}">
                        {{group.startDate |
                        amDateFormat: 'll'}}</td>
                    <td *ngIf="showItem" style="max-width: 200px" title="{{ group.endDate?.toLocaleString() }}">
                        {{group.endDate ?
                        (group.endDate |
                        amDateFormat: 'll') : '-'}}</td>
                    <ng-container *ngIf="deletePermission && permissions[deletePermission] && formMode === 1 && childId > -1">
                        <td *ngIf="!isSystemGroup(group); else none" style="width: 60px; text-align: right;">
                            <span [title]="'general.removeFromGroup' | translate" container="body">
                                <button class="btn btn-danger btn-grid"
                                    (click)="deleteItem(group, childId)">
                                    <i class="fas fa-fw fa-trash-alt"></i>
                                </button>
                            </span>
                        </td>
                        <ng-template #none>
                            <td>&nbsp;</td>
                        </ng-template>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="ibox-footer noselect dataTables_wrapper">
        <ng-content select="[groupAddSlot]"></ng-content>

        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="groups">
            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                <ul class="pagination">
                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{ 'grid.previous' |
                            translate }}</a>
                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                    </li>

                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                        [class.current]="p.getCurrent() === page.value">
                        <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                            <span>{{ page.label }}</span>
                        </a>
                        <a class="page-link" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() === page.value">
                            <span style="font-weight: bold;">{{ page.label }}</span>
                        </a>
                    </li>

                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                            {{ 'grid.next' | translate }}
                        </a>
                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }} </a>
                    </li>
                </ul>
            </div>
        </pagination-template>
        <div style="clear:both"></div>
    </div>
</div>