<div *ngIf="headers.length == 0 && processing == false" style="position:relative" style="padding:10px">
  <ngx-loading [show]="uploading"></ngx-loading>

  <div *ngIf="!useButton" class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
    (onFileDropped)="onFileChange($event)">
    <div style="text-align: center; margin-top: 10px; opacity: 0.6;"><i class="fas fa-fw fa-file-alt fa-4x"></i></div>
    <input hidden type="file" #fileInput (change)="onFileChange($event)" style="display: none;">
  </div>

  <div *ngIf="useButton">
    <label for="file-upload" class="btn btn-primary">
      <i class="fas fa-fw fa-file-alt"></i> {{'general.browseFile' | translate }}
    </label>
    <input id="file-upload" type="file" (change)="onFileChange($event)" multiple="false" style="display: none;" />
  </div>
</div>

<div *ngIf="headers.length > 0">
  <form #uploadForm="ngForm">
    <table class="table table-fixed nowrap dtr-inline no-footer" style="width: 100%;">
      <tbody>
        <tr *ngFor="let column of columns">
          <td class="row_opening">
            {{ ('fields.' + column.name) | translate }}
            <span class="error" *ngIf="column.isRequired && !column.mapping">*</span>
            <span *ngIf="!column.isRequired"> ({{'general.optional' | translate }})</span>
            <!-- && fieldVar[column.name].invalid" -->
          </td>
          <td class="row_content" style="overflow: visible">
            <ng-select [name]="column.name" placeholder="{{'general.chooseField' | translate }}"
              class="form-control table-inline" style="width:100%" ngDefaultControl [(ngModel)]="column.mapping"
              [required]="column.isRequired">
              <ng-option *ngFor="let header of headers; let i = index" [value]="header.id">
                [ {{ header.name }} ]
              </ng-option>
            </ng-select>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="padding:10px">
      <button id="submit" name="submit" type="button" class="btn btn-primary" (click)="post()"
        [disabled]="uploadForm.invalid || !uploadForm.dirty"> <i class="fas fa-fw fa-save"></i> {{
        'general.complete' | translate}}</button>
      <button id="cancel" name="cancel" type="button" class="btn btn-info" (click)="cancel()"> <i
          class="fas fa-fw fa-times"></i> {{ 'general.cancel' | translate}}</button>

      <!-- <span class="pull-right">
        <button name="saveMapping" class="btn btn-info" (click)="saveMapping()"
          title="{{ 'general.saveMapping' | translate}}"> <i class="fas fa-fw fa-save"></i> </button>
        <button name="loadMapping" class="btn btn-info" (click)="loadMapping()"
          title="{{ 'general.loadMapping' | translate}}"> <i class="fas fa-fw fa-file"></i> </button>
      </span> -->
    </div>
  </form>
</div>