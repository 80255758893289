import { Component, Input, OnChanges } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';


@Component({
    selector: 'fh-permission-handler',
    templateUrl: 'permissionHandler.template.html',
})
export class PermissionHandlerComponent implements OnChanges {

    @Input() permissionName;

    permissions = {};
    showContent = false;

    constructor(private autheticationService: AuthenticationService) {
        this.permissions = this.autheticationService.permissions;
    }

    ngOnChanges(): void {
        this.showContent = this.permissions[this.permissionName];
    }
}
