import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormMode } from 'app/common/enums';
import { AccountInventory } from 'app/models/account.model';
import { Reseller } from 'app/models/reseller.model';
import { UserRole } from 'app/models/userRole.model';
import { AccountService } from 'app/services/account/account.service';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ResellerService } from 'app/services/resellers/resellers.service';

@Component({
    selector: 'fh-user-role-details',
    templateUrl: 'userRoleDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class UserRoleDetailsComponent implements OnInit {
    @Input() userRole: UserRole;
    @Input() formMode: any = FormMode.read;
    @Output() onAccountChanged = new EventEmitter();
    @Output() onResellerChanged = new EventEmitter();

    accounts: AccountInventory[] = [];
    resellers: Reseller[] = [];
    permissions: {};
    isImpersonated: boolean;
    loadingAccounts = false;
    loadingResellers = false;

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private accountService: AccountService, private resellerService: ResellerService, private translate: TranslateService) {
        this.permissions = this.authenticationService.permissions;
        this.isImpersonated = this.authenticationService.getIsImpersonated();
    }

    ngOnInit(): void {
    }

    accountChanged(accountId) {
        this.onAccountChanged.emit(this.userRole.accountId);
    }

    resellerChanged(resellerId) {
        this.userRole.accountId = null;

        this.onResellerChanged.emit(this.userRole.resellerId);
    }
}
