import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormMode, UserContactType } from 'app/common/enums';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { AssetType } from 'app/models/assetType.model';
import { UserContact } from 'app/models/userContact.model';
import { UserContactService } from 'app/services/users/userContacts.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fh-user-contact-add',
  templateUrl: 'add.template.html'
})
export class UserContactAddViewComponent {
  token: string;
  loading = false;
  userContact = new UserContact;

  formMode: FormMode = FormMode.add;
  formModeAssetTypes: FormMode = FormMode.read;

  error: any;
  permissions: {};
  success: { statusText: string; success: string; };
  assetTypes: AssetType[];

  permissionName = 'UserContacts_Add';

  constructor(private translateServe: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private router: Router, private userContactService: UserContactService) {
    this.permissions = this.authenticationService.permissions;
    this.token = this.authenticationService.getAuthToken();
  }

  onInsert() {
    this.loading = true;

    if (this.userContact.userContactType === undefined) {
      this.userContact.userContactType = UserContactType.NormalContact;
    }

    this.userContactService.saveUserContact(this.userContact).subscribe(result => {

      this.success = {
        statusText: 'Success',
        success: this.translateServe.instant('general.saveSuccessRedirect', { entity: 'userContact' })
      };

      setTimeout(() => {
        this.router.navigate(['/Users/UserContactDetails/Index/' + result.id]);
      }, 2000);

      this.loading = false;
    }, error => {
      this.error = error
      this.loading = false;
    });
  }

  accountChanged(accountId) {
    console.log('Changed accountId');
    this.userContact.accountId = accountId;

    this.cd.markForCheck();
  }
}
