import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'app/models/device.model';
import { DeviceService } from '../../services/device/device.service';
import { FormMode } from 'app/common/enums';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-device-settings',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'deviceSettings.template.html'
})
export class DeviceSettingsViewComponent implements OnInit {
    sub;
    device: Device;
    deviceId;
    form = new FormGroup({});

    formMode = FormMode.read;
    error: any;
    success: any;

    loading = false;
    saving = false;

    permissions = {};
    permissionName = 'FleetManagement_DeviceSettingsView';

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private http: HttpClient, private deviceService: DeviceService, private route: ActivatedRoute, private router: Router) {
        this.device = null;
        this.permissions = this.authenticationService.permissions;
    }

    setFormMode(mode) {
        this.formMode = mode;

        this.getDeviceInfo();
    }

    onDelete() {
        this.error = 'Deleting is not implemented';
    }

    onSave() {
        this.device.settings.sensorCalibrationPoints.forEach((sensorCalibrationPoint) => {
            sensorCalibrationPoint.deviceId = +this.device.id;
        });

        this.form.value['sensorCalibrationPoints'] = this.device.settings.sensorCalibrationPoints;

        // only use this var in batch mode
        this.form.value['updatedParams'] = null;

        this.saving = true;
        this.deviceService.saveSettings(this.form.value).subscribe(result => {
            this.saving = false;

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Devicesettings are successfully updated.'
            };

            this.cd.markForCheck();

            this.setFormMode(FormMode.read)
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    getDeviceInfo() {
        this.deviceService.getDeviceById(this.device.id).subscribe(device => {
            this.device = device;
            this.cd.markForCheck();

            if (this.device == null) {
                this.router.navigate(['/Devices/Overview'])
            }
        });
    }

    ngOnInit() {

        this.device = new Device;
        this.device.id = ''
        this.loading = true;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.deviceId = id;
            this.deviceService.getDeviceById(id).subscribe(device => {
                this.device = device;
                this.loading = false;
                this.cd.markForCheck();

                if (this.device == null) {
                    this.router.navigate(['/Devices/Overview'])
                }
            });
        }, error => {
            this.error = error;
            this.error.statusText = 'Error fetching device';

            setTimeout(() => {
                this.router.navigate(['/Devices/Overview']);
            }, 3000);
        });
    }
}
