<div class="ibox-content slick" style="position:relative">
    <ngx-loading [show]="loadingUserRoles || loading"></ngx-loading>
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!user">
            <tr>
                <td colspan="4">
                    {{ 'general.noUser' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="user">
            <ng-container *ngIf="!smallMode">
                <ng-container *ngIf="formMode != 2">
                    <tr [hidden]="!permissions['Resellers_View']">
                        <td class="row_opening">
                            {{ 'general.resellerDescription' | translate}}
                        </td>
                        <td class="padding-readonly-correct row_content">
                            <a class="secondary link_bolder"
                                href="/#/ResellerDetails/Index/{{user?.resellerId}}">{{user?.resellerDescription
                                }}</a>
                        </td>
                    </tr>
                    <tr [hidden]="!permissions['Accounts_View']">
                        <td class="row_opening">
                            {{ 'general.companyName' | translate}}
                        </td>
                        <td class="row_content padding-readonly-correct">
                            <a class="secondary link_bolder"
                                href="/#/AccountDetails/Index/{{user?.accountId}}">{{user?.companyName }}</a>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="formMode == 2">
                    <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                        <td class="row_opening">
                            {{ 'general.reseller' | translate}}
                            <span class="error" *ngIf="resellerVar.invalid">*</span>
                        </td>
                        <td class="row_content" style="position:relative; overflow: visible">
                            <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                (onChanged)="resellerChanged($event)" required [tableInline]="true"
                                placeholder="{{ 'general.chooseReseller' | translate}}"
                                [(ngModel)]="selectedResellerId">
                            </fh-reseller-input>
                        </td>
                    </tr>
                    <tr [hidden]="!permissions['Accounts_View'] && user.accountId">
                        <td class="row_opening">
                            {{ 'general.account' | translate}}
                            <span class="error" *ngIf="accountVar.invalid">*</span>
                        </td>
                        <td class="row_content" style="position:relative; overflow: visible">
                            <fh-account-input name="account" #accountVar="ngModel" (onChanged)="accountChanged()"
                                required [selectedResellerId]="selectedResellerId" [tableInline]="true"
                                placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                [(ngModel)]="user.accountId">
                            </fh-account-input>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="formMode == 1 && !profileMode">
                <tr>
                    <td class="row_opening">
                        {{ 'general.linkedDriver' | translate}}
                    </td>
                    <td class="padding-readonly-correct row_content">
                        <a *ngIf="user?.driverId" class="secondary link_bolder"
                            href="/#/DriverDetails/Index/{{user?.driverId}}"><i class="fas fa-fw fa-id-card-alt"></i>
                            {{user?.driverName }}</a>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="formMode != 2 && formMode != 1 && !profileMode">
                <tr>
                    <td class="row_opening">
                        {{ 'general.linkedDriver' | translate}}
                    </td>
                    <td class="row_content" style="overflow: visible">
                        <fh-driver-input name="driver" #driverVar="ngModel" [selectedAccountId]="user.accountId"
                            [autoSelect]=false [readonly]="formMode == 1"
                            placeholder="{{ (user.accountId? 'general.chooseDriver' : 'general.firstChooseAccount') | translate}}"
                            [(ngModel)]="user.driverId">
                        </fh-driver-input>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="row_opening">
                    {{ 'general.userNameEmail' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <fh-copyinput [hide]="formMode != 1">
                        <input body type="text"
                            [readonly]="(formMode == 3 && !permissions['Platform_IsReseller']) || formMode == 1"
                            name="name" placeholder="{{ 'placeholder.userName' | translate}}" autocomplete="off"
                            class="form-control table-inline" [(ngModel)]="user.name" required minlength="4"
                            #name="ngModel">
                    </fh-copyinput>
                </td>
            </tr>
            <ng-container *ngIf="formMode == 2">
                <tr>
                    <td class="row_opening">
                        {{ 'general.password' | translate}}
                        <!-- <span class="error" *ngIf="password.invalid">*</span> -->
                    </td>
                    <td class="row_content">
                        <fh-password-input name="password" autocomplete="off" [(ngModel)]="user.password" required
                            minlength="4" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!%?#.&])[\S]{8,}$"
                            placeholder="{{ 'placeholder.newPassword' | translate}}" #password="ngModel"
                            behaviour="click">
                        </fh-password-input>
                    </td>
                </tr>
                <tr>
                    <td class="row_opening">
                        {{ 'general.repeatPassword' | translate}}
                        <span class="error" *ngIf="password2.invalid">*</span>
                    </td>
                    <td class="row_content">
                        <input type="password" name="password2" class="form-control table-inline" autocomplete="off"
                            placeholder="{{ 'placeholder.repeatPassword' | translate}}"
                            [(ngModel)]="passwordConfirmation" required minlength="4" #password2="ngModel">
                    </td>
                </tr>
                <tr class="alert alert-danger"
                    *ngIf="(password.value?.length > 0 && password2.value?.length > 0 && password.value !== password2.value || (password?.invalid && (password?.dirty || password?.touched)))">
                    <td colspan="2">
                        <div *ngIf="password.value !== password2.value">
                            * Passwords do not match
                        </div>
                        <div *ngIf="password?.errors?.minlength">
                            * Password must be at least 4 characters long.
                        </div>
                        <div *ngIf="password.errors?.pattern">
                            * Requirements are not ok<br />
                            (Eight characters, Uppercase letter, Lowercase letter, Special
                            char, Number)
                        </div>
                        <div *ngIf="password?.errors?.required">
                            * Password is required
                        </div>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="row_opening">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="(firstName.invalid || lastName.invalid) && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <span style="width:50%; float: left"><input type="text" [readonly]="formMode == 1" required
                            autocomplete="off" name="firstName" class="form-control table-inline"
                            placeholder="{{ 'placeholder.firstName' | translate}}" [(ngModel)]="user.firstName"
                            #firstName="ngModel"></span>
                    <span style="width:50%; float: left; padding-left: 10px"> <input type="text"
                            [readonly]="formMode == 1" name="plate" class="form-control table-inline" autocomplete="off"
                            placeholder="{{ 'placeholder.lastName' | translate}}" [(ngModel)]="user.lastName" required
                            #lastName="ngModel"></span>
                </td>
            </tr>
            <tr *ngIf="!smallMode">
                <td class="row_opening">
                    {{ 'general.language' | translate}}
                    <span class="error" *ngIf="language.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select placeholder="{{ 'general.chooseUserContactGroup' | translate}}" name="language"
                        [disabled]="formMode == 1" [(ngModel)]="user.language" required [searchable]="true"
                        [clearable]="false" class="form-control table-inline"
                        placeholder="{{ 'settings.chooselanguage' | translate}}" #language="ngModel" style="width:100%">
                        <ng-option *ngFor="let language of languages" [value]="language.name">
                            {{language.name}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr *ngIf="!smallMode">
                <td class="row_opening">
                    {{ 'general.whitelabel' | translate}}
                    <span class="error" *ngIf="whitelabel.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select placeholder="{{ 'general.chooseUserContactGroup' | translate}}" name="whitelabel"
                        [disabled]="!permissions['Platform_IsReseller'] || formMode == 1" [searchable]="true"
                        [clearable]="false" [(ngModel)]="user.userType" class="form-control table-inline"
                        #whitelabel="ngModel" required style="width:100%">
                        <ng-option *ngFor="let userType of userTypes" [value]="userType.value">
                            {{userType.name}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr *ngIf="!smallMode">
                <td class="row_opening">
                    {{ 'dates.timezone' | translate}}
                    <span class="error" *ngIf="timezoneVar.invalid">*</span>
                </td>
                <td class="row_content" style="overflow:visible">
                    <fh-timezone-picker name="timeZoneSelector" [disabled]="formMode == 1"
                        [timezone]="user.timezoneIana" required #timezoneVar="ngModel" [(ngModel)]="user.timezoneIana">
                    </fh-timezone-picker>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.assetDisplayName' | translate}}
                    <span class="error" *ngIf="assetDisplayName.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select placeholder="{{ 'general.chooseUserContactGroup' | translate}}" name="assetDisplayName"
                        [disabled]="formMode == 1" [(ngModel)]="user.assetDisplayName" [searchable]="true"
                        [clearable]="false" required class="form-control table-inline" #assetDisplayName="ngModel"
                        style="width:100%">
                        <ng-option *ngFor="let displayName of displayNameOptions" [value]="displayName.value">
                            {{ 'enums.displayNameOptions.' + displayName.value | translate }}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr *ngIf="!smallMode">
                <td class="row_opening">
                    {{ 'general.distanceUnit' | translate}}
                    <span class="error" *ngIf="distanceUnit.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select placeholder="{{ 'general.chooseUserContactGroup' | translate}}" name="distanceUnit"
                        [disabled]="formMode == 1" [(ngModel)]="user.distanceUnit" required [searchable]="true"
                        [clearable]="false" class="form-control table-inline" #distanceUnit="ngModel"
                        style="width:100%">
                        <ng-option *ngFor="let unit of distanceUnits" [value]="unit.value">
                            {{unit.name}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr *ngIf="!smallMode">
                <td class="row_opening">
                    {{ 'general.volumeUnit' | translate}}
                    <span class="error" *ngIf="volumeUnit.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select placeholder="{{ 'general.ChooseVolumeUnit' | translate}}" name="volumeUnit"
                        [disabled]="formMode == 1" [(ngModel)]="user.volumeUnit" required [searchable]="true"
                        [clearable]="false" class="form-control table-inline" #volumeUnit="ngModel" style="width:100%">
                        <ng-option *ngFor="let unit of volumeUnits" [value]="unit.value">
                            {{unit.name}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr *ngIf="!profileMode">
                <td class="row_opening">
                    {{ 'general.userRole' | translate}}
                    <span class="error" *ngIf="userRole.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select [loading]="loadingUserRoles" name="userRoleId" ngDefaultControl [searchable]="false"
                        [clearable]="false" placeholder="{{ 'general.chooseUserRole' | translate}}" required
                        [(ngModel)]="user.userRoleId" #userRole="ngModel" [disabled]="formMode == 1"
                        class="form-control table-inline" style="width:100%" (ngModelChange)="userRoleChanged()">
                        <ng-option *ngFor="let userRole of userRoles| orderBy : 'name':false" [value]="userRole.id">
                            {{ userRole.name }}</ng-option>
                    </ng-select>
                </td>
            </tr>
            <tr [hidden]="formMode == 2" *ngIf="!profileMode && user.userRoleId">
                <td class="row_opening">
                    {{ 'general.clientPermissions' | translate}}
                    <span class="error" *ngIf="clientPermission.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="overflow: visible">
                    <ng-select name="clientPermissionId" ngDefaultControl required [(ngModel)]="user.clientPermissionId"
                        #clientPermission="ngModel" [searchable]="false" [clearable]="false"
                        [disabled]="formMode == 1 || (user.clientPermissionId == -1)" class="form-control table-inline"
                        style="width:100%" (ngModelChange)="userRoleChanged()">
                        <ng-option *ngIf="user.clientPermissionId !== -1" [value]="user.clientPermissionId"> {{
                            'general.useBackofficePermissions' |translate}}
                        </ng-option>
                        <ng-option [value]="-1"> {{ 'general.useFleetManagementPermissions' | translate}}
                        </ng-option>
                    </ng-select>
                </td>
            </tr>
        </tbody>
    </table>
</div>