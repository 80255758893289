import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormMode } from 'app/common/enums';
import { Asset } from 'app/models/asset.model';
import { EntityType } from 'app/common/enums';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { WaslService } from 'app/services/wasl/wasl.service';
// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';

import { UserService } from 'app/services/users/user.service';
import { AppUser } from 'app/models/user.model';
import { FhChartService } from 'app/services/charts/charts.service';
import { AssetTypeService } from 'app/services/asset/assetTypes.service';
import { AssetType } from 'app/models/assetType.model';

window['moment'] = Moment;
mTZ()


@Component({
    providers: [FhChartService],
    selector: 'fh-fh-asset-type-details',
    templateUrl: 'typeDetails.template.html'
})
export class AssetTypeDetailsViewComponent implements OnInit {
    sub: any;
    assetType: AssetType;
    device: any;
    tagType = EntityType.AssetType.valueOf();
    formMode = FormMode.read;

    loading = false;
    saving = false;
    loadingAssetWasl = false;

    filter;

    error: any;
    success: any;

    permissions: {};

    users: AppUser[];

    loadingDeviceCount = false;
    chartDeviceCount: any;

    // Pagination
    totalItems = 0;
    currentPage = 1;
    smallnumPages = 0;
    deviceCountData = [];
    distanceChartData = [];
    loadingCount = false;

    permissionName = 'AssetTypes_View';

    constructor(private router: Router, private assetTypeService: AssetTypeService, private cd: ChangeDetectorRef, private authentication: AuthenticationService, private assetTypesService: AssetTypeService, private route: ActivatedRoute, private userService: UserService) {
        this.permissions = this.authentication.permissions;
    }

    ngOnInit() {

        this.loading = true;
        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.getAssetTypeInfo(id);
        });
    }


    // Form

    onDelete() {
        this.loading = true;

        this.assetTypesService.deleteAssetType(this.assetType).subscribe(result => {
            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Asset type is successfully deleted.'
            };


            setTimeout(() => {
                this.router.navigate(['/Settings/AssetTypes/Overview']);
            }, 3000);
        }, error => {
            this.success = null;
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    onSave() {
        this.saving = true;

        this.assetTypesService.updateAssetType(this.assetType).subscribe(result => {

            this.error = null;
            this.success = {
                statusText: 'Success',
                success: 'Asset type is successfully updated.'
            };

            this.saving = false;
            this.cd.markForCheck();

            this.setFormMode(FormMode.read);
        }, error => {
            this.saving = false;
            this.success = null;
            this.error = error;
            this.cd.markForCheck();
        });
    }

    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getAssetTypeInfo();
        }
    }


    getAssetTypeInfo(id = 0) {
        this.loading = true;
        this.cd.markForCheck();

        if (id === 0) {
            id = this.assetType.id;
        }

        this.assetTypesService.getAssetTypeById(id.toString()).subscribe(assetType => {
            this.assetType = assetType;

            this.loading = false;
            this.cd.markForCheck();
        }, error => {
            this.success = null;
            this.error = error;
            this.error.statusText = 'Error fetching assetType';
            this.cd.markForCheck();

            setTimeout(() => {
                this.router.navigate(['/Settings/AssetTypes/Overview']);
            }, 3000);
        });
    }
}
