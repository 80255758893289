<div *ngIf="permissions['FleetManagement_Actions']">
    <ngx-loading [show]="saving"></ngx-loading>
    <!-- Custom content -->
    <ng-content>
    </ng-content>

    <div dropdown style="position: relative" [isDisabled]="gridSelection.length == 0">
        <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle"
            [ngClass]="gridSelection.length > 0 ? 'btn-primary' : 'btn-info'" aria-controls="dropdown-basic"
            style="min-width: 100px; margin:0px">
            <i class="fa fa-fw fa-bolt"></i> ({{ gridSelection.length }}) <span class="caret"></span>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-fix d dropdown-menu-right" role="menu"
            aria-labelledby="button-basic" style="min-width: 200px">
            <li [ngClass]="action.disabled ? 'disabled' : ''" role="menuitem"
                *ngFor="let action of batchActions | orderBy : 'name':false">
                <a class="dropdown-item" href="javascript:void(0)"
                    (click)="!action.disabled ? showModal(action) : null"> <i class="fas fa-fw  "
                        [ngClass]="action.icon ? action.icon : 'fa-angle-right'" style="padding-right:5px"></i>
                    {{('enums.batchAction.' + action.batchActionType) | translate}}
                </a>
            </li>
        </ul>
    </div>
</div>

<div style="display: none;" id="selectorHolder">
    <i id="grid_select_or" class="far fa-fw fa-lg " (click)="handleSelection()" [ngClass]="{ 'fa-square': gridSelection.length == 0, 
'fa-check-square' : gridSelection.length == tableIdsSelection.length && gridSelection.length > 0, 
'fa-minus-square' : gridSelection.length != tableIdsSelection.length && gridSelection.length > 0 }"
        style="cursor: pointer;"></i>
</div>

<div (onHidden)="hideModal()" bsModal [config]="{ backdrop: true, ignoreBackdropClick: true }" #batchModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title pull-left">
                    <i class="fa fa-fw fa-bolt"></i>
                    {{'general.bulkAction' | translate }}: {{('enums.batchAction.' + selectedAction?.batchActionType) |
                    translate }}
                </h5>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="selectedAction == null">
                {{'general.defaultBatchDescription' | translate }}
            </div>
            <div class="popup-content" #contentWrapper>
                <!-- Archive -->
                <fh-batch-archive-assets *ngIf="selectedAction && selectedAction.batchActionType == 1"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-archive-assets>

                <!-- Un-archive -->
                <fh-batch-unarchive-assets *ngIf="selectedAction && selectedAction.batchActionType == 2"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-unarchive-assets>

                <!-- Add logitem -->
                <fh-batch-add-log *ngIf="selectedAction && selectedAction.batchActionType == 3"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-add-log>

                <!-- get locations -->
                <fh-batch-get-location *ngIf="selectedAction && selectedAction.batchActionType == 4"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-get-location>

                <!-- Add to group -->
                <fh-batch-group-add *ngIf="selectedAction && selectedAction.batchActionType === 5"
                    [gridSelection]="gridSelection" [batchEntityType]="entityTypes" (onFinish)="process($event)">
                </fh-batch-group-add>

                <!-- Add to client -->
                <fh-batch-activate-device [gridSelection]="gridSelection"
                    *ngIf="selectedAction && selectedAction.batchActionType == 6" (onFinish)="process($event)">
                </fh-batch-activate-device>

                <!-- Update fields -->
                <fh-batch-update-fields [entityTypes]="entityTypes" [gridSelection]="gridSelection"
                    *ngIf="selectedAction && selectedAction.batchActionType == 7" (onFinish)="process($event)">
                </fh-batch-update-fields>

                <!-- Update devicesettings -->
                <fh-batch-device-settings [gridSelection]="gridSelection"
                    *ngIf="selectedAction && selectedAction.batchActionType == 8" (onFinish)="process($event)">
                </fh-batch-device-settings>

                <!-- Update devicesensors -->
                <fh-batch-device-sensors [gridSelection]="gridSelection"
                    *ngIf="selectedAction && selectedAction.batchActionType == 19" (onFinish)="process($event)">
                </fh-batch-device-sensors>

                <!-- delete -->
                <fh-batch-delete *ngIf="selectedAction && selectedAction.batchActionType == 9"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-delete>

                <!-- Apply devicesettingchanges -->
                <fh-batch-devicesettingchanges [gridSelection]="gridSelection"
                    *ngIf="selectedAction && selectedAction.batchActionType == 10" (onFinish)="process($event)">
                </fh-batch-devicesettingchanges>

                <!-- Change Reseller -->
                <fh-batch-change-inventory [gridSelection]="gridSelection" [gridSelection]="gridSelection"
                    *ngIf="selectedAction && selectedAction.batchActionType == 11" (onFinish)="process($event)">
                </fh-batch-change-inventory>

                <!-- Move to Client -->
                <fh-batch-move-to-client *ngIf="selectedAction && selectedAction.batchActionType == 12"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-move-to-client>

                <!-- Replace device -->
                <fh-batch-replace-device *ngIf="selectedAction && selectedAction.batchActionType == 13"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-replace-device>

                <!-- Remove from account -->
                <fh-batch-remove-from-account *ngIf="selectedAction && selectedAction.batchActionType == 14"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-remove-from-account>

                <!-- Register WASL -->
                <fh-batch-register-wasl *ngIf="selectedAction && selectedAction.batchActionType == 15"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-register-wasl>

                <!-- UnRegister WASL -->
                <fh-batch-unregister-wasl *ngIf="selectedAction && selectedAction.batchActionType == 16"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-unregister-wasl>

                <!-- Delete device history -->
                <fh-batch-delete-history *ngIf="selectedAction && selectedAction.batchActionType == 17"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-delete-history>

                <!-- Reset device -->
                <fh-batch-reset-device *ngIf="selectedAction && selectedAction.batchActionType == 18"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-reset-device>

                <!-- Virtual sensors -->
                <fh-batch-virtualsensors-device *ngIf="selectedAction && selectedAction.batchActionType == 21"
                    [gridSelection]="gridSelection" (onFinish)="process($event)">
                </fh-batch-virtualsensors-device>
            </div>
        </div>
    </div>
</div>