import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getGridButtons, getGridLanguages, createSearch, setTableStars, setSelection, setSelectionRows, createdCellCheckbox } from 'app/common/gridhelper';

import { TrailerService } from 'app/services/trailer/trailer.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';

import { Observable } from 'rxjs';
import { BatchStatus, EntityType } from 'app/common/enums';
import { ActivatedRoute } from '@angular/router';
import { GridBase360Directive } from 'app/common/360Grid.base';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { StorageHelper } from 'app/common/storagehelper';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-trailers',
  templateUrl: 'trailers.template.html'
})
export class TrailersViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;

  loading = false;
  languageLoaded: boolean;
  permissions: {};

  showActiveOnly = true;

  permissionName = 'Trailers_View';
  constructorName = 'TrailersViewComponent';

  error: any;
  success: any;

  entityTypes = EntityType.Trailer;
  timezoneIana: string;

  constructor(private route: ActivatedRoute, private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private trailerService: TrailerService, protected storageHelper: StorageHelper) {
    super(storageHelper);

    this.permissions = this.authenticationService.permissions;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    // Get all the date for dropdown boxes
    Observable.forkJoin(
      this.translateService.get('general.date')
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;

        this.initGrid();
      },
      err => console.error(err)
    );
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log('Fire update');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

        if (this.showActiveOnly === true) {
          dtInstance.column('isActive:name').search('true');
        } else {
          dtInstance.column('isActive:name').search('@ignore');
        }
        // Fire the request
        dtInstance.draw();
      });
    }
  }

  ngOnInit() {
    this.permissions = this.authenticationService.permissions;

    this.translateService.get('general.date').subscribe(value => {
      this.languageLoaded = true;
      this.initGrid();
    });
  }

  // Check custom filters from grid save
  checkFilters() {

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.showActiveOnly = dtInstance.column('isActive:name').search() !== '@ignore';
    });

    this.fireFilter(1);
  }

  // Check route to apply filters
  checkRoute() {
    this.route.queryParams.subscribe(params => {
      const companyName = params['companyName'];
      const resellerDescription = params['resellerDescription'];
      let requireDraw = false;

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        if (companyName) {
          dtInstance.column('resellerDescription:name').search('');
          dtInstance.column('companyName:name').search(companyName);
          dtInstance.column('name:name').search('');
          requireDraw = true;
        }
        if (resellerDescription) {
          dtInstance.column('resellerDescription:name').search(resellerDescription);
          dtInstance.column('companyName:name').search('');
          dtInstance.column('name:name').search('');
          requireDraw = true;
        }
        // Fire the request
        if (requireDraw) {
          dtInstance.draw();
        }
      });
    });
  }

  initGrid(): void {
    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    this.columns = [
      {
        name: 'id',
        data: 'id',
        className: 'noVis',
        orderable: false,
        title: '<div class="hideDropdown"></div>',
        width: '20',
        render: function (data, type, row) {
          return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/TrailerDetails/Index/' + data + '\'><span class="hidden-sm hidden-xs" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
        }
      }, {
        name: 'id_export',
        data: 'id',
        className: 'noVis',
        title: this.translateService.instant('general.id'),
        visible: false,
      },
      {
        name: 'name',
        data: 'name',
        defaultContent: '-',
        title: this.translateService.instant('general.name')
      },
      {
        name: 'erpCode',
        data: 'erpCode',
        defaultContent: '-',
        visible: false,
        title: this.translateService.instant('general.erpCode'),
      }, {
        name: 'companyName',
        data: 'companyName',
        defaultContent: '-',
        title: this.translateService.instant('general.companyName')
      }, {
        name: 'accountId',
        data: 'accountId',
        defaultContent: '-',
        title: this.translateService.instant('general.accountId'),
        visible: false
      }, {
        name: 'resellerDescription',
        data: 'resellerDescription',
        defaultContent: '-',
        title: this.translateService.instant('general.resellerDescription'),
        visible: false
      },
      {
        name: 'timeStamp',
        data: 'timestamp',
        type: 'date',
        title: this.translateService.instant('general.timeStamp'),
        render: function (data, type, row) {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
        },
        visible: true
      },
      {
        name: 'activeAssignments',
        data: 'activeAssignments',
        type: 'num',
        defaultContent: '-',
        title: this.translateService.instant('general.activeAssignments')
      }, {
        name: 'isArchived',
        data: 'isArchived',
        title: this.translateService.instant('general.isArchived'),
        type: 'checkBox',
        visible: true,
        createdCell: createdCellCheckbox,
        width: '40',
      }];

    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'trailers_overview', this.translateService.instant('menu.trailersoverview')),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 500,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 2 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => { try {
          const columnSettings = await that.loadState(that.constructorName);
          that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
          return columnSettings;
        } catch (e) {
          that.error = {};
          that.error.error = e;
          that.error.statusText = 'Error fetching column settings';

          return null;
        }})().then(result => {
          callback(result);
        });
      },
      order: [[2, 'asc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.trailerService.getPagingUrl(),
        data: (d) => {
          return d;
        },
        dataSrc: function (json) {
          // if selection and returning keys
          return json.data;
        },
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        that.checkRoute();
        that.checkFilters();
        that.setEvents();
        that.drawFilterRow();
        $('#grid_select_or').appendTo($('th > #selectorHeader'));
        that.loading = false;
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'id_export', 'isSelected', 'form']
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService),
    };
  }

  // batch processing
  onClose(event) {
    console.log('Closing batch popup');

    this.success = null;
    this.error = null;

    if (event && event.status === BatchStatus.Success) {
      console.log('success');

      this.success = {};
      this.success.statusText = 'Success';
      this.success.success = 'Your batch was accepted and scheduled to be proccesed';
      this.success.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
    if (event && event.status === BatchStatus.Error) {
      this.error = {};
      this.error.statusText = 'Error';
      this.error.error = 'Error in processing the batch';
      this.error.unique = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      this.cd.markForCheck();
    }
  }
}
