<ng-select [loading]="loadingDriverGroups" ngDefaultControl [searchable]="true" [clearable]="clearable"
    style="height: unset" [disabled]="driverGroups.length == 0" [readonly]="readonly" [virtualScroll]="true"
    placeholder="{{placeholder}} ({{driverGroups.length}})" #input [multiple]="multiple" [(ngModel)]="value"
    [searchFn]="searchFn" [items]="driverGroups" bindLabel="driverName" bindValue="id" class="form-control"
    groupBy="driverGroupItems" [selectableGroup]="true" [groupValue]="groupValueFn" [closeOnSelect]="false"
    [ngClass]="tableInline === true ? 'table-inline' : '' "  (ngModelChange)="driverGroupChanged()">
    <ng-template ng-label-tmp let-drivergroup="item" let-clear="clear">
        <span *ngIf="drivergroup.displayName">
            <i class="fas fa-fw fa-layer-group"
                title="Group: {{drivergroup.displayName}} {{drivergroup.length}}"></i> {{
            drivergroup.displayName
            }} ({{drivergroup.length}})</span>
        <span *ngIf="drivergroup.driverName">
            <i class="fa fa-fw fa-draw-polygon" title=" Driver: {{drivergroup.driverName}}"></i> {{
            drivergroup.driverName }} </span>
        <span class="ng-value-icon right" (click)="clear(drivergroup)" aria-hidden="true">×</span>
    </ng-template>
    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <i class='far fa-fw fa-lg' [ngClass]="item$.selected ? 'fa-check-square' : 'fa-square'"></i>
        {{item.displayName}}
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <i class="fa fa-fw fa-id-card-alt" title=" Driver: {{item.driverName}}"></i> {{item.driverName}}
    </ng-template>
</ng-select>
