import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Schedule } from 'app/models/commutingSchedule.model';
import { ScheduleAssignment, TriggerSchedule } from 'app/models/triggerSchedule.model';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
window['moment'] = Moment;
mTZ()

@Injectable()
export class TriggerScheduleService {
    base_url = '';
    url = '';
    timezoneIana: string;

    constructor(private http: HttpClient, private loggingService: LoggingService, private authenticationService: AuthenticationService, private translateService: TranslateService) {
        this.base_url = this.authenticationService.getWebserviceURL('');
        this.url = this.authenticationService.getWebserviceURL('triggerSchedule');
        this.timezoneIana = this.authenticationService.getTimeZoneIana();
    }

    getPagingUrl() {
        return this.url + `Paging`;
    }

    getTriggerSchedulesByAccount(accountId: string): Observable<TriggerSchedule[]> {
        return this.http.get(this.base_url + 'Account/' + accountId + '/TriggerSchedules', { headers: this.authenticationService.headers })
            .map((data: any) => {
                return this.parseResponse(data);
            })
            .catch(this.handleError);
    }

    getTriggerScheduleById(triggerScheduleId: any): Observable<any> {
        return this.http.get(this.url + triggerScheduleId, { headers: this.authenticationService.headers })
            .map((data: any) => {
                return this.parseReponseDetails(data);
            })
            .catch(this.handleError);
    }

    deleteTriggerSchedule(triggerSchedule: TriggerSchedule) {
        console.log(this.url + triggerSchedule.id)
        return this.http.delete(this.url + triggerSchedule.id, { headers: this.authenticationService.headers })
            .catch(this.handleError);

    }

    saveTriggerSchedule(triggerSchedule: TriggerSchedule): Observable<any> {
        return this.http.post(this.url, triggerSchedule, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    updateTriggerSchedule(triggerSchedule: any) {
        return this.http.put(this.url + triggerSchedule.id, triggerSchedule, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    getScheduleAssignmentsByAssetId(assetId: any) {
        return this.http.get(this.base_url + 'Asset/' + assetId + '/ScheduleAssignments', { headers: this.authenticationService.headers })
            .map((data: any) => {
                return this.parseScheduleAssignments(data);
            })
            .catch(this.handleError);
    }

    getScheduleAssignmentsByAssetGroupId(assetGroupId: any) {
        return this.http.get(this.base_url + 'AssetGroup/' + assetGroupId + '/ScheduleAssignments', { headers: this.authenticationService.headers })
            .map((data: any) => {
                return this.parseScheduleAssignments(data);
            })
            .catch(this.handleError);
    }

    getScheduleAssignmentsByProjectId(projectId: any) {
        return this.http.get(this.base_url + 'Project/' + projectId + '/ScheduleAssignments', { headers: this.authenticationService.headers })
            .map((data: any) => {
                return this.parseScheduleAssignments(data);
            })
            .catch(this.handleError);
    }

    getScheduleAssignmentsByAccountId(accountId: any) {
        return this.http.get(this.base_url + 'Account/' + accountId + '/ScheduleAssignments', { headers: this.authenticationService.headers })
            .map((data: any) => {
                return this.parseScheduleAssignments(data);
            })
            .catch(this.handleError);
    }

    assignSchedule(scheduleAssignment: ScheduleAssignment) {
        return this.http.post(this.url + scheduleAssignment.triggerScheduleId + '/ScheduleAssignments', scheduleAssignment, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    deleteScheduleAssignmentById(scheduleAssignmentId: any, triggerScheduleId: any) {
        return this.http.delete(this.url + triggerScheduleId + '/ScheduleAssignments/' + scheduleAssignmentId, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    private handleError(error: Response) {
        console.log(error);
        return throwError(error);
    }

    parseResponse(json: any) {
        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' TriggerSchedules.');

        const triggerSchedules: TriggerSchedule[] = [];

        json.forEach(item => {
            const triggerSchedule = this.parseReponseDetails(item);
            triggerSchedules.push(triggerSchedule);
        });

        return triggerSchedules;
    }

    parseReponseDetails(item) {
        const triggerSchedule = new TriggerSchedule();
        triggerSchedule.id = item.id;
        triggerSchedule.accountId = item.accountId;
        triggerSchedule.companyName = item.companyName;
        triggerSchedule.resellerId = item.resellerId;
        triggerSchedule.resellerDescription = item.resellerDescription;
        triggerSchedule.triggerScheduleName = item.triggerScheduleName;
        triggerSchedule.timezoneIana = item.timezoneIana;
        triggerSchedule.schedule = [];
        triggerSchedule.markAsPrivate = item.markAsPrivate;
        triggerSchedule.isAccountDefault = item.isAccountDefault;

        item.schedule.forEach(element => {
            const schedule = this.parseScheduleDetails(element);
            triggerSchedule.schedule.push(schedule);
        })

        return triggerSchedule;
    }

    parseScheduleAssignments(json: any) {
        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' ScheduleAssignments.');

        const scheduleAssignments: ScheduleAssignment[] = [];

        json.forEach(item => {
            const scheduleAssignment = this.parseScheduleAssignmentDetails(item);
            scheduleAssignments.push(scheduleAssignment);
        });

        return scheduleAssignments;
    }

    parseScheduleAssignmentDetails(item) {
        const scheduleAssignment = new ScheduleAssignment();
        scheduleAssignment.id = item.id;
        scheduleAssignment.triggerScheduleId = item.triggerScheduleId;
        scheduleAssignment.triggerScheduleName = item.triggerScheduleName;
        scheduleAssignment.assetId = item.assetId;
        scheduleAssignment.assetgroupId = item.assetgroupId;
        scheduleAssignment.projectId = item.projectId;
        scheduleAssignment.accountId = item.accountId;
        scheduleAssignment.startDate = item.startDate === undefined ? undefined : Moment.utc(item.startDate)['tz'](this.timezoneIana).toDate();
        scheduleAssignment.endDate = item.endDate === undefined ? undefined : Moment.utc(item.endDate)['tz'](this.timezoneIana).toDate();
        scheduleAssignment.applyScheduleToTrip = item.applyScheduleToTrip;

        return scheduleAssignment;
    }

    parseScheduleDetails(data) {
        const schedule = new Schedule(data.day, data.commuting, data.startTime, data.endTime);
        return schedule;
    }

    public determineScheduleDuration(item) {
        let workingHours = 0;

        item.schedule?.forEach(element => {
            if (element.commuting) {
                const timeStart = new Date('01/01/2007 ' + element.startTime).getTime();
                const timeEnd = new Date('01/01/2007 ' + element.endTime).getTime();

                const hourDiff = (timeEnd - timeStart) / 1000 / 3600;
                workingHours += hourDiff;
            }
        });

        return workingHours;
    }
}
