<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Devices/Overview">{{'menu.add' | translate }}</a>
            </li>
        </ol>
    </div>
    <fh-device-navigation></fh-device-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-6">
                <fh-error [error]="error" [success]="success"></fh-error>
                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fas fa-fw fa-microchip"></i> {{'general.device' | translate }}
                            </h5>
                        </div>
                        <fh-device-details [device]="device" [formMode]="formMode" [loading]="loading">
                        </fh-device-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()" [formMode]="formMode"
                            [allowSave]="permissions['Devices_Add']">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">
                <fh-group-overview [loading]="loadingGroups" [groups]="assetGroups" [showCount]="true">
                </fh-group-overview>
            </div>
        </div>
    </div>
</fh-permission-handler>