import { Component, Input } from '@angular/core';
import { Device } from 'app/models/device.model';
import { FormMode } from 'app/common/enums';

@Component({
    selector: 'fh-device-details-driver',
    templateUrl: 'devicesDetailsDriver.template.html',
})
export class DeviceDetailsDriverComponent {
    @Input() loading = false;
    @Input() device: Device;

    constructor() {
    }
}

