<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li class="active">{{'menu.dashboard' | translate }}
            </li>
        </ol>
        <span class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix hidden-sm hidden-xs" title="Help"
                href="https://help.360locate.com/knowledge-base/dashboard/" target="_blank"><i class="fa fa-fa fa-book"
                    style="margin-right: 6px"></i> <span class="hidden-sm hidden-xs">{{'general.needHelp' |
                    translate }}</span></a>
        </span>
    </div>
    <fh-dashboard-navigation></fh-dashboard-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error"></fh-error>
            </div>
        </div>

        <div class="row" *ngIf="announcements.length > 0">
            <div class="col-lg-12">
                <ng-container *ngFor="let announcement of announcements">
                    <div *ngIf="announcement.culture == culture || announcement.type == 2" class="alert shadow"
                        [ngClass]="'alert-' + announcement.severity">
                        <span>
                            <p *ngIf="announcement.type == 1" style="font-size:15px"><strong>
                                    <i class="fa fa-fw fa-bullhorn fa-fw"></i>
                                    {{ 'general.serviceAnnouncement' | translate }} </strong></p>

                            <p *ngIf="announcement.type == 2" style="font-size:15px"><strong>
                                    <i class="fa fa-fw fa-exclamation-triangle"></i>
                                    {{ 'general.clientAnnouncement' | translate }} </strong></p>
                        </span>
                        <span>{{ announcement.message }}</span>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="permissions['Devices_View'] ? ['/Devices/Overview/'] : []"
                    title="{{'general.activedevices_hover' | translate }}">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-microchip"></i> {{'general.devices' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <h1 class="margin-bottom font-bold">{{activeDevices?.toLocaleString()}}
                        </h1>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="permissions['Accounts_View'] ? ['/Accounts/Overview/']: []">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-building"></i> {{'general.accounts' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <h1 class="margin-bottom font-bold">{{activeCustomers?.toLocaleString()}}
                        </h1>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="permissions['Users_View'] ? ['/Users/Overview/']: []">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-users"></i> {{'general.users' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <h1 class="margin-bottom font-bold">{{activeUsers?.toLocaleString()}}
                        </h1>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="permissions['Drivers_View'] ? ['/Drivers/Overview/']: []">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-id-card-alt"></i> {{'general.drivers' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <h1 class="margin-bottom font-bold">{{activeDrivers?.toLocaleString()}}
                        </h1>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="permissions['Geofences_View'] ? ['/Geofences/Overview/']: []">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-draw-polygon"></i> {{'general.geofences' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <h1 class="margin-bottom font-bold">{{activeGeofences?.toLocaleString()}}
                        </h1>
                    </div>
                </div>
            </div>
            <div *ngIf="permissions['Warehouses_View']" class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="['/Warehouses/Overview/']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-warehouse"></i> {{'general.warehouses' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <h1 class="margin-bottom font-bold">{{activeWarehouses?.toLocaleString()}}
                        </h1>
                    </div>
                </div>
            </div>
            <div *ngIf="!permissions['Warehouses_View']" class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="permissions['Tags_View'] ? ['/Tags/Overview/']: []">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-tags"></i> {{'general.tags' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <h1 class="margin-bottom font-bold">{{activeTags?.toLocaleString()}}
                        </h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="permissions['Platform_IsReseller'] && accountDeviceCount.length > 1">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5 [routerLink]="['/Accounts/Overview/']">
                            <i class="fas fa-fw fa-building"></i> {{'general.deviceCountByAccount' | translate
                            }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 224px; position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>

                        <highcharts-chart [runOutsideAngular]="true" style="display: block; width: 100%; height:214px"
                            [Highcharts]="Highcharts" [options]="chartClients">
                        </highcharts-chart>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="permissions['Platform_IsReseller']">
            <div class="col-lg-4">
                <div class="ibox">
                    <div class="ibox-title">
                        <span style="float: left; width: calc(100% - 30px); overflow:hidden">
                            <h5>
                                <i class="fas fa-fw fa-microchip"></i> {{'general.devicesWithTopUsage' | translate
                                }}

                                <i tooltip="{{'general.devicesWithTopUsageToolTip' | translate }}"
                                    class="fas fa-fw fa-question"></i>
                            </h5>
                        </span>
                        <span class="pull-right" style="width:30px">
                            <i class="hand fa-duotone fa-lg fa-fw " [tooltip]="'Show based on events or locationcount'"
                                title="Disable updating"
                                [ngClass]="{'fa-toggle-off': showEvents === true,'fa-toggle-on': showEvents === false}"
                                (click)="showEvents = !showEvents"></i>
                        </span>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 210px; position: relative">
                        <ngx-loading [show]="loadingTopUsage"></ngx-loading>

                        <highcharts-chart [runOutsideAngular]="true" *ngIf="!showEvents && chartTopUsage"
                            style="display: block; width: 100%;height:200px" [Highcharts]="Highcharts"
                            [options]="chartTopUsage"></highcharts-chart>
                        <highcharts-chart [runOutsideAngular]="true" *ngIf="showEvents && chartTopUsage"
                            style="display: block; width: 100%;height:200px" [Highcharts]="Highcharts"
                            [options]="chartTopEvents"></highcharts-chart>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-router"></i> {{'general.devicetypes' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 210px; position: relative">
                        <ngx-loading [show]="loadingDeviceTypes"></ngx-loading>

                        <highcharts-chart *ngIf="chartDeviceTypes" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:200px" [Highcharts]="Highcharts"
                            [options]="chartDeviceTypes">
                        </highcharts-chart>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <fh-devices-connected [loadingDeviceCount]="loadingDeviceCount" [data]="deviceCountData">
                </fh-devices-connected>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-lg-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-comment-alt-dots"></i> {{'general.locationCount' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 210px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart *ngIf="chartLocationCount" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:200px" [Highcharts]="Highcharts"
                            [options]="chartLocationCount">
                        </highcharts-chart>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-route"></i> {{'general.distanceperday' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 210px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart *ngIf="chartDistance" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:200px" [Highcharts]="Highcharts"
                            [options]="chartDistance">
                        </highcharts-chart>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-location-arrow"></i> {{'general.triggerCount' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 210px; position: relative">
                        <ngx-loading [show]="loadingTriggerCount"></ngx-loading>

                        <highcharts-chart *ngIf="chartTriggerCount" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:200px" [Highcharts]="Highcharts"
                            [options]="chartTriggerCount">
                        </highcharts-chart>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-car"></i> {{'general.communicatingAssets' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slicker" style="min-height: 210px; position: relative">
                        <ngx-loading [show]="loadingCount"></ngx-loading>

                        <highcharts-chart *ngIf="chartActiveDevices" [runOutsideAngular]="true"
                            style="display: block; width: 100%; height:200px" [Highcharts]="Highcharts"
                            [options]="chartActiveDevices">
                        </highcharts-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>