import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Driver } from 'app/models/driver.model';
import { DriverService } from 'app/services/driver/driver.service';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { FormMode } from 'app/common/enums';
// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { WaslService } from 'app/services/wasl/wasl.service';

@Component({
    selector: 'fh-driver-wasl',
    templateUrl: 'wasl.template.html'
})
export class DriverWaslViewComponent implements OnInit {
    sub;
    loading = false;

    error: any;
    warning: any;
    success: any;

    formMode = FormMode.read;
    driver: Driver;
    driverId: any;

    constructor(private cd: ChangeDetectorRef, private waslService: WaslService, private driverService: DriverService, private route: ActivatedRoute, private router: Router) {
        this.driver = null;
    }

    ngOnInit() {
        this.loading = true;
        this.driver = new Driver;
        this.driver.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.driverId = id;
            this.driverService.getDriverById(id).subscribe(driver => {
                this.driver = driver;


                this.loading = false;
                if (this.driver == null) {
                    this.router.navigate(['/Drivers/Overview'])
                }
            });
        });
    }

    onSave() {
    }

    getAccountInfo() {
        if (this.loading !== true) {
            this.loading = true;
        }

        this.driverService.getDriverById(this.driver.id).subscribe(driver => {
            this.driver = driver;
            this.loading = false;
        });
    }

    // Wasl
    setFormMode(mode) {
        this.formMode = mode;

        if (this.formMode === FormMode.read) {
            this.loading = true;
            this.getAccountInfo();
        }
    }

    onInsert() {
    }

    register(company) {
        this.loading = true;

        this.waslService.registerDriver(this.driver.id, this.driver.properties.wasl, company).subscribe(registerResult => {

            this.loading = false;
            if (registerResult.isSuccess) {
                this.success = {
                    statusText: 'Success',
                    success: registerResult.message
                };
                this.error = null;
            } else if (registerResult.warning) {
                this.warning = {
                    statusText: 'Warning',
                    success: registerResult.message
                };
                this.error = null;
            } else {
                this.error = {
                    statusText: 'Error',
                    error: registerResult.message
                };
            }
            this.getAccountInfo();
        }, error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }

    unRegister(company) {
        this.loading = true;

        this.waslService.unregisterDriver(this.driver.id, company).subscribe(unregisterResult => {
            console.log(unregisterResult);
            this.loading = false;
            if (unregisterResult.isSuccess) {
                this.success = {
                    statusText: 'Success',
                    success: unregisterResult.message
                };
                this.error = null;
            } else {
                this.error = {
                    statusText: 'Error',
                    error: unregisterResult.message
                };
            }
            this.getAccountInfo();
        }, error => {
            console.log(error);
            this.error = error;
            this.loading = false;
            this.cd.markForCheck();
        });
    }
}
