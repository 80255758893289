<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li class="active">
                <a href="/#/Map">{{'menu.map' | translate }}</a>
            </li>
        </ol>

        <div class="hidden-xs" style="margin-left: auto; margin-right: 10px;">
            {{ statusText }}
            {{ previousLookupTimestamp | amDateFormat: 'LLL' }}
            <i *ngIf="loading" style="margin-left: 3px;" class="fa fa-spinner fa-spin fa-fw"></i>
        </div>
    </div>
    <div style="clear:both"></div>
    <div style="padding: 7px 8px 7px 8px;">
        <div class="col-sm-3 col-xs-12">
            <div class="form-group input-group" style="margin-bottom: 5px;">
                <span class="input-group-addon">
                    <i class="fa fa-fw fa-search"></i>
                </span>
                <input style="width:100%" type="text" min="0" class="form-control"
                    placeholder="{{ 'general.searchOnDeviceOrImei' | translate}}" (input)="filterMarkers(true)"
                    [(ngModel)]="search">
            </div>
        </div>
        <div class="col-sm-3  hidden-xs">
            <div class="form-group" style="margin-bottom: 6px;">
                <fh-asset-group-input name="assetGroups" #assetGroupVar="ngModel" required
                    [selectedAccountId]="selectedAccountId" [tableInline]="false" (onChanged)="filterMarkers(true)"
                    [multiple]="false" [autoSelect]="false"
                    placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                    [(ngModel)]="selectedAssetGroups">
                </fh-asset-group-input>
            </div>
        </div>
        <div class="col-sm-3 hidden-xs">
            <div class="form-group input-group" style="margin-bottom: 5px;">
                <span class="input-group-addon">
                    <i class="fa fa-fw fa-map-marker-alt"></i>
                </span>
                <ng-select class="form-control" name="displayEvents" (change)="filterMarkers(true)"
                    placeholder="{{ 'general.deviceState' | translate }}" [(ngModel)]="status" style="width:100%">
                    <ng-option [value]=0>
                        <i class="fa fa-fw fa-map-marker-alt eye-active black"></i>
                        {{ 'general.deviceState_0' | translate}} ({{getDeviceCount(0)}})
                    </ng-option>
                    <ng-option [value]=1>
                        <i class="fa fa-fw fa-map-marker-alt eye-active green"></i>
                        {{ 'general.deviceState_1' | translate}} ({{getDeviceCount(1)}})
                    </ng-option>
                    <ng-option [value]=2>
                        <i class="fa fa-fw fa-map-marker-alt eye-active red"></i>
                        {{ 'general.deviceState_2' | translate}} ({{getDeviceCount(2)}})
                    </ng-option>
                    <ng-option [value]=3>
                        <i class="fa fa-fw fa-map-marker-alt eye-active orange"></i>
                        {{ 'general.deviceState_3' | translate}} ({{getDeviceCount(3)}})
                    </ng-option>
                    <ng-option [value]=4>
                        <i class="fa fa-fw fa-map-marker-alt eye-active blue"></i>
                        {{ 'general.deviceState_4' | translate}} ({{getDeviceCount(4)}})
                    </ng-option>
                    <ng-option [value]=5>
                        <i class="fa fa-fw fa-map-marker-alt eye-active black"></i>
                        {{ 'general.deviceState_5' | translate}} ({{getDeviceCount(5)}})
                    </ng-option>
                    <ng-option [value]=6>
                        <i class="fa fa-fw fa-location-crosshairs-slash eye-active violet"></i>
                        {{ 'general.deviceState_6' | translate}} ({{getDeviceCount(6)}})
                    </ng-option>
                </ng-select>
            </div>
        </div>

        <div class="col-sm-3 hidden-xs">
            <div class="form-group input-group" style="margin-bottom: 5px;">
                <span class="input-group-addon">
                    <i class="fa fa-fw fa-router"></i>
                </span>
                <ng-select class="form-control" name="displayEvents" (change)="filterMarkers(true)"
                    placeholder="{{ 'general.chooseDeviceType' | translate }}  ({{ deviceTypeOptions.length }})"
                    [loading]="loadingDeviceTypes" [(ngModel)]="deviceType" style="width:100%">
                    <ng-option *ngFor="let deviceType of deviceTypeOptions" [value]="deviceType.id">
                        {{ deviceType.value }}</ng-option>
                </ng-select>
            </div>
        </div>
        <div style="clear: both"></div>
    </div>
    <div class="col-lg-12" style="position: absolute; top: 200px;z-index: 2000;width: 50%;">
        <fh-error [error]="error"></fh-error>
    </div>
</div>

<div id="mapFullContainerWithFilter" class="shadow-inner">
    <ngx-loading [show]="loading"></ngx-loading>
    <div id="map" style="height: 100%; width:calc(100% - 6px);" leaflet [leafletOptions]="options"
        (leafletMapReady)="onMapReady($event)">
    </div>

    <div [@slideInOut]="menuState" class="slideMenu sidebar-bg">
        <div (click)="toggleMenu()" class="slideMenuControl">
        </div>
        <div class="slideMenuDetails white-bg fontColor" style="position:relative">
            <ngx-loading [show]="loadingSidebar"></ngx-loading>
            <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                <tbody>
                    <tr>
                        <td class="row_opening">
                            {{ 'general.name' | translate}}
                        </td>
                        <td class="row_content">
                            <a class="secondary link_bolder" href="/#/DeviceDetails/Index/{{selectedDevice?.id}}">{{
                                selectedDevice?.asset?.name }}</a>
                        </td>
                        <td class="row_content"><img style="position: absolute; right: 20px;"
                                [src]="getIcon(selectedDevice?.asset?.icon)"></td>
                    </tr>
                    <tr>
                        <td class="row_opening">
                            {{ 'general.lastCommunication' | translate}}
                        </td>
                        <td class="row_content" colspan="2"
                            title="{{selectedDevice?.theMarker?.data?.deviceState?.communicationState?.updateTimestamp}}">
                            {{ selectedDevice?.theMarker?.data?.deviceState?.communicationState?.updateTimestamp |
                            amTimeAgo }}
                        </td>
                    </tr>
                    <tr *ngIf="permissions['Entities_DeviceTypes']">
                        <td class="row_opening">
                            {{ 'general.modelId' | translate}}
                        </td>
                        <td class="row_content" colspan="2">
                            <a class="secondary link_bolder"
                                href="/#/DeviceTypeDetails/Index/{{selectedDevice?.deviceTypeId}}">{{
                                selectedDevice?.deviceTypeNameFull
                                }}</a>
                        </td>
                    </tr>
                    <tr>
                        <td class="row_opening">
                            {{ 'general.unitId' | translate}}
                        </td>
                        <td class="row_content" colspan="2">
                            {{ selectedDevice?.unitId }}
                        </td>
                    </tr>
                    <tr *ngIf="selectedDevice?.asset?.currentAddress">
                        <td class="row_opening">
                            {{ 'general.near' | translate}}
                        </td>
                        <td class="row_content" colspan="2"
                            title="{{ selectedDevice?.asset?.currentAddress?.address }}, {{ selectedDevice?.asset?.currentAddress?.city }}, {{ selectedDevice?.asset?.currentAddress?.country }}">
                            {{ selectedDevice?.asset?.currentAddress?.address }}, {{
                            selectedDevice?.asset?.currentAddress?.city }},
                            {{ selectedDevice?.asset?.currentAddress?.country }}
                        </td>
                    </tr>
                    <tr *ngIf="selectedDeviceExtended?.driver && permissions['Drivers_View']">
                        <td class="row_opening">
                            {{ 'general.driver' | translate}}
                        </td>
                        <td class="row_content" colspan="2">
                            <a class="secondary link_bolder"
                                href="/#/DriverDetails/Index/{{selectedDeviceExtended?.driver?.id }}"> {{
                                selectedDeviceExtended?.driver?.name }}</a>
                        </td>
                    </tr>
                    <tr *ngIf="permissions['Accounts_View']">
                        <td class="row_opening">
                            {{ 'general.customer' | translate}}
                        </td>
                        <td class="row_content" colspan="2">
                            <a class="secondary link_bolder"
                                href="/#/AccountDetails/Index/{{selectedDevice?.accountId}}">{{
                                selectedDevice?.companyName }}</a>
                        </td>
                    </tr>
                    <tr *ngIf="permissions['AssetGroups_View']">
                        <td colspan="3">
                            <span *ngFor="let group of selectedDeviceExtended?.asset?.assetGroups">
                                <a class="btn btn-info btn-grid" href="/#/AssetGroupDetails/Index/{{group?.id}}"><i
                                        class="fas fa-fw fa-layer-group"></i> {{group?.name
                                    }}</a>
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="!showAll">
                        <td class="row_content" colspan="3">
                            <span class="pull-right"
                                tooltip="{{ 'general.location' | translate}} / {{ 'general.utilizationLastWeek' | translate}}">

                                <div class="fa_checkbox fa_toggle">
                                    <input [checked]="true" id="showUtilization" type="checkbox" name="showUtilization"
                                        (click)="flipUtilization()" />
                                    <label for="showUtilization"></label>
                                </div>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="" *ngIf="showUtilization || showAll">
                <div class="col-xs-6" style="padding:0">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            {{ selectedDeviceUtilization?.segmentsDistance ?
                            selectedDeviceUtilization?.segmentsDistance.toLocaleString() : '-' }} km
                        </h3>
                        <div>{{'general.distance' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            {{ selectedDeviceUtilization?.tripDurationInSeconds ?
                            actualRoundSeconds(selectedDeviceUtilization?.tripDurationInSeconds) : '-'
                            }}
                        </h3>
                        <div>{{'general.duration' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            {{ selectedDeviceUtilization?.idlingDurationInSeconds ?
                            actualRoundSeconds(selectedDeviceUtilization?.idlingDurationInSeconds) :
                            '-'
                            }}
                        </h3>
                        <div>{{'general.idling' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <a class='secondary link_bolder' (click)="$event.stopPropagation();"
                                href="/#/DeviceDetails/Trips/{{selectedDevice?.id}}"> {{
                                selectedDeviceUtilization?.tripCount ?
                                selectedDeviceUtilization?.tripCount : '-' }}</a>
                        </h3>
                        <div>{{'general.trips' | translate }}</div>
                    </div>
                </div>

                <div class="col-xs-6" style="padding:0">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            {{ selectedDeviceUtilization?.accelCount ?
                            selectedDeviceUtilization?.accelCount : '-' }}
                        </h3>
                        <div>{{'general.acceleration' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            {{ selectedDeviceUtilization?.decelCount ?
                            selectedDeviceUtilization?.decelCount : '-' }}
                        </h3>
                        <div>{{'general.braking' | translate }}</div>
                    </div>
                </div>
                <!-- <div class="col-xs-6" style="padding:0">
                                    <div class="kpi-item kpi-item-small">
                                        <h3 class="margin-bottom font-bold">
                                            {{ selectedDeviceUtilization?.corneringCount ?
                                            selectedDeviceUtilization?.corneringCount : '-' }}
                                        </h3>
                                        <div>{{'general.cornering' | translate }}</div>
                                    </div>
                                </div> -->
                <div class="col-xs-6" style="padding:0">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            {{ selectedDeviceUtilization?.roadSpeedingCount ?
                            selectedDeviceUtilization?.roadSpeedingCount : '-' }}
                        </h3>
                        <div>{{'general.speeding' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            {{ selectedDeviceUtilization?.maxSpeed ?
                            selectedDeviceUtilization?.maxSpeed : '-' }}
                        </h3>
                        <div>{{'general.maxSpeed' | translate }}</div>
                    </div>
                </div>
            </div>


            <div class="" *ngIf="!showUtilization || showAll">
                <div class="col-xs-6" style="padding:0">
                    <div class="kpi-item kpi-item-small border-active "
                        [ngClass]="selectedDevice?.theMarker?.data?.deviceState?.markerColor">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-map-marker-alt eye-active "
                                [ngClass]="selectedDevice?.theMarker?.data?.deviceState?.markerColor"></i>
                            {{ ('general.deviceState_' +
                            selectedDevice?.theMarker?.data?.deviceState?.calculatedDeviceState?.deviceState) |
                            translate }}
                        </h3>
                        <div
                            title="{{selectedDevice?.theMarker?.data?.deviceState?.calculatedDeviceState?.updateTimestamp}}">
                            {{'general.stateSince' | translate }}
                            {{selectedDevice?.theMarker?.data?.deviceState?.calculatedDeviceState?.updateTimestamp |
                            amTimeAgo:true}}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            {{ selectedDevice?.theMarker?.data?.deviceState?.currentPosition?.speed ?
                            selectedDevice?.theMarker?.data?.deviceState?.currentPosition?.speed : '-' }} km/h
                        </h3>
                        <div>{{'general.speed' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            {{ selectedDevice?.theMarker?.data?.deviceState?.currentPosition?.heading ?
                            selectedDevice?.theMarker?.data?.deviceState?.currentPosition?.heading :
                            '-' }} °</h3>
                        <div>{{'general.heading' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0"
                    *ngIf="selectedDevice?.theMarker?.data?.deviceState?.externalBattery?.batteryLevelInVoltage">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-car-battery" style="opacity: 0.3;"></i> {{
                            selectedDevice?.theMarker?.data?.deviceState?.externalBattery?.batteryLevelInVoltage ?
                            selectedDevice?.theMarker?.data?.deviceState?.externalBattery?.batteryLevelInVoltage.toLocaleString()
                            : '-' }} V
                        </h3>
                        <div>{{'general.battery' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0"
                    *ngIf="selectedDevice?.theMarker?.data?.deviceState?.temperature1">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-thermometer-three-quarters" style="opacity: 0.3;"></i> {{
                            selectedDevice?.theMarker?.data?.deviceState?.temperature1?.temperatureInCelcius ?
                            selectedDevice?.theMarker?.data?.deviceState?.temperature1?.temperatureInCelcius : '-' }} °C
                        </h3>
                        <div>{{'general.temperature' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0"
                    *ngIf="selectedDevice?.theMarker?.data?.deviceState?.temperature2">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-thermometer-three-quarters" style="opacity: 0.3;"></i> {{
                            selectedDevice?.theMarker?.data?.deviceState?.temperature2?.temperatureInCelcius ?
                            selectedDevice?.theMarker?.data?.deviceState?.temperature2?.temperatureInCelcius : '-' }} °C
                        </h3>
                        <div>{{'general.temperature' | translate }}</div>
                    </div>
                </div>

                <div class="col-xs-6" style="padding:0" *ngIf="selectedDevice?.theMarker?.data?.deviceState?.humidity1">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-humidity" style="opacity: 0.3;"></i> {{
                            selectedDevice?.theMarker?.data?.deviceState?.humidity1?.humidityInPercent ?
                            selectedDevice?.theMarker?.data?.deviceState?.humidity1?.humidityInPercent : '-' }}%
                        </h3>
                        <div>{{'general.humidity' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0"
                    *ngIf="selectedDevice?.theMarker?.data?.deviceState?.totalAxleWeight">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-weight" style="opacity: 0.3;"></i> {{
                            selectedDevice?.theMarker?.data?.deviceState?.totalAxleWeight?.totalWeightInKg ?
                            selectedDevice?.theMarker?.data?.deviceState?.totalAxleWeight?.totalWeightInKg.toLocaleString()
                            : '-' }} kg
                        </h3>
                        <div>{{'general.weight' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            {{ (selectedDevice?.theMarker?.data?.deviceState?.odometer?.gpsOdometer !== null)
                            ? (actualRound(( selectedDeviceExtended?.asset?.calibrationOdoOffsetInKm ?? 0) +
                            (selectedDevice?.theMarker?.data?.deviceState?.odometer?.gpsOdometer / 1000),
                            0)).toLocaleString() : '-' }} km
                        </h3>
                        <div>{{'general.odo' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0"
                    *ngIf="selectedDevice?.theMarker?.data?.deviceState?.currentPosition?.radiusInMeters">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            {{ selectedDevice?.theMarker?.data?.deviceState?.currentPosition?.radiusInMeters ?
                            selectedDevice?.theMarker?.data?.deviceState?.currentPosition?.radiusInMeters : '-' }} m
                        </h3>
                        <div>{{'general.radiusInMeters' | translate }}</div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0"
                    *ngIf="selectedDeviceExtended?.asset?.driver && permissions['Drivers_View']">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <a class="secondary link_bolder"
                                href="/#/DriverDetails/Index/{{selectedDeviceExtended?.asset?.driver?.id }}">
                                <i class="fa fa-fw fa-id-card-alt" style="opacity: 0.8;"></i>
                                {{
                                selectedDeviceExtended?.asset?.driver?.name }}</a>
                        </h3>
                        <div>{{'general.driver' | translate }}</div>
                    </div>
                </div>
            </div>

            <div class="">
                <div class="col-xs-6" style="padding:0"
                    *ngIf=" selectedDevice?.theMarker?.data?.deviceState?.temperatureInCelcius || selectedDevice?.theMarker?.data?.deviceState?.lastKnownTemperatureInCelcius">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-thermometer-three-quarters" style="opacity: 0.3;"></i>
                            <ng-container
                                *ngIf="selectedDevice?.theMarker?.data?.deviceState?.temperatureInCelcius else temperatureLastKnown">
                                {{
                                selectedDevice?.theMarker?.data?.deviceState?.temperatureInCelcius.toLocaleString()
                                }}
                            </ng-container>
                            <ng-template #temperatureLastKnown>
                                {{
                                selectedDevice?.theMarker?.data?.deviceState?.lastKnownTemperatureInCelcius.toLocaleString()
                                }}
                            </ng-template>
                            °C
                        </h3>
                        <div>{{'general.temperature' | translate }} 1 <i
                                *ngIf="!selectedDevice?.theMarker?.data?.deviceState?.temperatureInCelcius"
                                class="fa fa-fw fa-history"
                                title="{{ selectedDevice?.theMarker?.data?.deviceState?.lastKnownTemperatureRtcBasedTimestamp }}"></i>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0"
                    *ngIf=" selectedDevice?.theMarker?.data?.deviceState?.temperature2InCelcius || selectedDevice?.theMarker?.data?.deviceState?.lastKnownTemperature2InCelcius">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-thermometer-three-quarters" style="opacity: 0.3;"></i>
                            <ng-container
                                *ngIf="selectedDevice?.theMarker?.data?.deviceState?.temperature2InCelcius else temperature2LastKnown">
                                {{
                                selectedDevice?.theMarker?.data?.deviceState?.temperature2InCelcius.toLocaleString()
                                }}
                            </ng-container>
                            <ng-template #temperature2LastKnown>
                                {{
                                selectedDevice?.theMarker?.data?.deviceState?.lastKnownTemperature2InCelcius.toLocaleString()
                                }}
                            </ng-template>
                            °C
                        </h3>
                        <div>{{'general.temperature' | translate }} 2 <i
                                *ngIf="!selectedDevice?.theMarker?.data?.deviceState?.temperature2InCelcius"
                                class="fa fa-fw fa-history"
                                title="{{ selectedDevice?.theMarker?.data?.deviceState?.lastKnownTemperature2RtcBasedTimestamp }}"></i>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0"
                    *ngIf=" selectedDevice?.theMarker?.data?.deviceState?.fuelLevelInPercentage || selectedDevice?.theMarker?.data?.deviceState?.lastKnownFuelLevel">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-gas-pump" style="opacity: 0.3;"></i>
                            <ng-container
                                *ngIf="selectedDevice?.theMarker?.data?.deviceState?.fuelLevelInPercentage else fuelLevelLastKnown">
                                {{
                                selectedDevice?.theMarker?.data?.deviceState?.fuelLevelInPercentage.toLocaleString()
                                }}
                            </ng-container>
                            <ng-template #fuelLevelLastKnown>
                                {{
                                selectedDevice?.theMarker?.data?.deviceState?.lastKnownFuelLevel.toLocaleString()
                                }}
                            </ng-template>
                            %
                        </h3>
                        <div>{{'general.fuelLevelInPercentage' | translate }} 1 <i
                                *ngIf="!selectedDevice?.theMarker?.data?.deviceState?.fuelLevelInPercentage"
                                class="fa fa-fw fa-history"
                                title="{{ selectedDevice?.theMarker?.data?.deviceState?.lastKnownFuelLevelRtcBasedTimestamp }}"></i>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0"
                    *ngIf="selectedDevice?.theMarker?.data?.deviceState?.humidityInPercent1 || selectedDevice?.theMarker?.data?.deviceState?.lastKnownHumidity1">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-humidity" style="opacity: 0.3;"></i>
                            <ng-container
                                *ngIf="selectedDevice?.theMarker?.data?.deviceState?.humidityInPercent1; else humidity1LastKnown">
                                {{
                                selectedDevice?.theMarker?.data?.deviceState?.humidityInPercent1.toLocaleString()
                                }}
                            </ng-container>
                            <ng-template #humidity1LastKnown>
                                {{
                                selectedDevice?.theMarker?.data?.deviceState?.lastKnownHumidity1.toLocaleString()
                                }}
                            </ng-template>
                            %
                        </h3>
                        <div>{{'general.humidity' | translate }} 1 <i
                                *ngIf="!selectedDevice?.theMarker?.data?.deviceState?.humidityInPercent1"
                                class="fa fa-fw fa-history"
                                title="{{ selectedDevice?.theMarker?.data?.deviceState?.lastKnownHumidity1RtcBasedTimestamp }}"></i>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0"
                    *ngIf="selectedDevice?.theMarker?.data?.deviceState?.humidityInPercent2 || selectedDevice?.theMarker?.data?.deviceState?.lastKnownHumidity2">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-humidity" style="opacity: 0.3;"></i>
                            <ng-container
                                *ngIf="selectedDevice?.theMarker?.data?.deviceState?.humidityInPercent2; else humidity2LastKnown">
                                {{selectedDevice?.theMarker?.data?.deviceState?.humidityInPercent2.toLocaleString()}}
                            </ng-container>
                            <ng-template #humidity2LastKnown>
                                {{selectedDevice?.theMarker?.data?.deviceState?.lastKnownHumidity2.toLocaleString()
                                }}
                            </ng-template>
                            %
                        </h3>
                        <div>{{'general.humidity' | translate }} 2 <i
                                *ngIf="!selectedDevice?.theMarker?.data?.deviceState?.humidityInPercent2"
                                class="fa fa-fw fa-history"
                                title="{{ selectedDevice?.theMarker?.data?.deviceState?.lastKnownHumidity2RtcBasedTimestamp }}"></i>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0"
                    *ngIf="selectedDevice?.theMarker?.data?.deviceState?.axleWeightInKg1 || selectedDevice?.theMarker?.data?.deviceState?.lastKnownWeight1">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-weight" style="opacity: 0.3;"></i>
                            <ng-container
                                *ngIf="selectedDevice?.theMarker?.data?.deviceState?.axleWeightInKg1; else axleWeightInKg1LastKnown">
                                {{
                                selectedDevice?.theMarker?.data?.deviceState?.axleWeightInKg1.toLocaleString()
                                }}
                            </ng-container>
                            <ng-template #axleWeightInKg1LastKnown>
                                {{
                                selectedDevice?.theMarker?.data?.deviceState?.lastKnownWeight1.toLocaleString()
                                }}
                            </ng-template>
                            kg
                        </h3>
                        <div>{{'general.weight' | translate }} 1 <i
                                *ngIf="!selectedDevice?.theMarker?.data?.deviceState?.axleWeightInKg1"
                                class="fa fa-fw fa-history"
                                title="{{ selectedDevice?.theMarker?.data?.deviceState?.lastKnownWeight1RtcBasedTimestamp }}"></i>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6" style="padding:0"
                    *ngIf="selectedDevice?.theMarker?.data?.deviceState?.axleWeightInKg2 || selectedDevice?.theMarker?.data?.deviceState?.lastKnownWeight2">
                    <div class="kpi-item kpi-item-small">
                        <h3 class="margin-bottom font-bold">
                            <i class="fas fa-fw fa-weight" style="opacity: 0.3;"></i>
                            <ng-container
                                *ngIf="selectedDevice?.theMarker?.data?.deviceState?.axleWeightInKg2; else axleWeightInKg2LastKnown">
                                {{selectedDevice?.theMarker?.data?.deviceState?.axleWeightInKg2.toLocaleString()}}
                            </ng-container>
                            <ng-template #axleWeightInKg2LastKnown>
                                {{selectedDevice?.theMarker?.data?.deviceState?.lastKnownWeight2.toLocaleString()
                                }}
                            </ng-template>
                            kg
                        </h3>
                        <div>{{'general.weight' | translate }} 2 <i
                                *ngIf="!selectedDevice?.theMarker?.data?.deviceState?.axleWeightInKg2"
                                class="fa fa-fw fa-history"
                                title="{{ selectedDevice?.theMarker?.data?.deviceState?.lastKnownWeight2RtcBasedTimestamp }}"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>