<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="maintenance?.accountId">
                <a href="/#/AccountDetails/Index/{{maintenance?.accountId}}">{{maintenance?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Maintenances/Overview">{{'general.maintenance' | translate }}</a>
            </li>
            <li>
                <a href="/#/MaintenanceDetails/Index/{{maintenance?.id}}">{{maintenance?.typeName}}</a>
            </li>
            <li class="active">{{'menu.log' | translate }}</li>
        </ol>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <fh-logger [logItems]="maintenanceLog" [loading]="loading"></fh-logger>
            </div>
        </div>
    </div>
</fh-permission-handler>