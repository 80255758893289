import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { MaintenanceService } from 'app/services/maintenance/maintenance.service';
import { MaintenanceOccurrence } from 'app/models/maintenance.model';

@Component({
    selector: 'fh-maintenance-log',
    templateUrl: 'maintenanceLog.template.html'
})
export class MaintenanceLogViewComponent implements OnInit {
    maintenanceLog: AuditLog[];
    sub;
    maintenance: MaintenanceOccurrence;
    loading = false;

    error: any;
    success: any;

    permissionName = 'FleetManagement_Log';

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private maintenanceService: MaintenanceService, private route: ActivatedRoute, private router: Router) {
        this.maintenance = null;
        this.maintenanceLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.maintenance = new MaintenanceOccurrence();
        this.maintenance.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];
            this.maintenanceService.getMaintenanceById(id).subscribe(maintenance => {
                this.maintenance = maintenance;

                if (this.maintenance == null) {
                    this.router.navigate(['/Maintenances/Overview'])
                }

                this.auditLogService.getAuditLogByMaintenance(id).subscribe(maintenanceLog => {
                    console.log('loading done');
                    this.maintenanceLog = maintenanceLog;
                    this.loading = false;
                }, error => {
                    this.error = error;
                    this.loading = false;
                });
            });
        });
    }
}
