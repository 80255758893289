<fh-wizard [(step)]="step" (finish)="onCheckOutFinish()" [canContinue]="deviceForm">
    <fh-wizard-step title="SelectAccount" icon='fa-file-pdf' [validate]="groupForm" [padding]=false (open)="initForm()">
        <div class="wizard-content border-bottom" style="padding:12px">
            {{ 'wizard.selectYourClient' | translate}}
        </div>
        <div class="wizard-content" style="position: relative;">
            <form [formGroup]="groupForm" style="width: 100%; border-top: 0px">
                <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                    <tbody>
                        <tr>
                            <td style="width: 25%; border-bottom: 0px!important">
                                {{'general.reseller' | translate }}

                                <span class="error" *ngIf="groupForm.get('selectedReseller')?.errors">*</span>
                            </td>
                            <td style="width: 75%; border-bottom: 0px!important">
                                <fh-reseller-input name="reseller" (onChanged)="resellerChanged($event)" required
                                    placeholder="{{ 'general.chooseReseller' | translate}}"  [tableInline]="true"
                                    formControlName="selectedReseller">
                                </fh-reseller-input>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 25%; border-bottom: 0px!important">
                                {{'general.account' | translate }}

                                <span class="error" *ngIf="groupForm.get('selectedAccount').errors">*</span>
                            </td>
                            <td style="width: 75%; border-bottom: 0px!important">
                                <fh-account-input name="account" (onChanged)="accountChanged($event)" required
                                    [selectedResellerId]="selectedResellerId"  [tableInline]="true"
                                    placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                    formControlName="selectedAccount">
                                </fh-account-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="DeviceForm" icon='fa-file-pdf' [padding]=false (open)="loadDevices()">
        <div class="ibox-content slick" style="position: relative; min-height:72px; overflow: auto">
            <ngx-loading [show]="loadingDeviceDetails"></ngx-loading>
            <ngx-loading [show]="loadingDmv"></ngx-loading>

            <div *ngIf="loadingDmvResultReady" style="margin: 12px;">
                <p class="alert alert-warning" style="font-weight: bold;">
                    Request to DMV finished! ({{ dmvErrorMessages.length }} errors found)
                </p>
                <p class="alert alert-warning" *ngFor="let errorMessage of dmvErrorMessages" style="margin-top: 10px;">
                    {{ errorMessage }}
                </p>
            </div>

            <ng-container *ngIf="deviceForm">
                <form [formGroup]="deviceForm" class="popupOverflow">
                    <table class="table nowrap dtr-inline no-footer table-fixed">
                        <thead>
                            <tr>
                                <th style="width: 35px;" scope="col">#</th>
                                <th style="min-width: 375px; width: 375px;" colspan="3" scope="col">
                                    {{ 'general.addToClientActivation' | translate}}
                                </th>
                                <th style="min-width: 900px; width: 900px;" colspan="9" scope="col">
                                    {{ 'general.addToClientVehicle' | translate}}
                                </th>
                                <th style="min-width: 250px; width: 250px;" colspan="2" scope="col">
                                    {{ 'general.addToClientOdometer' | translate}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="small_padding" scope="row"></th>
                                <td class="small_padding" style="min-width: 150px; width: 150px;">
                                    {{ 'general.imei' | translate}} *</td>
                                <td class="small_padding">{{ 'general.assetName' | translate}} *</td>
                                <td class="small_padding">{{ 'general.assetCode' | translate}}</td>
                                <td class="small_padding">{{ 'general.assignmentStart' | translate}}</td>
                                <td class="small_padding">{{ 'general.chassisNumber' | translate}}</td>
                                <td class="small_padding">{{ 'general.vehicleType' | translate}}</td>
                                <td class="small_padding">{{ 'general.plate' | translate}}</td>
                                <td class="small_padding">{{ 'general.brand' | translate}}</td>
                                <td class="small_padding">{{ 'general.model' | translate}}</td>
                                <td class="small_padding">{{ 'general.year' | translate}}</td>
                                <td class="small_padding">{{ 'general.color' | translate}}</td>
                                <td class="small_padding">{{ 'general.comment' | translate}}</td>
                                <td class="small_padding">{{ 'general.odometer' | translate}}</td>
                                <td class="small_padding">{{ 'general.odometerDate' | translate}}</td>
                            </tr>
                            <ng-container *ngIf="loadingDeviceDetails == false && deviceForm.controls.length === 0">
                                <tr>
                                    <td colspan="14"> {{ 'general.noValidDevicesFound' | translate}}</td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let formGroup of deviceForm.controls; let i = index;">
                                <tr [formGroup]="formGroup">
                                    <th scope="row">{{i}}</th>
                                    <td class="small_padding"><input readonly class="form-control table-inline"
                                            formControlName="imei"></td>
                                    <td class="small_padding"><input
                                            [ngStyle]="{'border': formGroup.get('name').errors ? '1px solid red' : '' }"
                                            class="form-control table-inline" formControlName="name"></td>
                                    <td class="small_padding"><input class="form-control table-inline"
                                            formControlName="code"></td>
                                    <td class="small_padding"><input
                                            [ngStyle]="{'border': formGroup.get('activationDate').errors ? '1px solid red' : '' }"
                                            class="form-control table-inline" formControlName="activationDate"
                                            autocomplete="off" [bsConfig]="dpConfig" bsDatepicker> </td>
                                    <td class="small_padding"><input class="form-control table-inline"
                                            formControlName="vin">
                                        <i tooltip="Get asset information from DMV (Only available in the U.S.)"
                                            *ngIf="formGroup.get('vin').value && formGroup.get('vin').value.length > 0"
                                            class="fa fa-fw fa-satellite-dish"
                                            style="right: 3px; bottom: 17px; position: relative; cursor: pointer; float: right;"
                                            (click)="getInformationDMV(true)"></i>
                                    </td>
                                    <td class="small_padding">
                                        <select formControlName="vehicleType"
                                            class="form-control table-inline single-line" name="assetType" required
                                            style="width:100%">
                                            <option *ngFor="let vehicleType of vehicleTypes" [value]="vehicleType.id">
                                                {{ ('enums.vehicleType.' + vehicleType.id) | translate }}
                                            </option>
                                        </select>
                                    </td>
                                    <td class="small_padding"><input class="form-control table-inline"
                                            formControlName="plateNumber">
                                        <i tooltip="Get asset information from DMV"
                                            *ngIf="formGroup.get('plateNumber').value && formGroup.get('plateNumber').value.length > 0"
                                            class="fa fa-fw fa-satellite-dish"
                                            style="right: 3px; bottom: 17px; position: relative; cursor: pointer; float: right;"
                                            (click)="getInformationDMV(false)"></i>
                                    </td>
                                    <td class="small_padding"><input class="form-control table-inline"
                                            formControlName="brand"></td>
                                    <td class="small_padding"><input class="form-control table-inline"
                                            formControlName="model"></td>
                                    <td class="small_padding"><input class="form-control table-inline"
                                            formControlName="year"></td>
                                    <td class="small_padding"><input class="form-control table-inline"
                                            formControlName="color"></td>
                                    <td class="small_padding"><input class="form-control table-inline"
                                            formControlName="comment"></td>
                                    <td class="small_padding"><input class="form-control table-inline"
                                            formControlName="calibrationOdo"></td>
                                    <td class="small_padding"><input class="form-control table-inline"
                                            [ngStyle]="{'border': formGroup.get('calibrationOdoDate').errors ? '1px solid red' : '' }"
                                            formControlName="calibrationOdoDate" autocomplete="off"
                                            [bsConfig]="dpConfig" bsDatepicker>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </form>
            </ng-container>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Remarks" icon='fa-file-pdf'>
        <div class="wizard-content" style="padding-bottom: 10px">
            {{ 'wizard.optionalComment' | translate }}
        </div>
        <div class="wizard-content">
            <textarea class="form-control noresize stickypad" style="height:100px; width:100%; resize: none"
                placeholder="{{ 'placeholder.comments' | translate}}" [(ngModel)]="logItem"></textarea>
        </div>
    </fh-wizard-step>
    <fh-wizard-step title="Summary" icon='fa-summary-pdf'>
        <div class="wizard-content" style="padding-bottom: 10px"
            [innerHTML]="'wizard.summary' | translate:{ count: deviceForm?.controls?.length }">
        </div>
        <div class="ibox-content slick popupOverflow " style="position: relative; min-height:72px; overflow: auto">
            <table class="table nowrap dtr-inline no-footer table-summary">
                <thead>
                    <tr>
                        <th style="width: 35px;" scope="col">#</th>
                        <th style="min-width: 375px; width: 375px;" colspan="3" scope="col">
                            {{ 'general.addToClientActivation' | translate}}
                        </th>
                        <th style="min-width: 900px; width: 900px;" colspan="9" scope="col">
                            {{ 'general.addToClientVehicle' | translate}}
                        </th>
                        <th style="min-width: 250px; width: 250px;" colspan="2" scope="col">
                            {{ 'general.addToClientOdometer' | translate}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th class="small_padding" scope="row"></th>
                        <td class="small_padding" style="min-width: 150px; width: 150px;">
                            {{ 'general.imei' | translate}}</td>
                        <td class="small_padding">{{ 'general.assetName' | translate}}</td>
                        <td class="small_padding">{{ 'general.assetCode' | translate}}</td>
                        <td class="small_padding">{{ 'general.assignmentStart' | translate}}</td>
                        <td class="small_padding">{{ 'general.chassisNumber' | translate}}</td>
                        <td class="small_padding">{{ 'general.vehicleType' | translate}}</td>
                        <td class="small_padding">{{ 'general.plate' | translate}}</td>
                        <td class="small_padding">{{ 'general.brand' | translate}}</td>
                        <td class="small_padding">{{ 'general.model' | translate}}</td>
                        <td class="small_padding">{{ 'general.year' | translate}}</td>
                        <td class="small_padding">{{ 'general.color' | translate}}</td>
                        <td class="small_padding">{{ 'general.comment' | translate}}</td>
                        <td class="small_padding">{{ 'general.odometer' | translate}}</td>
                        <td class="small_padding">{{ 'general.odometerDate' | translate}}</td>
                    </tr>
                    <ng-container *ngFor="let formGroup of deviceForm?.controls; let i = index;">
                        <tr>
                            <th scope="row">{{i}}</th>
                            <td class="small_padding">
                                <input [value]="formGroup.get('imei').value" readonly class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('name').value" readonly class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('code').value" readonly class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('activationDate').value | amDateFormat: 'll'" readonly
                                    class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('vin').value" readonly class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="vehicleTypesDict[formGroup.get('vehicleType').value]" readonly
                                    class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('plateNumber').value" readonly
                                    class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('brand').value" readonly
                                    class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('model').value" readonly
                                    class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('year').value" readonly class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('color').value" readonly
                                    class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('comment').value" readonly
                                    class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('calibrationOdo').value" readonly
                                    class="form-control table-inline">
                            </td>
                            <td class="small_padding">
                                <input [value]="formGroup.get('calibrationOdoDate').value | amDateFormat: 'll'" readonly
                                    class="form-control table-inline">
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </fh-wizard-step>
</fh-wizard>