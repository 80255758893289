<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Devices/Overview">{{'general.devices' | translate }}</a>
            </li>
            <li class="active">{{'general.cards' | translate }}</li>
        </ol>
    </div>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">

        <div class="row">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>

                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-engine"></i> {{'general.utilization' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <div class="col-lg-4">
                            <div class="form-group" style="margin-bottom: 6px;">
                                <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                    (onChanged)="resellerChanged($event)" required [tableInline]="false"
                                    placeholder="{{ 'general.chooseReseller' | translate}}"
                                    [(ngModel)]="selectedResellerId">
                                </fh-reseller-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group" style="margin-bottom: 6px;">
                                <fh-account-input name="account" #accountVar="ngModel"
                                    (onChanged)="accountChanged($event)" required [activeOnly]=true
                                    [selectedResellerId]="selectedResellerId" [tableInline]="false"
                                    placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                    [(ngModel)]="selectedAccountId">
                                </fh-account-input>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group" style="margin-bottom: 6px;">
                                <fh-asset-group-input name="assetGroups" #assetGroupVar="ngModel" required
                                    [selectedAccountId]="selectedAccountId" [tableInline]="false"
                                    (onChanged)="filterDevices()"
                                    placeholder="{{ (selectedAccountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                                    [(ngModel)]="selectedAssetGroups">
                                </fh-asset-group-input>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group input-group" style="margin-bottom: 5px;">
                                <span class="input-group-addon">
                                    <i class="fa fa-fw fa-calendar"></i>
                                </span>
  <input placeholder="Daterangepicker" style="width:100%" placeholder="Datepicker" type="text"
                                [bsConfig]="dpConfig" class="form-control" bsDaterangepicker
                                (onHidden)="dateChanged('hidden')" [(ngModel)]="daterangepickerModel">
                            <span class="input-group-calendar-datatables">
                                <i class="fa fa-calendar-alt"></i>
                            </span>
                            <div style="clear:both"></div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group input-group" style="margin-bottom: 6px;">
                                <span class="input-group-addon">
                                    <i class="fa fa-fw fa-filter"></i>
                                </span>
                                <input type="text" class="form-control" placeholder="Filter on name/imei ect"
                                    (ngModelChange)="p.setCurrent(0)" [(ngModel)]="filter">
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group input-group" style="margin-bottom: 6px;">
                                <span class="input-group-addon hand" title="Sort direction"
                                    (click)="orderByReversed = !orderByReversed">
                                    <i class="fa fa-fw "
                                        [ngClass]="orderByReversed == true ? 'fa-sort-amount-down' : 'fa-sort-amount-up'"></i>
                                </span>
                                <ng-select [(ngModel)]="orderBy" class="form-control" name="itemsPerPage" required
                                    [clearable]="false" style="width: 50%; float:left;" style="width:100%">
                                    <ng-option value='score'>{{'general.score' | translate }}
                                    </ng-option>
                                    <ng-option value='segmentsDistance'>{{'general.distance' | translate }}
                                    </ng-option>
                                    <ng-option value='assetSearchName'>{{'general.name' | translate }}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group input-group" style="margin-bottom: 6px;">
                                <span class="input-group-addon" title="Items per page">
                                    <i class="fa fa-fw fa-stream"></i>
                                </span>
                                <ng-select [(ngModel)]="itemsPerPage" class="form-control" name="itemsPerPage" required
                                    [clearable]="false" style="width:100%">
                                    <ng-option [value]="10">10</ng-option>
                                    <ng-option [value]="17">17</ng-option>
                                    <ng-option [value]="25">25</ng-option>
                                    <ng-option [value]="50">50</ng-option>
                                    <ng-option [value]="99999">{{'general.all' | translate }}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="devices.length == 0">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-content" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <span *ngIf="!selectedAccountId">{{'general.pleaseSelectAccount' | translate
                            }}</span>
                        <span *ngIf="selectedAccountId">{{'general.noDataFound' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="devices.length > 0">
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="['/Devices/Overview/']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-car"></i> {{'general.assets' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <h3 class="margin-bottom font-bold">{{kpis?.assetCount?.toLocaleString()}} /
                            {{kpis?.assetCountWithTrips?.toLocaleString()}}
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="['/Devices/Overview/']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-route"></i> {{'general.trips' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <h3 class="margin-bottom font-bold">{{actualRound(kpis?.tripCount,0)?.toLocaleString()}}
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="['/Devices/Overview/']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-drafting-compass"></i> {{'general.distance' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <h3 class="margin-bottom font-bold">{{actualRound(kpis?.totalDistance,0)?.toLocaleString()}} KM
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="['/Devices/Overview/']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-drafting-compass"></i> {{'general.distancePrivate' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <h3 class="margin-bottom font-bold">
                            {{actualRound(kpis?.segmentsDistancePrivate,0)?.toLocaleString()}} KM
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="['/Devices/Overview/']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-drafting-compass"></i> {{'general.distanceBusiness' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <h3 class="margin-bottom font-bold">{{actualRound(kpis?.totalDistance -
                            kpis?.segmentsDistancePrivate,0)?.toLocaleString()}} KM
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="['/Devices/Overview/']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-clock"></i> {{'general.duration' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <h3 class="margin-bottom font-bold">{{actualRound((kpis?.totalDuration /
                            60),0)?.toLocaleString()}} H
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="['/Devices/Overview/']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-snooze"></i> {{'general.idling' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <h3 class="margin-bottom font-bold">{{actualRound((kpis?.totalIdling/ 60),0)?.toLocaleString()}}
                            H
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-xs-6">
                <div class="ibox hand" [routerLink]="['/Devices/Overview/']">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-fw fa-diagnoses"></i> {{'general.utilization' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content ibox-kpi" style="position: relative;">
                        <h3 class="margin-bottom font-bold">{{actualRound(((kpis?.efficiency / kpis?.assetCount) *
                            100),2)?.toLocaleString() }} %
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="devices.length > 0">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-engine"></i> {{'general.utilization' | translate }}
                        </h5>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <ngx-loading [show]="loading"></ngx-loading>
                        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody>
                                <highcharts-chart [runOutsideAngular]="true" style="display: block; width: 100%; height:200px" [Highcharts]="Highcharts" [options]="chart">
                                </highcharts-chart>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6"
                *ngFor="let device of devices | orderBy : orderBy : orderByReversed | stringFilter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, id: 'first'}; ">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-car"></i> <a class="secondary link_bolder"
                                href="/#/DeviceDetails/Index/{{device?.deviceId}}">{{ device.assetName }} | {{
                                device.unitId
                                }}</a>
                        </h5>
                        <span class="pull-right">
                            <span class="btn btn-info" style="display: inline; margin-top: -11px;"> {{
                                device?.efficiency ?
                                actualRound((device.efficiency *
                                100),1).toLocaleString() : 0 }}
                                %</span>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening_small">
                                        {{ 'general.trips' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ device?.tripCount ? actualRound(device.tripCount,0).toLocaleString() : 0 }}
                                    </td>
                                    <td class="row_opening_small"></td>
                                    <td class="row_content"><img style="position: absolute;"
                                            [src]="getIcon(device.iconId)"></td>
                                </tr>
                                <tr>
                                    <td class="row_opening_small">
                                        {{ 'general.distance' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ device?.segmentsDistance ?
                                        actualRound(device.segmentsDistance,0).toLocaleString() : 0 }} KM
                                    </td>
                                    <td class="row_opening_small"></td>
                                    <td class="row_content"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="ibox-content slick" style="position: relative">
                        <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td class="row_opening_small">
                                        {{ 'general.duration' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ device?.tripDurationInMinutes ? actualRound((device.tripDurationInMinutes /
                                        60),0).toLocaleString() : 0 }} H
                                    </td>
                                    <td class="row_opening_small">
                                        {{ 'general.idling' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ device?.idlingDurationInMinutes ? actualRound((device.idlingDurationInMinutes
                                        / 60), 0).toLocaleString() : 0 }} H
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_small">
                                        {{ 'general.speeding' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ device?.speedingCount ? actualRound(device.speedingCount,0).toLocaleString()
                                        : 0 }} / {{
                                        device?.roadSpeedingCount ?
                                        actualRound(device.roadSpeedingCount,0).toLocaleString() : 0 }}
                                    </td>
                                    <td class="row_opening_small">
                                        {{ 'general.braking' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ device?.decelCount ? actualRound(device.decelCount,0).toLocaleString() : 0 }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening_small">
                                        {{ 'general.acceleration' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ device?.accelCount ? actualRound(device.accelCount,0).toLocaleString() :
                                        0 }}
                                    </td>
                                    <td class="row_opening_small">
                                        {{ 'general.cornering' | translate}}
                                    </td>
                                    <td class="row_content">
                                        {{ device?.corneringCount ? actualRound(device.corneringCount,
                                        0).toLocaleString() : 0 }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_content" colspan="4">
                                        <span *ngFor="let group of device.assetGroups">
                                            <a class="btn btn-info btn-grid"
                                                href="/#/AssetGroupDetails/Index/{{group?.id}}"><i
                                                    class="fas fa-fw fa-layer-group"></i> {{group?.name
                                                }}</a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-footer noselect dataTables_wrapper">
                        <pagination-template #p="paginationApi" (pageChange)="currentPage = $event" id="first">
                            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" [class.disabled]="p.isFirstPage()">
                                        <a class="page-link" (click)="p.previous()" *ngIf="!p.isFirstPage()">{{
                                            'grid.previous' | translate }}</a>
                                        <a *ngIf="p.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                                    </li>

                                    <li class="paginate_button page-item " *ngFor="let page of p.pages"
                                        [class.current]="p.getCurrent() === page.value">
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </a>
                                        <a class="page-link" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() === page.value">
                                            <span style="font-weight: bold;">{{ page.label }}</span>
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" [class.disabled]="p.isLastPage()">
                                        <a class="page-link" (click)="p.next()" *ngIf="!p.isLastPage()">
                                            {{ 'grid.next' | translate }}
                                        </a>
                                        <a class="page-link" *ngIf="p.isLastPage()"> {{ 'grid.next' | translate }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </pagination-template>
                        <div style="clear:both"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>