<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="project?.accountId">
                <a href="/#/AccountDetails/Index/{{project?.accountId}}">{{project?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Projects/Overview">{{'general.project' | translate }}</a>
            </li>
            <li>
                <a href="/#/ProjectDetails/Index/{{project?.id}}">{{project?.name}}</a>
            </li>
            <li class="active">{{'general.dashboard' | translate }}</li>
        </ol>
    </div>
    <fh-project-details-navigation [projectId]="projectId"></fh-project-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error || success || warning">
            <div class="col-lg-12">
                <fh-error [error]="error" [warning]="warning" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-gavel"></i> {{'general.project' | translate }}
                            </h5>
                        </div>
                        <fh-project-details [project]="project" [formMode]="formMode" [loading]="loading || saving">
                        </fh-project-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                            [allowDelete]="allowDelete" (setFormMode)="setFormMode($event)" [formMode]="formMode">
                            <span class="pull-right">
                                <fh-task-dropdown (onFinishAction)="finishTask($event)" #taskDropdown
                                    [entityTypes]="tagType" [entity]="project"></fh-task-dropdown>
                            </span>
                        </fh-save-footer>
                    </div>
                </form>

            </div>
            <div class="col-lg-6">

                <form #assignmentChooserForm="ngForm">
                    <ng-container *ngIf="formMode == 3; else viewMode;">
                        <fh-wizard [(step)]="step" [showHeader]=true (finish)="onCheckOutFinish()">
                            <fh-wizard-step [title]="'general.assetsLinked'" icon='fa-microchip' [padding]=false>
                                <fh-dual-list [key]="'deviceId'" [source]="source" [(destination)]="confirmed"
                                    [format]="format" [display]="display" [sort]=true>
                                </fh-dual-list>
                            </fh-wizard-step>

                            <fh-wizard-step [title]="'general.assignmentCount'" icon='fa-clock'
                                [validate]="assignmentChooserForm" [padding]=false>

                                <div style="min-height: 430px;" class="popupOverflow">
                                    <table [style.display]="confirmed.length ? '' : 'none'"
                                        class="table nowrap dtr-inline no-footer">
                                        <thead>
                                            <tr>
                                                <th class="small_padding">&nbsp;</th>
                                                <th class="small_padding">{{ 'general.assignmentStart' | translate}}
                                                </th>
                                                <th class="small_padding">{{ 'general.assignmentEnd' | translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="small_padding">
                                                    <input class="form-control table-inline" style="visibility: hidden;"
                                                        readonly>
                                                </td>
                                                <td class="small_padding">
                                                    <div class="form-group input-group" style="margin-bottom: 5px;">
                                                        <input name="assignmentStartAll"
                                                            class="form-control table-inline" autocomplete="off"
                                                            (bsValueChange)="setStartDate($event)" [bsConfig]="dpConfig"
                                                            bsDatepicker>
                                                        <span class="input-group-calendar-datatables"
                                                            style="margin-top: unset;">
                                                            <i class="fa fa-calendar-alt"></i>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td class="small_padding">
                                                    <div class="form-group input-group" style="margin-bottom: 5px;">
                                                        <input name="assignmentEndAll" class="form-control table-inline"
                                                            autocomplete="off" (bsValueChange)="setEndDate($event)"
                                                            [bsConfig]="dpConfig" bsDatepicker>
                                                        <span class="input-group-calendar-datatables"
                                                            style="margin-top: unset;">
                                                            <i class="fa fa-calendar-alt"></i>
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="table nowrap dtr-inline no-footer">
                                        <thead>
                                            <tr>
                                                <th class="small_padding">{{ 'general.name' | translate}}</th>
                                                <th class="small_padding">{{ 'general.assignmentStart' | translate}}
                                                    *
                                                </th>
                                                <th class="small_padding">{{ 'general.assignmentEnd' | translate}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="confirmed.length; else noAssetAssignments">
                                                <tr [ngModelGroup]="item.id"
                                                    *ngFor="let item of confirmed; let i = index;">
                                                    <td style="display: none;" class="small_padding">
                                                        <input [ngModel]="item.assetId ?? item.id" name="assetId"
                                                            readonly class="form-control table-inline">
                                                    </td>
                                                    <td class="small_padding">
                                                        <input [ngModel]="item.assetName ?? item.name" name="name"
                                                            readonly class="form-control table-inline">
                                                    </td>
                                                    <td class="small_padding">
                                                        <div class="form-group input-group" style="margin-bottom: 5px;">
                                                            <input ngModel #assignmentStart="ngModel"
                                                                [ngModel]="item.dateStart | amDateFormat: 'LLL'"
                                                                [style.border]="assignmentStart.valid ? '': '1px solid red'"
                                                                name="startDate" class="form-control table-inline"
                                                                autocomplete="off" [bsConfig]="dpConfig" bsDatepicker
                                                                required>
                                                            <span class="input-group-calendar-datatables"
                                                                style="margin-top: unset;">
                                                                <i class="fa fa-calendar-alt"></i>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td class="small_padding">
                                                        <div class="form-group input-group" style="margin-bottom: 5px;">
                                                            <input ngModel #assignmentEnd="ngModel"
                                                                [ngModel]="item.dateEnd | amDateFormat: 'LLL'"
                                                                name="endDate" class="form-control table-inline"
                                                                autocomplete="off" [bsConfig]="dpConfig" bsDatepicker>
                                                            <span class="input-group-calendar-datatables"
                                                                style="margin-top: unset;">
                                                                <i class="fa fa-calendar-alt"></i>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <ng-template #noAssetAssignments>
                                                <tr>
                                                    <td colspan="5"> {{ 'general.noValidDevicesFound' | translate}}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </tbody>
                                    </table>
                                </div>
                            </fh-wizard-step>
                            <fh-wizard-step [title]="'general.summery'" icon='fa-list-alt' [padding]=false
                                (open)="sendRequest(assignmentChooserForm)">
                                <ngx-loading [show]="loading"></ngx-loading>

                                <div class="ibox-content slick popupOverflow"
                                    style="position: relative; min-height: 430px; overflow: auto;">
                                    <table class="table nowrap dtr-inline no-footer table-summary">
                                        <thead>
                                            <tr>
                                                <th class="small_padding">{{ 'general.name' | translate}}</th>
                                                <th class="small_padding">{{ 'general.assignmentStart' | translate}}
                                                </th>
                                                <th class="small_padding">{{ 'general.assignmentEnd' | translate}}
                                                </th>
                                                <th class="small_padding">{{ 'general.valid' | translate}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="confirmed.length; else noAssetAssignments">
                                                <tr
                                                    *ngFor="let item of assignmentChooserForm?.form.value | keyvalue; let i = index;">
                                                    <td class="small_padding">
                                                        <input [value]="item.value['name']" readonly
                                                            class="form-control table-inline">
                                                    </td>
                                                    <td class="small_padding">
                                                        <input [value]="item.value['startDate'] | amDateFormat: 'LLL'"
                                                            readonly class="form-control table-inline">
                                                    </td>
                                                    <td class="small_padding">
                                                        <input [value]="item.value['endDate'] | amDateFormat: 'LLL'"
                                                            readonly class="form-control table-inline">
                                                    </td>
                                                    <td class="small_padding">
                                                        <i *ngIf="loading; else result"
                                                            class="fa fa-spinner fa-spin fa-hourglass-start"></i>

                                                        <ng-template #result>
                                                            <h3 class="margin-bottom font-bold"
                                                                *ngIf="!errorItems[item.value['assetId']]">
                                                                <i class="fa fa-fw fa-check"
                                                                    style="color: rgb(0, 142, 67)"></i>
                                                            </h3>
                                                            <h3 [tooltip]="errorItems[item.value['assetId']]"
                                                                class="margin-bottom font-bold"
                                                                *ngIf="!!errorItems[item.value['assetId']]">
                                                                <i class="fa fa-fw fa-times"
                                                                    style="color: rgb(151, 28, 36)"></i>
                                                            </h3>
                                                        </ng-template>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <ng-template #noAssetAssignments>
                                                <tr>
                                                    <td colspan="5"> {{ 'general.noValidDevicesFound' | translate}}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </tbody>
                                    </table>
                                </div>
                            </fh-wizard-step>
                        </fh-wizard>
                    </ng-container>
                    <ng-template #viewMode>
                        <fh-project-devices [deviceAssignments]="projectAssignments" [loading]="loadingAssignments">
                        </fh-project-devices>
                    </ng-template>
                </form>

                <fh-schedule-assignments [projectId]="project.id"                    
                    [selectedAccountId]="project?.accountId" [deletePermission]="'Projects_Edit'">
                </fh-schedule-assignments>

                <fh-tagging [objectId]="project?.id" [objectType]="tagType">
                </fh-tagging>
            </div>
        </div>
    </div>
</fh-permission-handler>