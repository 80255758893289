<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Fuel/Overview">{{'general.fuel' | translate }}</a>
            </li>
            <li class="active">
                {{'menu.add' | translate }}
            </li>
        </ol>
    </div>
    <fh-fuel-navigation>
        <li class="active">
            <a href="/#/Fuel/Add/"><i class="fa fa-fw fa-gas-pump"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a>
        </li>
    </fh-fuel-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-lg-12">
                <fh-error [success]="success" [error]="error"></fh-error>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-fw fa-gas-pump"></i> {{'general.fuel' | translate }}
                            </h5>
                        </div>
                        <fh-fuel-details [fuel]="fuel" [formMode]="formMode" [loading]="loading"></fh-fuel-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onInsert)="onInsert()"
                            [allowAdd]="permissions['FuelEvents_Add']" [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <div class="ibox" style="position:relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-city"></i> {{'general.info' | translate }}</h5>
                    </div>
                    <div class="ibox-content">
                        Info
                    </div>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>