import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';
import { TranslateService } from '@ngx-translate/core';
import { DistanceUnits } from './enums';
import { kmToMilesMultiplier } from './globals';
import { AuthenticationService } from 'app/services/authentication/authentication.service';


@Injectable()
export class DistanceUnitService {

    multiplier = 1;
    distanceUnit = DistanceUnits.Kilometers.valueOf();

    constructor(private authenticationService: AuthenticationService,
        private translate: TranslateService) {
        this.distanceUnit = this.authenticationService.getDistanceUnit() !== '0' ? DistanceUnits.Miles : DistanceUnits.Kilometers;
        if (this.distanceUnit !== DistanceUnits.Kilometers) {
            this.multiplier = kmToMilesMultiplier;
            console.log('setting multiplier ' + this.multiplier);
        }
    }

    getDistanceUnit(): string {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return 'general.km';
        } else {
            return 'general.miles';
        }
    }

    getDistanceUnitPerHour(): string {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return 'general.kmh';
        } else {
            return 'general.mph';
        }
    }

    getDistanceUnitSpeedDiscription(): string {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return this.translate.instant('general.speedinkph');
        } else {
            return this.translate.instant('general.speedinmph');
        }
    }

    getDistanceUnitOdo(): string {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return this.translate.instant('general.Odo');
        } else {
            return this.translate.instant('general.OdoInMiles');
        }
    }

    calculateDistanceUnitFromKm(distanceInKm: number, precision = 2): number {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return distanceInKm;
        } else {
            return +(distanceInKm * this.multiplier).toFixed(precision);
        }
    }

    calculateDistanceUnitFromKmString(distanceInKm: number, precision = 0): string {
        if (this.distanceUnit === DistanceUnits.Kilometers) {
            return distanceInKm.toString();
        } else {
            return (distanceInKm * this.multiplier).toFixed(precision);
        }
    }
}

