import { Component, ChangeDetectorRef, ViewChild, OnDestroy, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ProjectService } from 'app/services/project/project.service';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Device } from 'app/models/device.model';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'fh-project-chooser',
    templateUrl: 'projectChooser.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: []
})
export class ProjectChooserComponent implements OnInit {
    @ViewChild('autoShownModal') autoShownModal: ModalDirective;
    modalRef: BsModalRef;
    isModalShown = false;

    token: string;
    @Input() device: Device;
    @Output() onSelect = new EventEmitter();
    toEnabled = false;

    dropdown_IssueType = '';

    dtOptions = {};

    min: number;
    max: number;

    loading = false;
    languageLoaded: boolean;
    permissions: {};

    selected: any = { id: null, to: null, from: new Date() };

    search$ = new Subject<string>();
    searchTerm: any;

    step = 1;

    ismeridian = false;

    // Datepicker
    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    constructor(private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private projectService: ProjectService) {

        this.token = this.authenticationService.getAuthToken();

        // Datepicker
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.dpConfig.withTimepicker = true;
    }

    close() {
        this.toEnabled = false;
        this.step = 1;
        this.hideModal();
        this.cd.markForCheck();
    }

    onHidden() {
        this.hideModal();
    }

    onToDate(event) {
        if (event !== null) {
            // this.selected.to = selected.to;
            this.toEnabled = true;
        }
    }

    resetToDate() {
        this.selected.to = null;
        this.toEnabled = false;
    }

    select() {
        // this.hideModal();
        // this.onSelect.emit({ id: this.selected.id, name: this.selected.name });
        this.step++;
    }

    finish() {
        let project;
        [this.selected, project] = [{ id: null, to: null, from: new Date() }, this.selected];
        this.step = 1;
        this.hideModal();

        if (!this.toEnabled) {
            project.to = null;
        }

        this.onSelect.emit(project);
    }

    clickHandler(info: any): void {
        const { from } = this.selected;
        this.selected = info;
        this.selected.from = from;
    }

    ngOnInit(): void {
        this.permissions = this.authenticationService.permissions;

        this.translateService.get('general.date').subscribe(value => {
            this.languageLoaded = true;
            this.cd.markForCheck();
        });
    }

    // Model
    public showModal(): void {
        console.log('show modal');
        this.isModalShown = true;
        this.cd.markForCheck();
    }

    public hideModal(): void {
        this.isModalShown = false;
        this.cd.markForCheck();
    }
}
