<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/System/">{{'general.system' | translate }}</a>
            </li>
            <li>
                <a href="/#/System/WebhookEndpoints/Overview">{{'general.webhookEndpoints' | translate }}</a>
            </li>
            <li class="active">{{'general.details' | translate }}</li>
        </ol>

        <!-- <span style="margin-left: auto;" class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix hidden-sm hidden-xs" title="Help"
                href="https://help.360locate.com/knowledge-base/use-workschedule-to-mark-trips-as-private-or-business/"
                target="_blank"><i class="fa fa-fa fa-question"></i> <span
                    class="hidden-sm hidden-xs">{{'general.needHelp' |
                    translate }}</span></a>
        </span> -->
    </div>
    <fh-system-navigation>
        <li class="active"><a href="/#/System/WebhookEndpointDetails/Add/"><i class="fas fa-fw fa-calendar"></i>
                <div>{{ 'general.add' | translate }}</div>
            </a></li>
    </fh-system-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row" data-equalizer="">
        <div class="col-lg-12">
            <fh-error [error]="error" [success]="success"></fh-error>
        </div>
    </div>

    <form #form="ngForm">
        <div class="row" data-equalizer="">
            <div class="col-lg-6">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>
                            <i class="fas fa-fw fa-calendar"></i> {{'general.webhookEndpoints' | translate }}
                        </h5>
                    </div>
                    <ngx-loading [show]="loading || saving"></ngx-loading>
                    <fh-webhook-endpoint-details [webhookEndpoint]="webhookEndpoint" [loading]="loading"
                        [formMode]="formMode">
                    </fh-webhook-endpoint-details>
                    <fh-save-footer [valid]="!form.invalid" [allowCancelOnInsert]=false (onInsert)="onInsert()"
                        [formMode]="formMode">
                    </fh-save-footer>
                </div>                
            </div>
            <div class="col-lg-6">
                <div class="ibox" style="position:relative">
                    <div class="ibox-title">
                        <h5><i class="fas fa-fw fa-city"></i> {{'general.info' | translate }}</h5>
                    </div>
                    <div class="ibox-content">
                        Info
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>