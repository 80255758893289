import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { StorageType } from 'app/common/enums';
import { OrderByPipe } from 'app/common/orderBy.pipe';
import { StorageHelper } from 'app/common/storagehelper';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'iboxseach',
    templateUrl: 'iboxsearch.template.html',
    providers: [OrderByPipe],
})
export class IboxSearchComponent implements OnChanges, OnInit {
    @ViewChild('batchModal', { static: false }) batchModal: ModalDirective;

    @Input() searchTerm: string;
    @Input() showColumns: Boolean = true;
    @Input() showPresets: Boolean = true;
    @Input() constructorName: any;

    @Output() public seachChanged: EventEmitter<any> = new EventEmitter();
    @Output() public columnsChanged: EventEmitter<any> = new EventEmitter();
    @Output() presetLoaded = new EventEmitter();
    @Output() filterChanged = new EventEmitter();

    storageType = StorageType.DatabasePrefetch; // StorageType.LocalStorage;

    presetList = [];
    preset_name = '';
    gridPresets: string;

    searchVar: string;
    isEditMode = false;
    search$ = new Subject<string>();

    showFiltersEnabled = true;
    showColumnsEnabled = false;
    showPresetsEnabled = false;
    showFilter = true;

    selectedAccountId;

    userId: number;

    success;
    error;

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private storageHelper: StorageHelper) {
        this.searchVar = '';

        this.userId = +this.authenticationService.getUserId();

        this.search$
            .debounceTime(700)
            .distinctUntilChanged()
            .subscribe(search => {
                this.seachChanged.emit(search);
            });
    }

    ngOnChanges() {
        if (this.searchTerm != null) {
            this.searchVar = this.searchTerm;
        }

        if (this.constructorName) {
            this.presetList = [];
            this.initPresets();
        }

        this.loadShowFilter();
    }

    sumbitSearch() {
        this.seachChanged.emit(this.searchVar);
    }

    switchEditMode() {
        if (this.isEditMode === false) {
            this.isEditMode = true;

            const button = $('#toolboxButton');
            const toolbox = $('#grid-toolbox');
            toolbox.appendTo(button);

            if (this.isEditMode) {
                toolbox.removeClass('hidden');

                // reset trey
                $(document).mouseup((e: any) => {
                    if (!toolbox.is(e.target) && toolbox.has(e.target).length === 0) {
                        $(this).unbind(e);

                        toolbox.addClass('hidden');
                        this.isEditMode = false;
                    }
                });
            }
        }
    }

    switchColumnChooser() {
        if (this.showColumnsEnabled === false) {
            this.showColumnsEnabled = true;

            const button = $('#columnChooseButton');
            const chooser = $('#grid-column-chooser');
            chooser.appendTo(button);

            if (this.showColumnsEnabled) {
                chooser.removeClass('hidden');

                // reset trey
                $(document).mouseup((e: any) => {
                    if (!chooser.is(e.target) && chooser.has(e.target).length === 0) {

                        $(this).unbind(e);

                        chooser.addClass('hidden');
                        this.showColumnsEnabled = false;
                        this.columnsChanged.next(void 0);
                    }
                });
            }
        }
    }

    // Hide or show filters
    loadShowFilter() {
        this.storageHelper.loadStoreState(this.storageType, 'ShowFilter_', this.constructorName).subscribe((result) => {
            this.showFilter = JSON.parse(result) !== false;

            this.filterChanged.emit(this.showFilter);
            this.cd.markForCheck();
        });
    }

    changeShowFilter(filterValue) {
        this.showFilter = filterValue;
        this.storageHelper.saveStoreState(this.storageType, 'ShowFilter_', this.constructorName, filterValue);
        this.filterChanged.emit(this.showFilter);
    }

    // Presets
    showModal() {
        this.batchModal.show();
    }

    hideModal() {
        this.batchModal.hide();
    }

    ngOnInit(): void {
        this.initPresets();
    }

    // Saving and loading presets
    initPresets() {
        this.presetList = [];

        this.storageHelper.loadStoreState(this.storageType, 'Presets_', this.constructorName).subscribe((result) => {
            if (typeof result !== 'object') {
                return;
            }

            this.presetList = result ?? [];

            // const selectedColumns = JSON.parse(localStorage.getItem('Columns_' + this.constructorName));
            // delete selectedColumns['time'];

            // for (const { json } of this.presetList) {
            //     const jsonObject = JSON.parse(json);
            //     delete jsonObject['time'];
            // }

            this.cd.markForCheck();
        });
    }

    savePreset() {
        const presetJson = localStorage.getItem('Columns_' + this.constructorName);

        const listLength = this.presetList.length;

        const systemPreset = this.presetList.find(el => el.name === this.preset_name && !('userId' in el && el.userId === +this.authenticationService.getUserId()));
        if (systemPreset) {
            this.success = null;
            this.error = null;
            this.error = {
                statusText: 'Error',
                error: `You cannot create or overwrite a preset with same name as system ones`,
            };
            return;
        }

        this.presetList = this.presetList.filter((el) => { return el.name !== this.preset_name; });
        this.presetList.push({ name: this.preset_name, userId: +this.authenticationService.getUserId(), json: presetJson });

        this.storageHelper.saveStoreState(this.storageType, 'Presets_', this.constructorName, this.presetList);

        this.success = null;
        this.error = null;
        this.success = {
            statusText: 'Success',
            success: `Preset ${this.preset_name} ${listLength === this.presetList.length ? 'updated' : 'added'}`,
        };

        this.preset_name = '';
    }

    deletePreset(preset_name) {
        this.presetList = this.presetList.filter((el) => { return el.name !== preset_name; });

        this.storageHelper.saveStoreState(this.storageType, 'Presets_', this.constructorName, this.presetList);

        this.success = null;
        this.error = null;
        this.success = {
            statusText: 'Success',
            success: `Preset ${preset_name} deleted`
        };
    }

    clearGridPreset() {
        this.loadGridPreset({ name: '', json: null });

        this.success = null;
        this.error = null;
        this.success = {
            statusText: 'Success',
            success: 'Grid cleared to default'
        };
    }

    loadGridPreset(preset) {
        // Reset path to clear for loading of new filter
        window.history.replaceState(null, null, window.location.href.split('?')[0]);

        // Set the chooser back to the holder
        const chooser = $('#grid-column-chooser');
        const chooserHolder = $('#grid-column-chooser-holder');
        chooser.appendTo(chooserHolder);

        const toolbox = $('#grid-toolbox');
        const toolboxHolder = $('#grid-toolbox-holder');
        toolbox.appendTo(toolboxHolder);

        this.preset_name = preset.name;
        this.presetLoaded.next(preset);
        this.hideModal();
    }
}
