import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { LoggingService } from '../logging/logging.service';
import { Trip } from 'app/models/trip.model';

import { getGeofenceActionResources, roundMinutes, roundSeconds } from 'app/common/globals';
import { GeofenceState } from 'app/models/geofenceState.model';
import { Episode } from 'app/models/episode.model';
import { TranslateService } from '@ngx-translate/core';
import { DistanceUnitService } from 'app/common/distanceunit.service';
import { parseEpisode } from '../common/episode.parser';

import * as L from 'leaflet';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';

window['moment'] = Moment;
mTZ()


@Injectable()
export class TripService {

    timestamp;
    baseUrl: string;
    url: string;
    timezoneIana: string;

    constructor(private http: HttpClient, private loggingService: LoggingService, private distance: DistanceUnitService, private authenticationService: AuthenticationService, private translate: TranslateService) {
        this.url = authenticationService.getWebserviceURL('Trip');
        this.baseUrl = authenticationService.getWebserviceURL('');
        this.timezoneIana = authenticationService.getTimeZoneIana();
    }

    getTripsForDevice(deviceId: string, start, end): Observable<Trip[]> {

        console.log('getting trips from service');

        return this.http.get(this.url + '?deviceId=' + deviceId + '&start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getTripsForDriver(driverId: string, start, end): Observable<Trip[]> {
        console.log('getting trips from service');

        return this.http.get(this.url + '?driverId=' + driverId + '&start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = this.parseResponse(data);
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    getTripDetails(tripId: string): Observable<any> {

        console.log('getting locations for trip from service');
        return this.http.get(this.url + tripId + '/Details', { headers: this.authenticationService.headers })
            .map((data: any) => {
                const trip: any = this.parseTripDetails(data);
                trip.geofenceStates = this.parseGeofenceStates(data.geofenceStates);
                trip.messages = data.messages
                trip.episodes = [];

                data.episodes?.forEach(item => {
                    const episode = parseEpisode(item, this.translate, Moment, this.timezoneIana, this.distance);
                    trip.episodes.push(episode);
                });

                return trip;
            })
            .catch(this.handleError);
    }

    updateTripById(tripId, isPrivate, kmPrivate, comment): Observable<any> {
        console.log('getting assetGroups from service : ' + this.baseUrl);

        const tripObject = {
            id: tripId,
            isPrivate: isPrivate,
            kmPrivate: kmPrivate,
            comment: comment,
        }

        return this.http.put(this.url + tripId, tripObject, { headers: this.authenticationService.headers })
            .map((data) => {
                return data;
            })
            .catch(this.handleError);
    }

    getMessageUrl(deviceId: string, limit: number = 500, source, start, end, key) {
        return this.baseUrl + 'Message/Paging?deviceId=' + deviceId + '&limit=' + limit + '&source=' + source + '&begin=' + start.unix() + '&end=' + end.unix() + '&rnd=' + key;
    }

    getEpisodeUrl(deviceId: string, triggerId: string, start, end, key) {
        return this.baseUrl + 'Episode/Paging?deviceId=' + deviceId + '&triggerId=' + triggerId + '&begin=' + start.unix() + '&end=' + end.unix() + '&rnd=' + key;
    }

    getEpisodes(deviceId: string, start, end) {
        const that = this;
        console.log('getting episodes states from service');

        return this.http.get(this.baseUrl + 'Device/' + deviceId + '/Episodes?start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .map((data: any) => {
                const returnItem = [];
                data.forEach(item => {
                    const episode = parseEpisode(item, this.translate, Moment, this.timezoneIana, this.distance);
                    returnItem.push(episode);
                });

                return returnItem;
            })
            .catch(this.handleError);
    }

    getEpisodeDetailsById(episodeId: string) {
        const that = this;
        console.log('getting episodes states from service');
        return this.http.get(this.baseUrl + 'Episode/' + episodeId, { headers: this.authenticationService.headers })
            .map((data: any) => {
                return parseEpisode(data, this.translate, Moment, this.timezoneIana, this.distance);
            })
            .catch(this.handleError);
    }

    getEpisodeDetailsByIdentifier(episodeGuid: string) {
        const that = this;
        console.log('getting episodes states from service');
        return this.http.get(this.baseUrl + 'Episode/' + episodeGuid + '/Identifier', { headers: this.authenticationService.headers })
            .map((data: any) => {
                return parseEpisode(data, this.translate, Moment, this.timezoneIana, this.distance);
            })
            .catch(this.handleError);
    }

    deleteEpisodeById(episodeId: any) {
        return this.http.delete(this.baseUrl + 'Episode/' + episodeId, { headers: this.authenticationService.headers })
            .catch(this.handleError);
    }

    getGeofenceStatesUrl(deviceId: string, geofenceId: number, start, end, key) {
        return this.baseUrl + 'GeofenceState/Paging?deviceId=' + deviceId + '&geofenceId=' + geofenceId + '&begin=' + start.unix() + '&end=' + end.unix() + '&rnd=' + key;
    }

    getGeofenceStates(deviceId: string, start, end) {
        console.log('getting geofence states from service');
        return this.http.get(this.baseUrl + 'GeofenceState?deviceId=' + deviceId + '&start=' + start.unix() + '&end=' + end.unix(), { headers: this.authenticationService.headers })
            .map((data: any) => {
                return this.parseGeofenceStates(data);
            })
            .catch(this.handleError);
    }

    parseGeofenceStates(data) {
        const returnItem = [];

        data?.forEach(item => {

            const geofenceState = new GeofenceState();

            geofenceState.display = true;
            geofenceState.rtcBasedTimestamp = item.stateChangeDateTime;
            geofenceState.startUtc = Moment.utc(item.stateChangeDateTime)['tz'](this.timezoneIana);
            geofenceState.date = Moment.utc(item.stateChangeDateTime)['tz'](this.timezoneIana).unix();
            geofenceState.geofenceId = item.geoFenceId;
            geofenceState.assetId = item.assetId;

            const { icon, iconFontColor, markerColor, description }: { [key: string]: any } = getGeofenceActionResources(item, this.translate);

            geofenceState.id = item.Id;

            geofenceState.icon = icon;

            geofenceState.iconFontColor = iconFontColor;
            geofenceState.markerColor = markerColor;
            geofenceState.description = description;

            geofenceState.duration = item.durationInSeconds || 0;

            geofenceState.iconBackgroundClass = 'grey';
            geofenceState.iconUrl = '';

            returnItem.push(geofenceState);
        });

        return returnItem;
    }

    getMessagesForDevice(deviceId: string, start, end, key, source = 1): Observable<any> {

        console.log('getting messages from service');
        return this.http.get(this.baseUrl + 'Message?deviceId=' + deviceId + '&start=' + start.unix() + '&end=' + end.unix() + '&source=' + source + '&rnd=' + key, { headers: this.authenticationService.headers })
            .map((data) => {
                const parsedResponse = data;
                return parsedResponse;
            })
            .catch(this.handleError);
    }

    parseResponse(json: any): Trip[] {

        this.loggingService.log(this.constructor.name, 'Retrieved ' + json.length + ' schedules.');

        let ident = json.length;
        const trips: Trip[] = [];

        json.forEach(item => {

            const trip = this.parseTripDetails(item);
            trip.ident = ident;
            trips.push(trip);

            ident--;
        });

        this.timestamp = new Date().getTime();
        return trips;
    }

    parseTripDetails(item) {
        const trip = new Trip();
        trip.id = item.id;
        trip.assetId = item.assetId;
        trip.deviceId = item.deviceId;
        trip.driverId = item.driverId;
        trip.driverName = item.driverName;

        trip.beginDateTime = Moment.utc(item.beginTS)['tz'](this.timezoneIana);

        trip.beginLabel = item.BeginLabel;
        trip.beginCity = item.beginCity;
        trip.beginAddress = item.beginAddress;
        trip.beginAddressFull = item.beginAddressFull;

        trip.beginLatitude = item.beginLatitude;
        trip.beginLongitude = item.beginLongitude;
        trip.beginLocation = L.latLng(item.beginLatitude, item.beginLongitude);

        trip.endDateTime = Moment.utc(item.endTS)['tz'](this.timezoneIana);

        var duration = Moment.duration((new Date(item.endTS).getTime() - new Date(item.beginTS).getTime()), 'millisecond');
        trip.duration = roundSeconds(duration.asSeconds());
        trip.durationHumanized = duration.humanize();

        trip.endLabel = item.EndLabel;
        trip.endCity = item.endCity;
        trip.endAddress = item.endAddress;
        trip.endAddressFull = item.endAddressFull;

        trip.endLatitude = item.endLatitude;
        trip.endLongitude = item.endLongitude;
        trip.endLocation = L.latLng(item.endLatitude, item.endLongitude);

        trip.calibratedOdoValueEnd = item.calibratedOdoValueEnd ? item.calibratedOdoValueEnd / 1000 : 0;

        trip.boundingDistanceInMetres = item.boundingDistanceInMetres;
        trip.segmentDistanceInKilometers = item.segmentDistanceInKilometers;

        trip.maxSpeed = item.maxSpeed;
        trip.isPrivate = item.isPrivate;
        trip.isHidden = item.isHidden;
        trip.kmPrivate = item.kmPrivate;
        trip.tripMethod = item.tripMethod;
        trip.tripType = item.tripType;

        if (item.isHidden) {
            trip.beginAddress = '-';
            trip.beginAddressFull = '-';
            trip.endAddress = '-';
            trip.endAddressFull = '-';
        } else {
            trip.beginAddress = item.beginAddress;
            trip.beginAddressFull = item.beginAddressFull;
            trip.endAddress = item.endAddress;
            trip.endAddressFull = item.endAddressFull;
            trip.locations = item.locations;
        }

        return trip;
    }

    private handleError(error: Response) {
        return throwError(error);
    }
}
