<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li>
                <a href="/#/Reporting">{{'general.reporting' | translate }}</a>
            </li>
            <li class="active">{{'general.create' | translate }}</li>
        </ol>

        <span style="margin-left: auto;" class="fa-filter-container noselect">
            <a style="margin-left:2px" class="btn btn-info margin-fix hidden-sm hidden-xs" title="Help"
                href="https://help.360locate.com/knowledge-base/create-report" target="_blank"><i
                    class="fa fa-fa fa-book" style="margin-right: 6px"></i> <span
                    class="hidden-sm hidden-xs">{{'general.needHelp' |
                    translate }}</span></a>
        </span>
    </div>
    <fh-reporting-navigation>
        <li class="active"><a [routerLink]="['/Reporting/Create']">
                <i class="fa fa-fw fa-user-chart"></i>
                <div>{{'general.createReport' | translate }}</div>
            </a>
        </li>
    </fh-reporting-navigation>
</div>

<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <fh-error [success]="success" [error]="error"></fh-error>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <fh-wizard [(step)]="step" [showHeader]=true (finish)="onCheckOutFinish()">
                <fh-wizard-step stepTitle="general.selectReport" [validate]="actionChooserForm" icon='fa-building'
                    [padding]=false (open)="getReportTypes()">
                    <ng-container *ngIf="loadingColumns">
                        <ngx-loading [show]=true></ngx-loading>
                    </ng-container>
                    <div [hidden]="loadingColumns">
                        <form #actionChooserForm="ngForm" style="width: 100%; border-top: 0px;">
                            <table style="width:100%" class="table nowrap dtr-inline no-footer">
                                <tbody>
                                    <tr [hidden]="!permissions['Accounts_View'] && !permissions['Resellers_View']">
                                        <td colspan="2">
                                            <strong>{{ 'general.account' | translate}}</strong>
                                        </td>
                                    </tr>
                                    <tr [hidden]="!permissions['Resellers_View'] && selectedResellerId">
                                        <td class="row_opening">
                                            {{ 'general.reseller' | translate}}
                                            <span class="error" *ngIf="resellerVar.invalid">*</span>
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible">
                                            <fh-reseller-input name="reseller" #resellerVar="ngModel"
                                                (onChanged)="resellerChanged($event)" required [tableInline]="true"
                                                placeholder="{{ 'general.chooseReseller' | translate}}"
                                                [(ngModel)]="selectedResellerId">
                                            </fh-reseller-input>
                                        </td>
                                    </tr>
                                    <tr [hidden]="!permissions['Accounts_View'] && reportSubscription.accountId">
                                        <td class="row_opening">
                                            {{ 'general.account' | translate}}
                                            <span class="error" *ngIf="accountVar.invalid">*</span>
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible">
                                            <fh-account-input name="account" #accountVar="ngModel"
                                                (onChanged)="accountChanged($event)" required
                                                [showEmptyAccountsButton]="false"
                                                [selectedResellerId]="selectedResellerId" [tableInline]="true"
                                                placeholder="{{ (selectedResellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                                                [(ngModel)]="reportSubscription.accountId">
                                            </fh-account-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <strong>{{ 'general.report' | translate}}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.selectedReportCategory' | translate}}
                                            <span class="error" *ngIf="entityVar.invalid">*</span>
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible">
                                            <ng-select (change)="listReports()" name="actionId" ngDefaultControl
                                                [searchable]="false" [clearable]="false"
                                                placeholder="{{ 'general.chooseReportEntity' | translate}}"
                                                [(ngModel)]="reportSubscription.selectedReportCategory"
                                                #entityVar="ngModel" class="form-control table-inline"
                                                style="width:100%" required>
                                                <ng-option *ngIf="availableReportCategories[reportCategory.FleetBased]"
                                                    [value]="reportCategory.FleetBased">
                                                    <i class="fa fa-fw fa-car"></i>
                                                    {{ 'enums.reportCategory.' + reportCategory.FleetBased | translate}}
                                                </ng-option>
                                                <ng-option *ngIf="availableReportCategories[reportCategory.TripBased]"
                                                    [value]="reportCategory.TripBased">
                                                    <i class="fa fa-fw fa-route"></i>
                                                    {{ 'enums.reportCategory.' + reportCategory.TripBased | translate}}
                                                </ng-option>
                                                <ng-option *ngIf="availableReportCategories[reportCategory.DriverBased]"
                                                    [value]="reportCategory.DriverBased">
                                                    <i class="fa fa-fw fa-id-card-alt"></i>
                                                    {{ 'enums.reportCategory.' + reportCategory.DriverBased |
                                                    translate}}
                                                </ng-option>
                                                <ng-option
                                                    *ngIf="availableReportCategories[reportCategory.GeofenceBased]"
                                                    [value]="reportCategory.GeofenceBased">
                                                    <i class="fa fa-fw fa-draw-polygon"></i>
                                                    {{ 'enums.reportCategory.' + reportCategory.GeofenceBased |
                                                    translate}}
                                                </ng-option>
                                                <ng-option *ngIf="availableReportCategories[reportCategory.SensorBased]"
                                                    [value]="reportCategory.SensorBased">
                                                    <i class="fa fa-fw fa-sensor"></i>
                                                    {{ 'enums.reportCategory.' + reportCategory.SensorBased |
                                                    translate}}
                                                </ng-option>
                                                <ng-option *ngIf="availableReportCategories[reportCategory.FuelBased]"
                                                    [value]="reportCategory.FuelBased">
                                                    <i class="fa fa-fw fa-gas-pump"></i>
                                                    {{ 'enums.reportCategory.' + reportCategory.FuelBased | translate}}
                                                </ng-option>
                                                <ng-option
                                                    *ngIf="availableReportCategories[reportCategory.MaintenanceBased]"
                                                    [value]="reportCategory.MaintenanceBased" [disabled]=true>
                                                    <i class="fa fa-fw fa-car-mechanic"></i>
                                                    {{ 'enums.reportCategory.' + reportCategory.MaintenanceBased |
                                                    translate}}
                                                </ng-option>
                                                <ng-option
                                                    *ngIf="availableReportCategories[reportCategory.ResellerBased]"
                                                    [value]="reportCategory.ResellerBased">
                                                    <i class="fa fa-fw fa-city"></i>
                                                    {{ 'enums.reportCategory.' + reportCategory.ResellerBased |
                                                    translate}}
                                                </ng-option>
                                            </ng-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.selectedReportType' | translate}}
                                            <span class="error" *ngIf="reportVar.invalid">*</span>
                                        </td>
                                        <td class="row_content" style="position: relative; overflow: visible">
                                            <ng-select name="reportId" ngDefaultControl [searchable]="true"
                                                [clearable]="false"
                                                placeholder="{{ 'general.chooseReport' | translate}}"
                                                [(ngModel)]="reportSubscription.selectedReportType"
                                                [disabled]="reportSubscription.selectedReportCategory == null"
                                                #reportVar="ngModel" (change)="prepareFilters()"
                                                class="form-control table-inline" style="width:100%" required>
                                                <ng-option *ngFor="let report of reports | orderBy : 'name':false"
                                                    [value]="report.id">
                                                    <i class="fa fa-fw">{{report.icon}}</i>
                                                    {{ ('enums.reportType.' + report.id) | translate}}
                                                </ng-option>
                                            </ng-select>
                                        </td>
                                    </tr>
                                    <tr *ngIf="reportSubscription.selectedReportType != 2">
                                        <td class="row_opening">
                                            {{ 'general.reportTemplate' | translate }}
                                            <span class="error" *ngIf="reportTemplateVar.invalid">*</span>
                                        </td>
                                        <td class="row_content" style="position: relative; overflow: visible;">
                                            <ng-select name="templateName" ngDefaultControl [searchable]="true"
                                                [clearable]="false"
                                                placeholder="{{ 'general.chooseReportTemplate' | translate}} ({{reportTemplates.length > 0 ? reportTemplates.length + 1 : 0}})"
                                                [(ngModel)]="selectedReportTemplateId"
                                                (change)="applyColumnConfiguration()"
                                                [disabled]="reportSubscription.selectedReportType == null"
                                                #reportTemplateVar="ngModel" class="form-control table-inline"
                                                style="width: 100%;" required>
                                                <ng-option [value]="'default'">
                                                    {{ 'general.default' | translate}}
                                                </ng-option>
                                                <ng-option
                                                    *ngFor="let reportTemplate of reportTemplates | orderBy : 'name':false"
                                                    [value]="reportTemplate.id">
                                                    {{ reportTemplate.name }} <div *ngIf="reportTemplate.userid > 0"> ({{ 'general.personal' | translate}})</div>
                                                </ng-option>
                                            </ng-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.name' | translate}}
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible">
                                            <input name="name" type="text" style="width:100%"
                                                class="form-control table-inline" [(ngModel)]="reportSubscription.name"
                                                placeholder='{{"general.name" | translate }}'>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </fh-wizard-step>
                <fh-wizard-step stepTitle="general.filterSelection" [validate]="filterForm" icon='fa-filter'
                    (open)="getDefaultColumns()" [padding]=false>
                    <ng-container *ngIf="loadingColumns">
                        <ngx-loading [show]=true></ngx-loading>
                    </ng-container>
                    <div [hidden]="loadingColumns">
                        <div style="padding: 10px" *ngIf="customFields?.length == 0 && 
                             selectedReport?.filterDefinitions?.filterGrouping?.defaultValue != 'true' &&
                             selectedReport?.filterDefinitions?.filterDateGrouping?.defaultValue != 'true' &&
                             selectedReport?.filterDefinitions?.selectGeofenceGroups?.defaultValue != 'true' &&
                             selectedReport?.filterDefinitions?.selectDriverGroups?.defaultValue != 'true' && 
                             selectedReport?.filterDefinitions?.selectAssetGroups?.defaultValue != 'true'">
                            {{ 'general.noFiltersFound' | translate}}
                        </div>
                        <form #filterForm="ngForm" style="width: 100%; border-top: 0px">
                            <table style="width:100%" class="table nowrap dtr-inline no-footer">
                                <tbody *ngIf="selectedReport">
                                    <tr>
                                        <td class="row_opening">
                                            {{ 'general.ignoreEmptyReport' | translate}}
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible;">
                                            <div class="fa_checkbox">
                                                <input id="ignoreEmptyReport" type="checkbox" name="ignoreEmptyReport"
                                                    [(ngModel)]="reportSubscription.parameterConfiguration['ignoreEmptyReport']" />
                                                <label for="ignoreEmptyReport"></label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="selectedReport.filterDefinitions?.selectAssetGroups?.defaultValue == 'true'">
                                        <td class="row_opening">
                                            {{ 'general.assetGroups' | translate}}
                                            <span class="error" *ngIf="assetGroupVar.invalid">*</span>
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible">
                                            <fh-asset-group-item-input name="assetGroups" #assetGroupVar="ngModel"
                                                required [selectedAccountId]="reportSubscription.accountId"
                                                [tableInline]="true" [includeEmptyGroups]="false"
                                                placeholder="{{ (reportSubscription.accountId? 'general.chooseAssetGroups' : 'general.firstChooseAccount') | translate}}"
                                                [(ngModel)]="selectedAssetGroups">
                                            </fh-asset-group-item-input>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="selectedReport.filterDefinitions?.selectDriverGroups?.defaultValue == 'true'">
                                        <td class="row_opening">
                                            {{ 'general.driverGroups' | translate}}
                                            <span class="error" *ngIf="driverGroupVar.invalid">*</span>
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible">
                                            <fh-driver-group-item-input name="driverGroups" #driverGroupVar="ngModel"
                                                required [selectedAccountId]="reportSubscription.accountId"
                                                [tableInline]="true" [includeEmptyGroups]="false"
                                                placeholder="{{ (reportSubscription.accountId ? 'general.chooseDriverGroups' : 'general.firstChooseAccount') | translate}}"
                                                [(ngModel)]="selectedDriverGroups">
                                            </fh-driver-group-item-input>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="selectedReport.filterDefinitions?.selectGeofenceGroups?.defaultValue == 'true'">
                                        <td class="row_opening">
                                            {{ 'general.geofenceGroups' | translate}}
                                            <span class="error" *ngIf="geofenceGroupVar.invalid">*</span>
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible">
                                            <fh-geofence-group-item-input name="geofenceGroups"
                                                #geofenceGroupVar="ngModel" required [includeEmptyGroups]="false"
                                                [selectedAccountId]="reportSubscription.accountId" [tableInline]="true"
                                                placeholder="{{ (reportSubscription.accountId ? 'general.chooseGeofenceGroups' : 'general.firstChooseAccount') | translate}}"
                                                [(ngModel)]="selectedGeofenceGroups">
                                            </fh-geofence-group-item-input>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let field of customFields | orderBy : 'name':false">
                                        <td class="row_opening">
                                            {{ 'general.' + field.name | translate}}
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible;">
                                            <container-element [ngSwitch]="field.type">
                                                <div *ngSwitchCase="'enum'">
                                                    <ng-select name="enum" ngDefaultControl [searchable]="false"
                                                        [clearable]="false" [multiple]="true"
                                                        [(ngModel)]="reportSubscription.parameterConfiguration[field.name]"
                                                        class="form-control table-inline"
                                                        style="width:100%; height: unset;" required>
                                                        <ng-option
                                                            *ngFor="let option of field.options | orderBy : 'Key':false"
                                                            [value]="option.Key">
                                                            {{ ('enums.' + field.name + '.' + option.Key) | translate}}
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                                <div *ngSwitchCase="'bool'" class="fa_checkbox">
                                                    <input [id]="field.name" type="checkbox" [name]="field.name"
                                                        [(ngModel)]="reportSubscription.parameterConfiguration[field.name]" />
                                                    <label [for]="field.name"></label>
                                                </div>
                                                <input *ngSwitchCase="'int'" [name]="field.name" type="number"
                                                    style="width: 100%;" class="form-control table-inline"
                                                    [(ngModel)]="reportSubscription.parameterConfiguration[field.name]"
                                                    placeholder='{{ "general." + field.name | translate }}'>
                                                <span *ngSwitchDefault></span>
                                            </container-element>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="selectedReport.filterDefinitions?.filterGrouping?.defaultValue == 'true'">
                                        <td class="row_opening">
                                            {{ 'general.grouping' | translate}}
                                            <span class="error" *ngIf="filterGroupingVar.invalid">*</span>
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible">
                                            <ng-select name="grouping" ngDefaultControl [searchable]="false"
                                                [clearable]="false"
                                                placeholder="{{ 'general.chooseAction' | translate}}"
                                                [(ngModel)]="reportSubscription.parameterConfiguration.filterGrouping"
                                                #filterGroupingVar="ngModel" class="form-control table-inline"
                                                style="width:100%" required>
                                                <ng-option [value]="0">
                                                    <i class="fa fa-fw fa-ban"></i>
                                                    {{ 'enums.grouping.0' | translate}}
                                                </ng-option>
                                                <ng-option [value]="1">
                                                    <i class="fa fa-fw fa-car"></i>
                                                    {{ 'enums.grouping.1' | translate}}
                                                </ng-option>
                                                <ng-option [value]="2">
                                                    <i class="fa fa-fw fa-draw-polygon"></i>
                                                    {{ 'enums.grouping.2' | translate}}
                                                </ng-option>
                                                <ng-option [value]="3">
                                                    <i class="fa fa-fw fa-gavel"></i>
                                                    {{ 'enums.grouping.3' | translate}}
                                                </ng-option>
                                                <ng-option [value]="4">
                                                    <i class="fa fa-fw fa-id-card-alt"></i>
                                                    {{ 'enums.grouping.4' | translate}}
                                                </ng-option>
                                            </ng-select>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="selectedReport.filterDefinitions?.filterDateGrouping?.defaultValue == 'true'">
                                        <td class="row_opening">
                                            {{ 'general.dateGrouping' | translate}}
                                            <span class="error" *ngIf="dateGroupingVar.invalid">*</span>
                                        </td>
                                        <td class="row_content" style="position:relative; overflow: visible">
                                            <ng-select name="grouping" ngDefaultControl [searchable]="false"
                                                [clearable]="false"
                                                placeholder="{{ 'general.chooseAction' | translate}}"
                                                [(ngModel)]="reportSubscription.parameterConfiguration.filterDateGrouping"
                                                #dateGroupingVar="ngModel" class="form-control table-inline"
                                                style="width:100%" required>
                                                <ng-option [value]="0">
                                                    <i class="fa fa-fw fa-clock"></i>
                                                    {{ 'enums.dateGrouping.0' | translate}}
                                                </ng-option>
                                                <ng-option [value]="1">
                                                    <i class="fa fa-fw fa-clock"></i>
                                                    {{ 'enums.dateGrouping.1' | translate}}
                                                </ng-option>
                                                <ng-option [value]="2">
                                                    <i class="fa fa-fw fa-clock"></i>
                                                    {{ 'enums.dateGrouping.2' | translate}}
                                                </ng-option>
                                                <ng-option [value]="3">
                                                    <i class="fa fa-fw fa-clock"></i>
                                                    {{ 'enums.dateGrouping.3' | translate}}
                                                </ng-option>
                                                <ng-option [value]="4">
                                                    <i class="fa fa-fw fa-clock"></i>
                                                    {{ 'enums.dateGrouping.4' | translate}}
                                                </ng-option>
                                            </ng-select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </fh-wizard-step>
                <fh-wizard-step [disabled]="!permissions['FleetManagementReporting_HasReportColumnSelection']"
                    stepTitle="general.template" [validate]="columnForm" icon='fa-table-columns' [padding]=false
                    (open)="applyColumnConfiguration()">
                    <form #columnForm="ngForm" style="width: 100%; border-top: 0px">
                        <div *ngIf="reportSubscription.selectedReportType == 2" style="padding: 15px">
                            Not applicable
                        </div>

                        <fh-column-selector [defaultSourceColumns]="defaultSourceColumns" [loading]="loadingColumns"
                            (reportTemplateChange)="selectedReportTemplateId = $event"
                            (updateReportTemplateEmitter)="updateReportTemplate($event)"
                            (saveReportTemplateEmitter)="saveReportTemplate(reportSubscription.selectedReportType, $event)"
                            [selectedReportTemplateId]="selectedReportTemplateId" [reportTemplates]="reportTemplates"
                            [(template)]="reportSubscription.template">
                        </fh-column-selector>
                    </form>

                </fh-wizard-step>
                <fh-wizard-step stepTitle="general.scheduling" [validate]="scheduleForm" icon='fa-calendar'
                    [padding]=false>
                    <form #scheduleForm="ngForm" style="width: 100%; border-top: 0px">
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        <strong>{{ 'general.scheduling' | translate}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening">
                                        {{ 'general.reportSubscriptionType' | translate}}
                                        <span class="error" *ngIf="scheduleVar.invalid">*</span>
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        <ng-select name="subscriptionType" ngDefaultControl [searchable]="false"
                                            [clearable]="false" placeholder="{{ 'general.chooseSchedule' | translate}}"
                                            [(ngModel)]="reportSubscription.subscriptionType" #scheduleVar="ngModel"
                                            class="form-control table-inline" style="width:100%" required>
                                            <ng-option [value]="1">
                                                <i class="fa fa-fw fa-bolt"></i>
                                                {{ 'general.generateDirect' | translate}}
                                            </ng-option>
                                            <ng-option [value]="2">
                                                <i class="fa fa-fw fa-calendar"></i>
                                                {{ 'general.createSchedule' | translate}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                </tr>
                                <tr
                                    *ngIf="selectedReport?.filterDefinitions?.selectDateRange && reportSubscription.subscriptionType == 1">
                                    <td class="row_opening">
                                        {{ 'general.date' | translate}}
                                        <span class="error" *ngIf="dateVar.invalid">*</span>
                                    </td>
                                    <td class="row_content" style="position:relative; overflow: visible">
                                        <input name="Daterangepicker" placeholder="Daterangepicker" style="width:100%"
                                            placeholder="Datepicker" type="text" [bsConfig]="dpConfig"
                                            class="form-control table-inline" bsDaterangepicker #dateVar="ngModel"
                                            required (onHidden)="dateChanged('hidden')"
                                            [(ngModel)]="daterangepickerModel">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <fh-cron-editor *ngIf="reportSubscription.subscriptionType == 2" name="cron-editor"
                            [(cron)]="reportingInterval" [disabled]="isCronDisabled" [options]="cronOptions">
                        </fh-cron-editor>
                        <table style="width:100%" class="table nowrap dtr-inline no-footer">
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        <strong>{{ 'general.outputMessage' | translate}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="row_opening"> {{ 'general.notifyOnCompletion' | translate}}</td>
                                    <td class="row_content">
                                        <div class="fa_checkbox">
                                            <input [checked]="false" id="sendOutputMessage" type="checkbox"
                                                name="sendOutputMessage" [(ngModel)]="sendOutputMessage" />
                                            <label for="sendOutputMessage">
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="sendOutputMessage">
                                    <td class="row_opening"> {{ 'general.userContactGroup' | translate}}
                                        <span class="error" *ngIf="userContactGroupVar?.invalid">*</span>
                                    </td>
                                    <td class="row_content" style="overflow: visible;">
                                        <fh-user-contact-group-item-input name="userContactGroup"
                                            #userContactGroupVar="ngModel" required [includeSystemGroups]="false"
                                            [selectedAccountId]="reportSubscription.accountId" [tableInline]="true"
                                            placeholder="{{ (reportSubscription.accountId? 'general.chooseUserContactGroup' : 'general.firstChooseAccount') | translate}}"
                                            [(ngModel)]="selectedUserContactGroups">
                                        </fh-user-contact-group-item-input>
                                    </td>
                                </tr>
                                <tr *ngIf="sendOutputMessage">
                                    <td class="row_opening"> {{ 'general.useCustomSubject' | translate}}</td>
                                    <td class="row_content">
                                        <div class="fa_checkbox">
                                            <input [checked]="true" id="useCustomSubject" type="checkbox"
                                                name="useCustomSubject" [(ngModel)]="useCustomSubject" />
                                            <label for="useCustomSubject">
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="sendOutputMessage && useCustomSubject">
                                    <td class="row_opening"> {{ 'general.subject' | translate}}
                                        <span class="error" *ngIf="subjectTemplateVar?.invalid">*</span>
                                    </td>
                                    <td class="row_content">
                                        <input type="text" name="subject" class="form-control table-inline"
                                            placeholder="{{ 'placeholder.subjectTemplate' | translate}}" required
                                            [(ngModel)]="reportSubscription.notificationConfiguration.subject"
                                            minlength="3" #subjectTemplateVar="ngModel">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="ibox-content" style="position: relative;" *ngIf="sendOutputMessage">
                            <textarea id="textbox" name="text" #textVar="ngModel" class="noresize stickypad"
                                style="height:150px; width:100%; resize: none"
                                [(ngModel)]="reportSubscription.notificationConfiguration.body" required></textarea>
                        </div>
                    </form>
                </fh-wizard-step>
                <fh-wizard-step stepTitle="general.confirm" icon='fa-check' [padding]=false>
                    <ngx-loading [show]="processing"></ngx-loading>
                    <table style="width:100%" class="table nowrap dtr-inline no-footer">
                        <tbody>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.account' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    {{ selectedAccount?.name }}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.selectedReportCategory' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    {{ ('enums.reportCategory.' + reportSubscription.selectedReportCategory) |
                                    translate}}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.selectedReportType' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    {{ ('enums.reportType.' + reportSubscription.selectedReportType) | translate}}
                                </td>
                            </tr>
                            <tr>
                                <td class="row_opening">
                                    {{ 'general.name' | translate}}
                                </td>
                                <td class="row_content" style="position:relative; overflow: visible">
                                    {{ reportSubscription.name != '' ? reportSubscription.name : '-' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fh-wizard-step>
            </fh-wizard>
        </div>
    </div>
</div>