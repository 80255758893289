import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountInventory } from 'app/models/account.model';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { AccountService } from 'app/services/account/account.service';

@Component({
    selector: 'fh-account-log',
    templateUrl: 'accountLog.template.html'
})
export class AccountLogViewComponent implements OnInit {
    accountLog: AuditLog[];
    sub;
    account: AccountInventory;
    loading = false;

    constructorName = 'AccountLogViewComponent';

    error: any;
    success: any;
    accountId: any;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private accountService: AccountService, private route: ActivatedRoute, private router: Router) {
        this.account = null;
        this.accountLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.account = new AccountInventory;
        this.account.id = ''

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.accountId = id;
            this.accountService.getAccountById(id).subscribe(account => {
                this.account = account;

                if (this.account == null) {
                    this.router.navigate(['/Accounts/Overview'])
                }

                this.auditLogService.getAuditLogByAccount(id).subscribe(deviceLog => {
                    console.log('loading done');
                    this.accountLog = deviceLog;
                    this.loading = false;
                }, error => {
                    this.error = error;
                    this.loading = false;
                });
            });
        });
    }
}
