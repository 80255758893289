
export class Warehouse {

    public id: any;

    public name: string;
    public description: any;
    public address: any;
    public city: any;
    public licenseNumber: any;
    public landAreaInSquareMeter: any;
    public phone: any;
    public email: any;
    public managerMobile: any;
    public licenseExpiryDate: any;
    public licenseIssueDate: any;
    public properties = new Properties();
    public resellerDescription;
    public resellerId;
    public companyName: any;
    public accountId: any;
    public latitude: any;
    public longitude: any;
    public identifier: any;

    public referenceId: any;
    public geoJson: any;
    public landCoordinates: LandCoordinate[];
}

export class LandCoordinate {
    public x: number;
    public y: number;
}

export class Properties {
    public wasl = new RegisterWarehouse();
}

export class RegisterWarehouse {
    public referenceKey = '';
    public city = '';
    public landCoordinates = [];
    public landAreaInSquareMeter = '';
    public licenseNumber = '';
    public licenseIssueDate = '';
    public licenseExpiryDate = '';
    public managerMobile = '';
    public phone = '';
    public email = '';
    public registerDateSfda = '';
}

export class WarehouseZone {
    public zoneId: any;
    public identifier: any;
    public name: any;
    public warehouseId: any;
    public partitionKey: any;
    public rowKey: any;
    public type: any;
}

export class WarehouseSensor {
    public devEUI: any;
    public name: any;
    public lowerTemperatureThreshold: any;
    public upperTemperatureThreshold: any;
    public lowerHumidityThreshold: any;
    public upperHumidityThreshold: any;
    public zoneId: any;
    public rowKey: any;
    public partitionKey: any;
}

