<div class="ibox-content slick" style="position:relative">
    <table style="width:100%" class="table table-fixed nowrap dtr-inline no-footer">
        <tbody *ngIf="!userRole">
            <tr>
                <td colspan="4">
                    {{ 'general.noUserRole' | translate}}
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="userRole">
            <tr *ngIf="formMode != 2">
                <td class="row_opening">
                    {{ 'general.resellerDescription' | translate}}
                </td>
                <td class="padding-readonly-correct row_content">
                    <a class="secondary link_bolder"
                        href="/#/ResellerDetails/Index/{{userRole?.resellerId}}">{{userRole?.resellerDescription }}</a>
                </td>
            </tr>
            <tr *ngIf="formMode != 2">
                <td class="row_opening">
                    {{ 'general.companyName' | translate}}
                </td>
                <td *ngIf="formMode != 2" class="padding-readonly-correct row_content">
                    <a class="secondary link_bolder"
                        href="/#/AccountDetails/Index/{{userRole?.accountId}}">{{userRole?.companyName }}</a>
                </td>
            </tr>
            <tr *ngIf="formMode == 2">
                <td class="row_opening elipses_overflow">
                    {{ 'general.resellerDescription' | translate}}

                    <span class="error" *ngIf="resellerVar?.invalid">*</span>
                </td>
                <td class="row_content" style="position:relative; overflow: visible">
                    <fh-reseller-input name="reseller" #resellerVar="ngModel" (onChanged)="resellerChanged($event)"
                        [required]="!permissions['Platform_IsReseller']" [tableInline]="true"
                        placeholder="{{ 'general.chooseReseller' | translate}}" [(ngModel)]="userRole.resellerId">
                    </fh-reseller-input>
                </td>
            </tr>
            <tr *ngIf="formMode == 2">
                <td class="row_opening">
                    {{ 'general.companyName' | translate}}
                    <span class="error" *ngIf="accountVar.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content" style="position:relatives;overflow:visible">
                    <fh-account-input name="account" #accountVar="ngModel" (onChanged)="accountChanged($event)"
                        [required]="!permissions['Platform_IsReseller']" [selectedResellerId]="userRole.resellerId"
                        [tableInline]="true"
                        placeholder="{{ (userRole.resellerId ? 'general.chooseAccount' : 'general.firstChooseReseller') | translate}}"
                        [(ngModel)]="userRole.accountId">
                    </fh-account-input>
                </td>
            </tr>
            <tr>
                <td class="row_opening">
                    {{ 'general.name' | translate}}
                    <span class="error" *ngIf="name.invalid && formMode != 1">*</span>
                </td>
                <td class="row_content">
                    <input type="text" [readonly]="formMode == 1" name="name" class="form-control table-inline"
                        placeholder="{{ 'placeholder.name' | translate}}" [(ngModel)]="userRole.name" required
                        minlength="4" #name="ngModel">
                </td>
            </tr>
        </tbody>
    </table>
</div>