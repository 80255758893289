<ng-select [loading]="loadingGeofenceGroups" ngDefaultControl [searchable]="true" [clearable]="clearable"
    style="height: unset;" [disabled]="geofenceGroups.length == 0" [readonly]="readonly" [virtualScroll]="true"
    placeholder="{{placeholder}} ({{geofenceGroups.length}})" #input [multiple]="multiple" [(ngModel)]="value"
    [searchFn]="searchFn" [items]="geofenceGroups" bindLabel="geofenceName" bindValue="id" class="form-control"
    groupBy="geofenceGroupItems" [selectableGroup]="true" [groupValue]="groupValueFn" [compareWith]="compareFn" [closeOnSelect]="false"
    [ngClass]="tableInline === true ? 'table-inline' : '' "  (ngModelChange)="geofenceGroupChanged()">
    <ng-template ng-label-tmp let-geofencegroup="item" let-clear="clear">
        <span *ngIf="geofencegroup.displayName">
            <i class="fas fa-fw fa-layer-group"
                title="Group: {{geofencegroup.displayName}} {{geofencegroup.length}}"></i> {{
            geofencegroup.displayName
            }} ({{geofencegroup.length}})</span>
        <span *ngIf="geofencegroup.geofenceName">
            <i class="fa fa-fw fa-draw-polygon" title=" Geofence: {{geofencegroup.geofenceName}}"></i> {{
            geofencegroup.geofenceName }} </span>
        <span class="ng-value-icon right" (click)="clear(geofencegroup)" aria-hidden="true">&times;</span>
    </ng-template>
    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <i class='far fa-fw fa-lg' [ngClass]="item$.selected ? 'fa-check-square' : 'fa-square'"></i>
        {{item.displayName}}
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <i class="fa fa-fw fa-draw-polygon" title=" Geofence: {{item.geofenceName}}"></i> {{item.geofenceName}}
    </ng-template>
</ng-select>
