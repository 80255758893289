export class GeofenceState {
    display: boolean;
    startUtc: any;
    date: number;
    geofenceId: any;
    assetId: any;
    id: any;
    header: any;
    event: any;
    icon: any;
    footer: string;
    iconFontColor: any;
    markerColor: any;
    description: any;
    duration: any;
    iconBackgroundClass: string;
    iconUrl: string;
    rtcBasedTimestamp: any;
}
