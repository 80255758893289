import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'fh-lang-selector',
    template: `
        <div id="lang-selector" class="form-group">
            <ng-select class="form-control" (change)="selectLang($event)">
                <ng-option *ngFor="let lang of langs" value="{{lang}}" class="lang-{{lang}}">{{lang}}</ng-option>
            </ng-select>
        </div>
    `
})
export class LangSelectorComponent {
    public langs;
    public currentLang;
    private translator;

    constructor(private translate: TranslateService) {
        this.langs = translate.getLangs();
        this.currentLang = this.translate.currentLang;
        // this.currentLang = this.translator.getBrowserLang();
    }

    /**
     * @desc Triggered when the select's value changes
     * @param event
     */
    public selectLang(event): void {
        this.currentLang = event.target.value;
        this.translator.use(this.currentLang);
    }
}
