import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import 'jquery-slimscroll';

import { colorArray2, roundAsString } from 'app/common/globals';

import { Subscription } from 'rxjs/internal/Subscription';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { DeviceService } from 'app/services/device/device.service';
import { AssetGroupsService } from 'app/services/asset/assetGroups.service';
import { KeyValue } from '@angular/common';
import { TripService } from 'app/services/trip/trip.service';
import { TranslateService } from '@ngx-translate/core';
import { DriverService } from 'app/services/driver/driver.service';
import { DriverGroupsService } from 'app/services/driver/driverGroups.service';
import { type FleetOverviewModeCase, FleetOverviewStoreService } from 'app/services/fleetoverview/fleetoverview-store.service';
import { colorMapper } from 'app/common/leafletGlobals';
import { getDefaultDpConfig } from 'app/common/gridhelper';
import { EntityType } from 'app/common/enums';

import * as Moment from 'moment';

declare var L;
declare var $;

@Component({
  selector: 'fh-overview-navigation',
  templateUrl: 'overviewNavigation.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewNavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  hasFuel: any;
  // Permissions
  hasReporting: any;
  hasCalculationSettings: any;
  hasLocations: any;
  hasTrips: any;
  hasAdvice: any;
  hasSchedule: any;
  hasSettings: any;
  hasAssets: any;
  hasDevices: any;
  hasCustomers: any;

  showId: any;
  id: number;
  private sub: Subscription;
  children = false;
  permissions: {};
  isImpersonated: boolean;

  colorArray = colorArray2;

  selectedDevice;
  filter = '';
  assetGroups = new Map();
  driverGroups = new Map();

  assetGroupSelection = new Map();
  driverGroupSelection = new Map();
  deviceSelection = new Map();
  driverSelection = new Map();

  calculatedDeviceState = new Map<number, any>();
  lastCommunication = new Map<number, string>();

  cachedTrips = new Map<number, L.FeatureGroup<any>>();

  hiddenAssets = new Map<number, boolean>();
  liveHidden = new Map<number, boolean>();

  maxListItems = 20;

  maxGroups = 100;

  selectedHistoryDevice;
  selectedLiveGroup;
  mode: FleetOverviewModeCase = 'Overview';
  tab = 'Assets';
  history = new Map();

  live = new Map();

  currentDate: number;

  loadingAssets = false;

  timezoneIana: string;

  daterangepickerModel: Date[] = [];
  dpConfig;

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private deviceService: DeviceService,
    private assetGroupService: AssetGroupsService,
    private tripService: TripService,
    private driverService: DriverService,
    private driverGroupService: DriverGroupsService,
    private fleetOverviewStoreService: FleetOverviewStoreService,
    private translateService: TranslateService,
  ) {
    this.id = 0;
    this.showId = '';

    this.timezoneIana = authentication.getTimeZoneIana();

    this.daterangepickerModel = [
      Moment().subtract(1, 'week').startOf('day').toDate(),
      Moment().add(0, 'days').endOf('day').toDate(),
    ];

    this.dpConfig = getDefaultDpConfig(Moment, authentication);
  }

  ngAfterViewInit() {
    if ($('body').hasClass('fixed-sidebar')) {
      $('.sidebar-collapse')['slimscroll']({
        height: '100%',
      });
    }
  }

  ngOnInit() {
    this.isImpersonated = this.authentication.getIsImpersonated();

    this.permissions = this.authentication.permissions;

    this.translateService.get('general.date').subscribe(_ => {

      this.fleetOverviewStoreService.deviceState$.subscribe(states => {
        const newStates = new Map<number, any>();
        for (const [id, state] of states) {
          newStates.set(id, { id: state, get color() { return colorMapper(this.id) } });
        }

        this.calculatedDeviceState = newStates;
        this.cd.markForCheck();
      });

      this.fleetOverviewStoreService.lastCommunication$.subscribe(states => {
        this.lastCommunication = states;
        this.cd.markForCheck();
      });

      this.driverGroupService.getDriverGroups(null, false).subscribe(driverGroups => {
        for (const driverGroup of driverGroups) {
          const name = driverGroup.displayName + ' - ' + driverGroup.companyName;
          this.driverGroups.set(driverGroup.id, { _order: name, name, items: [] });
        }

        this.driverService.getDrivers(true).subscribe((drivers) => {
          for (const driver of drivers) {
            for (const { id } of driver.driverGroups) {
              const driverGroup = this.driverGroups.get(+id);
              if (driverGroup !== undefined) {
                driverGroup.items.push({ id: driver.id, name: driver.name });
              }
            }
          }
        });
      });

      this.loadingAssets = true;

      this.assetGroupService.getAssetGroups(null, true).subscribe(assetGroups => {
        for (const assetGroup of assetGroups) {
          const name = assetGroup.displayName + ' - ' + assetGroup.companyName;

          const that = this;

          const groupItems = [];
          for (const groupItem of assetGroup.assetGroupItems) {
            groupItems.push({ get status() { return that.calculatedDeviceState.get(this.id)?.id }, get time() { return +that.lastCommunication.get(this.id) }, id: groupItem.deviceId, name: groupItem.assetName });
          }

          this.assetGroups.set(assetGroup.id, { _order: name, name, items: groupItems });

          this.loadingAssets = false;

          this.cd.markForCheck();

          setTimeout(() => {
            $('#side-menu')['metisMenu']();
          }, 1);
        }

        // this.deviceService
        //   .getDevicesLimited(true, null, null, true, false, true)
        //   .subscribe((devices) => {
        //     console.log('devices', devices);
        //     for (const device of devices) {
        //       if ((device?.asset?.name?.length ?? 0) === 0) {
        //         continue;
        //       }

        //       if (device.asset.id === 14994 || +device.id === 14994) {
        //         console.log('device', device, 14994);
        //       }

        //       for (const id of device.asset.assetGroupIds) {
        //         const assetGroup = this.assetGroups.get(id);
        //         if (assetGroup !== undefined) {
        //           assetGroup.items.push({ id: device.id, name: device.asset.name });
        //         }
        //       }
        //     }

        //     this.loadingAssets = false;

        //     this.cd.markForCheck();

        //     setTimeout(() => {
        //       $('#side-menu')['metisMenu']();
        //     }, 1);
        //   });
      });
    });

    this.router.events.subscribe((params) => {
      // console.log(params);
      this.cd.markForCheck();
    });

    if (this.route.children.length > 0) {
      this.children = true;

      this.sub = this.route.children[0].params.subscribe((params) => {
        this.id = params['id'];
        this.showId = this.id ? this.id.toString().substring(0, 4) : '';
        this.cd.markForCheck();
      });
    }
  }

  changeMode(updatedMode: FleetOverviewModeCase) {
    // Update Assets always on mode change
    this.fleetOverviewStoreService.hiddenAssets.next(this.hiddenAssets);

    this.fleetOverviewStoreService.fleetOverviewMode = updatedMode;
    this.mode = updatedMode;

    switch (updatedMode) {
      case 'History':
        this.getHistory();
        break;
      case 'Live':
        this.getLive();
        break;
      default:
        break;
    }
  }

  changeTab(updatedTab: string) {
    this.tab = updatedTab;

    switch (this.tab) {
      case 'Assets':
        // this.getHistory();
        break;
      default:
        break;
    }
  }

  getLive() {
    this.selectedLiveGroup = undefined;
    this.liveHidden = new Map();

    for (const [_, value] of this.assetGroups) {
      for (const item of value.items) {
        this.liveHidden.set(item.id, true);
      }
    }

    this.live = new Map();
    for (const [id, predicate] of this.assetGroupSelection) {
      if (predicate === true) {
        const assetGroup = this.assetGroups.get(id);

        if (assetGroup === undefined) {
          continue;
        }

        const filteredItems = assetGroup.items.filter(value => {
          for (const [deviceId, item] of this.deviceSelection) {
            if (item.checked === true) {
              if (value.id === deviceId) {
                return true;
              }
            }
          }

          return false;
        });

        this.live.set(id, { _order: assetGroup._order, name: assetGroup.name, items: filteredItems});
      }
    }

    for (const [id, item] of this.deviceSelection) {
      if (item.checked === true) {
        this.liveHidden.delete(id);
      }
    }

    this.fleetOverviewStoreService.hiddenAssets.next(this.liveHidden);
  }

  getHistory() {
    this.selectedHistoryDevice = undefined;
    this.history = new Map();

    // for (const [id, predicate] of this.assetGroupSelection) {
    //   if (predicate === true) {
    //     const assetGroup = this.assetGroups.get(id);

    //     if (assetGroup === undefined) {
    //       continue;
    //     }

    //     for (const asset of assetGroup.items) {
    //       this.history.set(asset.id, { name: asset.name, trips: [] });
    //     }
    //   }
    // }


    if (this.tab === 'Assets') {
      for (const [id, item] of this.deviceSelection) {
        if (item.checked === true) {
          this.history.set(`${EntityType[EntityType.Asset]}_${id}`, { id, name: item.name, loading: true, trips: [] });
        }
      }
    } else if (this.tab === 'Drivers') {
      for (const [id, item] of this.driverSelection) {
        if (item.checked === true) {
          this.history.set(`${EntityType[EntityType.Driver]}_${id}`, { id, name: item.name, loading: true, trips: [] });
        }
      }
    }

    console.log('assetGroupSelection', this.assetGroupSelection);
    console.log('deviceSelection', this.deviceSelection);
    console.log('history', this.history);
  }

  activateHistoryToggle(updatedSelectedDevice, id) {
    if (this.selectedHistoryDevice === updatedSelectedDevice) {
      this.selectedHistoryDevice = undefined;
      return;
    }

    this.selectedHistoryDevice = updatedSelectedDevice;

    const tripsForAsset = this.history.get(updatedSelectedDevice);
    if (tripsForAsset === undefined || tripsForAsset.trips.length > 0) {
      return;
    }

    let tripsMethod = this.tripService.getTripsForDevice.bind(this.tripService);
    if (updatedSelectedDevice.startsWith(EntityType[EntityType.Driver])) {
      tripsMethod = this.tripService.getTripsForDriver.bind(this.tripService);
    }

    tripsMethod(id, Moment.utc(this.daterangepickerModel[0]).tz(this.timezoneIana).startOf('day'), Moment.utc(this.daterangepickerModel[1]).tz(this.timezoneIana).endOf('day'))
      .subscribe(trips => {
      this.currentDate = null;

      trips.sort((a, b) => new Date(b.beginDateTime).getTime() - new Date(a.beginDateTime).getTime());

      this.history.set(updatedSelectedDevice, { name: tripsForAsset.name, loading: false, trips });

      // this.trips = trips;

      // const realTrips = trips.filter(x => x.tripMethod !== 5);
      // if (realTrips.length > 0) {
      //     this.clearSelectionAndGetTripData(null, realTrips[0])
      // }

      // this.totalItems = this.trips.length;
      // this.smallnumPages = Math.ceil(this.totalItems / 10);
      // this.loadingTrips = false;

      this.cd.markForCheck();
    }, error => {
      // this.loadingTrips = false;
      // this.error = error;
      this.cd.markForCheck();
    });
  }

  valueAscOrder = (a: KeyValue<number, { [id: string]: string }>, b: KeyValue<number, { [id: string]: string }>): number => {
    return a.value._order.localeCompare(b.value._order);
  }

  selectAsset(x) {
    this.selectedDevice = x;
    window.dispatchEvent(new CustomEvent('centerMap', { detail: this.selectedDevice, bubbles: true }));
  }

  openTrip(trip, ident) {
    trip.isOpen = !trip.isOpen;

    if (trip.locations !== undefined) {
      trip.loading = false;

      this.cd.markForCheck();
      return;
    }

    trip.loading = true;
    this.cd.markForCheck();

    this.tripService.getTripDetails(trip.id).subscribe(result => {
      const locations = result.messages.sort((a, b) => b.rtcBasedTimestamp - a.rtcBasedTimestamp);
      trip.locations = locations;

      const featureGroup = this.cachedTrips.get(trip.id);

      if (featureGroup !== undefined) {
        this.fleetOverviewStoreService.removeTrip.next([featureGroup]);
      }

      const tripFeatureGroup = this.renderFeatureGroup(locations, ident);

      this.cachedTrips.set(trip.id, tripFeatureGroup);

      trip.plotted = true;
      trip.loading = false;

      this.cd.markForCheck();

      this.fleetOverviewStoreService.selectedTrip.next([tripFeatureGroup]);
    });
  }

  renderFeatureGroup(locations, ident): L.FeatureGroup<any> {
    const pointList = [];

    locations.forEach(location => {
      if (location.latitude !== 0) {
        pointList.push(new L.LatLng(location.latitude, location.longitude));
      }
    });

    const color = this.colorArray[ident % this.colorArray.length];

    const startIcon = new L['NumberMarker'].Icon({
      backgroundColor: color,
      className: 'm360',
      color: '#fff',
      number: ident,
    });

    const startMarker = L.marker(new L.LatLng(pointList[0].lat, pointList[0].lng), { icon: startIcon });
    // this.leafletMapComponent.oms.addMarker(this.startMarker);

    const finishIcon = L.icon({
      iconUrl: 'assets/images/icons/end.png',
      className: 'markerEnd',
    });

    // const iconPath = getIconPath(this.device.asset?.icon)[1];

    // const theAssetIcon = L.icon({
    //     iconUrl: iconPath,
    //     // className: 'markerPlayTrip',
    //     iconAnchor: [16, 16],
    // });

    // { icon: isOngoing ? theAssetIcon : finishIcon });
    const endMarker = L.marker(new L.LatLng(pointList[pointList.length - 1].lat, pointList[pointList.length - 1].lng), { icon: finishIcon });

    const tripPolyLine = new L.Polyline(pointList, {
      color,
      weight: 2,
      opacity: 1,
      smoothFactor: 1,
      dashArray: '10, 5'
    });

    const tripPolyLine2 = new L.Polyline(pointList, {
      color: '#fff',
      weight: 6,
      opacity: 0.8,
    });

    const tripPolyLine3 = new L.Polyline(pointList, {
      color: '#000',
      weight: 9,
      opacity: 0.3,
    });

    const decoratorLine = L['polylineDecorator'](tripPolyLine, {
      patterns: [
        { offset: 24, repeat: 100, symbol: L['Symbol']['arrowHead']({ pixelSize: 15, pathOptions: { fillOpacity: 0.9, color, weight: 0, stroke: true } }) }
      ]
    });

    return L.featureGroup([startMarker, endMarker, tripPolyLine3, tripPolyLine2, tripPolyLine, decoratorLine]);
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) === 1;
  }

  changeTripVisibility(trip, ident) {
    if (!('plotted' in trip)) {
      this.openTrip(trip, ident);
      return;
    }

    trip.plotted = !trip.plotted;

    const featureGroup = this.cachedTrips.get(trip.id);

    if (featureGroup === undefined) {
      return;
    }

    if (trip.plotted === false) {
      this.fleetOverviewStoreService.removeTrip.next([featureGroup]);
    } else {
      this.fleetOverviewStoreService.selectedTrip.next([featureGroup]);
    }

    console.log('changeTripVisibility', trip.id);
  }

  renderTrips(trips: any[]) {
    this.currentDate = null;
    return trips;
  }

  isNewDate(trip) {
    const checkDate = Moment(trip.beginDateTime).date();
    if (checkDate === this.currentDate) {
      return false;
    } else {
      this.currentDate = checkDate;
      return true;
    }
  }

  shouldToggleGroup(event, deviceId) {
    for (const [key, value] of this.assetGroups) {
      if (value.items === undefined || value.items.find(x => x.id === deviceId) === undefined) {
        continue;
      }

      if (event === true) {
        this.assetGroupSelection.set(key, true);
        continue;
      }

      let anyChecked = false;

      for (const item of value.items) {
        if (this.deviceSelection.get(item.id)?.checked === true) {
          anyChecked = true;
        }
      }

      if (anyChecked === false) {
        this.assetGroupSelection.set(key, false);
      }
    }
  }

  selectGroup(group, event) {
    this.assetGroupSelection.set(group.key, event);

    for (const item of group.value.items) {
      this.deviceSelection.set(item.id, { checked: event, name: item.name });
      this.shouldToggleGroup(event, item.id);
    }
  }

  watchGroupSelected(items: any[]) {
    for (const item of items) {
      if (this.deviceSelection.get(item.id) === undefined || this.deviceSelection.get(item.id).checked === false) {
        return false;
      }
    }

    return true;
  }

  watchGroupVisibility(collection: Map<number, boolean>, items: any[]) {
    for (const item of items) {
      if (collection.get(item.id) === true) {
        return false;
      }
    }

    return true;
  }

  changeGroupVisibility(collection: Map<number, boolean>, state: boolean, items: any[]) {
    for (const item of items) {
      collection.set(item.id, !state);
    }

    this.fleetOverviewStoreService.hiddenAssets.next(collection);
  }

  changeVisibility(collection: Map<number, boolean>, id: number) {
    collection.set(id, !collection.get(id));
    this.fleetOverviewStoreService.hiddenAssets.next(collection);
  }

  hasAnySelected(selected: Map<any, any>[]) {
    for (const map of selected) {
      for (const [_, predicate] of map) {
        if (predicate !== true && predicate?.checked !== true) {
          continue;
        }

        return true;
      }
    }

    return false;
  }

  dateChanged(event) {
    console.log('date', event);
    this.getHistory();
  }

  filterMarkers() {
    if (this.tab !== 'Assets') {
      return;
    }

    this.fleetOverviewStoreService.searchFilter.next(this.filter);
  }

  actualRound(value, decimals) {
    return roundAsString(value, decimals);
  }

  getFleetOverviewState() {
    return this.fleetOverviewStoreService.fleetOverviewState$;
  }

  getFleetOverviewMode() {
    return this.fleetOverviewStoreService.fleetOverviewMode$;
  }

  log(e) {
    console.log(e);
  }

  ngOnDestroy() {
    if (this.children) {
      this.sub.unsubscribe();
    }
  }
}
