import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { UserContactGroup } from 'app/models/userContactGroup.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-user-contact-group-details',
    templateUrl: 'userContactGroupDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class UserContactGroupDetailsComponent implements OnInit {

    @Input() userContactGroup: UserContactGroup;
    @Input() accounts = [];
    @Input() formMode: any = FormMode.read;
    @Output() onAccountChanged = new EventEmitter();

    loadingAccounts = false;
    selectedResellerId;
    permissions: {};

    constructor(private cd: ChangeDetectorRef, private authenticationService: AuthenticationService) {
        this.permissions = this.authenticationService.permissions;
    }

    ngOnInit(): void {
    }

    resellerChanged(resellerId) {
        this.userContactGroup.accountId = null;
        this.selectedResellerId = resellerId;
    }

    accountChanged(accountId) {
        this.onAccountChanged.emit(this.userContactGroup?.accountId);
    }
}

