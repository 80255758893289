import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditLog } from '../../models/auditlog.model';
import { AuditLogService } from '../../services/auditlog/auditlog.service';
import { GeofenceService } from 'app/services/geofence/geofence.service';
import { Geofence } from 'app/models/geofence.model';

@Component({
    selector: 'fh-geofence-log',
    templateUrl: 'geofenceLog.template.html'
})
export class GeofenceLogViewComponent implements OnInit {
    geofenceLog: AuditLog[];
    sub;
    geofence: Geofence;
    loading = false;

    permissionName = 'FleetManagement_Log';
    constructorName = 'GeofenceLogViewComponent';

    error: any;
    success: any;
    geofenceId: any;

    constructor(private http: HttpClient, private auditLogService: AuditLogService, private geofenceService: GeofenceService, private route: ActivatedRoute, private router: Router) {
        this.geofence = null;
        this.geofenceLog = [];
    }

    ngOnInit() {
        this.loading = true;
        this.geofence = new Geofence();
        this.geofence.id = 0;

        this.sub = this.route.params.subscribe(params => {
            const id = params['id'];

            this.geofenceId = id;
            this.geofenceService.getGeofenceById(id).subscribe(geofence => {
                this.geofence = geofence;

                if (this.geofence == null) {
                    this.router.navigate(['/Geofences/Overview'])
                }

                this.auditLogService.getAuditLogByGeofence(id).subscribe(deviceLog => {
                    console.log('loading done');
                    this.geofenceLog = deviceLog;
                    this.loading = false;
                }, error => {
                    this.error = error;
                    this.loading = false;
                });
            });
        });
    }
}
