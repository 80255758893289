<ng-select [loading]="loadingUserContactGroups" ngDefaultControl [searchable]="true" [clearable]="clearable"
    style="height: unset" [disabled]="userContactGroups.length === 0" [readonly]="readonly" [virtualScroll]="true"
    placeholder="{{placeholder}} ({{userContactGroups.length}})" #input [multiple]="multiple" [(ngModel)]="value"
    [items]="userContactGroups" bindLabel="userContactName" bindValue="id" class="form-control"
    groupBy="userContactItems" [selectableGroup]="true" [groupValue]="groupValueFn" [closeOnSelect]="false"
    [searchFn]="searchFn" [ngClass]="tableInline === true ? 'table-inline' : '' "
    (ngModelChange)="userContactGroupChanged()">
    <ng-template ng-label-tmp let-userContactGroup="item" let-clear="clear">
        <span *ngIf="userContactGroup.name">
            <i class="fas fa-fw fa-layer-group"
                title="Group: {{userContactGroup.name}} {{userContactGroup.length}}"></i> {{
            userContactGroup.name
            }} ({{userContactGroup.length}})</span>
        <span *ngIf="userContactGroup.email">
            {{userContactGroup.email}}
        </span>
        <span class="ng-value-icon right" (click)="clear(userContactGroup)" aria-hidden="true">×</span>
    </ng-template>
    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
        <i class='far fa-fw fa-lg' [ngClass]="item$.selected ? 'fa-check-square' : 'fa-square'"></i>
        {{item.name}}
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
        <span style="float: left; margin-top: -8px;"></span>
        <span style="padding: 10px"> {{item.email}}</span>
    </ng-template>
</ng-select>
<div style="clear:both"></div>