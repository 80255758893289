<div class="ibox" style="position:relative">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="ibox-title">
        <h5 *ngIf="name">
            <i class="fas fa-fw " [ngClass]="sharingIcon ? 'fa-podcast' : 'fa-list-ul'"></i> {{ name }}
        </h5>
        <h5 *ngIf="!name && showItem">
            <i class="fas fa-fw fa-list-ul"></i> {{'general.items' | translate }}
        </h5>
        <h5 *ngIf="!name && !showItem">
            <i class="fas fa-fw fa-layer-group"></i> {{'general.groups' | translate }}
        </h5>
        <span class="pull-right">
            <div class="filter-form hidden-sm hidden-xs">
                <input type="text" class="form-control table-inline top-filter hidden-sm hidden-xs"
                    [(ngModel)]="filter">
            </div>
        </span>
    </div>
    <div class="ibox-content slick" style="position: relative; overflow-y: auto;">
        <table style="width: 100%" class="table nowrap dtr-inline no-footer">
            <thead>
                <tr>
                    <th *ngIf="allowSelect"></th>
                    <th>{{'general.name' | translate }}</th>
                    <th *ngIf="permissions['Accounts_View']" style="width:200px">{{'general.account' | translate}}</th>
                    <th *ngIf="showCount">{{'general.count' | translate }}</th>
                    <th style="width:110px">{{'general.created' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="groups.length == 0">
                    <td colspan="3">{{'general.noGroupsFound' | translate }}</td>
                </tr>
                <tr
                    *ngFor="let group of groups | stringFilter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, id: 'uTable' }">
                    <td style="width:20px" *ngIf="allowSelect"><input type="checkbox" value=""></td>
                    <td>
                        <a href='/#/{{linkUrl}}/Index/{{  group.deviceId ? group.deviceId : group.id}}'>
                            <span class='secondary link_bolder'><i class="fas fa-fw fa-angle-right"></i></span>
                            {{group.name}}
                        </a>
                    </td>
                    <td *ngIf="permissions['Accounts_View']" style="width:200px"><a href='/#/AccountDetails/Index/{{ group.accountId }}'>
                            <span class='secondary link_bolder'><i class="fas fa-fw fa-angle-right"></i></span>
                            {{ group.companyName }}</a>
                    </td>
                    <td *ngIf="showCount">{{group.itemCount}}</td>
                    <td style="width:110px" title="{{ group.timestamp?.toLocaleString() }}">{{ group.timestamp |
                        amDateFormat: 'll'}} </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="ibox-footer noselect dataTables_wrapper">
        <pagination-template #ut="paginationApi" (pageChange)="currentPage = $event" id="uTable">
            <div class="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                <ul class="pagination">
                    <li class="paginate_button page-item previous" [class.disabled]="ut.isFirstPage()">
                        <a class="page-link" (click)="ut.previous()" *ngIf="!ut.isFirstPage()">{{ 'grid.previous' |
                            translate }}</a>
                        <a *ngIf="ut.isFirstPage()">{{ 'grid.previous' | translate }}</a>
                    </li>

                    <li class="paginate_button page-item " *ngFor="let page of ut.pages"
                        [class.current]="ut.getCurrent() === page.value">
                        <a class="page-link" (click)="ut.setCurrent(page.value)" *ngIf="ut.getCurrent() !== page.value">
                            <span>{{ page.label }}</span>
                        </a>
                        <a class="page-link" (click)="ut.setCurrent(page.value)" *ngIf="ut.getCurrent() === page.value">
                            <span style="font-weight: bold;">{{ page.label }}</span>
                        </a>
                    </li>

                    <li class="paginate_button page-item next" [class.disabled]="ut.isLastPage()">
                        <a class="page-link" (click)="ut.next()" *ngIf="!ut.isLastPage()">
                            {{ 'grid.next' | translate }}
                        </a>
                        <a class="page-link" *ngIf="ut.isLastPage()"> {{ 'grid.next' | translate }} </a>
                    </li>
                </ul>
            </div>
        </pagination-template>
        <div style="clear:both"></div>
    </div>
</div>