import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormMode } from 'app/common/enums';
import { MaintenanceOccurrence } from 'app/models/maintenance.model';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
    selector: 'fh-maintenance-details',
    templateUrl: 'maintenanceDetails.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class MaintenanceDetailsComponent implements OnInit, OnChanges {
    @Input() maintenanceOccurrence: MaintenanceOccurrence;
    @Input() useCompact = false;
    @Input() loading = false;
    @Input() identifier = 0;
    @Input() formMode: any = FormMode.read;

    // Datepicker
    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    permissions: {};

    constructor(private authenticationService: AuthenticationService) {
        this.permissions = this.authenticationService.permissions;
    }

    ngOnInit(): void {
        // Datepicker
        this.dpConfig.containerClass = 'theme-default'; // or whatever color
        this.dpConfig.dateInputFormat = 'lll';
        this.dpConfig.isAnimated = true;
        this.dpConfig.withTimepicker = true;
    }

    ngOnChanges(): void {
        console.log(this.maintenanceOccurrence);
    }
}
