import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getGridButtons, getGridLanguages, setSelection, setTableStars, setSelectionRows, createdCellCheckbox } from 'app/common/gridhelper';
import { DeviceType } from 'app/models/devicetype.model';

import { DeviceTypeService } from 'app/services/devicetypes/devicetypes.service';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { TranslatorService } from '../../services/common/translator.service';
import { DeviceService } from '../../services/device/device.service';
import { AnalogFunctions, BatchStatus, DeviceOutput, EntityType, LocationEventType } from 'app/common/enums';
import { getIconPath, roundAsNumber, roundAsString } from 'app/common/globals';
import { GridBase360Directive } from 'app/common/360Grid.base';

// Moment timezone
import * as Moment from 'moment';
import * as mTZ from 'moment-timezone';
import { Device } from 'app/models/device.model';
import { StorageHelper } from 'app/common/storagehelper';

window['moment'] = Moment;
mTZ()

@Component({
  selector: 'fh-devices-state',
  templateUrl: 'state.template.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DevicesStateViewComponent extends GridBase360Directive implements OnInit, OnDestroy {
  token: string;
  countrySelect = false;

  excludingColumns = ['timeStamp', 'assetDateStart', 'assetDateEnd', 'lastPosition', 'lastCommunication', 'input1', 'input2', 'input3', 'input4', 'input5', 'input6', 'output1', 'output2'];
  loading = false;

  randomKey;

  deviceTypes: DeviceType[];

  dropdown_CustomerName = '';
  languageLoaded: boolean;
  timezoneIana: string;
  dropdown_CustomerNameSettings: any;
  selectedCustomers = [];

  dropdownSettingsDeviceTypes: any;

  permissionName = 'Devices_View';
  constructorName = 'DevicesSensorsViewComponent';

  error: any;
  success: any;

  enableSelecting = true;

  gridSelection = [];
  showSelection = false;

  entityTypes = EntityType.Device;
  isAllSelected = false;

  daterangepickerModel: any;
  accountService: any;
  router: any;

  includeGeofenceNames = false;
  includeAddresses = false;

  constructor(private localeService: BsLocaleService, private route: ActivatedRoute, private translatorService: TranslatorService, private translateService: TranslateService, private cd: ChangeDetectorRef, private authenticationService: AuthenticationService, private deviceService: DeviceService, private deviceTypeService: DeviceTypeService, protected storageHelper: StorageHelper) {
    super(storageHelper);

    this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

    const that = this;
    this.loading = true;

    this.token = this.authenticationService.getAuthToken();
    this.timezoneIana = this.authenticationService.getTimeZoneIana();

    // Get all the date for dropdown boxes
    Observable.forkJoin([
      this.translateService.get('general.date'),
      this.deviceTypeService.getDeviceTypes()]
    ).subscribe(
      data => {

        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();

        this.deviceTypes = data[1].filter(x => x.deviceCount > 0);
        this.deviceTypes = this.deviceTypes.sort((a, b) => (a.modelName > b.modelName ? 1 : -1));

        this.initGrid();
      },
      err => {
        this.error = err;
        this.languageLoaded = true;
        this.loading = false;
        this.cd.markForCheck();
      });
  }


  ngOnInit() {

  }

  dateChanged(event) {
    const that = this;
    if (event !== null) {
      this.loading = true;

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

        this.randomKey = Math.floor(Math.random() * (999999 - 100000)) + 100000;

        dtInstance.ajax.url(that.deviceService.getStatePagingUrl(this.randomKey, this.includeGeofenceNames, this.includeAddresses))
          .load(() => this.loading = false);
      });
    }
  }

  // Check custom filters from grid save
  checkFilters() {
    this.fireFilter(1);
  }

  // Check route to apply filters
  checkRoute() {
  }

  // Fire custom filters to update grid and call server again
  fireFilter(event): void {
    if (event != null) {
      console.log('Fire update');
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Fire the request
        dtInstance.draw();
      });
    }
  }

  initGrid(): void {

    const that = this;

    $.fn['dataTable'].ext.search.pop();

    this.loading = true;

    const deviceTypeOptions = [];
    this.deviceTypes.forEach(function (item, index) {
      if (item.modelName !== '') {
        deviceTypeOptions.push({ id: item.modelName, value: item.modelName + ' (' + item.deviceCount + ')' });
      }
    });

    const inputMapping = Object.keys(LocationEventType)
      .filter(k => typeof LocationEventType[k] === 'string')
      .map(n => ({ id: n, value: this.translateService.instant('enums.locationEventType.' + n) }))
      .sort((a, b) => a.value.localeCompare(b.value));

    const outputMapping = Object.keys(DeviceOutput)
      .filter(k => typeof DeviceOutput[k] === 'string')
      .map(n => ({ id: n, value: this.translateService.instant('enums.deviceOutput.' + n) }))
      .sort((a, b) => a.value.localeCompare(b.value));

    const analogMapping = Object.keys(AnalogFunctions)
      .filter(k => typeof AnalogFunctions[k] === 'string')
      .map(n => ({ id: n, value: this.translateService.instant('enums.analogFunctions.' + n) }))
      .sort((a, b) => a.value.localeCompare(b.value));

    this.columns = [{
      name: 'id',
      data: 'id',
      className: 'noVis',
      orderable: false,
      title: '<div class="hideDropdown"></div>',
      width: '20',
      render: function (data, type, row) {
        return '<a class=\'btn btn-primary btn-grid\' title=\'' + that.translateService.instant('general.details') + '\' href=\'/#/DeviceDetails/Index/' + data + '\'><span class="hidden-sm hidden-xs" style="padding-left: 7px">' + that.translateService.instant('general.details') + ' </span><i class=\'fas fa-fw fa-angle-right\'></i></a>';
      }
    }, {
      name: 'id_export',
      data: 'id',
      className: 'noVis',
      title: this.translateService.instant('general.deviceId'),
      visible: false,
    },
    {
      name: 'asset_id',
      data: 'asset.id',
      defaultContent: '-',
      title: this.translateService.instant('general.assetId'),
      visible: false,
    },
    {
      name: 'assetName',
      data: 'asset.name',
      defaultContent: '-',
      title: this.translateService.instant('general.name'),
    },
    {
      name: 'erpCode',
      data: 'erpCode',
      defaultContent: '-',
      visible: false,
      title: this.translateService.instant('general.assetErpCode'),
    },
    {
      name: 'accountErpCode',
      data: 'accountErpCode',
      defaultContent: '-',
      visible: false,
      title: this.translateService.instant('general.accountErpCode'),
    },
    {
      name: 'unitId',
      data: 'unitId',
      title: this.translateService.instant('general.unitId'),
      visible: true
    },
    {
      name: 'deviceTypeName',
      data: 'deviceTypeName',
      defaultContent: '-',
      iconName: 'fas fa-fw fa-router',
      type: 'select',
      visible: false,
      options: deviceTypeOptions.sort((a, b) => a.value.localeCompare(b.value)),
      title: this.translateService.instant('general.devicetype')
    },
    {
      name: 'deviceState',
      data: 'deviceState.calculatedDeviceState.deviceState',
      title: this.translateService.instant('general.deviceState'),
      visible: true,
      iconName: 'fas fa-fw fa-map-marker-alt',
      type: 'select',
      options: [
        { id: '0', value: that.translateService.instant(('general.deviceState_0')) },
        { id: '1', value: that.translateService.instant(('general.deviceState_1')) },
        { id: '2', value: that.translateService.instant(('general.deviceState_2')) },
        { id: '3', value: that.translateService.instant(('general.deviceState_3')) },
        { id: '4', value: that.translateService.instant(('general.deviceState_4')) },
        { id: '5', value: that.translateService.instant(('general.deviceState_5')) },
        { id: '6', value: that.translateService.instant(('general.deviceState_6')) },
      ],
      render: function (data, type, row) {
        if (data == null) {
          return '-';
        } else {
          let color = 'black';
          if (data === 1) {
            color = 'green';
          }
          if (data === 2) {
            color = 'red';
          }
          if (data === 3) {
            color = 'orange';
          }
          if (data === 4) {
            color = 'blue';
          }
          return '<i class="fa fa-fw fa-map-marker-alt eye-active ' + color + '"></i> ' + that.translateService.instant('general.deviceState_' + data);
        }
      }
    },
    {
      name: 'lastCommunication',
      data: 'deviceState.communicationState.updateTimestamp',
      defaultContent: '',
      title: this.translateService.instant('general.lastCommunication'),
      render: function (data, type, row) {
        if (data == null) {
          return 'N/A';
        } else {
          const date = Moment.utc(data)['tz'](that.timezoneIana);
          return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
        }
      }
    },
    {
      name: 'latency',
      data: 'deviceState.communicationState.updateTimestamp',
      defaultContent: '',
      title: this.translateService.instant('general.latencyInSeconds'),
      visible: false,
      render: function (data, type, row) {
        const serverTime = Moment.utc(row.deviceState.communicationState?.serverTimestamp);
        const deviceTime = Moment.utc(row.deviceState.communicationState?.updateTimestamp);

        const duration = Moment.duration(serverTime.diff(deviceTime));

        return data ? roundAsString(duration.asSeconds(), 0) : '-';
      }
    },
    {
      name: 'manufacturer',
      data: 'manufacturer',
      defaultContent: '-',
      title: this.translateService.instant('general.manufacturer'),
      visible: false
    },
    {
      name: 'assetCode',
      data: 'asset.code',
      defaultContent: '-',
      title: this.translateService.instant('general.assetCode'),
      visible: false
    },
    {
      name: 'assetPlateNumber',
      data: 'asset.plateNumber',
      defaultContent: '-',
      title: this.translateService.instant('general.plateNumber'),
      visible: false
    },
    {
      name: 'assetBrand',
      data: 'asset.brand',
      defaultContent: '-',
      title: this.translateService.instant('general.assetBrand'),
      visible: false
    },
    {
      name: 'assetModel',
      data: 'asset.model',
      defaultContent: '-',
      title: this.translateService.instant('general.assetModel'),
      visible: false
    },
    {
      name: 'assetColor',
      data: 'asset.color',
      defaultContent: '-',
      title: this.translateService.instant('general.color'),
      visible: false
    },
    {
      name: 'assetComment',
      data: 'asset.comment',
      defaultContent: '-',
      title: this.translateService.instant('general.comment'),
      visible: false
    },
    {
      name: 'companyName',
      data: 'companyName',
      defaultContent: '-',
      title: this.translateService.instant('general.companyName')
    }, {
      name: 'accountId',
      data: 'accountId',
      defaultContent: '-',
      title: this.translateService.instant('general.accountId'),
      visible: false
    },
    {
      name: 'timeStamp',
      data: 'createdDate',
      title: this.translateService.instant('general.timeStamp'),
      render: function (data, type, row) {
        const date = Moment.utc(data)['tz'](that.timezoneIana);
        return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
      },
      visible: false
    },
    {
      name: 'location',
      data: 'row.deviceState.currentPosition.latitude ',
      defaultContent: '',
      title: this.translateService.instant('general.location'),
      render: function (data, type, row) {
        if (row.deviceState?.currentPosition?.latitude != null && row.deviceState?.currentPosition?.longitude != null) {
          return '<a class="secondary link_bolder" target="_blank" href="http://maps.google.com/?q=' + row.deviceState?.currentPosition?.latitude + ',' + row.deviceState?.currentPosition?.longitude + '"><i class="fa fa-globe"></i> ' + row.deviceState?.currentPosition?.latitude.toFixed(4) + '/' + row.deviceState?.currentPosition?.longitude.toFixed(4) + '</a>';
        }
        return 'Unknown';
      },
      visible: false
    },
    {
      name: 'simIdentifier',
      data: 'simIdentifier',
      defaultContent: '-',
      title: this.translateService.instant('general.simIdentifier'),
      visible: false
    },
    {
      name: 'simActivationStatus',
      data: 'simActivationStatus',
      defaultContent: '-',
      title: this.translateService.instant('general.simActivationStatus'),
      visible: false,
      render: function (data, type, row) {
        return that.translateService.instant(('enums.simActivationStatus.' + data));
      }
    },
    {
      name: 'msisdn',
      data: 'msisdn',
      defaultContent: '-',
      title: this.translateService.instant('general.msisdn'),
      visible: false
    },
    {
      name: 'imsi',
      data: 'imsi',
      defaultContent: '-',
      title: this.translateService.instant('general.imsi'),
      visible: false
    },
    {
      name: 'resellerDescription',
      data: 'resellerDescription',
      defaultContent: '-',
      title: this.translateService.instant('general.resellerDescription'),
      visible: false
    },
    {
      name: 'assetCount',
      data: 'assetCount',
      defaultContent: '-',
      title: this.translateService.instant('general.assetCount'),
      visible: false
    },
    {
      name: 'assetDateStart',
      data: 'asset.assetDateStart',
      defaultContent: '',
      title: this.translateService.instant('general.assetDateStart'),
      render: function (data, type, row) {
        if (data == null) {
          return '-';
        } else {
          if (type && type === 'display') {
            const date = Moment.utc(data)['tz'](that.timezoneIana);
            return data ? '<span title=" ' + date.toLocaleString() + '">' + date.format('lll') + '</span>' : '';
          } else {
            return data;
          }
        }
      },
      visible: false
    },
    {
      name: 'assetTypeName',
      data: 'assetTypeName',
      defaultContent: '-',
      title: this.translateService.instant('general.assetTypeName'),
      visible: false
    },
    {
      name: 'iconId',
      data: 'iconId',
      title: this.translateService.instant('general.icon'),
      render: function (data, type, row) {
        if (data == null) {
          return '-';
        } else {
          return data ? '<img style=" margin-top: -20px; margin-bottom: -10px;" src="' + (data > 0 ? getIconPath(data)[1] : '/assets/images/icons/vista/Trucks/32x32/TankerTruck_Black.png') + '">' : '';
        }
      },
      visible: false
    },
    {
      name: 'speedInKph',
      data: 'deviceState.currentPosition.speed',
      defaultContent: '-',
      type: 'num',
      iconName: 'fa fa-fa fa-gauge-max',
      title: this.translateService.instant('general.speedInKph'),
      visible: true,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + ' km/h' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'rpm',
      data: 'deviceState.rpmStatus.rpm',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-engine',
      title: this.translateService.instant('general.rpm'),
      visible: true,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + ' RPM' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'externalBatteryLevelInVoltage',
      data: 'deviceState.externalBattery.batteryLevelInVoltage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-car-battery',
      title: this.translateService.instant('general.externalBatteryLevelInVoltage'),
      visible: true,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 1) + ' V' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'backupBatteryLevelInVoltage',
      data: 'deviceState.backupBattery.batteryLevelInVoltage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-car-battery',
      title: this.translateService.instant('general.backupBatteryLevelInVoltage'),
      visible: true,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 1) + ' V' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'temperatureInCelcius',
      data: 'deviceState.temperature1.temperatureInCelcius',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-thermometer-three-quarters',
      title: this.translateService.instant('general.temperature'),
      visible: true,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 1) + ' °C' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'temperature2InCelcius',
      data: 'deviceState.temperature2.temperatureInCelcius',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-thermometer-three-quarters',
      title: this.translateService.instant('general.temperature2'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 1) + ' °C' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'temperature3',
      data: 'deviceState.temperature3.temperatureInCelcius',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-thermometer-three-quarters',
      title: this.translateService.instant('general.temperature3'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 1) + ' °C' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'temperature4',
      data: 'deviceState.temperature4.temperatureInCelcius',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-thermometer-three-quarters',
      title: this.translateService.instant('general.temperature4'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 1) + ' °C' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'deviceAuxiliary',
      data: 'deviceAuxiliary',
      defaultContent: '-',
      iconName: 'fas fa-fw fa-toggle-off',
      title: this.translateService.instant('general.deviceAuxiliary'),
      visible: false,
      width: '40'
    },
    {
      name: 'totalWeight',
      data: 'deviceState.totalAxleWeight.totalWeightInKg',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-weight',
      title: this.translateService.instant('general.weight'),
      visible: true,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? (roundAsNumber(data, 0)).toLocaleString() + ' kg' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'humidityInPercent1',
      data: 'deviceState.humidity1.humidityInPercent',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-humidity',
      title: this.translateService.instant('general.humidityInPercent1'),
      visible: true,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'humidityInPercent2',
      data: 'deviceState.humidity2.humidityInPercent',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-humidity',
      title: this.translateService.instant('general.humidityInPercent2'),
      visible: true,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'fuelLevelInPercentage',
      data: 'deviceState.fuel.fuelLevelInPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-gas-pump',
      title: this.translateService.instant('general.fuelLevelInPercentage'),
      visible: true,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'fuelLevelInPercentage1',
      data: 'deviceState.fuel1.fuelLevelInPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-gas-pump',
      title: this.translateService.instant('general.fuelLevelInPercentage1'),
      visible: true,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'fuelLevelInPercentage2',
      data: 'deviceState.fuel2.fuelLevelInPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-gas-pump',
      title: this.translateService.instant('general.fuelLevelInPercentage2'),
      visible: true,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'fuelConsumed',
      data: 'deviceState.fuel.fuelConsumed',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-weight',
      title: this.translateService.instant('general.fuelConsumed'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? (roundAsNumber(data, 0)).toLocaleString() + ' l' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'externalPower_mapping',
      data: 'mappingProfile.externalPowerMapping',
      defaultContent: '',
      title: this.translateService.instant('general.externalPowerMapping'),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.externalPower + '">' + that.translateService.instant('enums.locationEventType.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'externalPower',
      data: 'deviceState.externalPower.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.externalPowerState'),
      visible: true,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'ignition_mapping',
      data: 'mappingProfile.ignitionMapping',
      defaultContent: '',
      title: this.translateService.instant('general.ignitionMapping'),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.ignition + '">' + that.translateService.instant('enums.locationEventType.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'ignition',
      data: 'deviceState.ignition.state',
      type: 'checkBox',
      defaultContent: '',
      iconName: 'fas fa-fw fa-key',
      title: this.translateService.instant('general.ignitionState'),
      visible: true,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'input1_mapping',
      data: 'mappingProfile.inputMapping1',
      defaultContent: '-',
      title: this.translateService.instant('general.inputMappingFormatted', { value: '1' }),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.mappingProfile.inputMapping1 + '">' + that.translateService.instant('enums.locationEventType.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'input1_value',
      data: 'deviceState.input1.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.inputStateFormatted', { value: '1' }),
      visible: true,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'input2_mapping',
      data: 'mappingProfile.inputMapping2',
      defaultContent: '-',
      title: this.translateService.instant('general.inputMappingFormatted', { value: '2' }),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.mappingProfile.inputMapping2 + '">' + that.translateService.instant('enums.locationEventType.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'input2_value',
      data: 'deviceState.input2.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.inputStateFormatted', { value: '2' }),
      visible: true,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'input3_mapping',
      data: 'mappingProfile.inputMapping3',
      defaultContent: '',
      title: this.translateService.instant('general.inputMappingFormatted', { value: '3' }),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.mappingProfile.inputMapping3 + '">' + that.translateService.instant('enums.locationEventType.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'input3_value',
      data: 'deviceState.input2.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.inputStateFormatted', { value: '3' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'input4_mapping',
      data: 'mappingProfile.inputMapping4',
      defaultContent: '',
      title: this.translateService.instant('general.inputMappingFormatted', { value: '4' }),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.mappingProfile.inputMapping4 + '">' + that.translateService.instant('enums.locationEventType.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'input4_value',
      data: 'deviceState.input4.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.inputStateFormatted', { value: '4' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'input5_mapping',
      data: 'mappingProfile.inputMapping5',
      defaultContent: '',
      title: this.translateService.instant('general.inputMappingFormatted', { value: '5' }),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.mappingProfile.inputMapping5 + '">' + that.translateService.instant('enums.locationEventType.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'input5_value',
      data: 'deviceState.input5.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.inputStateFormatted', { value: '5' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'input6_mapping',
      data: 'mappingProfile.inputMapping6',
      defaultContent: '',
      title: this.translateService.instant('general.inputMappingFormatted', { value: '6' }),
      visible: false,
      type: 'select',
      options: inputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.mappingProfile.inputMapping6 + '">' + that.translateService.instant('enums.locationEventType.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'input6_value',
      data: 'deviceState.input6.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.inputStateFormatted', { value: '6' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'output1_mapping',
      data: 'mappingProfile.outputMapping1',
      defaultContent: '',
      title: this.translateService.instant('general.outputMappingFormatted', { value: '1' }),
      visible: false,
      type: 'select',
      options: outputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.output1 + '">' + that.translateService.instant('enums.deviceOutput.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'output1_value',
      data: 'deviceState.output1.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.outputStateFormatted', { value: '1' }),
      visible: true,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'output2_mapping',
      data: 'mappingProfile.outputMapping2',
      defaultContent: '',
      title: this.translateService.instant('general.outputMappingFormatted', { value: '2' }),
      visible: false,
      type: 'select',
      options: outputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.output2 + '">' + that.translateService.instant('enums.deviceOutput.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'output2_value',
      data: 'deviceState.output2.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.outputStateFormatted', { value: '2' }),
      visible: true,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'output3_mapping',
      data: 'mappingProfile.outputMapping3',
      defaultContent: '',
      title: this.translateService.instant('general.outputMappingFormatted', { value: '3' }),
      visible: false,
      type: 'select',
      options: outputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.output3 + '">' + that.translateService.instant('enums.deviceOutput.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'output3_value',
      data: 'deviceState.output3.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.outputStateFormatted', { value: '3' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'output4_mapping',
      data: 'mappingProfile.outputMapping4',
      defaultContent: '',
      title: this.translateService.instant('general.outputMappingFormatted', { value: '4' }),
      visible: false,
      type: 'select',
      options: outputMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.output4 + '">' + that.translateService.instant('enums.deviceOutput.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'output4_value',
      data: 'deviceState.output4.state',
      type: 'checkBox',
      defaultContent: '',
      title: this.translateService.instant('general.outputStateFormatted', { value: '4' }),
      visible: false,
      createdCell: createdCellCheckbox,
      width: '40',
    },
    {
      name: 'analog1_mapping',
      data: 'mappingProfile.analogMapping1',
      defaultContent: '',
      title: this.translateService.instant('general.analogMappingFormatted', { value: '1' }),
      visible: false,
      type: 'select',
      options: analogMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.analog1 + '">' + that.translateService.instant('enums.analogFunctions.' + BigInt(data)) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'analog1_value',
      data: 'deviceState.analog1.value',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-bolt',
      title: this.translateService.instant('general.analogStateFormatted', { value: '1' }),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 2) + ' V' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'analog2_mapping',
      data: 'mappingProfile.analogMapping2',
      defaultContent: '',
      title: this.translateService.instant('general.analogMappingFormatted', { value: '2' }),
      visible: false,
      type: 'select',
      options: analogMapping,
      render: function (data, type, row) {
        return data > 0 ? '<span title="' + row.analog2 + '">' + that.translateService.instant('enums.analogFunctions.' + data) + '</span>' : '-';
      },
      width: '40',
    },
    {
      name: 'analog2_value',
      data: 'deviceState.analog2.value',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-bolt',
      title: this.translateService.instant('general.analogStateFormatted', { value: '2' }),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 2) + ' V' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'bleBatteryPercentage1',
      data: 'deviceState.bleBatteryState1.batteryLevelPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.bleBatteryVoltage1'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'bleBatteryPercentage2',
      data: 'deviceState.bleBatteryState1.batteryLevelPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.bleBatteryVoltage2'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'bleBatteryPercentage3',
      data: 'deviceState.bleBatteryState1.batteryLevelPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.bleBatteryVoltage3'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'bleBatteryPercentage4',
      data: 'deviceState.bleBatteryState4.batteryLevelPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.bleBatteryVoltage4'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '%' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'actualAccellerationForce',
      data: 'deviceState.accelerometerState.accelerationForce',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.actualAccellerationForce'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() + ' mg' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'actualBrakingForce',
      data: 'deviceState.accelerometerState.brakingForce',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.actualBrakingForce'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() + ' mg' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'actualCorneringForce',
      data: 'deviceState.accelerometerState.corneringForce',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.actualCorneringForce'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data !== undefined ? (roundAsNumber(data, 0)).toLocaleString() + ' mg' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'calibratedOdo',
      data: 'asset.calibratedOdo',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.calibratedOdo'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data !== undefined ? (roundAsNumber(data / 1000, 0)).toLocaleString() + ' km' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'odometerValueInMetres',
      data: 'deviceState.odometer.gpsOdometer',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.gpsOdometerInMeters'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data !== undefined ? (roundAsNumber(data / 1000, 0)).toLocaleString() + ' km' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'canbusOdometerValueInMetres',
      data: 'deviceState.odometer.canBusOdometer',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-battery-half',
      title: this.translateService.instant('general.canBusOdometerValueInMeters'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data !== undefined ? (roundAsNumber((data) / 1000, 0)).toLocaleString() + ' km' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'angle1InDegrees',
      data: 'deviceState.angle1InDegrees',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-angle',
      title: this.translateService.instant('general.angle1InDegrees'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + ' °' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'angle2InDegrees',
      data: 'deviceState.angle2InDegrees',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-angle',
      title: this.translateService.instant('general.angle2InDegrees'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + ' °' : '-';
        } else {
          return data;
        }
      },
    }, {
      name: 'angle3InDegrees',
      data: 'deviceState.angle3InDegrees',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-angle',
      title: this.translateService.instant('general.angle3InDegrees'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + ' °' : '-';
        } else {
          return data;
        }
      },
    }, {
      name: 'angle4InDegrees',
      data: 'deviceState.angle4InDegrees',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-angle',
      title: this.translateService.instant('general.angle4InDegrees'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + ' °' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'batteryChargeLevelInPercentage',
      data: 'deviceState.batteryConsumptionState.batteryChargeLevelInPercentage',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-thermometer-three-quarters',
      title: this.translateService.instant('general.batteryChargeLevelInPercentage'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '  %' : '-';
        } else {
          return data;
        }
      },
    },
    {
      name: 'batteryPowerConsumptionInKWhPer100Km',
      data: 'deviceState.batteryConsumptionState.batteryPowerConsumptionInKWhPer100Km',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-thermometer-three-quarters',
      title: this.translateService.instant('general.batteryPowerConsumptionInKWhPer100Km'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '  %' : '-';
        } else {
          return data;
        }
      },
    }, {
      name: 'remainingDistanceInKm',
      data: 'deviceState.batteryConsumptionState.remainingDistanceInKm',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-thermometer-three-quarters',
      title: this.translateService.instant('general.remainingDistanceInKm'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data ? roundAsString(data, 0) + '  km' : '-';
        } else {
          return data;
        }
      },
    }, {
      name: 'tagScanStatus',
      data: 'deviceState.tagScanStatus.tag',
      defaultContent: '-',
      iconName: 'fas fa-fw fa-tag',
      title: this.translateService.instant('general.tagScanStatus'),
      visible: false,
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data;
        }
      },
    },
    {
      name: 'engineHour',
      data: 'deviceState.engineHour.engineHourInSeconds',
      defaultContent: '-',
      type: 'num',
      iconName: 'fas fa-fw fa-clock',
      title: this.translateService.instant('general.engineHour'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data !== undefined ? (roundAsNumber(data / 1000, 0)).toLocaleString() + ' H' : '-';
        } else {
          return data;
        }
      },
    }, {
      name: 'project',
      data: 'asset.activeProject.name',
      defaultContent: '-',
      title: this.translateService.instant('general.project'),
      visible: false
    }, {
      name: 'Geofences',
      data: 'asset.currentGeofences',
      defaultContent: '-',
      title: this.translateService.instant('general.geofences'),
      visible: false,
      render: function (data, type, row) {
        return data != null ? `<span title='${data}'>${data}</span>` : '-';
      },
    },
    {
      name: 'city',
      data: 'asset.currentAddress.city',
      defaultContent: '-',
      iconName: 'fas fa-fw fa-clock',
      title: this.translateService.instant('general.city'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data;
        }
      }
    }, {
      name: 'address',
      data: 'asset.currentAddress.address',
      defaultContent: '-',
      iconName: 'fas fa-fw fa-clock',
      title: this.translateService.instant('general.address'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data;
        }
      }
    }, {
      name: 'city',
      data: 'asset.currentAddress.country',
      defaultContent: '-',
      iconName: 'fas fa-fw fa-clock',
      title: this.translateService.instant('general.country'),
      visible: false,
      width: '40',
      render: function (data, type, row) {
        if (type && type === 'display') {
          return data;
        }
      }
    },
    {
      name: 'canBusParameters',
      data: 'canBusParameters',
      defaultContent: '-',
      iconName: 'fas fa-fw fa-filter',
      title: this.translateService.instant('general.canBusParameters'),
      visible: false,
      width: '40'
    },
    ];
    this.dtOptions = {
      buttons: getGridButtons(this.commonExportOptions, 'device_state_overview', this.translateService.instant('menu.deviceoverview')),
      pagingType: 'simple_numbers',
      serverSide: true,
      processing: true,
      searchDelay: 2000,
      deferRender: true,
      scrollX: true,
      colReorder: { fixedColumnsLeft: 1 },
      deferLoading: 0,
      stateSave: true,
      stateSaveCallback: function (settings, data) {
        that.saveState(that.constructorName, data);
      },
      stateLoadCallback: function (_, callback) {
        (async () => {
          try {
            const columnSettings = await that.loadState(that.constructorName);
            that.searchTerm = columnSettings && columnSettings.search && columnSettings.search.search;
            return columnSettings;
          } catch (e) {
            that.error = {};
            that.error.error = e;
            that.error.statusText = 'Error fetching column settings';

            return null;
          }
        })().then(result => {
          callback(result);
        });
      },
      order: [[6, 'desc']],
      ajax: {
        beforeSend: () => {
          that.drawFilterRow();

          $('.dataTables_info').html(this.translateService.instant('grid.loadingData'));
        },
        url: that.deviceService.getStatePagingUrl(this.randomKey, this.includeGeofenceNames, this.includeAddresses),
        data: (d) => {
          return d;
        },
        dataSrc: function (json) {
          // if selection and returning keys
          return json.data;
        },
        error: (jqXHR, ajaxOptions, thrownError) => {
          that.loading = false;
          that.error = thrownError;
          that.cd.markForCheck();
        },
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + that.token
        }
      },
      initComplete: function (settings, json) {
        that.checkRoute();
        that.checkFilters();
        that.setEvents();
        that.drawFilterRow();
        $('#grid_select_or').appendTo($('th > #selectorHeader'));
        that.loading = false;
        that.cd.markForCheck();
      },
      colVis: {
        restore: this.translateService.instant('general.restore'),
        showAll: this.translateService.instant('general.showAll'),
        showNone: this.translateService.instant('general.hideAll'),
        exclude: ['id', 'id_export', 'isSelected']
      },
      columns: this.columns,
      pageLength: 17,
      lengthMenu: [[10, 17, 25, 50, -1], [10, 17, 25, 50, this.translateService.instant('general.all')]],
      language: getGridLanguages(this.translateService)
    };
  }
}
