<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-12 flex header-content">
        <ol class="breadcrumb">
            <li>
                <a href="/#/">
                    <i class="fas fa-fw fa-home"></i> {{'general.home' | translate }}</a>
            </li>
            <li *ngIf="warehouse?.accountId">
                <a href="/#/AccountDetails/Index/{{warehouse?.accountId}}">{{warehouse?.companyName}}</a>
            </li>
            <li>
                <a href="/#/Warehouses/Overview">{{'general.warehouses' | translate }}</a>
            </li>
            <li>
                <a href="/#/WarehouseDetails/Index/{{warehouse?.id}}">{{warehouse?.name}}</a>
            </li>
            <li class="active">{{'general.dashboard' | translate }}</li>
        </ol>
    </div>
    <fh-warehouse-details-navigation [warehouseId]="warehouseId"></fh-warehouse-details-navigation>
</div>

<fh-permission-handler [permissionName]="permissionName">
    <div class="wrapper wrapper-content">
        <div class="row" data-equalizer="" *ngIf="error || success">
            <div class="col-lg-12">
                <fh-error [error]="error" [success]="success"></fh-error>
            </div>
        </div>

        <div class="row" data-equalizer="">
            <div class="col-lg-6">

                <form #userForm="ngForm">
                    <div class="ibox" style="position:relative">
                        <div class="ibox-title">
                            <h5>
                                <i class="fa fa-warehouse"></i> {{'general.warehouse' | translate }}
                            </h5>
                        </div>
                        <fh-warehouse-details [warehouse]="warehouse" [warehouseAzure]="warehouseAzure"
                            [formMode]="formMode" [loading]="loading || saving">
                        </fh-warehouse-details>
                        <fh-save-footer [valid]="!userForm.invalid" (onSave)="onSave()" (onDelete)="onDelete()"
                            [allowEdit]="permissions['Warehouses_Edit']"
                            [allowDelete]="permissions['Warehouses_Delete']" (setFormMode)="setFormMode($event)"
                            [formMode]="formMode">
                        </fh-save-footer>
                    </div>
                </form>

                <fh-tagging [objectId]="warehouse?.id" [objectType]="tagType">
                </fh-tagging>

            </div>
            <div class="col-lg-6">
                <div class="ibox" style="position:relative" *ngIf="zoneData.length == 0">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-border-none"></i> {{'general.zones' | translate }}
                        </h5>

                        <span class="pull-right"
                            tooltip="{{'general.updated' | translate }} {{ previousLookupDisplay | amDateFormat: 'LLL' }}">
                            <i style="margin-left: 3px;" class="fa fa-fw"
                                [ngClass]="isSpinning ? 'fa-spinner fa-spin eye-active green' : 'fa-sync-alt'"></i>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative;">
                        <ngx-loading [show]="loadingZoneData"></ngx-loading>

                        <div style="padding:10px" *ngIf="loadingZoneData">Fetching zone data</div>
                        <div style="padding:10px" *ngIf="!loadingZoneData && zoneData.length == 0"> {{
                            'general.noSensorData' | translate}}</div>
                    </div>
                </div>

                <div class="ibox" style="position:relative"
                    *ngFor="let data of zoneData | orderBy: 'zoneName': false ; let i = index">
                    <div class="ibox-title">
                        <h5>
                            <i class="fa fa-border-none"></i> {{data.zoneName}}
                        </h5>

                        <span class="pull-right"
                            tooltip="{{'general.updated' | translate }} {{ previousLookupDisplay | amDateFormat: 'LLL' }}">
                            <i style="margin-left: 3px;" class="fa fa-fw"
                                [ngClass]="isSpinning ? 'fa-spinner fa-spin eye-active green' : 'fa-sync-alt'"></i>
                        </span>
                    </div>
                    <div class="ibox-content slick" style="position: relative;">
                        <div>
                            <div class="col-lg-6 col-xs-12" style="padding: 0;">
                                <div class="kpi-item border-active " [ngClass]="data?.markerColor">
                                    <h3 class="margin-bottom font-bold"
                                        title="{{ data.maxTimestamp | amDateFormat: 'LLL'}}">
                                        <i class="fa fa-signal"
                                            [style.color]="data?.markerColor == 'green' ? '#0a8a15' : (data?.markerColor == 'orange' ? '#d48113' : '#b81313')"></i>
                                        {{ data.maxTimestamp | amTimeAgo}}
                                    </h3>
                                    <div title="{{ data.maxTimestamp | amDateFormat: 'LLL'}}">{{
                                        'general.lastCommunication' | translate}}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xs-12" style="padding: 0;">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold">

                                        <i class="fas fa-fw fa-sensor"
                                            [style.color]="data.sensorViolationCount == 0 ? '#0a8a15' : '#b81313'"
                                            title="Sensors / Sensors with violation"></i>
                                        {{ data.sensorCount}} / {{ data.sensorViolationCount}}
                                    </h3>
                                    <div>{{ 'general.sensorCount' | translate}} / {{ 'general.violations' | translate}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xs-12" style="padding: 0;">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold"
                                        title="Accepted range: {{ data.lowerTemperatureThreshold }}°C - {{data.upperTemperatureThreshold}}°C">

                                        <i class="fas fa-fw fa-thermometer-three-quarters" title="Temperature"></i>
                                        {{ actualRound(data.avgTemperature,1) }} °C
                                    </h3>
                                    <div>{{ 'general.temperature' | translate}}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xs-12" style="padding: 0;">
                                <div class="kpi-item">
                                    <h3 class="margin-bottom font-bold"
                                        title="Accepted range: {{ data.lowerHumidityThreshold }}% - {{data.upperHumidityThreshold}}%">

                                        <i class="fas fa-fw fa-humidity" title="humidity"></i>
                                        {{ actualRound(data.avgHumidity,0) }} %
                                    </h3>
                                    <div>{{ 'general.humidity' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                </div>

                <div class="ibox">
                    <fh-leaflet-map [editableGeofence]="geofence" [height]="450" [allowEdit]="allowEdit"
                        [showSearch]=true [allowBookmarks]=false (onSave)="onSaveShape($event)">
                        <div header class="ibox-title">
                            <span class="pull-left">
                                <h5>
                                    <i class="fa fa-fw fa-map-marked-alt"></i> {{'general.map' | translate }}
                                </h5>
                            </span>
                        </div>
                    </fh-leaflet-map>
                </div>
            </div>
        </div>
    </div>
</fh-permission-handler>