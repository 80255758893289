
export class Reseller {
    public id: string;
    public resellerDescription: any;
    public resellerAuthorizationCode: any;
    public resellerEmail: any;
    public resellerTechnicalContactEmail: any;
    public isNotSelectable = false;
    public parentResellerId: any;
    public parentDescription: any;
    public deviceCount: any;
    public deviceRecursiveCount: any;
    public defaultAssetIconId: any;
    public clientCount: any;
}


