<form #groupForm="ngForm" style="width: 100%; border-top: 0px">
    <table class="table nowrap dtr-inline no-footer" *ngIf="reportSubscription">
        <thead>
            <tr>
                <th class="small_padding">{{ 'general.name' | translate}}</th>
                <th class="small_padding">{{ 'report.period' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="small_padding">
                    {{ reportSubscription?.name != "" ?
                    reportSubscription?.name : ('enums.reportType.' + reportSubscription?.selectedReportType) |
                    translate }}
                </td>
                <td class="small_padding">
                    <span>
                        <input name="Daterangepicker" style="width:100%"
                        placeholder="{{ 'report.period' | translate}}" type="text" [bsConfig]="dpConfig" class="form-control table-inline"
                        bsDaterangepicker #dateVar="ngModel" required (ngModelChange)="dateChanged($event)"
                        (onHidden)="dateChanged('hidden')" [(ngModel)]="daterangepickerModel">
                        <span class="input-group-calendar">
                            <i class="fa fa-calendar-alt"></i>
                        </span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</form>