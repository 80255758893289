import { Component, Input } from '@angular/core';
import { roundAsNumber } from 'app/common/globals';

const noop = () => {
};

@Component({
    selector: 'fh-score-indicator',
    templateUrl: './scoreIndicator.component.html',
})

export class ScoreIndicatorComponent {

    @Input() score;
    @Input() totalScore;

    constructor() {
    }

    round(value, decimals) {
        return roundAsNumber(value, decimals);
    }
}
