<div *ngIf="error && show" class="alert alert-danger shadow">
    <i class="fas fa-fw fa-times pull-right" style="cursor: pointer;" (click)="clearError()"></i>
    <span *ngIf="error && error?.statusText">
        <p style="font-size:15px"><strong>{{ error?.statusText }}</strong></p>
    </span>
    <span *ngIf="error && error?.error && !error?.error.errors">
        <p style="margin:0">{{ error?.error }}</p>
    </span>

    <span *ngIf="error && error?.error && error?.error.errors">
        <p>{{error?.error.error}}</p>
        <p *ngFor="let error of error?.error.errors">{{ error.field}} - {{ error.messages[0]}}</p>
    </span>

    <!-- <span *ngIf="error && error?.message">
        <p>- {{ error?.message }}</p>
    </span>
    <span *ngIf="error && error?.name">
        <p>- {{ error?.name }}</p>
    </span> -->
    <span *ngIf="error && error.json">
        <pretty-json [obj]="error.json"></pretty-json>
    </span>

    <span *ngIf="error && !error?.statusText"><strong>{{ error }}</strong></span>
</div>

<div *ngIf="warning && show" class="alert alert-warning shadow">
    <i class="fas fa-fw fa-times pull-right" style="cursor: pointer;" (click)="clearError()"></i>
    <span *ngIf="warning && warning?.statusText">
        <p style="font-size:15px"><strong>{{ warning?.statusText }}</strong></p>
    </span>
    <span *ngIf="warning && warning?.warning">
        <p style="margin:0">{{ warning?.warning }}</p>
    </span>

    <span *ngIf="error && warning.json">
        <pretty-json [obj]="warning.json"></pretty-json>
    </span>

    <span *ngIf="warning && !warning?.statusText"><strong>{{ warning }}</strong></span>
</div>

<div *ngIf="success && show" class="alert alert-success shadow">
    <i class="fas fa-fw fa-times pull-right" style="cursor: pointer;" (click)="clearError()"></i>
    <span *ngIf="success && success?.statusText">
        <p style="font-size:15px"><strong>{{ success?.statusText }}</strong></p>
    </span>
    <span *ngIf="success && success?.success">
        <p style="margin:0">{{ success?.success }}</p>
    </span>
    <span *ngIf="success && success.json">
        <pretty-json [obj]="success.json"></pretty-json>
    </span>
    <span *ngIf="success && !success.statusText && !copyText">
        <p style="margin:0"><strong>{{ success }}</strong></p>
    </span>
    <span style="display: flex; align-items: center;" *ngIf="success && !success.statusText && copyText">
        <p style="margin:0"><strong>{{ success }}</strong>
            <ng-container *ngIf="!copyCompleted; else copyResult">
                <i tooltip="Click to copy" class="fa fa-fw fa-copy" style="padding: 0px 10px; cursor: pointer;"
                    (click)="textToClipboard()"></i>
            </ng-container>
            <ng-template #copyResult>
                <i tooltip="Copied!" class="fa fa-fw fa-copy" style="padding: 0px 10px; cursor: pointer;"></i>
            </ng-template>
        </p>
    </span>
</div>