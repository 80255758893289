import { BatchAction, BatchStatus, BatchItemStatus, EntityType } from 'app/common/enums';

export class Batch {
    public id: any;
    public status: any;
    public name: string;
    public itemCount: any;
}

export class BatchJob {
    public id: any;
    public items: any;
    public entityTypes: EntityType;
    public batchActionType: BatchAction;
    public jsonObject: any;
    public status: BatchStatus;
    public error: any;
    public jobRequested: any;
    public jobStarted: any;
    public jobCompleted: any;
    public errorDescription: any;
    public log: any;
}

export class BatchItem {
    public id: any;
    public entityId: any;
    public status: BatchItemStatus;
    public message: any;
    public link: any;
}
