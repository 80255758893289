import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication/authentication.service';

@Component({
    selector: 'fh-equipment-navigation',
    templateUrl: 'equipmentNavigation.template.html'
})

export class EquipmentNavigationComponent implements OnInit {
    sub: any;
    permissions: {};

    constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) {
        this.permissions = this.authenticationService.permissions;
    }

    ngOnInit() {
    }

    activeRoute(routename: string): boolean {
        const active = this.router.url.indexOf(routename) !== -1;
        return active;
    }
}
