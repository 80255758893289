import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';


import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    private isRefreshing = false;

    constructor(
        private authService: AuthenticationService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

        req = req.clone({
            withCredentials: true,
        });

        return next.handle(req).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && !req.url.includes('FromRefreshToken') && error.status === 401) {
                    return this.handle401Error(req, next);
                }

                return throwError(error);
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        console.log('Handling intercepting refresh');
        if (!this.isRefreshing) {
            this.isRefreshing = true;

            var isValid = this.authService.isTokenValid()

            if (isValid == false) {
                console.log('Token not valid anymore.. getting refresh');

                return this.authService.IsAuthenticated('', true).pipe(
                    switchMap(() => {
                        this.isRefreshing = false;

                        return next.handle(request);
                    }),
                    catchError((error) => {
                        this.isRefreshing = false;

                        return throwError(() => error);
                    })
                );
            }
        }

        return next.handle(request);
    }
}

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];